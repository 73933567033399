import React, {Component} from 'react'
import {connect} from "react-redux"
import c from '../../../constants'
import {debounce} from '../../hoc/myUtils'
import {
    getStakingOperationsByPage,
    getStakingOperationsInitial,
} from "../../../redux/actions/staking"
import StakingOperationsTable from "./StakingOperationsTable"


class StakingOperations extends Component {

    constructor(props) {
        super(props)
        this.state = {
            rows: [],
            page: 0,
            rowsPerPage: 10,
            filtersMap: {
                "userStatus": "",
                "group": 0
            },
            sort: {},
        }

        this.setRowsPerPage = this.setRowsPerPage.bind(this)
        this.setPage = this.setPage.bind(this)
        this.onFilterChange = this.onFilterChange.bind(this)
        this.onFilter = this.onFilter.bind(this)
        this.debouncedFilter = debounce(this.onFilter, 500)
    }

    setRowsPerPage (value) {
        this.state.rowsPerPage = value
        this.state.page = 0
        this.props.getSOInitial({filters: this.state.filtersMap,
            sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
    }

    setPage (value) {
        let page = value
        if (!this.props.pagesDownloaded[page+1]) {
            this.state.page = page
            this.props.getSOByPage({filters: this.state.filtersMap,
                sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
        } else {
            this.setState({
                page: page
            })
        }
    }

    onFilterChange(e) {
        let filterEntry = {[e.target.name]: e.target.value}
        this.setState({
            filtersMap: {...this.state.filtersMap, ...filterEntry}
        })
        this.debouncedFilter()
    }

    onFilter() {
        this.props.getSOInitial({filters: this.state.filtersMap,
            sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
    }

    componentDidMount() {
        this.props.getSOInitial({filters: this.state.filtersMap,
            sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
    }

    render() {
        let {docs, totalDocs} = this.props
        return (<StakingOperationsTable
            rows={docs}
            rowsPerPage={this.state.rowsPerPage}
            setRowsPerPage={this.setRowsPerPage}
            page={this.state.page}
            setPage={this.setPage}
            totalDocs={totalDocs ? totalDocs : 0}
            filtersMap={this.state.filtersMap}
            onFilterChange={this.onFilterChange}
        />)
    }
}

let mapStateToProps = rootState => ({
    docs: rootState.staking.stakingOperations.docs,
    totalDocs: rootState.staking.stakingOperations.totalDocs,
    totalPages: rootState.staking.stakingOperations.totalPages,
    pagesDownloaded: rootState.staking.stakingOperations.pagesDownloaded
})

let mapDispatchToProps = dispatch => {
    return {
        getSOInitial: (props) => dispatch(getStakingOperationsInitial(props)),
        getSOByPage: (props) => dispatch(getStakingOperationsByPage(props)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (StakingOperations)
