import React from "react";
import {FormControlLabel, TextField, withStyles} from "@material-ui/core"
import CardMembership from '@material-ui/icons/CardMembership'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import makeStyles from "@material-ui/core/styles/makeStyles";


const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 110,
        backgroundColor: '#101A26'
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    }
}))

const CustomColorRadio = withStyles({
    root: {
        color: "#ffffff",
        '&$checked': {
            color: "#ffffff",
        },
    },
    checked: {},
})(props => <Radio color="default" {...props} />)

export default function SelectFollowerSubscription(props) {
    const {allSubscriptions,  handleSelect, selectedSubscriptionId, 
        selectedSubscriptionExpirationDate, handleChangeExpirationDate,
        editExpirationDate} = props
    const classes = useStyles()

    function getSubscriptionName(elem) {
        if (elem.free)
            return <div>{elem.name} <span style={{fontSize: "0.5rem", color: "#64fe7c"}}>Free</span></div>
        else
            return <div>{elem.name}</div>
    }

    return (
    <div className={"wizardSectionContainer"}>
        <div className={"wizardTitle"}>
            <span className={"wizardTitleIcon"}><CardMembership/></span> Subscriptions
        </div>
        <div style={{marginLeft: "0.6rem"}}>
            <RadioGroup value={selectedSubscriptionId} onChange={handleSelect}>
                {allSubscriptions && allSubscriptions.map((elem, index) => {
                    if (selectedSubscriptionId === elem._id ) {
                        if (elem.free) {
                            return <div style={{display: "flex"}}>
                                <FormControlLabel value={elem._id} control={<CustomColorRadio />} label={getSubscriptionName(elem)} checked={true}/>
                                {editExpirationDate && <div style={{marginTop: "0.8rem", fontSize: "0.7rem"}}>(never expires)</div>}
                            </div>
                        } else {
                            return <div style={{display: "flex"}}>
                                <FormControlLabel value={elem._id} control={<CustomColorRadio />} label={getSubscriptionName(elem)} checked={true}/>
                                {editExpirationDate && <TextField variant="outlined" margin="dense"
                                           className={classes.textField}
                                           label={"Expires on"}
                                           placeholder={"YYYY-MM-DD"}
                                           value={selectedSubscriptionExpirationDate}
                                           onChange={handleChangeExpirationDate}
                                />}
                            </div>
                        }

                    } else {
                        return <FormControlLabel value={elem._id} control={<CustomColorRadio />} label={getSubscriptionName(elem)} checked={false}/>
                    }
                })}

            </RadioGroup>
        </div>
    </div>)
}
