import React from 'react'
import Fab from "@material-ui/core/Fab"
import c from '../../constants'
import {Check, Info} from '@material-ui/icons'

import makeStyles from "@material-ui/core/styles/makeStyles";
import {FormControlLabel, withStyles} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(theme => ({
    fabSubscribe: {
        margin: theme.spacing(1),
        width: 160,
        background: "#101926",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "white",
        '&:hover': {
            background: "#424242"
        }
    },

    fabRemove: {
        margin: theme.spacing(1),
        background: "#FB4A4A",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "white",
        '&:hover': {
            background: "#424242"
        },
    },
}));

const CustomColorCheckbox = withStyles({
    root: {
        color: "#ffffff",
        '&$checked': {
            color: "#ffffff",
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />)

export default function TraderSubscription(props) {
    const classes = useStyles()
    const {subscription: _s,
            handleSubscribe,
            handleUnsubscribe,
            handleRemove,
            handleChangeAutoRenew,
            handleReSubscribe,
            usersSubscriptionsMap,
            user,
            shownToFollower} = props

    function getNameContent(_s) {
        if (_s.active) {
            if (!_s.unsubscribed) {
                return <div className={"traderSubTitle"}>{_s.name}</div>
            } else {
                return <div className={"traderSubTitle"} style={{color: "gray"}}>{_s.name}</div>
            }
        } else {
            return <div className={"traderSubTitle"} style={{color: "gray"}}>{_s.name}</div>
        }
    }

    function getPriceContent(_s) {
        let priceContent = []
        if (!_s.free) {
            let period = ""
            if (_s.periodicity === 1)
                period = "month"
            else if (_s.periodicity === 3)
                period = "quarter"
            else if (_s.periodicity === 12)
                period = "year"
            if (_s.discounted) {
                let discount = (parseFloat(_s.discountedPrice) - parseFloat(_s.listPrice) ) / parseFloat(_s.listPrice)
                discount = (discount * 100).toFixed(0)
                priceContent.push(<div className={"traderSubListPriceCut traderSubPriceSubContainer"} key={Math.random()}>{_s.listPrice}</div>)
                priceContent.push(<div className={"traderSubDiscount traderSubPriceSubContainer"} key={Math.random()}><b>{discount}%</b> off</div>)
                priceContent.push(<div className={"traderSubMainPrice traderSubPriceSubContainer"} key={Math.random()}>{_s.discountedPrice} <span className={"traderSubMainPriceCoin"}>{_s.priceCoin}</span></div>)
                priceContent.push(<div className={"traderSubPeriod traderSubPriceSubContainer"} key={Math.random()}>per <b>{period}</b></div>)
            } else if (!_s.discounted) {
                priceContent.push(<div className={"traderSubPriceTopOffset"} key={Math.random()}>&nbsp;</div>)
                priceContent.push(<div className={"traderSubMainPrice traderSubPriceSubContainer"} key={Math.random()}>{_s.listPrice} <span className={"traderSubMainPriceCoin"}>{_s.priceCoin}</span></div>)
                priceContent.push(<div className={"traderSubPeriod traderSubPriceSubContainer"} key={Math.random()}>per <b>{period}</b></div>)
                // priceContent.push(<div className={"traderSubPriceBottomOffset"} key={Math.random()}>&nbsp;</div>)
            }
        } else if (_s.free) {
            priceContent.push(<span className={"traderSubMainPriceFree"} key={Math.random()}>Free</span>)
        }
        return <div className={"traderSubPriceContainer"} key={Math.random()}>{priceContent}</div>
    }

    function getExpirationDateContent(_s) {
        if (_s.free)
            return
        let expirationDate= new Date(_s.expirationDate)
        let year = expirationDate.getFullYear()
        let month = expirationDate.getMonth() + 1
        let day = expirationDate.getDate()
        if (_s.active) {
            return <div title={"YYYY-MM-DD"} className={"traderActiveTill"}>(active till&nbsp;<b>{year}-{month}-{day}</b>)</div>
        } else {
            return <div title={"YYYY-MM-DD"} className={"traderSubExpiresOn"}>(expired! on &nbsp;<b>{year}-{month}-{day}</b>)</div>
        }


    }

    function getSubscriptionStatusContent(_s) {
        if (_s.unsubscribed)
            return <div className={"traderSubSubscribedStatus"}>UNSUBSCRIBED</div>
    }

    function getAutoRenewContent(_s) {
        if (_s.free)
            return

        if (!_s.unsubscribed) {
            let content = undefined
            if (_s.autoRenew) {
                content = <FormControlLabel
                    control={<CustomColorCheckbox checked={true}
                                                  onChange={() => handleChangeAutoRenew(_s)} value={_s.subscriptionId} />}
                    label={"Auto renew"}
                />
            } else {
                content = <FormControlLabel
                    control={<CustomColorCheckbox checked={false}
                                                  onChange={() => handleChangeAutoRenew(_s)} value={_s.subscriptionId} />}
                    label={"Auto renew"}
                />
            }
            return <div style={{width: "100%", display: "flex", justifyContent: "center"}}>{content}</div>
        }
    }

    function getFeaturesContent(_s) {
        let featuresContent = []
        for(let i = 0; i < _s.features.length; i++)
            featuresContent.push(<div className={"traderSubFeature"} key={Math.random()}>{_s.features[i]}</div>)
        return <div style={{display: "flex", flexDirection: "column", marginBottom: "1rem"}}>
            {featuresContent}
        </div>
    }

    function getMainDivClasses(_s, simpleForm) {
        if (simpleForm)
            return "traderSubscriptionBox"

        if (_s.isMain)
            return "traderSubscriptionBox traderSubscriptionBoxMain"
        else
            return "traderSubscriptionBox"
    }

    function getStateHR(_s) {
        let hrMainState, hrSecondaryState, details
        if (_s.active) {
            if (!_s.unsubscribed) {
                if (_s.autoRenew) {
                    hrMainState = "Active"
                    hrSecondaryState = "Active, Subscribed & Auto Renewable"
                    details = ["Your subscription is active and you are receiving signals from this trader."]
                    if (!_s.free) {
                        details.push("Upon expiry date your wallet will be automatically billed to renew it.")
                    }
                } else {
                    hrMainState = "Active"
                    hrSecondaryState = "Active, Subscribed & Not Auto Renewable"
                    details = ["Your subscription is active and you are receiving signals from this trader until the expiry date.",
                        "Upon expiry date your wallet won't be billed to renew it and you will stop receiving signals from this trader afterwards."]
                }
            } else {
                hrMainState = "Active"
                hrSecondaryState = 'Active, Unsubscribed'
                details = ['You subscription is still active and you are receiving signals from this trader until the expiry date.', "However, you can still do the following:",
                    '1) Resubscribe at no cost', '2) Or simply terminate the subscription by removing it.']
            }
        } else {
            hrMainState = 'Expired'
            details = [<div style={{textAlign: "center"}}>Your subscription is expired.</div>]
        }
        return <div style={{marginTop: "1rem", backgroundColor: "#292929", padding: "0.6rem", borderRadius: "4px"}}>
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}><Info/>&nbsp;<b>{hrMainState}</b></div>
            {hrSecondaryState && <div style={{display: "flex", alignItems: "center", justifyContent: "center", fontSize: "0.8rem", opacity: "0.9", marginTop: "0.2rem"}}>{hrSecondaryState}</div>}
            {details && <div style={{fontSize: "0.68rem", opacity: "0.8"}}>{details.map(d => <div style={{textJustify: "inter-character", padding: "0.6rem"}}>{d}</div>)}</div>}
        </div>
    }

    return (
        <div className={getMainDivClasses(_s, shownToFollower)}
             style={{width: "16rem"}}>
            {_s.traderName && <div className={"traderSubTitle"} style={{fontSize: "1.3rem"}}>{_s.traderName}</div>}
            {!shownToFollower &&
                <div style={{width: "100%"}}>
                    {_s.status === c.TRADER_SUBSCRIPTION_ACTIVE && <div className={"traderSubTitle"}>{_s.name}</div>}
                    {_s.status === c.TRADER_SUBSCRIPTION_DISABLED && <div className={"traderSubTitle"} style={{color: "gray"}}>
                        {_s.name}
                        <div style={{fontWeight: "normal", fontSize: "0.9rem"}}>(inactive)</div>
                    </div>}
                </div>
            }
            {shownToFollower && getNameContent(_s)}
            {getPriceContent(_s)}
            {shownToFollower && <div style={{width: "100%", background: "gray", borderRadius: "0.4rem"}}>
                {getExpirationDateContent(_s)}
                {getSubscriptionStatusContent(_s)}
                {getAutoRenewContent(_s)}
            </div>}

            {_s.description && <div className={"traderSubDescription"}>{_s.description}</div>}
            {getFeaturesContent(_s)}

            {/*{(user && (user._id === "5f22f25bd5ecb64ef8d4be18" || user._id === "5d6e5c53ea916f18bccbe9dd")) &&*/}
            {/*<div>*/}
            {/*    {usersSubscriptionsMap && <div>usersSubscriptionsMap {JSON.stringify(usersSubscriptionsMap)} {Object.keys(usersSubscriptionsMap).length}</div>}*/}
            {/*    {!usersSubscriptionsMap && <div>usersSubscriptionsMap NULL</div>}*/}
            {/*</div>}*/}

            {handleSubscribe && <div style={{width: "100%"}}>
                {(usersSubscriptionsMap && Object.keys(usersSubscriptionsMap).length === 0) && <div style={{display: "flex", justifyContent: "center", width: "100%", padding: "2em 0.6em 2em 0.6em"}}>
                    <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabSubscribe} onClick={() => handleSubscribe(_s)}>
                        Subscribe
                    </Fab>
                </div>}
            </div>}

            {usersSubscriptionsMap && <div style={{display: "flex", justifyContent: "center", width: "100%", padding: "2em 0.6em 2em 0.6em"}}>
                {usersSubscriptionsMap[_s._id] && <div className={"traderSubSubscribed"}>
                    <Check style={{color: "#3badff"}}/> Subscribed
                </div>}
            </div>}

            {(usersSubscriptionsMap && !usersSubscriptionsMap[_s._id] && Object.keys(usersSubscriptionsMap).length > 0) &&
            <div style={{width: "100%"}}>
                <div
                    title={"You can have only one active subscription at the time."}
                    style={{display: "flex", justifyContent: "center", width: "100%", padding: "2em 0.6em 2em 0.6em"}}>
                    <Fab disabled={true} variant="extended" color="default" aria-label="Cancel" className={classes.fabSubscribe}>
                        Subscribe
                    </Fab>
                </div>
            </div>}

            {shownToFollower &&
                <div style={{width: "100%"}}>
                    {getStateHR(_s)}
                    {(_s.active && !_s.unsubscribed) && <div style={{width: "100%"}}>
                        <div style={{display: "flex", justifyContent: "center", width: "100%", padding: "2em 0.6em 2em 0.6em", marginLeft:"-1rem"}}>
                            <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabSubscribe} onClick={() => handleUnsubscribe(_s)}>
                                UNSUBSCRIBE
                            </Fab>
                        </div>
                    </div>}

                    {(_s.active && _s.unsubscribed) &&<div style={{width: "100%"}}>
                        <div style={{display: "flex", justifyContent: "center", width: "100%", padding: "2em 0.6em 2em 0.6em", marginLeft:"-1rem"}}>
                            <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabSubscribe} onClick={() => handleReSubscribe(_s)}>
                                RE-SUBSCRIBE
                            </Fab>
                            <Fab variant="extended" aria-label="Cancel" className={classes.fabRemove} onClick={() => handleRemove(_s)}>
                                REMOVE
                            </Fab>
                        </div>
                    </div>}
                    {!_s.active && <div style={{width: "100%"}}>
                        <div style={{display: "flex", justifyContent: "center", width: "100%", padding: "2em 0.6em 2em 0.6em", marginLeft:"-1rem"}}>
                            <Fab variant="extended" aria-label="Cancel" className={classes.fabRemove} onClick={() => handleRemove(_s)}>
                                REMOVE
                            </Fab>
                        </div>
                    </div>}
            </div>}
        </div>
    )
}
