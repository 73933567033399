import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import TableHead from "@material-ui/core/TableHead"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import {timeFormatter2} from "../../fncomponents/UISupportFns"
import {TextField} from "@material-ui/core";


const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5),
    },
}))

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, columns } = props
    const createSortHandler = property => event => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {columns.map(column => (
                    <TableCell
                        key={column.id}
                        align={column.numeric ? 'right' : 'left'}
                        padding={'default'}
                        sortDirection={orderBy === column.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === column.id}
                            direction={order}
                            onClick={createSortHandler(column.id)}
                        >
                            {column.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
}

function TablePaginationActions(props) {
    const classes = useStyles1()
    const theme = useTheme()
    const { count, page, rowsPerPage, onChangePage } = props

    function handleFirstPageButtonClick(event) {
        onChangePage(event, 0)
    }

    function handleBackButtonClick(event) {
        onChangePage(event, page - 1)
    }

    function handleNextButtonClick(event) {
        onChangePage(event, page + 1)
    }

    function handleLastPageButtonClick(event) {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    )
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
}

const useStyles2 = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}))


export default function SignalsNotificationsTable(props) {
    const classes = useStyles2()
    const {rows,
        rowsPerPage, setRowsPerPage, page, setPage, totalDocs,
        handleChangeText, text,
        isSuperAdmin, readUnread,
        accept, reject, discard} = props
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('value')


    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc'
        setOrder(isDesc ? 'asc' : 'desc')
        setOrderBy(property)
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalDocs - page * rowsPerPage)

    function handleChangePage(event, newPage) {
        setPage(newPage)
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    return (
        <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
                <Table className={classes.table}>
                    <TableBody>
                        <TableRow hover tabIndex={-1} key={"filterRow"}>
                            <TableCell component="th" scope="row">
                                <div style={{fontSize:"1rem", opacity: "0.7", marginTop: "1rem"}}>
                                    <TextField
                                        value={text}
                                        onChange={handleChangeText}
                                        placeholder={"Search.."}
                                    />
                                </div>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                <div style={{fontSize:"1rem", opacity: "0.7", marginTop: "1rem"}}>Actions</div>
                            </TableCell>
                        </TableRow>
                        {rows && rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                            <TableRow hover tabIndex={0} key={row._id}>
                                <TableCell component="th" scope="row"  onClick={()=>{readUnread(row._id)}} style={{cursor: "pointer"}}>
                                    <div style={{opacity: `${row.status === 1 ? '0.9': '0.6'}`}}>
                                        <span style={{fontSize: "0.8rem"}}>{timeFormatter2(row.creationDate, true)}</span>
                                        {row.text}
                                        {row.actionResult === 1 && <span style={{color: "#64FE7C", marginLeft: "1rem"}}>✓ Accepted</span>}
                                        {row.actionResult === 2 && <span style={{color: "#FB4A4A", marginLeft: "1rem"}}>✗ Rejected</span>}
                                    </div>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {row.actionResult === 0 && <div style={{display: "flex", justifyContent: "flex-start", width:"6rem", marginLeft:"-1rem"}}>
                                            <div className={"hrefButton"}
                                                 onClick={()=>{accept(row._id)}}>Accept</div>
                                            <div style={{marginLeft: "0.8rem"}} className={"hrefButton"}
                                                 onClick={()=>{reject(row._id)}}>Reject</div>
                                    </div>}
                                    {row.actionResult === 1  && <div style={{marginLeft:"-1rem", width: "100%", textAlign: "left"}} className={"hrefButton"}
                                            onClick={()=>{reject(row._id)}}>Reject</div>}
                                    {row.actionResult === 2  && <div style={{marginLeft:"-1rem", width: "100%", textAlign: "left"}} className={"hrefButton"}
                                                                                                 onClick={()=>{accept(row._id)}}>Accept</div>}
                                </TableCell>
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 48 * emptyRows }}>
                                <TableCell colSpan={5}>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10]}
                                colSpan={6}
                                count={totalDocs}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </div>
        </Paper>
    )
}
