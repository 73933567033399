import makeStyles from "@material-ui/core/styles/makeStyles";
import Fab from "@material-ui/core/Fab";
import React from "react";
import c from '../../constants'

const useStyles = makeStyles(theme => ({
    fabCancel: {
        margin: theme.spacing(1),
        width: 130,
        background: "#101926",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "white",
        '&:hover': {
            background: "#424242"
        }
    },
    fabTakeProfit: {
        margin: theme.spacing(1),
        background: "#152B40",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "white",
        '&:hover': {
            background: "#424242"
        }
    },
    fabDiscard: {
        margin: theme.spacing(1),
        background: "#FB4A4A",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "black",
        '&:hover': {
            background: "#424242"
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    }
}));

export default function BTDDetailsMainButtons(props) {
    const classes = useStyles()
    let {btd, cancelBTD, discardBTD} = props

    if (btd.status === c.BTD_STATUS_DIP_REACHED || btd.status === c.BTD_STATUS_CANCELED || btd.status === c.BTD_STATUS_ERROR)
        return (
            <div className={"btdDetailsMainButtonsContainer"}>
                <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabDiscard}
                     onClick={() => {
                         discardBTD()
                     }}>
                    <div style={{color: "white"}}>Discard</div>
                </Fab>
            </div>
        )
    else return (
        <div className={"btdDetailsMainButtonsContainer"}>
            <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabCancel}
                 onClick={() => {
                     cancelBTD()
                 }}>
                <div style={{color: "white"}}>Cancel</div>
            </Fab>
        </div>
    )
}
