import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import {changePassword} from "../../redux/actions/authentication"
import {connect} from "react-redux"
import Button from "@material-ui/core/Button"
import {Helmet} from "react-helmet/es/Helmet";
import {getTitle} from "../fncomponents/PageTitleUtils";

const qs = require("query-string")

class ResetPassword extends Component {

    constructor(props) {
        super(props)
        this.state = {
            password: "",
            passwordConfirmation: "",
            passwordResetToken: "",
            passwordChanged: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.changePassword = this.changePassword.bind(this)
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    changePassword() {
        this.props.changePassword({
            resetPasswordToken: this.state.passwordResetToken,
            password: this.state.password,
            passwordConfirmation: this.state.passwordConfirmation
        }, ()=> {this.setState({
            passwordChanged: true
        })})
    }

    componentDidMount() {
        this.state.passwordResetToken = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).kjq
    }

    render() {
        let localCustomStyle = {width: "16em"}
        let {errors} = this.props
        errors = errors ? errors: {}

        if (this.state.passwordChanged) {
            return <Redirect to="/login"/>
        }


        return (<div className={'loginForm'} style={{marginTop: "6em"}}>
            <div>
                <div className={"loginFormTitle"}>Change Password</div>
                <div className={"vertSpacer"}/>
            </div>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <Paper style={{padding: "1em", width: "16em"}}>
                <TextField
                    id={"password"}
                    variant={"outlined"}
                    autoFocus
                    type="password"
                    name={"password"}
                    label={"Password"}
                    placeholder={"Password"}
                    style={localCustomStyle}
                    onChange = {this.handleChange}
                    value = {this.state.password}
                    error={errors && errors["password"]}
                    helperText={errors["password"]}
                    autoComplete={"false"}
                />
                <br/>
                <br/>
                <TextField
                    id={"passwordConfirm"}
                    variant={"outlined"}
                    autoFocus
                    type="password"
                    name={"passwordConfirmation"}
                    label={"Password confirmation"}
                    placeholder={"Password confirmation"}
                    style={localCustomStyle}
                    onChange = {this.handleChange}
                    value = {this.state.passwordConfirmation}
                    error={errors && errors["passwordConfirmation"]}
                    helperText={errors["passwordConfirmation"]}
                    autoComplete={"false"}/>
                <br/><br/>
                <Button variant="contained"  color="primary" fullWidth label="LogIn" onClick={() => this.changePassword()}>Submit</Button>
            </Paper>
        </div>)

    }
}

const mapStateToProps = rootState => ({
    errors: rootState.errors.changePasswordError,
})

const mapDispatchToProps =  dispatch => {
    return {
        changePassword: (props, cb) => {dispatch(changePassword(props, cb))}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
