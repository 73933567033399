import io from 'socket.io-client'
import s from '../../settings'
import {
    DEPOSIT_ADD_OR_UPDATE,
    GET_FUTURES_TRADE_UPDATE,
    GET_SUC_UPDATE,
    GET_TRADE_UPDATE,
    SIGNALS_GET_UPDATE
} from "./actionTypes"
import {unflatten} from 'flat'
import sum from './utils/hash-sum'
import vss from '../virtualScrollerState'

let storeRef = undefined
let clientWebSocket = undefined

export const initStore = store => {
    storeRef = store
}

const logPrefix = "wsPrices"
const LOGGING = false
const LOGGING_SIGNAL_UPDATE = false

export const initWS = (userId) => {
    if (!userId) {
        return
    }

    if (clientWebSocket) {
        return
    }

    // console.log(`---------------------------------------------`)
    // console.log(`${logPrefix}/initWS PRICES < ${userId} >`)
    // console.log(`---------------------------------------------`)
    clientWebSocket = io(`${s.WS_API_BACKEND()}`, {
        path: `/ws`,
        query: {
            userId
        }
    })
    // clientWebSocket.onopen = () => {
    //     console.log(`${logPrefix} clientWebSocket Connected`)
    // }

    let lastPayloadHash = ""
    clientWebSocket.on("fromAPI", message => {
        let objFromServer = unflatten(message)
        let newPayloadHash = sum(objFromServer.payload)
        LOGGING && console.log("%s ws[%s] message received of type %s", logPrefix, objFromServer.socketId, objFromServer.type)
        if (lastPayloadHash !== newPayloadHash) {
            lastPayloadHash = newPayloadHash
        } else {
            return
        }

        switch (objFromServer.type) {
            case "TRADE_UPDATE": {

                if (vss.userIsScrolling) {
                    // console.log("\twsUpdate user is scrolling ABORT")
                    return
                } else {
                    // console.log("\twsUpdate user is NOT scrolling GO ON")
                }
                storeRef.dispatch({
                    type: GET_TRADE_UPDATE,
                    payload: objFromServer.payload
                }); break; }
            case "FUTURES_TRADE_UPDATE": {
                if (vss.userIsScrolling) {
                    // console.log("\twsUpdate user is scrolling ABORT")
                    return
                } else {
                    // console.log("\twsUpdate user is NOT scrolling GO ON")
                }
                storeRef.dispatch({
                    type: GET_FUTURES_TRADE_UPDATE,
                    payload: objFromServer.payload
                }); break; }
            case "SUC_UPDATE": {
                // LOGGING && console.log("%s ws SUC_UPDATE %s", logPrefix, JSON.stringify(objFromServer.payload))
                storeRef.dispatch({
                    type: GET_SUC_UPDATE,
                    payload: objFromServer.payload
                }); break; }
            case "SIGNAL_UPDATE": {
                LOGGING_SIGNAL_UPDATE && console.log("%s ws SIGNAL_UPDATE %s", logPrefix, JSON.stringify(objFromServer.payload))
                storeRef.dispatch({
                    type: SIGNALS_GET_UPDATE,
                    payload: objFromServer.payload
                }); break; }
            case "DEPOSIT_ADD_OR_UPDATE_DETECTED": {
                storeRef.dispatch({
                    type: DEPOSIT_ADD_OR_UPDATE,
                    payload: objFromServer.payload
                }); break; }
        }

    })
}

export const closeWS = () => {
    if (clientWebSocket) {
        console.log("%s << closeWS", logPrefix)
        clientWebSocket.close()
        clientWebSocket = undefined
    } else {
        console.log("%s << closeWS (clientWebSocket undefined)", logPrefix)
    }
}
