import React from "react";
import {TextField} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Class from '@material-ui/icons/Class'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(1),
        width: 280,
        backgroundColor: '#101A26'
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    }
}))

export default function SetTradingResearchDetails(props) {
    const {timeFrame, handleChangeTimeFrame, tvChartURL, handleChangeTVChartURL} = props
    const classes = useStyles()

    return (
    <div className={"wizardSectionContainer"}>
        <div className={"wizardTitle"}>
            <span className={"wizardTitleIcon"}><Class/></span> Trading research
        </div>
        <div style={{marginLeft: "0.6rem"}}>
            <TextField
                id="outlined-adornment-weight_set_trading_research_details"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                placeholder={"Time frame"}
                value={timeFrame}
                onChange={handleChangeTimeFrame}
            />
            <br/>
            <TextField
                id="outlined-adornment-weight_set_trading_research_details_2"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                placeholder={"Chart URL"}
                value={tvChartURL}
                onChange={handleChangeTVChartURL}
            />
        </div>
    </div>)
}
