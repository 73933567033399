import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet/es/Helmet";

const WHALogo = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    // Replace 'your-server-url' with the actual URL of your backend server
    fetch("/api/wha/getlogosize")
      .then(response => response.json())
      .then(data => {
        setWidth(data.width)
        setHeight(data.height)
      })

  }, []);

  return <img src={"/api/wha/getlogo"} alt={"Logo"} width={width} height={height}/>;
};

export default WHALogo;



