import React from 'react'
import s from '../../../settings'
import c from '../../../constants'
import NewBotFormMainButtons from "../../fncomponents/NewBotFormMainButtons"
import {Android, Help} from "@material-ui/icons"
import SelectPair from "../../fncomponents/SelectPair"
import TradeConditionsForBot from "../../fncomponents/TradeConditionsForBot"
import {TextField} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import SelectActionTypeRadios from "../../fncomponents/SelectActionTypeRadios"
import SetPosSize from "../../fncomponents/SetPosSize"
import BuySellForBot from "../../fncomponents/BuySellForBot"
import SimplePopOver from "../../fncomponents/SimplePopOver"

const useStyles = makeStyles(theme => ({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 360,
            backgroundColor: '#101A26'
        },
        dense: {
            marginTop: theme.spacing(2),
        },
        menu: {
            width: 200,
        },
        fab: {
            // margin: theme.spacing(1),
            marginLeft: theme.spacing(0.6),
            backgroundColor: '#101A26',
            border: "1px solid #ffffff",
            color: "#ffffff"
        },
        fabSelected: {
            // margin: theme.spacing(),
            marginLeft: theme.spacing(0.6),
            backgroundColor: '#ffffff',
            border: "1px solid #000000",
            color: "#000000"
        },
        switcher: {
            checked: {
                backgroundColor: "#17BEBB"
            }
        }}
))

export default function NewBotForm(props) {
    let {
            open, handleCancel, handlePreview,
            actionType, handleChangeActionType, tradeType,
            quote, handleChangeQuote, quoteCurrenciesNom,
            base, handleChangeBase, baseCurrenciesNom,
            selectPairErr,
            posSize, handleChangePosSize, posSizeErr,
            userBaseBalance, selectMaxBaseBalance,
            actionTypeOption, handleChangeActionTypeOption,
            currentLastBidPrice,
            stopLossTarget, handleChangeStopLossTarget,
            stopLossOption, handleChangeStopLossOption,
            takeProfitTarget, handleChangeTakeProfitTarget,
            takeProfitOption, handleChangeTakeProfitOption,
            botDbId, botId, botSecret, user,
            openHelp, openHelpAnchorEl, handleOpenHelp, handleCloseHelp,
        } = props

    const [labelForOpeningTrade, setLabelForOpeningTrade] = React.useState('')
    const [labelForClosingTrade, setLabelForClosingTrade] = React.useState('')

    React.useEffect(()=> {
        if (actionType === c.ACTION_TYPE_BUY) {
            setLabelForOpeningTrade('Message for opening trade (with a Buy)')
            setLabelForClosingTrade('Message for closing trade (with a Sell)')
        } else if (actionType === c.ACTION_TYPE_SELL) {
            setLabelForOpeningTrade('Message for opening trade (with a Sell)')
            setLabelForClosingTrade('Message for closing trade (with a Buy)')
        }
    })

    const classes = useStyles()

    if (!open)
        return null

    let commandOpen = {bot_id: botId, bot_secret: botSecret, command: 'open'}
    let commandClose = {bot_id: botId, bot_secret: botSecret, command: 'close'}

    return (
        <div key={"theForm"}>
            <div className={"vertSpacer2"}/>
            <div className={"wizardTitle"} style={{display: "flex", width: "17rem"}}>
                    <span className={"wizardTitleIcon"}><Android/></span>
                    {!botDbId && <span>New Bot for <b>Binance</b></span>}
                    {botDbId && <span>Update Bot for <b>Binance</b></span>}
                    <span onClick={handleOpenHelp} style={{display: "flex", alignItems: "center", justifyContent: "flex-end", width: "3rem"}} title={"Find out more"}><Help/></span>
            </div>
            <SimplePopOver open={openHelp} anchorEl={openHelpAnchorEl} content={<div style={{width: "18rem"}}>
                Designed to work with Trading View.<br/>
                It works as follows:
                <ul style={{width: "16rem"}}>
                    <li>you will have to define two <b>Alarms</b> per pair: one for opening a trade, and another one for closing a trade</li>
                    <li>you will paste the <b>Message for opening the trade</b> in the <b>Message</b> section of your 'Open Trade' Alarm</li>
                    <li>you will paste the <b>Message for closing the trade</b> in the <b>Message</b> section of your 'Close Trade' Alarm</li>
                </ul>
                As long as this bot will be active, it will open a trade (based on the parameters defined below) whenever an 'open trade' event will be received via the webhook.<br/>
                It will close the single active trade per bot, whenever it will receive a close trade event.<br/>
                It will open a new trade when it will receive the next open trade event, and so on.<br/>
                The bot won't open a new trade if two open trade events are received in succession.<br/>
                The only accepted events succession is: open trade event, close trade event, open trade event, and so on.
            </div>} handleClose={handleCloseHelp}/>
            <div className={"vertSpacer1_5"}/>
            <div className={"horizSeparator"}/>
            <div className={"vertSpacer1"}/>
            <SelectActionTypeRadios actionType={actionType} handleChangeActionType={handleChangeActionType} componentLabel={"Type"}/>
            <div className={"vertSpacer1_5"}/>
            <div className={"horizSeparator"}/>
            <div className={"vertSpacer1_5"}/>
            <SelectPair handleChangeQuote={handleChangeQuote}
                        handleChangeBase={handleChangeBase}
                        quote={quote}
                        base={base}
                        quoteCurrenciesNom={quoteCurrenciesNom}
                        baseCurrenciesNom={baseCurrenciesNom}
                        selectPairErr={selectPairErr}
                        botId={botDbId}
                        userBalance={userBaseBalance}
                        selectMaxBaseBalance={selectMaxBaseBalance}
            />
            <div className={"vertSpacer1_5"}/>
            <div className={"horizSeparator"}/>
            <div className={"vertSpacer1_5"}/>
            <SetPosSize posSize={posSize}
                        handleChangePosSize={handleChangePosSize}
                        quote={quote}
                        base={base}
                        actionType={actionType}
                        tradeType={tradeType}
                        hideVariants={true}
                        otherPosSizeErrors={posSizeErr}
            />
            <div className={"vertSpacer1_5"}/>
            <div className={"horizSeparator"}/>
            <div className={"vertSpacer1_5"}/>
            <BuySellForBot actionType={actionType}
                           tradeType={tradeType}
                           actionTypeOption={actionTypeOption}
                           handleChangeActionTypeOption={handleChangeActionTypeOption}
                           quote={quote}
                           base={base}
                           currentLastBidPrice={currentLastBidPrice}/>
            <div className={"vertSpacer1_5"}/>
            <div className={"horizSeparator"}/>
            <div className={"vertSpacer1_5"}/>
            <TradeConditionsForBot
                 stopLossOption={stopLossOption}
                 stopLossPriceOrPercent={stopLossTarget}
                 handleChangeStopLossOption={handleChangeStopLossOption}
                 handleChangeStopLossPriceOrPercent={handleChangeStopLossTarget}
                 base={base}

            />
            <div className={"vertSpacer1_5"}/>
            <div className={"horizSeparator"}/>
            <div className={"vertSpacer1_5"}/>
            <div>
                <TextField
                    className={classes.textField}
                    variant={"outlined"}
                    margin={"dense"}
                    id="input-with-icon-textfield_new_bot"
                    value={`${s.WEB_HOOK_BACKEND_TRADING_VIEW()}`}
                    disabled={true}
                    label={"Trading View Webhook url"}
                    helperText={"Paste this link in your Alarm Webhook section"}
                />
            </div>
            <div className={"vertSpacer1_5"}/>
            <div>
                <TextField
                    className={classes.textField}
                    variant={"outlined"}
                    margin={"dense"}
                    multiline
                    id="input-with-icon-textfield_new_bot_2"
                    value={JSON.stringify(commandOpen)}
                    disabled={true}
                    label={labelForOpeningTrade}
                    helperText={"Paste this text in your 'Open Trade' Alarm Message section"}
                />
            </div>
            <div className={"vertSpacer1_5"}/>
            <div>
                <TextField
                    className={classes.textField}
                    variant={"outlined"}
                    margin={"dense"}
                    multiline
                    id="input-with-icon-textfield_new_bot_3"
                    value={JSON.stringify(commandClose)}
                    disabled={true}
                    label={labelForClosingTrade}
                    helperText={"Paste this text in your 'Close Trade' Alarm Message section"}
                />
            </div>
            <div className={"vertSpacer1_5"}/>
            <div className={"horizSeparator"}/>
            <div className={"vertSpacer1"}/>
            <NewBotFormMainButtons handleCancel={handleCancel} handlePreview={handlePreview}/>
        </div>
    )
}
