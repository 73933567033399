import React from "react";
import { makeStyles } from '@material-ui/core/styles'
import TraderSubscription from "../../fncomponents/TraderSubscription"
import {Info} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        marginLeft: '1rem',
        marginBottom: '1rem'
    },
    button: {
        margin: theme.spacing(1),
    },
    typography: {
        padding: theme.spacing(2),
    },
}))

export default function TraderFollowerSubscriptions({
        traderSubscriptions, handleUnsubscribe, handleChangeAutoRenew, handleRemove, handleReSubscribe}) {

    return <div>
        <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start", marginTop: "1rem"}}><Info/>&nbsp;Your account can use only one subscription at a time, thus following only one trader.</div>
        <div className={"userTraderSubscriptionsContainer"}>
            {traderSubscriptions && traderSubscriptions.map((elem, index) => {
                return <TraderSubscription subscription={elem}
                                           handleUnsubscribe={handleUnsubscribe}
                                           handleChangeAutoRenew={handleChangeAutoRenew}
                                           handleRemove={handleRemove}
                                           handleReSubscribe={handleReSubscribe}
                                           shownToFollower={true} key={Math.random()}/>
            })}
        </div>
    </div>

}
