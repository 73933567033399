import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import c from "../../../../constants";
import { closeWS, initWS } from "../../../../redux/actions/wsPrices";
import SUCcard from "./SUCcard";

function SUCsList(props) {
  const runningSUCIds = useSelector((state) => state.sucs.runningSUCs?.map((suc) => suc.suc_id) || []);
  const closedSUCIds = useSelector((state) => state.sucs.closedSUCs?.map((suc) => suc.suc_id) || []);
  const activeSUCsNo = useSelector((state) => state.sucs.activeSUCsNo) || "";
  const closedSUCsNo = useSelector((state) => state.sucs.closedSUCsNo) || "";
  const user = useSelector((state) => state.authentication.user);

  // useEffect(() => {
  //   console.log(runningSUCIds, closedSUCIds);
  // }, [runningSUCIds, closedSUCIds]);

  useEffect(() => {
    if (user?.userId) {
      closeWS();
      initWS(user.userId);
    }
  }, [user]);

  return (
    <div>
      <div className={"vertSpacer"} />
      <div>
        {activeSUCsNo && props.type === c.RUNNING_SUCS_TAB && runningSUCIds?.map((sucId) => <SUCcard key={sucId} sucId={sucId} type="running" />)}
        {closedSUCsNo && props.type === c.CLOSED_SUCS_TAB && closedSUCIds?.map((sucId) => <SUCcard key={sucId} sucId={sucId} type="closed" />)}
        {!activeSUCsNo && props.type === c.RUNNING_SUCS_TAB && (
          <div style={{ textAlign: "center", marginTop: "2rem", opacity: "0.6" }}>
            You have no <b>R</b>unning signals...
          </div>
        )}
        {!closedSUCsNo && props.type === c.CLOSED_SUCS_TAB && (
          <div style={{ textAlign: "center", marginTop: "2rem", opacity: "0.6" }}>
            You have no <b>C</b>losed signals...
          </div>
        )}
      </div>
      <div className={"vertSpacer3"} />
    </div>
  );
}

export default SUCsList;
