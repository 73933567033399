import React, {Component} from 'react'
import {connect} from "react-redux"
import {debounce} from '../../hoc/myUtils'
import {getTitle} from "../../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"
import AffiliateCommissionsTable from "./AffiliateCommissionsTable"
import {ChevronLeft} from "@material-ui/icons"
import Button from "@material-ui/core/Button"
import {getAffiliateCommissionsByPage, getAffiliateCommissionsInitial} from "../../../redux/actions/profile"

class AffiliateCommissions extends Component {

    constructor(props) {
        super(props)
        this.state = {
            rows: [],
            page: 0,
            rowsPerPage: 10,
            filtersMap: {},
            sort: {}
        }

        this.setRowsPerPage = this.setRowsPerPage.bind(this)
        this.setPage = this.setPage.bind(this)
        this.onFilterChange = this.onFilterChange.bind(this)
        this.onFilter = this.onFilter.bind(this)
        this.debouncedFilter = debounce(this.onFilter, 500)
    }

    setRowsPerPage (value) {
        this.state.rowsPerPage = value
        this.state.page = 0
        this.props.getAffiliateCommissionsInitial({filters: this.state.filtersMap,
            sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
    }

    setPage (value) {
        let page = value
        if (!this.props.pagesDownloaded[page+1]) {
            this.state.page = page
            this.props.getAffiliateCommissionsByPage({filters: this.state.filtersMap,
                sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
        } else {
            this.setState({
                page: page
            })
        }
    }

    onFilterChange(e) {
        let filterEntry = {[e.target.name]: e.target.value}
        this.setState({
            filtersMap: {...this.state.filtersMap, ...filterEntry}
        })
        this.debouncedFilter()
    }

    onFilter() {
        this.props.getAffiliateCommissionsInitial({filters: this.state.filtersMap,
            sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
    }

    componentDidMount() {
        this.props.getAffiliateCommissionsInitial({filters: this.state.filtersMap,
            sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
    }

    render() {
        let {commissions, totalDocs, user} = this.props

        return (
            <div className={"AppMainContent"}>
                <div className={"vertSpacer2"}/>
                <Helmet>
                    <title>{getTitle()}</title>
                </Helmet>
                <Button onClick={()=>{this.props.history.push("/myprofile")}} color="primary"><ChevronLeft/> Your Profile</Button>
                <AffiliateCommissionsTable
                    rows={commissions}
                    rowsPerPage={this.state.rowsPerPage}
                    setRowsPerPage={this.setRowsPerPage}
                    page={this.state.page}
                    setPage={this.setPage}
                    totalDocs={totalDocs ? totalDocs : 0}
                    filtersMap={this.state.filtersMap}
                    onFilterChange={this.onFilterChange}
                />
            </div>
        )
    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    commissions: rootState.profile.commissions,
    totalDocs: rootState.profile.totalDocs,
    totalPages: rootState.profile.totalPages,
    pagesDownloaded: rootState.profile.pagesDownloaded,
})

let mapDispatchToProps = dispatch => {
    return {
        getAffiliateCommissionsInitial: (props) => dispatch(getAffiliateCommissionsInitial(props)),
        getAffiliateCommissionsByPage: (props) => dispatch(getAffiliateCommissionsByPage(props)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (AffiliateCommissions)
