import axios from "./axiosFabric"
import {handleError} from "./errorHandler"
import {
    CHANGE_SELECTED_SUCS_TAB, GET_ALL_SUCS, GET_JOIN_SIGNALS_GROUP_REQUEST_STATE,
    OPEN_SUC,
    UPDATE_SUC_CANCEL,
    UPDATE_SUC_CONDITIONS,
    UPDATE_SUC_CONNECTION_TO_SIGNAL, UPDATE_SUC_DISCARD,
    UPDATE_SUC_TRADE_CONDITIONS
} from "./actionTypes";
import {isAuthorizationHeaderPresent} from "./authentication";

export const getAllSUCs = (props) => async dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return

    axios.post("/sucs/getall", props).then(res => {
        dispatch({
            type: GET_ALL_SUCS,
            payload: res.data
        })
    })

}

export const changeSelectedSUCsTab = (newValue) => dispatch => {
    dispatch({
        type: CHANGE_SELECTED_SUCS_TAB,
        payload: newValue
    })
}

export const openSUC = (suc) => dispatch => {
    dispatch({
        type: OPEN_SUC,
        payload: suc
    })
}

export const updateSUCConnectionToSignal = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/sucs/updateconnectiontosignal`, props)
        .then(res => {
            dispatch({
                type: UPDATE_SUC_CONNECTION_TO_SIGNAL,
                payload: res.data
            })
            if (cb) cb(res.data)
        }).catch(error => handleError(error, dispatch))
}

export const updateSUCConditions = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/sucs/updateconditions`, props)
        .then(res => {
            dispatch({
                type: UPDATE_SUC_CONDITIONS,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const updateSUCTradeConditions = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/sucs/updatetradeconditions`, props)
        .then(res => {
            dispatch({
                type: UPDATE_SUC_TRADE_CONDITIONS,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const updateSUCCancelIt = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/sucs/cancel`, props)
        .then(res => {
            dispatch({
                type: UPDATE_SUC_CANCEL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const updateSUCDiscardIt = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/sucs/discard`, props)
        .then(res => {
            dispatch({
                type: UPDATE_SUC_DISCARD,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}


export const getUserJoinSignalsRequestState = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/profile/joinsignalsrequeststate`, props)
        .then(res => {
            dispatch({
                type: GET_JOIN_SIGNALS_GROUP_REQUEST_STATE,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const requestToJoinSignals = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/profile/joinsignalsrequeststate`, props)
        .then(res => {
            dispatch({
                type: GET_JOIN_SIGNALS_GROUP_REQUEST_STATE,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}
