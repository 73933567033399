import React, {useEffect} from "react"
import {Chip, FormControl, MenuItem, Paper, Select, TextField} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import InputAdornment from "@material-ui/core/InputAdornment"
import {TrendingUp, TrendingDown, Timeline} from "@material-ui/icons"
import Fab from '@material-ui/core/Fab'
import CustomSwitch from "./CustomSwitch";
import c from '../../constants'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 270,
        backgroundColor: '#101A26'
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    fab: {
        // margin: theme.spacing(1),
        marginLeft: theme.spacing(0.6),
        backgroundColor: '#101A26',
        border: "1px solid #ffffff",
        color: "#ffffff"
    },
    fabSelected: {
        // margin: theme.spacing(),
        marginLeft: theme.spacing(0.6),
        backgroundColor: '#ffffff',
        border: "1px solid #000000",
        color: "#000000"
    },
    switcher: {
        checked: {
            backgroundColor: "#17BEBB"
        }
    }}
))

const MAX_TAKE_PROFIT_TARGETS = 2

export default function SignalTradeConditions(props) {
    const {
            stopLossTarget, handleChangeStopLossTarget,
            stopLossOption, handleChangeStopLossOption,
            switchWithOrWithoutStopLossTarget,
            takeProfitTargets, handleChangeTakeProfitTarget,
            takeProfitOption, handleChangeTakeProfitOption,
            addTakeProfitTarget, removeTakeProfitTarget,
            takeProfitErr, stopLossErr,
            base,
            potentialProfit, potentialLoss,
            riskRewardRatio, averagePosSize,
            candlesType, handleChangeCandlesType
    } = props

    const [withStopLossTarget, setWithStopLossTarget] = React.useState(true)

    const classes = useStyles()

    function getTakeProfitInputAdorment() {
        switch (takeProfitOption) {
            case c.TAKE_PROFIT_OPTION_PERCENTAGE: return "%";
            case c.TAKE_PROFIT_OPTION_PRICE: return base;

        }
    }

    function getStopLossInputAdorment() {
        switch (stopLossOption) {
            case c.STOP_LOSS_OPTION_PERCENTAGE: return "%";
            case c.STOP_LOSS_OPTION_PRICE: return base;

        }
    }

    function getStyleByStopLossTab(_stopLossTab) {
        if ( (_stopLossTab === 0 &&
            (stopLossOption === c.STOP_LOSS_OPTION_PRICE ||
                stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE ||
                stopLossOption === c.STOP_LOSS_OPTION_TRAILING)) ||
            (_stopLossTab === 1 && stopLossOption === c.STOP_LOSS_OPTION_CONDITIONAL)) {
            return {backgroundColor: "#ffffff", color: "#000000"}
        }
    }

    React.useEffect(() => {
        setWithStopLossTarget(stopLossOption !== c.STOP_LOSS_OPTION_MANUAL)
    })

    return (<div className={"wizardSectionContainer"}>
        <div className={"wizardTitle"}>
            <span className={"wizardTitleIcon"}><Timeline/></span> Trade conditions
        </div>
        <div className={"signalIndicatorsSeparator"}/>
        <div className={"signalIndicatorsContainer"}>
            <div className={"signalIndicatorsLabel"}>Potential profit<sup>*</sup></div><div className={"signalIndicatorsValue"}>+{potentialProfit} {base}</div>
        </div>
        <div className={"signalIndicatorsContainer"}>
            <div className={"signalIndicatorsLabel"}>Potential loss<sup>*</sup></div><div className={"signalIndicatorsValue"}>-{potentialLoss} {base}</div>
        </div>
        <div className={"signalIndicatorsContainer"}>
            <div className={"signalIndicatorsLabel"}>Risk/Reward ratio<sup>*</sup></div><div className={"signalIndicatorsValue"}><b>{riskRewardRatio}</b>:1</div>
        </div>
        <div className={"signalIndicatorsObs"}><sup>*</sup>For an average position size of {averagePosSize} {base}</div>
        <div className={"signalIndicatorsSeparator"}/>
        <div id={"takeProfit"} style={{marginTop: "1em", maxWidth: "27em"}}>
            {takeProfitErr && <div className={"errorContainer"} style={{marginLeft: "0.6rem"}}>{takeProfitErr}</div>}
            <div>
                <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8"}}>Take  profit targets (max {MAX_TAKE_PROFIT_TARGETS})</div>
                {takeProfitTargets.map((takeProfitTarget, index) =>
                    <div style={{display: "flex"}}>
                        <div style={{marginTop: "1rem"}}>
                            {index < 10 && `#0${index+1}:`}
                            {index >= 10 && `#${index+1}:`}
                        </div>
                        <TextField
                            className={classes.textField}
                            variant={"outlined"}
                            margin={"dense"}
                            id="input-with-icon-textfield_signal_trade_cond"
                            value={takeProfitTarget}
                            onChange={(e)=>{handleChangeTakeProfitTarget(e, index)}}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <TrendingUp/>
                                        {(takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE) && <span>&nbsp;+</span>}
                                    </InputAdornment>
                                ),
                                endAdornment: <InputAdornment position="end">{getTakeProfitInputAdorment()}</InputAdornment>
                            }}
                            inputProps={{ maxLength: 16 }}
                        />
                        {(index < takeProfitTargets.length - 1)  &&
                        <Fab color={"default"} aria-label="Add" className={classes.fab} variant={"extended"}
                             onClick={() => {removeTakeProfitTarget(index)}}>-</Fab>}
                        {(index === takeProfitTargets.length - 1) &&
                            <div>
                                {index > 0 && <Fab color={"default"} aria-label="Add" className={classes.fab} variant={"extended"}
                             onClick={() => {removeTakeProfitTarget(index)}}>-</Fab>}
                                {index < (MAX_TAKE_PROFIT_TARGETS -1) && <Fab color={"default"} aria-label="Add" className={classes.fab} variant={"extended"}
                                 onClick={() => {addTakeProfitTarget()}}>+</Fab>}
                            </div>}
                    </div>
                )}
            </div>
            <div style={{marginTop: "1.05rem", width: "100%", display: "flex", justifyContent:"flex-begin"}}>
                <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8", marginTop: "1rem", marginRight: "0.8rem"}}>Take profit targets type</div>
                <Fab color={takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE? "primary": "default"} aria-label="Add"
                     className={takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE ? classes.fabSelected : classes.fab} variant={"extended"}
                     onClick={() => {handleChangeTakeProfitOption(c.TAKE_PROFIT_OPTION_PERCENTAGE)}}>
                    %
                </Fab>
                <Fab color={takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE? "primary": "default"} aria-label="Add"
                     className={takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE ? classes.fabSelected : classes.fab} variant={"extended"}
                     onClick={() => {handleChangeTakeProfitOption(c.TAKE_PROFIT_OPTION_PRICE)}}>
                    #
                </Fab>
            </div>
        </div>
        <div style={{marginLeft: "0em", marginTop: "1.6em"}}>
            With a stop loss target
            <CustomSwitch checked={withStopLossTarget} color="primary"
                          inputProps={{ 'aria-label': 'checkbox with default color' }}
                          onChange={switchWithOrWithoutStopLossTarget} classes={{checked: classes.switcher.checked}}
            />
        </div>
        {withStopLossTarget && <div id={"stopLoss"} style={{marginTop: "0.6em", maxWidth: "25em"}}>
            {candlesType && <div style={{marginBottom: "0.4rem"}}>
                <Chip
                    label={<div style={{display: "flex", alignItems: "center"}}>Simple</div>}
                    clickable
                    onClick={()=>{handleChangeStopLossOption(c.STOP_LOSS_OPTION_PRICE)}}
                    color="primary"
                    variant="outlined"
                    style={getStyleByStopLossTab(0)}
                />
                &nbsp;
                <Chip
                    label={<div style={{display: "flex", alignItems: "center"}}>Conditional</div>}
                    clickable
                    onClick={()=>{handleChangeStopLossOption(c.STOP_LOSS_OPTION_CONDITIONAL)}}
                    color="primary"
                    variant="outlined"
                    style={getStyleByStopLossTab(1)}
                />
            </div>}
            {stopLossErr && <div className={"errorContainer"} style={{marginLeft: "0.6rem"}}>{stopLossErr}</div>}
            {(stopLossOption === c.STOP_LOSS_OPTION_PRICE ||
                stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE ||
                stopLossOption === c.STOP_LOSS_OPTION_TRAILING) && <Paper>
                <div style={{padding: "0.6rem"}}>
                    <div>
                        <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8"}}>Stop loss target</div>
                        <TextField
                            className={classes.textField}
                            style={{marginLeft: "2.36rem"}}
                            variant={"outlined"}
                            margin={"dense"}
                            id="input-with-icon-textfield_signal_trade_cond_2"
                            value={stopLossTarget}
                            onChange={handleChangeStopLossTarget}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <TrendingDown/>
                                        {(stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE) && <span>&nbsp;-</span>}
                                    </InputAdornment>
                                ),
                                endAdornment: <InputAdornment position="end">{getStopLossInputAdorment()}</InputAdornment>
                            }}
                            inputProps={{ maxLength: 16 }}
                        />
                    </div>
                    <div style={{marginTop: "1.05rem", width: "100%", display: "flex", justifyContent:"flex-begin"}}>
                        <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8", marginTop: "1rem", marginRight: "1.7rem"}}>Stop loss target type</div>
                        <Fab color={ (stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE)
                            ? "primary": "default"} aria-label="Add"
                             className={(stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE) ?
                                 classes.fabSelected: classes.fab} variant={"extended"}
                             onClick={() => {handleChangeStopLossOption(c.STOP_LOSS_OPTION_PERCENTAGE)}}>
                            %
                        </Fab>
                        <Fab color={stopLossOption === c.STOP_LOSS_OPTION_PRICE ? "primary": "default"}
                             aria-label="Add" className={stopLossOption === c.STOP_LOSS_OPTION_PRICE ? classes.fabSelected: classes.fab}
                             variant={"extended"}
                             onClick={() => {handleChangeStopLossOption(c.STOP_LOSS_OPTION_PRICE)}}>
                            #
                        </Fab>
                    </div>
                </div>
            </Paper>}
            {stopLossOption === c.STOP_LOSS_OPTION_CONDITIONAL && <Paper>
                <div style={{padding: "0.6rem"}}>
                    <div id={"stopLossConditional"} style={{display: "flex",marginTop: "1.6em",
                        justifyContent: "space-between", flexWrap: "wrap", maxWidth: "25em"}}>
                        <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8"}}>Stop loss price at candle close</div>
                        <TextField
                            className={classes.textField}
                            variant={"outlined"}
                            margin={"dense"}
                            id="input-with-icon-textfield_signal_trade_cond_3"
                            value={stopLossTarget}
                            onChange={handleChangeStopLossTarget}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <TrendingDown/>
                                    </InputAdornment>
                                ),
                                endAdornment: <InputAdornment position="end">{base}</InputAdornment>
                            }}
                            inputProps={{ maxLength: 16 }}
                        />
                    </div>
                    <div id={"stopLossConditional"} style={{marginTop: "0.6em", marginBottom: "1rem"}}>
                        <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8"}}>Candles</div>
                        <FormControl style={{marginLeft: "20px"}}>
                            <Select
                                inputProps={{
                                    name: 'candles',
                                    id: 'candles',
                                }}
                                onChange={handleChangeCandlesType}
                                value={candlesType}
                            >
                                <MenuItem value={1} key={Math.random()}>30m</MenuItem>
                                <MenuItem value={2} key={Math.random()}>1h</MenuItem>
                                <MenuItem value={3} key={Math.random()}>4h</MenuItem>
                                <MenuItem value={4} key={Math.random()}>8h</MenuItem>
                                <MenuItem value={5} key={Math.random()}>12h</MenuItem>
                                <MenuItem value={6} key={Math.random()}>24h</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </Paper>}
        </div>}
    </div>)
}
