import React, {Component} from 'react'
import c from '../../../constants'
import coinNamesNom from '../../../nom/coinNamesNom'
import EnhancedTableMarginCrossBalance from "../../fncomponents/EnhancedTableMarginCrossBalance";

class MarginCrossBalance extends Component {

    populateCoinNames (balance) {
        return balance.map(balanceEntry => {
            if (balanceEntry)
                balanceEntry.name = coinNamesNom[balanceEntry.coin]
            return balanceEntry
        })
    }

    render() {
        const columns = [
            { id: 'coin', numeric: false, disablePadding: true, label: 'Coin', pic: {class: 'coinLogo',
                    picAddress: (e) => {return `imgs/coins/${e.toUpperCase()}_${c.COIN_LOGOS_PIC_VERSION}.png`}} },
            { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
            { id: 'totalBalance', numeric: true, disablePadding: false, label: 'Total Balance' },
            { id: 'availableBalance', numeric: true, disablePadding: false, label: 'Available Balance' },
            { id: 'inOrderBalance', numeric: true, disablePadding: false, label: 'In Order' },
            { id: 'borrowedBalance', numeric: true, disablePadding: false, label: 'Borrowed' },
            { id: 'interestBalance', numeric: true, disablePadding: false, label: 'Interest' },
            { id: 'actions', numeric: false, disablePadding: false, label: 'Actions'}
        ]

        return <div>
            {this.props.totals &&
            <div style={{display: "flex", justifyContent: "flex-start", width: "100%", fontSize: "0.9rem"}}>
                <span style={{color: "gray"}}>Estimated value:</span>&nbsp;<b>{this.props.totals.BTC}</b>&nbsp;<span style={{color:"gray"}}>BTC&nbsp;${this.props.totals.USDT}</span></div>}
            <EnhancedTableMarginCrossBalance rows={this.props.balances ? this.populateCoinNames(this.props.balances) : []} columns={columns} actions={this.props.actions}/>
        </div>
    }
}


export default MarginCrossBalance
