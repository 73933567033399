import React, {Component} from 'react'
import {connect} from "react-redux"
import {Helmet} from "react-helmet/es/Helmet"
import {getTitle} from "../../fncomponents/PageTitleUtils"
import {buyTradersSubscription, getTrader, rateTrader} from "../../../redux/actions/traders"
import Button from "@material-ui/core/Button"
import {ChevronLeft, Email, Facebook, Http, Telegram, Twitter} from "@material-ui/icons"
import TraderSubscription from "../../fncomponents/TraderSubscription"
import Rating from "@material-ui/lab/Rating"
import c from '../../../constants'
import AlertDialog from "../../fncomponents/AlertDialog";
import CircularIndeterminate from "../../fncomponents/loading";

const qs = require("query-string")

class TraderPresentationPage extends Component {


    constructor(props) {
        super(props)
        this.state = {
            isSubscribeAlertDialogOpen: false
        }

        this.goBack = this.goBack.bind(this)
        this.handleSubscribe = this.handleSubscribe.bind(this)
        this.handleOpenSubscribeDialog = this.handleOpenSubscribeDialog.bind(this)
        this.handleCloseSubscribeDialog = this.handleCloseSubscribeDialog.bind(this)
        this.canUserRateTrader = this.canUserRateTrader.bind(this)
        this.rate = this.rate.bind(this)
    }

    rate(event, newValue) {
        this.props.rateTrader({traderId: this.state.traderId, rate: newValue})
    }

    goBack() {
        this.props.history.push(this.state.back)
    }

    handleSubscribe() {
        this.setState({
            isSubscribeAlertDialogOpen: false
        })
        this.props.buyTradersSubscription({_id: this.state.subscriptionId}, () => {
            this.props.history.push(`/confirmsub?subid=${this.state.subscriptionId}`)
        })
    }

    handleOpenSubscribeDialog(subscription) {
        let price = subscription.listPrice
        if (subscription.discounted)
            price = subscription.discountedPrice
        let periodicity = ''
        if (subscription.periodicity === c.TRADER_SUBSCRIPTION_PERIODICITY_MONTHLY) {
            periodicity = 'monthly'
        } else if (subscription.periodicity === c.TRADER_SUBSCRIPTION_PERIODICITY_QUARTERLY) {
            periodicity = 'quarterly'
        } else if (subscription.periodicity === c.TRADER_SUBSCRIPTION_PERIODICITY_YEARLY) {
            periodicity = 'yearly'
        }

        let subscribeTexts = undefined
        if (subscription.free) {
            subscribeTexts = [
                `Are your sure you want to subscribe to ${subscription.name}?`
            ]
        } else {
            subscribeTexts = [
                `Are your sure you want to subscribe to ${subscription.name}?`,
                `Your account balance will be debited with ${price} ${subscription.priceCoin} ${periodicity}.`
            ]
        }

        this.setState({
            subscriptionId: subscription._id,
            isSubscribeAlertDialogOpen: true,
            subscribeTitle: `Subscribe to ${subscription.name}`,
            subscribeTexts
        })
    }

    handleCloseSubscribeDialog() {
        this.setState({
            isSubscribeAlertDialogOpen: false
        })
    }

    componentDidMount() {
        this.state.f = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).f

        if ("es3" === this.state.f) {
            this.setState({
                back: "/trader/mypage"
            })
        } else {
            this.setState({
                back: "/traders"
            })
        }
        this.state.traderId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).tid
        this.props.getTrader({_id: this.state.traderId})
    }


    canUserRateTrader(user, trader) {
        if (!user)
            return false
        if (!trader)
            return false
        if (!trader.subscriptions)
            return false
        if (trader.subscriptions.length === 0)
            return false
        if (!user.sucsByTraders)
            return false
        if (user.sucsByTraders[trader.trader._id] < c.LIMIT_NO_SUCS_TO_RECEIVE_BEFORE_GIVING_RATES)
            return false
        for(let i = 0; i < trader.subscriptions.length; i++) {
            if (user.subscriptionsMap[trader.subscriptions[i]._id]) {
                if (trader.trader.ratings) {
                    if (trader.trader.ratings.voters) {
                        if (!trader.trader.ratings.voters[user._id]) {
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return true
                    }
                } else {
                    return true
                }
            }
        }
        return false
    }


    render() {


        let {trader, user} = this.props
        let _t = trader ? trader.trader: undefined

        if (!_t || _t && _t._id !== this.state.traderId) {
            //Means its the old trader still loaded
            return <div className={"AppMainContent"}>
                <Helmet>
                    <title>{getTitle()}</title>
                </Helmet>
                <div className={"loadingContainer"}>
                    <div className="loading">
                        <CircularIndeterminate/>
                    </div>
                    <div className={"loadingText"}>Open trader...</div>
                </div>
            </div>
        }
        let _s = trader.subscriptions
        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <Button onClick={this.goBack} color="primary" variant="outlined"><ChevronLeft/>
                {this.state.back === "/traders" && <span>Traders</span>}
                {this.state.back === "/trader/mypage" && <span>My Page</span>}
            </Button>

            {_t && <div style={{display: "flex", marginTop: "2rem"}}>
                <div>
                    <div style={{fontSize: "2rem", fontWeight: "bold"}}>
                        {_t.name}
                    </div>
                    {_t && _t.ratings && <div style={{display: "flex"}}>
                        <div style={{fontSize: "1.3rem", fontWeight: "bold"}}>
                            {_t.ratings.value}
                        </div>
                        <div style={{marginLeft:"0.6rem", marginTop: "0.2rem"}}>
                            {this.canUserRateTrader(user, trader) && <Rating name="read-only" value={_t.ratings.value} readOnly={false}
                                    precision={0.5} max={_t.ratings.max} onChange={this.rate}/>}
                            {!this.canUserRateTrader(user, trader) && <Rating name="read-only" value={_t.ratings.value} readOnly={true}
                                                                             precision={0.5} max={_t.ratings.max}/>}

                        </div>
                        <div style={{fontSize: "0.8rem", marginLeft: "0.6rem", marginTop: "0.36rem"}}>{_t.ratings.votes} Ratings</div>
                    </div>}
                    {(_t && _t.followers >= 0) && <div style={{display: "flex"}}>
                        <div style={{fontSize: "1.3rem", fontWeight: "bold"}}>
                            {_t.followers}
                        </div>
                        <div style={{fontSize: "0.8rem", marginLeft: "0.6rem", marginTop: "0.36rem"}}>Followers</div>
                    </div>}
                    <div style={{display: "flex", width: "100%", justifyContent: "flex-start", marginTop: "0.4rem"}}>
                        {_t.email && <div><a href={`mailto: ${_t.email}`} target={"_blank"} title={_t.email}><Email/></a></div>}
                        {_t.twitter && <div><a href={`${_t.twitter}`} target={"_blank"} title={_t.twitter}><Twitter/></a></div>}
                        {_t.telegram && <div><a href={`${_t.telegram}`} target={"_blank"} title={_t.telegram}><Telegram/></a></div>}
                        {_t.facebook && <div><a href={`${_t.facebook}`} target={"_blank"} title={_t.facebook}><Facebook/></a></div>}
                        {_t.website && <div><a href={_t.website} target={"_blank"} title={_t.website}><Http/></a></div>}
                    </div>
                </div>
                {_t.logo && <div style={{marginLeft:"2rem"}}>
                    <img src={_t.logo.data} width={"100"}/>
                </div>}
            </div>}

            {_t && <div>
                <div className={"traderSalesPageSectionContainer"}>
                    <div className={"traderSalesPageAbout"}>
                        {_t.shortDescription}
                    </div>
                </div>
                <div className={"traderSalesPageSectionContainer"}>
                    <div className={"traderSalesPageAbout"}>
                        <div dangerouslySetInnerHTML={{ __html: _t.about }} />
                    </div>
                </div>
            </div>}
            {(_s && _s.length > 0) &&
            <div className={"traderSalesPageSectionContainer"}>
                <div className={"traderSalesPageSectionTitle"}>Subscriptions</div>
                <div className={"traderSalesPageSubscriptionsContainer"}>
                    {_s.map((elem, index) => {
                        return <TraderSubscription subscription={elem} key={Math.random()} handleSubscribe={this.handleOpenSubscribeDialog}
                                                   usersSubscriptionsMap={user.subscriptionsMap} user={user}/>
                    })}
                </div>
            </div>}
            <div className={"vertSpacer2"}/>
            <AlertDialog
                open={this.state.isSubscribeAlertDialogOpen}
                title={this.state.subscribeTitle}
                texts={this.state.subscribeTexts}
                handleOk={this.handleSubscribe}
                handleCancel={this.handleCloseSubscribeDialog}
                okLabel={"Go ahead"}
            />
        </div>
    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    trader: rootState.traders.trader,
})

let mapDispatchToProps = dispatch => {
    return {
        getTrader: (props) => dispatch(getTrader(props)),
        rateTrader: (props) => dispatch(rateTrader(props)),
        buyTradersSubscription: (props, cb) => dispatch(buyTradersSubscription(props, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (TraderPresentationPage)
