import React from 'react'
import c from '../../constants'
import s from '../../settings'
import moment, {max} from "moment";
import precisionByBase from '../../nom/PrecisionByBaseNom'

export function calculateJustAboveBidPrice(bidPrice) {
    if (!bidPrice)
        return 0
    let _bidPrice = parseFloat(bidPrice)
    let justAboveBidPrice = _bidPrice * 1.002
    return brushValue(justAboveBidPrice)
}

export function scientificToDecimal(number, fixed){
    number = `${number}`
    let numberHasSign = number.startsWith("-") || number.startsWith("+");
    let sign = numberHasSign ? number[0] : "";
    number = numberHasSign ? number.replace(sign, "") : number;

    //if the number is in scientific notation remove it
    if (/\d+\.?\d*e[\+\-]*\d+/i.test(number)) {
        let zero = '0';
        let parts = String(number).toLowerCase().split('e'); //split into coeff and exponent
        let e = parts.pop();//store the exponential part
        let l = Math.abs(e); //get the number of zeros
        let sign = e / l;
        let coeff_array = parts[0].split('.');

        if (sign === -1) {
            coeff_array[0] = Math.abs(coeff_array[0]);
            number = zero + '.' + new Array(l).join(zero) + coeff_array.join('');
        } else {
            let dec = coeff_array[1];
            if (dec) l = l - dec.length;
            number = coeff_array.join('') + new Array(l + 1).join(zero);
        }
    }

    if (fixed) {
        return parseFloat(`${sign}${number}`).toFixed(fixed)
    }

    return `${sign}${number}`;
}

let isNumberInScientificNotation = number => {
    if (/\d+\.?\d*e[\+\-]*\d+/i.test(number)) {
        return true
    }
    return false
}

export function addCommasToLeftSideOfTheNumber(number) {
    let segments = `${number}`.split('.')
    let beforeDot = segments[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    let afterDot = segments[1]
    if (!afterDot) {
        return beforeDot
    } else {
        return `${beforeDot}.${afterDot}`
    }
}

export function removeInsignificantZeroes(number) {
    if (isNaN(number))
        return 0
    let _number = parseFloat(number)
    let noZeroes = _number.toString()
    return noZeroes
}

let truncateToMaxDigits = (number = "0", maxDigits = 0) => {
    if (number.length > (maxDigits + 2)) {
        maxDigits = maxDigits < 0 ? 0: maxDigits
        return parseFloat(number).toFixed(maxDigits)
    }
    return number
}

export function formatNumberWithCommas(value, tickSize) {
    let q = parseInt(value)
    if (q > 0) {
        let parts = `${value}`.split(".")
        let partOne = new Intl.NumberFormat("us-US", {currency: "USD"}).format(parts[0])
        let partTwo = parts[1] ? `.${parts[1]}`: ""
        return `${partOne}${partTwo}`
    }
    return value
}

export function brushValue(value, maxDigits=8, removeInsignificantZeros = true) {
    if (value && value !== "undefined") {
        maxDigits = parseInt(`${maxDigits}`)
        if (isNaN(maxDigits))
            maxDigits = 8
        let number = `${value}`
        //Remove scientific notation if it's the case
        if (isNumberInScientificNotation(number)) {
            number = scientificToDecimal(number)
        }
        //Truncate no to maxDigits (+2 for the 0.)
        number = truncateToMaxDigits(number, maxDigits)
        if (removeInsignificantZeros) number = removeInsignificantZeroes(number)
        //Remove again the scientific notation (it might be the case after many parseFloat, toFixed, etc)
        if (isNumberInScientificNotation(number)) {
            number = scientificToDecimal(number)
        }
        //number = addCommasToLeftSideOfTheNumber(number)
        return number
    } else return "0"
}

export function brushValue2(value, maxDigits=8, removeInsignificantZeros = true) {
    if (value && value !== "undefined") {
        let number = `${value}`
        //Remove scientific notation if it's the case
        if (isNumberInScientificNotation(number)) {
            number = scientificToDecimal(number)
        }
        let segments = number.split(".")
        let finalNumber = `${segments[0]}`
        if (segments[1]) {
            finalNumber += "."
            let max = segments[1].length > maxDigits ? maxDigits : segments[1].length
            for(let i = 0; i < max; i++) {
                finalNumber += segments[1][i]
            }
        }
        if (removeInsignificantZeros)
            finalNumber = removeInsignificantZeroes(finalNumber)
        return finalNumber
    } else return "0"
}

export function getQuoteAndBase(record) {
    if (!record)
        return {quote: "", base: ""}
    let p = record.pairSlash
    if (p && p.length > 0) {
        let quote = p.substring(0, p.indexOf("/"))
        let base = p.substring(p.indexOf("/") + 1, p.length)
        return {quote: quote, base: base}
    }
    return {quote: "", base: ""}
}

export function getCurrentAndBuyPriceForBTD(btd) {
    if (!btd)
        return {currentPrice: 0, buyPrice: 0}

    let buyPrice = brushValue(btd.dip_price, 8)
    let currentPrice = brushValue(btd.current_price, 8)

    return {currentPrice: currentPrice,
        buyPrice: buyPrice}
}

let getCalculatedBuyPrice = trade => {
    let sCummulativeQuoteQty = 0
    let sExecutedQty = 0
    if (trade.parent_orders) {
        for (let i in trade.parent_orders) {
            let o = trade.parent_orders[i]
            sCummulativeQuoteQty += parseFloat(o.cummulative_quote_qty)
            sExecutedQty += parseFloat(o.executed_qty)
        }
    }
    let onTradeRemainingQty = parseFloat(trade.executed_qty) - sExecutedQty
    let onTradeRemainingQuoteQty = parseFloat(trade.pos_size) - sCummulativeQuoteQty
    sCummulativeQuoteQty += onTradeRemainingQuoteQty
    sExecutedQty += onTradeRemainingQty
    return sCummulativeQuoteQty / sExecutedQty
}

let getCalculatedBuyPriceFutures = trade => {
    let sCumQuote = 0
    let sExecutedQty = 0
    if (trade.parent_orders) {
        for (let i in trade.parent_orders) {
            let o = trade.parent_orders[i]
            sCumQuote += parseFloat(o.cum_quote)
            sExecutedQty += parseFloat(o.executed_qty)
        }
    }
    let onTradeRemainingQty = parseFloat(trade.executed_qty) - sExecutedQty
    let onTradeRemainingQuoteQty = parseFloat(trade.notional_value_ax) - sCumQuote
    sCumQuote += onTradeRemainingQuoteQty
    sExecutedQty += onTradeRemainingQty
    return sCumQuote / sExecutedQty
}

let getCalculatedBuyPriceForClosed = trade => {
    let sCummulativeQuoteQty = 0
    let sExecutedQty = 0
    if (trade.parent_orders) {
        for (let i in trade.parent_orders) {
            let o = trade.parent_orders[i]
            sCummulativeQuoteQty += parseFloat(o.cummulative_quote_qty)
            sExecutedQty += parseFloat(o.executed_qty)
        }

        //Meaning what was paid for the last parent (the one that is not stored in the parent_orders array)
        let lastParentCummulativeQuote = parseFloat(trade.cummulative_quote_qty)
        sCummulativeQuoteQty += lastParentCummulativeQuote

        if (trade.status === "FILLED") {
            //The total executed qty is stored on the trade if the trade is FILLED
            sExecutedQty = parseFloat(trade.executed_qty)
        } else {
            //Else keep the old fashion
            sExecutedQty += parseFloat(trade.executed_qty)
        }
    } else {
        sCummulativeQuoteQty += parseFloat(trade.cummulative_quote_qty)
        sExecutedQty += parseFloat(trade.executed_qty)
    }
    return sCummulativeQuoteQty / sExecutedQty
}

let getCalculatedSellPrice = trade => {
    let sCummulativeQuoteQty = 0
    let sExecutedQty = 0
    if (trade.parent_orders) {
        for (let i in trade.parent_orders) {
            let o = trade.parent_orders[i]
            sCummulativeQuoteQty += parseFloat(o.cummulative_quote_qty)
            sExecutedQty += parseFloat(o.executed_qty)
        }
    }

    let onTradeRemainingQty = parseFloat(trade.executed_qty) - sExecutedQty
    let onTradeRemainingQuoteQty = parseFloat(trade.cummulative_quote_qty) - sCummulativeQuoteQty
    sCummulativeQuoteQty += onTradeRemainingQuoteQty
    sExecutedQty += onTradeRemainingQty
    return sCummulativeQuoteQty / sExecutedQty
}

let getCalculatedSellPriceForClosed = trade => {
    let sCummulativeQuoteQty = 0
    let sExecutedQty = 0
    if (trade.parent_orders) {
        for (let i in trade.parent_orders) {
            let o = trade.parent_orders[i]
            sCummulativeQuoteQty += parseFloat(o.cummulative_quote_qty)
            sExecutedQty += parseFloat(o.executed_qty)
        }
    }

    sCummulativeQuoteQty += parseFloat(trade.cummulative_quote_qty)
    sExecutedQty += parseFloat(trade.executed_qty)
    return sCummulativeQuoteQty / sExecutedQty
}

function hasChild(trade) {
    if (trade.child_order && trade.child_order.order_id) {
        return true
    }
    return false
}

function isActive(trade) {
    if (trade.child_order.status === c.ORDER_STATUS_NEW || trade.child_order.status === c.ORDER_STATUS_PARTIALLY_FILLED) {
        return true
    }
    return false
}

function isClosed(trade) {
    if (trade.child_order.status === c.ORDER_STATUS_FILLED || trade.child_order.status === c.ORDER_STATUS_CANCELED ||
        trade.child_order.status === c.ORDER_STATUS_ERROR) {
        return true
    }
    return false
}

export function getCurrentBuyAndSellPrice(trade) {
    if (!trade)
        return {currentPrice: 0, buyPrice: 0, sellPrice: 0}

    let tickSize = parseInt(trade.tick_size)
    //Extracting Buy Price
    let buyPrice = 0
    if (trade.action_type === c.ACTION_TYPE_BUY) {
        if (hasChild(trade)) {
            if (isActive(trade)) {
                //It means that this is an active trade and we'll have to show a calculated buy_price
                buyPrice = brushValue(getCalculatedBuyPrice(trade), tickSize)
            } else if (isClosed(trade)) {
                buyPrice = brushValue(getCalculatedBuyPriceForClosed(trade), tickSize)
            }
        } else {
            //Return last buy price based on user options action_type_options
            if (trade.action_type_option === c.ACTION_TYPE_OPTION_FIXED_PRICE ||
                trade.action_type_option === c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE) {
                buyPrice = brushValue(trade.buy_price, tickSize)
            } else if (trade.action_type_option === c.ACTION_TYPE_OPTION_MARKET) {
                buyPrice = brushValue(trade.cummulative_quote_qty / trade.executed_qty, tickSize)
            }
        }
    }

    //Extracting Sell Price
    let sellPrice = 0
    if (trade.action_type === c.ACTION_TYPE_SELL) {
        if (hasChild(trade)) {

            if (isActive(trade)) {
                //It means that this is an active trade and we'll have to show a calculated sell_price
                sellPrice = getCalculatedSellPrice(trade)
            } else if (isClosed(trade)) {
                sellPrice = brushValue(getCalculatedSellPriceForClosed(trade), tickSize)
            }
        } else {
            //Return last sell price based on user options action_type_options
            if (trade.action_type_option === c.ACTION_TYPE_OPTION_FIXED_PRICE  ||
                trade.action_type_option === c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE) {
                sellPrice = brushValue(trade.sell_price, tickSize)
            } else if (trade.action_type_option === c.ACTION_TYPE_OPTION_MARKET) {
                sellPrice = brushValue(trade.cummulative_quote_qty / trade.executed_qty, tickSize)
            }
        }
    }

    //Extracting Current Price
    let currentPrice = undefined
    if (trade.child_order && trade.child_order.order_id) {
        currentPrice = brushValue(trade.child_order.current_price, tickSize)
    } else {
        currentPrice = brushValue(trade.current_price, tickSize)
    }
    return {currentPrice, buyPrice, sellPrice}
}

export function getBuyAndSellPrice(trade) {
    if (!trade)
        return {buyPrice: 0, sellPrice: 0}

    let tickSize = parseInt(trade.tick_size)
    //Extracting Buy Price
    let buyPrice = 0
    if (trade.action_type === c.ACTION_TYPE_BUY) {
        if (hasChild(trade)) {
            //It means that this is an active trade and we'll have to show a calculated buy_price
            buyPrice = brushValue(getCalculatedBuyPrice(trade), tickSize)
        } else {
            //Return last buy price based on user options action_type_options
            if (trade.action_type_option === c.ACTION_TYPE_OPTION_FIXED_PRICE ||
                trade.action_type_option === c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE) {
                buyPrice = brushValue(trade.buy_price, tickSize)
            } else if (trade.action_type_option === c.ACTION_TYPE_OPTION_MARKET) {
                buyPrice = brushValue(trade.cummulative_quote_qty / trade.executed_qty, tickSize)
            }
        }
    }

    //Extracting Sell Price
    let sellPrice = 0
    if (trade.action_type === c.ACTION_TYPE_SELL) {
        if (hasChild(trade)) {
            //It means that this is an active trade and we'll have to show a calculated sell_price
            sellPrice = brushValue(getCalculatedSellPrice(trade), tickSize)
        } else {
            //Return last sell price based on user options action_type_options
            if (trade.action_type_option === c.ACTION_TYPE_OPTION_FIXED_PRICE  ||
                trade.action_type_option === c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE) {
                sellPrice = brushValue(trade.sell_price, tickSize)
            } else if (trade.action_type_option === c.ACTION_TYPE_OPTION_MARKET) {
                sellPrice = brushValue(trade.cummulative_quote_qty / trade.executed_qty, tickSize)
            }
        }
    }

    return {buyPrice, sellPrice}
}

export function getEnterTradePriceForFutures(futuresTrade) {
    if (!futuresTrade)
        return 0

    let tickSize = parseInt(futuresTrade.tick_size)
    if (hasChild(futuresTrade)) {
        let q = parseFloat(futuresTrade.avg_price)
        if (q === 0 || isNaN(q)) {
            return brushValue(futuresTrade.price, tickSize)
        }
        return brushValue(futuresTrade.avg_price, tickSize)
    } else {
        return brushValue(futuresTrade.price, tickSize)
    }

    // let tickSize = parseInt(futuresTrade.tick_size)
    // //Extracting Buy Price
    // let buyPrice = 0

    // if (hasChild(futuresTrade)) {
    //     //It means that this is an Open/Closed Position and we'll have to show a calculated buy_price
    //     buyPrice = brushValue(getCalculatedBuyPriceFutures(futuresTrade), tickSize)
    // } else {
    //     //Return last buy price based on user options action_type_options
    //     if (futuresTrade.action_type_option === c.FUTURES_ACTION_TYPE_OPTION_FIXED_PRICE ||
    //         futuresTrade.action_type_option === c.FUTURES_ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE ||
    //         futuresTrade.action_type_option === c.FUTURES_ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE) {
    //         buyPrice = brushValue(futuresTrade.buy_price, tickSize)
    //     } else if (futuresTrade.action_type_option === c.FUTURES_ACTION_TYPE_OPTION_MARKET) {
    //         let notional_value_ax = parseFloat(futuresTrade.notional_value_ax)
    //         let executed_qty = parseFloat(futuresTrade.executed_qty)
    //         if (executed_qty > 0) {
    //             buyPrice = brushValue( notional_value_ax/ executed_qty, tickSize)
    //         } else {
    //             buyPrice = 0
    //         }
    //
    //     }
    // }

    // return buyPrice
}

export function getCurrentPrice(trade) {
    if (!trade)
        return 0

    let tickSize = parseInt(trade.tick_size)
    //Extracting Current Price
    let currentPrice = undefined
    if (trade.child_order && trade.child_order.order_id) {
        if (trade.child_order.status === "FILLED") {
            currentPrice = trade.child_order.avg_price
            let currentPriceF = parseFloat(trade.child_order.avg_price)
            currentPrice = (isNaN(currentPrice) || currentPriceF === 0) ? trade.child_order.current_price : currentPrice
        } else {
            currentPrice = trade.child_order.current_price
        }
    } else {
        currentPrice = trade.current_price
    }
    return brushValue(currentPrice, tickSize)
}

export function getProgress(trade) {
    let origQty = parseFloat(trade.orig_qty)
    let executed_qty = 0
    if (!trade.children) {
        if (trade.child_order) {
            executed_qty = trade.child_order.executed_qty ? parseFloat(trade.child_order.executed_qty) : 0
        } else {
            executed_qty = 0
        }

    } else {
        executed_qty = parseFloat(trade.child_order.executed_qty_sum) + parseFloat(trade.child_order.executed_qty)
    }
    let percent = (executed_qty / origQty) * 100
    return percent.toFixed(0)
}

export function getProgressForFuturesOpenedPosition(futuresTrade) {
    let origQty = parseFloat(futuresTrade.orig_qty)
    let executed_qty = 0
    if (!futuresTrade.children) {
        if (futuresTrade.child_order) {
            executed_qty = futuresTrade.child_order.executed_qty ? parseFloat(futuresTrade.child_order.executed_qty) : 0
        } else {
            executed_qty = 0
        }

    } else {
        executed_qty = parseFloat(futuresTrade.child_order.executed_qty_sum) + parseFloat(futuresTrade.child_order.executed_qty)
    }
    let percent = (executed_qty / origQty) * 100
    return percent.toFixed(0)
}

export function getProgressForWaitingTrade(trade) {
    let origQty = parseFloat(trade.orig_qty)
    let executed_qty = parseFloat(trade.executed_qty)
    let percent = 0
    try {
        percent = (executed_qty / origQty) * 100
        if (isNaN(percent))
            percent = 0
    } catch (err) {}
    return percent.toFixed(0)
}

export function getCurrentPriceAndDeltaPercent(tradeBTDOrderOrSUC, isSUC = false) {
    if (!tradeBTDOrderOrSUC)
        return {
            trend: 0,
            currentPriceDeltaPercent: 0,
            currentPrice: 0,
            currentPriceClass: ""
        }
    let tickSize = parseInt(tradeBTDOrderOrSUC.tick_size)
    let trend = 0

    let tradeEntryPrice = undefined
    if (tradeBTDOrderOrSUC.buy_the_dip_id) {
        tradeEntryPrice = parseFloat(tradeBTDOrderOrSUC.dip_price)
    } else {
        if (!tradeBTDOrderOrSUC.signal_type || tradeBTDOrderOrSUC.signal_type == c.SIGNAL_TYPE_SPOT) {
            if (tradeBTDOrderOrSUC.action_type === c.ACTION_TYPE_BUY) {
                if (isSUC) {
                    tradeEntryPrice = parseFloat(tradeBTDOrderOrSUC.buy_price)
                } else {
                    //Before was used buy_price, but now is used buyPrice which is the buyPrice calculated on the
                    //UI in UISupportFns.getBuyAndSellPrice
                    //tradeEntryPrice = parseFloat(tradeBTDOrderOrSUC.buy_price)
                    tradeEntryPrice = parseFloat(tradeBTDOrderOrSUC.buyPrice)
                }

            } else if (tradeBTDOrderOrSUC.action_type === c.ACTION_TYPE_SELL) {
                if (isSUC) {
                    tradeEntryPrice = parseFloat(tradeBTDOrderOrSUC.sell_price)
                } else {
                    //Before was used sell_price, but now is used sellPrice which is the sellPrice calculated on the
                    //UI in UISupportFns.getBuyAndSellPrice
                    // tradeEntryPrice = parseFloat(tradeBTDOrderOrSUC.sell_price)
                    tradeEntryPrice = parseFloat(tradeBTDOrderOrSUC.sellPrice)
                }
            }
        } else if (tradeBTDOrderOrSUC.signal_type == c.SIGNAL_TYPE_FUTURES) {
            tradeEntryPrice = parseFloat(tradeBTDOrderOrSUC.entry_price)
        }
    }

    let currentPrice = undefined
    let currentPriceOriginal = undefined
    if (tradeBTDOrderOrSUC.child_order && tradeBTDOrderOrSUC.child_order.order_id) {
        currentPrice = parseFloat(tradeBTDOrderOrSUC.child_order.current_price)
        currentPriceOriginal = tradeBTDOrderOrSUC.child_order.current_price
    } else {
        currentPrice = parseFloat(tradeBTDOrderOrSUC.current_price)
        currentPriceOriginal = tradeBTDOrderOrSUC.current_price
    }

    //If tradeBTDOrderOrSUC hasn't been updated with current_price yet...this is the case
    //in the first millis from its launch.
    currentPrice = currentPrice ? currentPrice: tradeEntryPrice

    if (tradeEntryPrice > currentPrice) {
        trend = -1
    } else if (tradeEntryPrice <= currentPrice) {
        trend = 1
    }

    let currentPriceDeltaPercent = ((currentPrice - tradeEntryPrice) / tradeEntryPrice) * 100
    let currentPriceClass = "currentPriceInline"

    if (tradeBTDOrderOrSUC.action_type === c.ACTION_TYPE_SELL) {
        trend *= -1
        currentPriceDeltaPercent *= -1
    }

    if (trend > 0) {
        currentPriceClass = "currentPriceUp"
    } else if (trend < 0) {
        currentPriceClass = "currentPriceDown"
    }
    if (isNaN(currentPriceDeltaPercent))
        currentPriceDeltaPercent = 0
    return {
        trend,
        currentPriceDeltaPercent: (trend > 0? '+': '') + currentPriceDeltaPercent.toFixed(2),
        currentPrice: brushValue(currentPriceOriginal, tickSize),
        currentPriceClass
    }
}

export function getCurrentPriceAndDeltaPercentForFutures(futuresTrade, isOrder) {
    if (!futuresTrade)
        return {
            trend: 0,
            pnl: 0,
            currentPriceDeltaPercent: 0,
            currentPrice: 0,
            currentPriceClass: ""
        }
    let tickSize = parseInt(futuresTrade.tick_size)
    let trend = 0



    let tradeEntryPrice = 0
    if (!hasChild(futuresTrade)) {
        tradeEntryPrice = parseFloat(futuresTrade.price)
    } else {
        tradeEntryPrice = parseFloat(futuresTrade.avg_price)
        if (tradeEntryPrice === 0 || isNaN(tradeEntryPrice)) {
            tradeEntryPrice = parseFloat(futuresTrade.price)
        }
    }

    let currentPrice = undefined
    if (hasChild(futuresTrade)) {
        if (futuresTrade.child_order.status === "FILLED") {
            currentPrice = parseFloat(futuresTrade.child_order.avg_price)
            currentPrice = (isNaN(currentPrice) || currentPrice === 0) ? parseFloat(futuresTrade.child_order.current_price) : currentPrice
        } else {
            currentPrice = parseFloat(futuresTrade.child_order.current_price)
        }
    } else {
        currentPrice = parseFloat(futuresTrade.current_price)
    }

    //If futuresTrade hasn't been updated with current_price yet...this is the case
    //in the first millis from its launch.
    currentPrice = currentPrice ? currentPrice: tradeEntryPrice
    if (tradeEntryPrice > currentPrice) {
        trend = -1
    } else if (tradeEntryPrice <= currentPrice) {
        trend = 1
    }

    let leverage = !isOrder ? parseFloat(futuresTrade.leverage): 1
    let currentPriceDeltaPercent = (currentPrice - tradeEntryPrice) / tradeEntryPrice * leverage
    let pnl = (currentPriceDeltaPercent * parseFloat(futuresTrade.cost)).toFixed(tickSize)
    pnl = !isNaN(pnl) ? pnl: 0
    currentPriceDeltaPercent = currentPriceDeltaPercent * 100 //so that we'll show a percent
    let currentPriceClass = "currentPriceInline"

    if (futuresTrade.futures_type === c.FUTURES_TYPE_SHORT_s) {
        if (hasChild(futuresTrade)) {
            trend *= -1
            currentPriceDeltaPercent *= -1
        }
        pnl *= -1
    }

    if (trend > 0) {
        currentPriceClass = "currentPriceUp"
    } else if (trend < 0) {
        currentPriceClass = "currentPriceDown"
    }
    if (isNaN(currentPriceDeltaPercent))
        currentPriceDeltaPercent = 0
    return {
        trend,
        pnl,
        currentPriceDeltaPercent: (trend > 0? '+': '') + currentPriceDeltaPercent.toFixed(2),
        currentPrice: brushValue(currentPrice, tickSize),
        currentPriceClass
    }
}

export function getMaxMinAndDeltaPercent(trade) {
    if (!trade)
        return {
            maxPrice: 0,
            maxDelta: 0,
            minPrice: 0,
            minDelta: 0
        }

    let maxPrice = undefined
    let minPrice = undefined
    if (trade.child_order && trade.child_order.order_id !== null) {
        maxPrice = trade.child_order.max_price_during_trade ? parseFloat(trade.child_order.max_price_during_trade) : 0
        minPrice = trade.child_order.min_price_during_trade ? parseFloat(trade.child_order.min_price_during_trade): 0
    } else {
        maxPrice = trade.max_price_during_trade ? parseFloat(trade.max_price_during_trade) : 0
        minPrice = trade.min_price_during_trade ? parseFloat(trade.min_price_during_trade) : 0
    }

    let referencePrice = undefined
    if (trade.buy_the_dip_id) {
        referencePrice = trade.dip_price ? parseFloat(trade.dip_price) : 0
    } else {
        if (trade.action_type === c.ACTION_TYPE_BUY)
            referencePrice = trade.buy_price ? parseFloat(trade.buy_price) : 0
        else if (trade.action_type === c.ACTION_TYPE_SELL)
            referencePrice = trade.sell_price ? parseFloat(trade.sell_price) : 0
    }

    let maxDelta = ((maxPrice - referencePrice) / referencePrice) * 100
    let minDelta = ((minPrice - referencePrice) / referencePrice) * 100

    if (isNaN(maxDelta))
        maxDelta = 0
    if (isNaN(minDelta))
        minDelta = 0
    let tickSize = parseInt(trade.tick_size)
    return {
        maxPrice: brushValue(maxPrice, tickSize), maxDelta: maxDelta.toFixed(2),
        minPrice: brushValue(minPrice, tickSize), minDelta: minDelta.toFixed(2)
    }
}

export function getMaxMinAndDeltaPercentFutures(futuresTrade, isOrder) {
    if (!futuresTrade)
        return {
            maxPrice: 0,
            maxDelta: 0,
            minPrice: 0,
            minDelta: 0
        }

    let maxPrice = undefined
    let minPrice = undefined
    if (futuresTrade.child_order && futuresTrade.child_order.order_id !== null) {
        maxPrice = futuresTrade.child_order.max_price_during_trade ? parseFloat(futuresTrade.child_order.max_price_during_trade) : 0
        minPrice = futuresTrade.child_order.min_price_during_trade ? parseFloat(futuresTrade.child_order.min_price_during_trade): 0
    } else {
        maxPrice = futuresTrade.max_price_during_trade ? parseFloat(futuresTrade.max_price_during_trade) : 0
        minPrice = futuresTrade.min_price_during_trade ? parseFloat(futuresTrade.min_price_during_trade) : 0
    }

    let referencePrice = undefined
    if (futuresTrade.suc_id) {
        //This is a SUC
        referencePrice = parseFloat(futuresTrade.entry_price)
    } else {
        if (hasChild(futuresTrade)) {
            referencePrice = parseFloat(futuresTrade.avg_price)
        } else {
            referencePrice = parseFloat(futuresTrade.price)
        }
    }

    let sign = 1
    if (futuresTrade.futures_type === c.FUTURES_TYPE_SHORT) {
        sign = -1
    }

    let leverage = !isOrder ? parseFloat(futuresTrade.leverage): 1
    let maxDelta = sign * ((maxPrice - referencePrice) / referencePrice) * 100 * leverage
    let minDelta = sign * ((minPrice - referencePrice) / referencePrice) * 100 * leverage

    if (isNaN(maxDelta))
        maxDelta = 0
    if (isNaN(minDelta))
        minDelta = 0
    let tickSize = parseInt(futuresTrade.tick_size)
    return {
        maxPrice: brushValue(maxPrice, tickSize), maxDelta: maxDelta.toFixed(2),
        minPrice: brushValue(minPrice, tickSize), minDelta: minDelta.toFixed(2)
    }
}

export function getTradeStatus(trade) {
    let result = {isClosing: false, takeProfit: 0, stopLoss: 0, takeProfitPrice: 0,
        stopLossPrice: 0}

    if (!trade)
        return result

    let maxDigits = parseInt(trade.tick_size)
    let takeProfitPrice = calculateTakeProfitPrice(trade)
    let stopLossPrice = calculateStopLossPrice(trade)
    let currentPrice = parseFloat(trade.current_price)


    if (currentPrice >= takeProfitPrice) {
        result.isClosing = true
        result.takeProfit = 1
    } else if (currentPrice <= stopLossPrice) {
        result.isClosing = true
        result.stopLoss = 1
    }

    if (trade.child_order && trade.child_order.status == c.ORDER_STATUS_PARTIALLY_FILLED) {
        result.isClosing = true
    }

    result.takeProfitPrice = brushValue(takeProfitPrice, maxDigits)
    result.stopLossPrice = brushValue(stopLossPrice, maxDigits)

    return result
}

function calculateTakeProfitPrice(trade) {
    if (!trade)
        return 0

    if (!trade.take_profit_price_or_percent)
        return 999999
    let buyPrice = parseFloat(trade.buy_price)
    let takeProfitPriceOrPercent = trade.take_profit_price_or_percent ? trade.take_profit_price_or_percent : 0
    if (trade.take_profit_option == c.TAKE_PROFIT_OPTION_PERCENTAGE) {
        return buyPrice * (1 + (parseFloat(takeProfitPriceOrPercent) / 100) )
    } else if (trade.take_profit_option == c.TAKE_PROFIT_OPTION_PRICE) {
        return parseFloat(takeProfitPriceOrPercent)
    }
}

function calculateStopLossPrice(trade) {
    if (!trade)
        return 0
    if (!trade.stop_loss_price_or_percent)
        return -1
    let buyPrice = parseFloat(trade.buy_price)
    let stopLossPriceOrPercent = trade.stop_loss_price_or_percent ? trade.stop_loss_price_or_percent : 0
    if (trade.stop_loss_option == c.STOP_LOSS_OPTION_PERCENTAGE) {
        return buyPrice * (1 - (parseFloat(stopLossPriceOrPercent) / 100) )
    } else if (trade.stop_loss_option == c.STOP_LOSS_OPTION_TRAILING) {
        return parseFloat(trade.child_order.stop_price)
    } else if (trade.stop_loss_option == c.STOP_LOSS_OPTION_PRICE) {
        return parseFloat(stopLossPriceOrPercent)
    }
}

export function timeAgoFormatter(value, unit, suffix, epochSeconds) {

    let plural = value > 1 ? "s": ""
    return `${value} ${unit}${plural}`
}

export function timeFormatter(value, showTime) {
    let _value = parseInt(value)
    let date = new Date(_value)
    let now = new Date()
    let format = showTime ? "D MMM YYYY HH:mm " : "D MMM YYYY"
    if (date.getFullYear() === now.getFullYear()) {
        format = showTime ? "D MMM HH:mm ": "D MMM"
    }
    let result = moment(_value).format(format)
    if ("Invalid date" == result)
        return "..."
    return result
}

export function timeFormatter2(date, showTime, showYearIfCurrent) {
    let _date = new Date(date)
    let now = new Date()
    let format = showTime ? "D MMM YYYY HH:mm " : "D MMM YYYY"
    if (_date.getFullYear() === now.getFullYear()) {
        let year = showYearIfCurrent ? "YYYY": ""
        format = showTime ? `D MMM ${year} HH:mm `: `D MMM ${year}`
    }
    let result = moment(_date).format(format)
    if ("Invalid date" == result)
        return "..."
    return result
}

export function formatTimeDuration(stopTime, startTime, separator = ":") {
    if (!stopTime || !startTime)
        return "..."

    stopTime = parseInt(stopTime)
    startTime = parseInt(startTime)
    if (isNaN(stopTime) || isNaN(startTime)) {
        return "..."
    }
    let durationInMillis = stopTime - startTime
    let s = durationInMillis / 1000
    let days = 0
    let hours = 0
    let minutes = 0
    let seconds = 0
    if (s > 59) {
        seconds = Math.floor(s % 60)
        minutes = Math.floor(s / 60)
        if (minutes > 59) {
            minutes = Math.floor(s / 60) % 60
            hours = Math.floor(s / 60 / 60)
            if (hours > 23) {
                hours = Math.floor(s / 60 / 60) % 24
                days = Math.floor(s / 60 / 60 / 24)
                return (days > 0 ? days + `d` : "") +
                    (hours > 0 ? (days > 0 ? separator: "") + hours + `m` : "") +
                    (minutes > 0 ? (hours > 0 ? separator: "") + minutes + `m` : "") +
                    (seconds > 0 ? (minutes > 0 ? separator: "")  + seconds + "s" : "")
            } else {
                return (hours > 0 ? hours + `h` : "") +
                    (minutes > 0 ? (hours > 0 ? separator: "") + minutes + `m` : "") +
                    (seconds > 0 ? (minutes > 0 ? separator: "")  + seconds + "s" : "")
            }
        } else {
            return (minutes > 0 ? minutes + `m` : "") + (seconds > 0 ? (minutes > 0 ? separator: "") + seconds + "s" : "")
        }
    } else {
        return Math.floor(s) + "s"
    }
}

export function formatTimeDuration2(durationInMillis, separator = ":") {
    let s = durationInMillis / 1000
    let days = 0
    let hours = 0
    let minutes = 0
    let seconds = 0
    if (s > 59) {
        seconds = Math.floor(s % 60)
        minutes = Math.floor(s / 60)
        if (minutes > 59) {
            minutes = Math.floor(s / 60) % 60
            hours = Math.floor(s / 60 / 60)
            if (hours > 23) {
                hours = Math.floor(s / 60 / 60) % 24
                days = Math.floor(s / 60 / 60 / 24)
                return (days > 0 ? days + `d` : "") +
                    (hours > 0 ? (days > 0 ? separator: "") + hours + `m` : "") +
                    (minutes > 0 ? (hours > 0 ? separator: "") + minutes + `m` : "") +
                    (seconds > 0 ? (minutes > 0 ? separator: "")  + seconds + "s" : "")
            } else {
                return (hours > 0 ? hours + `h` : "") +
                    (minutes > 0 ? (hours > 0 ? separator: "") + minutes + `m` : "") +
                    (seconds > 0 ? (minutes > 0 ? separator: "")  + seconds + "s" : "")
            }
        } else {
            return (minutes > 0 ? minutes + `m` : "") + (seconds > 0 ? (minutes > 0 ? separator: "") + seconds + "s" : "")
        }
    } else {
        return Math.floor(s) + "s"
    }
}

function getTakeProfitAndStopLossConfigsForTradeOrBTD(record) {
    if (!record)
        return {
            tpc: {}, slc: {}
        }

    let ACTION_TYPE = record.action_type
    let tickSize = parseInt(record.tick_size)
    let takeProfitConfig = {
        type: undefined,
        percent: undefined,
        percents: [],
        price: undefined,
        prices: []
    }
    let stopLossConfig = {
        type: undefined,
        percent: undefined,
        price: undefined,
        trailed: false,
        candlesType: record.candles_type
    }

    let tradeEntryPriceF = 0
    if (record.buy_the_dip_id)
        tradeEntryPriceF = parseFloat(record.dip_price)
    else {
        if (record.action_type === c.ACTION_TYPE_BUY)
            tradeEntryPriceF = parseFloat(record.buy_price)
        else if (record.action_type === c.ACTION_TYPE_SELL)
            tradeEntryPriceF = parseFloat(record.sell_price)
    }

    if (record.take_profit_option === c.TAKE_PROFIT_OPTION_PRICE) {
        takeProfitConfig.type = c.TAKE_PROFIT_OPTION_PRICE
        if (record.from_SUC) {
            let l = parseInt(record.take_profit_targets)
            for(let i = 1; i <= l; i++) {
                let price = record[`take_profit_price_or_percent_${i}`]
                let percent = brushValue(((parseFloat(price) - tradeEntryPriceF) / tradeEntryPriceF) * 100,1)
                if (ACTION_TYPE === c.ACTION_TYPE_SELL)
                    percent *= -1
                takeProfitConfig.percents.push(`${percent}`)
                takeProfitConfig.prices.push(brushValue(price, tickSize))
            }
        } else {
            let percent = brushValue(((parseFloat(record.take_profit_price_or_percent) - tradeEntryPriceF) / tradeEntryPriceF) * 100,1)
            if (ACTION_TYPE === c.ACTION_TYPE_SELL)
                percent *= -1
            takeProfitConfig.percent = `${percent}`
            takeProfitConfig.price = brushValue(record.take_profit_price_or_percent, tickSize)
        }
    } else if (record.take_profit_option === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
        takeProfitConfig.type = c.TAKE_PROFIT_OPTION_PERCENTAGE
        if (record.from_SUC) {
            let l = parseInt(record.take_profit_targets)
            for(let i = 1; i <=l; i++) {
                let percent = record[`take_profit_price_or_percent_${i}`]
                let takeProfitPrice = 0
                let sign = 1
                if (ACTION_TYPE === c.ACTION_TYPE_SELL)
                    sign = -1
                takeProfitPrice = (1 + sign * (parseFloat(percent) / 100)) * tradeEntryPriceF
                takeProfitConfig.prices.push(brushValue(takeProfitPrice, tickSize))
                takeProfitConfig.percents.push(`${brushValue(percent,1)}`)
            }
        } else {
            let takeProfitPrice = 0
            let sign = 1
            if (ACTION_TYPE === c.ACTION_TYPE_SELL)
                sign = -1
            takeProfitPrice = (1 + sign * (parseFloat(record.take_profit_price_or_percent) / 100)) * tradeEntryPriceF
            takeProfitConfig.price = brushValue(takeProfitPrice, tickSize)
            takeProfitConfig.percent = `${brushValue(record.take_profit_price_or_percent,1)}`
        }
    } else {
        takeProfitConfig.type = c.TAKE_PROFIT_OPTION_MANUAL
    }

    if (record.stop_loss_option === c.STOP_LOSS_OPTION_TRAILING) {
        stopLossConfig.percent = `${brushValue(record.stop_loss_price_or_percent,1)}`
        let stopLossPrice = 0
        let stopPrice = (record.child_order && record.child_order.stop_price) ? parseFloat(record.child_order.stop_price): 0
        if (stopPrice > 0) {
            stopLossPrice = record.child_order.stop_price
        } else {
            let currentPrice = record.child_order ? parseFloat(record.child_order.current_price): 0
            if (currentPrice === 0)
                currentPrice = record.current_price
            let sign = 1
            if (ACTION_TYPE === c.ACTION_TYPE_SELL)
                sign = -1
            stopLossPrice = (1 - sign * (parseFloat(record.stop_loss_price_or_percent) / 100)) * currentPrice
        }
        stopLossConfig.price = brushValue(stopLossPrice, tickSize)
        stopLossConfig.type = c.STOP_LOSS_OPTION_TRAILING
        stopLossConfig.trailed = true
    } else if (record.stop_loss_option === c.STOP_LOSS_OPTION_PRICE) {
        let percent = brushValue(((parseFloat(record.stop_loss_price_or_percent) - tradeEntryPriceF) / tradeEntryPriceF) * 100, 1)
        if (ACTION_TYPE === c.ACTION_TYPE_SELL)
            percent *= -1
        stopLossConfig.percent = `${percent}`
        stopLossConfig.price = brushValue(record.stop_loss_price_or_percent, tickSize)
        stopLossConfig.type = c.STOP_LOSS_OPTION_PRICE
    } else if (record.stop_loss_option === c.STOP_LOSS_OPTION_PERCENTAGE) {
        let stopLossPrice = 0
        let sign = 1
        if (ACTION_TYPE === c.ACTION_TYPE_SELL)
            sign = -1
        stopLossPrice = (1 - sign * (parseFloat(record.stop_loss_price_or_percent) / 100)) * tradeEntryPriceF
        stopLossConfig.price = brushValue(stopLossPrice, tickSize)
        stopLossConfig.percent = `-${brushValue(record.stop_loss_price_or_percent,1)}`
        stopLossConfig.type = c.STOP_LOSS_OPTION_PERCENTAGE
    } else if (record.stop_loss_option === c.STOP_LOSS_OPTION_CONDITIONAL) {
        stopLossConfig.price = brushValue(record.stop_loss_price_or_percent, tickSize)
        stopLossConfig.type = c.STOP_LOSS_OPTION_CONDITIONAL
    }

    return {tpc: takeProfitConfig, slc: stopLossConfig}
}

function getTakeProfitAndStopLossConfigsForFuturesTrade(futuresTrade) {
    if (!futuresTrade)
        return {
            tpc: {}, slc: {}
        }

    let l = parseFloat(futuresTrade.leverage)
    let tickSize = parseInt(futuresTrade.tick_size)
    let takeProfitConfig = {
        type: undefined,
        percent: undefined,
        percents: [],
        price: undefined,
        prices: []
    }
    let stopLossConfig = {
        type: undefined,
        percent: undefined,
        price: undefined,
        trailed: false,
        candlesType: futuresTrade.candles_type
    }

    let tradeEntryPriceF = 0
    if (!hasChild(futuresTrade)) {
        tradeEntryPriceF = parseFloat(futuresTrade.buy_price)
    } else {
        tradeEntryPriceF = parseFloat(futuresTrade.avg_price)
        if (tradeEntryPriceF === 0 || isNaN(tradeEntryPriceF)) {
            tradeEntryPriceF = parseFloat(futuresTrade.price)
        }
    }

    if (futuresTrade.take_profit_option === c.TAKE_PROFIT_OPTION_PRICE) {
        takeProfitConfig.type = c.TAKE_PROFIT_OPTION_PRICE
        if (futuresTrade.from_SUC) {
            let ltpts = parseInt(futuresTrade.take_profit_targets)
            for(let i = 1; i <= ltpts; i++) {
                let price = futuresTrade[`take_profit_price_or_percent_${i}`]
                let percent = brushValue(((parseFloat(price) - tradeEntryPriceF) / tradeEntryPriceF) * 100 * l,1)
                if (futuresTrade.futures_type === c.FUTURES_TYPE_SHORT)
                    percent *= -1
                takeProfitConfig.percents.push(`${percent}`)
                takeProfitConfig.prices.push(brushValue(price, tickSize))
            }
        } else {
            let percent = brushValue(((parseFloat(futuresTrade.take_profit_price_or_percent) - tradeEntryPriceF) / tradeEntryPriceF) * 100 * l,1)
            if (futuresTrade.futures_type === c.FUTURES_TYPE_SHORT_s)
                percent *= -1
            takeProfitConfig.percent = `${percent}`
            takeProfitConfig.price = brushValue(futuresTrade.take_profit_price_or_percent, tickSize)
        }
    } else if (futuresTrade.take_profit_option === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
        takeProfitConfig.type = c.TAKE_PROFIT_OPTION_PERCENTAGE
        if (futuresTrade.from_SUC) {
            let ltpts = parseInt(futuresTrade.take_profit_targets)
            for(let i = 1; i <= ltpts; i++) {
                let percent = futuresTrade[`take_profit_price_or_percent_${i}`]
                let takeProfitPrice = 0
                let sign = 1
                if (futuresTrade.futures_type === c.FUTURES_TYPE_SHORT_s)
                    sign = -1
                takeProfitPrice = (1 + sign * (parseFloat(percent) / 100 / l)) * tradeEntryPriceF
                takeProfitConfig.prices.push(brushValue(takeProfitPrice, tickSize))
                takeProfitConfig.percents.push(`${brushValue(percent,1)}`)
            }
        } else {
            let takeProfitPrice = 0
            let sign = 1
            if (futuresTrade.futures_type === c.FUTURES_TYPE_SHORT_s)
                sign = -1
            takeProfitPrice = (1 + sign * (parseFloat(futuresTrade.take_profit_price_or_percent) / 100) / l) * tradeEntryPriceF
            takeProfitConfig.price = brushValue(takeProfitPrice, tickSize)
            takeProfitConfig.percent = `${brushValue(futuresTrade.take_profit_price_or_percent,1)}`
        }
    } else {
        takeProfitConfig.type = c.TAKE_PROFIT_OPTION_MANUAL
    }

    if (futuresTrade.stop_loss_option === c.STOP_LOSS_OPTION_TRAILING) {
        stopLossConfig.percent = `${brushValue(futuresTrade.stop_loss_price_or_percent,1)}`
        let stopLossPrice = 0
        let stopPrice = (futuresTrade.child_order && futuresTrade.child_order.stop_price) ? parseFloat(futuresTrade.child_order.stop_price): 0
        if (stopPrice > 0) {
            stopLossPrice = futuresTrade.child_order.stop_price
        } else {
            let currentPrice = futuresTrade.child_order ? parseFloat(futuresTrade.child_order.current_price): 0
            if (currentPrice === 0)
                currentPrice = futuresTrade.current_price
            let sign = 1
            if (futuresTrade.futures_type === c.FUTURES_TYPE_SHORT_s)
                sign = -1
            stopLossPrice = (1 - sign * (parseFloat(futuresTrade.stop_loss_price_or_percent) / 100) / l) * currentPrice
        }
        stopLossConfig.price = brushValue(stopLossPrice, tickSize)
        stopLossConfig.type = c.STOP_LOSS_OPTION_TRAILING
        stopLossConfig.trailed = true
    } else if (futuresTrade.stop_loss_option === c.STOP_LOSS_OPTION_PRICE) {
        let percent = brushValue(((parseFloat(futuresTrade.stop_loss_price_or_percent) - tradeEntryPriceF) / tradeEntryPriceF) * 100 * l, 1)
        if (futuresTrade.futures_type === c.FUTURES_TYPE_SHORT_s)
            percent *= -1
        stopLossConfig.percent = `${percent}`
        stopLossConfig.price = brushValue(futuresTrade.stop_loss_price_or_percent, tickSize)
        stopLossConfig.type = c.STOP_LOSS_OPTION_PRICE
    } else if (futuresTrade.stop_loss_option === c.STOP_LOSS_OPTION_PERCENTAGE) {
        let stopLossPrice = 0
        let sign = 1
        if (futuresTrade.futures_type === c.FUTURES_TYPE_SHORT_s)
            sign = -1
        stopLossPrice = (1 - sign * (parseFloat(futuresTrade.stop_loss_price_or_percent) / 100) / l) * tradeEntryPriceF
        stopLossConfig.price = brushValue(stopLossPrice, tickSize)
        stopLossConfig.percent = `-${brushValue(futuresTrade.stop_loss_price_or_percent,1)}`
        stopLossConfig.type = c.STOP_LOSS_OPTION_PERCENTAGE
    } else if (futuresTrade.stop_loss_option === c.STOP_LOSS_OPTION_CONDITIONAL) {
        stopLossConfig.price = brushValue(futuresTrade.stop_loss_price_or_percent, tickSize)
        stopLossConfig.type = c.STOP_LOSS_OPTION_CONDITIONAL
    }

    return {tpc: takeProfitConfig, slc: stopLossConfig}
}

export function getTradeConditionsContentForActiveTrade(trade) {
    let {tpc, slc} = getTakeProfitAndStopLossConfigsForTradeOrBTD(trade)
    let isTradeConnectedToSignal = trade.connected_to_signal === true || trade.connected_to_signal === "true"
    let ACTION_TYPE = trade.action_type
    let tpContent, slContent
    if (trade.from_SUC) {
        tpContent = []
        let l = parseInt(trade.take_profit_targets)
        if (tpc.type === c.TAKE_PROFIT_OPTION_PRICE) {
            for(let i = 0; i <l; i++) {
                tpContent.push(<div>TP#{i+1} <b>{tpc.prices[i]}</b>&nbsp;
                    (+{tpc.percents[i]}%) {trade[`take_profit_target_${i+1}_reached`] && <span style={{color: "#64FE7C"}}>✓</span>}</div>)
            }
        } else if (tpc.type === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
            for(let i = 0; i <l; i++) {
                tpContent.push(<div>TP#{i+1} <b>+{tpc.percents[i]}%</b>&nbsp;
                    ({tpc.prices[i]}) {trade[`take_profit_target_${i+1}_reached`] && <span style={{color: "#64FE7C"}}>✓</span>}</div>)
            }
        } else if (tpc.type === c.TAKE_PROFIT_OPTION_MANUAL) {
            if (isTradeConnectedToSignal) {
                tpContent.push(<div>TP <b>BY TRADER</b></div>)
            } else {
                tpContent.push(<div>TP <b>MANUAL</b></div>)
            }
        }
    } else {
        if (tpc.type === c.TAKE_PROFIT_OPTION_PRICE) {
            tpContent = <span>TP <b>{tpc.price}</b> (+{tpc.percent}%)</span>
        } else if (trade.take_profit_option === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
            tpContent = <span>TP <b>+{tpc.percent}%</b> ({tpc.price})</span>
        } else if (trade.take_profit_option === c.TAKE_PROFIT_OPTION_MANUAL) {
            tpContent = <span>TP <b>MANUAL</b></span>
        }
    }

    if (trade.stop_loss_option === c.STOP_LOSS_OPTION_TRAILING) {
        slContent = <span><b>TR.</b> SL <b>-{slc.percent}%</b> ({slc.price})</span>
    } else if (trade.stop_loss_option === c.STOP_LOSS_OPTION_PRICE) {
        if (trade.from_SUC) {
            slContent = <div>SL <b>{slc.price}</b>&nbsp;
                ({slc.percent}%) {trade.last_action === c.TRADE_OP_AUTO_TAKE_LOSS && <span style={{color: "#FB4A4A"}}>✓</span>}</div>
        } else {
            slContent = <div>SL <b>{slc.price}</b> ({slc.percent}%)</div>
        }
    } else if (trade.stop_loss_option === c.STOP_LOSS_OPTION_PERCENTAGE) {
        if (trade.from_SUC) {
            slContent = <div>SL&nbsp;
                <b>{getSign(slc.percent)}{slc.percent}%</b> ({slc.price}) {trade.last_action === c.TRADE_OP_AUTO_TAKE_LOSS && <span style={{color: "#FB4A4A"}}>✓</span>}</div>
        } else {
            slContent = <div>SL <b>{getSign(slc.percent)}{slc.percent}%</b> ({slc.price})</div>
        }
    } else if (trade.stop_loss_option === c.STOP_LOSS_OPTION_MANUAL) {
        if (trade.from_SUC && isTradeConnectedToSignal) {
            slContent = <div>SL <b>BY TRADER</b></div>
        } else {
            slContent = <div>SL <b>MANUAL</b></div>
        }

    } else if (trade.stop_loss_option === c.STOP_LOSS_OPTION_CONDITIONAL) {
        slContent = <div>SL if <b>{c.CANDLES_TYPES_HR_MAP[slc.candlesType]}</b>&nbsp;
                {ACTION_TYPE === c.ACTION_TYPE_BUY && <span title={"candle close below"}>c.c.b.</span>}
                {ACTION_TYPE === c.ACTION_TYPE_SELL && <span title={"candle close above"}>c.c.a.</span>} <b>{slc.price}</b></div>
    }

    return {tpContent: tpContent, slContent: slContent}
}

export function getTradeConditionsContentForActiveTradeFutures(futuresTrade) {
    let {tpc, slc} = getTakeProfitAndStopLossConfigsForFuturesTrade(futuresTrade)
    let tpContent = [], slContent = []
    if (futuresTrade.from_SUC) {
        let l = parseInt(futuresTrade.take_profit_targets)
        if (tpc.type === c.TAKE_PROFIT_OPTION_PRICE) {
            for(let i = 0; i <l; i++) {
                tpContent.push(<div>TP#{i+1} <b>{tpc.prices[i]}</b>&nbsp;
                    (+{tpc.percents[i]}%) {futuresTrade[`take_profit_target_${i+1}_reached`] && <span style={{color: "#64FE7C"}}>✓</span>}</div>)
            }
        } else if (futuresTrade.take_profit_option === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
            for(let i = 0; i <l; i++) {
                tpContent.push(<div>TP#{i+1} <b>+{tpc.percents[i]}%</b>&nbsp;
                    ({tpc.prices[i]}) {futuresTrade[`take_profit_target_${i+1}_reached`] && <span style={{color: "#64FE7C"}}>✓</span>}</div>)
            }
        }
    } else {
        if (tpc.type === c.TAKE_PROFIT_OPTION_PRICE) {
            tpContent.push(<div key={`TP${Math.random()}`}>TP <b>{tpc.price}</b> (+{tpc.percent}%)</div>)
        } else if (futuresTrade.take_profit_option === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
            tpContent.push(<div key={`TP${Math.random()}`}>TP <b>+{tpc.percent}%</b> ({tpc.price})</div>)
        }
        // tpContent.push(<div className={"realProfit"} key={`RealP${Math.random()}`}>&nbsp;real profit <b>+{parseFloat(tpc.percent) * leverage}%</b></div>)
    }

    if (futuresTrade.stop_loss_option === c.STOP_LOSS_OPTION_TRAILING) {
        slContent.push(<span><b>TR.</b> SL <b>-{slc.percent}%</b> ({slc.price})</span>)
    } else if (futuresTrade.stop_loss_option === c.STOP_LOSS_OPTION_PRICE) {
        if (futuresTrade.from_SUC) {
            slContent.push(<div>SL <b>{slc.price}</b>&nbsp;
                ({slc.percent}%) {futuresTrade.last_action === c.TRADE_OP_AUTO_TAKE_LOSS && <span style={{color: "#FB4A4A"}}>✓</span>}</div>)
        } else {
            slContent.push(<div>SL <b>{slc.price}</b> ({slc.percent}%)</div>)
        }
        // slContent.push(<div className={"realLoss"}>&nbsp;real loss <b>{parseFloat(slc.percent) * leverage}%</b></div>)
    } else if (futuresTrade.stop_loss_option === c.STOP_LOSS_OPTION_PERCENTAGE) {
        if (futuresTrade.from_SUC) {
            slContent.push(<div key={`SL${Math.random()}`}>SL&nbsp;
                <b>{getSign(slc.percent)}{slc.percent}%</b> ({slc.price}) {futuresTrade.last_action === c.TRADE_OP_AUTO_TAKE_LOSS && <span style={{color: "#FB4A4A"}}>✓</span>}</div>)
        } else {
            slContent.push(<div key={`SL${Math.random()}`}>SL <b>{getSign(slc.percent)}{slc.percent}%</b> ({slc.price})</div>)
        }
        // slContent.push(<div className={"realLoss"} key={`RealLoss${Math.random()}`}>&nbsp;real loss <b>{parseFloat(slc.percent) * leverage}%</b></div>)
    } else if (futuresTrade.stop_loss_option === c.STOP_LOSS_OPTION_MANUAL) {
        slContent.push(<div>SL <b>MANUAL</b></div>)
    } else if (futuresTrade.stop_loss_option === c.STOP_LOSS_OPTION_CONDITIONAL) {
        slContent.push(<div>SL if <b>{c.CANDLES_TYPES_HR_MAP[slc.candlesType]}</b>&nbsp;
            {futuresTrade.futures_type === c.FUTURES_TYPE_LONG && <span title={"candle close below"}>c.c.b.</span>}
            {futuresTrade.futures_type === c.FUTURES_TYPE_SHORT && <span title={"candle close above"}>c.c.a.</span>} <b>{slc.price}</b></div>)
    }

    return {tpContent: tpContent, slContent: slContent}
}

function getSign(value) {
    let valueAsNo = parseFloat(value)
    if (!isNaN(valueAsNo) && valueAsNo > 0)
        return <span>+</span>
}

export function getTradeConditionsContentForTradeDetails(trade, showAlternatives=true) {
    let {tpc, slc} = getTakeProfitAndStopLossConfigsForTradeOrBTD(trade)
    let tpContent, slContent
    let ACTION_TYPE = trade.action_type
    if (trade.from_SUC) {
        let l = parseInt(trade.take_profit_targets)
        tpContent = []
        if (tpc.type === c.TAKE_PROFIT_OPTION_PRICE) {
            for(let i = 0; i < l; i++) {
                tpContent.push(<div><b>Take profit #{i+1}</b> at <b>{tpc.prices[i]}</b> {showAlternatives && <span>({getSign(tpc.percents[i])}{tpc.percents[i]}%)</span>}</div>)
                if (i < 2) {
                    tpContent.push(<div className={"executesSmallBox"}>executes {trade[`take_profit_target_${i+1}_percentage_execute`]}%</div>)
                }
            }
        } else if (trade.take_profit_option === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
            for(let i = 0; i < l; i++) {
                tpContent.push(<div><b>Take profit #{i+1}</b> at <b>{getSign(tpc.percents[i])}{tpc.percents[i]}%</b> {showAlternatives && <span>({tpc.prices[i]})</span>}</div>)
                if (i < 2) {
                    tpContent.push(<div className={"executesSmallBox"}>executes {trade[`take_profit_target_${i+1}_percentage_execute`]}%</div>)
                }
            }
        }
    } else {
        if (tpc.type === c.TAKE_PROFIT_OPTION_PRICE) {
            tpContent = <span><b>Take profit</b> at <b>{tpc.price}</b> {showAlternatives && <span>({getSign(tpc.percent)}{tpc.percent}%)</span>}</span>
        } else if (trade.take_profit_option === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
            tpContent = <span><b>Take profit</b> at <b>{getSign(tpc.percent)}{tpc.percent}%</b> {showAlternatives && <span>({tpc.price})</span>}</span>
        }
    }

    if (trade.stop_loss_option === c.STOP_LOSS_OPTION_TRAILING) {
        slContent = <span><b>Tr. Stop loss</b> at <b>{getSign(slc.percent)}{slc.percent}%</b> {showAlternatives && <span>({slc.price})</span>}</span>
    } else if (trade.stop_loss_option === c.STOP_LOSS_OPTION_PRICE) {
        slContent = <span><b>Stop loss</b> at <b>{slc.price}</b> {showAlternatives && <span>({getSign(slc.percent)}{slc.percent}%)</span>}</span>
    } else if (trade.stop_loss_option === c.STOP_LOSS_OPTION_PERCENTAGE) {
        slContent = <span><b>Stop loss</b> at <b>{getSign(slc.percent)}{slc.percent}%</b> {showAlternatives && <span>({slc.price})</span>}</span>
    } else if (trade.stop_loss_option === c.STOP_LOSS_OPTION_MANUAL) {
        slContent = <span><b>Stop loss</b> <b>MANUAL</b></span>
    } else if (trade.stop_loss_option === c.STOP_LOSS_OPTION_CONDITIONAL) {
        slContent = <span><b>Stop loss</b> if <b>{c.CANDLES_TYPES_HR_MAP[slc.candlesType]}</b> candle closes&nbsp;
            {ACTION_TYPE === c.ACTION_TYPE_BUY && <span>below</span>}
            {ACTION_TYPE === c.ACTION_TYPE_SELL && <span>above</span>} <b>{slc.price}</b></span>
    }

    return {tpContent: tpContent, slContent: slContent}
}

export function getTradeConditionsContentForFuturesTradeDetails(futuresTrade, showAlternatives=true) {
    let {tpc, slc} = getTakeProfitAndStopLossConfigsForFuturesTrade(futuresTrade)
    let tpContent, slContent
    if (futuresTrade.from_SUC) {
        let l = parseInt(futuresTrade.take_profit_targets)
        tpContent = []
        if (tpc.type === c.TAKE_PROFIT_OPTION_PRICE) {
            for(let i = 0; i < l; i++) {
                tpContent.push(<div><b>Take profit #{i+1}</b> at <b>{tpc.prices[i]}</b> {showAlternatives && <span>({getSign(tpc.percents[i])}{tpc.percents[i]}%)</span>}</div>)
                if (i < 2) {
                    tpContent.push(<div className={"executesSmallBox"}>executes {futuresTrade[`take_profit_target_${i+1}_percentage_execute`]}%</div>)
                }
            }
        } else if (futuresTrade.take_profit_option === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
            for(let i = 0; i < l; i++) {
                tpContent.push(<div><b>Take profit #{i+1}</b> at <b>{getSign(tpc.percents[i])}{tpc.percents[i]}%</b> {showAlternatives && <span>({tpc.prices[i]})</span>}</div>)
                if (i < 2) {
                    tpContent.push(<div className={"executesSmallBox"}>executes {futuresTrade[`take_profit_target_${i+1}_percentage_execute`]}%</div>)
                }
            }
        }
    } else {
        if (tpc.type === c.TAKE_PROFIT_OPTION_PRICE) {
            tpContent = <span><b>Take profit</b> at <b>{tpc.price}</b> {showAlternatives && <span>({getSign(tpc.percent)}{tpc.percent}%)</span>}</span>
        } else if (futuresTrade.take_profit_option === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
            tpContent = <span><b>Take profit</b> at <b>{getSign(tpc.percent)}{tpc.percent}%</b> {showAlternatives && <span>({tpc.price})</span>}</span>
        }
    }

    if (futuresTrade.stop_loss_option === c.STOP_LOSS_OPTION_TRAILING) {
        slContent = <span><b>Tr. Stop loss</b> at <b>{getSign(slc.percent)}{slc.percent}%</b> {showAlternatives && <span>({slc.price})</span>}</span>
    } else if (futuresTrade.stop_loss_option === c.STOP_LOSS_OPTION_PRICE) {
        slContent = <span><b>Stop loss</b> at <b>{slc.price}</b> {showAlternatives && <span>({getSign(slc.percent)}{slc.percent}%)</span>}</span>
    } else if (futuresTrade.stop_loss_option === c.STOP_LOSS_OPTION_PERCENTAGE) {
        slContent = <span><b>Stop loss</b> at <b>{getSign(slc.percent)}{slc.percent}%</b> {showAlternatives && <span>({slc.price})</span>}</span>
    } else if (futuresTrade.stop_loss_option === c.STOP_LOSS_OPTION_MANUAL) {
        slContent = <span><b>Stop loss</b> <b>MANUAL</b></span>
    } else if (futuresTrade.stop_loss_option === c.STOP_LOSS_OPTION_CONDITIONAL) {
        slContent = <span><b>Stop loss</b> if <b>{c.CANDLES_TYPES_HR_MAP[slc.candlesType]}</b> candle closes&nbsp;
            {futuresTrade.futures_type === c.FUTURES_TYPE_LONG && <span>below</span>}
            {futuresTrade.futures_type === c.FUTURES_TYPE_SHORT && <span>above</span>} <b>{slc.price}</b></span>
    }

    return {tpContent: tpContent, slContent: slContent}
}


function getTakeProfitAndStopLossConfigsForSUC(suc) {
    if (!suc)
        return {
            tpc: {}, slc: {}
        }

    let takeProfitConfig = {
        type: undefined,
        targets:[]
    }
    let stopLossConfig = {
        type: undefined,
        percent: undefined,
        price: undefined,
        trailed: false,
        candlesType: suc.candles_type
    }

    let sucEntryPriceF = 0
    let leverage = 1
    if (suc.action_type === c.ACTION_TYPE_BUY)
        sucEntryPriceF = parseFloat(suc.buy_price)
    else if (suc.action_type === c.ACTION_TYPE_SELL)
        sucEntryPriceF = parseFloat(suc.sell_price)
    else if (suc.signal_type == c.SIGNAL_TYPE_FUTURES) {
        sucEntryPriceF = parseFloat(suc.entry_price)
        leverage = parseFloat(suc.leverage)
    }


    takeProfitConfig.type = suc.take_profit_option
    let precision = parseInt(suc.tick_size)
    if (suc.take_profit_option === c.TAKE_PROFIT_OPTION_PRICE) {
        for(let i = 0; i < suc.take_profit_targets.length; i++) {
            let take_profit_target = suc.take_profit_targets[i]
            let percent = brushValue(((parseFloat(take_profit_target) - sucEntryPriceF) / sucEntryPriceF) * 100 * leverage,1)
            if (suc.action_type === c.ACTION_TYPE_SELL || suc.futures_type === c.FUTURES_TYPE_SHORT)
                percent *= -1
            takeProfitConfig.targets.push({percent: `${percent}`, price: brushValue(take_profit_target, precision)})
        }
    } else if (suc.take_profit_option === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
        for(let i = 0; i < suc.take_profit_targets.length; i++) {
            let take_profit_target = suc.take_profit_targets[i]
            let sign = 1
            if (suc.action_type === c.ACTION_TYPE_SELL || suc.futures_type === c.FUTURES_TYPE_SHORT)
                sign = -1
            let takeProfitPrice = (1 + sign * (parseFloat(take_profit_target) / leverage / 100)) * sucEntryPriceF
            takeProfitConfig.targets.push({percent: `${take_profit_target}`,
                price: brushValue(takeProfitPrice, precision)})
        }
    }

    stopLossConfig.type = suc.stop_loss_option
    if (suc.stop_loss_option === c.STOP_LOSS_OPTION_PRICE) {
        let percent = brushValue(((parseFloat(suc.stop_loss_target) - sucEntryPriceF) / sucEntryPriceF) * 100 * leverage, 1)
        if (suc.action_type === c.ACTION_TYPE_SELL || suc.futures_type === c.FUTURES_TYPE_SHORT)
            percent *= -1
        stopLossConfig.percent = `${percent}`
        stopLossConfig.price = brushValue(suc.stop_loss_target, precision)
    } else if (suc.stop_loss_option === c.STOP_LOSS_OPTION_PERCENTAGE) {
        let sign = 1
        if (suc.action_type === c.ACTION_TYPE_SELL || suc.futures_type === c.FUTURES_TYPE_SHORT)
            sign = -1
        let stopLossPrice = (1 - sign * (parseFloat(suc.stop_loss_target) / leverage / 100)) * sucEntryPriceF
        stopLossConfig.price = brushValue(stopLossPrice, precision)
        stopLossConfig.percent = `${brushValue(suc.stop_loss_target,1)}`
    } else if (suc.stop_loss_option === c.STOP_LOSS_OPTION_CONDITIONAL) {
        stopLossConfig.price = brushValue(suc.stop_loss_target, precision)
        stopLossConfig.type = c.STOP_LOSS_OPTION_CONDITIONAL
    }

    return {tpc: takeProfitConfig, slc: stopLossConfig}
}

export function getTradeConditionsContentForSUC(suc) {
    let {tpc, slc} = getTakeProfitAndStopLossConfigsForSUC(suc)
    // console.log("tpc", JSON.stringify(tpc))
    // console.log("slc", JSON.stringify(slc))
    let ACTION_TYPE = suc.action_type
    let tpContent = [], slContent
    if (tpc.type == c.TAKE_PROFIT_OPTION_PRICE) {
        for(let i = 0; i < tpc.targets.length; i++) {
            tpContent.push(<div key={`tp_${i+1}_${Math.random()}`}>TP{i+1} <b>{tpc.targets[i].price}</b> ({tpc.targets[i].percent}%) {suc[`take_profit_target_${i+1}_reached`]==="true" && <span style={{color: "#64FE7C"}}>✓</span>}</div>)
        }
    } else if (tpc.type == c.TAKE_PROFIT_OPTION_PERCENTAGE) {
        for(let i = 0; i < tpc.targets.length; i++) {
            tpContent.push(<div key={i}>TP{i+1} <b>+{tpc.targets[i].percent}%</b> ({tpc.targets[i].price}) {suc[`take_profit_target_${i+1}_reached`]==="true" && <span style={{color: "#64FE7C"}}>✓</span>}</div>)
        }
    } else if (tpc.type == c.TAKE_PROFIT_OPTION_MANUAL) {
        tpContent.push(<div>TP <b>BY TRADER</b></div>)
    }

    if (suc.stop_loss_option == c.STOP_LOSS_OPTION_PRICE) {
        slContent = <div>SL <b>{slc.price}</b> ({slc.percent}%) {suc[`stop_loss_target_reached`]==="true" && <span style={{color: "#FB4A4A"}}>✓</span>}</div>
    } else if (suc.stop_loss_option == c.STOP_LOSS_OPTION_PERCENTAGE) {
        slContent = <div>SL <b>-{slc.percent}%</b> ({slc.price}) {suc[`stop_loss_target_reached`]==="true" && <span style={{color: "#FB4A4A"}}>✓</span>}</div>
    } else if (suc.stop_loss_option == c.STOP_LOSS_OPTION_MANUAL) {
        slContent = <div>SL <b>BY TRADER</b></div>
    } else if (suc.stop_loss_option === c.STOP_LOSS_OPTION_CONDITIONAL) {
        slContent = <div>SL if <b>{c.CANDLES_TYPES_HR_MAP[slc.candlesType]}</b>&nbsp;
                {ACTION_TYPE === c.ACTION_TYPE_BUY && <span title={"candle close below"}>c.c.b.</span>}
                {ACTION_TYPE === c.ACTION_TYPE_SELL && <span title={"candle close above"}>c.c.a.</span>} <b>{slc.price}</b>
        </div>
    }

    return {tpContent: tpContent, slContent: slContent}
}

export function getBTDStateTitle(btd) {
    if (!btd)
        return "Non existent"
    if (!btd.status)
        return "?"
    if (btd.status === c.BTD_STATUS_WAITING)
        return "Waiting"
    else if (btd.status === c.BTD_STATUS_CANCELED)
        return "Canceled"
    else if (btd.status === c.BTD_STATUS_DIP_REACHED)
        return "Waited"
}

export function getTradeStateTitle(trade) {
    if (!trade)
        return "Non existent"
    if (trade.child_order &&
        (trade.child_order.status == c.ORDER_STATUS_NEW ||
            trade.child_order.status == c.ORDER_STATUS_PARTIALLY_FILLED)) {
        return "Active"
    }

    if (trade.child_order && trade.child_order.status == c.ORDER_STATUS_CANCELED) {
        return ""
    }

    if (!trade.child_order) {
        return "Waiting"
    }
}

export function getTradeEnterVerb(trade) {
    if (!trade)
        return "verb?"

    if (trade.record_type === "ORDER") {
        if (trade.status === "NEW" || trade.status === "PARTIALLY_FILLED") {
            if (trade.action_type === c.ACTION_TYPE_BUY)
                return "buying"
            else if (trade.action_type === c.ACTION_TYPE_SELL)
                return "selling"
        } else if (trade.status === "FILLED" || trade.status === "ERROR") {
            if (trade.action_type === c.ACTION_TYPE_BUY)
                return "bought"
            else if (trade.action_type === c.ACTION_TYPE_SELL)
                return "sold"
        }
    }

    if (trade.child_order && trade.child_order.order_id) {
        if (trade.action_type === c.ACTION_TYPE_BUY)
            return "bought"
        else if (trade.action_type === c.ACTION_TYPE_SELL)
            return "sold"
    } else {
        if (trade.action_type === c.ACTION_TYPE_BUY)
            return "buying"
        else if (trade.action_type === c.ACTION_TYPE_SELL)
            return "selling"
    }
}

export function getTradeEnterPastTenseVerb(trade) {
    if (!trade)
        return "verb?"
    if (trade.action_type === c.ACTION_TYPE_BUY)
        return "bought"
    else if (trade.action_type === c.ACTION_TYPE_SELL)
        return "sold"
}

export function getFuturesTradeEnterVerb(futuresTrade) {
    if (!futuresTrade)
        return "verb?"

    if (futuresTrade.child_order && futuresTrade.child_order.order_id) {
        return "bought"
    } else {
        return "buying"
    }
}

export function getTradeExitVerb(trade) {
    if (!trade)
        return "verb?"

    if (trade.child_order && trade.child_order.order_id) {
        if (trade.action_type === c.ACTION_TYPE_BUY)
            return "sold"
        else if (trade.action_type === c.ACTION_TYPE_SELL)
            return "bought"
    } else {
        if (trade.action_type === c.ACTION_TYPE_BUY)
            return "selling"
        else if (trade.action_type === c.ACTION_TYPE_SELL)
            return "buying"
    }
}

export function getFuturesTradeExitVerb(futuresTrade) {
    if (!futuresTrade)
        return "verb?"

    return "sold"
}

export function getTradeEnterVerbPastTense(trade) {
    if (!trade)
        return "verb?"
    if (!trade.action_type) {
        return "bought"
    } else {
        if (trade.action_type === c.ACTION_TYPE_BUY)
            return "bought"
        else if (trade.action_type === c.ACTION_TYPE_SELL)
            return "sold"
    }
}

export function getTradeExitVerbPastTense(trade) {
    if (!trade)
        return "verb?"
    if (trade && trade.child_order && trade.child_order.status == "CANCELED")
        return "canceled"
    if (!trade.action_type) {
        return "sold"
    } else {
        return trade.action_type
    }
}

export function getTradeEnterVerbPresentTense(trade) {
    if (!trade)
        return "verb?"
    if (!trade.action_type) {
        return "buying"
    } else {
        return trade.action_type
    }
}

export function getTradeEnterVerbFutureTense(trade) {
    if (!trade)
        return "verb?"
    if (!trade.action_type) {
        return "will buy"
    } else {
        if (trade.action_type === c.ACTION_TYPE_BUY)
            return "will buy"
        else if (trade.action_type === c.ACTION_TYPE_SELL)
            return "will sell for"
        else
            return trade.action_type
    }
}

export function getTradeVerb(trade) {
    if (!trade)
        return "verb?"
    if (!trade.action_type) {
        return "verb?"
    } else {
        if (trade.action_type === c.ACTION_TYPE_BUY)
            return "buy"
        else if (trade.action_type === c.ACTION_TYPE_SELL)
            return "sell"
    }
}

export function getActionTypeOptionDetails(trade) {
    if (trade.action_type_option == c.ACTION_TYPE_OPTION_MARKET) {
        return `@market avg.p.`
    } else if (trade.action_type_option == c.ACTION_TYPE_OPTION_FIXED_PRICE ||
        trade.action_type_option == c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE) {
        return `fixed p.`
    }
}

export function getActionTypeOptionDetailsExplicit(trade) {
    if (trade.action_type_option === c.ACTION_TYPE_OPTION_MARKET) {
        return `@ market price`
    } else if (trade.action_type_option === c.ACTION_TYPE_OPTION_FIXED_PRICE) {
        return '@ fixed price'
    } else if (trade.action_type_option === c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE) {
        return `@ just above 1st bid price`
    }
}

export function getActionTypeOptionDetailsExplicitFutures(trade) {
    if (trade.action_type_option === c.FUTURES_ACTION_TYPE_OPTION_MARKET) {
        return `@ market price`
    } else if (trade.action_type_option === c.FUTURES_ACTION_TYPE_OPTION_FIXED_PRICE) {
        return '@ fixed price'
    } else if (trade.action_type_option === c.FUTURES_ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE) {
        return `@ just above 1st bid price`
    }
}

export function getTradeState(trade) {
    if (!trade)
        return -1

    if (trade.child_order && trade.child_order.order_id) {
        let status = trade.child_order.status
        if (status == c.ORDER_STATUS_CANCELED) {
            let cummulativeQuoteQty = parseFloat(trade.child_order.cummulative_quote_qty)
            if (cummulativeQuoteQty === 0)
                return c.TRADE_STATE_WITH_CHILD_CANCELED_AND_UNFULFILLED
            else
                return c.TRADE_STATE_WITH_CHILD_CANCELED
        } else if (status == c.ORDER_STATUS_NEW) {
            return c.TRADE_STATE_WITH_CHILD_ONGOING
        } else if (status == c.ORDER_STATUS_PARTIALLY_FILLED) {
            return c.TRADE_STATE_WITH_CHILD_PARTIALLY_FILLED
        } else if (status == c.ORDER_STATUS_FILLED) {
            return c.TRADE_STATE_WITH_CHILD_FILLED
        } else if (status == c.ORDER_STATUS_ERROR) {
            return c.TRADE_STATE_WITH_CHILD_IN_ERROR
        }
    } else {
        if (trade.status == c.ORDER_STATUS_CANCELED) {
            return c.TRADE_STATE_PARENT_CANCELED
        } else if (trade.status == c.ORDER_STATUS_NEW) {
            return c.TRADE_STATE_PARENT_ONGOING
        } else if (trade.status == c.ORDER_STATUS_PARTIALLY_FILLED) {
            return c.TRADE_STATE_PARENT_PARTIALLY_FILLED
        } else if (trade.status == c.ORDER_STATUS_FILLED) {
            return c.TRADE_STATE_PARENT_FILLED
        } else if (trade.status == c.ORDER_STATUS_ERROR) {
            return c.TRADE_STATE_PARENT_IN_ERROR
        }
    }
}

export function getFuturesTradeState(futuresTrade) {
    if (!futuresTrade)
        return -1

    if (futuresTrade.state === c.BACKEND_FUTURES_TRADE_STATE_UNCERTAIN)
        return c.FUTURES_TRADE_STATE_UNCERTAIN

    if (futuresTrade.child_order && futuresTrade.child_order.order_id) {
        let status = futuresTrade.child_order.status
        if (status === c.FUTURES_ORDER_STATUS_CANCELED) {
            let cumQuote = parseFloat(futuresTrade.child_order.cum_quote)
            if (cumQuote === 0)
                return c.FUTURES_TRADE_STATE_WITH_CHILD_CANCELED_AND_UNFULFILLED
            else
                return c.FUTURES_TRADE_STATE_WITH_CHILD_CANCELED
        } else if (status === c.FUTURES_ORDER_STATUS_NEW) {
            return c.FUTURES_TRADE_STATE_WITH_CHILD_ONGOING
        } else if (status === c.FUTURES_ORDER_STATUS_PARTIALLY_FILLED) {
            return c.FUTURES_TRADE_STATE_WITH_CHILD_PARTIALLY_FILLED
        } else if (status === c.FUTURES_ORDER_STATUS_FILLED) {
            return c.FUTURES_TRADE_STATE_WITH_CHILD_FILLED
        } else if (status === c.FUTURES_ORDER_STATUS_ERROR) {
            return c.FUTURES_TRADE_STATE_WITH_CHILD_IN_ERROR
        }
    } else {
        if (futuresTrade.status === c.FUTURES_ORDER_STATUS_CANCELED) {
            return c.FUTURES_TRADE_STATE_PARENT_CANCELED
        } else if (futuresTrade.status === c.FUTURES_ORDER_STATUS_NEW) {
            return c.FUTURES_TRADE_STATE_PARENT_ONGOING
        } else if (futuresTrade.status === c.FUTURES_ORDER_STATUS_PARTIALLY_FILLED) {
            return c.FUTURES_TRADE_STATE_PARENT_PARTIALLY_FILLED
        } else if (futuresTrade.status === c.FUTURES_ORDER_STATUS_FILLED) {
            return c.FUTURES_TRADE_STATE_PARENT_FILLED
        } else if (futuresTrade.status === c.FUTURES_ORDER_STATUS_ERROR) {
            return c.FUTURES_TRADE_STATE_PARENT_IN_ERROR
        }
    }
}

export function getTradeMainState(trade) {
    if (!trade)
        return -1

    if (trade.child_order && trade.child_order.order_id) {
        let k = trade.child_order
        if (k.status === c.ORDER_STATUS_CANCELED) {
            return c.TRADE_MAIN_STATE_FINISHED
        } else if (k.status === c.ORDER_STATUS_NEW) {
            return c.TRADE_MAIN_STATE_ONGOING
        } else if (k.status === c.ORDER_STATUS_PARTIALLY_FILLED) {
            return c.TRADE_MAIN_STATE_ONGOING
        } else if (k.status === c.ORDER_STATUS_FILLED) {
            return c.TRADE_MAIN_STATE_FINISHED
        } else if (k.status === c.ORDER_STATUS_ERROR) {
            return c.TRADE_MAIN_STATE_FINISHED
        }
    } else {
        if (trade.status === c.ORDER_STATUS_CANCELED) {
            return c.TRADE_MAIN_STATE_FINISHED
        } else if (trade.status === c.ORDER_STATUS_NEW) {
            return c.TRADE_MAIN_STATE_ONGOING
        } else if (trade.status === c.ORDER_STATUS_PARTIALLY_FILLED) {
            return c.TRADE_MAIN_STATE_ONGOING
        } else if (trade.status === c.ORDER_STATUS_FILLED) {
            return c.TRADE_MAIN_STATE_FINISHED
        }
    }
}

export function getFuturesTradeMainState(trade) {
    if (!trade)
        return -1

    if (trade.child_order && trade.child_order.order_id) {
        let k = trade.child_order
        if (k.status == c.ORDER_STATUS_CANCELED) {
            return c.TRADE_MAIN_STATE_FINISHED
        } else if (k.status == c.ORDER_STATUS_NEW) {
            return c.TRADE_MAIN_STATE_ONGOING
        } else if (k.status == c.ORDER_STATUS_PARTIALLY_FILLED) {
            return c.TRADE_MAIN_STATE_ONGOING
        } else if (k.status == c.ORDER_STATUS_FILLED) {
            return c.TRADE_MAIN_STATE_FINISHED
        } else if (k.status == c.ORDER_STATUS_ERROR) {
            return c.TRADE_MAIN_STATE_FINISHED
        }
    } else {
        if (trade.status == c.ORDER_STATUS_CANCELED) {
            return c.TRADE_MAIN_STATE_FINISHED
        } else if (trade.status == c.ORDER_STATUS_NEW) {
            return c.TRADE_MAIN_STATE_ONGOING
        } else if (trade.status == c.ORDER_STATUS_PARTIALLY_FILLED) {
            return c.TRADE_MAIN_STATE_ONGOING
        } else if (trade.status == c.ORDER_STATUS_FILLED) {
            return c.TRADE_MAIN_STATE_FINISHED
        }
    }
}

export function getTradeProfitOrLossOld(trade) {
    const ACTION_TYPE = trade.action_type
    let maxDigits = parseInt(trade.tick_size)
    let result = {
        profit: false,
        loss: false,
        sign: "",
        deltaValue: 0,
        deltaPercent: 0,
        exitPrice: 0,
        exitPriceInPercents: 0,
        posSize: 0,
        excutedQty: 0,
    }
    if (!trade)
        return result
    // if (!trade.child_order || (trade.child_order && trade.child_order.order_id == null)) {
    //     return result
    // }
    // if (tradeState !== c.TRADE_STATE_WITH_CHILD_FILLED)
    //     return result
    let {quote, base} = getQuoteAndBase(trade)
    let buyPrice = parseFloat(trade.buy_price)
    let percentageExecuted = 0
    let executed_qty = 0
    let cummulative_quote_qty = 0
    let input = 0

    let output = 0
    let cummulative_quote_qty_sum = 0
    let executed_qty_sum = 0
    let tpTargets = trade.take_profit_targets ? parseInt(trade.take_profit_targets): 0

    if (trade.child_order) {
        if (trade.from_SUC && tpTargets === 2 && trade.take_profit_target_1_reached) {
            //this is a special case
            if (trade.last_action === c.TRADE_OP_USER_TAKES_PROFIT) {
                cummulative_quote_qty = parseFloat(trade.child_order.cummulative_quote_qty) + parseFloat(trade.child_order.cummulative_quote_qty_sum)
                executed_qty = parseFloat(trade.child_order.executed_qty) + parseFloat(trade.child_order.executed_qty_sum)
            } else {
                cummulative_quote_qty = parseFloat(trade.child_order.cummulative_quote_qty)
                executed_qty = parseFloat(trade.child_order.executed_qty)
            }
            if (ACTION_TYPE === c.ACTION_TYPE_BUY) {
                output = cummulative_quote_qty
                percentageExecuted = executed_qty / parseFloat(trade.orig_qty) * 100
            } else if (ACTION_TYPE === c.ACTION_TYPE_SELL) {
                output = executed_qty
                percentageExecuted = cummulative_quote_qty / parseFloat(trade.cummulative_quote_qty) * 100
                //We need this estimation because usually for a 'SHORT' trade, you won't be able to
                //buy with the exact same amount of the 'base' crypto that you've received when you've sold.
                //This happens because of the limitations of the exchange in terms of tickSize and precision.
                if (percentageExecuted > 99)
                    percentageExecuted = 100
            }
        } else {
            cummulative_quote_qty_sum = parseFloat(trade.child_order.cummulative_quote_qty_sum)
            if (isNaN(cummulative_quote_qty_sum))
                cummulative_quote_qty_sum = 0
            executed_qty_sum = parseFloat(trade.child_order.executed_qty_sum)
            if (isNaN(executed_qty_sum))
                executed_qty_sum = 0

            cummulative_quote_qty = parseFloat(trade.child_order.cummulative_quote_qty) + cummulative_quote_qty_sum
            executed_qty = parseFloat(trade.child_order.executed_qty) + executed_qty_sum
            // percentageExecuted = executed_qty / parseFloat(trade.orig_qty) * 100

            if (ACTION_TYPE === c.ACTION_TYPE_BUY) {
                output = cummulative_quote_qty
                percentageExecuted = executed_qty / parseFloat(trade.orig_qty) * 100
            } else if (ACTION_TYPE === c.ACTION_TYPE_SELL) {
                output = executed_qty
                if (trade.type === c.ORDER_TYPE_FAKE_PARENT) {
                    percentageExecuted = cummulative_quote_qty / parseFloat(trade.cummulative_quote_qty) * 100
                } else {
                    percentageExecuted = cummulative_quote_qty / parseFloat(trade.cummulative_quote_qty_before_fees) * 100
                }
                //We need this estimation because usually for a 'SHORT' trade, you won't be able to
                //buy with the exact same amount of the 'base' crypto that you've received when you've sold.
                //This happens because of the limitations of the exchange in terms of tickSize and precision.
                if (percentageExecuted > 98)
                    percentageExecuted = 100
            }
        }
    }
    if (ACTION_TYPE === c.ACTION_TYPE_BUY) {
        input = parseFloat(trade.pos_size) * percentageExecuted / 100
    } else if (ACTION_TYPE === c.ACTION_TYPE_SELL) {
        input = parseFloat(trade.pos_size) * percentageExecuted / 100
    }
    let delta = output - input
    let deltaPercent = (delta/input) * 100
    // console.log("percentageExecuted %s, input %s, output %s, delta %s, deltaPercenta %s", percentageExecuted, input, output, delta, deltaPercent)
    let exitPrice = 0
    if (trade.child_order && trade.child_order.order_id) {
        if (trade.children) {
            let cummulative_quote_qty = parseFloat(trade.child_order.cummulative_quote_qty_sum) + parseFloat(trade.child_order.cummulative_quote_qty)
            let executed_qty = parseFloat(trade.child_order.executed_qty_sum) + parseFloat(trade.child_order.executed_qty)
            exitPrice = cummulative_quote_qty / executed_qty
        } else {
            if (trade.child_order.executed_qty_sum)
                exitPrice = parseFloat(trade.child_order.cummulative_quote_qty_sum) / parseFloat(trade.child_order.executed_qty_sum)
            else
                exitPrice = parseFloat(trade.child_order.cummulative_quote_qty) / parseFloat(trade.child_order.executed_qty)
        }
    }


    let exitPricePercent = ((exitPrice - buyPrice) / buyPrice) * 100
    result.profit = delta >= 0
    result.loss = delta < 0
    result.sign = delta >= 0 ? "+" : ""

    result.deltaValue = brushValue(delta, precisionByBase[base])
    result.deltaPercent = brushValue(deltaPercent, 2)
    result.exitPrice = brushValue(exitPrice, maxDigits)
    result.exitPriceInPercents = brushValue(exitPricePercent,1)
    result.posSize = trade.pos_size
    result.executedQty = brushValue(trade.executed_qty)

    return result
}

export function getTradeProfitOrLoss(trade) {
    let result = {
        profit: false,
        loss: false,
        sign: "",
        deltaValue: 0,
        deltaPercent: 0,
        currency: "",
        exitPrice: 0,
        exitPriceInPercents: 0,
        posSize: 0,
        excutedQty: 0,
    }
    if (!trade)
        return result
    let t = trade
    let ACTION_TYPE = t.action_type
    let profitInBaseCurrency = 0
    let invested = 0
    let profitInPercentage = 0
    let cummulative_quote_qty = 0
    let cummulative_quote_qty_sum = 0
    let executed_qty = 0
    let executed_qty_sum = 0
    let percentageExecuted = 0
    let {quote, base} = getQuoteAndBase(t)

    let tpTargets = t.take_profit_targets ? parseInt(t.take_profit_targets): 0
    if (t.from_SUC && tpTargets === 2 && t.take_profit_target_1_reached) {
        //this is a special case
        //This was wrong, was giving a profit higher than the real one, because
        //for trade of this specific, the t.child_order.cummulative_quote_qty_sum had already the sum of 0 + first take profit target execution
        //so this was used just to calculate the final t.child_order.cummulative_quote_qty so there is no need to add them here again
        // cummulative_quote_qty_sum = t.child_order.cummulative_quote_qty_sum ? parseFloat(t.child_order.cummulative_quote_qty_sum): 0
        // executed_qty_sum = t.child_order.executed_qty_sum ? parseFloat(t.child_order.executed_qty_sum) : 0
        // cummulative_quote_qty = parseFloat(t.child_order.cummulative_quote_qty) + cummulative_quote_qty_sum
        // executed_qty = parseFloat(t.child_order.executed_qty) + executed_qty_sum
        if (t.last_action === c.TRADE_OP_USER_TAKES_PROFIT) {
            //The user manually takes profit, so take profit target 2 never gets reached
            cummulative_quote_qty = parseFloat(t.child_order.cummulative_quote_qty) + parseFloat(t.child_order.cummulative_quote_qty_sum)
            executed_qty = parseFloat(t.child_order.executed_qty) + parseFloat(t.child_order.executed_qty_sum)
        } else {
            cummulative_quote_qty = parseFloat(t.child_order.cummulative_quote_qty)
            executed_qty = parseFloat(t.child_order.executed_qty)
        }
        if (ACTION_TYPE === c.ACTION_TYPE_BUY) {
            percentageExecuted = executed_qty / parseFloat(t.orig_qty) * 100
        } else if (ACTION_TYPE === c.ACTION_TYPE_SELL) {
            percentageExecuted = cummulative_quote_qty / parseFloat(t.cummulative_quote_qty) * 100
            //We need this estimation because usually for a 'SHORT' trade, you won't be able to
            //buy with the exact same amount of the 'base' crypto that you've received when you've sold.
            //This happens because of the limitations of the exchange in terms of tickSize and precision.
            if (percentageExecuted > 99)
                percentageExecuted = 100
        }
    } else {
        cummulative_quote_qty_sum = t.child_order ? parseFloat(t.child_order.cummulative_quote_qty_sum): 0
        if (isNaN(cummulative_quote_qty_sum))
            cummulative_quote_qty_sum = 0
        executed_qty_sum = t.child_order ? parseFloat(t.child_order.executed_qty_sum): 0
        if (isNaN(executed_qty_sum))
            executed_qty_sum = 0

        let lastChildCummulativeQuoteQty = 0
        let lastChildExecutedQty = 0
        //An child order can end up in two cases:
        //1 Filled and that means that we need to add its cummulativeQuoteQty and executedQty to the sums
        //2 Canceled, and that meant that its cummulativeQuoteQty and executedQty were already added to the sums in the
        //exchangeOps.stopChild method.
        if (t.child_order && t.child_order.status === "FILLED") {
            lastChildCummulativeQuoteQty = parseFloat(t.child_order.cummulative_quote_qty)
            lastChildExecutedQty = parseFloat(t.child_order.executed_qty)
        }
        cummulative_quote_qty = lastChildCummulativeQuoteQty + cummulative_quote_qty_sum
        executed_qty = lastChildExecutedQty + executed_qty_sum
        if (ACTION_TYPE === c.ACTION_TYPE_BUY) {
            percentageExecuted = executed_qty / parseFloat(t.orig_qty) * 100
        }
        if (ACTION_TYPE === c.ACTION_TYPE_SELL) {
            percentageExecuted = cummulative_quote_qty / parseFloat(t.cummulative_quote_qty) * 100
        }
    }

    //For the case when t.orig_qty or t.cummulative_quote_qty is zero, and so we have a division by zero
    if (isNaN(percentageExecuted))
        percentageExecuted = 0

    //We need this estimation because usually for a 'SHORT' trade, you won't be able to
    //buy with the exact same amount of the 'base' crypto that you've received when you've sold.
    //This happens because of the limitations of the exchange in terms of tickSize and precision.
    if (ACTION_TYPE === c.ACTION_TYPE_SELL && percentageExecuted > 98)
        percentageExecuted = 100

    // log.debug("%s onClose: cummulative_quote_qty %s, executed_qty %s, percentageExecuted %s. trade.children %s, trade %s", _logPrefix,
    //     cummulative_quote_qty, executed_qty, percentageExecuted.toFixed(2),
    //     (t.children ? t.children.length : "null"), JSON.stringify(t))

    if (ACTION_TYPE === c.ACTION_TYPE_BUY) {
        invested = parseFloat(t.pos_size) * percentageExecuted / 100
        profitInBaseCurrency = cummulative_quote_qty  - invested
    } else if (ACTION_TYPE === c.ACTION_TYPE_SELL) {
        invested = parseFloat(t.pos_size) * percentageExecuted / 100
        profitInBaseCurrency = executed_qty  - invested
    }

    profitInPercentage = profitInBaseCurrency / invested * 100
    if (isNaN(profitInPercentage)) {
        //It might be the case when we have a canceled trade, and the percentage executed was zero
        profitInPercentage = 0
    }

    result.profit = profitInBaseCurrency >= 0
    result.loss = profitInBaseCurrency < 0
    result.sign = profitInBaseCurrency < 0 ? "-": ""

    let decimalsShown = 0
    if (ACTION_TYPE === c.ACTION_TYPE_BUY)
        decimalsShown = t.tick_size
    else if (ACTION_TYPE === c.ACTION_TYPE_SELL)
        decimalsShown = t.precision
    result.deltaValue = profitInBaseCurrency.toFixed(decimalsShown)
    result.deltaPercent = profitInPercentage.toFixed(2)

    if (ACTION_TYPE === c.ACTION_TYPE_BUY)
        result.currency = base
    else if (ACTION_TYPE === c.ACTION_TYPE_SELL)
        result.currency = quote

    let entryPrice = 0
    if (ACTION_TYPE === c.ACTION_TYPE_BUY)
        entryPrice = parseFloat(t.buy_price)
    else if (ACTION_TYPE === c.ACTION_TYPE_SELL)
        entryPrice = parseFloat(t.sell_price)

    let exitPrice = 0
    if (t.child_order && t.child_order.order_id) {
        if (t.children) {
            let cummulative_quote_qty = parseFloat(t.child_order.cummulative_quote_qty_sum) + parseFloat(t.child_order.cummulative_quote_qty)
            let executed_qty = parseFloat(t.child_order.executed_qty_sum) + parseFloat(t.child_order.executed_qty)
            exitPrice = cummulative_quote_qty / executed_qty
        } else {
            if (t.child_order.executed_qty_sum)
                exitPrice = parseFloat(t.child_order.cummulative_quote_qty_sum) / parseFloat(t.child_order.executed_qty_sum)
            else
                exitPrice = parseFloat(t.child_order.cummulative_quote_qty) / parseFloat(t.child_order.executed_qty)
        }
    }

    let exitPricePercent = ((exitPrice - entryPrice) / entryPrice) * 100
    result.exitPrice = exitPrice.toFixed(t.tick_size)
    result.exitPriceInPercents = exitPricePercent.toFixed(2)


    return result
}

export function getTradeProfitOrLossFutures(futuresTrade) {

    let leverage = parseInt(futuresTrade.leverage)
    let precision = parseInt(futuresTrade.tick_size)
    let result = {
        profit: false,
        loss: false,
        sign: "",
        deltaValue: 0,
        deltaPercent: 0,
        exitPrice: 0,
        exitPriceInPercents: 0,
        excutedQty: 0,
    }
    if (!futuresTrade)
        return result
    let {base} = getQuoteAndBase(futuresTrade)
    let entryPrice = parseFloat(futuresTrade.buy_price)
    let percentageExecuted = 0
    let executed_qty = 0
    let cum_quote = 0
    let input = 0

    let output = 0
    let cum_quote_sum = 0
    let executed_qty_sum = 0
    let tpTargets = futuresTrade.take_profit_targets ? parseInt(futuresTrade.take_profit_targets): 0

    if (futuresTrade.child_order) {
        if (futuresTrade.from_SUC && tpTargets === 2 && futuresTrade.take_profit_target_1_reached) {
            //this is a special case
            if (futuresTrade.last_action === c.TRADE_OP_USER_TAKES_PROFIT) {
                cum_quote = parseFloat(futuresTrade.child_order.cum_quote) + parseFloat(futuresTrade.child_order.cum_quote_sum)
                executed_qty = parseFloat(futuresTrade.child_order.executed_qty) + parseFloat(futuresTrade.child_order.executed_qty_sum)
            } else {
                cum_quote = parseFloat(futuresTrade.child_order.cum_quote)
                executed_qty = parseFloat(futuresTrade.child_order.executed_qty)
            }
            output = cum_quote
            percentageExecuted = executed_qty / parseFloat(futuresTrade.orig_qty) * 100
        } else {
            cum_quote_sum = parseFloat(futuresTrade.child_order.cum_quote_sum)
            if (isNaN(cum_quote_sum))
                cum_quote_sum = 0
            executed_qty_sum = parseFloat(futuresTrade.child_order.executed_qty_sum)
            if (isNaN(executed_qty_sum))
                executed_qty_sum = 0

            cum_quote = parseFloat(futuresTrade.child_order.cum_quote) + cum_quote_sum
            executed_qty = parseFloat(futuresTrade.child_order.executed_qty) + executed_qty_sum
            // percentageExecuted = executed_qty / parseFloat(futuresTrade.orig_qty) * 100

            output = cum_quote
            percentageExecuted = executed_qty / parseFloat(futuresTrade.orig_qty) * 100
        }
    }

    let signByFuturesType = futuresTrade.futures_type === c.FUTURES_TYPE_SHORT_s? -1: 1
    input = parseFloat(futuresTrade.notional_value) * percentageExecuted / 100
    let delta = (output - input) * signByFuturesType
    let deltaPercent = delta / parseFloat(futuresTrade.cost) * 100

    let exitPrice = 0
    if (futuresTrade.child_order && futuresTrade.child_order.order_id) {
        if (futuresTrade.children) {
            let cum_quote = parseFloat(futuresTrade.child_order.cum_quote_sum) + parseFloat(futuresTrade.child_order.cum_quote)
            let executed_qty = parseFloat(futuresTrade.child_order.executed_qty_sum) + parseFloat(futuresTrade.child_order.executed_qty)
            exitPrice = cum_quote / executed_qty
        } else {
            if (futuresTrade.child_order.executed_qty_sum)
                exitPrice = parseFloat(futuresTrade.child_order.cum_quote_sum) / parseFloat(futuresTrade.child_order.executed_qty_sum)
            else
                exitPrice = parseFloat(futuresTrade.child_order.cum_quote) / parseFloat(futuresTrade.child_order.executed_qty)
        }
    }


    let exitPricePercent = ((exitPrice - entryPrice) / entryPrice) * 100
    result.profit = delta >= 0
    result.loss = delta < 0
    result.sign = delta >= 0 ? "+" : ""

    result.deltaValue = brushValue(delta, futuresTrade.tick_size)
    result.deltaPercent = brushValue(deltaPercent, 2)
    result.exitPrice = brushValue(exitPrice, precision)
    result.exitPriceInPercents = brushValue(exitPricePercent,1)
    result.notional_value = futuresTrade.notional_value
    result.executedQty = brushValue(futuresTrade.executed_qty)

    return result
}

export function getHRStateForTrade(trade) {
    if (trade.child_order && trade.child_order.status !== c.ORDER_STATUS_NEW) {
        switch (trade.child_order.status) {
            case c.ORDER_STATUS_FILLED: return {hrStateText: true, hrStateSymbol: "◼", hrStateSymbolColor: "#FE484F", blinking: false}
            case c.ORDER_STATUS_CANCELED: return {hrStateText: true, hrStateSymbol: "❚❚", hrStateSymbolColor: "#E8E288", blinking: false}
            case c.ORDER_STATUS_ERROR: return {hrStateText: true, hrStateSymbol: "☢", hrStateSymbolColor: "#FE484F", blinking: false}
        }
    }

    if (trade.child_order && trade.child_order.sub_type) {
        switch (trade.child_order.sub_type) {
            case "STOP_LOSS": return {hrStateText: true, hrStateSymbol: "▶", hrStateSymbolColor: "#68FF7D", blinking: true}
            case "TAKE_LOSS": return {hrStateText: <div>Taking loss (auto)<br/>at price {brushValue(trade.child_order.limit_price, 8)}</div>, hrStateSymbol: "▼", hrStateSymbolColor: "#FE484F", blinking: true}
            case "TAKE_PROFIT": return {hrStateText: <div>Taking profit (auto)<br/>at price {brushValue(trade.child_order.price, 8)}</div>,  hrStateSymbol: "▲", hrStateSymbolColor: "#17BEBB", blinking: true}
            case "MANUAL_TAKE_PROFIT": return {hrStateText: <div>Taking profit (by you)<br/>at price {brushValue(trade.child_order.price, 8)}</div>, hrStateSymbol: "▲", hrStateSymbolColor: "#17BEBB", blinking: true}
            default: return {hrStateText: true, hrStateSymbol: "▶", hrStateSymbolColor: "#68FF7D", blinking: true}
        }
    }

    return {hrState: undefined, hrStateSymbol: "...", blinking: true}
}


export function getHRStateForBTD(btd) {
    if (!btd.status)
        return {hrStateText: true, hrStateSymbol: "▶", hrStateSymbolColor: "#68FF7D", blinking: true}
    switch (btd.status) {
        case c.BTD_STATUS_WAITING: return {hrStateText: true, hrStateSymbol: "▶", hrStateSymbolColor: "#68FF7D", blinking: true}
        case c.BTD_STATUS_DIP_REACHED: return {hrStateText: "Dip reached",  hrStateSymbol: "", hrStateSymbolColor: "#17BEBB", blinking: false}
        case c.BTD_STATUS_CANCELED: return {hrStateText: "Canceled",  hrStateSymbol: "", hrStateSymbolColor: "#17BEBB", blinking: false}
        default: return {hrStateText: true, hrStateSymbol: "?", hrStateSymbolColor: "#68FF7D", blinking: false}
    }
}

export function getHRStateForFuturesTrade(futuresTrade) {
    if (futuresTrade.child_order && futuresTrade.child_order.status !== c.FUTURES_ORDER_STATUS_NEW) {
        switch (futuresTrade.child_order.status) {
            case c.FUTURES_ORDER_STATUS_FILLED: return {hrStateText: true, hrStateSymbol: "◼", hrStateSymbolColor: "#FE484F", blinking: false}
            case c.FUTURES_ORDER_STATUS_CANCELED: return {hrStateText: true, hrStateSymbol: "❚❚", hrStateSymbolColor: "#E8E288", blinking: false}
            case c.FUTURES_ORDER_STATUS_ERROR: return {hrStateText: true, hrStateSymbol: "☢", hrStateSymbolColor: "#FE484F", blinking: false}
        }
    }

    if (futuresTrade.child_order && futuresTrade.child_order.sub_type) {
        switch (futuresTrade.child_order.sub_type) {
            case "STOP_LOSS": return {hrStateText: true, hrStateSymbol: "▶", hrStateSymbolColor: "#68FF7D", blinking: true}
            case "TAKE_LOSS": return {hrStateText: <div>Taking loss (auto)<br/>at price {brushValue(futuresTrade.child_order.price, 8)}</div>, hrStateSymbol: "▼", hrStateSymbolColor: "#FE484F", blinking: true}
            case "TAKE_PROFIT": return {hrStateText: <div>Taking profit (auto)<br/>at price {brushValue(futuresTrade.child_order.price, 8)}</div>,  hrStateSymbol: "▲", hrStateSymbolColor: "#17BEBB", blinking: true}
            case "MANUAL_TAKE_PROFIT": return {hrStateText: <div>Taking profit (by you)<br/>at price {brushValue(futuresTrade.child_order.price, 8)}</div>, hrStateSymbol: "▲", hrStateSymbolColor: "#17BEBB", blinking: true}
            default: return {hrStateText: true, hrStateSymbol: "▶", hrStateSymbolColor: "#68FF7D", blinking: true}
        }
    }

    return {hrState: undefined, hrStateSymbol: "...", blinking: true}
}

export function getAllowedToEditTradeConditions(trade) {
    if (trade.from_SUC && (trade.connected_to_signal === true || trade.connected_to_signal === "true")) {
        return false
    } else if (trade.child_order && trade.child_order.order_id) {
        if (trade.child_order.sub_type === "MANUAL_TAKE_PROFIT")
            return false
        if (trade.child_order.sub_type === "TAKE_PROFIT")
            return false
        if (trade.child_order.sub_type === "TAKE_LOSS")
            return false
        if (trade.child_order.sub_type === "STOP_LOSS")
            return true
        if (trade.child_order.sub_type === "TAKE_PROFIT_TARGET_1_FILLED")
            return true
    } else {
        return true
    }
}

export function getAllowedToEditEnterTradeConditions(trade, ASAS) {

    if (ASAS && ASAS.suspendActivities === "true" && ASAS.suspendedActivitiesMap &&
        ASAS.suspendedActivitiesMap[c.SUSPENDED_ACTIVITIES_EDIT_ENTER_TRADE_CONDITIONS]) {
        return false
    }

    if (trade.child_order && trade.child_order.order_id) {
        return false
    } else {
        return true
    }
}

export function getAllowedToEditFuturesOrderConditions(trade, ASAS) {

    if (ASAS && ASAS.suspendActivities === "true" && ASAS.suspendedActivitiesMap &&
        ASAS.suspendedActivitiesMap[c.SUSPENDED_ACTIVITIES_EDIT_ENTER_TRADE_CONDITIONS]) {
        return false
    }

    if (parseFloat(trade.executed_qty) !== 0)
        return false

    if (trade.child_order && trade.child_order.order_id) {
        return false
    } else {
        return true
    }
}

export function getAllowedToEditEnterOrderConditions(trade, ASAS) {

    if (ASAS && ASAS.suspendActivities === "true" && ASAS.suspendedActivitiesMap &&
        ASAS.suspendedActivitiesMap[c.SUSPENDED_ACTIVITIES_NEW_CONVERSION_ID]) {
        return false
    }
    if (trade.child_order && trade.child_order.order_id) {
        return false
    } else {
        return true
    }
}


export function getAllowedToEditBTDConditions(btd) {
    if (btd.status === c.BTD_STATUS_WAITING)
        return true
    return false
}

export function getPosSize(trade) {
    if (trade.type === c.ORDER_TYPE_FAKE_PARENT) {
        if (trade.action_type === c.ACTION_TYPE_BUY) {
            let posSize = parseFloat(trade.pos_size)
            let buyPrice = parseFloat(trade.buy_price)
            return   brushValue(posSize * buyPrice, precisionByBase[trade.base_currency])
        } else if (trade.action_type === c.ACTION_TYPE_SELL) {
            return trade.executed_qty
        }
    }

    if (trade.status === c.ORDER_STATUS_CANCELED) {
        return brushValue(trade.pos_size, precisionByBase[trade.base_currency])
    }

    return brushValue(trade.pos_size, precisionByBase[trade.base_currency])
}

export function getCummulativeQuoteQty(trade) {
    let {quote, base} = getQuoteAndBase(trade)
    return brushValue(trade.cummulative_quote_qty, c.BASE_PRECISIONS[base])
}

export function getCummulativeQuoteQtyAtOutput(trade) {
    if (trade.child_order && trade.child_order.order_id) {
        if (trade.children) {
            let cummulative_quote_qty = parseFloat(trade.child_order.cummulative_quote_qty_sum) + parseFloat(trade.child_order.cummulative_quote_qty)
            return {cummulativeQuoteQtyAtOutputNo: cummulative_quote_qty, cummulativeQuoteQtyAtOutput: brushValue(cummulative_quote_qty, parseInt(trade.precision))}
        } else {
            return {cummulativeQuoteQtyAtOutputNo: parseFloat(trade.child_order.cummulative_quote_qty),
                cummulativeQuoteQtyAtOutput: brushValue(trade.child_order.cummulative_quote_qty, parseInt(trade.precision))}
        }
    } else {
        return {executedQtyAtOutputNo: 0}
    }
}

export function getReceivedPosSize(trade) {
    let tpTargets = trade.take_profit_targets ? parseInt(trade.take_profit_targets): 0
    if (trade.action_type === c.ACTION_TYPE_BUY) {
        if (trade.child_order && trade.child_order.order_id) {
            if (trade.children) {
                let cummlative_quote_qty = parseFloat(trade.child_order.cummulative_quote_qty_sum) + parseFloat(trade.child_order.cummulative_quote_qty)
                return brushValue(cummlative_quote_qty, precisionByBase[trade.base_currency])
            } else {
                let cummQuoteQty
                if (trade.from_SUC && tpTargets === 2 && trade.take_profit_target_1_reached) {
                    if (trade.last_action === c.TRADE_OP_USER_TAKES_PROFIT) {
                        cummQuoteQty = parseFloat(trade.child_order.cummulative_quote_qty) + parseFloat(trade.child_order.cummulative_quote_qty_sum)
                    } else {
                        cummQuoteQty = parseFloat(trade.child_order.cummulative_quote_qty)
                    }
                } else {
                    cummQuoteQty = trade.child_order.cummulative_quote_qty
                }
                return brushValue(cummQuoteQty, precisionByBase[trade.base_currency])
            }
        } else {
            return 0
        }
    } else if (trade.action_type === c.ACTION_TYPE_SELL) {
        if (trade.child_order && trade.child_order.order_id) {
            if (trade.children) {
                let executed_qty = parseFloat(trade.child_order.executed_qty_sum) + parseFloat(trade.child_order.executed_qty)
                return brushValue(executed_qty, precisionByBase[trade.quote_currency])
            } else {
                return brushValue(trade.child_order.executed_qty, precisionByBase[trade.quote_currency])
            }
        } else {
            return 0
        }
    }

}

let formatAttrValue = (attrValue) => {
    // if (Array.isArray(attrValue)) {
    //     JSON.stringify(attrValue)
    // } else {
    //     return attrValue
    // }
    return JSON.stringify(attrValue)
}

export function stringifyForHTML(obj, suffix) {
    if (!obj)
        return null
    let elems = []
    let attributes = Object.keys(obj)
    let k = 0
    for(let i in attributes) {
        k++
        let attrName = attributes[i]
        let attrValue = obj[attrName]
        if (k === attributes.length)
            elems.push(<div style={{marginBottom: "1rem"}}>{attrName}: {formatAttrValue(attrValue)}{suffix}</div>)
        else
            elems.push(<div>{attrName}: {formatAttrValue(attrValue)}</div>)

    }
    return <div>{elems}</div>
}

export function stringifyArrayForHTML(obj) {
    let elems = []
    if (Array.isArray(obj)) {
        for(let i in obj) {
            elems.push(<div>{stringifyForHTML(obj[i], ",")}</div>)
        }
    }
    return <div>{elems}</div>
}

export function  getValueWithPrecision(value, precision) {
    if (!value)
        return 0
    if (isNaN(value))
        return 0
    if (isNumberInScientificNotation(value))
        value = scientificToDecimal(value)
    value = `${value}`
    if (value[0] === "+" || value[0] === "-")
        value = value.substr(1, value.length)

    let parts = value.split(".")
    if (parts.length === 1) {
        return parts[0]
    } else {
        return `${parts[0]}.${parts[1].substr(0, precision)}`
    }

}

export function capitalize (s) {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export function getExecutedQtyAtInput(trade) {
    return brushValue(trade.executed_qty, trade.precision)
}

export function getExecutedQtyAtOutput(trade) {
    let tpTargets = trade.take_profit_targets ? parseInt(trade.take_profit_targets): 0
    if (trade.child_order && trade.child_order.order_id) {
        if (trade.children) {
            let executed_qty = parseFloat(trade.child_order.executed_qty_sum) + parseFloat(trade.child_order.executed_qty)
            return {executedQtyAtOutputNo: executed_qty, executedQtyAtOutput: brushValue(executed_qty, trade.precision)}
        } else {
            let execQty
            if (trade.from_SUC && tpTargets === 2 && trade.take_profit_target_1_reached) {
                if (trade.last_action === c.TRADE_OP_USER_TAKES_PROFIT) {
                    // cummulative_quote_qty = parseFloat(trade.child_order.cummulative_quote_qty) + parseFloat(trade.child_order.cummulative_quote_qty_sum)
                    execQty = parseFloat(trade.child_order.executed_qty) + parseFloat(trade.child_order.executed_qty_sum)
                } else {
                    // cummulative_quote_qty = parseFloat(trade.child_order.cummulative_quote_qty)
                    execQty = parseFloat(trade.child_order.executed_qty)
                }
            } else {
                execQty = parseFloat(trade.child_order.executed_qty)
            }
            return {executedQtyAtOutputNo: parseFloat(execQty), executedQtyAtOutput: brushValue(execQty, trade.precision)}
        }
    } else {
        return {executedQtyAtOutputNo: 0}
    }
}

export function hasTradeChild(trade) {
    if (trade.child_order && trade.child_order.order_id)
        return true
    return false
}

export function isValidPair(quote, base, quoteCurrenciesNom) {
    if (quote && quote.length > 0 && base && base.length > 0) {
        let quotes = quoteCurrenciesNom ? quoteCurrenciesNom[base] : undefined
        if (quotes && quotes.length > 0) {
            let q = quote.toUpperCase()
            let elem = quotes.filter(obj => (obj === q))
            return elem.length !== 0
        }
    }
    return false
}

export function getAllowedToEditSUCConditions() {}

export function getSUCStateTitle(suc) {
    if (!suc)
        return "Non existent"
    if (!suc.status)
        return "?"
    if (suc.status === c.SUC_STATUS_WAITING)
        return "Waiting"
    else if (suc.status === c.SUC_STATUS_MATERIALIZED)
        return "Trade started"
    else if (suc.status === c.SUC_STATUS_CLOSED)
        return "Closed"
}

export function getCurrentAndBuyPriceForSUC(suc) {
    if (!suc)
        return {currentPrice: 0, buyPrice: 0}

    let buyPrice = brushValue(suc.buy_price, 8)
    let currentPrice = brushValue(suc.current_price, 8)

    return {currentPrice: currentPrice,
        buyPrice: buyPrice}
}

export function getHRStateForSUC(suc) {
    if (!suc.status)
        return {hrStateText: true, hrStateSymbol: "▶", hrStateSymbolColor: "#68FF7D", blinking: true}
    switch (suc.status) {
        case c.SUC_STATUS_WAITING: return {hrStateText: true, hrStateSymbol: "▶", hrStateSymbolColor: "#68FF7D", blinking: true}
        case c.SUC_STATUS_MATERIALIZED: return {hrStateText: "Trade started",  hrStateSymbol: "", hrStateSymbolColor: "#17BEBB", blinking: false}
        case c.SUC_STATUS_CLOSED: return {hrStateText: "Closed",  hrStateSymbol: "", hrStateSymbolColor: "#17BEBB", blinking: false}
        default: return {hrStateText: true, hrStateSymbol: "?", hrStateSymbolColor: "#68FF7D", blinking: false}
    }
}

export function getTradeConditionsContentForSUCDetails(suc) {
    let {tpc, slc} = getTakeProfitAndStopLossConfigsForSUC(suc)
    let tpContent = [], slContent
    let ACTION_TYPE = suc.action_type
    if (tpc.type == c.TAKE_PROFIT_OPTION_PRICE) {
        for(let i = 0; i < tpc.targets.length; i++) {
            let tpt = tpc.targets[i]
            tpContent.push(<div><b>Take profit #{i+1}</b> at <b>{tpt.price}</b> <span>({tpt.percent > 0 && <span>+</span>}{tpt.percent}%)</span></div>)
        }
    } else if (suc.take_profit_option == c.TAKE_PROFIT_OPTION_PERCENTAGE) {
        for(let i = 0; i < tpc.targets.length; i++) {
            let tpt = tpc.targets[i]
            tpContent.push(<div><b>Take profit #{i+1}</b> at <b>+{tpt.percent}%</b> <span>({tpt.price})</span></div>)
        }
    }

    if (slc.type == c.STOP_LOSS_OPTION_PRICE) {
        slContent = <span><b>Stop loss</b> at <b>{slc.price}</b> <span>({slc.percent}%)</span></span>
    } else if (suc.stop_loss_option == c.STOP_LOSS_OPTION_PERCENTAGE) {
        slContent = <span><b>Stop loss</b> at <b>-{slc.percent}%</b> <span>({slc.price})</span></span>
    } else if (suc.stop_loss_option == c.STOP_LOSS_OPTION_MANUAL) {
        slContent = <span><b>Stop loss</b> <b>MANUAL</b></span>
    } else if (suc.stop_loss_option === c.STOP_LOSS_OPTION_CONDITIONAL) {
        slContent = <span><b>Stop loss</b> if <b>{c.CANDLES_TYPES_HR_MAP[slc.candlesType]}</b> candle closes&nbsp;
            {ACTION_TYPE === c.ACTION_TYPE_BUY && <span>below</span>}
            {ACTION_TYPE === c.ACTION_TYPE_SELL && <span>above</span>} <b>{slc.price}</b></span>
    }

    return {tpContent: tpContent, slContent: slContent}
}

export function numberWithCommas(x, toFixed=8) {
    let y = parseFloat(`${x}`).toFixed(toFixed)
    let parts = y.split(".");
    if (parts.length === 2) {
        return `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${parts[1]}`
    } else return `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
}


export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export function isBLVT(coin) {
    if (!coin || coin === undefined)
        return
    let prefix = ""
    if (coin.endsWith("UP"))
        prefix = coin.substring(0, coin.length-2)
    else if (coin.endsWith("DOWN"))
        prefix = coin.substring(0, coin.length-4)
    return s.BLVTS[prefix]
}

export function isFuturesOpenedOrder (futuresTrade) {
    if (!futuresTrade)
        return false

    if (!(futuresTrade.child_order && futuresTrade.child_order.order_id)) {
        return (futuresTrade.status === "NEW" || futuresTrade.status === "PARTIALLY_FILLED")
    }
}

export function isFuturesClosedOrder (futuresTrade) {
    if (!futuresTrade)
        return false

    if (!(futuresTrade.child_order && futuresTrade.child_order.order_id)) {
        return !(futuresTrade.status === "NEW" || futuresTrade.status === "PARTIALLY_FILLED")
    }
}

export function isFuturesOpenedPosition (futuresTrade) {
    if (!futuresTrade)
        return false
    if (futuresTrade.child_order && futuresTrade.child_order.order_id) {
        return (futuresTrade.child_order.status === "NEW" || futuresTrade.child_order.status === "PARTIALLY_FILLED")
    }
    return false
}

export function isFuturesClosedPosition (futuresTrade) {
    if (!futuresTrade)
        return false
    if (futuresTrade.child_order && futuresTrade.child_order.order_id) {
        return !(futuresTrade.child_order.status === "NEW" || futuresTrade.child_order.status === "PARTIALLY_FILLED")
    }
    return false
}

export function getFuturesCardSymbolWidth(fTrade) {
    let width = "10rem"
    if (fTrade && fTrade.pair) {
        switch (fTrade.pair.length) {
            case 8: width = "11rem"; break;
            case 9: width = "12rem"; break;
        }
    }
    return width
}


export function toFixedAbsolutCeil(value, precision) {
    let sign = value >= 0 ? 1: -1
    value = Math.abs(value)
    let tenToPow = Math.pow(10, precision)
    let q = value * tenToPow
    q = Math.ceil(q)
    q = q / tenToPow
    return sign * parseFloat(q.toFixed(precision))
}


export function changeFuturesTakeProfitOption(option, _this, currentPrice, force = false) {
    console.log("changeFuturesTakeProfitOption option %s, currentPrice %s", option, currentPrice)
    //If the user unchecks an option either Percentage or Price, it will de-activate it
    //so that it will switch on manual
    if (_this.state.takeProfitOption === option && !force) {
        _this.setState({
            takeProfitOption: c.TAKE_PROFIT_OPTION_MANUAL,
            takeProfitPriceOrPercent: "manual"
        })
        return
    }

    //Else if the transition is from Percentage or Price, transform
    //percentage to price and vice-versa
    if (option === c.TAKE_PROFIT_OPTION_PRICE) {
        //This is the transition from Percentage
        let oldPercent = parseFloat(_this.state.takeProfitPriceOrPercent)
        if (isNaN(oldPercent))
            oldPercent = 100
        let sign = 1
        if (_this.state.futuresType === c.FUTURES_TYPE_SHORT || _this.state.futuresType === c.FUTURES_TYPE_SHORT_s) {
            sign = -1
        }
        let price = (1 + sign * oldPercent/_this.state.leverage/100) * parseFloat(currentPrice)
        _this.state.takeProfitPriceOrPercent = getValueWithPrecision(price, _this.state.tickSize)
    } else if (option === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
        //Transition from percentage to price
        let percent = 0
        let oldPrice = parseFloat(_this.state.takeProfitPriceOrPercent)
        if (isNaN(oldPrice)) {
            percent = 10
        } else {
            percent = Math.round((oldPrice - currentPrice) / currentPrice * 100 * _this.state.leverage).toFixed(2)
        }
        _this.state.takeProfitPriceOrPercent = percent
    }

    _this.setState({
        takeProfitOption: option
    })
}

export function changeFuturesStopLossOption(option, _this, currentPrice) {
    //If there is no change, do nothing
    if (option === _this.state.stopLossOption)
        return

    let oldSLOption = _this.state.stopLossOption
    let oldSLOptionType = 0
    switch (oldSLOption) {
        case c.STOP_LOSS_OPTION_PRICE: oldSLOptionType = 1; break;
        case c.STOP_LOSS_OPTION_CONDITIONAL: oldSLOptionType = 1; break;
        case c.STOP_LOSS_OPTION_PERCENTAGE: oldSLOptionType = 2; break;
        case c.STOP_LOSS_OPTION_TRAILING: oldSLOptionType = 2; break;
    }

    //Attention:
    //STOP_LOSS_OPTION_TRAILING - means that the stop loss option is Trailing
    //STOP_LOSS_OPTION_PERCENTAGE_FROM_TRAILING - means that the user switched off the Trailing option and we default to Percentage
    if (option === c.STOP_LOSS_OPTION_PERCENTAGE_FROM_TRAILING) {
        option = c.STOP_LOSS_OPTION_PERCENTAGE
    }

    let optionType = 0
    switch (option) {
        case c.STOP_LOSS_OPTION_PRICE: optionType = 1; break;
        case c.STOP_LOSS_OPTION_CONDITIONAL: optionType = 1; break;
        case c.STOP_LOSS_OPTION_PERCENTAGE: optionType = 2; break;
        case c.STOP_LOSS_OPTION_TRAILING: optionType = 2; break;
    }

    let transitionDelta = oldSLOptionType - optionType

    if (transitionDelta === 0) {
        //no need for calculations
    } else if (transitionDelta === -1) {
        //transition from price to percentage
        let oldPrice = parseFloat(_this.state.stopLossPriceOrPercent)
        _this.state.stopLossPriceOrPercent = Math.abs(Math.round((currentPrice - oldPrice) / currentPrice * 100 * _this.state.leverage)).toFixed(2)
    } else if (transitionDelta === 1) {
        //transition from percentage to price
        let oldPercent = parseFloat(_this.state.stopLossPriceOrPercent)

        let sign = -1
        if (_this.state.futuresType === c.FUTURES_TYPE_SHORT || _this.state.futuresType === c.FUTURES_TYPE_SHORT_s)
            sign = 1
        let price = (1 + sign * oldPercent / 100 / _this.state.leverage) * parseFloat(currentPrice)
        _this.state.stopLossPriceOrPercent = getValueWithPrecision(price, _this.state.tickSize)
    }

    _this.setState({
        stopLossOption: option
    })
}

export function getExecutedQtyAndCummulativeQuoteQty(trade) {
    let executedQty = 0, cummulativeQuoteQty = 0
    if (trade.parent_orders) {
        let sExecutedQty = 0
        let sCummulativeQuoteQty = 0
        for(let i in trade.parent_orders) {
            let o = trade.parent_orders[i]
            sExecutedQty += parseFloat(o.executed_qty)
            sCummulativeQuoteQty += parseFloat(o.cummulative_quote_qty)
        }
        executedQty = sExecutedQty + parseFloat(trade.executed_qty)
        cummulativeQuoteQty = sCummulativeQuoteQty + (trade.cummulative_quote_qty_before_fees ?
            parseFloat(trade.cummulative_quote_qty_before_fees): parseFloat(trade.cummulative_quote_qty))
        if (trade.order_id == "93791501") {
            console.log(JSON.stringify(trade))
        }

    } else {
        executedQty = parseFloat(trade.executed_qty)
        cummulativeQuoteQty = parseFloat(trade.cummulative_quote_qty)
    }


    return {executedQty, cummulativeQuoteQty}
}

export function getClosedFuturesTradeExitPriceAndReceivedNotionalValue(fTrade) {

    if (!fTrade.child_order) {
        return 0
    }

    let cum_quote_sum = parseFloat(fTrade.child_order.cum_quote_sum)
    if (isNaN(cum_quote_sum))
        cum_quote_sum = 0
    let executed_qty_sum = parseFloat(fTrade.child_order.executed_qty_sum)
    if (isNaN(executed_qty_sum))
        executed_qty_sum = 0

    let lastChildCumQuote = 0
    let lastChildExecutedQty = 0
    //An child order can end up in two cases:
    //1 Filled and that means that we need to add its cummulativeQuoteQty and executedQty to the sums
    //2 Canceled, and that meant that its cummulativeQuoteQty and executedQty were already added to the sums in the
    //exchangeOps.stopChild method.
    if (fTrade.child_order.status === "FILLED") {
        lastChildCumQuote = parseFloat(fTrade.child_order.cum_quote)
        lastChildExecutedQty = parseFloat(fTrade.child_order.executed_qty)
    }
    let cum_quote = lastChildCumQuote + cum_quote_sum
    let executed_qty = lastChildExecutedQty + executed_qty_sum
    let exitPrice = brushValue(cum_quote / executed_qty, fTrade.tick_size)
    let receivedNotionalValue = brushValue(cum_quote, fTrade.tick_size)
    return {exitPrice, receivedNotionalValue}
}


export function showThisMonthTraderCommissions(thisMonthTraderCommissions) {
    if (!thisMonthTraderCommissions)
        return
    let sC = thisMonthTraderCommissions.spotCommissions
    let fC = thisMonthTraderCommissions.futuresCommissions
    const decimalsByCoin = {
        "USDT": 2,
        "BTC": 8,
        "ETH": 6,
    }
    let content = []
    if (fC && fC.length > 0) {
        let fCContent = []
        for(let i = 0; i < fC.length; i++) {
            if (fCContent.length > 0) {

            }
            fCContent.push(<span>{fCContent.length > 0 && <span>, </span>}<b>{brushValue(fC[i].v, decimalsByCoin[fC[i].c])}</b><span style={{opacity: "0.8"}}> {fC[i].c}</span></span>)
        }
        content.push(<div style={{display: "flex"}}><div style={{fontSize: "0.7rem", marginTop: "0.2rem", marginRight: "0.4rem"}}>Futures:</div><div>{fCContent}</div></div>)
    }

    if (sC && sC.length > 0) {
        let sCContent = []
        for(let i = 0; i < sC.length; i++) {
            sCContent.push(<span>{sCContent.length > 0 && <span>, </span>}<b>{brushValue(sC[i].v, decimalsByCoin[sC[i].c])}</b><span style={{opacity: "0.8"}}> {sC[i].c}</span></span>)
        }
        if (content.length > 0) {
            content.push(<div style={{marginLeft: "0.2rem", marginRight: "0.2rem"}}>|</div>)
        }
        content.push(<div style={{display: "flex"}}><div style={{fontSize: "0.7rem", marginTop: "0.2rem", marginRight: "0.4rem"}}>Spot:</div><div>{sCContent}</div></div>)
    }

    return <div style={{marginLeft: "1rem", display: "flex", marginTop: "0.3rem"}}>{content}</div>
}