import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import {connect} from "react-redux"
import TimeAgo from 'react-timeago'
import {Create, Clear, ArrowBack} from "@material-ui/icons"
import {
    brushValue, getAllowedToEditBTDConditions, getBTDStateTitle,
    getCurrentAndBuyPriceForBTD,
    getCurrentPriceAndDeltaPercent, getHRStateForBTD, getMaxMinAndDeltaPercent,
    getPosSize,
    getQuoteAndBase,
    getTradeConditionsContentForTradeDetails, getTradeEnterVerbFutureTense, getValueWithPrecision,
    timeAgoFormatter,
    timeFormatter,
} from "../../fncomponents/UISupportFns"
import {Paper} from "@material-ui/core"
import TradeConditions from "../../fncomponents/TradeConditions"
import BTDConditions from "../../fncomponents/BTDConditions"
import c from "../../../constants"
import Fade from '@material-ui/core/Fade'
import CancelItem from "../../fncomponents/CancelItem"
import BTDDetailsMainButtons from "../../fncomponents/BTDDetailsMainButtons"
import DiscardItem from "../../fncomponents/DiscardItem"
import {
    openBTD,
    updateBTDCancelIt,
    updateBTDConditions,
    updateBTDDiscardIt,
    updateBTDTradeConditions
} from "../../../redux/actions/trades"
import {debounce} from "../../hoc/myUtils"
import OrderBook from "../../fncomponents/OrderBook"
import {getTitle} from "../../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"
// import {getOrderBook} from "../../../redux/actions/newTrade"
import {closeWS, initWS} from "../../../redux/actions/wsOrderBook"

class BTDDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isEditBTDTradeConditionsPanelOpen: false,
            isEditBTDConditionsPanelOpen: false,
            isBTDDetailsPanelOpen: true,
            isCancelBTDPanelOpen: false,
            isDiscardBTDPanelOpen: false,
            goBack: false
        }

        this.openEditBTDTradeConditionsPanel = this.openEditBTDTradeConditionsPanel.bind(this)
        this.closeEditBTDTradeConditionsPanel = this.closeEditBTDTradeConditionsPanel.bind(this)
        this.openEditBTDConditionsPanel = this.openEditBTDConditionsPanel.bind(this)
        this.closeEditBTDConditionsPanel = this.closeEditBTDConditionsPanel.bind(this)
        this.handleChangeTakeProfitOption = this.handleChangeTakeProfitOption.bind(this)
        this.handleChangeTakeProfitPriceOrPercent = this.handleChangeTakeProfitPriceOrPercent.bind(this)
        this.fixTakeProfitPriceOrPercent = this.fixTakeProfitPriceOrPercent.bind(this)
        this.debounceFixTakeProfitPriceOrPercent = debounce(this.fixTakeProfitPriceOrPercent, 500)
        this.handleChangeStopLossOption = this.handleChangeStopLossOption.bind(this)
        this.handleChangeStopLossPriceOrPercent = this.handleChangeStopLossPriceOrPercent.bind(this)
        this.fixStopLossPriceOrPercent = this.fixStopLossPriceOrPercent.bind(this)
        this.debounceFixStopLossPriceOrPercent = debounce(this.fixStopLossPriceOrPercent, 500)
        this.updateBTDTradeConditions = this.updateBTDTradeConditions.bind(this)
        this.goBack = this.goBack.bind(this)
        this.openCancelBTDPanel = this.openCancelBTDPanel.bind(this)
        this.closeCancelBTDPanel = this.closeCancelBTDPanel.bind(this)
        this.cancelBTD = this.cancelBTD.bind(this)
        this.openDiscardBTDPanel = this.openDiscardBTDPanel.bind(this)
        this.closeDiscardBTDPanel = this.closeDiscardBTDPanel.bind(this)
        this.discardBTD = this.discardBTD.bind(this)
        this.handleChangePosSize = this.handleChangePosSize.bind(this)
        this.fixPosSize = this.fixPosSize.bind(this)
        this.debounceFixPosSize = debounce(this.fixPosSize, 500)
        this.handleChangeDipPrice = this.handleChangeDipPrice.bind(this)
        this.fixDipPrice = this.fixDipPrice.bind(this)
        this.debounceFixDipPrice = debounce(this.fixDipPrice, 500)
    }

    openEditBTDTradeConditionsPanel() {
        this.setState({
            isEditBTDTradeConditionsPanelOpen: true,
            isBTDDetailsPanelOpen: false
        })
    }

    closeEditBTDTradeConditionsPanel() {
        this.setState({
            isEditBTDTradeConditionsPanelOpen: false,
            isBTDDetailsPanelOpen: true,
        })
    }

    openEditBTDConditionsPanel() {
        this.setState({
            isEditBTDConditionsPanelOpen: true,
            isBTDDetailsPanelOpen: false
        })
    }

    closeEditBTDConditionsPanel() {
        this.setState({
            isEditBTDConditionsPanelOpen: false,
            isBTDDetailsPanelOpen: true,
        })
    }

    updateBTDTradeConditions() {
        this.state.isEditBTDTradeConditionsPanelOpen = false
        this.state.isBTDDetailsPanelOpen = true
        this.props.updateBTDTradeConditions({
            btdId: this.props.btd.buy_the_dip_id,
            takeProfitOption: this.state.takeProfitOption,
            takeProfitPriceOrPercent: this.state.takeProfitPriceOrPercent,
            stopLossOption: this.state.stopLossOption,
            stopLossPriceOrPercent: this.state.stopLossPriceOrPercent
        })
    }

    handleChangeTakeProfitOption(option) {
        //If there is no change, do nothing
        if (this.state.takeProfitOption == option)
            return

        if (this.state.takeProfitOption == c.TAKE_PROFIT_OPTION_MANUAL) {
            //If the transition is from Manual, don't do any calculations
        } else {
            //Else if the transition is from Percentage or Price, transform
            //percentage to price and vice-versa
            if (option == c.TAKE_PROFIT_OPTION_MANUAL) {
                this.state.takeProfitPriceOrPercent = ""
            } else if (option == c.TAKE_PROFIT_OPTION_PRICE) {
                let oldPercent = parseFloat(this.state.takeProfitPriceOrPercent)
                let {currentPrice} = getCurrentAndBuyPriceForBTD(this.props.btd)
                let price = (1 + oldPercent/100) * parseFloat(currentPrice)
                this.state.takeProfitPriceOrPercent = brushValue(price, 8)
            } else if (option == c.TAKE_PROFIT_OPTION_PERCENTAGE) {
                let oldPrice = parseFloat(this.state.takeProfitPriceOrPercent)
                let {currentPrice} = getCurrentAndBuyPriceForBTD(this.props.btd)
                let percent = ((oldPrice - currentPrice) / currentPrice) * 100
                this.state.takeProfitPriceOrPercent = brushValue(percent, 1)
            }
        }



        this.setState({
            takeProfitOption: option
        })
    }

    handleChangeTakeProfitPriceOrPercent(e) {
        let value = e.target && e.target.value ? e.target.value : ""
        if (value === 0 || value.length === 0) {
            this.state.takeProfitOption = c.TAKE_PROFIT_OPTION_MANUAL
        }
        this.setState({
            takeProfitPriceOrPercent: value
        })
        this.debounceFixTakeProfitPriceOrPercent()
    }

    fixTakeProfitPriceOrPercent() {
        if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE)
            this.setState({
                takeProfitPriceOrPercent: getValueWithPrecision(this.state.takeProfitPriceOrPercent, 1)
            })
        else this.setState({
            takeProfitPriceOrPercent: getValueWithPrecision(this.state.takeProfitPriceOrPercent, 8)
        })
    }

    handleChangeStopLossOption(option) {
        //If there is no change, do nothing
        if (this.state.stopLossOption == option)
            return
        if (this.state.stopLossOption == c.STOP_LOSS_OPTION_PERCENTAGE &&
            option == c.STOP_LOSS_OPTION_TRAILING) {
            //Do no calculations percentage -> percentage
        } else if (this.state.stopLossOption == c.STOP_LOSS_OPTION_TRAILING &&
            option == c.STOP_LOSS_OPTION_PERCENTAGE) {
            //Do no calculations percentage -> percentage
        } else {
            //If the transition is from Percentage/Trailing or Price, transform
            //percentage to price and vice-versa
            if (option == c.STOP_LOSS_OPTION_PRICE) {
                let oldPercent = parseFloat(this.state.stopLossPriceOrPercent)
                let {currentPrice} = getCurrentAndBuyPriceForBTD(this.props.btd)
                let price = (1 - oldPercent/100) * parseFloat(currentPrice)
                this.state.stopLossPriceOrPercent = brushValue(price, 8)
            } else if (option == c.STOP_LOSS_OPTION_PERCENTAGE || option == c.STOP_LOSS_OPTION_TRAILING) {
                let oldPrice = parseFloat(this.state.stopLossPriceOrPercent)
                let {currentPrice} = getCurrentAndBuyPriceForBTD(this.props.btd)
                let percent = ((currentPrice - oldPrice) / currentPrice) * 100
                this.state.stopLossPriceOrPercent = brushValue(percent, 1)
            }
        }



        this.setState({
            stopLossOption: option
        })
    }

    handleChangeStopLossPriceOrPercent(e) {
        this.setState({
            stopLossPriceOrPercent: e.target.value
        })

        this.debounceFixStopLossPriceOrPercent()
    }

    fixStopLossPriceOrPercent() {
        if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PRICE)
            this.setState({
                stopLossPriceOrPercent: getValueWithPrecision(this.state.stopLossPriceOrPercent, 8)
            })
        else this.setState({
            stopLossPriceOrPercent: getValueWithPrecision(this.state.stopLossPriceOrPercent, 1)
        })
    }

    goBack() {
        this.setState({
            goBack: true
        })
    }

    handleChangePosSize(e) {
        this.setState({
            posSize: e.target.value
        })

        this.debounceFixPosSize()
    }

    fixPosSize() {
        this.setState({
            posSize: getValueWithPrecision(this.state.posSize, 8)
        })
    }

    handleChangeDipPrice(e) {
        this.setState({
            dipPrice: e.target.value
        })
        this.debounceFixDipPrice()
    }

    fixDipPrice() {
        this.setState({
            dipPrice: getValueWithPrecision(this.state.dipPrice, 8)
        })
    }

    updateBTDConditions() {
        this.state.isEditBTDConditionsPanelOpen = false
        this.state.isBTDDetailsPanelOpen = true
        this.props.updateBTDConditions({
            btdId: this.props.btd.buy_the_dip_id,
            posSize: this.state.posSize,
            dipPrice: this.state.dipPrice,
        })
    }

    openCancelBTDPanel() {
        this.setState({
            isCancelBTDPanelOpen: true,
            isBTDDetailsPanelOpen: false
        })
    }

    closeCancelBTDPanel() {
        this.setState({
            isCancelBTDPanelOpen: false,
            isBTDDetailsPanelOpen: true,
        })
    }

    cancelBTD() {
        this.state.isCancelBTDPanelOpen = false
        this.state.isBTDDetailsPanelOpen = true
        this.props.updateBTDCancelIt({btdId: this.props.btd.buy_the_dip_id})
    }

    openDiscardBTDPanel() {
        this.setState({
            isDiscardBTDPanelOpen: true,
            isBTDDetailsPanelOpen: false
        })
    }

    closeDiscardBTDPanel() {
        this.setState({
            isDiscardBTDPanelOpen: false,
            isBTDDetailsPanelOpen: true,
        })
    }

    discardBTD() {
        this.state.isDiscardBTDPanelOpen = false
        this.state.isBTDDetailsPanelOpen = true
        this.props.updateBTDDiscardIt({btdId: this.props.btd.buy_the_dip_id})
    }

    componentDidMount() {
        if (!this.props.btd)
            return

        let {currentPrice} = getCurrentAndBuyPriceForBTD(this.props.btd)
        const {quote, base} = getQuoteAndBase(this.props.btd)
        this.setState( {
            posSize: this.props.btd.pos_size,
            dipPrice: this.props.btd.dip_price,
            takeProfitOption: this.props.btd.take_profit_option,
            takeProfitPriceOrPercent: this.props.btd.take_profit_price_or_percent,
            stopLossOption: this.props.btd.stop_loss_option,
            stopLossPriceOrPercent: this.props.btd.stop_loss_price_or_percent,
            executeTakeProfitPrice: currentPrice,
            quote: quote,
            base: base
        })
        //this.getOrderBookPeriodically()
        this.props.user && initWS(this.props.user.userId, `${quote}${base}`)
    }

    componentWillUnmount() {
        closeWS()
    }

    render() {
        if (this.state.goBack)
            return <Redirect to="/"/>

        let {btd} = this.props
        if (!btd)
            return <Redirect to="/"/>

        const {quote, base} = getQuoteAndBase(btd)
        const verbFutureTense = getTradeEnterVerbFutureTense(btd)
        const posSize = getPosSize(btd)
        const {currentPriceDeltaPercent, currentPrice, currentPriceClass} = getCurrentPriceAndDeltaPercent(btd)
        const {maxPrice, maxDelta, minPrice, minDelta} = getMaxMinAndDeltaPercent(btd)
        const {tpContent, slContent} = getTradeConditionsContentForTradeDetails(btd, false)
        const stateTitle = getBTDStateTitle(btd)
        const {hrStateText, hrStateSymbol, hrStateSymbolColor, blinking} = getHRStateForBTD(btd)
        const allowedToEditBTDConditions = getAllowedToEditBTDConditions(btd)

        return <div className={"TradeContent"} style={{display: "flex"}}>
                <Helmet>
                    <title>{getTitle()}</title>
                </Helmet>
                <div style={{maxWidth: "30rem", minWidth: "22rem"}}>
                    {this.state.isBTDDetailsPanelOpen &&
                    <Fade in={this.state.isBTDDetailsPanelOpen}>
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.goBack()}}><ArrowBack/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>BTD <span style={{opacity: "0.5"}}>{btd.buy_the_dip_id}</span></div>
                                    <div style={{width: "2.6rem"}}/>
                                </div>
                            </div>
                            <div className={"vertSpacer1"}/>
                            <div className={"tradeDetailsCreationDate"}>
                               <span><b>{stateTitle}</b> for <TimeAgo date={parseInt(btd.time)} formatter={timeAgoFormatter}/> · </span> created on {timeFormatter(btd.time, true)}
                            </div>
                            <div className={"tradeDetailsOngoingAction"}>
                                <div className={"vertSpacer1"}/>
                                {hrStateText} {blinking && <div className="blink liveBig" style={{color: hrStateSymbolColor}}>{hrStateSymbol}</div>}
                            </div>
                            <div className={"vertSpacer1"}/>
                            <div className={"tradeDetailsPair"}>
                                {quote} <span className={"tradeDetailsPairSlash"}>/</span> {base}
                            </div>
                            <div className={"tradeDetailsPosAndQty"}>
                                <b>{posSize} {base}</b> {verbFutureTense} <small>{quote}</small>
                            </div>
                            <div className={`tradeDetailsCurrentPrice ${currentPriceClass}`} title={"Current price"}>
                                {currentPriceDeltaPercent}% · {currentPrice}
                            </div>
                            <div className={"tradeDetailsMaxPrice"}>
                                {maxPrice && <span>max price {maxDelta}% · {maxPrice}</span>}
                            </div>
                            <div className={"tradeDetailsMaxPrice"} style={{marginTop: "0"}}>
                                {minPrice && <span>min price {minDelta}% · {minPrice}</span>}
                            </div>
                            <div className={"vertSpacer2"}/>
                            <div style={{padding: "0.6rem"}}>
                                <Paper className={"tradeDetailsTradeConditionsPaper"}>
                                    <div style={{display: "flex", justifyContent: "space-between" ,fontSize: "0.7rem"}}>
                                        <span>BTD CONDITIONS</span>
                                        {allowedToEditBTDConditions && <span style={{cursor: "pointer"}} onClick={()=> {this.openEditBTDConditionsPanel()}}><Create/></span>}
                                    </div>
                                    <div className={"takeProfitStopLossConfigTradeDetails"}>
                                        <b>Wait</b> price to <b>dip</b> at {btd.dip_price} {base}
                                    </div>
                                    <div className={"takeProfitStopLossConfigTradeDetails"}>
                                        <b>Position size</b> {btd.pos_size} {base}
                                    </div>
                                </Paper>
                            </div>
                            <div style={{padding: "0.6rem"}}>
                                <Paper className={"tradeDetailsTradeConditionsPaper"}>
                                    <div style={{display: "flex", justifyContent: "space-between" ,fontSize: "0.7rem"}}>
                                        <span>TRADE CONDITIONS</span>
                                        {allowedToEditBTDConditions && <span style={{cursor: "pointer"}} onClick={()=> {this.openEditBTDTradeConditionsPanel()}}><Create/></span>}
                                    </div>
                                    {tpContent && <div className={"takeProfitStopLossConfigTradeDetails"}>
                                        {tpContent}
                                    </div>}
                                    <div className={"takeProfitStopLossConfigTradeDetails"}>
                                        {slContent}
                                    </div>
                                </Paper>
                            </div>
                            <div style={{padding: "0.6rem"}}>
                                <BTDDetailsMainButtons btd={btd} cancelBTD={this.openCancelBTDPanel}
                                                         discardBTD={this.openDiscardBTDPanel}/>
                            </div>
                        </Paper>
                    </Fade>}
                    {this.state.isEditBTDTradeConditionsPanelOpen &&
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeEditBTDTradeConditionsPanel()}}><Clear/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>Trade conditions</div>
                                    <div className={"linkButton"} style={{marginRight: "0.6rem"}} onClick={()=> {this.updateBTDTradeConditions()}}>SAVE</div>
                                </div>
                            </div>
                            <div className={"tradeDetailsTradeConditionsPaper"}>
                                <TradeConditions takeProfitOption={this.state.takeProfitOption}
                                                 takeProfitPriceOrPercent={this.state.takeProfitPriceOrPercent}
                                                 stopLossOption={this.state.stopLossOption}
                                                 stopLossPriceOrPercent={this.state.stopLossPriceOrPercent}
                                                 handleChangeTakeProfitOption={this.handleChangeTakeProfitOption}
                                                 handleChangeTakeProfitPriceOrPercent={this.handleChangeTakeProfitPriceOrPercent}
                                                 handleChangeStopLossOption={this.handleChangeStopLossOption}
                                                 handleChangeStopLossPriceOrPercent={this.handleChangeStopLossPriceOrPercent}
                                                 base={base}
                                />
                            </div>
                        </Paper>}
                    {this.state.isEditBTDConditionsPanelOpen &&
                    <Paper style={{backgroundColor: "#101926"}}>
                        <div className={"tradeDetailsTopBar"}>
                            <div className={"tradeDetailsTopBarVerticalCenter"}>
                                <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeEditBTDConditionsPanel()}}><Clear/></div>
                                <div className={"tradeDetailsTopBarTitle"}>BTD conditions</div>
                                <div className={"linkButton"} style={{marginRight: "0.6rem"}} onClick={()=> {this.updateBTDConditions()}}>SAVE</div>
                            </div>
                        </div>
                        <div className={"tradeDetailsTradeConditionsPaper"}>
                            <BTDConditions posSize={this.state.posSize}
                                           dipPrice={this.state.dipPrice}
                                           handleChangePosSize={this.handleChangePosSize}
                                           handleChangeDipPrice={this.handleChangeDipPrice}
                                           base={base}
                            />
                        </div>
                    </Paper>}
                    {this.state.isCancelBTDPanelOpen &&
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeCancelBTDPanel()}}><Clear/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>Cancel BTD</div>
                                    <div style={{width: "2.6rem"}}/>
                                </div>
                            </div>
                            <div className={"tradeDetailsTradeConditionsPaper"}>
                                <CancelItem cancelItem={this.cancelBTD} nevermind={this.closeCancelBTDPanel}
                                            question={"Are you sure you want to cancel this BTD?"}
                                            cancelText={"Cancel"} nevermindText={"Nevermind"}/>
                            </div>
                        </Paper>}
                    {this.state.isDiscardBTDPanelOpen &&
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeDiscardBTDPanel()}}><Clear/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>Discard BTD</div>
                                    <div style={{width: "2.6rem"}}/>
                                </div>
                            </div>
                            <div className={"tradeDetailsTradeConditionsPaper"}>
                                <DiscardItem discardItem={this.discardBTD} nevermind={this.closeDiscardBTDPanel}
                                             question={"Are you sure you want to discard this BTD?"}
                                             discardWarningText={"Discarding this BTD will remove it from your waiting BTD list."}/>
                            </div>
                        </Paper>}
                </div>
                <div key={"orderBook"} className={"OrderBook"}>
                    <OrderBook base={base} quote={quote} orderBook={this.props.orderBook} size={12}/>
                </div>
                <div className={"vertSpacer1_5"}/>
            </div>
    }
}


let mapStateToProps = rootState =>({
    user: rootState.authentication.user,
    btd: rootState.trades.openedBTD,
    // orderBook: rootState.newTrade.orderBook
    orderBook: rootState.orderBook.orderBook
})

let mapDispatchToProps = dispatch => {
    return {
        openBTD: (btd) => dispatch(openBTD(btd)),
        updateBTDTradeConditions: (props) => dispatch(updateBTDTradeConditions(props)),
        updateBTDConditions: (props) => dispatch(updateBTDConditions(props)),
        updateBTDCancelIt: (props) => dispatch(updateBTDCancelIt(props)),
        updateBTDDiscardIt: (props) => dispatch(updateBTDDiscardIt(props)),
        // getOrderBook: (props) => dispatch(getOrderBook(props))
    }
}


export default connect(mapStateToProps, mapDispatchToProps) (BTDDetails)
