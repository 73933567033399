import React from 'react'
import c from '../../constants'
import {
    getQuoteAndBase,
    timeFormatter, brushValue, formatTimeDuration, getValueWithPrecision, getExecutedQtyAndCummulativeQuoteQty
} from "./UISupportFns";
import {Warning} from "@material-ui/icons"

export default function CompletedTrade(props) {
    const {completedTrade, isUserSuperAdmin} = props
    const ACTION_TYPE = completedTrade.action_type
    const {quote, base} = getQuoteAndBase(completedTrade)
    // const executedQty = brushValue(completedTrade.executed_qty)
    let posSize = 0
    // let cummulativeQuoteQty = brushValue(completedTrade.cummulative_quote_qty)

    if (ACTION_TYPE === c.ACTION_TYPE_BUY) {
       posSize =  getValueWithPrecision(completedTrade.pos_size, completedTrade.tick_size)
    } else if (ACTION_TYPE === c.ACTION_TYPE_SELL) {
        posSize =  getValueWithPrecision(completedTrade.pos_size, 8)
    }

    let {executedQty, cummulativeQuoteQty} = getExecutedQtyAndCummulativeQuoteQty(completedTrade.original_trade)
    let price = cummulativeQuoteQty / executedQty
    price = isNaN(price) ? 0: price.toFixed(8)

    if (completedTrade.last_action === c.TRADE_OP_USER_CANCELS_TRADE) {
        cummulativeQuoteQty = completedTrade.cummulative_quote_qty;
    }

    return (
        <div id={completedTrade.id} className={"tradeContainer"}>
            <div className={"tradeSubContainerLeft"}>
                <div style={{display: "flex", justifyContent: "space-between", width: "9em"}}>
                    <div>
                        <span style={{fontSize: "1.2em", fontWeight: "bold"}}>{quote}</span>
                        <span style={{fontSize: "1em"}}> / {base}</span>
                    </div>
                </div>

                {ACTION_TYPE === c.ACTION_TYPE_BUY && <div>
                    {completedTrade.last_action !== c.TRADE_OP_USER_CANCELS_TRADE && <div className={"activeTradePosAndQty"} title={"What are you trading"}><small><b>{executedQty} {quote}</b></small>&nbsp;
                        <span><span className={"buy"}>bought</span> with <b>{cummulativeQuoteQty} {base}</b></span>
                        <span>, price <b>{price}</b></span>
                    </div>}
                    {completedTrade.last_action === c.TRADE_OP_USER_CANCELS_TRADE &&
                        <div className={"activeTradePosAndQty"} title={"What are you trading"}><small><b>{executedQty} {quote}</b></small>&nbsp;
                        <span><span className={"buy"}>bought</span> with <b>{cummulativeQuoteQty} {base}</b></span>
                        <span>, price <b>{price}</b></span>
                    </div>}
                </div>}
                {ACTION_TYPE === c.ACTION_TYPE_SELL && <div>
                    {completedTrade.last_action !== c.TRADE_OP_USER_CANCELS_TRADE && <div className={"activeTradePosAndQty"} title={"What are you trading"}>
                        <span><small><b>{posSize} {quote}</b></small>&nbsp;<span className={"sell"}>sold</span> for <b>{cummulativeQuoteQty} {base}</b></span>
                        <span>, price <b>{price}</b></span>
                    </div>}
                    {completedTrade.last_action === c.TRADE_OP_USER_CANCELS_TRADE && <div className={"activeTradePosAndQty"} title={"What are you trading"}>
                        <span><small><b>{executedQty} {quote}</b></small>&nbsp;<span className={"sell"}>sold</span> for <b>{cummulativeQuoteQty} {base}</b></span>
                        <span>, price <b>{price}</b></span>
                    </div>}
                </div>
                }
                <div style={{display: "flex"}}>
                    <div className={"activeTradePosAndQty"} title={"Created on"}>C. on <b>{timeFormatter(completedTrade.start_time, true)}</b></div>
                    {completedTrade.status === "FILLED" && <div className={"activeTradePosAndQty"} title={"Filled on"}>, F. on <b>{timeFormatter(completedTrade.last_action_time, true)}</b></div>}
                    {completedTrade.status === "CANCELED" && <div className={"activeTradePosAndQty"} title={"Canceled on"}>, C. on <b>{timeFormatter(completedTrade.last_action_time, true)}</b></div>}
                    {completedTrade.status === "ERROR" && <div className={"activeTradePosAndQty"} title={"Errored on"}>, E. on <b>{timeFormatter(completedTrade.last_action_time, true)}</b></div>}
                    <div className={"buyPriceContainer"}>, took <b>{formatTimeDuration(completedTrade.stop_time, completedTrade.start_time, " ")}</b></div>
                </div>

            </div>
            <div className={"tradeSubContainerRight"}>
                <div>
                    {/*<div><CompareArrows style={{color: "#64FE7C", fontSize: "42"}} /></div>*/}
                    <div style={{fontSize: "0.8rem", color: "#64FE7C"}}>ORDER</div>
                    {completedTrade.status === "FILLED" && <div style={{fontSize: "0.66rem", color: "#878E98"}}>FILLED</div>}
                    {completedTrade.status === "CANCELED" && <div style={{fontSize: "0.66rem", color: "#878E98"}}>CANCELED</div>}
                    {completedTrade.status === "ERROR" &&
                        <div style={{fontSize: "0.66rem", color: "#FB4A4A"}} title={completedTrade.child_error}>ERRORED</div>
                    }
                </div>
            </div>
            {/*<div style={{display:"flex", justifyContent:"center", width: "100%"}}>*/}
            {/*    <div className={"buyPriceContainer"}>*/}
            {/*        {completedTrade.last_action === c.TRADE_OP_USER_TAKES_PROFIT && <span>You took profit</span>}*/}
            {/*        {completedTrade.last_action === c.TRADE_OP_AUTO_TAKE_PROFIT && <span>Took profit</span>}*/}
            {/*        {completedTrade.last_action === c.TRADE_OP_AUTO_TAKE_PROFIT_TARGET_1 && <span>Took profit target #1</span>}*/}
            {/*        {completedTrade.last_action === c.TRADE_OP_AUTO_TAKE_PROFIT_TARGET_2 && <span>Took profit target #2</span>}*/}
            {/*        {completedTrade.last_action === c.TRADE_OP_AUTO_TAKE_LOSS && <span>Stopped loss</span>}*/}
            {/*        {(completedTrade.last_action === c.TRADE_OP_USER_CANCELS_TRADE && completedTrade.has_child) && <span>Canceled</span>}*/}
            {/*        {(completedTrade.last_action === c.TRADE_OP_USER_CANCELS_TRADE && !completedTrade.has_child) && <span>Canceled while <b>waiting</b></span>}*/}
            {/*        {completedTrade.last_action === c.TRADE_OP_AUTO_CLOSE && <span>Closed</span>}*/}
            {/*        &nbsp;on <b>{timeFormatter(completedTrade.last_action_time, true)}</b>*/}
            {/*        {completedTrade.child_status === "ERROR" && <span>&nbsp;but got an <span style={{color: "#FB4A4A"}} title={completedTrade.child_error}>Error</span></span>}*/}
            {/*    </div>*/}
            {/*    <div className={"buyPriceContainer"}>,&nbsp;took <b>{formatTimeDuration(completedTrade.stop_time, completedTrade.start_time, " ")}</b></div>*/}
            {/*</div>*/}
            <div style={{display:"flex", justifyContent:"space-between", width: "100%"}} className={"recordId"}>
                <div style={{position: "relative", top: "0.52rem"}}>
                    {completedTrade.id}
                </div>
            </div>
            {(ACTION_TYPE === c.ACTION_TYPE_SELL && (quote !== "BTC" && quote !== "ETH" && quote !== "USDT") ) &&
            <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "17rem", opacity: "0.6", marginTop: "1rem"}}>
                <Warning/><span style={{fontSize: "0.76rem"}}>Conversion orders do not impact statistics.</span>
            </div>}
            </div>)
}
