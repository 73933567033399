import {
    CLEAR_ERRORS,
    ENABLE_2FA_ERROR,
    ERROR_VALIDATING_ADDRESS,
    RECEIVED_ERRORS,
    DISABLE_2FA_ERROR,
    RECEIVED_AUTH_ERRORS,
    RESET_PASSWORD_ERROR,
    CHANGE_PASSWORD_ERROR,
    LAUNCH_NEW_TRADE_ERROR,
    LAUNCH_NEW_FUTURES_ERROR,
    LAUNCH_NEW_EXCHANGE_ERROR
} from "../actions/actionTypes";

export default function (state = {}, action) {
    switch (action.type) {

        case RECEIVED_ERRORS: {
            return action.payload
        }

        case RECEIVED_AUTH_ERRORS: {
            return {
                ...state,
                ...{
                    authError: action.payload
                }
            }
        }

        case RESET_PASSWORD_ERROR: {
            return {
                ...state,
                ...{
                    resetPasswordError: action.payload
                }
            }
        }

        case CHANGE_PASSWORD_ERROR: {
            return {
                ...state,
                ...{
                    changePasswordError: action.payload
                }
            }
        }

        case ERROR_VALIDATING_ADDRESS: {
            let newState = {...state, ...{}}
            newState.validAddress = 0
            return newState
        }

        case ENABLE_2FA_ERROR: {
            let newState = {...state, ...{}}
            newState.twoFAValidationErrors = 1
            return newState
        }

        case DISABLE_2FA_ERROR: {
            let newState = {...state, ...{}}
            newState.twoFAValidationErrors = 1
            return newState
        }

        case LAUNCH_NEW_TRADE_ERROR: {
            return {
                ...state,
                ...{
                    launchNewTradeError: action.payload.message
                }
            }
        }

        case LAUNCH_NEW_EXCHANGE_ERROR: {
            return {
                ...state,
                ...{
                    launchNewExchangeError: action.payload.message
                }
            }
        }

        case LAUNCH_NEW_FUTURES_ERROR: {
            return {
                ...state,
                ...{
                    launchNewFuturesError: action.payload.message
                }
            }
        }

        case CLEAR_ERRORS: {
            return {}
        }

        default:
            return state
    }
}
