import React, {Component} from 'react'
import { connect } from 'react-redux'
import {getPrograms} from "../../../redux/actions/staking";
import c from "../../../constants";
import StakingProgram from "../../fncomponents/StakingProgram";

class StakingPrograms extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {
        if (this.props.selectedTab === c.STAKES_STAKES_PROGRAMS_TAB) {
            this.props.getPrograms()
        }

    }

    render() {
        return <div>
            {this.props.programs && this.props.programs.map(p => <StakingProgram program={p}/>)}
        </div>
    }

}

const mapStateToProps = rootState => ({
    selectedTab: rootState.staking.selectedTab,
    programs: rootState.staking.programs
})

const mapDispatchToProps = dispatch => {
    return {
        getPrograms: props => dispatch(getPrograms(props))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (StakingPrograms)
