import React, {Component} from 'react'
import {connect} from "react-redux"
import {debounce} from '../../hoc/myUtils'
import {getStakingRewardsByPage, getStakingRewardsInitial} from "../../../redux/actions/staking"
import StakingRewardsTable from "./StakingRewardsTable"


class StakingRewards extends Component {

    constructor(props) {
        super(props)
        this.state = {
            rows: [],
            page: 0,
            rowsPerPage: 10,
            filtersMap: {
                "userStatus": "",
                "group": 0
            },
            sort: {},
        }

        this.setRowsPerPage = this.setRowsPerPage.bind(this)
        this.setPage = this.setPage.bind(this)
        this.onFilterChange = this.onFilterChange.bind(this)
        this.onFilter = this.onFilter.bind(this)
        this.debouncedFilter = debounce(this.onFilter, 500)
    }

    setRowsPerPage (value) {
        this.state.rowsPerPage = value
        this.state.page = 0
        this.props.getSRInitial({filters: this.state.filtersMap,
            sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
    }

    setPage (value) {
        let page = value
        if (!this.props.pagesDownloaded[page+1]) {
            this.state.page = page
            this.props.getSRByPage({filters: this.state.filtersMap,
                sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
        } else {
            this.setState({
                page: page
            })
        }
    }

    onFilterChange(e) {
        let filterEntry = {[e.target.name]: e.target.value}
        this.setState({
            filtersMap: {...this.state.filtersMap, ...filterEntry}
        })
        this.debouncedFilter()
    }

    onFilter() {
        this.props.getSRInitial({filters: this.state.filtersMap,
            sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
    }

    componentDidMount() {
        this.props.getSRInitial({filters: this.state.filtersMap,
            sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
    }

    render() {
        let {docs, totalDocs} = this.props
        return (<StakingRewardsTable
                    rows={docs}
                    rowsPerPage={this.state.rowsPerPage}
                    setRowsPerPage={this.setRowsPerPage}
                    page={this.state.page}
                    setPage={this.setPage}
                    totalDocs={totalDocs ? totalDocs : 0}
                    filtersMap={this.state.filtersMap}
                    onFilterChange={this.onFilterChange}
                />)
    }
}

let mapStateToProps = rootState => ({
    docs: rootState.staking.stakingRewards.docs,
    totalDocs: rootState.staking.stakingRewards.totalDocs,
    totalPages: rootState.staking.stakingRewards.totalPages,
    pagesDownloaded: rootState.staking.stakingRewards.pagesDownloaded
})

let mapDispatchToProps = dispatch => {
    return {
        getSRInitial: (props) => dispatch(getStakingRewardsInitial(props)),
        getSRByPage: (props) => dispatch(getStakingRewardsByPage(props)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (StakingRewards)
