import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getTitle} from "../../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"
import KeyboardBackArrow from '@material-ui/icons/KeyboardBackspace'
import FormButtons from "../../fncomponents/FormButtons"
import {
    addTraderFollower,
    getTraderFollower,
    getTradersSubscriptions,
    updateTraderFollower
} from "../../../redux/actions/traders"
import {getUserByEmail} from "../../../redux/actions/users"
import {debounce} from "../../hoc/myUtils"
import SelectedFollower from "../../fncomponents/SelectedFollower"
import SelectFollowerSubscription from "../../fncomponents/SelectFollowerSubscription";
import InfoBox from "../../fncomponents/InfoBox";
import c from '../../../constants'

const qs = require("query-string")

const DEBOUNCE_DELAY_IN_MS = 500

class NewOrUpdateTraderFollower extends Component {

    constructor(props) {
        super(props)
        this.state = {
            followerId: '',
            selectedUser: undefined,
            selectedSubscriptionId: undefined,
        }
        this.handleChangeEmail = this.handleChangeEmail.bind(this)
        this.searchUserByEmail = this.searchUserByEmail.bind(this)
        this.debounceSearchUserByEmail = debounce(this.searchUserByEmail, DEBOUNCE_DELAY_IN_MS)
        this.handleSelectUser = this.handleSelectUser.bind(this)
        this.handleSelectSubscription = this.handleSelectSubscription.bind(this)
        this.handleUpdateFollower = this.handleUpdateFollower.bind(this)
        this.handleChangeExpirationDate = this.handleChangeExpirationDate.bind(this)
    }

    handleChangeExpirationDate(e) {
        this.setState({
            selectedSubscriptionExpirationDate: e.target.value,
            userChangedExpirationDate: true,
        })
    }

    handleUpdateFollower() {
        if (!this.props.openedTraderFollower) {
            return this.setState({
                error: "Please select a user"
            })
        }

        if (!this.state.selectedSubscriptionId) {
            return this.setState({
                error: "Please select a subscription"
            })
        }
        console.log("state", JSON.stringify(this.state))
        this.props.updateTraderFollower({
            userId: this.state.selectedUser._id,
            subscriptionId: this.state.selectedSubscriptionId,
            expirationDate: this.state.selectedSubscriptionExpirationDate,
            userChangedExpirationDate: this.state.userChangedExpirationDate
        }, (response) => {
            if (response.status === "OK") {
                this.setState({
                    success: "Follower updated.",
                    email: '',
                    error: undefined
                })
            } else if (response.status === "ERROR") {
                this.setState({
                    success: undefined,
                    error: response.message,
                    email: '',
                })
            }

        })
    }

    handleSelectSubscription(e) {
        let subscription = undefined
        for(let i = 0; i < this.props.subscriptions.length && !subscription; i++) {
            if (e.target.value === this.props.subscriptions[i]._id)
                subscription = this.props.subscriptions[i]
        }

        if (subscription) {
            if (subscription.free) {
                this.state.selectedSubscriptionExpirationDate = 'never'
            } else {
                let expiresDate = new Date(new Date().getTime() + c.PERIODICITY_IN_DAYS_BY_TYPE[subscription.periodicity] * c.MS_IN_A_DAY)
                let year = expiresDate.getFullYear()
                let month = expiresDate.getMonth() +1
                let day = expiresDate.getDate()
                this.state.selectedSubscriptionExpirationDate = `${year}-${month}-${day}`
            }
        }

        this.setState({
            selectedSubscriptionId: e.target.value,
            userChangedExpirationDate: false
        })
    }

    handleSelectUser() {
        this.setState({
            selectedUser: this.props.searchedUser,
        })
    }

    handleChangeEmail(e) {
        this.setState({
            email: e.target.value
        })
        this.debounceSearchUserByEmail()
    }

    searchUserByEmail() {
        this.props.getUserByEmail({email: this.state.email})
    }

    componentDidMount() {

        this.state.followerId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).followerId
        if (this.state.followerId) {
            this.props.getTraderFollower({_id: this.state.followerId}, traderFollower => {
                this.state.selectedUser = {_id: traderFollower.user}
                console.log("traderFollower", JSON.stringify(traderFollower))
                if (traderFollower.activeSubscriptions?.[0]) {
                    let expiresDate = new Date(traderFollower.activeSubscriptions[0].expirationDate)
                    let year = expiresDate.getFullYear()
                    let month = expiresDate.getMonth() +1
                    let day = expiresDate.getDate()
                    this.setState({
                        selectedSubscriptionId: traderFollower.activeSubscriptions[0].subscriptionId,
                        selectedSubscriptionExpirationDate: `${year}-${month}-${day}`
                    })
                    console.log("componentDidMount state", JSON.stringify(this.state))
                }
            })
        }
        if (!this.props.subscriptions) {
            this.props.getTradersSubscriptions()
        }

    }

    render() {

        let {user, openedTraderFollower} = this.props
        let {error, success} = this.state
        let selectedUser = undefined
        if (openedTraderFollower)
            selectedUser = {firstName: openedTraderFollower.userFirstName, lastName: openedTraderFollower.userLastName}

        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <div style={{display: "flex", justifyContent: "flex-start", width: "20em", cursor: "pointer"}}
                 onClick={()=>{this.props.history.push("/trader/followers")}} >
                <KeyboardBackArrow/>
                <div className={"pageTitle"} style={{marginLeft: "1rem"}}>Back</div>
            </div>
            <div key={"theForm"}>
                <div className={"vertSpacer2"}/>
                <InfoBox message="When you change a user subscription, if need - a first payment should be handled manually."
                    width={"20rem"}/>
                <div className={"vertSpacer1_5"}/>
                <div className={"horizSeparator"}/>
                <div className={"vertSpacer1"}/>
                {selectedUser && <SelectedFollower selectedUser={selectedUser}/>}
                <div className={"vertSpacer2"}/>
                <SelectFollowerSubscription
                    editExpirationDate={true}
                    selectedSubscriptionId={this.state.selectedSubscriptionId}
                    selectedSubscriptionExpirationDate={this.state.selectedSubscriptionExpirationDate}
                    handleChangeExpirationDate={this.handleChangeExpirationDate}
                    allSubscriptions={this.props.subscriptions}
                    handleSelect={this.handleSelectSubscription}
                />
                {error &&
                <div>
                    <div className={"vertSpacer1"}/>
                    <div style={{marginLeft: "1rem"}}>
                        <div className={"errorContainer"}>{error}</div>
                    </div>
                </div>}
                {success &&
                <div>
                    <div className={"vertSpacer1"}/>
                    <div style={{marginLeft: "1rem"}}>
                        <div className={"successContainer"}>{success}</div>
                    </div>
                </div>}
                <FormButtons
                    handleCancel={()=>{this.props.history.push("/trader/followers")}}
                    handleSave={this.handleUpdateFollower}
                    labelSave={"Update"}
                />
            </div>
        </div>
    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    openedTraderFollower: rootState.traders.openedTraderFollower,
    subscriptions: rootState.traders.subscriptions
})

let mapDispatchToProps = dispatch => {
    return {
        getTraderFollower: (props, cb) => dispatch(getTraderFollower(props, cb)),
        getUserByEmail: (props) => dispatch(getUserByEmail(props)),
        getTradersSubscriptions: () =>  dispatch(getTradersSubscriptions()),
        updateTraderFollower: (props, cb) => dispatch(updateTraderFollower(props, cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (NewOrUpdateTraderFollower)
