import axios from './axiosFabric'
import {handleError} from "./errorHandler"
import {
    CHECK_USER_BALANCE, CLEAR_NOT_ENOUGH_BALANCE,
    GET_CURRENCIES_MAP,
    GET_CURRENT_PRICE,
    GET_LAST_USED_PARAMS_ON_NEW_TRADE,
    GET_USER_BASE_BALANCE,
    GET_USER_QUOTE_BALANCE, LAUNCH_NEW_EXCHANGE, LAUNCH_NEW_EXCHANGE_ERROR,
    LAUNCH_NEW_TRADE, LAUNCH_NEW_TRADE_ERROR, PREVIEW_NEW_EXCHANGE,
    PREVIEW_NEW_TRADE, SET_CURRENT_TAB_ORDERS, SET_CURRENT_TAB_WAITING_TRADES
} from "./actionTypes"
import {isAuthorizationHeaderPresent} from "./authentication"
import {clearErrors} from "./errors";

export const getCurrenciesMap = props => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post("/newtrade/getcurrenciesmap")
        .then(res => {
            // let baseCurrencies = Object.keys(res.data)
            let baseCurrencies = ["BTC", "ETH", "USDT"]
            dispatch({
                type: GET_CURRENCIES_MAP,
                payload: {
                    baseCurrenciesNom: baseCurrencies,
                    quoteCurrenciesNom: res.data
                }
            })

        })
        .catch(error => handleError(error, dispatch))
}

export const getLastUsedParamsOnNewTrade = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post("/newtrade/getlastusedparamsonnewtrade")
        .then(res => {
            if (cb) cb(res.data)
            dispatch({
                type: GET_LAST_USED_PARAMS_ON_NEW_TRADE,
                payload: res.data
            })
        })
        .catch(error => handleError(error, dispatch))
}

export const getCurrentPriceOnPair = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post("/newtrade/getcurrentpriceonpair", props)
        .then(res => {
            if (cb) {
                cb(res.data)
            }
            dispatch({
                type: GET_CURRENT_PRICE,
                payload: {
                    currentLastBidPrice: res.data.current_price,
                    precision: res.data.tick_size,
                }
            })
        })
        .catch(error => handleError(error, dispatch))
}

export const previewNewTrade = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    let now = new Date().getTime()
    axios.post(`/newtrade/previewnewtrade/${now}`, props)
        .then(() => {
            //Attention below there are props, not res.data
            dispatch({
                type: PREVIEW_NEW_TRADE,
                payload: props
            })
            if (cb) cb()
        })
        .catch(error => handleError(error, dispatch))
}

export const previewNewExchange = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    let now = new Date().getTime()
    axios.post(`/newtrade/previewnewexchange/${now}`, props)
        .then(() => {
            //Attention below there are props, not res.data
            dispatch({
                type: PREVIEW_NEW_EXCHANGE,
                payload: props
            })
            if (cb) cb()
        })
        .catch(error => handleError(error, dispatch))
}

export const launchNewTrade = (cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    let now = new Date().getTime()
    axios.post(`/newtrade/launchnewtrade/${now}`)
        .then(res => {
            if (cb) cb()
            dispatch({
                type: LAUNCH_NEW_TRADE
            })
            dispatch({
                type: SET_CURRENT_TAB_WAITING_TRADES
            })
        })
        .catch(error => handleError(error, dispatch, LAUNCH_NEW_TRADE_ERROR))
}

export const launchNewExchange = () => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    let now = new Date().getTime()
    axios.post(`/newtrade/launchnewexchange/${now}`)
        .then(res => {
            dispatch({
                type: LAUNCH_NEW_EXCHANGE
            })
            dispatch({
                type: SET_CURRENT_TAB_ORDERS
            })
        })
        .catch(error => handleError(error, dispatch, LAUNCH_NEW_EXCHANGE_ERROR))
}

export const checkUserBalance = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    clearErrors()
    axios.post("/newtrade/checkuserbalance", props)
        .then(res => {
            if (cb) cb(res.data.result)
            dispatch({
                type: CHECK_USER_BALANCE,
                payload: res.data
            })
        })
        .catch(error => handleError(error, dispatch))
}

export const getUserBaseBalance = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post("/newtrade/getuserbalance", props)
        .then(res => {
            if (cb)
                cb(res.data)
            dispatch({
                type: GET_USER_BASE_BALANCE,
                payload: res.data
            })
        })
        .catch(error => handleError(error, dispatch))
}

export const getUserQuoteBalance = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post("/newtrade/getuserbalance", props)
        .then(res => {
            if (cb) cb(res.data)
            dispatch({
                type: GET_USER_QUOTE_BALANCE,
                payload: res.data
            })
        })
        .catch(error => handleError(error, dispatch))
}

export const clearNotEnoughBalance = () => dispatch => {
    dispatch({
        type: CLEAR_NOT_ENOUGH_BALANCE,
    })
}
