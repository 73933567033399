import makeStyles from "@material-ui/core/styles/makeStyles";
import Fab from "@material-ui/core/Fab";
import React from "react";
import c from '../../constants'
import {getTradeEnterVerb} from "./UISupportFns";

const useStyles = makeStyles(theme => ({
    fabCancel: {
        margin: theme.spacing(1),
        width: 130,
        background: "#101926",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "white",
        '&:hover': {
            background: "#424242"
        }
    },
    fabTakeProfit: {
        margin: theme.spacing(1),
        background: "#152B40",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "white",
        '&:hover': {
            background: "#424242"
        }
    },
    fabDiscard: {
        margin: theme.spacing(1),
        background: "#FB4A4A",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "black",
        '&:hover': {
            background: "#424242"
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    }
}));

export default function SUCDetailsMainButtons(props) {
    const classes = useStyles()
    let {suc, cancelSUC, discardSUC} = props

    if (suc.status === c.SUC_STATUS_WAITING)
        return (
        <div className={"sucDetailsMainButtonsContainer"}>
            <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabCancel}
                 onClick={() => {
                     cancelSUC()
                 }}>
                <div style={{color: "white"}}>Cancel</div>
            </Fab>
        </div>
    )
    else return (
        <div className={"sucDetailsMainButtonsContainer"}>
            <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabDiscard}
                 onClick={() => {
                     discardSUC()
                 }}>
                <div style={{color: "white"}}>Discard</div>
            </Fab>
        </div>
    )
}
