import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import {connect} from "react-redux"
import TimeAgo from 'react-timeago'
import {Create, Clear, ArrowBack} from "@material-ui/icons"
import {
    brushValue,
    capitalize,
    getAllowedToEditFuturesOrderConditions,
    getAllowedToEditTradeConditions,
    getCurrentBuyAndSellPrice,
    getProgress,
    getProgressForWaitingTrade,
    getQuoteAndBase,
    getTradeStateTitle,
    getValueWithPrecision,
    timeAgoFormatter,
    timeFormatter,
    getFuturesTradeState,
    getEnterTradePriceForFutures,
    getCurrentPriceAndDeltaPercentForFutures,
    getFuturesTradeEnterVerb,
    getTradeConditionsContentForFuturesTradeDetails,
    getActionTypeOptionDetailsExplicitFutures,
    getHRStateForFuturesTrade,
    hasTradeChild,
    getMaxMinAndDeltaPercentFutures,
    changeFuturesTakeProfitOption, getCurrentPrice, changeFuturesStopLossOption, brushValue2
} from "../../fncomponents/UISupportFns"
import {FormControlLabel, Paper, withStyles} from "@material-ui/core"
import FuturesOrderConditions from "../../fncomponents/FuturesOrderConditions"
import FuturesTradeConditionsFromSUC from "../../fncomponents/FuturesTradeConditionsFromSUC"
import c from "../../../constants"
import TakeProfitConditions from "../../fncomponents/TakeProfitConditions"
import Fade from '@material-ui/core/Fade'
import CancelItem from "../../fncomponents/CancelItem"
import DiscardItem from "../../fncomponents/DiscardItem"
import {debounce} from "../../hoc/myUtils"
import OrderBook from "../../fncomponents/OrderBook"
import {getTitle} from "../../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"
import Checkbox from "@material-ui/core/Checkbox"
import {initWSF, closeWSF} from "../../../redux/actions/wsOrderBook"
import FuturesTradeDetailsMainButtons from "../../fncomponents/FuturesTradeDetailsMainButtons"
import {
    computeAdditionalIsoMarg, computeLiquidationPrice, getRates, modifyIsolatedMargin,
    updateEnterTradeConditions,
    updateFuturesTradeCancelIt, updateFuturesTradeDiscardIt,
    updateTradeConditions,
    updateTradeExecuteTakeProfit,
    updateTradeFromSUCConnectionToSignal,
    updateTradeFromSUCTradeConditions,
} from "../../../redux/actions/futures";
import {getCurrentPriceOnPair} from "../../../redux/actions/newFutures"
import FuturesConditions from "../../fncomponents/FuturesConditions";
import NeedExtraIsoMargDialog from "../../fncomponents/NeedExtraIsoMargDialog";
import {getFutureMarginBalances} from "../../../redux/actions/wallet";

const DEBOUNCE_DELAY_IN_MS = 500

const CustomColorCheckbox = withStyles({
    root: {
        color: "#ffffff",
        '&$checked': {
            color: "#ffffff",
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);

class FuturesTradeDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isEditTradeConditionsPanelOpen: false,
            isTradeDetailsPanelOpen: true,
            isTakeProfitPanelOpen: false,
            isCancelTradePanelOpen: false,
            isDiscardTradePanelOpen: false,
            isEditEnterTradeConditionsPanelOpen: false,
            goBack: false,
            executeTakeProfitOption: "market",
            executeTakeProfitPrice: 0,
            //Flag to avoid double submit
            executeTakeProfitOngoing: false,
            extraAdditionalMargin: 0,
            isNeedExtraIsoMargDialogOpen: false
        }

        this.updateTradeFromSUCConnection = this.updateTradeFromSUCConnection.bind(this)

        this.openEditTradeConditionsPanel = this.openEditTradeConditionsPanel.bind(this)
        this.closeEditTradeConditionsPanel = this.closeEditTradeConditionsPanel.bind(this)
        this.handleChangeTakeProfitOption = this.handleChangeTakeProfitOption.bind(this)
        this.handleChangeTakeProfitOption2 = this.handleChangeTakeProfitOption2.bind(this)
        this.handleChangeTakeProfitPriceOrPercent = this.handleChangeTakeProfitPriceOrPercent.bind(this)
        this.fixTakeProfitPriceOrPercent = this.fixTakeProfitPriceOrPercent.bind(this)
        this.debounceFixTakeProfitPriceOrPercent = debounce(this.fixTakeProfitPriceOrPercent, DEBOUNCE_DELAY_IN_MS)
        this.handleChangeTakeProfitTarget = this.handleChangeTakeProfitTarget.bind(this)
        this.fixTakeProfitTarget = this.fixTakeProfitTarget.bind(this)
        this.debounceFixTakeProfitTarget = debounce(this.fixTakeProfitTarget, DEBOUNCE_DELAY_IN_MS)
        this.handleChangeStopLossOption = this.handleChangeStopLossOption.bind(this)
        this.handleChangeStopLossPriceOrPercent = this.handleChangeStopLossPriceOrPercent.bind(this)
        this.fixStopLossPriceOrPercent = this.fixStopLossPriceOrPercent.bind(this)
        this.debounceFixStopLossPriceOrPercent = debounce(this.fixStopLossPriceOrPercent, DEBOUNCE_DELAY_IN_MS)
        this.updateTradeConditions = this.updateTradeConditions.bind(this)

        this.updateTradeFromSUCTradeConditions = this.updateTradeFromSUCTradeConditions.bind(this)
        this.updateTradeConditionsGeneric = this.updateTradeConditionsGeneric.bind(this)
        this.goBack = this.goBack.bind(this)

        this.openTakeProfitPanel = this.openTakeProfitPanel.bind(this)
        this.closeTakeProfitPanel = this.closeTakeProfitPanel.bind(this)
        this.handleChangeExecuteTakeProfitOption = this.handleChangeExecuteTakeProfitOption.bind(this)
        this.handleChangeExecuteTakeProfitPrice = this.handleChangeExecuteTakeProfitPrice.bind(this)
        this.fixExecuteTakeProfitPrice = this.fixExecuteTakeProfitPrice.bind(this)
        this.debounceFixExecuteTakeProfitPrice = debounce(this.fixExecuteTakeProfitPrice, DEBOUNCE_DELAY_IN_MS)
        this.executeTakeProfit = this.executeTakeProfit.bind(this)

        this.openCancelTradePanel = this.openCancelTradePanel.bind(this)
        this.closeCancelTradePanel = this.closeCancelTradePanel.bind(this)
        this.cancelTrade = this.cancelTrade.bind(this)
        this.openDiscardTradePanel = this.openDiscardTradePanel.bind(this)
        this.closeDiscardTradePanel = this.closeDiscardTradePanel.bind(this)
        this.discardTrade = this.discardTrade.bind(this)

        this.openEditEnterTradeConditionsPanel = this.openEditEnterTradeConditionsPanel.bind(this)
        this.closeEditEnterTradeConditionsPanel = this.closeEditEnterTradeConditionsPanel.bind(this)
        this.updateEnterTradeConditions = this.updateEnterTradeConditions.bind(this)
        this.handleChangeActionTypeOption = this.handleChangeActionTypeOption.bind(this)
        this.handleChangeFixedPrice = this.handleChangeFixedPrice.bind(this)
        this.fixFixedPrice = this.fixFixedPrice.bind(this)
        this.debounceFixFixedPrice = debounce(this.fixFixedPrice, DEBOUNCE_DELAY_IN_MS)

        this.handleChangeTakeProfitTargetPercentageExecute = this.handleChangeTakeProfitTargetPercentageExecute.bind(this)
        this.fixTakeProfitTargetPercentageExecute = this.fixTakeProfitTargetPercentageExecute.bind(this)
        this.debounceFixTakeProfitTargetExecute = debounce(this.fixTakeProfitTargetPercentageExecute, DEBOUNCE_DELAY_IN_MS)

        this.switchWithOrWithoutStopLossTarget = this.switchWithOrWithoutStopLossTarget.bind(this)
        this.handleChangeCandlesType = this.handleChangeCandlesType.bind(this)

        this.computeLiqPriceAndAddIsoMargAgainstStopLoss = this.computeLiqPriceAndAddIsoMargAgainstStopLoss.bind(this)
        this.updateAdditionalMarginByNewPercent = this.updateAdditionalMarginByNewPercent.bind(this)
        this.computeCalculated = this.computeCalculated.bind(this)

        this.handleAddExtraIsoMarg = this.handleAddExtraIsoMarg.bind(this)
        this.cancelAddExtraIsoMarg = this.cancelAddExtraIsoMarg.bind(this)
    }

    handleChangeAdditionalMargin(e) {
        this.setState({
            additionalMargin: e.target.value
        })
        this.debounceFixAdditionalMargin()
    }

    handleChangeCandlesType(e) {
        this.setState({
            candlesType: e.target.value
        })
    }

    switchWithOrWithoutStopLossTarget() {
        if (this.state.stopLossOption === c.STOP_LOSS_OPTION_MANUAL) {
            this.setState({
                stopLossOption: c.STOP_LOSS_OPTION_PERCENTAGE,
                stopLossPriceOrPercent: 50,
            })
        } else {
            this.setState({
                stopLossOption: c.STOP_LOSS_OPTION_MANUAL
            })
        }
    }

    updateTradeFromSUCConnection(rand) {
        this.props.updateTradeFromSUCConnectionToSignal({tradeId: this.props.futuresTrade.order_id})
    }

    openEditTradeConditionsPanel() {
        let newState = {
            isEditTradeConditionsPanelOpen: true,
            isTradeDetailsPanelOpen: false,
            stopLossOption: this.props.futuresTrade.stop_loss_option,
            takeProfitOption: this.props.futuresTrade.take_profit_option,
            stopLossPriceOrPercent: this.props.futuresTrade.stop_loss_price_or_percent,
        }
        if (this.props.futuresTrade.from_SUC) {
            newState.takeProfitTargets = []
            for(let i=0; i < this.props.futuresTrade.take_profit_targets; i++) {
                newState.takeProfitTargets.push(this.props.futuresTrade[`take_profit_price_or_percent_${i+1}`])
            }
        } else {
            newState.takeProfitPriceOrPercent = this.props.futuresTrade.take_profit_price_or_percent
        }

        this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, result => {
            newState.currentPrice = brushValue(result.current_price, result.tick_size)
            // console.log("openEditTradeConditionsPanel currentPrice", newState.currentPrice)
            this.setState(newState)
        })
    }

    closeEditTradeConditionsPanel() {
        this.setState({
            isEditTradeConditionsPanelOpen: false,
            isTradeDetailsPanelOpen: true,
            liquidationPrice: this.state.initialLiquidationPrice,
            additionalMargin: this.state.initialAdditionalMargin
        })
    }

    updateTradeConditions() {
        this.state.isEditTradeConditionsPanelOpen = false
        this.state.isTradeDetailsPanelOpen = true
        this.props.updateTradeConditions({
            futuresTradeId: this.props.futuresTrade.order_id,
            takeProfitOption: this.state.takeProfitOption,
            takeProfitPriceOrPercent: this.state.takeProfitPriceOrPercent,
            stopLossOption: this.state.stopLossOption,
            stopLossPriceOrPercent: this.state.stopLossPriceOrPercent,
            candlesType: this.state.candlesType
        })
    }

    updateTradeFromSUCTradeConditions() {
        this.state.isEditTradeConditionsPanelOpen = false
        this.state.isTradeDetailsPanelOpen = true
        this.props.updateTradeFromSUCTradeConditions({
            tradeId: this.props.futuresTrade.order_id,
            takeProfitOption: this.state.takeProfitOption,
            takeProfitTargets: this.state.takeProfitTargets,
            stopLossOption: this.state.stopLossOption,
            stopLossPriceOrPercent: this.state.stopLossPriceOrPercent,
            takeProfitTarget1PercentageExecute: this.state.take_profit_target_1_percentage_execute,
            takeProfitTarget2PercentageExecute: this.state.take_profit_target_2_percentage_execute
        })
    }

    //1984
    updateTradeConditionsGeneric() {
        if (this.state.extraAdditionalMargin > 0) {
            return this.props.getFutureMarginBalances({}, futuresBalance => {
                this.setState({
                    isNeedExtraIsoMargDialogOpen: true,
                    showWaitingAddingExtraIsoMarg: false,
                    availableMargin: futuresBalance.margin.availableBalance
                })
            })
        }

        if (this.props.futuresTrade.from_SUC) {
            this.updateTradeFromSUCTradeConditions()
        } else {
            this.updateTradeConditions()
        }
    }

    handleAddExtraIsoMarg() {
        this.setState({
            showWaitingAddingExtraIsoMarg: true
        })
        this.props.modifyIsolatedMargin({
            futuresTradeId: this.state.fTradeOrderId,
            operation: "ADD",
            margin: this.state.extraAdditionalMargin
        }, result => {
            if (!result.error) {
                this.state.isNeedExtraIsoMargDialogOpen = false
                if (this.props.futuresTrade.from_SUC) {
                    this.updateTradeFromSUCTradeConditions()
                } else {
                    this.updateTradeConditions()
                }
            }
        })
    }

    cancelAddExtraIsoMarg() {
        this.setState({
            isNeedExtraIsoMargDialogOpen: false
        })
    }


    handleChangeTakeProfitOption(option) {
        changeFuturesTakeProfitOption(option, this, this.props.futuresTrade.currentPrice)
    }

    handleChangeTakeProfitOption2(option) {
        //If there is no change, do nothing
        if (this.state.takeProfitOption === option)
            return

        let sign = 1
        if (this.state.futuresType === c.FUTURES_TYPE_SHORT || this.state.futuresType === c.FUTURES_TYPE_SHORT_s)
            sign = -1

        if (option === c.TAKE_PROFIT_OPTION_PRICE) {
            for(let i = 0; i < this.state.takeProfitTargets.length; i++) {
                let oldPercent = parseFloat(this.state.takeProfitTargets[i])
                let currentPrice = parseFloat(this.state.currentPrice)
                let price = (1 + sign * oldPercent/ this.state.leverage/100) * parseFloat(currentPrice)
                this.state.takeProfitTargets[i] = brushValue(price, 8)
            }
        } else if (option === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
            for(let i = 0; i < this.state.takeProfitTargets.length; i++) {
                let oldPrice = parseFloat(this.state.takeProfitTargets[i])
                let currentPrice = parseFloat(this.props.futuresTrade.currentPrice)
                let percent = ((oldPrice - currentPrice) / currentPrice) * 100 * this.state.leverage
                percent *= sign
                if ( percent < 0) {
                    percent = -1 * percent
                }
                this.state.takeProfitTargets[i] = brushValue(percent, 1)
            }
        }

        this.setState({
            takeProfitOption: option
        })
    }

    handleChangeTakeProfitPriceOrPercent(e) {
        let value = e.target && e.target.value ? e.target.value : ""
        if (value === 0 || value.length === 0) {
            this.state.takeProfitOption = c.TAKE_PROFIT_OPTION_MANUAL
        } else if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_MANUAL) {
            this.state.takeProfitOption = c.TAKE_PROFIT_OPTION_PRICE
        }
        this.setState({
            takeProfitPriceOrPercent: value
        })

        this.debounceFixTakeProfitPriceOrPercent()
    }

    fixTakeProfitPriceOrPercent() {
        if (`${this.state.takeProfitPriceOrPercent}`.length > 0) {
            if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
                this.setState({
                    takeProfitPriceOrPercent: getValueWithPrecision(this.state.takeProfitPriceOrPercent, 2)
                })
            } else this.setState({
                takeProfitPriceOrPercent: getValueWithPrecision(this.state.takeProfitPriceOrPercent, this.state.tickSize)
            })
        }
    }

    handleChangeTakeProfitTarget(e) {
        let i = parseInt(e.target.name)
        let value = e.target && e.target.value ? e.target.value : ""
        this.state.takeProfitTargets[i] = value
        this.setState({
            lastTakeProfitTargetEdited: i
        })

        this.debounceFixTakeProfitTarget()

    }

    fixTakeProfitTarget() {

        let i = this.state.lastTakeProfitTargetEdited
        if (`${this.state.takeProfitTargets[i]}`.length > 0) {
            if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE)
                this.state.takeProfitTargets[i] = getValueWithPrecision(this.state.takeProfitTargets[i], 2)
            else
                this.state.takeProfitTargets[i] = getValueWithPrecision(this.state.takeProfitTargets[i], this.state.tickSize)
            this.setState({
                randomValue: Math.random()
            })
        }
    }


    handleChangeStopLossOption(option) {
        changeFuturesStopLossOption(option, this, getCurrentPrice(this.props.futuresTrade))
    }

    handleChangeStopLossPriceOrPercent(e) {
        this.setState({
            stopLossPriceOrPercent: e.target.value
        })

        this.debounceFixStopLossPriceOrPercent()
    }

    computeCalculated() {
        this.state.computeCalculatedLock = true
        let l = parseFloat(this.state.leverage) //Leverage
        let markPrice = parseFloat(this.props.orderBookCurrentPrice) //Mark Price

        let orderPrice = this.state.fixedPrice
        let direction = this.state.futuresType === c.FUTURES_TYPE_LONG ? 1: -1
        let q = Math.abs(Math.min(0, direction * (markPrice - orderPrice))) //Abs(min[0, direction * (mark price - order price)])

        let size = this.state.size
        let notionalValue = this.state.notionalValue
        let initialMargin = 0
        let additionalMargin = 0
        let openLoss = 0
        let cost = 0
        let rCost = 0
        let max = 0
        let fP = 0

        additionalMargin = parseFloat(this.state.additionalMargin)
        if (isNaN(additionalMargin))
            additionalMargin = 0

        if (this.state.actionTypeOption === c.FUTURES_ACTION_TYPE_OPTION_MARKET) {
            fP = this.props.user ? parseFloat(this.props.user.fpmf): 0
        } else {
            fP = this.props.user ? parseFloat(this.props.user.fplf): 0
        }
        let fees = 0

        notionalValue = this.state.notionalValue
        fees = notionalValue * fP
        initialMargin = notionalValue / l
        openLoss = size * q
        cost = initialMargin + openLoss + fees
        rCost = initialMargin + openLoss
        max = size

        this.props.getRates({symbol: `${this.state.quote}${this.state.base}`, leverage: this.state.leverage, notionalValue}, result => {
            let {maxLeverage, maxLeverageNotionalValue, initialMarginRate, maintenanceMarginRate, maintenanceAmount, maxNotionalValue, overMaxNV} = result
            let maintenanceMargin = maintenanceAmount
            let mMarginRate = maintenanceMarginRate
            this.props.computeLiquidationPrice({symbol: `${this.state.quote}${this.state.base}`, type: c.FUTURES_TYPES[this.state.futuresType],
                leverage: this.state.leverage, wb: initialMargin + additionalMargin, entryPrice: orderPrice, quantity: size}, liquidationPrice => {
                let percentageLiquidationToEntry = ((liquidationPrice - orderPrice) / orderPrice * 100 * l).toFixed(2)

                let userBalance = parseFloat(this.state.userBaseBalance)

                if (cost >= userBalance) {
                    size = userBalance / (orderPrice/l + q + orderPrice * fP)
                    notionalValue = size * orderPrice
                    initialMargin = notionalValue /l
                    fees = notionalValue * fP
                    openLoss = size * q
                    cost = initialMargin + openLoss + fees
                    rCost = initialMargin + openLoss
                    max = size
                    // s = {
                    //     size, notionalValue, fees, initialMargin, openLoss, cost, rCost, max
                    // }
                    // console.log("case III", JSON.stringify(s))
                }

                this.setState({
                    computeCalculatedLock: false,
                    maxLeverage,
                    maxLeverageNotionalValue: maxNotionalValue,
                    maxNotionalValue,
                    initialMarginRate,
                    initialMargin: brushValue(initialMargin, this.state.tickSize),
                    openLoss: brushValue(openLoss, this.state.tickSize),
                    size: brushValue(size, this.state.precision),
                    cost: brushValue(cost, this.state.tickSize),
                    totalCost: brushValue(cost + this.state.additionalMargin, this.state.tickSize),
                    rCost: brushValue(rCost, this.state.tickSize),
                    max: brushValue(max, this.state.precision),
                    usedPrice: orderPrice,
                    liquidationPrice,
                    maintenanceMargin,
                    mMarginRate,
                    percentageLiquidationToEntry
                })
            })
        })
    }

    updateAdditionalMarginByNewPercent(percent) {

        let leverage = parseInt(this.state.leverage)
        let entryPrice = parseFloat(this.state.fixedPrice)
        // console.log("updateAdditionalMarginByNewPercent percent %s, leverage %s, entryPrice %s, " +
        //     "futuresType %s, %s", percent, leverage, entryPrice, this.state.futuresType, c.FUTURES_TYPES[this.state.futuresType])

        let sign = this.state.futuresType === c.FUTURES_TYPE_LONG ? -1: 1
        let newLiquidationPrice = entryPrice * (1 + sign * percent / 100/ leverage)
        this.props.computeAdditionalIsoMarg({
            symbol: `${this.state.quote}${this.state.base}`, //x
            type: c.FUTURES_TYPES[this.state.futuresType],
            leverage: this.state.leverage, //x
            wb: this.state.initialMargin, //x
            entryPrice: this.state.fixedPrice, //x
            quantity: this.state.size, //x
            liquidationPrice: newLiquidationPrice//x
        }, additionalIsoMargin => {
            let a = parseFloat(additionalIsoMargin)
            let q = a - this.state.additionalMargin
            // console.log("new additional Iso margin", additionalIsoMargin)
            // console.log("extra additional Iso margin", q)
            this.state.extraAdditionalMargin = q
            this.state.additionalMargin = a
            // this.setState({
            //     additionalMargin: additionalIsoMargin,
            //     totalCost: brushValue(this.state.cost + additionalIsoMargin, this.state.tickSize)
            // })
            this.computeCalculated()
        })
    }

    computeLiqPriceAndAddIsoMargAgainstStopLoss() {
        let percent = 0

        let referencePrice = this.state.fixedPrice

        if (this.state.stopLossOption === c.STOP_LOSS_OPTION_MANUAL) {
            return this.updateAdditionalMarginByNewPercent(100)
        } else if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE || this.state.stopLossOption === c.STOP_LOSS_OPTION_TRAILING) {
            percent = this.state.stopLossPriceOrPercent
        } else if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PRICE) {
            percent = Math.abs((this.state.stopLossPriceOrPercent - referencePrice) / referencePrice * 100 * this.state.leverage)
        }

        this.updateAdditionalMarginByNewPercent(percent)
    }

    //1983
    fixStopLossPriceOrPercent() {
        if (this.state.stopLossPriceOrPercent.length === 0)
            return

        this.computeLiqPriceAndAddIsoMargAgainstStopLoss()

        if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PRICE || this.state.stopLossOption === c.STOP_LOSS_OPTION_CONDITIONAL)
            this.setState({
                stopLossPriceOrPercent: getValueWithPrecision(this.state.stopLossPriceOrPercent, this.state.tickSize),
            })
        else this.setState({
            stopLossPriceOrPercent: getValueWithPrecision(this.state.stopLossPriceOrPercent, 2),
        })
    }

    goBack() {
        this.props.history.push("/futures")
    }

    openTakeProfitPanel() {
        this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, result => {
            let currentPrice = parseFloat(result.current_price).toFixed(parseInt(result.tick_size))
            this.setState({
                isTakeProfitPanelOpen: true,
                isTradeDetailsPanelOpen: false,
                currentPrice: currentPrice,
                executeTakeProfitOption: "market",
                executeTakeProfitPrice: currentPrice,
            })
        })
    }

    closeTakeProfitPanel() {
        this.setState({
            isTakeProfitPanelOpen: false,
            isTradeDetailsPanelOpen: true,
        })
    }

    handleChangeExecuteTakeProfitOption(option) {
        if (option !== c.ACTION_TYPE_OPTION_MARKET) {
            let currentPrice = this.state.currentPrice

            let executeTakeProfitPrice =  currentPrice?
                parseFloat(currentPrice) * (option === c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE ? c.LAST_BID_PRICE_MULTIPLIER : 1) : 0
            this.state.executeTakeProfitPrice = brushValue(executeTakeProfitPrice, this.state.tickSize)
        }
        this.setState({
            executeTakeProfitOption: option
        })
    }

    handleChangeExecuteTakeProfitPrice(price) {
        this.setState({
            executeTakeProfitPrice: price
        })

        this.debounceFixExecuteTakeProfitPrice()
    }

    fixExecuteTakeProfitPrice() {
        if (`${this.state.executeTakeProfitPrice}`.length > 0) {
            this.setState({
                executeTakeProfitPrice: getValueWithPrecision(this.state.executeTakeProfitPrice, this.state.tickSize)
            })
        }
    }

    executeTakeProfit() {
        if (this.state.executeTakeProfitOngoing)
            return

        let executeTakeProfitPrice = parseFloat(this.state.executeTakeProfitPrice)
        if ( executeTakeProfitPrice === 0) {
            return
        }
        this.state.executeTakeProfitOngoing = true
        this.state.isTakeProfitPanelOpen = false
        this.state.isTradeDetailsPanelOpen = true
        this.props.updateTradeExecuteTakeProfit({
            futuresTradeId: this.props.futuresTrade.order_id,
            executeTakeProfitOption: this.state.executeTakeProfitOption,
            executeTakeProfitPrice: this.state.executeTakeProfitPrice
        }, () => {setTimeout(()=>{
            this.setState({
                executeTakeProfitOngoing: false
            })
        }, 1000)})
    }

    openCancelTradePanel() {
        this.setState({
            isCancelTradePanelOpen: true,
            isTradeDetailsPanelOpen: false
        })
    }

    closeCancelTradePanel() {
        this.setState({
            isCancelTradePanelOpen: false,
            isTradeDetailsPanelOpen: true,
        })
    }

    cancelTrade() {
        this.state.isCancelTradePanelOpen = false
        this.state.isTradeDetailsPanelOpen = true
        this.props.updateFuturesTradeCancelIt({futuresTradeId: this.props.futuresTrade.order_id})
    }

    openDiscardTradePanel() {
        this.setState({
            isDiscardTradePanelOpen: true,
            isTradeDetailsPanelOpen: false
        })
    }

    closeDiscardTradePanel() {
        this.setState({
            isDiscardTradePanelOpen: false,
            isTradeDetailsPanelOpen: true,
        })
    }

    discardTrade() {
        this.state.isDiscardTradePanelOpen = false
        this.state.isTradeDetailsPanelOpen = true
        this.props.updateTradeDiscardIt({tradeId: this.props.futuresTrade.order_id})
    }

    openEditEnterTradeConditionsPanel() {
        this.setState({
            isEditEnterTradeConditionsPanelOpen: true,
            isTradeDetailsPanelOpen: false
        })
    }

    closeEditEnterTradeConditionsPanel() {
        this.setState({
            isEditEnterTradeConditionsPanelOpen: false,
            isTradeDetailsPanelOpen: true
        })
    }

    updateEnterTradeConditions() {
        this.state.isEditEnterTradeConditionsPanelOpen = false
        this.state.isTradeDetailsPanelOpen = true
        this.props.updateEnterTradeConditions({
            futuresTradeId: this.props.futuresTrade.order_id,
            fixedPrice: this.state.fixedPrice,
            actionTypeOption: this.state.actionTypeOption
        })
    }

    handleChangeActionTypeOption(actTypeOption) {
        if (this.state.quote && this.state.quote.length < 2)
            return

        if (this.state.tradeType === c.TRADE_TYPE_OLD) {
            if (actTypeOption !== c.ACTION_TYPE_OPTION_FIXED_PRICE)
                return
        }

        this.setState({
            actionTypeOption: actTypeOption
        })
        if (this.state.quote.length > 1 && actTypeOption !== c.ACTION_TYPE_OPTION_MARKET)
            this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, result => {
                let multiplier = 1
                if (actTypeOption === c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE) {
                    multiplier = c.LAST_BID_PRICE_MULTIPLIER
                } else if (actTypeOption === c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE) {
                    multiplier = c.FIRST_ASK_PRICE_MULTIPLIER
                }
                let currentPrice = (parseFloat(result.current_price) * multiplier).toFixed(parseInt(result.tick_size))
                this.setState({
                    fixedPrice: currentPrice
                })
                this.debounceFixFixedPrice()
            })
    }

    getCurrentPrices() {
        this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base})
    }

    fixFixedPrice() {
        if (`${this.state.fixedPrice}`.length > 0) {
            this.setState({
                fixedPrice: getValueWithPrecision(this.state.fixedPrice, 8)
            })
        }
    }

    handleChangeFixedPrice(e) {
        this.setState({
            fixedPrice: e.target.value
        })
        this.debounceFixFixedPrice()
    }

    handleChangeTakeProfitTargetPercentageExecute(e) {
        this.state.lastTakeProfitPercentateExecuteIndex = e.target.name
        this.setState({
            [`take_profit_target_${e.target.name}_percentage_execute`]: e.target.value
        })

        this.debounceFixTakeProfitTargetExecute()
    }

    fixTakeProfitTargetPercentageExecute() {
        let i = parseInt(this.state.lastTakeProfitPercentateExecuteIndex)
        let tpt1, tpt2
        if (i === 1) {
            if (`${this.state.take_profit_target_1_percentage_execute}`.length > 0) {
                tpt1 = getValueWithPrecision(this.state.take_profit_target_1_percentage_execute, 0)
                tpt1 = Math.abs(tpt1)
                if (tpt1 > 100)
                    tpt1 = 100

                tpt2 = 100 - tpt1
            }
        } else if (i === 2) {
            if (`${this.state.take_profit_target_2_percentage_execute}`.length > 0) {
                tpt2 = getValueWithPrecision(this.state.take_profit_target_2_percentage_execute, 0)
                tpt2 = Math.abs(tpt2)
                if (tpt2 > 100)
                    tpt2 = 100
                tpt1 = 100 - tpt2
            }
        }

        if (tpt1 && tpt2) {
            this.setState({
                take_profit_target_1_percentage_execute: tpt1,
                take_profit_target_2_percentage_execute: tpt2,
            })
        }
    }

    componentDidMount() {
        if (!this.props.futuresTrade)
            return

        let {user} = this.props
        let {currentPrice} = getCurrentBuyAndSellPrice(this.props.futuresTrade)
        const {quote, base} = getQuoteAndBase(this.props.futuresTrade)
        let {futuresTrade} = this.props
        this.state.fTradeOrderId = futuresTrade.order_id
        this.state.futuresType = futuresTrade.futures_type
        this.state.leverage = futuresTrade.leverage
        this.state.takeProfitOption = futuresTrade.take_profit_option
        this.state.takeProfitPriceOrPercent = futuresTrade.take_profit_price_or_percent
        if (futuresTrade.from_SUC) {
            this.state.takeProfitTargets = []
            let l = parseInt(futuresTrade.take_profit_targets)
            for(let i = 0; i < l; i++) {
                this.state.takeProfitTargets.push(futuresTrade[`take_profit_price_or_percent_${i+1}`])
            }
            this.state.take_profit_target_1_percentage_execute = futuresTrade.take_profit_target_1_percentage_execute
            this.state.take_profit_target_2_percentage_execute = futuresTrade.take_profit_target_2_percentage_execute
        }

        this.state.stopLossOption = futuresTrade.stop_loss_option
        this.state.candlesType = futuresTrade.candles_type
        this.state.stopLossPriceOrPercent = futuresTrade.stop_loss_price_or_percent
        this.state.executeTakeProfitPrice = currentPrice
        this.state.actionTypeOption = futuresTrade.action_type_option
        this.state.initialMargin = parseFloat(futuresTrade.initial_margin)
        this.state.size = parseFloat(futuresTrade.origQty)
        this.state.fixedPrice = parseFloat(futuresTrade.price)
        this.state.notionalValue = parseFloat(futuresTrade.notionalValue)
        this.state.quote = quote
        this.state.base = base
        this.state.tickSize = parseInt(this.props.futuresTrade.tick_size)
        this.state.liquidationPrice = futuresTrade.liquidationPrice
        this.state.initialLiquidationPrice = futuresTrade.liquidationPrice
        this.state.additionalMargin = futuresTrade.additionalIsolatedMargin
        this.state.initialAdditionalMargin = futuresTrade.additionalIsolatedMargin

        user && initWSF(this.props.user.userId, `${quote}${base}`, true)
    }

    componentWillUnmount() {
        closeWSF()
    }

    render() {
        let {futuresTrade} = this.props
        if (!futuresTrade)
            return <Redirect to="/futures"/>
        if (futuresTrade.child_order && futuresTrade.child_order.order_id) {
            let s = futuresTrade.child_order.status
            if (s === "FILLED" || s === "ERRORED") {
                return <Redirect to="/futurespossummary"/>
            }
        } else {
            let s = futuresTrade.status
            if (s === "CANCELED" || s === "ERROR") {
                return <Redirect to="/futuresordersummary"/>
            }

        }

        const {quote, base} = getQuoteAndBase(futuresTrade)
        const verb = getFuturesTradeEnterVerb(futuresTrade)
        const verbFirstCaps = capitalize(verb)
        const notionalValue = brushValue(futuresTrade.notional_value, futuresTrade.tick_size)
        const origQty = brushValue(futuresTrade.orig_qty)
        const executedQty = brushValue(futuresTrade.executed_qty)
        const {currentPriceDeltaPercent, currentPrice, currentPriceClass} = getCurrentPriceAndDeltaPercentForFutures(futuresTrade, !hasTradeChild(futuresTrade))
        const {maxPrice, maxDelta, minPrice, minDelta} = getMaxMinAndDeltaPercentFutures(futuresTrade, !hasTradeChild(futuresTrade))
        const {tpContent, slContent} = getTradeConditionsContentForFuturesTradeDetails(futuresTrade)
        const stateTitle = getTradeStateTitle(futuresTrade)
        const tradeState = getFuturesTradeState(futuresTrade)
        const {hrStateText, hrStateSymbol, hrStateSymbolColor, blinking} = getHRStateForFuturesTrade(futuresTrade)
        const allowedToEditTradeConditions = getAllowedToEditTradeConditions(futuresTrade)
        const allowedToEditOrderConditions = getAllowedToEditFuturesOrderConditions(futuresTrade, this.props.ASAS)
        const enterTradePrice = getEnterTradePriceForFutures(futuresTrade)
        const actionTypeOptionExplicit = getActionTypeOptionDetailsExplicitFutures(futuresTrade)
        let progress = 0


        if (futuresTrade.child_order && futuresTrade.child_order.order_id) {
            progress = getProgress(futuresTrade)
        } else {
            progress = getProgressForWaitingTrade(futuresTrade)
        }


        return <div className={"TradeContent"} style={{display: "flex"}}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <div style={{marginLeft: "0"}}>
                <div style={{maxWidth: "30rem", minWidth: "22rem"}}>
                    {this.state.isTradeDetailsPanelOpen &&
                    <Fade in={this.state.isTradeDetailsPanelOpen}>
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.goBack()}}><ArrowBack/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>Futures Trade <span style={{opacity: "0.5"}}>{futuresTrade.order_id}</span> details</div>
                                    <div style={{width: "2.6rem"}}/>
                                </div>
                            </div>
                            <div className={"tradeDetailsPair"}>
                                {quote} <span className={"tradeDetailsPairSlash"}>/</span> {base}
                            </div>
                            {(tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_ONGOING || tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_PARTIALLY_FILLED) &&
                            <div className={"tradeDetailsOngoingAction"}>
                                <div className={"vertSpacer1"}/>
                                {blinking && <div className="blink liveBig" style={{textAlign: "center", color: hrStateSymbolColor}}>{hrStateText}{hrStateSymbol}</div>}
                            </div>
                            }
                            <div className={"vertSpacer1"}/>
                            <div className={"tradeDetailsCreationDate"}>
                                {(tradeState === c.FUTURES_TRADE_STATE_PARENT_ONGOING || tradeState === c.FUTURES_TRADE_STATE_PARENT_PARTIALLY_FILLED || tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_ONGOING) &&
                                <span><b>{stateTitle}</b> for <TimeAgo date={parseInt(futuresTrade.time)} formatter={timeAgoFormatter}/> · </span>} created on {timeFormatter(futuresTrade.time, true)}
                            </div>

                            {(tradeState === c.FUTURES_TRADE_STATE_PARENT_ONGOING || tradeState === c.FUTURES_TRADE_STATE_PARENT_PARTIALLY_FILLED) &&
                            <div>
                                <div>
                                    <div className={"tradeDetailsPosAndQty"}>
                                        <b>{notionalValue} {base}</b> {verb} <small>{executedQty}/{origQty} {quote}</small>
                                    </div>
                                    <div className={"tradeDetailsPosAndQty"}>
                                    at price <b>{enterTradePrice} {base}</b>
                                    </div>
                                </div>
                                <div style={{margin: "auto", width: "7.6rem"}}>
                                    <div className={"w3-progress-sizer"}>
                                        <div className="w3-progress-container w3-round-xlarge">
                                            <div className="w3-progressbar w3-round-xlarge" style={{width:`${progress}%`}}/>
                                        </div>
                                    </div>
                                    <div className={"progressBarText"}>{progress}% FILLED</div>
                                </div>
                            </div>}

                            {(tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_ONGOING || tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_PARTIALLY_FILLED) &&
                                <div>
                                    <div>
                                        <div className={"tradeDetailsPosAndQty"}>
                                            <b>{notionalValue} {base}</b> bought <small>{executedQty} {quote}</small>
                                        </div>
                                        <div className={"tradeDetailsPosAndQty"}>
                                            at price <b>{enterTradePrice} {base}</b>
                                        </div>
                                    </div>
                                </div>
                            }

                            <div className={`tradeDetailsCurrentPrice ${currentPriceClass}`} title={"Current price"}>
                                {currentPriceDeltaPercent}% · {currentPrice}
                            </div>
                            <div className={"tradeDetailsMaxPrice"}>
                                {maxPrice > 0 && <div className={"maxPriceContainer2"}>max price {maxDelta}% · {maxPrice}</div>}
                                {minPrice > 0 && <div className={"minPriceContainer2"}>min price {minDelta}% · {minPrice}</div>}
                            </div>
                            <div className={"vertSpacer2"}/>
                            {futuresTrade.from_SUC && <div style={{padding: "0.6rem"}}>
                                <Paper className={"tradeDetailsTradeConditionsPaper"}>
                                    <div>
                                        {(futuresTrade.connected_to_signal === true || futuresTrade.connected_to_signal === "true") && <div><FormControlLabel
                                            control={<CustomColorCheckbox checked={true}/>}
                                            label={"Connected"}
                                            onChange={()=>{this.updateTradeFromSUCConnection(Math.random())}}
                                        />
                                            <div className={"smallExplanations2"} style={{marginLeft: "1rem"}}>Receiving updates from Signal. You cannot edit it.</div>
                                        </div>}
                                        {(futuresTrade.connected_to_signal === false || futuresTrade.connected_to_signal === "false") && <div><FormControlLabel
                                            control={<CustomColorCheckbox checked={false}/>}
                                            label={"Connected"}
                                            onChange={()=>{this.updateTradeFromSUCConnection(Math.random())}}
                                        />
                                            <div className={"smallExplanations2"} style={{marginLeft: "1rem"}}>Won't receive updates from Signal. You can edit it.</div>
                                        </div>}
                                    </div>
                                </Paper>
                            </div>}
                            <div style={{padding: "0.6rem"}}>
                                <Paper className={"tradeDetailsTradeConditionsPaper"}>
                                    <div style={{display: "flex", justifyContent: "space-between" ,fontSize: "0.7rem"}}>
                                        <span>ORDER CONDITIONS</span>
                                        {allowedToEditOrderConditions && <span style={{cursor: "pointer"}} onClick={()=> {this.openEditEnterTradeConditionsPanel()}}><Create/></span>}
                                    </div>
                                    {futuresTrade.action_type_option !== c.FUTURES_ACTION_TYPE_OPTION_MARKET && <div className={"takeProfitStopLossConfigTradeDetails"}>
                                        Entry price&nbsp;<b>{enterTradePrice}</b>
                                    </div>}
                                    <div className={"takeProfitStopLossConfigTradeDetails"}>
                                        Entering <b>{actionTypeOptionExplicit}</b>
                                    </div>
                                    {(this.props.ASAS &&
                                        this.props.ASAS.suspendActivities === "true" && this.props.ASAS.suspendedActivitiesMap &&
                                        this.props.ASAS.suspendedActivitiesMap[c.SUSPENDED_ACTIVITIES_EDIT_ENTER_TRADE_CONDITIONS])
                                    && <div className={"maintenanceWarningSmall"} style={{marginTop: "0.6rem"}}>Editing futures order conditions is temporary disabled.</div>}
                                </Paper>
                            </div>
                            <div style={{padding: "0.6rem"}}>
                                <Paper className={"tradeDetailsTradeConditionsPaper"}>
                                    <div style={{display: "flex", justifyContent: "space-between" ,fontSize: "0.7rem"}}>
                                        <span>TRADE CONDITIONS</span>
                                        {allowedToEditTradeConditions && <span style={{cursor: "pointer"}} onClick={()=> {this.openEditTradeConditionsPanel()}}><Create/></span>}
                                    </div>
                                    {tpContent && <div className={"takeProfitStopLossConfigTradeDetails"}>
                                        {tpContent}
                                    </div>}
                                    <div className={"takeProfitStopLossConfigTradeDetails"}>
                                        {slContent}
                                    </div>
                                </Paper>
                            </div>
                            <div style={{padding: "0.6rem"}}>
                                <FuturesTradeDetailsMainButtons futuresTrade={futuresTrade}
                                                                takeProfit={this.openTakeProfitPanel}
                                                                cancelTrade={this.openCancelTradePanel}
                                                                discardTrade={this.openDiscardTradePanel}
                                                                state={tradeState}
                                                                ASAS={this.props.ASAS}/>
                            </div>
                        </Paper>
                    </Fade>}
                    {this.state.isEditTradeConditionsPanelOpen &&
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeEditTradeConditionsPanel()}}><Clear/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>Trade conditions</div>
                                    <div className={"linkButton"} style={{marginRight: "0.6rem"}} onClick={()=> {this.updateTradeConditionsGeneric()}}>SAVE</div>
                                </div>
                            </div>
                            <div className={"tradeDetailsTradeConditionsPaper"}>
                                {!futuresTrade.from_SUC && <FuturesConditions
                                                leverage={this.state.leverage}
                                                currentPrice={this.state.fixedPrice}
                                                liquidationPrice={this.state.liquidationPrice}
                                                additionalMargin={this.state.additionalMargin}
                                                takeProfitOption={this.state.takeProfitOption}
                                                takeProfitPriceOrPercent={this.state.takeProfitPriceOrPercent}
                                                stopLossOption={this.state.stopLossOption}
                                                stopLossPriceOrPercent={this.state.stopLossPriceOrPercent}
                                                switchWithOrWithoutStopLossTarget={this.switchWithOrWithoutStopLossTarget}
                                                handleChangeTakeProfitOption={this.handleChangeTakeProfitOption}
                                                handleChangeTakeProfitPriceOrPercent={this.handleChangeTakeProfitPriceOrPercent}
                                                handleChangeStopLossOption={this.handleChangeStopLossOption}
                                                handleChangeStopLossPriceOrPercent={this.handleChangeStopLossPriceOrPercent}
                                                handleChangeCandlesType={this.handleChangeCandlesType}
                                                base={this.state.base}
                                                futuresType={this.state.futuresType}
                                                candlesType={this.state.candlesType}
                                                stopLossPriceOrPercentError={this.state.stopLossPriceOrPercentError}
                                                handleChangeAdditionalMargin={this.handleChangeAdditionalMargin}
                                />}
                                {futuresTrade.from_SUC && <FuturesTradeConditionsFromSUC
                                    leverage={this.state.leverage}
                                    currentPrice={this.state.fixedPrice}
                                    liquidationPrice={this.state.liquidationPrice}
                                    additionalMargin={this.state.additionalMargin}
                                    futuresType={futuresTrade.futures_type}
                                    takeProfitOption={this.state.takeProfitOption}
                                    takeProfitTargets={this.state.takeProfitTargets}
                                    stopLossOption={this.state.stopLossOption}
                                    stopLossPriceOrPercent={this.state.stopLossPriceOrPercent}
                                    switchWithOrWithoutStopLossTarget={this.switchWithOrWithoutStopLossTarget}
                                    handleChangeTakeProfitOption={this.handleChangeTakeProfitOption2}
                                    handleChangeTakeProfitTarget={this.handleChangeTakeProfitTarget}
                                    handleChangeStopLossOption={this.handleChangeStopLossOption}
                                    handleChangeStopLossPriceOrPercent={this.handleChangeStopLossPriceOrPercent}
                                    take_profit_target_1_percentage_execute={this.state.take_profit_target_1_percentage_execute}
                                    take_profit_target_2_percentage_execute={this.state.take_profit_target_2_percentage_execute}
                                    handleChangeTakeProfitTargetPercentageExecute={this.handleChangeTakeProfitTargetPercentageExecute}
                                    base={this.state.base}
                                />}
                            </div>
                        </Paper>}
                    {this.state.isTakeProfitPanelOpen &&
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeTakeProfitPanel()}}><Clear/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>Take profit</div>
                                    <div style={{width: "2.6rem"}}/>
                                </div>
                            </div>
                            <div className={"tradeDetailsTradeConditionsPaper"}>
                                <TakeProfitConditions trade={futuresTrade}
                                                      currentPrice={this.state.currentPrice}
                                                      executeTakeProfitOption={this.state.executeTakeProfitOption}
                                                      executeTakeProfitPrice={this.state.executeTakeProfitPrice}
                                                      handleChangeExecuteTakeProfitOption={this.handleChangeExecuteTakeProfitOption}
                                                      handleChangeExecuteTakeProfitPrice={this.handleChangeExecuteTakeProfitPrice}
                                                      executeTakeProfit={this.executeTakeProfit}
                                                      nevermind={this.closeTakeProfitPanel}
                                />
                            </div>
                        </Paper>}
                    {this.state.isCancelTradePanelOpen &&
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeCancelTradePanel()}}><Clear/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>Cancel futures trade</div>
                                    <div style={{width: "2.6rem"}}/>
                                </div>
                            </div>
                            <div className={"tradeDetailsTradeConditionsPaper"}>
                                <CancelItem cancelItem={this.cancelTrade} nevermind={this.closeCancelTradePanel}
                                    question={"Are you sure you want to cancel this futures trade?"}
                                    cancelText={"Cancel"} nevermindText={"Nevermind"}/>
                            </div>
                        </Paper>}
                    {this.state.isDiscardTradePanelOpen &&
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeDiscardTradePanel()}}><Clear/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>Discard futures trade</div>
                                    <div style={{width: "2.6rem"}}/>
                                </div>
                            </div>
                            <div className={"tradeDetailsTradeConditionsPaper"}>
                                <DiscardItem discardItem={this.discardTrade} nevermind={this.closeDiscardTradePanel}
                                             question={"Are you sure you want to discard this futuresTrade?"}
                                             discardWarningText={"Discarding this futures trade will remove it from your lists permanently."}/>
                            </div>
                        </Paper>}
                    {this.state.isEditEnterTradeConditionsPanelOpen &&
                    <Paper style={{backgroundColor: "#101926"}}>
                        <div className={"tradeDetailsTopBar"}>
                            <div className={"tradeDetailsTopBarVerticalCenter"}>
                                <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeEditEnterTradeConditionsPanel()}}><Clear/></div>
                                <div className={"tradeDetailsTopBarTitle"}>Enter trade conditions</div>
                                <div className={"linkButton"} style={{marginRight: "0.6rem"}} onClick={()=> {this.updateEnterTradeConditions()}}>SAVE</div>
                            </div>
                        </div>
                        <div className={"tradeDetailsTradeConditionsPaper"}>
                            <FuturesOrderConditions
                                futuresType={futuresTrade.futures_type}
                                fixedPriceLabel={`${verbFirstCaps} price`}
                                actionTypeOptionLabel={`${verbFirstCaps} @`}
                                actionTypeOption={this.state.actionTypeOption}
                                handleChangeActionTypeOption={this.handleChangeActionTypeOption}
                                fixedPrice={this.state.fixedPrice}
                                handleChangeFixedPrice={this.handleChangeFixedPrice}
                                quote={quote}
                                base={base}
                                currentPrice={this.state.executeTakeProfitPrice}
                                hideTitle={false}
                            />
                        </div>
                    </Paper>}
                    <NeedExtraIsoMargDialog
                        open={this.state.isNeedExtraIsoMargDialogOpen}
                        title={"Additional isolated margin needed"}
                        extraAdditionalMargin={this.state.extraAdditionalMargin}
                        handleOk={this.handleAddExtraIsoMarg}
                        handleCancel={this.cancelAddExtraIsoMarg}
                        availableMargin={this.state.availableMargin}
                        okLabel={"Add"}
                        showWaitingAddingExtraIsoMarg={this.state.showWaitingAddingExtraIsoMarg}
                    />
                </div>
            </div>
            <div key={"orderBook"} className={"OrderBook"}>
                <OrderBook base={this.state.base} quote={this.state.quote} orderBook={this.props.orderBook} size={12} futures={true}/>
            </div>
            <div className={"vertSpacer1_5"}/>
        </div>
    }

}


let mapStateToProps = rootState =>({
    user: rootState.authentication.user,
    futuresTrade: rootState.futures.openedFuturesTrade,
    ASAS: rootState.administration.asas,
    random: rootState.futures.random, //used to refresh the page for example after a take profit is issued
    orderBook: rootState.orderBook.orderBook,
    orderBookCurrentPrice: rootState.orderBook.currentPrice
})

let mapDispatchToProps = dispatch => {
    return {
        updateEnterTradeConditions: (props) => dispatch(updateEnterTradeConditions(props)),
        updateTradeConditions: (props) => dispatch(updateTradeConditions(props)),
        updateTradeFromSUCTradeConditions: (props) => dispatch(updateTradeFromSUCTradeConditions(props)),
        updateTradeExecuteTakeProfit: (props, cb) => dispatch(updateTradeExecuteTakeProfit(props, cb)),
        updateTradeFromSUCConnectionToSignal: (props) => dispatch(updateTradeFromSUCConnectionToSignal(props)),
        updateFuturesTradeCancelIt: (props) => dispatch(updateFuturesTradeCancelIt(props)),
        updateTradeDiscardIt: (props) => dispatch(updateFuturesTradeDiscardIt(props)),
        getCurrentPriceOnPair: (props, cb) => dispatch(getCurrentPriceOnPair(props, cb)),
        getFutureMarginBalances: (props, cb) => dispatch(getFutureMarginBalances(props, cb)),
        modifyIsolatedMargin: (props, cb) => dispatch(modifyIsolatedMargin(props, cb)),
        computeLiquidationPrice: (props, cb) => dispatch(computeLiquidationPrice(props, cb)),
        computeAdditionalIsoMarg: (props, cb) => dispatch(computeAdditionalIsoMarg(props, cb)),
        getRates: (props, cb) => dispatch(getRates(props, cb)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps) (FuturesTradeDetails)
