import React from "react"
import {TextField, Tooltip} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import InputAdornment from "@material-ui/core/InputAdornment"
import {TrendingUp, TrendingDown, Timeline} from "@material-ui/icons"
import Fab from '@material-ui/core/Fab'
import CustomSwitch from "./CustomSwitch"
import c from '../../constants'
import s from '../../settings'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 270,
        backgroundColor: '#101A26'
    },
    additionalMarginTextField: {
        marginRight: theme.spacing(1),
        width: 200,
        backgroundColor: '#101A28'
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    fab: {
        // margin: theme.spacing(1),
        marginLeft: theme.spacing(0.6),
        backgroundColor: '#101A26',
        border: "1px solid #ffffff",
        color: "#ffffff"
    },
    fabSelected: {
        // margin: theme.spacing(),
        marginLeft: theme.spacing(0.6),
        backgroundColor: '#ffffff',
        border: "1px solid #000000",
        color: "#000000"
    },
    switcher: {
        checked: {
            backgroundColor: "#17BEBB"
        }
    },
    tabFabSelected: {
        backgroundColor: '#ffffff',
    }
}))

export default function FuturesConditions(props) {
    let {
        currentPrice,
        liquidationPrice,
        leverage,
        futuresType,
        takeProfitOption, takeProfitPriceOrPercent,
        stopLossOption, stopLossPriceOrPercent,
        switchWithOrWithoutStopLossTarget,
        handleChangeTakeProfitOption, handleChangeTakeProfitPriceOrPercent,
        handleChangeStopLossOption, handleChangeStopLossPriceOrPercent,
        base, noTitle, candlesType, handleChangeCandlesType, stopLossPriceOrPercentError,
        additionalMargin,
        handleChangeAdditionalMargin} = props

    const classes = useStyles()
    const [withStopLossTarget, setWithStopLossTarget] = React.useState(true)
    const [trailingStopLossOption, setTrailingStopLossOption] = React.useState(false)
    const [showTitle, setShowTitle] = React.useState(true)

    React.useEffect(() => {
        // setProfitPriceOrPercent(takeProfitPriceOrPercent)
        // setStopPriceOrPercent(stopLossPriceOrPercent)
        if (stopLossOption === c.STOP_LOSS_OPTION_TRAILING)
            setTrailingStopLossOption(true)
        else
            setTrailingStopLossOption(false)

        if (noTitle) {
            setShowTitle(false)
        }

        setWithStopLossTarget(stopLossOption !== c.STOP_LOSS_OPTION_MANUAL)
    })

    function getTakeProfitInputAdornment() {
        switch (takeProfitOption) {
            case c.TAKE_PROFIT_OPTION_MANUAL: return "";
            case c.TAKE_PROFIT_OPTION_PERCENTAGE: return "%";
            case c.TAKE_PROFIT_OPTION_PRICE: return base;

        }
    }

    function getTakeProfitLabel() {
        switch (takeProfitOption) {
            case c.TAKE_PROFIT_OPTION_MANUAL: return <span style={{fontWeight: "bold", color: "#ffffff"}}>Manual take profit</span>;
            case c.TAKE_PROFIT_OPTION_PERCENTAGE: return <span>Take profit at <span style={{fontWeight: "bold", color: "#ffffff"}}>percentage</span></span>;
            case c.TAKE_PROFIT_OPTION_PRICE: return <span>Take profit at <span style={{fontWeight: "bold", color: "#ffffff"}}>price</span></span>;
            default: return "_________________________";

        }
    }

    function getRealTakeProfitPercentage() {
        if (takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
            return <div  className={"realProfit"} style={{paddingLeft: "1.6rem"}}>Real profit <b>+{takeProfitPriceOrPercent * leverage}%</b></div>
        } else if (takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE) {
            let pricePercentageVariation = ((takeProfitPriceOrPercent - currentPrice) / currentPrice * 100)
            let realProfit = leverage * pricePercentageVariation
            return <div className={"realProfit"} style={{paddingLeft: "1.6rem"}}>Real profit <b>{realProfit.toFixed(0)}%</b> (price +{pricePercentageVariation.toFixed(0)}%)</div>
        }
    }

    function getRealStopLossPercentage() {
        if (stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE) {
            return <div className={"realLoss"} style={{paddingLeft: "1.6rem"}}>Real loss <b>{stopLossPriceOrPercent * leverage}%</b></div>
        } else if (stopLossOption === c.STOP_LOSS_OPTION_PRICE) {
            let pricePercentageVariation = ((stopLossPriceOrPercent - currentPrice) / currentPrice * 100)
            let realLoss = leverage * pricePercentageVariation
            return <div className={"realLoss"} style={{paddingLeft: "1.6rem"}}>Real loss <b>{realLoss.toFixed(0)}%</b> (price {pricePercentageVariation.toFixed(0)}%)</div>
        }
    }

    function getStopLossInputAdornment() {
        switch (stopLossOption) {
            case c.STOP_LOSS_OPTION_TRAILING: return "%";
            case c.STOP_LOSS_OPTION_PERCENTAGE: return "%";
            case c.STOP_LOSS_OPTION_PRICE: return base;

        }
    }

    function getStopLossLabel() {
        switch (stopLossOption) {
            case c.STOP_LOSS_OPTION_TRAILING: return <span><span style={{fontWeight: "bold", color: "#ffffff"}}>Trailing price percentage</span> stop loss</span>
            case c.STOP_LOSS_OPTION_PERCENTAGE: return <span>Stop loss at <span style={{fontWeight: "bold", color: "#ffffff"}}>percentage</span></span>
            case c.STOP_LOSS_OPTION_PRICE: return <span>Stop loss at <span style={{fontWeight: "bold", color: "#ffffff"}}>price</span></span>
            default: return "____________________";

        }
    }

    function switchTrailingStopLoss() {
        let newValue = !trailingStopLossOption
        setTrailingStopLossOption(newValue)

        if (newValue)
            handleChangeStopLossOption(c.STOP_LOSS_OPTION_TRAILING)
        else
            handleChangeStopLossOption(c.STOP_LOSS_OPTION_PERCENTAGE_FROM_TRAILING)
    }

    function getDirection() {
        if (futuresType === c.FUTURES_TYPE_LONG || futuresType === c.FUTURES_TYPE_LONG_s)
            return "rises"
        else return "descends"
    }

    function getStyleByStopLossTab(_stopLossTab) {
        if ( (_stopLossTab === 0 &&
                (stopLossOption === c.STOP_LOSS_OPTION_PRICE ||
                stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE ||
                stopLossOption === c.STOP_LOSS_OPTION_TRAILING)) ||
            (_stopLossTab === 1 && stopLossOption === c.STOP_LOSS_OPTION_CONDITIONAL)) {
            return {backgroundColor: "#ffffff", color: "#000000"}
        }
    }

    function getDeltaPercentLiqPrice() {
        if (liquidationPrice > 0) {
            let delta = Math.abs((currentPrice - liquidationPrice) / currentPrice * 100 * leverage).toFixed(2)
            return `(-${delta}%)`
        }
    }


    return (<div className={"wizardSectionContainer"}>
        {showTitle && <div className={"wizardTitle"}>
            <span className={"wizardTitleIcon"}><Timeline/></span> Set how you close the position
        </div>}
        <div id={"takeProfit"} style={{display: "flex", marginTop: "1em",
            justifyContent: "space-between", flexWrap: "wrap", maxWidth: "26em"}}>
            <div>
                <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8"}}>{getTakeProfitLabel()}</div>
                <TextField
                    className={classes.textField}
                    variant={"outlined"}
                    margin={"dense"}
                    id="input-with-icon-textfield_trade_cond"
                    value={takeProfitPriceOrPercent}
                    onChange={handleChangeTakeProfitPriceOrPercent}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <TrendingUp/>
                                {(takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE) && <span className={"signIndent"}>+</span>}
                            </InputAdornment>
                        ),
                        endAdornment: <InputAdornment position="end">{getTakeProfitInputAdornment()}</InputAdornment>
                    }}
                    inputProps={{ maxLength: 16 }}
                />
            </div>
            <div style={{marginTop: "1.05rem", width: "8rem"}}>
                <Fab color={takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE? "primary": "default"} aria-label="Add"
                     className={takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE ? classes.fabSelected : classes.fab} variant={"extended"}
                     onClick={() => {handleChangeTakeProfitOption(c.TAKE_PROFIT_OPTION_PERCENTAGE)}}>
                    %
                </Fab>
                <Fab color={takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE? "primary": "default"} aria-label="Add"
                     className={takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE ? classes.fabSelected : classes.fab} variant={"extended"}
                     onClick={() => {handleChangeTakeProfitOption(c.TAKE_PROFIT_OPTION_PRICE)}}>
                    #
                </Fab>
            </div>
        </div>
        {/*{getRealTakeProfitPercentage()}*/}
        <div style={{marginLeft: "0.6em", marginTop: "1.6em", marginBottom: "0.6rem"}}>
            With stop loss
            <CustomSwitch checked={withStopLossTarget} color="primary"
                          inputProps={{ 'aria-label': 'checkbox with default color' }}
                          onChange={switchWithOrWithoutStopLossTarget} classes={{checked: classes.switcher.checked}}
            />
        </div>
        <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8"}}>
            <Tooltip title={<span className={"toolTip"}>{`Your trade will be stopped at the liquidation price. In order to increase the interval till the liquidation price is reached, more margin is added automatically.`}</span>} placement="right" enterTouchDelay={s.enterTouchDelay}>
                <span className={"newFTradeFieldsWithExplanations"}>Liquidation price</span>
            </Tooltip>
            <span style={{fontSize: "1rem", fontWeight: "bold", color: "#3BADFF"}}>{liquidationPrice} {base} {getDeltaPercentLiqPrice()}</span>
        </div>
        <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8"}}>
            <Tooltip title={<span className={"toolTip"}>{`Additional isolated margin, added automatically in order to support the liquidation price.`}</span>} placement="right" enterTouchDelay={s.enterTouchDelay}>
                <span className={"newFTradeFieldsWithExplanations"}>Additional margin</span>
            </Tooltip>
            <span style={{fontSize: "1rem", fontWeight: "bold"}}>{additionalMargin} {base}</span>
        </div>

        {withStopLossTarget && <div>
            <div id={"stopLoss"} style={{display: "flex", marginTop: "1em",
                justifyContent: "space-between", flexWrap: "wrap", maxWidth: "25em"}}>
                <div>
                    <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8"}}>{getStopLossLabel()}</div>
                    <TextField
                        className={classes.textField}
                        variant={"outlined"}
                        margin={"dense"}
                        id="input-with-icon-textfield_trade_cond_2"
                        value={stopLossPriceOrPercent}
                        error={!!stopLossPriceOrPercentError}
                        helperText={stopLossPriceOrPercentError}
                        onChange={handleChangeStopLossPriceOrPercent}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <TrendingDown/>
                                    {(stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE ||
                                        stopLossOption === c.STOP_LOSS_OPTION_TRAILING) && <span className={"signIndent"}>-</span>}
                                </InputAdornment>
                            ),
                            endAdornment: <InputAdornment position="end">{getStopLossInputAdornment()}</InputAdornment>
                        }}
                        inputProps={{ maxLength: 16 }}
                    />
                </div>
                <div style={{marginTop: "1.05rem", width: "7rem"}}>
                    <Fab color={ (stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE || stopLossOption === c.STOP_LOSS_OPTION_TRAILING)
                        ? "primary": "default"} aria-label="Add"
                         className={(stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE || stopLossOption === c.STOP_LOSS_OPTION_TRAILING) ?
                         classes.fabSelected: classes.fab} variant={"extended"}
                         onClick={() => {handleChangeStopLossOption(c.STOP_LOSS_OPTION_PERCENTAGE)}}>
                        %
                    </Fab>
                    <Fab color={stopLossOption === c.STOP_LOSS_OPTION_PRICE ? "primary": "default"}
                         aria-label="Add" className={stopLossOption === c.STOP_LOSS_OPTION_PRICE ? classes.fabSelected: classes.fab}
                         variant={"extended"}
                         onClick={() => {handleChangeStopLossOption(c.STOP_LOSS_OPTION_PRICE)}}>
                        #
                    </Fab>
                </div>
            </div>
            <div style={{marginLeft: "0.6em", marginBottom: "1rem"}}>
                Use trailing stop loss
                <CustomSwitch checked={trailingStopLossOption} color="primary"
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                        onChange={switchTrailingStopLoss} classes={{checked: classes.switcher.checked}}
                />
                <div style={{maxWidth: "21em", fontSize: "0.86em"}}>Trailing moves stop loss price automatically if the price {getDirection()}.</div>
            </div>
        </div>}
    </div>)
}
