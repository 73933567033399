import React, {Component} from 'react'
import {connect} from 'react-redux'
import SelectPairFutures from "../fncomponents/SelectPairFutures"
import {closeWSF, initWSF} from "../../redux/actions/wsOrderBook"
import c from '../../constants'
import CircularIndeterminate from "../fncomponents/loading"
import {
    addCommasToLeftSideOfTheNumber,
    brushValue,
    brushValue2, changeFuturesStopLossOption, changeFuturesTakeProfitOption,
    getValueWithPrecision,
    isValidPair,
    scientificToDecimal
} from "../fncomponents/UISupportFns"
import {Info, Polymer, Warning} from "@material-ui/icons"
import {clearErrors} from "../../redux/actions/errors"
import {debounce} from "../hoc/myUtils"
import OrderBook from "../fncomponents/OrderBook"
import {getTitle} from "../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"
import {getActiveSuspendedActivities} from "../../redux/actions/administration"
import SelectFuturesTypeRadios from "../fncomponents/SelectFuturesTypeRadios"
import {
    checkUserBalance,
    clearNotEnoughBalance,
    getCurrenciesMap,
    getCurrentPriceOnPair,
    getLastUsedParamsOnNewFutures,
    getSymbolPrecisionAndTickSize,
    getUserBaseBalance,
    getUserLeverage,
    previewNewFutures,
    updateUserLeverage
} from "../../redux/actions/newFutures"
import SetPosSizeFutures from "../fncomponents/SetPosSizeFutures"
import BuySellAtFutures from "../fncomponents/BuySellAtFutures"
import SetAccountLeverageFutures from "../fncomponents/SetAccountLeverageFutures";
import NewFuturesStep1Buttons from "../fncomponents/NewFuturesStep1Buttons";
import FuturesConditions from "../fncomponents/FuturesConditions";
import {
    computeAdditionalIsoMarg,
    computeLiquidationPrice,
    getNoOfRunningTradesOnContract,
    getRates
} from "../../redux/actions/futures";


const DEBOUNCE_DELAY_IN_MS = 500
const LOGGING = true
const logPrefix = "NewFutures"
const log = {debug: LOGGING ? console.log: () => {}, error: LOGGING ? console.error: () => {}}

const TEST = false
const TEST_FIXED_PRICE = 55334
const TEST_CURRENT_PRICE = 60000
const TEST_AVAILABLE_MARGIN = 15
const TEST_STOP_LOSS_OPTION = c.STOP_LOSS_OPTION_PERCENTAGE
const TEST_STOP_LOSS_TARGET = 120

class NewFutures extends Component {

    constructor(props) {
        super(props)
        this.state = {
            quote: '',
            base: 'BTC',
            posSize: 0, //position size
            posSizeTick: 8, //position size tick (the amount of decimals are accepted regarding the quantity by the current currency)
            posSizeSlider: 25,
            exchange: c.EXCHANGE_BINANCE, //exchange_binance or exchange_coinex
            futuresType: c.FUTURES_TYPE_LONG, //LONG or SHORT futures
            actionTypeOption: '', //fixedPrice, market, justAboveBid
            fixedPrice: 0,
            currentPrice: 0,
            takeProfitOption: '', //TAKE_PROFIT_OPTION_MANUAL, auto: TAKE_PROFIT_OPTION_AT_A_SPECIFIC_PERCENTAGE/TAKE_PROFIT_OPTION_AT_A_SPECIFIC_PRICE
            takeProfitPriceOrPercent: 0,
            stopLossOption: '',//auto: price/percent, trailing, conditional
            stopLossPriceOrPercent: 0,
            showLoading: true,
            notionalValue: 0,
            leverage: undefined,
            leverageSlider: 20,
            openLoss: 0,
            isAdjustLeverageOpen: false,
            additionalMargin: 0,
            totalCost: 0
        }

        this.confirmNewLeverage = this.confirmNewLeverage.bind(this)
        this.cancelAdjustLeverage = this.cancelAdjustLeverage.bind(this)
        this.adjustLeverage = this.adjustLeverage.bind(this)
        this.computeCalculated = this.computeCalculated.bind(this)
        this.debounceComputeCalculated = debounce(this.computeCalculated, DEBOUNCE_DELAY_IN_MS)
        this.decreaseLeverage = this.decreaseLeverage.bind(this)
        this.increaseLeverage = this.increaseLeverage.bind(this)
        this.handleChangeLeverage = this.handleChangeLeverage.bind(this)
        this.handleChangeLeverageSlider = this.handleChangeLeverageSlider.bind(this)
        this.handleChangeFuturesType = this.handleChangeFuturesType.bind(this)
        this.afterChangeFuturesType = this.afterChangeFuturesType.bind(this)
        this.debounceChangeFuturesType = debounce(this.afterChangeFuturesType, DEBOUNCE_DELAY_IN_MS)
        this.handleChangeQuote = this.handleChangeQuote.bind(this)
        this.validatePairs = this.validatePairs.bind(this)
        this.debounceValidatePairs = debounce(this.validatePairs, 1.7 * DEBOUNCE_DELAY_IN_MS)
        this.handleChangeBase = this.handleChangeBase.bind(this)
        this.handleChangePosSize = this.handleChangePosSize.bind(this)
        this.handleChangePosSizeSlider = this.handleChangePosSizeSlider.bind(this)
        this.handleChangePosSizeSliderAtMount = this.handleChangePosSizeSliderAtMount.bind(this)
        this.handleChangeFixedPrice = this.handleChangeFixedPrice.bind(this)
        this.handleChangeActionTypeOption = this.handleChangeActionTypeOption.bind(this)
        this.getCurrentPrices = this.getCurrentPrices.bind(this)
        this.handleChangeTakeProfitOption = this.handleChangeTakeProfitOption.bind(this)
        this.handleChangeTakeProfitPriceOrPercent = this.handleChangeTakeProfitPriceOrPercent.bind(this)
        this.fixTakeProfitPriceOrPercent = this.fixTakeProfitPriceOrPercent.bind(this)
        this.debounceFixTakeProfitPriceOrPercent = debounce(this.fixTakeProfitPriceOrPercent, DEBOUNCE_DELAY_IN_MS)
        this.handleChangeStopLossOption = this.handleChangeStopLossOption.bind(this)
        this.handleChangeStopLossPriceOrPercent = this.handleChangeStopLossPriceOrPercent.bind(this)
        this.fixStopLossPriceOrPercent = this.fixStopLossPriceOrPercent.bind(this)
        this.debounceFixStopLossPriceOrPercent = debounce(this.fixStopLossPriceOrPercent, DEBOUNCE_DELAY_IN_MS)
        this.previewNewFutures = this.previewNewFutures.bind(this)
        this.checkUserBalance = this.checkUserBalance.bind(this)
        this.fixPosSize = this.fixPosSize.bind(this)
        this.debounceFixPosSize = debounce(this.fixPosSize, DEBOUNCE_DELAY_IN_MS)
        this.fixFixedPrice = this.fixFixedPrice.bind(this)
        this.debounceFixFixedPrice = debounce(this.fixFixedPrice, DEBOUNCE_DELAY_IN_MS)
        this.actualizeTakeProfitAndStopLoss = this.actualizeTakeProfitAndStopLoss.bind(this)
        this.switchWithOrWithoutStopLossTarget = this.switchWithOrWithoutStopLossTarget.bind(this)

        this.handleChangeAdditionalMargin = this.handleChangeAdditionalMargin.bind(this)
        this.fixAdditionalMargin = this.fixAdditionalMargin.bind(this)
        this.debounceFixAdditionalMargin = debounce(this.fixAdditionalMargin, DEBOUNCE_DELAY_IN_MS)

        this.updateAdditionalMarginByNewPercent = this.updateAdditionalMarginByNewPercent.bind(this)
        this.computeLiqPriceAndAddIsoMargAgainstStopLoss = this.computeLiqPriceAndAddIsoMargAgainstStopLoss.bind(this)
        // this.debounceComputeCalculatedAfterCheckingLiqPriceAgainstStopLoss = debounce(this.computeCalculatedAfterCheckingLiqPriceAgainstStopLoss, DEBOUNCE_DELAY_IN_MS)

        this.lastOrderBookCurrentPrice = undefined
        this.lastFixedPrice = undefined

        this.getFieldOrTestValue = this.getFieldOrTestValue.bind(this)

    }

    getFieldOrTestValue(value, testValue) {
        if (TEST) {
            return testValue
        }

        return value
    }

    handleChangeAdditionalMargin(e) {
        this.setState({
            additionalMargin: e.target.value
        })
        this.debounceFixAdditionalMargin()
    }

    fixAdditionalMargin() {
        let q = parseFloat(this.state.additionalMargin)
        if (isNaN(q))
            q = 0
        this.setState({
            additionalMargin: brushValue(q, this.state.tickSize)
        })
        this.debounceComputeCalculated()
        if (this.state.stopLossOption !== c.STOP_LOSS_OPTION_MANUAL) {
            this.debounceFixStopLossPriceOrPercent()
        }
    }

    confirmNewLeverage() {
        this.props.updateUserLeverage({symbol: `${this.state.quote}${this.state.base}`, leverage: this.state.leverage}, data => {
            this.setState({
                leverage: data.leverage,
                leverageMaxNotionalValue: data.maxNotionalValue,
                isAdjustLeverageOpen: false
            })
            this.debounceComputeCalculated()
        })
    }

    cancelAdjustLeverage() {
        this.setState({
            isAdjustLeverageOpen: false,
            leverage: this.state.initialLeverage
        })
    }

    adjustLeverage() {
        this.props.getNoOfRunningTradesOnContract({symbol: `${this.state.quote}${this.state.base}`}, no => {
            this.setState({
                isAdjustLeverageOpen: true,
                initialLeverage: this.state.leverage,
                noOfRunningTrades: no
            })
        })
    }

    computeCalculated() {
        let _logPrefix = `${logPrefix}::computeCalculated`
        this.state.computeCalculatedLock = true
        let amMax = parseFloat(brushValue(this.props.userBaseBalance, this.state.tickSize)) //Available Margin
        let l = parseFloat(this.state.leverage) //Leverage
        let markPrice = this.getFieldOrTestValue(parseFloat(this.props.orderBookCurrentPrice), TEST_CURRENT_PRICE) //Mark Price

        let orderPrice = 0
        if (this.state.actionTypeOption === c.FUTURES_ACTION_TYPE_OPTION_MARKET) {
            orderPrice = this.getFieldOrTestValue(parseFloat(this.lastOrderBookCurrentPrice), TEST_CURRENT_PRICE)
        } else {
            orderPrice = parseFloat(this.state.fixedPrice)
        }
        let direction = this.state.futuresType === c.FUTURES_TYPE_LONG ? 1: -1
        let q = Math.abs(Math.min(0, direction * (markPrice - orderPrice))) //Abs(min[0, direction * (mark price - order price)])

        let am = 0
        let size = 0
        let notionalValue = 0
        let initialMargin = 0
        let additionalMargin = 0
        let openLoss = 0
        let cost = 0
        let rCost = 0
        let max = 0
        let fP = 0

        additionalMargin = parseFloat(this.state.additionalMargin)
        if (isNaN(additionalMargin))
            additionalMargin = 0

        if (this.state.actionTypeOption === c.FUTURES_ACTION_TYPE_OPTION_MARKET) {
            fP = this.props.user ? parseFloat(this.props.user.fpmf): 0
        } else {
            fP = this.props.user ? parseFloat(this.props.user.fplf): 0
        }
        let fees = 0


        let indexP = 0
        try {
            indexP = this.state.posSize.indexOf("%")
        } catch (e) {}
        // console.log("-------------------------------")
        if (indexP > 0) {
            //First variant takes into account that the user specifies the available margin by filling in the percentage from
            //the available margin that he wants to put into this order
            am = this.getFieldOrTestValue(parseFloat(this.state.posSize.substring(0, this.state.posSize.length)) / 100 * amMax, TEST_AVAILABLE_MARGIN)
            size = parseFloat(brushValue2(l * am / (orderPrice + l * q), this.state.precision))
            notionalValue = size * orderPrice
            fees = notionalValue * fP
            initialMargin = notionalValue / l
            openLoss = size * q
            cost = initialMargin + openLoss + fees
            rCost = initialMargin + openLoss
            max = l * amMax / (orderPrice + l * q)
            // s = {
            //     l,  orderPrice, q, am, precision: this.state.precision, size, notionalValue, fees, initialMargin, openLoss, cost, rCost, max
            // }
            // console.log("case I", JSON.stringify(s))
        } else {
            //Second variant takes into account that the user specified directly the size
            size = parseFloat(this.state.posSize)
            notionalValue = size * orderPrice
            fees = notionalValue * fP
            initialMargin = size * orderPrice / l
            openLoss = size * q
            cost = initialMargin + openLoss + fees
            rCost = initialMargin + openLoss
            max = size
            // s = {
            //     size, notionalValue, fees, initialMargin, openLoss, cost, rCost, max
            // }
            // console.log("case I", JSON.stringify(s))
        }

        // let maintenanceMargin, mMarginRate}  = this.getExtraData(notionalValue)

        this.props.getRates({symbol: `${this.state.quote}${this.state.base}`, leverage: this.state.leverage, notionalValue}, result => {
            let {maxLeverage, maxLeverageNotionalValue, initialMarginRate, maintenanceMarginRate, maintenanceAmount, maxNotionalValue, overMaxNV} = result
            let maintenanceMargin = maintenanceAmount
            let mMarginRate = maintenanceMarginRate
            this.props.computeLiquidationPrice({symbol: `${this.state.quote}${this.state.base}`, type: c.FUTURES_TYPES[this.state.futuresType],
                leverage: this.state.leverage, wb: initialMargin + additionalMargin, entryPrice: orderPrice, quantity: size}, liquidationPrice => {
                let percentageLiquidationToEntry = ((liquidationPrice - orderPrice) / orderPrice * 100 * l).toFixed(2)

                let userBalance = parseFloat(this.state.userBaseBalance)

                if (cost >= userBalance) {
                    size = userBalance / (orderPrice/l + q + orderPrice * fP)
                    notionalValue = size * orderPrice
                    initialMargin = notionalValue /l
                    fees = notionalValue * fP
                    openLoss = size * q
                    cost = initialMargin + openLoss + fees
                    rCost = initialMargin + openLoss
                    max = size
                    // s = {
                    //     size, notionalValue, fees, initialMargin, openLoss, cost, rCost, max
                    // }
                    // console.log("case III", JSON.stringify(s))
                }

                this.setState({
                    computeCalculatedLock: false,
                    maxLeverage,
                    //Here the maxLeverageNotionalValue is by leverage and notional value
                    maxLeverageNotionalValue: maxNotionalValue,
                    maxNotionalValue,
                    initialMarginRate,
                    notionalValue: brushValue(notionalValue, this.state.tickSize),
                    initialMargin: brushValue(initialMargin, this.state.tickSize),
                    openLoss: brushValue(openLoss, this.state.tickSize),
                    size: brushValue(size, this.state.precision),
                    cost: brushValue(cost, this.state.tickSize),
                    totalCost: brushValue(cost + this.state.additionalMargin, this.state.tickSize),
                    rCost: brushValue(rCost, this.state.tickSize),
                    max: brushValue(max, this.state.precision),
                    usedPrice: orderPrice,
                    liquidationPrice,
                    maintenanceMargin,
                    mMarginRate,
                    percentageLiquidationToEntry
                })
            })
        })
    }

    decreaseLeverage() {
        let newL = this.state.leverage - 1
        let newLs = this.state.leverageSlider - 1
        newL = newL < 1 ? 1: newL
        newLs = newLs < 1 ? 1: newLs
        this.setState({
            leverage: newL,
            leverageSlider: newLs
        })
    }

    increaseLeverage() {
        let newL = this.state.leverage + 1
        let newLs = this.state.leverageSlider + 1
        newL = newL > this.state.maxLeverage ? this.state.maxLeverage: newL
        newLs = newLs > this.state.maxLeverage ? this.state.maxLeverage: newLs
        this.setState({
            leverage: newL,
            leverageSlider: newLs
        })
    }

    handleChangeLeverageSlider(event, value) {
        this.setState({
            leverageSlider: value,
            leverage: value
        })
        this.debounceComputeCalculated()
    }

    handleChangeLeverage(e) {
        if (e.target.value.length === 0) {
            this.setState({
                leverage: e.target.value,
                leverageSlider: e.target.value
            })

        } else {
            this.setState({
                leverage: parseInt(e.target.value),
                leverageSlider: parseInt(e.target.value)
            })
        }
    }

    handleChangeFuturesType() {
        if (this.state.futuresType === c.FUTURES_TYPE_LONG) {
            this.setState({
                futuresType: c.FUTURES_TYPE_SHORT,
            })
        } else if (this.state.futuresType === c.FUTURES_TYPE_SHORT) {
            this.setState({
                futuresType: c.FUTURES_TYPE_LONG,
            })

        }

        this.debounceComputeCalculated()
    }

    afterChangeFuturesType() {
        this.debounceComputeCalculated()
        this.handleChangeTakeProfitOption(c.TAKE_PROFIT_OPTION_PERCENTAGE, true)
        this.handleChangeTakeProfitPriceOrPercent({target: {value: 100}})
        this.handleChangeStopLossOption(c.STOP_LOSS_OPTION_PERCENTAGE)
        this.handleChangeStopLossPriceOrPercent({target: {value: 50}})
    }

    updateAdditionalMarginByNewPercent(percent) {
        let leverage = parseInt(this.state.leverage)
        let entryPrice = parseFloat(this.state.usedPrice)

        let sign = this.state.futuresType === c.FUTURES_TYPE_LONG ? -1: 1
        let newLiquidationPrice = entryPrice * (1 + sign * percent / 100/ leverage)
        this.props.computeAdditionalIsoMarg({
            symbol: `${this.state.quote}${this.state.base}`,
            type: c.FUTURES_TYPES[this.state.futuresType],
            leverage: this.state.leverage,
            wb: this.state.initialMargin,
            entryPrice: this.state.usedPrice,
            quantity: this.state.size,
            liquidationPrice: newLiquidationPrice}, additionalIsoMargin => {
                this.state.additionalMargin = parseFloat(additionalIsoMargin)
                // this.setState({
                //     additionalMargin: additionalIsoMargin,
                //     totalCost: brushValue(this.state.cost + additionalIsoMargin, this.state.tickSize)
                // })
                this.computeCalculated()
        })
    }

    switchWithOrWithoutStopLossTarget() {
        let percent = 0
        if (this.state.stopLossOption === c.STOP_LOSS_OPTION_MANUAL) {
            percent = 50
            this.setState({
                stopLossOption: c.STOP_LOSS_OPTION_PERCENTAGE,
                stopLossPriceOrPercent: percent,
            })
        } else {
            //If user opts for manual stop loss we make sure that his trade will stay active until a
            //liquidation price equivalent to a -100% stop loss
            percent = 100
            this.setState({
                stopLossOption: c.STOP_LOSS_OPTION_MANUAL
            })
        }
        this.updateAdditionalMarginByNewPercent(percent)
    }

    actualizeTakeProfitAndStopLoss(currentLastBidPrice) {
        let signForTP = 1
        let signForSL = -1
        if (this.state.futuresType === c.FUTURES_TYPE_SHORT) {
            signForTP = -1
            signForSL = 1
        }

        if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE) {
            this.state.takeProfitPriceOrPercent = getValueWithPrecision(currentLastBidPrice * (1 + signForTP * 0.1), this.state.tickSize)
        }
        // else if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
        //     this.state.takeProfitPriceOrPercent = 100
        // }

        if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PRICE) {
            this.state.stopLossPriceOrPercent = getValueWithPrecision(currentLastBidPrice * (1 + signForSL * 0.05), this.state.tickSize)
        }

        // else if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE ||
        //     this.state.stopLossOption === c.STOP_LOSS_OPTION_TRAILING) {
        //     this.state.stopLossPriceOrPercent = 50
        // }
    }

    handleChangeQuote(e) {
        let newQuote = e.target.value.toUpperCase()
        this.setState({
            quote: newQuote
        })
        this.debounceValidatePairs()
    }

    validatePairs() {
        this.props.clearNotEnoughBalance()
        this.props.getSymbolPrecisionAndTickSize({quote: this.state.quote, base: this.state.base}, result => {
            this.props.getNoOfRunningTradesOnContract({symbol: `${this.state.quote}${this.state.base}`}, no => {
                this.setState({
                    precision: result.precision,
                    tickSize: result.tickSize,
                    isAdjustLeverageOpen: false,
                    noOfRunningTrades: no
                })
            })
        })

        if(isValidPair(this.state.quote, this.state.base, this.props.quoteCurrenciesNom)) {
            closeWSF()
            this.props.user && initWSF(this.props.user.userId, `${this.state.quote}${this.state.base}`)
            this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, resultCP => {
                let currentLastBidPrice = getValueWithPrecision(resultCP.current_price, this.state.tickSize)
                this.actualizeTakeProfitAndStopLoss(currentLastBidPrice)
                this.props.getRates({symbol: `${this.state.quote}${this.state.base}`}, resultRates => {
                    let {maxLeverage, maxLeverageNotionalValue} = resultRates
                    this.props.getUserLeverage({symbol: `${this.state.quote}${this.state.base}`}, data => {
                        if (data.leverage > maxLeverage) {
                            data.leverage = maxLeverage
                        }
                        this.setState({
                            maxLeverage,
                            maxLeverageNotionalValue,
                            leverage: data.leverage,
                            leverageSlider: data.leverage,
                            fixedPrice: resultCP.current_price ? currentLastBidPrice : 0,
                            currentPrice: resultCP.current_price,
                        })
                        this.handleChangePosSizeSlider({}, this.state.posSizeSlider)
                    })
                })
            })
        } else {
            this.setState({
                leverage: undefined,
                leverageSlider: 0,
                fixedPrice: 0,
                currentPrice: 0,
            })
        }
    }

    handleChangeBase(e) {
        let newBase = e.target.value
        this.state.base = newBase

        this.props.getCurrentPriceOnPair({quote: this.state.quote, base:  newBase}, result => {
            this.state.tickSize = parseInt(result.tick_size)
            let currentLastBidPrice = getValueWithPrecision(result.current_price, this.state.tickSize)
            this.actualizeTakeProfitAndStopLoss(currentLastBidPrice)
            this.setState({
                fixedPrice: result.current_price ? currentLastBidPrice : 0,
                currentPrice: result.current_price
            })
        })


        this.props.getUserBaseBalance({currency: newBase})
        this.debounceValidatePairs()
    }

    handleChangePosSizeSlider(event, value) {
        this.setState({
            posSizeSlider: value,
            posSize: `${value}%`
        })
        this.debounceComputeCalculated()
    }

    handleChangePosSizeSliderAtMount(event, value) {
        this.setState({
            posSizeSlider: value,
            posSize: `${value}%`
        })
        this.computeCalculated()
        this.computeLiqPriceAndAddIsoMargAgainstStopLoss()
    }

    handleChangePosSize(e) {
        this.setState({
            posSize: e.target.value,
            posSizeSlider: 0,
        })
        this.debounceFixPosSize()
    }

    fixPosSize() {
        if (this.state.posSize.length === 0)
            return
        let precision = 8

        precision = c.BASE_PRECISIONS[this.state.base]
        this.setState({
            posSize: getValueWithPrecision(this.state.posSize, precision)
        })
        this.debounceComputeCalculated()
    }

    handleChangeFixedPrice(e) {
        if (this.state.tradeType === c.TRADE_TYPE_OLD && this.props.userAvgBuyPriceForQuote)
            return
        this.setState({
            fixedPrice: e.target.value
        })
        this.debounceFixFixedPrice()
    }

    fixFixedPrice() {
        if (this.state.fixedPrice.length === 0)
            return
        let fixedPriceError = undefined
        if (this.state.actionTypeOption === c.ACTION_TYPE_OPTION_FIXED_PRICE) {
            let fixedPriceF = parseFloat(this.state.fixedPrice)
            let currentPriceF = parseFloat(this.state.currentPrice)
            let delta = (fixedPriceF - currentPriceF) / currentPriceF
            if (this.state.futuresType === c.FUTURES_TYPE_SHORT)
                delta *= -1
            if (delta >= 0.1) {
                if (this.state.futuresType === c.FUTURES_TYPE_LONG) {
                    fixedPriceError = `buy price is current price +${(delta * 100).toFixed(2)}%`
                } else if (this.state.futuresType === c.FUTURES_TYPE_SHORT) {
                    fixedPriceError = `sell price is current price -${(delta * 100).toFixed(2)}%`
                }
            }
        }

        this.setState({
            fixedPrice: getValueWithPrecision(this.state.fixedPrice, this.state.tickSize),
            fixedPriceError
        })

        this.debounceComputeCalculated()
    }

    handleChangeActionTypeOption(actTypeOption) {
        if (this.state.quote && this.state.quote.length < 2)
            return

        this.setState({
            actionTypeOption: actTypeOption,
            actionTypeOptionError: undefined
        })

        if (this.state.quote.length > 1 && actTypeOption !== c.FUTURES_ACTION_TYPE_OPTION_MARKET) {
            this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, result => {
                let currentPrice = result.current_price
                let price = 0
                if (this.state.futuresType === c.FUTURES_TYPE_LONG)
                    price = currentPrice ? parseFloat(currentPrice) * (actTypeOption === c.FUTURES_ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE ? c.LAST_BID_PRICE_MULTIPLIER : 1): 0
                else if (this.state.futuresType === c.FUTURES_TYPE_SHORT)
                    price = currentPrice ? parseFloat(currentPrice) * (actTypeOption === c.FUTURES_ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE ? c.FIRST_ASK_PRICE_MULTIPLIER : 1): 0

                this.state.fixedPrice = this.getFieldOrTestValue(scientificToDecimal(getValueWithPrecision(price, this.state.tickSize)), TEST_FIXED_PRICE)
                this.currentPrice = result.current_price
                this.debounceComputeCalculated()
            })
        }
    }

    getCurrentPrices() {
        this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base})
    }

    handleChangeTakeProfitOption(option, force = false) {
        changeFuturesTakeProfitOption(option, this, this.getFieldOrTestValue(this.props.orderBookCurrentPrice, TEST_CURRENT_PRICE), force)
    }

    handleChangeTakeProfitPriceOrPercent(e) {
        this.setState({
            takeProfitPriceOrPercent: this.getFieldOrTestValue(e.target.value, TEST_STOP_LOSS_TARGET)
        })

        this.debounceFixTakeProfitPriceOrPercent()
    }

    fixTakeProfitPriceOrPercent() {
        if (this.state.takeProfitPriceOrPercent.length  === 0)
            return

        if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE)
            this.setState({
                takeProfitPriceOrPercent: getValueWithPrecision(this.state.takeProfitPriceOrPercent, 2)
            })
        else {
            if (this.state.takeProfitPriceOrPercent.length > 0)
                this.setState({
                    takeProfitPriceOrPercent: getValueWithPrecision(this.state.takeProfitPriceOrPercent, this.state.tickSize)
                })
        }
    }

    handleChangeStopLossOption(option) {
        changeFuturesStopLossOption(this.getFieldOrTestValue(option, TEST_STOP_LOSS_OPTION), this,
            this.getFieldOrTestValue(this.props.orderBookCurrentPrice, TEST_CURRENT_PRICE))
    }

    handleChangeStopLossPriceOrPercent(e) {
        this.setState({
            stopLossPriceOrPercent: e.target.value,
        })
        this.debounceFixStopLossPriceOrPercent()
    }

    computeLiqPriceAndAddIsoMargAgainstStopLoss() {
        let percent = 0

        let currentPrice = this.getFieldOrTestValue(this.props.orderBookCurrentPrice, TEST_CURRENT_PRICE)

        if (this.state.stopLossOption === c.STOP_LOSS_OPTION_MANUAL) {
            return this.updateAdditionalMarginByNewPercent(100)
        } else if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE || this.state.stopLossOption === c.STOP_LOSS_OPTION_TRAILING) {
            percent = this.getFieldOrTestValue(this.state.stopLossPriceOrPercent, TEST_STOP_LOSS_TARGET)
        } else if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PRICE) {
            percent = Math.abs((this.state.stopLossPriceOrPercent - currentPrice) / currentPrice * 100 * this.state.leverage)
        }

        this.updateAdditionalMarginByNewPercent(percent)
    }

    //1983
    fixStopLossPriceOrPercent() {
        if (this.state.stopLossPriceOrPercent.length === 0)
            return

        this.computeLiqPriceAndAddIsoMargAgainstStopLoss()

        if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PRICE || this.state.stopLossOption === c.STOP_LOSS_OPTION_CONDITIONAL)
            this.setState({
                stopLossPriceOrPercent: getValueWithPrecision(this.state.stopLossPriceOrPercent, this.state.tickSize),
            })
        else this.setState({
            stopLossPriceOrPercent: getValueWithPrecision(this.state.stopLossPriceOrPercent, 2),
        })
    }

    setLastUsedParamsToState(input) {
        this.state.futuresType = input.futuresType.toUpperCase()
        this.state.quote = input.quote ? input.quote: ""
        this.state.base = input.base ? input.base : ""
        this.state.posSize = input.posSize ? input.posSize : 0
        this.state.posSizeTick = input.posSizeTick ? input.posSizeTick: 8
        this.state.actionTypeOption = input.actionTypeOption //fixed/market/just above bid/just below ask
        this.state.takeProfitOption = input.takeProfitOption //manual/percentage/fixed price
        this.state.takeProfitPriceOrPercent = this.getFieldOrTestValue(input.takeProfitPriceOrPercent, TEST_STOP_LOSS_TARGET) //value for percentage or fixed
        this.state.stopLossOption = this.getFieldOrTestValue(input.stopLossOption, TEST_STOP_LOSS_OPTION) //trailing/percentage/fixed price
        this.state.candlesType = input.candlesType ? parseInt(input.candlesType) : this.state.candlesType
        switch (input.stopLoss) {
            case c.STOP_LOSS_OPTION_PERCENTAGE: this.state.stopLossTab = 0; break;
            case c.STOP_LOSS_OPTION_PRICE: this.state.stopLossTab = 0; break;
            case c.STOP_LOSS_OPTION_TRAILING: this.state.stopLossTab = 0; break;
            case c.STOP_LOSS_OPTION_CONDITIONAL: this.state.stopLossTab = 1; break;
            default: this.state.stopLossTab = 0; break;
        }
        this.state.stopLossPriceOrPercent = input.stopLossPriceOrPercent //value for each case
        this.state.fixedPrice = this.getFieldOrTestValue(scientificToDecimal(input.currentLastBidPrice), TEST_FIXED_PRICE)
        this.state.precision = parseInt(input.precision)
        this.state.tickSize = parseInt(input.tickSize)

        this.actualizeTakeProfitAndStopLoss(input.currentLastBidPrice)
        this.setState({
            showLoading: false
        })
    }

    checkUserBalance() {
        this.props.checkUserBalance({currency: this.getPosSizeCurrency(), value: this.state.posSize})
    }

    previewNewFutures() {
        if (this.state.computeCalculatedLock)
            return
        let cost = parseFloat(this.state.cost) + parseFloat(this.state.additionalMargin)
        let posSize = this.state.max

        let abort = false

        if (posSize <= 0)  {
            this.setState({
                otherPosSizeErrors: "Please correct value"
            })
            abort = true
        }

        if (cost > parseFloat(this.state.userBaseBalance)) {
            this.setState({
                otherPosSizeErrors: `Insufficient balance`
            })
            abort = true
        }

        if (cost <= 0) {
            this.setState({
                otherPosSizeErrors: "Cost cannot be zero"
            })
            abort = true
        }

        if (this.state.notionalValue > this.state.maxNotionalValue) {
            this.setState({
                otherPosSizeErrors: `Notional value above max. of ${addCommasToLeftSideOfTheNumber(this.state.maxNotionalValue)} ${this.state.base}.`
            })
            abort = true
        }

        if (this.state.actionTypeOption !== c.FUTURES_ACTION_TYPE_OPTION_FIXED_PRICE &&
            this.state.actionTypeOption !== c.FUTURES_ACTION_TYPE_OPTION_MARKET &&
            this.state.actionTypeOption !== c.FUTURES_ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE &&
            this.state.actionTypeOption !== c.FUTURES_ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE) {
            this.setState({
                actionTypeOptionError: "Please chose an option"
            })
            abort = true
        }

        if (abort)
            return

        this.props.clearErrors()



        this.props.checkUserBalance({currency: this.state.base, value: cost}, result => {
            if (result.status === c.USER_HAS_BALANCE) {
                this.props.previewNewFutures({
                    quote: this.state.quote,
                    base: this.state.base,
                    posSize: this.state.size,
                    cost: this.state.cost,
                    rCost: this.state.rCost,
                    notionalValue: this.state.notionalValue,
                    leverage: this.state.leverage,
                    openLoss: this.state.openLoss,
                    precision: this.state.precision,
                    tickSize: this.state.tickSize,
                    initialMargin: this.state.initialMargin,
                    additionalIsolatedMargin: this.state.additionalMargin,
                    liquidationPrice: this.state.liquidationPrice,
                    max: this.state.max,
                    maintenanceMargin: this.state.maintenanceMargin,
                    mMarginRate: this.state.mMarginRate,
                    exchange: this.state.exchange,
                    futuresType: this.state.futuresType,
                    actionTypeOption: this.state.actionTypeOption,
                    fixedPrice: this.state.fixedPrice,
                    currentPrice: this.state.usedPrice,
                    takeProfitOption: this.state.takeProfitOption,
                    takeProfitPriceOrPercent: this.state.takeProfitPriceOrPercent,
                    stopLossOption: this.state.stopLossOption,
                    stopLossPriceOrPercent: this.state.stopLossPriceOrPercent,
                }, ()=>{
                    this.props.history.push("/previewfutures")
                })
            } else {
                this.setState({
                    otherPosSizeErrors: "Insufficient margin"
                })
            }
        })

    }

    componentDidMount() {
        let _logPrefix = `${logPrefix}::componentDidMount`

        let {user} = this.props

        if (!this.props.baseCurrenciesNom) {
            this.props.getCurrenciesMap()
        }

        let _quote = ""
        let _base = ""

        this.props.getLastUsedParamsOnNewFutures({}, data => {

            if (!data.hasFuturesActivated || (data.hasFuturesActivated && parseInt(data.hasFuturesActivated) !== c.USER_HAS_FUTURES_ACTIVATED)) {
                this.props.history.push("/futures")
            }

            this.setLastUsedParamsToState(data)
            _quote = data.quote
            _base = data.base
            closeWSF()
            user && initWSF(this.props.user.userId, `${_quote}${_base}`, true)

            this.props.getCurrentPriceOnPair({quote: _quote, base: _base}, getCurrentPriceOnPairResult => {
                let currentPrice = getCurrentPriceOnPairResult.current_price
                // log.debug("%s currentPrice %s", _logPrefix, currentPrice)
                let price = 0
                if (this.state.futuresType === c.FUTURES_TYPE_LONG)
                    price = currentPrice ? parseFloat(currentPrice) * (data.actionTypeOption === c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE ? c.LAST_BID_PRICE_MULTIPLIER : 1): 0
                else if (this.state.futuresType === c.FUTURES_TYPE_SHORT)
                    price = currentPrice ? parseFloat(currentPrice) * (data.actionTypeOption === c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE ? c.FIRST_ASK_PRICE_MULTIPLIER : 1): 0

                this.props.getUserBaseBalance({currency: _base}, getUserBaseBalanceResult => {
                    this.state.userBaseBalance = getUserBaseBalanceResult.value
                    this.props.getSymbolPrecisionAndTickSize({quote: _quote, base: _base}, (getSymbolPrecisionAndTickSizeResult => {
                        this.state.precision = getSymbolPrecisionAndTickSizeResult.precision
                        this.state.tickSize = getSymbolPrecisionAndTickSizeResult.tickSize
                        this.props.getUserLeverage({symbol: `${this.state.quote}${this.state.base}`}, getUserLeverageResult => {
                            let notionalValue = parseFloat(this.state.posSize) * price
                            this.props.getRates({symbol: `${this.state.quote}${this.state.base}`, leverage: getUserLeverageResult.leverage, notionalValue}, result => {
                                let {maxLeverage, maxLeverageNotionalValue, initialMarginRate, maintenanceMarginRate, maintenanceAmount, maxNotionalValue, overMaxNV} = result
                                this.state.maxLeverage = maxLeverage
                                this.state.maxLeverageNotionalValue = maxLeverageNotionalValue
                                this.state.maxNotionalValue = maxNotionalValue

                                    if (getUserLeverageResult.leverage > maxLeverage) {
                                        getUserLeverageResult.leverage = maxLeverage
                                        getUserLeverageResult.maxNotionalValue = maxLeverageNotionalValue
                                    }
                                    this.state.fixedPrice = this.getFieldOrTestValue(scientificToDecimal(getValueWithPrecision(price, this.state.tickSize)), TEST_FIXED_PRICE)
                                    this.state.leverage = getUserLeverageResult.leverage
                                    this.state.leverageSlider = getUserLeverageResult.leverage
                                    this.state.leverageMaxNotionalValue = getUserLeverageResult.maxNotionalValue
                                    this.state.currentPrice = getUserLeverageResult.current_price
                                    this.handleChangePosSizeSliderAtMount({}, this.state.posSizeSlider)
                                    this.debounceFixStopLossPriceOrPercent()
                                })
                            })
                    }))
                })
            })
        })

        this.props.clearErrors()
        this.props.clearNotEnoughBalance()

        this.props.getActiveSuspendedActivities(data => {
            this.state.ASAS = data
        })
    }

    componentWillUnmount() {
        closeWSF()
    }


    render() {

        let {user} = this.props

        if (!this.props._hasFuturesActivated ||
            (this.props._hasFuturesActivated && this.props._hasFuturesActivated !== c.USER_HAS_FUTURES_ACTIVATED)) {
            this.props.history.push("/futures")
        }

        let shouldUpdate = false
        if (this.lastOrderBookCurrentPrice !== this.props.orderBookCurrentPrice) {
            this.lastOrderBookCurrentPrice = this.props.orderBookCurrentPrice
            if (this.state.actionTypeOption === c.FUTURES_ACTION_TYPE_OPTION_MARKET) {
                shouldUpdate = true
            }
        }

        if (this.lastFixedPrice !== this.state.fixedPrice) {
            this.lastFixedPrice = this.state.fixedPrice
            shouldUpdate = true
        }

        if (shouldUpdate) this.computeCalculated()

        if (this.state.showLoading)
            return <div>
                <Helmet>
                    <title>{getTitle()}</title>
                </Helmet>
                <div className="loading">
                    <CircularIndeterminate/>
                </div>
                <div className={"loadingText"}>Getting current price...</div>
            </div>
        else return (
            <div className={"TradeContent"} style={{display: "flex"}}>
                <Helmet>
                    <title>{getTitle(`${this.state.quote}/${this.state.base}`, this.props.orderBookCurrentPrice)}</title>
                </Helmet>
                <div key={"theForm"}>
                    {(this.state.ASAS && this.state.ASAS.showWarningMessage === "true") &&
                    <div class={"platformInformationContainer"}>
                        <div><Info/></div>
                        <div style={{marginLeft: "0.6rem"}}>
                            {this.state.ASAS.warningMessage}
                        </div>
                    </div>}
                    {this.state.ASAS && this.state.ASAS.suspendActivities === "true" && this.state.ASAS.suspendedActivitiesMap[c.SUSPENDED_ACTIVITIES_NEW_TRADE_ID] &&
                    <div className={"maintenanceWarning"}>
                        <div><Warning/></div>
                        <div style={{marginLeft: "0.6rem"}}>
                            Placing new trades is temporary disabled.
                        </div>
                    </div>}
                    <div className={"wizardTitle"}>
                        <span className={"wizardTitleIcon"}><Polymer/></span> Launch Futures on <b>Binance</b>
                    </div>
                    <div className={"vertSpacer1_5"}/>
                    <div className={"horizSeparator"}/>
                    <div className={"vertSpacer1"}/>
                    <SetAccountLeverageFutures
                        leverage={this.state.leverage} handleChangeLeverage={this.handleChangeLeverage}
                        maxLeverage={this.state.maxLeverage}
                        maxNotionalValue={this.state.maxLeverageNotionalValue}
                        initialMarginRate={this.state.initialMarginRate}
                        leverageSlider={this.state.leverageSlider} handleChangeLeverageSlider={this.handleChangeLeverageSlider}
                        decreaseLeverage={this.decreaseLeverage}
                        increaseLeverage={this.increaseLeverage}
                        quote={this.state.quote}
                        base={this.state.base}
                        isEditable={this.state.isAdjustLeverageOpen}
                        adjustLeverage={this.adjustLeverage}
                        noOfRunningTrades={this.state.noOfRunningTrades}
                        cancelAdjustLeverage={this.cancelAdjustLeverage}
                        confirmNewLeverage={this.confirmNewLeverage}
                    />
                    <SelectFuturesTypeRadios futuresType={this.state.futuresType}
                                             handleChangeFuturesType={this.handleChangeFuturesType}/>
                    <div className={"vertSpacer1_5"}/>
                    <div className={"horizSeparator"}/>
                    <div className={"vertSpacer1_5"}/>
                    <SelectPairFutures handleChangeQuote={this.handleChangeQuote}
                                handleChangeBase={this.handleChangeBase}
                                quote={this.state.quote}
                                base={this.state.base}
                                quoteCurrenciesNom={this.props.quoteCurrenciesNom}
                                baseCurrenciesNom={this.props.baseCurrenciesNom}
                                userBaseBalance={this.props.userBaseBalance}
                    />
                    <div className={"vertSpacer1_5"}/>
                    <div className={"horizSeparator"}/>
                    <div className={"vertSpacer1"}/>
                    <BuySellAtFutures futuresType={this.state.futuresType}
                                      actionTypeOptionError={this.state.actionTypeOptionError}
                                      actionTypeOption={this.state.actionTypeOption}
                                      handleChangeActionTypeOption={this.handleChangeActionTypeOption}
                                      fixedPrice={this.state.fixedPrice}
                                      fixedPriceError={this.state.fixedPriceError}
                                      handleChangeFixedPrice={this.handleChangeFixedPrice}
                                      quote={this.state.quote}
                                      base={this.state.base}
                                      currentLastBidPrice={this.props.currentLastBidPrice}
                                      currentLastBidPriceOrderBook={this.getFieldOrTestValue(this.props.orderBookCurrentPrice, TEST_CURRENT_PRICE)}
                                      tickSize={this.state.tickSize}
                                      userAvgBuyPriceForQuote={this.state.userAvgBuyPriceForQuote}
                    />
                    <div className={"vertSpacer1_5"}/>
                    <div className={"horizSeparator"}/>
                    <div className={"vertSpacer1_5"}/>
                    <SetPosSizeFutures
                                futuresType={this.state.futuresType}
                                posSize={this.state.posSize}
                                handleChangePosSize={this.handleChangePosSize}
                                posSizeSlider={this.state.posSizeSlider}
                                handleChangePosSizeSlider={this.handleChangePosSizeSlider}
                                quote={this.state.quote}
                                base={this.state.base}
                                baseBalance={this.state.userBaseBalance}
                                notionalValue={this.state.notionalValue ? this.state.notionalValue: 0}
                                initialMargin={this.state.initialMargin}
                                additionalIsolatedMargin={this.state.additionalMargin}
                                openLoss={this.state.openLoss}
                                size={this.state.size}
                                cost={this.state.cost}
                                totalCost={this.state.totalCost}
                                max={this.state.max}
                                precision={this.state.precision}
                                tickSize={this.state.tickSize}
                                otherPosSizeErrors={this.state.otherPosSizeErrors}
                                price={this.state.usedPrice}
                                liquidationPrice={this.state.liquidationPrice}
                                maintenanceMargin={this.state.maintenanceMargin}
                                mMarginRate={this.state.mMarginRate}
                                percentageLiquidationToEntry={this.state.percentageLiquidationToEntry}
                    />
                    <div className={"vertSpacer1_5"}/>
                    <div className={"horizSeparator"}/>
                    <div className={"vertSpacer1_5"}/>
                    <FuturesConditions
                                     leverage={this.state.leverage}
                                     currentPrice={this.getFieldOrTestValue(this.props.orderBookCurrentPrice, TEST_CURRENT_PRICE)}
                                     takeProfitOption={this.state.takeProfitOption}
                                     takeProfitPriceOrPercent={this.state.takeProfitPriceOrPercent}
                                     stopLossOption={this.state.stopLossOption}
                                     stopLossPriceOrPercent={this.state.stopLossPriceOrPercent}
                                     conditionalStopLossPrice={this.state.conditionalStopLossPrice}
                                     switchWithOrWithoutStopLossTarget={this.switchWithOrWithoutStopLossTarget}
                                     handleChangeTakeProfitOption={this.handleChangeTakeProfitOption}
                                     handleChangeTakeProfitPriceOrPercent={this.handleChangeTakeProfitPriceOrPercent}
                                     handleChangeStopLossOption={this.handleChangeStopLossOption}
                                     handleChangeStopLossPriceOrPercent={this.handleChangeStopLossPriceOrPercent}
                                     handleChangeCandlesType={this.handleChangeCandlesType}
                                     base={this.state.base}
                                     futuresType={this.state.futuresType}
                                     candlesType={this.state.candlesType}
                                     liquidationPrice={this.state.liquidationPrice}
                                     stopLossPriceOrPercentError={this.state.stopLossPriceOrPercentError}
                                     additionalMargin={this.state.additionalMargin}
                                     handleChangeAdditionalMargin={this.handleChangeAdditionalMargin}

                    />
                    <div className={"vertSpacer1_5"}/>
                    <div className={"horizSeparator"}/>
                    <div className={"vertSpacer1"}/>
                    <NewFuturesStep1Buttons preview={this.previewNewFutures} futuresType={this.state.futuresType} ASAS={this.state.ASAS}/>
                </div>
                <div key={"orderBook"} className={"OrderBook"}>
                    <OrderBook base={this.state.base} quote={this.state.quote} orderBook={this.props.orderBook} size={12} futures={true}/>
                </div>
                <div className={"vertSpacer1_5"}/>
            </div>
        )
    }

}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    base: rootState.newFutures.lastUsed.base,
    quote: rootState.newFutures.lastUsed.quote,
    posSize: rootState.newFutures.lastUsed.posSize,
    actionTypeOption: rootState.newFutures.lastUsed.actionTypeOption,
    takeProfitOption: rootState.newFutures.lastUsed.takeProfitOption,
    takeProfitPriceOrPercent: rootState.newFutures.lastUsed.takeProfitPriceOrPercent,
    stopLossOption: rootState.newFutures.lastUsed.stopLossOption,
    stopLossPriceOrPercent: rootState.newFutures.lastUsed.stopLossPriceOrPercent,

    baseCurrenciesNom: rootState.newFutures.baseCurrenciesNom,
    quoteCurrenciesNom: rootState.newFutures.quoteCurrenciesNom,
    currentLastBidPrice: rootState.newFutures.currentLastBidPrice,

    notEnoughBalance: rootState.newFutures.notEnoughBalance,
    userBaseBalance: rootState.newFutures.userBaseBalance,
    userQuoteBalance: rootState.newFutures.userQuoteBalance,

    _hasFuturesActivated: rootState.profile.hasFuturesActivated,

    orderBook: rootState.orderBook.orderBook,
    orderBookCurrentPrice: rootState.orderBook.currentPrice
})

let mapDispatchToProps = dispatch => {
    return {
        getCurrenciesMap: (props) => dispatch(getCurrenciesMap(props)),
        getLastUsedParamsOnNewFutures: (props, cb) => dispatch(getLastUsedParamsOnNewFutures(props, cb)),
        getCurrentPriceOnPair: (props, cb) => dispatch(getCurrentPriceOnPair(props, cb)),
        previewNewFutures: (props, cb) => dispatch(previewNewFutures(props, cb)),
        checkUserBalance: (props, cb) => dispatch(checkUserBalance(props, cb)),
        getUserBaseBalance: (props, cb) => dispatch(getUserBaseBalance(props, cb)),
        getSymbolPrecisionAndTickSize: (props, cb) => dispatch(getSymbolPrecisionAndTickSize(props, cb)),
        clearErrors: () => dispatch(clearErrors()),
        clearNotEnoughBalance: () => dispatch(clearNotEnoughBalance()),
        getActiveSuspendedActivities: (cb) => dispatch(getActiveSuspendedActivities(cb)),
        updateUserLeverage: (props, cb) => dispatch(updateUserLeverage(props, cb)),
        getUserLeverage: (props, cb) => dispatch(getUserLeverage(props, cb)),
        getNoOfRunningTradesOnContract: (props, cb) => dispatch(getNoOfRunningTradesOnContract(props, cb)),
        computeLiquidationPrice: (props, cb) => dispatch(computeLiquidationPrice(props, cb)),
        computeAdditionalIsoMarg: (props, cb) => dispatch(computeAdditionalIsoMarg(props, cb)),
        getRates: (props, cb) => dispatch(getRates(props, cb))
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (NewFutures)
