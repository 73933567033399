import React, {Component} from "react"
import {getTotals} from "../../redux/actions/wallet"
import {
    isTrader,
    isUserAdmin, isUserSignalsManager,
    isUserSuperAdmin,
    logOut
} from "../../redux/actions/authentication"
import {connect} from "react-redux"
import withGetScreenExt from "../../withGetScreenExt"
import SideBar from "../fncomponents/SideBar"
import Cookies from "universal-cookie"
import c from "../../constants"
import {withRouter} from "react-router-dom";
const cookies = new Cookies()

class AppAndSideBar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            lastUserId: this.props.user ? this.props.user.id: ""
        }
        this.handleChangeMarkets = this.handleChangeMarkets.bind(this)
        this.handleExpandTraderPanel = this.handleExpandTraderPanel.bind(this)
    }


    handleExpandTraderPanel() {
        let stateNow = cookies.get("trader_panel_expanded")
        stateNow = stateNow === "true"
        stateNow = !stateNow
        cookies.set("trader_panel_expanded", stateNow)
        this.setState({
            traderPanelExpanded: stateNow
        })
    }

    handleChangeMarkets(markets) {
        if (this.state.markets === markets)
            return
        cookies.set(`selected_markets`, markets)
        this.setState({
            markets
        })

        this.props.getTotals({markets}, data => {
            this.setState({
                totals: data.totals
            })
        })

        if (markets === c.MARKETS_SPOT) {
            this.props.history.push("/")
        }

        if (markets === c.MARKETS_FUTURES) {
            this.props.history.push("/futures")
        }
    }

    getTotalsPeriodically() {
        this.getTotalsInterval = setInterval(() => {
            if (!this.props.isMobile()) {
                this.props.getTotals({markets: this.state.markets}, data => {
                    this.setState({
                        totals: data.totals
                    })
                })
            }
        }, 60000)
    }

    componentDidMount() {
        this.getTotalsPeriodically()
        let markets = cookies.get(`selected_markets`)
        this.props.getTotals({markets}, data => {
            this.setState({
                totals: data.totals
            })
        })
        if (!markets) {
            markets = c.MARKETS_SPOT
        }

        let traderPanelExpanded = cookies.get("trader_panel_expanded")
        if (!traderPanelExpanded || traderPanelExpanded === "undefined") {
            //default when no cookie is set we start with the panel opened
            traderPanelExpanded = true
        } else {
            //else if the cookie is set we translate from string to boolean form
            traderPanelExpanded = traderPanelExpanded === "true"
        }
        this.setState({
            markets,
            traderPanelExpanded
        })

    }

    componentWillUnmount() {
        clearInterval(this.getTotalsInterval)
    }

    render() {
        let userId = this.props.user ? this.props.user.id : ""
        if (this.state.lastUserId !== userId) {
            this.state.lastUserId = userId
            this.props.getTotals({markets: this.state.markets}, data => {
                this.setState({
                    totals: data.totals
                })
            })
        }

        return <SideBar
                     renderCustomHeader={this.props.renderCustomHeader}                    
                     user={this.props.user}
                     logOut={this.props.logOut}
                     totals={this.state.totals}
                     isAdmin={isUserAdmin(this.props.user)}
                     isSuperAdmin={isUserSuperAdmin(this.props.user)}
                     isSignalsManager={isUserSignalsManager(this.props.user)}
                     isTrader={isTrader(this.props.user)}
                     isMobile={this.props.isMobile()}
                     markets={this.state.markets}
                     handleChangeMarkets={this.handleChangeMarkets}
                     handleExpandTraderPanel={this.handleExpandTraderPanel}
                     traderPanelExpanded={this.state.traderPanelExpanded}
        />
    }
}


const mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    logout: rootState.authentication.logout,
    totals: rootState.wallet.totals,
    renderCustomHeader: rootState.wallet.renderCustomHeader,
})

const mapDispatchToProps = dispatch => ({
    getTotals: (props, cb) => {dispatch(getTotals(props, cb))},
    logOut: () => dispatch(logOut())
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withGetScreenExt(AppAndSideBar)))
