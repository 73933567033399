let mode = "prod"

let API_HOST = window.location.hostname
let API_PORT, HTTP, WS, HOST

switch (mode) {
    case "direct":
        API_PORT = 3000
        HTTP = "http"
        WS = "ws"
        HOST = "localhost"
        break;
    case "dev":
        HTTP = "http"
        WS = "ws"
        HOST = "localhost"
        break;
    default:
        HTTP = "https"
        WS = "wss"
        HOST = "app.venbot.io"
        break;
}

let ACCESS_SETTINGS = {
    mode,
    WEB_HOOK_BACKEND_TRADING_VIEW: () => API_PORT ? `${HTTP}://${API_HOST}:${API_PORT}/whtv`: `${HTTP}://${API_HOST}/whtv`,
    API_BACKEND: () => API_PORT ? `${HTTP}://${API_HOST}:${API_PORT}/api`: `${HTTP}://${API_HOST}/api`,
    WS_API_BACKEND: () => API_PORT ? `${WS}://${API_HOST}:${API_PORT}` : `${WS}://${API_HOST}`,
    BLVTS: {
        "BTC": true, "ETH": true, "ADA": true, "LINK": true, "BNB": true, "XTZ": true, "EOS": true,
        "TRX": true, "DOT": true, "XRP": true, "LTC": true, "UNI": true

    },
    BLVT_DAILY_MANAGEMENT_FEE_PERCENTAGE: 0.01
}

let OTHER_SETTINGS = {
    HTTP,
    HOST,
    enterTouchDelay: 50
}


export default {
    ...ACCESS_SETTINGS,
    ...OTHER_SETTINGS
}
