import {
    WHA_NAME,
    WHA_REGISTER_LINK,
} from "../actions/actionTypes";

export default function (state = {user: undefined}, action) {
    switch (action.type) {

        case WHA_REGISTER_LINK: {
            return {
                ...state,
                ...{registerLink: action.payload}
            }
        }

        case WHA_NAME: {
            return {
                ...state,
                ...{name: action.payload}
            }
        }

        default:
            return state
    }
}
