import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles, useTheme  } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Fab from "@material-ui/core/Fab";
import AddIcon from '@material-ui/icons/Add';
import {Link as RouterLink} from "react-router-dom";
import Zoom from '@material-ui/core/Zoom';

function TabContainer(props) {
    const {className} = props
    return (
        <Typography component="div" className={className}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={event => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 0,
        backgroundColor: "#101A26",
        position: 'relative',
        minHeight: 300,
    },
    fab: {
        width: 50,
        height: 50,
        color: theme.palette.common.black,
        position: 'fixed',
        top: 'auto',
        left: 'auto',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 999999
    }
}));

const AntTabs = withStyles({
    root: {
        backgroundColor: "#101A26",
        color: "white"
    },
    indicator: {
        backgroundColor: '#17BEBB',
    },
})(Tabs);

export default function NavTabs(props) {
    const classes = useStyles();
    const {tabs, selectedTab, changeTab, tabContainerClass, showFloatingButton, isMobile, type} = props
    const [value, setValue] = React.useState(0)
    const theme = useTheme();

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    React.useEffect(() => {
        setValue(selectedTab)
    }, [selectedTab])

    function getLinkForAdd() {
        if (type === "SPOT") {
            return "/newtrade"
        } else if (type === "FUTURES") {
            return "/newfutures"
        }
    }

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <AntTabs variant="fullWidth" value={value} onChange={changeTab} key={Math.random()}>
                    {tabs.map(tab => <LinkTab key={`nav_tabs_link_${Math.random()}`} label={tab.title} className={classes.linkTab}/>)}
                </AntTabs>
            </AppBar>
            {(isMobile && showFloatingButton && selectedTab < 3) && <RouterLink to={getLinkForAdd()}>
                <Zoom
                    key={"ZoomFAB_293"}
                    in={selectedTab < 3}
                    timeout={transitionDuration}
                    style={{
                        transitionDelay: `${selectedTab > 1 ? transitionDuration.exit : 0}ms`,
                    }}
                    unmountOnExit
                >
                    <Fab color="primary" aria-label="add" className={classes.fab}>
                        <AddIcon/>
                    </Fab>
                </Zoom>
            </RouterLink>}
            {tabs.map((tab, tabIndex) =>
            {
                if (value === tabIndex && tab.content) {
                    return <TabContainer key={`nav_tabs_container_${Math.random()}`} className={tabContainerClass}
                                         children={tab.content}/>
                } else return null
            })
            }

        </div>
    );
}
