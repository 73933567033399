import React, {Component} from 'react'
import { connect } from 'react-redux'
import {getActiveInvestments, getInvestmentTermsAndCond, getSaleProgress} from "../../../redux/actions/investment";
import Cookies from "universal-cookie";
import c from "../../../constants";
import qs from "query-string";
import ActiveInvestmentProgram from "./ActiveInvestmentProgram";
import {getBalances} from "../../../redux/actions/wallet";
import UserInvests from "../../fncomponents/UserInvests";
import InvestmentTermsAndCond from "./InvestmentTermsAndCond";

class ActiveInvestmentsTab extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showInvest: false,
            showInvestConfirmation: false,
            showTermsAndConditions: false
        }
        this.renderInvestmentPrograms = this.renderInvestmentPrograms.bind(this)
        this.showInvest = this.showInvest.bind(this)
        this.closeInvest = this.closeInvest.bind(this)
        this.autoCloseInvest = this.autoCloseInvest.bind(this)

        this.openInvestmentProgram = this.openInvestmentProgram.bind(this)
        this.closeInvestmentProgram = this.closeInvestmentProgram.bind(this)

        this.openTermsAndConditions = this.openTermsAndConditions.bind(this)
        this.closeTermsAndConditions = this.closeTermsAndConditions.bind(this)

        this.canShowOpenedProgram = this.canShowOpenedProgram.bind(this)

        this.getSaleProgressFn = this.getSaleProgressFn.bind(this)
        this.getPeriodicallySaleProgress = this.getPeriodicallySaleProgress.bind(this)
    }

    getSaleProgressFn() {
        if (this.state.iPId) {
            this.props.getSaleProgress({investmentProgramIds:[this.state.iPId]}, data => {
                this.setState({
                    saleProgress: data.saleProgress[this.state.iPId],
                    yourInvestedStatus: data.yourInvestedStatus[this.state.iPId],
                    soldAllocation: data.soldAllocation[this.state.iPId],
                })
            })
        }
    }

    getPeriodicallySaleProgress() {
        this.getSaleProgressFn()
        this.getSaleProgressInterval = setInterval(() => getSaleProgress(), 3000)
    }


    canShowOpenedProgram() {
        return (!this.state.showInvest && !this.state.showTermsAndConditions)
    }

    closeTermsAndConditions(_id) {
        this.setState({
            termsAndCond: undefined,
            showTermsAndConditions: false
        })
    }

    openTermsAndConditions() {
        this.props.getInvestmentTermsAndCond({iPId: this.state.iPId}, data => {
            this.setState({
                termsAndCond: data,
                showTermsAndConditions: true
            })
        })
    }

    closeInvestmentProgram(_id) {
        this.setState({
            iPId: undefined,
            openedIP: undefined
        })
    }

    openInvestmentProgram(_id) {
        this.props.getActiveInvestments({iPId: _id, doNotDispatch: true}, activeInvPs => {
            this.setState({
                iPId: _id,
                openedIP: activeInvPs[0]
            })
            this.getPeriodicallySaleProgress()
        })
    }


    showInvest(selectedInvestmentProgramId) {
        this.props.getBalances({}, balances => {
            this.setState({
                showInvest: true,
                selectedInvestmentProgram: this.props.activeInvPs.filter(iP => iP._id === selectedInvestmentProgramId)[0],
                balances
            })
        })
    }

    closeInvest() {
        this.setState({
            showInvest: false,
            showInvestConfirmation: false,
        })
    }

    autoCloseInvest() {
        this.setState({
            showInvest: false,
            showInvestConfirmation: true,
        })
        setTimeout(() => {
            this.setState({
                showInvestConfirmation: false
            })
        }, 5000)
        this.getSaleProgressFn()
    }

    componentDidMount() {
        //Handling getting investment program id if the user clicked on a specific Program from the list
        //or if it came directly on the link
        let iPId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).id

        //Handling getting the referral link cookies
        const cookies = new Cookies()
        let rLinkUid = cookies.get(`${c.INVESTMENT_PROGRAM_REFERRAL_COOKIE}_R_LINK_UID`)

        if (rLinkUid) {
            cookies.remove(`${c.INVESTMENT_PROGRAM_REFERRAL_COOKIE}_IP_ID`)
            cookies.remove(`${c.INVESTMENT_PROGRAM_REFERRAL_COOKIE}_USER`)
            cookies.remove(`${c.INVESTMENT_PROGRAM_REFERRAL_COOKIE}_ACCESS_TIME`)
            cookies.remove(`${c.INVESTMENT_PROGRAM_REFERRAL_COOKIE}_NEW`)
            cookies.remove(`${c.INVESTMENT_PROGRAM_REFERRAL_COOKIE}_R_LINK_UID`)
        }

        //Getting - on component mount - the investment programs list or one specific program if the iPId was specified.
        //Also, passing the referral link uid, extracted from the cookie, so that, if the user would not
        //normally have access to a program but would obtain it via a referral link - we should know about and
        //act accordingly.
        //In the end, the referral cookies are deleted as we no longer need them, because once the referral link uid
        //is sent to the server, the server stores the access given.
        if (iPId) {
            //First get the simple data structure for all the active Investment Programs
            this.props.getActiveInvestments({rLinkUid}, activeInvPs => {
                //Then get the full data structure of the opened Investment Program
                this.props.getActiveInvestments({rLinkUid, iPId, doNotDispatch: true}, activeInvPs => {
                    this.setState({
                        iPId,
                        openedIP: activeInvPs[0]
                    })
                    this.getPeriodicallySaleProgress()
                })
            })
        } else {
            //If no Investment Program was specified in the id parameter, retrieve only the simplified data structure
            //for all the active Investment Programs
            this.props.getActiveInvestments({rLinkUid}, activeInvPs => {
                if (activeInvPs.length === 1) {
                    this.setState({
                        iPId: activeInvPs[0]._id,
                        openedIP: activeInvPs[0]
                    })
                    this.getPeriodicallySaleProgress()
                } else {
                    this.setState({
                        rLinkUid})
                }
            })
        }
    }


    renderInvestmentPrograms() {
        if (!this.props.activeInvPs)
            return null
        if (this.props.activeInvPs.length === 0) {
            return <div style={{paddingTop: "2rem", display: "flex", justifyContent: "center"}}>
                <div>There are no <b>a</b>ctive contributions</div>
            </div>
        }

        let content = []
        for(let i = 0; i < this.props.activeInvPs.length; i++) {
            content.push(<div style={{display: "flex"}}>
                <div><h1>{this.props.activeInvPs[i].title}</h1></div>
                <div style={{marginLeft: "0.5rem", paddingTop: "2rem"}}>
                    <div className={"linkButton"} onClick={() => {this.openInvestmentProgram(this.props.activeInvPs[i]._id)}}>Open</div></div>
            </div>)
        }
        return content
    }

    render() {
        return <div>
            {this.state.iPId &&
            <>
                {this.canShowOpenedProgram() && <ActiveInvestmentProgram
                    back={(this.props.activeInvPs && this.props.activeInvPs.length > 1) ? this.closeInvestmentProgram: undefined}
                    program={this.state.openedIP}
                    invest={this.showInvest}
                    yourInvestedStatus={this.state.yourInvestedStatus? this.state.yourInvestedStatus: ""}
                    progressBarPercentage={this.state.saleProgress? this.state.saleProgress: 0}
                    soldAllocation={this.state.soldAllocation ? this.state.soldAllocation: 0}
                    openInviteDialog={this.openInviteDialog}
                    userId={this.props.user.userId}
                    showInvestConfirmation={this.state.showInvestConfirmation}
                    openTC={this.openTermsAndConditions}
                />}
                {this.state.showInvest && <UserInvests
                    investProgram={this.state.selectedInvestmentProgram}
                    balances={this.state.balances? this.state.balances.balances: undefined}
                    investmentCoin={"USDT"}
                    cancel={this.closeInvest}
                    autoClose={this.autoCloseInvest}
                />}
                {this.state.showTermsAndConditions && <InvestmentTermsAndCond
                    data={this.state.termsAndCond}
                    back={this.closeTermsAndConditions}
                />}
            </>}
            {!this.state.iPId && this.renderInvestmentPrograms()}
        </div>

    }
}

const mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    activeInvPs: rootState.investment.activeInvPs
})

const mapDispatchToProps = dispatch => {
    return {
        getActiveInvestments: (props, cb) => dispatch(getActiveInvestments(props, cb)),
        getBalances: (props, cb) => dispatch(getBalances(props, cb)),
        getInvestmentTermsAndCond: (props, cb) => dispatch(getInvestmentTermsAndCond(props, cb)),
        getSaleProgress: (props, cb) => dispatch(getSaleProgress(props, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ActiveInvestmentsTab)
