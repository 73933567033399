import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import QRCode from 'qrcode.react'
import OTPInput from 'react-otp-input'
import {TextField} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

function getSteps() {
    return ['Download App', 'Scan QR Code', 'Backup Key', 'Enable Google Authenticator']
}

const step1Content = <div className={"stepperStepContainer"}>
    <div className={"stepperStepTitle"}>Step 1</div>
    <div className={"stepperStepSubtitle"}>Download and install the Google Authenticator App.</div>
    <div style={{display:"flex", flexWrap: "wrap"}}>
        <div>
            <a href={"https://apps.apple.com/us/app/google-authenticator/id388497605"} target={"_blank"} rel="noopener noreferrer">
                <img src={"imgs/appStoreBadge/128x128.png"} alt={"appStoreBadge"}/>
            </a>
        </div>
        <div style={{width: "3em"}}/>
        <div>
            <a href={"https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"} target={"_blank"} rel="noopener noreferrer">
                <img src={"imgs/googlePlayBadge/128x128.png"} alt={"googlePlayBadge"}/>
            </a>
        </div>
    </div>
</div>

function step2Content({otpAuthUrl, twoFASecret}) {
    return (
        <div className={"stepperStepContainer"}>
            <div className={"stepperStepTitle"}>Step 2</div>
            <div className={"stepperStepSubtitle"}>Scan this QR code in the Google Authenticator App.</div>
            <br/>
            <div style={{display: "flex", flexWrap: "wrap"}}>
                <div style={{marginRight: "1em", backgroundColor: "white", padding: "1rem"}}>
                    {otpAuthUrl && <QRCode value={otpAuthUrl}/>}
                </div>
                <div>
                    <br/>
                    <div className={"mediumControlBoldGray"} style={{width: "20em"}}>If you are unable to scan the QR code, please enter this code manually into the app.</div>
                    <br/>
                    <div style={{backgroundColor: "#FAFAFA", fontSize: "1.2em", padding: "0.6em", textAlign: "center", opacity: "0.7", color: "#000000"}}>
                        {twoFASecret}
                    </div>
                    <br/><br/>
                </div>
            </div>
        </div>
    )
}

function step3Content({twoFASecret}) {
    return (
        <div className={"stepperStepContainer"}>
            <div className={"stepperStepTitle"}>Step 3</div>
            <div className={"stepperStepSubtitle"}>Please save this Key on paper. This Key will allow you to recover your Google Authenticator in case of phone loss/change.</div>
            <br/>
            <div>
                <div className={"mediumControlBoldGray"}>Resetting your Google Authenticator requires opening a support ticket and takes at least 7-14 days to process.</div>
                <br/>
                <div style={{backgroundColor: "#FAFAFA", fontSize: "1.2em", padding: "0.6em", textAlign: "center",  opacity: "0.7", color: "#000000"}}>
                    {twoFASecret}
                </div>
            </div>
            <br/><br/>
        </div>
    )
}

function step4Content({otp, onOtpChange, password, onPasswordChange, validationErrors}) {
    const errorDisplay = validationErrors ? "block" : "none"
    return (
        <div className={"stepperStepContainer"}>
            <div className={"stepperStepTitle"}>Step 4</div>
            <div className={"stepperStepSubtitle"}>Enable your Google Authenticator.</div>
            <br/>
            <div>
                <div style={{color: "red", display: errorDisplay}}>Password or token invalid...</div>
                <TextField placeholder={"Login password"}
                           label={"Login password"}
                           type={"password"}
                           name="password"
                           value={password}
                           onChange={onPasswordChange}/>
                <br/><br/>
                <div style={{marginBottom: "0.6em", color: "#8B8275"}}>Google Authentication Code:</div>
                <OTPInput numInputs={6}
                          value={otp}
                          onChange={onOtpChange}
                          containerStyle={{width: "18em", justifyContent: "space-between"}}
                          inputStyle={{width: "1.5em", height: "2em", borderBottom: "1px solid black", fontSize: "1.2em", color: "black", borderRadius: "5px"}}/>
                <br/><br/>
            </div>
        </div>
    )
}

function getStepContent(stepIndex, params) {
    switch (stepIndex) {
        case 0:
            return step1Content
        case 1:
            return step2Content(params)
        case 2:
            return step3Content(params)
        case 3:
            return step4Content(params)
        default:
            return 'Uknown stepIndex'
    }
}

export default function StepperOTPActivation({otpAuthUrl, twoFASecret, otp, onOtpChange, password, onPasswordChange, finish, validationErrors}) {
    const classes = useStyles()
    const [activeStep, setActiveStep] = React.useState(0)
    const steps = getSteps()

    function handleNext() {
        if (activeStep === steps.length - 1) {
            finish()
        } else
            setActiveStep(prevActiveStep => prevActiveStep + 1)
    }

    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1)
    }

    return (
        <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                <div className={"AppMainContent"}>
                        {getStepContent(activeStep,
                            {
                                otpAuthUrl: otpAuthUrl,
                                twoFASecret: twoFASecret,
                                otp: otp, onOtpChange: onOtpChange,
                                password: password, onPasswordChange: onPasswordChange,
                                validationErrors: validationErrors})}
                    <div>

                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.backButton}
                            >Back</Button>
                            <Button variant="contained" color="primary" onClick={handleNext}>
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </div>
                        <br/><br/>
                    </div>
                </div>
            </div>
        </div>
    )
}
