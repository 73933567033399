import React from "react";
import { Redirect } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { ArrowBack } from "@material-ui/icons";

import MenuIcon from "@material-ui/icons/Menu";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";

import { withRouter } from "react-router-dom";
import TemporaryDrawer from "../menu/TemporaryDrawer";
import PermanentDrawer from "../menu/PermanentDrawer";
import WHALogo from "./WHALogo";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#101A26",
    color: "#ffffff",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  fab: {
    margin: theme.spacing(1),
    width: 40,
    height: 38,
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const KNOWN_URLS = {
  "/": "Spot",
  "/newtrade": "New Spot Trade",
  "/newmargin": "New Margin Trade",
  "/futures": "Futures",
  "/newfutures": "New Futures Trade",
  "/converter": "Converter",
  "/previewtrade": "Preview Trade",
  "/previewconversion": "Preview Conversion",
  "/previewfutures": "Preview Futures",
  "/wallets": "Wallets",
  "/myprofile": "My Profile",
  "/referredusers": "Referred users",
  "/affiliate/commissions": "Your Affiliate Commissions",
  "/tradedetails": "Trade Details",
  "/orderdetails": "Order Details",
  "/tradesummary": "Trade Summary",
  "/completedtradedetails": "Completed Trade Details",
  "/completedfuturestradedetails": "Completed Futures Trade Details",
  "/ordersummary": "Order Summary",
  "/btdsummary": "BTD Summary",
  "/btddetails": "BTD Details",
  "/2fa": "2FA",
  "/signals": "Your Signals",
  "/signalsnotifs": "Requests to Join",
  "/newsignal": "New Spot Signal",
  "/newsignalF": "New Futures Signal",
  "/editsignal": "Edit Signal",
  "/editsignalF": "Edit Futures Signal",
  "/previewsignal": "Preview Signal",
  "/sucs": "Signals",
  "/sucs-new":"Signals",
  "/bots": "Bots",
  "/newbot": "New Bot",
  "/editbot": "Edit Bot",
  "/previewbot": "Preview Bot",
  "/sucdetails": "Signal Details",
  "/sucsummary": "Signal Summary",
  "/cw": "Confirm Withdrawal",
  "/ct": "Confirm Transfer",
  "/traders": "Traders",
  "/trader": "Trader",
  "/traderbox": "Trader Box",
  "/trader/mypage": "Trader - My Page",
  "/applyfortrader": "Apply for Trader",
  "/confirmsub": "Confirm Subscription",
  "/confirmunsub": "Unsubscribe",
  "/trader/followers": "Your Followers",
  "/trader/followers/new": "New Follower",
  "/trader/followers/edit": "Edit Follower",
  "/trader/payments": "Your Payments",
  "/trader/commissions": "Your Commissions",
  "/buycrypto": "Buy crypto to your wallet",
  "/buycryptot": "Buy crypto TRANSAK",
  "/ref": "Register via refferal",
  "/staking": "Staking",
  "/futuresorderdetails": "Futures Order Details",
  "/futuresordersummary": "Futures Order Summary",
  "/futuresposdetails": "Futures Position Details",
  "/futurespossummary": "Futures Position Summary",
  "/unsub": "Unsubscribe",
  "/contributions": "Contributions",
  "/contribution": "Contribution",
  "/contributiontc": "Contribution Terms & Conditions",
};

const SideBar = (props) => {
  const {
    user,
    logOut,
    location,
    totals,
    isAdmin,
    isSuperAdmin,
    isSignalsManager,
    isTrader,
    isMobile,
    markets,
    handleChangeMarkets,
    handleExpandTraderPanel,
    traderPanelExpanded,
    renderCustomHeader,
  } = props;



  const classes = useStyles();

  function getNavBarTitle() {
    if (!user || (user && user.permissions[0] == "OTPONLY"))
      return (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <a href={"/"} style={{marginTop: "10px"}}>
            <WHALogo/>
          </a>
        </div>
      );

    let title = KNOWN_URLS[location.pathname];
    // return title ? title  +  (navigator.appVersion.indexOf("iPhone")!=-1 ? " | iPhone" : ""): "Unknown"
    if (renderCustomHeader) {
        return renderCustomHeader();
    }
    return title ? title : "Unknown";
  }

  function handleLogout() {
    logOut();
  }

  function isAuthenticated() {
    if (user) {
      if (user.permissions && user.permissions[0] == "OTPONLY") return false;
      return true;
    }
    return false;
  }

  let [isDrawerOpen, setDrawerOpen] = React.useState(false);

  function toggleDrawer(e) {
    setDrawerOpen(!isDrawerOpen);
  }

  function getMenuOrBackButton() {
    if (location.pathname == "/newtrade") {
      return (
        <IconButton edge="start" className={classes.menuButton}>
          <RouterLink to={"/"} style={{ color: "white" }}>
            <ArrowBack />
          </RouterLink>
        </IconButton>
      );
    } else
      return (
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="Menu"
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>
      );
  }

  function beautifyUserEmail(email) {
    let first2L = email.substring(0, 2)
    let second2L = email.substring(2,4)
    let theRest = email.substring(4, email.length)
    return <span>
      <span style={{opacity: "0.9"}}>{first2L}</span>
      <span style={{opacity: "0.8"}}>{second2L}</span>
      <span style={{opacity: "0.7"}}>{theRest}</span>
    </span>
  }

  if (!isAuthenticated())
    return (
      <React.Fragment>
        <ElevationScroll {...props}>
          <AppBar className={classes.root}>
            <Toolbar>
              <Typography
                className={classes.title}
                variant="h6"
                style={{ display: "flex", flexGrow: "0", width: "100%" }}
              >
                {getNavBarTitle()}
              </Typography>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
      </React.Fragment>
    );

  if (!KNOWN_URLS[location.pathname]) {
    return <Redirect to={"/"} />;
  } else {
    // console.log("location.pathname", KNOWN_URLS[location.pathname])
  }

  return (
    <React.Fragment>
      {isMobile && (
        <ElevationScroll {...props}>
          <AppBar className={classes.root}>
            <Toolbar>
              {isMobile && isAuthenticated() && getMenuOrBackButton()}
              <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                <Typography
                    className={classes.title}
                    variant="h6"
                    style={{ display: "flex", flexGrow: "0", width: "100%" }}
                >
                  {getNavBarTitle()}
                </Typography>
                <div style={{fontSize: "0.7rem", marginTop: "0.7rem"}}>{beautifyUserEmail(user.email)}</div>
              </div>

            </Toolbar>
          </AppBar>
        </ElevationScroll>
      )}
      {isMobile && (
        <TemporaryDrawer
          isDrawerOpen={isDrawerOpen}
          toggleDrawer={toggleDrawer}
          user={user}
          isAuthenticated={isAuthenticated()}
          isAdmin={isAdmin}
          isSuperAdmin={isSuperAdmin}
          isSignalsManager={isSignalsManager}
          isTrader={isTrader}
          handleLogout={handleLogout}
          isMobile={isMobile}
          location={location.pathname}
          totals={totals}
          markets={markets}
          handleChangeMarkets={handleChangeMarkets}
          handleExpandTraderPanel={handleExpandTraderPanel}
          traderPanelExpanded={traderPanelExpanded}
        />
      )}

      {!isMobile && (
        <AppBar className={classes.root}>
          <Toolbar style={{ marginLeft: "15rem" }}>
            <Typography
              className={classes.title}
              variant="h6"
              style={{ display: "flex", flexGrow: "0", width: "100%" }}
            >
              {getNavBarTitle()}
            </Typography>
          </Toolbar>
        </AppBar>
      )}

      {!isMobile && (
        <PermanentDrawer
          user={user}
          isAuthenticated={isAuthenticated()}
          isAdmin={isAdmin}
          isSuperAdmin={isSuperAdmin}
          isSignalsManager={isSignalsManager}
          isTrader={isTrader}
          handleLogout={handleLogout}
          isMobile={isMobile}
          location={location.pathname}
          totals={totals}
          markets={markets}
          handleChangeMarkets={handleChangeMarkets}
          handleExpandTraderPanel={handleExpandTraderPanel}
          traderPanelExpanded={traderPanelExpanded}
        />
      )}
    </React.Fragment>
  );
};

export default withRouter(SideBar);
