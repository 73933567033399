import React from 'react'
import { ReactComponent as UpTrendGreen } from '../../svgs/up_trend_green.svg'
import { ReactComponent as DownTrendRed } from '../../svgs/down_trend_red.svg'
import { ReactComponent as InlineTrendWhite } from '../../svgs/inline_trend_white.svg'
import c from '../../constants'

import {
    timeFormatter2,
    timeFormatter, brushValue, getFuturesCardSymbolWidth,
} from "./UISupportFns";

import {AddBox, SettingsInputAntenna} from "@material-ui/icons";

export default function FuturesOrderCard(props) {
    const {futuresTrade, openRecord} = props

    return (
        <div className={`tradeContainer ${futuresTrade.futures_type ? futuresTrade.futures_type.toLowerCase(): "?"}FuturesTradeBand`} onClick={openRecord}>
            <div className={"tradeSubContainerLeft"}>
                <div style={{display: "flex", justifyContent: "space-between", width: getFuturesCardSymbolWidth(futuresTrade)}}>
                    <div>
                        <span style={{fontSize: "1.2em", fontWeight: "bold"}}>{futuresTrade.quote}</span>
                        <span style={{fontSize: "1em"}}> / {futuresTrade.base}</span>
                        <span className={`${futuresTrade.futures_type ? futuresTrade.futures_type.toLowerCase(): "?"}FuturesCardLeverage`}>{futuresTrade.leverage}X</span>
                        <span className={`futuresType${futuresTrade.futures_type}`}>{futuresTrade.futures_type}</span>
                    </div>
                    <div style={{top: "0.8rem"}}>
                        {futuresTrade.trend === 1 && <UpTrendGreen/>}
                        {futuresTrade.trend === 0 && <InlineTrendWhite/>}
                        {futuresTrade.trend === -1 && <DownTrendRed/>}
                    </div>
                </div>
                <div className={"activeTradePosAndQtyStart"}>
                    <small>size</small> <span className={"futuresSize"}>{futuresTrade.origQty} {futuresTrade.quote}</span>
                </div>
                <div className={"activeTradePosAndQtyStart"} title={"Initial margin"}>
                    <small>initial margin</small> <b>{futuresTrade.cost} {futuresTrade.base}</b>
                </div>
                <div className={"activeTradePosAndQtyStart"} title={"Additional margin"}>
                    <small>additional margin</small> <b>{futuresTrade.additionalIsolatedMargin}</b>
                </div>
                <div className={"activeTradePosAndQtyStart"} title={"Cost"}>
                    <small>liq.price</small> <b>{futuresTrade.liquidationPrice} {futuresTrade.base}</b>
                </div>
                <div>
                    <div className={"w3-progress-sizer"}>
                        <div className="w3-progress-container w3-round-xlarge">
                            <div className="w3-progressbar w3-round-xlarge" style={{width:`${futuresTrade.progress}%`}}/>
                        </div>
                    </div>
                    <div className={"progressBarText"}>{futuresTrade.progress}% FILLED</div>
                    {futuresTrade.status === c.FUTURES_ORDER_STATUS_CANCELED && <div className={"activeTradePosAndQtyStart"}>
                        <small>({futuresTrade.executedQty} {futuresTrade.quote} bought with {futuresTrade.cumQuote} {futuresTrade.base})</small>
                    </div>}
                </div>
                {futuresTrade.from_SUC &&
                <div style={{display: "flex", justifyContent: "flex-start"}}>
                    <SettingsInputAntenna style={{color: "#64FE7C", fontSize: "42"}} />
                </div>}
            </div>




            {(futuresTrade.tradeState === c.FUTURES_TRADE_STATE_PARENT_ONGOING || futuresTrade.tradeState === c.FUTURES_TRADE_STATE_PARENT_PARTIALLY_FILLED) &&
            <div className={"tradeSubContainerRight"}>
                <div className={"currentPriceStatsContainer"} title={"Current price"}>
                    <div className={futuresTrade.currentPriceClass}>{futuresTrade.currentPriceDeltaPercent}%</div>
                    <span className={"horizSpacer"}/>
                    <div className={futuresTrade.currentPriceClass}>{futuresTrade.currentPrice}</div>
                </div>
                <div className={"activeTradePosAndQtyEnd"}><span className={`entryPrice${futuresTrade.futures_type}`}>{futuresTrade.entryPriceLable}</span>  price <b>{futuresTrade.price}</b></div>
                {futuresTrade.maxPrice !== "0" &&<div className={"maxPriceContainer"} title={"Max price during the trade"}>
                    <span>{futuresTrade.maxDelta}% · {futuresTrade.maxPrice}</span>
                </div>}
                {futuresTrade.minPrice !== "0" && <div className={"minPriceContainer"} title={"Min price during the trade"}>
                    <span>{futuresTrade.minDelta}% · {futuresTrade.minPrice}</span>
                </div>}
                {(futuresTrade.from_SUC && futuresTrade.trader_name) && <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <span style={{fontSize: "0.7rem", marginTop: "0.3rem"}}>from&nbsp;</span>{futuresTrade.trader_name}</div>}
            </div>}



            {(futuresTrade.tradeState === c.FUTURES_TRADE_STATE_PARENT_CANCELED || futuresTrade.tradeState === c.FUTURES_TRADE_STATE_PARENT_IN_ERROR ||
                futuresTrade.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_FILLED) &&
            <div className={"tradeSubContainerRight"}>
                <div className={"currentPriceStatsContainer"} title={"Current price"}>
                    <div className={futuresTrade.currentPriceClass}>{futuresTrade.currentPriceDeltaPercent}%</div>
                    <span className={"horizSpacer"}/>
                    <div className={futuresTrade.currentPriceClass}>{futuresTrade.currentPrice}</div>
                </div>
                {futuresTrade.tradeState === c.FUTURES_TRADE_STATE_PARENT_CANCELED &&
                    <div className={"currentPriceStatsContainer"}>
                        <span style={{color: " #E8E288"}}>Canceled</span>
                    </div>}
                {futuresTrade.tradeState === c.FUTURES_TRADE_STATE_PARENT_IN_ERROR &&
                    <div className={"currentPriceStatsContainer"}>
                        <span style={{color: "#FB4A4A"}}>Error</span>
                    </div>}
                {(futuresTrade.from_SUC && futuresTrade.trader_name) && <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <span style={{fontSize: "0.7rem", marginTop: "0.3rem"}}>from&nbsp;</span>{futuresTrade.trader_name}</div>}
            </div>}

            <div style={{display:"flex", justifyContent:"center", width: "100%"}}>
                <div className={"buyPriceContainer"}>
                    {futuresTrade.status === c.FUTURES_ORDER_STATUS_FILLED &&
                    <div>
                        <span>Filled at {futuresTrade.buyPrice}</span>
                    </div>}
                    {futuresTrade.status === c.FUTURES_ORDER_STATUS_CANCELED && <div>Canceled on {timeFormatter2(futuresTrade.last_update_time, true)}</div>}
                </div>
                {(futuresTrade.status === c.FUTURES_ORDER_STATUS_NEW || futuresTrade.status === c.FUTURES_ORDER_STATUS_PARTIALLY_FILLED) &&
                <div>
                    <div className={"hrState"}>
                        <span className="blink live" style={{color: "#68FF7D"}}>▶</span>
                    </div>
                </div>}
            </div>
            <div style={{display:"flex", justifyContent:"space-between", width: "100%"}} className={"recordId"}>
                <span>{futuresTrade.last_order_id}
                        {futuresTrade.from_SUC && futuresTrade.from_SUC !== "undefined" && (<span title={`from Signal ${futuresTrade.signal_id}`}>&nbsp;&nbsp;(<b>{futuresTrade.signal_id}</b>)</span>)}
                </span>
                <span>{timeFormatter(futuresTrade.time, true)}</span>
            </div>
        </div>)
}
