import React from "react"
import {TextField} from "@material-ui/core"
import MenuItem from "@material-ui/core/MenuItem"
import makeStyles from "@material-ui/core/styles/makeStyles"
import SwapHoriz from '@material-ui/icons/SwapHoriz'
import {brushValue, isValidPair} from "./UISupportFns";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 130,
        backgroundColor: '#101A26'
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    }
}))


export default function SelectPairFutures(props) {
    const {quote, base, handleChangeQuote,
        handleChangeBase, quoteCurrenciesNom, baseCurrenciesNom,
        userBaseBalance,
        selectPairErr,
        signalId, botId} = props
    const classes = useStyles()
    const [errorOnQuote, setErrorOnQuote] = React.useState(false)
    const [quoteHelperText, setQuoteHelperText] = React.useState(false)
    let userBaseBalance2 = brushValue(userBaseBalance)

    React.useEffect(() =>{
        if (!isValidPair(quote, base, quoteCurrenciesNom)) {
            setErrorOnQuote(true)
            setQuoteHelperText("Invalid quote or pair")
        } else {
            setErrorOnQuote(false)
            setQuoteHelperText("")
        }
    })


    function _handleChangeQuote(e) {
        if (!isValidPair(e.target.value, base, quoteCurrenciesNom)) {
            setErrorOnQuote(true)
            setQuoteHelperText("Invalid quote")
        } else {
            setErrorOnQuote(false)
            setQuoteHelperText("")
        }
        handleChangeQuote(e)
    }

    function getUserBaseBalanceColor() {
        if (userBaseBalance < 30) {
            return "#FB4A4A"
        }
    }


    return (
        <div className={"wizardSectionContainer"}>
            <div className={"wizardTitle"}>
                <span className={"wizardTitleIcon"}><SwapHoriz/></span> Pair
            </div>
            {selectPairErr && <div className={"errorContainer"} style={{marginLeft: "0.6rem"}}>{selectPairErr}</div>}
            <div style={{display: "flex"}}>
                <div style={{width: "8.6rem"}}>
                    <div>
                        <TextField variant="outlined" margin="dense"
                                   className={classes.textField}
                                   label={"Quote currency"}
                                   error={errorOnQuote}
                                   helperText={quoteHelperText}
                            value={quote} onChange={_handleChangeQuote}
                                   disabled={(signalId && signalId.length > 0) || (botId && botId.length > 0)}
                        />
                    </div>
                </div>
                <div style={{position: "relative", top: "1em", left: "0.6rem", width: "1rem"}}> / </div>
                <div style={{width: "8.6rem", display: "flex", flexDirection: "column"}}>
                    <div>
                        <TextField
                            select
                            label="Base currency"
                            className={classes.textField}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            disabled={(signalId && signalId.length > 0) || (botId && botId.length > 0)}
                            value={base}
                            onChange={handleChangeBase}
                            margin="dense"
                            variant="outlined">
                            {baseCurrenciesNom && baseCurrenciesNom.map(el => {
                                if (el == base) return (<MenuItem key={el} value={el} selected={true}>
                                    {el}
                                </MenuItem>)
                                else return (<MenuItem key={el} value={el}>
                                    {el}
                                </MenuItem>)
                            })}
                        </TextField>
                    </div>
                    {userBaseBalance >= 0 &&
                    <div style={{display: "flex", justifyContent: "flex-end", fontSize: "0.8rem", opacity: "0.8", marginLeft: "0.2rem"}}>
                        <div style={{textAlign: "center", width: "7.2rem"}}>available margin<br/>
                            <span style={{color: getUserBaseBalanceColor()}}><b>{userBaseBalance2} {base}</b></span>
                        </div>
                    </div>}
                </div>
            </div>
        </div>)
}
