import React, { useState, useEffect } from "react";
import c from '../../../constants'
import { Button, TextField } from "@material-ui/core";

import { useDispatch, useSelector } from "react-redux";
import {
  getFuturesBalances,
  sendToStake
} from "../../../redux/actions/wallet";
import { SpotCustomHeader } from "../../fncomponents/CustomHeaders";
import CircularProgress from "@material-ui/core/CircularProgress";
import {brushValue} from "../../fncomponents/UISupportFns";
import { LocalPlay } from "@material-ui/icons";

const SendToStake = (props) => {
  const { changeRenderCustomHeader } = props;

  const selectedStakingCoin = useSelector(
    (state) => state.wallet.selectedStakingCoin
  );

  const selectedBalanceCoin = useSelector(
      (state) => state.wallet.selectedBalanceCoin
  );

  const usdtSpot = useSelector((state) =>
    state.wallet.balances.find((balance) => balance.coin === "USDT")
  );

  const [stakeButtonEnabled, setStakeButtonEnabled] = useState(true)
  const [showLoading, setShowLoading] = useState(false)
  const [selectedCoin, setSelectedCoin] = useState({});
  const [stakeAmount, setStakeAmount] = useState("");
  const [stakeAmountError, setStakeAmountError] = useState(null);
  const [errorOnStaking, setErrorOnStaking] = useState("");
  const [operationCompletedSuccessfully, setOperationCompletedSuccessfully] = useState(false);


  const {coin, availableBalance, name } = selectedCoin;

  const dispatch = useDispatch();

  useEffect(() => {
    changeRenderCustomHeader(() => <SpotCustomHeader currentLevel={2}/>);
    getFuturesBalances()(dispatch);
  }, []);

  useEffect(() => {
    if (stakeAmount === "") {
      return;
    }

    if (stakeAmount > availableBalance) {
      setStakeAmountError("Insufficient balance");
    } else if (stakeAmount < 0) {
      setStakeAmountError("Amount should be positive");
    } else {
      setStakeAmountError(null);
    }
  }, [stakeAmount]);

  useEffect(() => {
    setSelectedCoin(selectedStakingCoin);

    setStakeAmount("");
  }, [selectedStakingCoin]);


    const areFieldsValidForTransfer = (isInternal = false) => {
        let valid = true
        if (stakeAmount === "") {
            setStakeAmountError("Please specify an amount")
            valid = false
        } else if (stakeAmount > availableBalance) {
            setStakeAmountError("Insufficient balance")
            valid = false
        } else if (stakeAmount < 0) {
            setStakeAmountError("Amount should be positive");
            valid = false
        } else if (parseFloat(stakeAmount) === 0) {
            setStakeAmountError("Amount should be > 0");
            valid = false
        }

        if (errorOnStaking.length > 0) {
            valid = false
        }

        return valid
    }


  if (operationCompletedSuccessfully) {
    return (<div className="transfer__form">
      <div style={{marginBottom: "1rem"}}>You funds have been moved successfully from your <b>Spot</b> Wallet to your <b>Staking</b> Wallet!</div>
      <div style={{marginBottom: "0.8rem"}}>Please check <a href={"/staking"}><LocalPlay /> Staking</a> section to finalize staking by clicking the <span className={"action"}>Stake</span>* button.</div>
      <small style={{marginTop: "1rem", opacity: "0.7"}}>* = By clicking the 'Stake' button, a staking request will be sent to our team and will be solved in the shortest amount of time.</small>
    </div>)
  } else {
    return (
      <div className="transfer__form">
        <div className="balance__top">
          <img
            src={`imgs/coins/${coin?.toUpperCase()}_${c.COIN_LOGOS_PIC_VERSION}.png`}
            className="balance__coinLogo"
            alt="icon"
          />
          <div className="balance__title">
            <span>{coin}</span>
            <span className="balance__dot"> · </span>
            <span>{name}</span>
            {" "}
            <span>Available</span>
          </div>
          <div className="balance__amount">{brushValue(availableBalance)}</div>
          <Button
            style={{
              color: "#17BEBB",
              display: "flex",
              width: "161px",
              margin: "0 auto",
              height: "30px",
              textAlign: "center",
              textTransform: "none",
            }}
            onClick={() => setStakeAmount(brushValue(availableBalance))}
          >
            Use Max.
          </Button>
        </div>
        <div className="transfer__amount">
          <TextField
            type="number"
            variant="outlined"
            placeholder="Amount to send to Stake"
            margin={"normal"}
            value={stakeAmount}
            helperText={stakeAmountError}
            onChange={(e) => setStakeAmount(e.target.value)}
            error={stakeAmountError !== null}
          />


          {errorOnStaking.length > 0 && <div style={{
            marginTop: "0.7rem", marginBottom: "1rem", width: "22rem",
            opacity: "0.9", color: "#fb4a4a", display: "flex", justifyContent: "center"
          }}>
            {errorOnStaking}</div>}
          <Button
            style={{
              backgroundColor: "#17BEBB",
              display: "flex",
              width: "161px",
              margin: "10px auto",
              borderRadius: "32px",
              height: "48px",
              textAlign: "center",
              color: "black"
            }}
            onClick={() => {
              if (!stakeButtonEnabled) return;
              if (!areFieldsValidForTransfer())
                return
              setStakeButtonEnabled(false);
              setShowLoading(true);
              sendToStake({
                  coin,
                  amount: stakeAmount,
                },
                data => {
                  setStakeButtonEnabled(true);
                  setShowLoading(false);
                  if (data.error) {
                    setErrorOnStaking(data.error)
                  } else if (data.status === "OK") {
                    setOperationCompletedSuccessfully(true)
                  }
                })(dispatch)
            }}>
            {showLoading && <CircularProgress size={18} style={{ color: "black" }} />}&nbsp;&nbsp;Send to Stake
          </Button>
          {errorOnStaking &&
            <div className={"errorGeneralMessageSmall"} style={{ margin: "auto" }}>Error completing staking...</div>}
        </div>
      </div>
    );
  }

}

export default SendToStake
