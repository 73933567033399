import React, {Component} from 'react'
import {connect} from "react-redux"
import {Helmet} from "react-helmet/es/Helmet"
import {getTitle} from "../fncomponents/PageTitleUtils"
import ApplyForTraderForm from "../fncomponents/ApplyForTraderForm"
import {applyForTrader, getUserTraderApplication} from "../../redux/actions/traders"
import {ChevronLeft} from "@material-ui/icons"
import Button from "@material-ui/core/Button"
import {Redirect} from "react-router-dom"

class ApplyForTrader extends Component {


    constructor(props) {
        super(props)
        this.state = {
            _id: undefined,
            about: "",
            telegramChannel: "",
            website: "",
            followers: 0
        }
        this.handleChangeAbout = this.handleChangeAbout.bind(this)
        this.handleChangeTelegramChannel = this.handleChangeTelegramChannel.bind(this)
        this.handleChangeWebsite = this.handleChangeWebsite.bind(this)
        this.handleChangeFollowers = this.handleChangeFollowers.bind(this)

        this.apply = this.apply.bind(this)
    }

    handleChangeAbout(e) {
        this.setState({
            about: e.target.value
        })
    }

    handleChangeTelegramChannel(e) {
        this.setState({
            telegramChannel: e.target.value
        })
    }

    handleChangeWebsite(e) {
        this.setState({
            website: e.target.value
        })
    }

    handleChangeFollowers(e) {
        this.setState({
            followers: e.target.value
        })
    }

    apply() {
        this.props.applyForTrader({
            _id: this.state._id,
            about: this.state.about, telegramChannel: this.state.telegramChannel,
            website: this.state.website, followers: this.state.followers
        }, (response) => {
            if (response) {
                if (response.status === "OK") {
                    this.setState({
                        applicationSaved: true,
                        applicationNotSaved: false
                    })
                } else if (response.error) {
                    this.setState({
                        applicationSaved: false,
                        applicationNotSaved: true
                    })
                }
            }

        })
    }

    componentDidMount() {
        this.props.getUserTraderApplication(userTraderApp => {
            if (userTraderApp && userTraderApp._id) {
                this.setState({
                    _id: userTraderApp._id,
                    about: userTraderApp.about,
                    telegramChannel: userTraderApp.telegramChannel,
                    website: userTraderApp.website,
                    followers: userTraderApp.followers
                })
            }
        })
    }

    render() {
        if (this.state.applicationSaved) {
            return <Redirect to="/myprofile"/>
        }
        return (<div className={"TradeContent"} style={{display: "flex"}}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <div>
            <Button onClick={()=>{this.props.history.push("/myprofile")}} color="primary" variant="outlined"><ChevronLeft/> My Profile</Button>
            <ApplyForTraderForm
                _id={this.state._id}
                applicationSaved={this.state.applicationSaved}
                applicationNotSaved={this.state.applicationNotSaved}
                about={this.state.about}
                handleChangeAbout={this.handleChangeAbout}
                telegramChannel={this.state.telegramChannel}
                handleChangeTelegramChannel={this.handleChangeTelegramChannel}
                website={this.state.website}
                handleChangeWebsite={this.handleChangeWebsite}
                followers={this.state.followers}
                handleChangeFollowers={this.handleChangeFollowers}
                apply={this.apply}
            />
            </div>
        </div>)
    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    applyForTraderApplication: rootState.traders.applyForTraderApplication,
})

let mapDispatchToProps = dispatch => {
    return {
        applyForTrader: (props, cb) => dispatch(applyForTrader(props, cb)),
        getUserTraderApplication: (cb) => dispatch(getUserTraderApplication(cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ApplyForTrader)
