import ListItem from "@material-ui/core/ListItem"
import {Link as RouterLink} from "react-router-dom"
import Divider from "@material-ui/core/Divider"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import {
    AccountBalanceWallet,
    AccountCircle,
    Polymer,
    BlurCircular,
    PowerSettingsNew,
    People,
    EuroSymbol,
    SettingsInputAntenna,
    Android,
    CompareArrows,
    CallToAction,
    MonetizationOn, LocalPlay, Euro, DonutLarge,
} from "@material-ui/icons"
import ListItemText from "@material-ui/core/ListItemText"
import List from "@material-ui/core/List"
import React from "react"
import c from '../../constants'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import UserPermissions from "../fncomponents/UserPermissions"
import {addCommasToLeftSideOfTheNumber, brushValue} from "../fncomponents/UISupportFns"
import WHALogo from "../fncomponents/WHALogo";


function menuItem(name, url, location) {
    if (location === url)
        return <ListItemText primary={<span className={"menuSelected"}>{name}</span>}/>
    else
        return <ListItemText primary={<span className={"menuNormal"}>{name}</span>}/>
}

export default function DrawerContent(props) {
    const {isSuperAdmin, isTrader, user, isAuthenticated,
        handleLogout, isMobile, toggleDrawer, location, totals,
        markets, handleChangeMarkets, handleExpandTraderPanel, traderPanelExpanded} = props

    function getTraderPanelExpandedState() {
        if (traderPanelExpanded === undefined)
            return true
        return traderPanelExpanded
    }
    return (
        <List>
        <ListItem>
            <RouterLink to={'/'}>
                <WHALogo/>
            </RouterLink>
        </ListItem>
        {isAuthenticated &&
        <div style={{marginTop: "-.6rem"}}>
            <ListItem>
                <div style={{marginLeft: "0.3rem"}}>
                    <div>{user.firstName} {user.lastName}</div>
                    <UserPermissions permissions={user.permissions} size={"small"}/>
                    <div style={{fontSize: "0.7rem"}}>{user.email}</div>
                </div>
            </ListItem>
            <Divider />
            {totals && <div><ListItem>
                {!totals.futures &&<div style={{width: "100%", marginLeft: "0.3rem", fontSize: "0.8rem"}}>
                    <div style={{fontSize: "0.6rem", opacity: "0.5"}}>Spot Balance</div>
                    <div><span style={{fontSize: "1.3rem", fontWeight: "bold"}}>{brushValue(totals.BTC, 6)}</span> BTC</div>
                    <div><span style={{color:"gray", fontSize: "0.72rem"}}>= {addCommasToLeftSideOfTheNumber(totals.USDT)} USDT</span></div>
                </div>}
                {totals.futures &&<div style={{width: "100%", marginLeft: "0.3rem", fontSize: "0.8rem"}}>
                    <div style={{fontSize: "0.6rem", opacity: "0.5"}}>Futures Balance</div>
                    <div><span style={{fontSize: "1.3rem", fontWeight: "bold"}}>{brushValue(totals.USDT, 2)}</span> USDT</div>
                </div>}
            </ListItem>
            <Divider />
            </div>}
            <div style={{width: "6rem", marginLeft: "1rem", marginTop: "0.6rem", marginBottom: "0.6rem"}}>
                <span>
                    {markets === c.MARKETS_SPOT && <a href={"#"} className={"spotOrFuturesSelected"}>Spot</a>}
                    {markets !== c.MARKETS_SPOT && <a href={"#"} className={"spotOrFutures"} onClick={() => handleChangeMarkets(c.MARKETS_SPOT)}>Spot</a>}
                </span>
                <span style={{marginLeft: "0.6rem"}}>
                    {markets === c.MARKETS_FUTURES && <a href={"#"} className={"spotOrFuturesSelected"}>Futures</a>}
                    {markets !== c.MARKETS_FUTURES && <a href={"#"} className={"spotOrFutures"} onClick={() => handleChangeMarkets(c.MARKETS_FUTURES)}>Futures</a>}
                </span>
            </div>
            {markets === c.MARKETS_SPOT &&
            <div>
                <RouterLink to={'/'} style={{color: "inherit", textDecoration: "none"}} onClick={()=>{if (isMobile) toggleDrawer()}}>
                    <ListItem button key={"Trades"}>
                        <ListItemIcon><BlurCircular /></ListItemIcon>
                        {menuItem("Trades", "/", location)}
                    </ListItem>
                </RouterLink>
               <RouterLink to={'/newtrade'} style={{color: "#3BADFF", textDecoration: "none"}} onClick={()=>{if (isMobile) toggleDrawer()}}>
                    <ListItem button key={"New Trade"}>
                        <ListItemIcon><Polymer style={{color: "#3BADFF"}}/></ListItemIcon>
                        {menuItem("New Trade", "/newtrade", location)}
                    </ListItem>
                </RouterLink>
                <RouterLink to={'/converter'} style={{color: "inherit", textDecoration: "none"}} onClick={()=>{if (isMobile) toggleDrawer()}}>
                    <ListItem button key={"Convertor"}>
                        <ListItemIcon><CompareArrows/></ListItemIcon>
                        {menuItem("Convertor", "/converter", location)}
                    </ListItem>
                </RouterLink>
                <RouterLink to={'/sucs'} style={{color: "inherit", textDecoration: "none"}} onClick={()=>{if (isMobile) toggleDrawer()}}>
                    <ListItem button key={"Signals"}>
                        <ListItemIcon><SettingsInputAntenna/></ListItemIcon>
                        {menuItem("Signals", "/sucs", location)}
                    </ListItem>
                </RouterLink>
                <RouterLink to={'/bots'} style={{color: "inherit", textDecoration: "none"}} onClick={()=>{if (isMobile) toggleDrawer()}}>
                    <ListItem button key={"Bots"}>
                        <ListItemIcon><Android/></ListItemIcon>
                        {menuItem("Bots", "/bots", location)}
                    </ListItem>
                </RouterLink>
            </div>}

            {markets === c.MARKETS_FUTURES &&
            <div>
                <RouterLink to={'/futures'} style={{color: "inherit", textDecoration: "none"}} onClick={()=>{if (isMobile) toggleDrawer()}}>
                    <ListItem button key={"Futures"}>
                        <ListItemIcon><BlurCircular /></ListItemIcon>
                        {menuItem("Futures", "/futures", location)}
                    </ListItem>
                </RouterLink>
                <RouterLink to={'/newfutures'} style={{color: "#3BADFF", textDecoration: "none"}} onClick={()=>{if (isMobile) toggleDrawer()}}>
                    <ListItem button key={"Futures"}>
                        <ListItemIcon><Polymer style={{color: "#3BADFF"}}/></ListItemIcon>
                        {menuItem("New Futures", "/newfutures", location)}
                    </ListItem>
                </RouterLink>
                <RouterLink to={'/sucs'} style={{color: "inherit", textDecoration: "none"}} onClick={()=>{if (isMobile) toggleDrawer()}}>
                    <ListItem button key={"Signals"}>
                        <ListItemIcon><SettingsInputAntenna/></ListItemIcon>
                        {menuItem("Signals", "/sucs", location)}
                    </ListItem>
                </RouterLink>
            </div>}
            {(user.group !== 5)&& <>
            <Divider />
                <RouterLink to={'/staking'} style={{color: "inherit", textDecoration: "none"}} onClick={()=>{if (isMobile) toggleDrawer()}}>
                <ListItem button key={"Staking"}>
                    <ListItemIcon><LocalPlay /></ListItemIcon>
                    {menuItem("Staking", "/staking", location)}
                </ListItem>
            </RouterLink>
            <RouterLink to={'/contributions'} style={{color: "inherit", textDecoration: "none"}} onClick={()=>{if (isMobile) toggleDrawer()}}>
                <ListItem button key={"Contributions"}>
                    <ListItemIcon><DonutLarge /></ListItemIcon>
                    {menuItem("Contributions", "/contributions", location)}
                </ListItem>
            </RouterLink>
            <Divider />
            </>}
            {isSuperAdmin &&
            <RouterLink to={'/buycrypto'} style={{color:"#FE484F", textDecoration: "none"}} onClick={()=>{if (isMobile) toggleDrawer()}}>
                <ListItem button key={"Buy crypto"}>
                    <ListItemIcon><EuroSymbol style={{color:"#FE484F"}}/></ListItemIcon>
                    {menuItem("Buy crypto", "/buycrypto", location)}
                </ListItem>
            </RouterLink>}
        </div>}

        {isTrader && <ExpansionPanel expanded={getTraderPanelExpandedState()}>
            <ExpansionPanelSummary
                onClick={handleExpandTraderPanel}
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"><span style={{color: "white", fontWeight: "bold"}}>Trader</span></ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <div style={{display: "flex", flexDirection:"column"}}>
                    <RouterLink to={'/signals'} style={{color: "inherit", textDecoration: "none"}} onClick={()=>{if (isMobile) toggleDrawer()}}>
                        <ListItem button key={"Signals"}>
                            <ListItemIcon><SettingsInputAntenna /></ListItemIcon>
                            {menuItem("Signals", "/signals", location)}
                        </ListItem>
                    </RouterLink>
                    {/*<RouterLink to={'/signals'} style={{color: "inherit", textDecoration: "none"}} onClick={()=>{if (isMobile) toggleDrawer()}}>*/}
                    {/*    <ListItem button key={"Signals Old"}>*/}
                    {/*        <ListItemIcon><SettingsInputAntenna /></ListItemIcon>*/}
                    {/*        {menuItem("Signals Old", "/signals", location)}*/}
                    {/*    </ListItem>*/}
                    {/*</RouterLink>*/}
                    <RouterLink to={'/trader/mypage'} style={{color: "inherit", textDecoration: "none"}} onClick={()=>{if (isMobile) toggleDrawer()}}>
                        <ListItem button key={"My Page"}>
                            <ListItemIcon><CallToAction /></ListItemIcon>
                            {menuItem("My Page", "/trader/mypage", location)}
                        </ListItem>
                    </RouterLink>
                    <RouterLink to={'/trader/followers'} style={{color: "inherit", textDecoration: "none"}} onClick={()=>{if (isMobile) toggleDrawer()}}>
                        <ListItem button key={"Followers"}>
                            <ListItemIcon><People /></ListItemIcon>
                            {menuItem("Followers", "/trader/followers", location)}
                        </ListItem>
                    </RouterLink>
                    <RouterLink to={'/trader/payments'} style={{color: "inherit", textDecoration: "none"}} onClick={()=>{if (isMobile) toggleDrawer()}}>
                        <ListItem button key={"payments"}>
                            <ListItemIcon><MonetizationOn /></ListItemIcon>
                            {menuItem("Payments", "/trader/payments", location)}
                        </ListItem>
                    </RouterLink>
                </div>
            </ExpansionPanelDetails>
        </ExpansionPanel>}
        {isAuthenticated &&
        <div>
            <Divider />
            <RouterLink to={'/wallets'} style={{color: "inherit", textDecoration: "none"}} onClick={()=>{if (isMobile) toggleDrawer()}}>
                <ListItem button key={"Wallets"}>
                    <ListItemIcon><AccountBalanceWallet /></ListItemIcon>
                    {menuItem("Wallets", "/wallets", location)}
                </ListItem>
            </RouterLink>
            <RouterLink to={'/myprofile'} style={{color: "inherit", textDecoration: "none"}} onClick={()=>{if (isMobile) toggleDrawer()}}>
                <ListItem button key={"My Profile"}>
                    <ListItemIcon><AccountCircle /></ListItemIcon>
                    {menuItem("My Profile", "/myprofile", location)}
                </ListItem>
            </RouterLink>
            <ListItem button key={"Logout"} onClick={handleLogout}>
                <ListItemIcon><PowerSettingsNew /></ListItemIcon>
                <ListItemText primary={"Logout"} />
            </ListItem>
        </div>
        }
    </List>
    )
}
