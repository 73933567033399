import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import {
    openTrade, updateTradeConditions, updateTradeExecuteTakeProfit,
    updateTradeCancelIt, updateTradeAsOrderDiscardIt
} from "../../../redux/actions/trades"
import {connect} from "react-redux"
import {Clear, ArrowBack} from "@material-ui/icons"
import {
    brushValue, formatTimeDuration, getCummulativeQuoteQty,
    getCurrentBuyAndSellPrice, getExecutedQtyAndCummulativeQuoteQty, getExecutedQtyAtInput, getExecutedQtyAtOutput,
    getMaxMinAndDeltaPercent, getPosSize,
    getQuoteAndBase, getReceivedPosSize,
    getTradeConditionsContentForTradeDetails, getTradeEnterVerb, getTradeExitVerb,
    getTradeProfitOrLoss,
    getTradeState, hasTradeChild,
    timeFormatter,
} from "../../fncomponents/UISupportFns";
import {Paper} from "@material-ui/core";
import c from "../../../constants";
import Fade from '@material-ui/core/Fade';
import TradeDetailsMainButtons from "../../fncomponents/TradeDetailsMainButtons";
import DiscardItem from "../../fncomponents/DiscardItem";
import {getTitle} from "../../fncomponents/PageTitleUtils";
import {Helmet} from "react-helmet/es/Helmet";



class OrderSummary extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isTradeDetailsPanelOpen: true,
            isDiscardTradePanelOpen: false,
            goBack: false,
        }

        this.goBack = this.goBack.bind(this)
        this.openDiscardTradePanel = this.openDiscardTradePanel.bind(this)
        this.closeDiscardTradePanel = this.closeDiscardTradePanel.bind(this)
        this.discardTrade = this.discardTrade.bind(this)
    }

    goBack() {
        this.setState({
            goBack: true
        })
    }

    openDiscardTradePanel() {
        this.setState({
            isDiscardTradePanelOpen: true,
            isTradeDetailsPanelOpen: false
        })
    }

    closeDiscardTradePanel() {
        this.setState({
            isDiscardTradePanelOpen: false,
            isTradeDetailsPanelOpen: true,
        })
    }

    discardTrade() {
        this.state.isDiscardTradePanelOpen = false
        this.state.isTradeDetailsPanelOpen = true
        this.props.updateTradeAsOrderDiscardIt({tradeId: this.props.tradeAsOrder.order_id})
    }

    render() {

        if (this.state.goBack)
            return <Redirect to="/"/>

        let {tradeAsOrder} = this.props
        if (!tradeAsOrder)
            return <Redirect to="/"/>

        const {quote, base} = getQuoteAndBase(tradeAsOrder)
        const ACTION_TYPE = tradeAsOrder.action_type
        const verb = getTradeEnterVerb(tradeAsOrder)
        const {maxPrice, maxDelta, minPrice, minDelta} = getMaxMinAndDeltaPercent(tradeAsOrder)
        const tradeState = getTradeState(tradeAsOrder)
        const tradeProfitOrLoss = getTradeProfitOrLoss(tradeAsOrder)
        const posSize = getPosSize(tradeAsOrder)
        const origQty = brushValue(tradeAsOrder.orig_qty, tradeAsOrder.precision)
        const {buyPrice, sellPrice} = getCurrentBuyAndSellPrice(tradeAsOrder)
        const {executedQty, cummulativeQuoteQty} = getExecutedQtyAndCummulativeQuoteQty(tradeAsOrder)

        return <div className={"TradeContent"} style={{display: "flex"}}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <div style={{maxWidth: "30rem", minWidth: "22rem"}}>
                {this.state.isTradeDetailsPanelOpen &&
                <Fade in={this.state.isTradeDetailsPanelOpen}>
                    <Paper style={{backgroundColor: "#101926"}}>
                        <div className={"tradeDetailsTopBar"}>
                            <div className={"tradeDetailsTopBarVerticalCenter"}>
                                <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.goBack()}}><ArrowBack/></div>
                                <div className={"tradeDetailsTopBarTitle"}>Order <span style={{opacity: "0.5"}}>{tradeAsOrder.order_id}</span> summary </div>
                                <div style={{width: "2.6rem"}}/>
                            </div>
                        </div>

                        <div className={"tradeDetailsPair"} >
                            {quote} <span className={"tradeDetailsPairSlash"}>/</span> {base}
                        </div>
                        <div className={"vertSpacer1"}/>
                        {(tradeState === c.TRADE_STATE_WITH_CHILD_FILLED || tradeState === c.TRADE_STATE_WITH_CHILD_CANCELED) &&
                        <div>
                            {tradeProfitOrLoss.profit &&
                            <div>
                                <div style={{color: "#64FE7C", textAlign: "center"}}>You made ↑</div>
                                <div style={{color: "#64FE7C", textAlign: "center", fontSize: "1.6rem", lineHeight: "1.8rem"}}>
                                    +{tradeProfitOrLoss.deltaPercent}% <small>{tradeProfitOrLoss.deltaValue}&nbsp;
                                    {ACTION_TYPE === c.ACTION_TYPE_BUY && <span>{base}</span>}
                                    {ACTION_TYPE === c.ACTION_TYPE_SELL && <span>{quote}</span>}</small>
                                </div>
                            </div>
                            }
                            {tradeProfitOrLoss.loss &&
                            <div>
                                <div style={{color: "#FB4A4A", textAlign: "center"}}>You lost ↓</div>
                                <div style={{color: "#FB4A4A", textAlign: "center", fontSize: "1.6rem", lineHeight: "1.8rem"}}>
                                    {tradeProfitOrLoss.deltaPercent}% <small>{tradeProfitOrLoss.deltaValue}&nbsp;
                                    {ACTION_TYPE === c.ACTION_TYPE_BUY && <span>{base}</span>}
                                    {ACTION_TYPE === c.ACTION_TYPE_SELL && <span>{quote}</span>}
                                </small></div>
                            </div>
                            }
                        </div>
                        }
                        <div className={"vertSpacer1"}/>
                        {tradeAsOrder.last_action === c.TRADE_OP_AUTO_TAKE_PROFIT &&
                        <div style={{textAlign: "center"}}>
                            <span>by <b>Taking profit</b> (auto)</span><br/>
                        </div>
                        }
                        {tradeAsOrder.last_action === c.TRADE_OP_AUTO_TAKE_LOSS &&
                        <div style={{textAlign: "center"}}>
                            <span>by <b>Stopping the loss</b> (auto)</span><br/>
                        </div>
                        }
                        {tradeAsOrder.last_action === c.TRADE_OP_USER_TAKES_PROFIT &&
                        <div style={{textAlign: "center"}}>
                            <span>by <b>Taking profit</b> (by you)</span><br/>
                        </div>
                        }
                        {(tradeState === c.TRADE_STATE_WITH_CHILD_CANCELED || tradeState === c.TRADE_STATE_WITH_CHILD_CANCELED_AND_UNFULFILLED ||
                            tradeState === c.TRADE_STATE_PARENT_CANCELED) &&
                        <div style={{color: "#E8E288", textAlign: "center", fontSize: "1.6rem", lineHeight: "1.8rem"}}>
                            Canceled
                        </div>
                        }
                        {tradeState === c.TRADE_STATE_PARENT_IN_ERROR &&
                        <div>
                            <div style={{color: "#FB4A4A", textAlign: "center", fontSize: "1.6rem", lineHeight: "1.8rem"}}>
                                Error
                            </div>
                            <div style={{textAlign: "center", width: "22rem"}}>
                                {tradeAsOrder.error}
                            </div>
                        </div>
                        }
                        {tradeState === c.TRADE_STATE_WITH_CHILD_IN_ERROR &&
                        <div>
                            <div style={{color: "#FB4A4A", textAlign: "center", fontSize: "1.6rem", lineHeight: "1.8rem"}}>
                                Error
                            </div>
                            <div style={{textAlign: "center", width: "22rem"}}>
                                {tradeAsOrder.child_order.error}
                            </div>
                        </div>
                        }
                        <div className={"vertSpacer2"}/>
                        <div className={"tradeDetailsCreationDate"} style={{fontSize: "0.9rem", textAlign: "center"}}>
                            <span title={"Created on"}>{timeFormatter(tradeAsOrder.time, true)}</span>
                            {(tradeState !== c.TRADE_STATE_PARENT_CANCELED && tradeState !== c.TRADE_STATE_PARENT_IN_ERROR) && <span title={"Closed on"}>&nbsp;→&nbsp;{timeFormatter(tradeAsOrder.last_action_time, true)}</span>}
                            <br/>
                            {(tradeState !== c.TRADE_STATE_PARENT_CANCELED && tradeState !== c.TRADE_STATE_PARENT_IN_ERROR) && <span title={"Duration"}>{formatTimeDuration(tradeAsOrder.time, tradeAsOrder.last_action_time)}</span>}
                        </div>
                        {(ACTION_TYPE === c.ACTION_TYPE_BUY && tradeAsOrder.status !== "ERROR") &&
                        <div className={"tradeDetailsPosAndQty"}>
                            <b>{posSize} {base}</b> {verb} <small>{origQty} {quote}</small> at price <b>{buyPrice}</b><br/>
                        </div>
                        }
                        {(ACTION_TYPE === c.ACTION_TYPE_BUY && tradeAsOrder.status === "ERROR") &&
                        <div className={"tradeDetailsPosAndQty"}>
                            <b>{cummulativeQuoteQty} {base}</b> {verb} <small>{executedQty} {quote}</small> at price <b>{buyPrice}</b><br/>
                        </div>
                        }
                        {(ACTION_TYPE === c.ACTION_TYPE_SELL && tradeAsOrder.status !== "ERROR") &&
                        <div className={"tradeDetailsPosAndQty"}>
                            <b>{posSize} {quote}</b> {verb} for {cummulativeQuoteQty} <small>{base}</small> at price <b>{sellPrice}</b><br/>
                        </div>
                        }
                        {(ACTION_TYPE === c.ACTION_TYPE_SELL && tradeAsOrder.status === "ERROR") &&
                        <div className={"tradeDetailsPosAndQty"}>
                            <b>{executedQty} {quote}</b> {verb} for {cummulativeQuoteQty} <small>{base}</small> at price <b>{sellPrice}</b><br/>
                        </div>
                        }


                        <div className={"vertSpacer"}/>
                        <div className={"tradeDetailsCreationDate"}>
                            <div><b>During order</b></div>
                            {maxPrice > 0 && <div className={"maxPriceContainer2"}>max price {maxDelta}% · {maxPrice}</div>}
                            {minPrice > 0 && <div className={"minPriceContainer2"}>min price {minDelta}% · {minPrice}</div>}
                        </div>
                        <div className={"vertSpacer2"}/>
                        <div style={{padding: "0.6rem"}}>
                            <TradeDetailsMainButtons trade={tradeAsOrder} discardTrade={this.openDiscardTradePanel} state={tradeState}/>
                        </div>
                    </Paper>
                </Fade>}
                {this.state.isDiscardTradePanelOpen &&
                <Paper style={{backgroundColor: "#101926"}}>
                    <div className={"tradeDetailsTopBar"}>
                        <div className={"tradeDetailsTopBarVerticalCenter"}>
                            <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeDiscardTradePanel()}}><Clear/></div>
                            <div className={"tradeDetailsTopBarTitle"}>Discard trade</div>
                            <div style={{width: "2.6rem"}}/>
                        </div>
                    </div>
                    <div className={"tradeDetailsTradeConditionsPaper"}>
                        <DiscardItem discardItem={this.discardTrade} nevermind={this.closeDiscardTradePanel}
                                     question={"Are you sure you want to discard this trade?"}
                                     discardWarningText={"Discarding this trade will remove it from your lists."}/>
                    </div>
                </Paper>}
            </div>
        </div>
    }
}


let mapStateToProps = rootState =>({
    tradeAsOrder: rootState.trades.openedOrder,
})

let mapDispatchToProps = dispatch => {
    return {
        openTrade: (trade) => dispatch(openTrade(trade)),
        updateTradeConditions: (props) => dispatch(updateTradeConditions(props)),
        updateTradeExecuteTakeProfit: (props) => dispatch(updateTradeExecuteTakeProfit(props)),
        updateTradeCancelIt: (props) => dispatch(updateTradeCancelIt(props)),
        updateTradeAsOrderDiscardIt: (props) => dispatch(updateTradeAsOrderDiscardIt(props))
    }
}


export default connect(mapStateToProps, mapDispatchToProps) (OrderSummary)
