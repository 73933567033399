import {
    USERS_GROUPS_GET_ALL_SIMPLE,
} from "../actions/actionTypes";

export default function (state = {docs: [], totalDocs: 0, totalPages: 0, pagesDownloaded: {}, totals: {}}, action) {
    switch (action.type) {

        case USERS_GROUPS_GET_ALL_SIMPLE: {
            let usersGroups = action.payload
            let usersGroupsHash = {}
            for(let i = 0; i < usersGroups.length; i++) {
                usersGroupsHash[usersGroups[i]._id] = usersGroups[i]
            }
            let newState =  {
                ...state,
                ...{usersGroups: usersGroups, usersGroupsHash: usersGroupsHash}
            }

            return newState
        }

        default:
            return state
    }
}
