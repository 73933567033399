import c from '../../constants'
import {
    STAKING_GET_BALANCES,
    STAKING_CHANGE_TAB,
    STAKING_GET_STAKING_REWARDS_ALL,
    STAKING_GET_STAKING_REWARDS_INITIAL,
    STAKING_GET_STAKING_OPERATIONS_ALL,
    STAKING_GET_STAKING_OPERATIONS_INITIAL,
    STAKING_STAKE,
    STAKING_UNSTAKE,
    STAKING_GET_PROGRAMS,
} from "../actions/actionTypes"

export default function (state = {selectedTab: c.STAKES_STAKES_TAB, stakingRewards: {docs: []}, stakingOperations: {docs: []}}, action) {
    switch (action.type) {

        case STAKING_CHANGE_TAB: {
            return {
                ...state,
                selectedTab: action.payload
            }
        }

        case STAKING_GET_BALANCES: {
            return {
                ...state,
                balances: action.payload.balances,
            }
        }

        case STAKING_GET_STAKING_REWARDS_ALL: {
            return {
                ...state,
                ...{
                    stakingRewards: {
                        docs: [...state.stakingRewards.docs, ...action.payload.docs],
                        totalDocs: action.payload.totalDocs,
                        totalPages: action.payload.totalPages,
                        pagesDownloaded: {...state.stakingRewards.pagesDownloaded, ...{[action.payload.page]: true}}
                    }
                }
            }
        }

        case STAKING_GET_STAKING_REWARDS_INITIAL: {
            return {
                ...state,
                ...{
                    stakingRewards: {
                        docs: action.payload.docs,
                        totalDocs: action.payload.totalDocs,
                        totalPages: action.payload.totalPages,
                        pagesDownloaded: {[action.payload.page]: true}
                    }
                }
            }
        }

        case STAKING_GET_STAKING_OPERATIONS_ALL: {
            return {
                ...state,
                ...{
                    stakingOperations: {
                        docs: [...state.stakingOperations.docs, ...action.payload.docs],
                        totalDocs: action.payload.totalDocs,
                        totalPages: action.payload.totalPages,
                        pagesDownloaded: {...state.stakingOperations.pagesDownloaded, ...{[action.payload.page]: true}}
                    }
                }
            }
        }

        case STAKING_GET_STAKING_OPERATIONS_INITIAL: {
            return {
                ...state,
                ...{
                    stakingOperations: {
                        docs: action.payload.docs,
                        totalDocs: action.payload.totalDocs,
                        totalPages: action.payload.totalPages,
                        pagesDownloaded: {[action.payload.page]: true}
                    }
                }
            }
        }

        case STAKING_STAKE: {
            return {
                ...state,
                ...{
                    staking: "ok"
                }
            }
        }

        case STAKING_UNSTAKE: {
            return {
                ...state,
                ...{
                    staking: "ok"
                }
            }
        }

        case STAKING_GET_PROGRAMS: {
            return {
                ...state,
                ...{
                    programs: action.payload
                }
            }
        }

        default:
            return state
    }
}
