import {isAuthorizationHeaderPresent} from "./authentication"
import axios from "./axiosFabric"
import {
    CHECK_USER_BALANCE_FOR_FUTURES,
    CLEAR_NOT_ENOUGH_BALANCE_FOR_FUTURES,
    GET_CURRENCIES_MAP_FOR_FUTURES,
    GET_CURRENT_PRICE_FOR_FUTURES,
    GET_LAST_USED_PARAMS_ON_NEW_FUTURES,
    GET_USER_BASE_BALANCE_FOR_FUTURES,
    GET_USER_LEVERAGE,
    GET_USER_QUOTE_BALANCE_FOR_FUTURES,
    LAUNCH_NEW_FUTURES,
    LAUNCH_NEW_FUTURES_ERROR,
    PREVIEW_NEW_FUTURES,
    SET_CURRENT_TAB_WAITING_TRADES,
    UPDATE_USER_LEVERAGE
} from "./actionTypes";
import {handleError} from "./errorHandler"
import {clearErrors} from "./errors";


export const getCurrenciesMap = props => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post("/newfutures/getcurrenciesmap")
        .then(res => {
            // let baseCurrencies = Object.keys(res.data)
            let baseCurrencies = ["USDT"]
            dispatch({
                type: GET_CURRENCIES_MAP_FOR_FUTURES,
                payload: {
                    baseCurrenciesNom: baseCurrencies,
                    quoteCurrenciesNom: res.data
                }
            })

        })
        .catch(error => handleError(error, dispatch))
}

export const getLastUsedParamsOnNewFutures = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post("/newfutures/getlastusedparamsonnewfutures")
        .then(res => {
            if (cb) cb(res.data)
            dispatch({
                type: GET_LAST_USED_PARAMS_ON_NEW_FUTURES,
                payload: res.data
            })
        })
        .catch(error => handleError(error, dispatch))
}

export const previewNewFutures = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    let now = new Date().getTime()
    axios.post(`/newfutures/previewnewfutures/${now}`, props)
        .then(() => {
            //Attention below there are props, not res.data
            dispatch({
                type: PREVIEW_NEW_FUTURES,
                payload: props
            })
            if (cb) cb()
        })
        .catch(error => handleError(error, dispatch))
}

export const getCurrentPriceOnPair = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post("/newfutures/getcurrentpriceonpair", props)
        .then(res => {
            if (cb) {
                cb(res.data)
            }
            dispatch({
                type: GET_CURRENT_PRICE_FOR_FUTURES,
                payload: {
                    currentLastBidPrice: res.data.current_price,
                    precision: res.data.tick_size,
                }
            })
        })
        .catch(error => handleError(error, dispatch))
}

export const checkUserBalance = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    clearErrors()
    axios.post("/newfutures/checkuserbalance", props)
        .then(res => {
            if (cb) cb(res.data.result)
            dispatch({
                type: CHECK_USER_BALANCE_FOR_FUTURES,
                payload: res.data
            })
        })
        .catch(error => handleError(error, dispatch))
}

export const getUserBaseBalance = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post("/newfutures/getuserbalance", props)
        .then(res => {
            if (cb)
                cb(res.data)
            dispatch({
                type: GET_USER_BASE_BALANCE_FOR_FUTURES,
                payload: res.data
            })
        })
        .catch(error => handleError(error, dispatch))
}

export const getSymbolPrecisionAndTickSize = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post("/newfutures/getsymbolprecisionandticksize", props)
        .then(res => {
            if (cb) cb(res.data)
        })
        .catch(error => handleError(error, dispatch))
}

export const clearNotEnoughBalance = () => dispatch => {
    dispatch({
        type: CLEAR_NOT_ENOUGH_BALANCE_FOR_FUTURES,
    })
}

export const updateUserLeverage = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post("/profile/futures/updateuserleverage", props)
        .then(res => {
            if (cb) cb(res.data)
            dispatch({
                type: UPDATE_USER_LEVERAGE,
                payload: res.data
            })
        })
        .catch(error => handleError(error, dispatch))
}

export const getUserLeverage = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post("/profile/futures/getuserleverage", props)
        .then(res => {
            if (cb) cb(res.data)
            dispatch({
                type: GET_USER_LEVERAGE,
                payload: res.data
            })
        })
        .catch(error => handleError(error, dispatch))
}

export const launchNewFutures = (cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    let now = new Date().getTime()
    axios.post(`/newfutures/launchnewfutures/${now}`)
        .then(res => {
            if (cb) cb()
            dispatch({
                type: LAUNCH_NEW_FUTURES
            })
            dispatch({
                type: SET_CURRENT_TAB_WAITING_TRADES
            })
        })
        .catch(error => {
            handleError(error, dispatch, LAUNCH_NEW_FUTURES_ERROR)
        })
}
