import React, {Component} from 'react'
import { connect } from 'react-redux'
import NavTabs from '../../fncomponents/NavTabs'
import StakingWallet from './StakingWallet'
import StakingRewards from './StakingRewards'
import StakingOperations from './StakingOperations'
import StakingPrograms from './StakingPrograms'
import {
    changeTab,
} from "../../../redux/actions/staking"
import {getTitle} from "../../fncomponents/PageTitleUtils";
import {Helmet} from "react-helmet/es/Helmet";




class StakingSection extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedTab: 0,
        }

        this.changeTab = this.changeTab.bind(this)
    }

    changeTab(event, newValue) {
        if (this.props.selectedTab === newValue)
            return

        this.props.changeTab(newValue)
    }

    render() {
        let tabs = []
        tabs.push({title: "Wallet", content: <StakingWallet/>})
        tabs.push({title: "Rewards", content: <StakingRewards/>})
        tabs.push({title: "Operations", content: <StakingOperations/>})
        tabs.push({title: "Staking Programs", content: <StakingPrograms/>})

        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <NavTabs tabs={tabs} selectedTab={this.props.selectedTab}
                     changeTab={this.changeTab}
                     tabContainerClass={"tabsContainer"}/>
        </div>
    }
}

const mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    selectedTab: rootState.staking.selectedTab,
})

const mapDispatchToProps = dispatch => {
    return {
        changeTab: (tab) => dispatch(changeTab(tab)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (StakingSection)
