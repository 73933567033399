import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getTitle} from "../../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"
import Button from "@material-ui/core/Button"
import NavTabs from "../../fncomponents/NavTabs"
import Badge from '@material-ui/core/Badge'
import ActiveBots from "./ActiveBots"
import StoppedBots from "./StoppedBots"
import {
    stopBot,
    clearCurrentBot,
    getStoppedBotsByPage,
    getStoppedBotsInitial,
    getActiveBotsByPage,
    getActiveBotsInitial,
    selectTab
} from "../../../redux/actions/bots"
import {debounce} from "../../hoc/myUtils"
import {isUserSuperAdmin} from "../../../redux/actions/authentication"
import AlertDialog from "../../fncomponents/AlertDialog"

const ACTIVE_BOTS_TAB = 0
const STOPPED_BOTS_TAB = 1

const DEBOUNCE_FILTER_DELAY = 500

class Bots extends Component {

    constructor(props) {
        super(props)
        this.state = {
            //Active Bots
            ab_page: 0,
            ab_rowsPerPage: 10,
            ab_filtersMap: {},
            ab_sort: {},
            //Stopped Bots
            sb_page: 0,
            sb_rowsPerPage: 10,
            sb_filtersMap: {},
            sb_sort: {},
            stopBotDialogOpen: false,
        }

        this.changeTab = this.changeTab.bind(this)

        //Active Bots
        this.setRowsPerPageAB = this.setRowsPerPageAB.bind(this)
        this.setPageAB = this.setPageAB.bind(this)
        this.onFilterChangeAB = this.onFilterChangeAB.bind(this)
        this.onFilterAB = this.onFilterAB.bind(this)
        this.debouncedFilterAB = debounce(this.onFilterAB, DEBOUNCE_FILTER_DELAY)

        //Stopped Bots
        this.setRowsPerPageSB = this.setRowsPerPageSB.bind(this)
        this.setPageSB = this.setPageSB.bind(this)
        this.onFilterChangeSB = this.onFilterChangeSB.bind(this)
        this.onFilterSB = this.onFilterSB.bind(this)
        this.debouncedFilterSB = debounce(this.onFilterSB, DEBOUNCE_FILTER_DELAY)

        this.editBot = this.editBot.bind(this)
        this.newBot = this.newBot.bind(this)

        this.openStopBotDialog  = this.openStopBotDialog.bind(this)
        this.hideStopBotDialog  = this.hideStopBotDialog.bind(this)
        this.stopBot = this.stopBot.bind(this)

    }

    changeTab(event, newValue) {
        if (this.props.selectedTab != newValue) {
            this.props.selectTab(newValue)
            if (newValue == ACTIVE_BOTS_TAB) {
                this.props.getActiveBotsInitial({filters: this.state.ab_filtersMap,
                    sort: this.state.ab_sort, page: this.state.ab_page, rowsPerPage: this.state.ab_rowsPerPage,
                    isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
            } else if (newValue == STOPPED_BOTS_TAB) {
                this.props.getStoppedBotsInitial({filters: this.state.sb_filtersMap,
                    sort: this.state.sb_sort, page: this.state.sb_page, rowsPerPage: this.state.sb_rowsPerPage,
                    isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
            }
        }
    }

    setRowsPerPageAB (value) {
        this.state.ab_rowsPerPage = value
        this.state.ab_page = 0
        this.props.getActiveBotsInitial({filters: this.state.ab_filtersMap,
            sort: this.state.ab_sort, page: this.state.ab_page, rowsPerPage: this.state.ab_rowsPerPage,
            isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
    }

    setPageAB (value) {
        let page = value
        if (!this.props.ab_pagesDownloaded[page+1]) {
            this.state.ab_page = page
            this.props.getActiveBotsByPage({filters: this.state.ab_filtersMap,
                sort: this.state.ab_sort, page: this.state.ab_page, rowsPerPage: this.state.ab_rowsPerPage,
                isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
        } else {
            this.setState({
                ab_page: page
            })
        }
    }

    onFilterChangeAB(e) {
        let filterEntry = {[e.target.name]: e.target.value}
        this.setState({
            ab_filtersMap: {...this.state.ab_filtersMap, ...filterEntry}
        })
        this.debouncedFilterAB()
    }

    onFilterAB() {
        this.props.getActiveBotsInitial({filters: this.state.ab_filtersMap,
            sort: this.state.ab_sort, page: this.state.ab_page, rowsPerPage: this.state.ab_rowsPerPage,
            isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
    }

    setRowsPerPageSB (value) {
        this.state.sb_rowsPerPage = value
        this.state.sb_page = 0
        this.props.getStoppedBotsInitial({filters: this.state.sb_filtersMap,
            sort: this.state.sb_sort, page: this.state.sb_page, rowsPerPage: this.state.sb_rowsPerPage,
            isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
    }

    setPageSB (value) {
        let page = value
        if (!this.props.sb_pagesDownloaded[page+1]) {
            this.state.sb_page = page
            this.props.getStoppedBotsByPage({filters: this.state.sb_filtersMap,
                sort: this.state.sb_sort, page: this.state.sb_page, rowsPerPage: this.state.sb_rowsPerPage,
                isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
        } else {
            this.setState({
                sb_page: page
            })
        }
    }

    onFilterChangeSB(e) {
        let filterEntry = {[e.target.name]: e.target.value}
        this.setState({
            sb_filtersMap: {...this.state.sb_filtersMap, ...filterEntry}
        })
        this.debouncedFilterSB()
    }

    onFilterSB() {
        this.props.getStoppedBotsInitial({filters: this.state.sb_filtersMap,
            sort: this.state.sb_sort, page: this.state.sb_page, rowsPerPage: this.state.sb_rowsPerPage,
            isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
    }

    editBot(botId) {
        this.props.history.push(`/editbot?botId=${botId}`)
    }

    newBot() {
        this.props.clearCurrentBot(() => {
            this.props.history.push("/newbot")
        })
    }

    openStopBotDialog(botId) {
        this.setState({
            stopBotDialogOpen: true,
            botDbId: botId,
        })
    }

    hideStopBotDialog() {
        this.setState({
            stopBotDialogOpen: false
        })
    }

    stopBot() {
        this.state.stopBotDialogOpen = false
        this.props.stopBot({botDbId: this.state.botDbId,
            isUserSuperAdmin: isUserSuperAdmin(this.props.user),
            page: 0,
            rowsPerPage: this.state.ab_rowsPerPage})
    }



    componentDidMount() {
        if (!this.props.selectedTab || this.props.selectedTab === ACTIVE_BOTS_TAB) {
            this.props.getActiveBotsInitial({filters: this.state.ab_filtersMap,
                sort: this.state.ab_sort, page: this.state.ab_page, rowsPerPage: this.state.ab_rowsPerPage,
                isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
        }
    }

    render() {
        let tabs = []
        tabs.push({title: "Active", content: <ActiveBots
                activeBots={this.props.ab_docs}
                page={this.state.ab_page}
                rowsPerPage={this.state.ab_rowsPerPage}
                totalDocs={this.props.ab_totalDocs}
                filtersMap={this.state.ab_filtersMap}
                setRowsPerPage={this.setRowsPerPageAB}
                setPage={this.setPageAB}
                onFilterChange={this.onFilterChangeAB}
                editBot={this.editBot}
                stopBot={this.openStopBotDialog}
            />})
        tabs.push({title: "Stopped", content: <StoppedBots
                stoppedBots={this.props.sb_docs}
                page={this.state.sb_page}
                rowsPerPage={this.state.sb_rowsPerPage}
                totalDocs={this.props.sb_totalDocs}
                filtersMap={this.state.sb_filtersMap}
                setRowsPerPage={this.setRowsPerPageSB}
                setPage={this.setPageSB}
                onFilterChange={this.onFilterChangeSB}
            />})

        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <Button onClick={()=>{this.newBot()}}>New</Button>

            <NavTabs tabs={tabs} selectedTab={this.props.selectedTab >=0 ? this.props.selectedTab: ACTIVE_BOTS_TAB}
                     changeTab={this.changeTab}
                     tabContainerClass={"tabsContainer"}/>
            <AlertDialog
                open={this.state.stopBotDialogOpen}
                title={"Stop Bot"}
                texts={["Are you sure you want to stop this Bot?"]}
                handleOk={this.stopBot}
                handleCancel={this.hideStopBotDialog}
            />
    </div>
    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    selectedTab: rootState.bots.selectedTab,

    ab_docs: rootState.bots.ab_docs,
    ab_totalDocs: rootState.bots.ab_totalDocs,
    ab_totalPages: rootState.bots.ab_totalPages,
    ab_pagesDownloaded: rootState.bots.ab_pagesDownloaded,
    ab_totals: rootState.bots.ab_totals,

    sb_docs: rootState.bots.sb_docs,
    sb_totalDocs: rootState.bots.sb_totalDocs,
    sb_totalPages: rootState.bots.sb_totalPages,
    sb_pagesDownloaded: rootState.bots.sb_pagesDownloaded,
    sb_totals: rootState.bots.sb_totals,
})

let mapDispatchToProps = dispatch => {
    return {
        selectTab: (tab) => dispatch(selectTab(tab)),
        getActiveBotsInitial: (props) => dispatch(getActiveBotsInitial(props)),
        getActiveBotsByPage: (props) => dispatch(getActiveBotsByPage(props)),
        getStoppedBotsInitial: (props) => dispatch(getStoppedBotsInitial(props)),
        getStoppedBotsByPage: (props) => dispatch(getStoppedBotsByPage(props)),
        stopBot: (props) => dispatch(stopBot(props)),
        clearCurrentBot: (cb) => dispatch(clearCurrentBot(cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (Bots)
