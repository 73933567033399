import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import {Edit, Close} from "@material-ui/icons";
import {TextField} from "@material-ui/core";
import SmallButton from "../../fncomponents/SmallButton";

const useStyles = makeStyles(theme => ({


    textField: {
        width: 400,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        backgroundColor: '#101A26'
    }
}))

export default function EditableContactsSection(props) {
    const {
        editable,
        switchOnEditable,
        switchOffEditable,
        trader,
        editedTraderContactsElement,
        twitter,
        website,
        email,
        telegram,
        facebook,
        handleChangeTwitter,
        handleChangeWebsite,
        handleChangeEmail,
        handleChangeTelegram,
        handleChangeFacebook,
        handleSave,
        handleCancel} = props
    const classes = useStyles()

    if (editable)
        return (<div>
            <div className={"traderSalesPageEditableSectionContainer"} style={{width: "26rem"}}>
                <div style={{display: "flex", justifyContent: "flex-end"}} title={"Close edit mode"}>
                    <Close className={"smallMenuIcon"} onClick={switchOffEditable}/>
                </div>
                <div>
                    <TextField
                        id="subscription-edit-twitter"
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        autoFocus={editedTraderContactsElement === "twitter"}
                        placeholder={"Twitter"}
                        value={twitter}
                        onChange={handleChangeTwitter}
                    />
                </div>
                <div>
                    <TextField
                        id="subscription-edit-website"
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        autoFocus={editedTraderContactsElement === "website"}
                        placeholder={"Website"}
                        value={website}
                        onChange={handleChangeWebsite}
                    />
                </div>
                <div>
                    <TextField
                        id="subscription-edit-email"
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        autoFocus={editedTraderContactsElement === "email"}
                        placeholder={"Email"}
                        value={email}
                        onChange={handleChangeEmail}
                    />
                </div>
                <div>
                    <TextField
                        id="subscription-edit-telegram"
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        autoFocus={editedTraderContactsElement === "telegram"}
                        placeholder={"Telegram"}
                        value={telegram}
                        onChange={handleChangeTelegram}
                    />
                </div>
                <div>
                    <TextField
                        id="subscription-edit-facebook"
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        autoFocus={editedTraderContactsElement === "facebook"}
                        placeholder={"Facebook"}
                        value={facebook}
                        onChange={handleChangeFacebook}
                    />
                </div>
                <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
                    <SmallButton label={"Save"} variant="outlined" onClick={handleSave}/>
                    <SmallButton label={"Cancel"} variant="outlined" onClick={handleCancel}/>
                </div>
            </div>
        </div>)
    else if (trader) return (<div>
        <div className={"traderSalesPageEditableSectionContainer"} style={{width: "26rem"}}>
            <div style={{display: "flex", justifyContent: "flex-end"}} title={"Open edit mode"}>
                <Edit className={"smallMenuIcon"} onClick={switchOnEditable}/>
            </div>
            <div title={"Twitter"}><a href={`https://twitter.com/${trader.twitter}`} target={"_blank"} rel="noopener noreferrer">{trader.twitter}</a></div>
            <div title={"Website"}><a href={trader.website} target={"_blank"} rel="noopener noreferrer">{trader.website}</a></div>
            <div title={"Email"}><a href={`mailto:${trader.email}`} target={"_blank"} rel="noopener noreferrer">{trader.email}</a></div>
            <div title={"Telegram"}><a href={`https://t.me/${trader.telegram}`} target={"_blank"} rel="noopener noreferrer">{trader.telegram}</a></div>
            <div title={"Facebook"}><a href={trader.facebook} target={"_blank"} rel="noopener noreferrer">{trader.facebook}</a></div>
        </div>
    </div>)
    else return null
}
