import axios from "./axiosFabric"
import {handleError} from "./errorHandler"
import {
    GET_ALL_ACTIVE_TRADES,
    GET_ALL_CLOSED_TRADES,
    GET_ALL_ORDERS,
    GET_ALL_TRADES,
    GET_ALL_WAITING_TRADES,
    GET_HISTORY,
    OPEN_BTD,
    OPEN_ORDER,
    OPEN_TRADE,
    OPEN_TRADE_FROM_BTD,
    OPEN_TRADE_FROM_SUC,
    TRADE_SCROLL_INDEX, TRADE_SCROLL_IS_OFF, TRADE_SCROLL_IS_ON,
    TRADES_RE_SORT_ON_VISIBLE_TRADES,
    UPDATE_ORDER_DISCARD,
    UPDATE_TRADE_CANCEL,
    UPDATE_TRADE_CONDITIONS,
    UPDATE_TRADE_CONNECTION_TO_SIGNAL,
    UPDATE_TRADE_DISCARD,
    UPDATE_TRADE_EXECUTE_TAKE_PROFIT
} from "./actionTypes";
import {isAuthorizationHeaderPresent} from "./authentication"

export const getAllTrades = () => async dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    let tradesRes = {}
    try {
        tradesRes = await axios.post(`/trades/getall`)
    } catch (err) {
        tradesRes.data = {}
    }
    let btdsRes = {}
    try {
        btdsRes = await axios.post("/btds/getall")
    } catch (err) {
        btdsRes.data = {}
    }

    dispatch({
        type: GET_ALL_TRADES,
        payload: {...tradesRes.data, ...btdsRes.data}
    })
}

export const getAllVisibleTrades = (selectedTradesTab) => async dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    let res = {}
    let btdsRes = {}
    try {
        switch (selectedTradesTab) {
            case 0: res = await axios.post(`/trades/getallwaiting`); break;
            case 1: res = await axios.post(`/trades/getallactive`);  break;
            case 2: res = await axios.post(`/trades/getallclosed`);  break;
            case 3: res = await axios.post(`/trades/getallorders`);  break;
        }
    } catch (err) {
        res.data = {}
    }

    let data = res.data
    switch (selectedTradesTab) {
        case 0: dispatch({ type: GET_ALL_WAITING_TRADES, payload: {data, selectedTradesTab}}); break;
        case 1: dispatch({ type: GET_ALL_ACTIVE_TRADES, payload: {data, selectedTradesTab}}); break;
        case 2: dispatch({ type: GET_ALL_CLOSED_TRADES, payload: {data, selectedTradesTab}}); break;
        case 3: dispatch({ type: GET_ALL_ORDERS, payload: {data, selectedTradesTab}}); break;
    }

}

export const reSortVisibleTrades = () => dispatch => {
    dispatch({
        type: TRADES_RE_SORT_ON_VISIBLE_TRADES,
    })
}

export const openTrade = (trade) => dispatch => {
    dispatch({
        type: OPEN_TRADE,
        payload: trade
    })
}

export const openTradeFromBTD = (order_id) => dispatch => {
    dispatch({
        type: OPEN_TRADE_FROM_BTD,
        payload: {order_id: order_id}
    })
}

export const openOrder = (order) => dispatch => {
    dispatch({
        type: OPEN_ORDER,
        payload: order
    })
}

export const openCompletedTrade = (trade) => dispatch => {
    dispatch({
        type: OPEN_TRADE,
        payload: trade
    })
}

export const updateTradeConditions = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/updatetrade/conditions`, props)
        .then(res => {
            dispatch({
                type: UPDATE_TRADE_CONDITIONS,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const updateEnterTradeConditions = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/updatetrade/enterconditions`, props)
        .then(res => {
            dispatch({
                type: UPDATE_TRADE_CONDITIONS,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const updateTradeExecuteTakeProfit = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/updatetrade/executetakeprofit`, props)
        .then(res => {
            if (cb) cb()
            dispatch({
                type: UPDATE_TRADE_EXECUTE_TAKE_PROFIT,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const updateTradeCancelIt = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/updatetrade/cancel`, props)
        .then(res => {
            dispatch({
                type: UPDATE_TRADE_CANCEL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const updateTradeDiscardIt = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/updatetrade/discard`, props)
        .then(res => {
            dispatch({
                type: UPDATE_TRADE_DISCARD,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const updateTradeAsOrderDiscardIt = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/updatetrade/discard`, props)
        .then(res => {
            dispatch({
                type: UPDATE_ORDER_DISCARD,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const updateTradeFromSUCConnectionToSignal = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/updatetrade/updateconnectiontosignal`, props)
        .then(res => {
            dispatch({
                type: UPDATE_TRADE_CONNECTION_TO_SIGNAL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const updateTradeFromSUCTradeConditions = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/updatetrade/conditions2`, props)
        .then(res => {
            dispatch({
                type: UPDATE_TRADE_CONDITIONS,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getHistory = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/trades/gethistory`, props)
        .then(res => {
            if (cb) {
                cb(res.data.historyFilterSelectedMonth, res.data.historyFilterSelectedYear)
            }

            dispatch({
                type: GET_HISTORY,
                payload: res.data
            })
        }).catch(error => {
        if (error.response && error.response.status === 404) {
            dispatch({
                type: GET_HISTORY,
                payload: null
            })
        } else {
            return handleError(error, dispatch)
        }
    })
}

export const downloadHistoryForUser = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/trades/downloadhistoryforuser`, props)
        .then(response => {
            if (cb) cb(response.data)
        }).catch(error => {
    })
}

export const openBTD = (btd) => dispatch => {
    dispatch({
        type: OPEN_BTD,
        payload: btd
    })
}

export const updateBTDTradeConditions = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/updatebtd/tradeconditions`, props)
        .then(res => {
            dispatch({
                type: UPDATE_TRADE_CONDITIONS,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const updateBTDConditions = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/updatebtd/btdconditions`, props)
        .then(res => {
            dispatch({
                type: UPDATE_TRADE_CONDITIONS,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const updateBTDCancelIt = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/updatebtd/cancel`, props)
        .then(res => {
            dispatch({
                type: UPDATE_TRADE_CANCEL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const updateBTDDiscardIt = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/updatebtd/discard`, props)
        .then(res => {
            dispatch({
                type: UPDATE_TRADE_DISCARD,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const openTradeFromSUC = (order_id) => dispatch => {
    dispatch({
        type: OPEN_TRADE_FROM_SUC,
        payload: {order_id: order_id}
    })
    //small comment to be deleted
}

export const setScrollIndex = scrollIndex => dispatch => {
    dispatch({
        type: TRADE_SCROLL_INDEX,
        payload: scrollIndex
    })
}

export const setScrollIsOn = () => dispatch => {
    dispatch({
        type: TRADE_SCROLL_IS_ON
    })
}

export const setScrollIsOff = () => dispatch => {
    dispatch({
        type: TRADE_SCROLL_IS_OFF
    })
}
