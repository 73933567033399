import {
    GET_ALL_CLOSED_FUTURES_POSITIONS,
    GET_ALL_FUTURES_ORDERS,
    GET_ALL_OPENED_FUTURES_POSITIONS,
    GET_FUTURES_TRADE_UPDATE,
    GET_HISTORY_FUTURES, LAUNCH_NEW_FUTURES,
    OPEN_FUTURES_TRADE,
    UPDATE_FUTURES_TRADE_CANCEL,
    UPDATE_FUTURES_TRADE_CONDITIONS,
    UPDATE_FUTURES_TRADE_CONNECTION_TO_SIGNAL,
    UPDATE_FUTURES_TRADE_DISCARD,
    UPDATE_FUTURES_TRADE_EXECUTE_TAKE_PROFIT
} from "../actions/actionTypes"
import c from "../../constants"
import Cookies from 'universal-cookie'
import {
    brushValue,
    getEnterTradePriceForFutures,
    getCurrentPrice,
    getCurrentPriceAndDeltaPercentForFutures,
    getFuturesTradeState,
    getHRStateForFuturesTrade,
    getMaxMinAndDeltaPercentFutures,
    getProgressForFuturesOpenedPosition,
    getProgressForWaitingTrade,
    getQuoteAndBase,
    getTradeConditionsContentForActiveTradeFutures,
    getTradeProfitOrLossFutures,
    hasTradeChild,
    getClosedFuturesTradeExitPriceAndReceivedNotionalValue
} from "../../components/fncomponents/UISupportFns"

const cookies = new Cookies()

let getFuturesEntryPriceLable = futures => {
    if (futures.futures_type === "LONG") {
        return "buy"
    } else if (futures.futures_type === "SHORT") {
        return "sell"
    }
}

let computeFieldsForFuturesOrder = (futuresOrder, newRecord = true) => {
    if (newRecord) {
        const {quote, base} = getQuoteAndBase(futuresOrder)
        futuresOrder.quote = quote
        futuresOrder.base = base
        futuresOrder.origQty = brushValue(futuresOrder.orig_qty)
        futuresOrder.notionalValue = brushValue(futuresOrder.notional_value, futuresOrder.tick_size)
        futuresOrder.liquidationPrice = brushValue(futuresOrder.liquidation_price, futuresOrder.tick_size)
        futuresOrder.price = getEnterTradePriceForFutures(futuresOrder)
        futuresOrder.entryPriceLable = getFuturesEntryPriceLable(futuresOrder)
    }

    futuresOrder.additionalIsolatedMargin = futuresOrder.initial_additional_isolated_margin ? futuresOrder.initial_additional_isolated_margin : "0"
    futuresOrder.cumQuote = brushValue(futuresOrder.cum_quote)

    futuresOrder.executedQty = brushValue(futuresOrder.executed_qty)
    futuresOrder.currentPrice = getCurrentPrice(futuresOrder)
    futuresOrder.progress = getProgressForWaitingTrade(futuresOrder)
    const {trend, currentPriceDeltaPercent, currentPriceClass} = getCurrentPriceAndDeltaPercentForFutures(futuresOrder, true)
    futuresOrder.trend = trend
    futuresOrder.currentPriceDeltaPercent = currentPriceDeltaPercent
    futuresOrder.currentPriceClass = currentPriceClass
    futuresOrder.tradeState = getFuturesTradeState(futuresOrder)
    const {maxPrice, maxDelta, minPrice, minDelta} = getMaxMinAndDeltaPercentFutures(futuresOrder, true)
    futuresOrder.maxPrice = maxPrice
    futuresOrder.maxDelta = maxDelta
    futuresOrder.minPrice = minPrice
    futuresOrder.minDelta = minDelta

    return futuresOrder
}

let computeFieldsForFuturesOpenedPosition = (futuresOpenedPosition, newRecord = true) => {
    if (newRecord) {
        const {quote, base} = getQuoteAndBase(futuresOpenedPosition)
        futuresOpenedPosition.quote = quote
        futuresOpenedPosition.base = base
        futuresOpenedPosition.origQty = brushValue(futuresOpenedPosition.orig_qty)
        futuresOpenedPosition.notionalValue = brushValue(futuresOpenedPosition.notional_value, futuresOpenedPosition.tick_size)
        futuresOpenedPosition.price = getEnterTradePriceForFutures(futuresOpenedPosition)
        futuresOpenedPosition.entryPriceLable = getFuturesEntryPriceLable(futuresOpenedPosition)
    }

    let additionalIsolatedMargin = parseFloat(futuresOpenedPosition.additional_isolated_margin)
    additionalIsolatedMargin = isNaN(additionalIsolatedMargin) ? 0: additionalIsolatedMargin
    futuresOpenedPosition.additionalIsolatedMargin = additionalIsolatedMargin
    futuresOpenedPosition.liquidationPrice = brushValue(futuresOpenedPosition.liquidation_price, futuresOpenedPosition.tick_size)
    futuresOpenedPosition.executedQty = brushValue(futuresOpenedPosition.executed_qty)
    futuresOpenedPosition.cumQuote = brushValue(futuresOpenedPosition.cum_quote)
    futuresOpenedPosition.currentPrice = getCurrentPrice(futuresOpenedPosition)

    futuresOpenedPosition.progress = getProgressForFuturesOpenedPosition(futuresOpenedPosition)
    const {trend, pnl, currentPriceDeltaPercent, currentPriceClass} = getCurrentPriceAndDeltaPercentForFutures(futuresOpenedPosition)
    let addIsoMarg = parseFloat(futuresOpenedPosition.additionalIsolatedMargin)
    addIsoMarg = isNaN(addIsoMarg) ? 0: addIsoMarg

    let margin = parseFloat(futuresOpenedPosition.initial_margin) + parseFloat(pnl) + addIsoMarg
    futuresOpenedPosition.margin = margin.toFixed(futuresOpenedPosition.tick_size)
    let maintenanceMargin = parseFloat(futuresOpenedPosition.maintenance_margin_rate) / 100 * parseFloat(futuresOpenedPosition.executed_qty) * parseFloat(futuresOpenedPosition.currentPrice)
    let marginRatioNo = maintenanceMargin / margin * 100
    futuresOpenedPosition.marginRatio = isNaN(marginRatioNo) ? 0: marginRatioNo.toFixed(2)


    futuresOpenedPosition.trend = trend
    futuresOpenedPosition.pnl = pnl
    futuresOpenedPosition.currentPriceDeltaPercent = currentPriceDeltaPercent
    futuresOpenedPosition.currentPriceClass = currentPriceClass
    futuresOpenedPosition.exitPrice = futuresOpenedPosition.child_order.avg_price
    futuresOpenedPosition.tradeState = getFuturesTradeState(futuresOpenedPosition)
    if (futuresOpenedPosition.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_FILLED || futuresOpenedPosition.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_CANCELED) {
        futuresOpenedPosition.tradeProfitOrLoss = getTradeProfitOrLossFutures(futuresOpenedPosition, futuresOpenedPosition.tradeState)
    }
    const {tpContent, slContent} = getTradeConditionsContentForActiveTradeFutures(futuresOpenedPosition)
    futuresOpenedPosition.tpContent = tpContent
    futuresOpenedPosition.slContent = slContent
    const {maxPrice, maxDelta, minPrice, minDelta} = getMaxMinAndDeltaPercentFutures(futuresOpenedPosition)
    futuresOpenedPosition.maxPrice = maxPrice
    futuresOpenedPosition.maxDelta = maxDelta
    futuresOpenedPosition.minPrice = minPrice
    futuresOpenedPosition.minDelta = minDelta
    const {hrStateText, hrStateSymbol, hrStateSymbolColor, blinking} = getHRStateForFuturesTrade(futuresOpenedPosition)
    futuresOpenedPosition.hrStateText = hrStateText
    futuresOpenedPosition.hrStateSymbol = hrStateSymbol
    futuresOpenedPosition.hrStateSymbolColor = hrStateSymbolColor
    futuresOpenedPosition.blinking = blinking


    return futuresOpenedPosition
}

let computeFieldsForFuturesClosedPosition = futuresClosedPosition => {
    const {quote, base} = getQuoteAndBase(futuresClosedPosition)
    futuresClosedPosition.quote = quote
    futuresClosedPosition.base = base
    futuresClosedPosition.origQty = brushValue(futuresClosedPosition.orig_qty)
    futuresClosedPosition.notionalValue = brushValue(futuresClosedPosition.notional_value, futuresClosedPosition.tick_size)
    futuresClosedPosition.liquidationPrice = brushValue(futuresClosedPosition.liquidation_price, futuresClosedPosition.tick_size)
    futuresClosedPosition.price = getEnterTradePriceForFutures(futuresClosedPosition)
    futuresClosedPosition.entryPriceLable = getFuturesEntryPriceLable(futuresClosedPosition)
    futuresClosedPosition.executedQty = brushValue(futuresClosedPosition.executed_qty)
    futuresClosedPosition.cumQuote = brushValue(futuresClosedPosition.cum_quote)
    futuresClosedPosition.currentPrice = getCurrentPrice(futuresClosedPosition)
    futuresClosedPosition.progress = getProgressForFuturesOpenedPosition(futuresClosedPosition)
    const {trend, currentPriceDeltaPercent, currentPriceClass, pnl} = getCurrentPriceAndDeltaPercentForFutures(futuresClosedPosition)
    futuresClosedPosition.trend = trend
    //For a Closed Futures Position we won't show the PNL as there is shown already the profitOrLoss form the actual exit
    // futuresClosedPosition.pnl = pnl
    let addIsoMarg = parseFloat(futuresClosedPosition.additional_isolated_margin)
    addIsoMarg = isNaN(addIsoMarg) ? 0 : addIsoMarg
    let margin = parseFloat(futuresClosedPosition.cost) + parseFloat(pnl) + addIsoMarg
    futuresClosedPosition.margin = margin.toFixed(futuresClosedPosition.tick_size)
    let maintenanceMargin = parseFloat(futuresClosedPosition.maintenance_margin_rate) / 100 * parseFloat(futuresClosedPosition.executed_qty) * parseFloat(futuresClosedPosition.currentPrice)
    let marginRatioNo = maintenanceMargin / margin * 100
    futuresClosedPosition.marginRatio = isNaN(marginRatioNo) ? 0: marginRatioNo.toFixed(2)

    futuresClosedPosition.currentPriceDeltaPercent = currentPriceDeltaPercent
    futuresClosedPosition.currentPriceClass = currentPriceClass
    let {exitPrice, receivedNotionalValue} = getClosedFuturesTradeExitPriceAndReceivedNotionalValue(futuresClosedPosition)
    futuresClosedPosition.exitPrice = exitPrice
    futuresClosedPosition.receivedNotionalValue = receivedNotionalValue
    futuresClosedPosition.tradeState = getFuturesTradeState(futuresClosedPosition)
    if (futuresClosedPosition.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_FILLED || futuresClosedPosition.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_CANCELED) {
        futuresClosedPosition.tradeProfitOrLoss = getTradeProfitOrLossFutures(futuresClosedPosition, futuresClosedPosition.tradeState)
    }
    const {tpContent, slContent} = getTradeConditionsContentForActiveTradeFutures(futuresClosedPosition)
    futuresClosedPosition.tpContent = tpContent
    futuresClosedPosition.slContent = slContent
    const {maxPrice, maxDelta, minPrice, minDelta} = getMaxMinAndDeltaPercentFutures(futuresClosedPosition)
    futuresClosedPosition.maxPrice = maxPrice
    futuresClosedPosition.maxDelta = maxDelta
    futuresClosedPosition.minPrice = minPrice
    futuresClosedPosition.minDelta = minDelta
    const {hrStateText, hrStateSymbol, hrStateSymbolColor, blinking} = getHRStateForFuturesTrade(futuresClosedPosition)
    futuresClosedPosition.hrStateText = hrStateText
    futuresClosedPosition.hrStateSymbol = hrStateSymbol
    futuresClosedPosition.hrStateSymbolColor = hrStateSymbolColor
    futuresClosedPosition.blinking = blinking

    return futuresClosedPosition
}

function getOrder(a, b, sortOption, type) {
    if (!sortOption || sortOption === "newest") {
        let at, bt
        if (type === c.FUTURES_CLOSED_POSITIONS_TAB_POS) {
            at = parseInt(a.last_action_time)
            bt = parseInt(b.last_action_time)
        } else {
            at = parseInt(a.time)
            bt = parseInt(b.time)
        }
        return bt - at
    } else {
        let ap = a.currentPriceDeltaPercent
        let bp = b.currentPriceDeltaPercent
        let delta = ap - bp
        if (sortOption === "winners") {
            return -1 * delta
        } else if (sortOption === "losers") {
            return delta
        } else {
            return delta
        }
    }
}

function discardFuturesTradeFromAllLists(state, futuresTrade) {
    discardFuturesTradeFromListIfFound(state, futuresTrade, state.futuresOrders, state.futuresOrdersMap)
    discardFuturesTradeFromListIfFound(state, futuresTrade, state.futuresOpenedPositions, state.futuresOpenedPositionsMap)
    discardFuturesTradeFromListIfFound(state, futuresTrade, state.futuresClosedPositions, state.futuresClosedPositionsMap)
}

function discardFuturesTradeFromListIfFound(state, futuresTrade, list, listMap) {
    if (!listMap)
        return
    let idx = listMap[futuresTrade.order_id]
    if (idx >= 0) {
        //it's a waiting futuresTrade
        list.splice(idx, 1)
        delete listMap[futuresTrade.order_id]
    }
}

let UPDATE_FUTURES_TRADE_CONDITIONS_LOCK = false

function getNewFuturesOrdersListAndMap(state, newFuturesTrade) {

    let newFuturesOrders = []
    let newFuturesOrdersMap = {}

    let idx = -1
    if (state.futuresOrders) {
        idx = parseInt(state.futuresOrdersMap[newFuturesTrade.order_id])
    }
    if (idx >= 0) {
        //The trade is already present in the Waiting Trades List and Map
        //(so nothing to be done on newFuturesOrdersMap, just a clone
        newFuturesOrdersMap = {...state.futuresOrdersMap, ...{}}
        //Check if it's the case to update or remove the trade
        if (newFuturesTrade.child_order && newFuturesTrade.child_order.order_id) {
            //Remove it as trade has transitioned to a an Active Trade
            newFuturesOrders = [...state.futuresOrders, ...[]]
            newFuturesOrders.splice(idx, 1)
            delete newFuturesOrdersMap[newFuturesTrade.order_id]
        } else {
            //Update it
            newFuturesOrders = state.futuresOrders.map((fTrade, index) => {
                if (index !== idx)
                    return fTrade
                else{
                    return computeFieldsForFuturesOrder({
                        ...fTrade,
                        ...newFuturesTrade
                    }, false)
                }
            })
        }
    } else {
        //It's a new trade
        //Check if it's a Futures Order
        if (!newFuturesTrade.child_order || (newFuturesTrade.child_order && !newFuturesTrade.child_order.order_id)) {
            //Yes it's a Futures Order, we'll add it to the state, at the end of the list
            if (state.futuresOrders) {
                newFuturesOrders = state.futuresOrders.slice(0)
                newFuturesOrdersMap = {...state.futuresOrdersMap, ...{}}
            }
            newFuturesOrders.push(computeFieldsForFuturesOrder(newFuturesTrade))
            newFuturesOrdersMap[newFuturesTrade.order_id] = newFuturesOrders.length - 1
        } else {
            //Let everything untouched
            newFuturesOrdersMap = state.futuresOrdersMap
            newFuturesOrders = state.futuresOrders
        }
    }

    return {newFuturesOrders, newFuturesOrdersMap}
}

function getNewOpenedFuturesTrade(state, newFuturesTrade) {
    if (!state.openedFuturesTrade) {
        return
    }

    //This condition was introduced because, in the case of a Futures Trade a lot of price movement is happening
    //and it might be the case that at some point, when user cancels a trade, an older event with a trade
    //with status=NEW will be process after the cancel event which puts the trade in the CANCELED status,
    //and so, to prevent this from happening we block that transition, which is anyways illogic: CANCELED -> NEW.
    if (state.openedFuturesTrade.status === c.ORDER_STATUS_CANCELED) {
        return state.openedFuturesTrade
    }

    if (state.openedFuturesTrade.order_id === newFuturesTrade.order_id) {
        // console.log("getNewOpenedTrade found openedTrade with order_id " + newFuturesTrade.order_id)
        if (hasTradeChild(newFuturesTrade)) {
            newFuturesTrade.exitPrice = newFuturesTrade.child_order.avg_price
            return {
                ...state.openedFuturesTrade,
                ...newFuturesTrade,
                child_order: {
                    ...state.openedFuturesTrade.child_order, ...newFuturesTrade.child_order
                }
            }
        } else {
            // console.log("getNewOpenedTrade found openedTrade with order_id " + newFuturesTrade.order_id + ", without child")
            return {
                ...state.openedFuturesTrade,
                ...newFuturesTrade
            }
        }
    } else {
        return state.openedFuturesTrade
    }
}

function getNewOpenedPositionsListAndMap(state, newFuturesTrade) {
    let newOpenedPositions = []
    let newOpenedPositionsMap = {}
    let newClosedPositions = []
    let newClosedPositionsMap = {}

    let idxOpenedPositions = -1
    if (state.futuresOpenedPositions) {
        idxOpenedPositions = parseInt(state.futuresOpenedPositionsMap[newFuturesTrade.order_id])
    }
    let idxClosedPositions = -1
    if (state.futuresClosedPositions) {
        idxClosedPositions = parseInt(state.futuresClosedPositionsMap[newFuturesTrade.order_id])
    }

    if (idxOpenedPositions >= 0) {
        //The trade is already present in the Active Trades List and Map
        //(so nothing to be done on newOpenedPositionsMap, just a clone
        newOpenedPositionsMap = {...state.futuresOpenedPositionsMap, ...{}}
        //Update the trade
        newOpenedPositions = state.futuresOpenedPositions.map((futuresTrade, index) => {
            if (index !== idxOpenedPositions) {
                return {...futuresTrade, ...{idx: index}}
            } else {
                let updatedTrade = {...futuresTrade, ...newFuturesTrade}
                updatedTrade.child_order = {...futuresTrade.child_order, ...newFuturesTrade.child_order}
                return {...computeFieldsForFuturesOpenedPosition(updatedTrade, true), ...{idx: index}}
            }
        })
    } else if (idxClosedPositions >= 0) {
        if (newFuturesTrade.child_order && newFuturesTrade.child_order.status === "ERROR") {
            // console.log("(error) 1 getNewOpenedPositionsListAndMap idxOpenedPositions %s, idxClosedPositions %s, newFuturesTrade ", idxOpenedPositions, idxClosedPositions, JSON.stringify(newFuturesTrade))
        }

        //Let Opened Positions Untouched
        newOpenedPositions = state.futuresOpenedPositions
        newOpenedPositionsMap = state.futuresOpenedPositionsMap
        //The trade is already present in the Closed Positions List and Map
        //(so nothing to be done on newOpenedPositionsMap, just a clone
        newClosedPositionsMap = {...state.futuresClosedPositionsMap, ...{}}
        //Update the trade
        newClosedPositions = state.futuresClosedPositions.map((futuresTrade, index) => {
            if (index !== idxClosedPositions) {
                return futuresTrade
            } else {
                let updatedTrade = {...futuresTrade, ...newFuturesTrade}
                updatedTrade.child_order = {...futuresTrade.child_order, ...newFuturesTrade.child_order}
                return computeFieldsForFuturesClosedPosition(updatedTrade)
            }

        })
    } else {
        if (newFuturesTrade.child_order && newFuturesTrade.child_order.status === "ERROR") {
            // console.log("(error) 2 getNewOpenedPositionsListAndMap idxOpenedPositions %s, idxClosedPositions %s, newFuturesTrade ", idxOpenedPositions, idxClosedPositions, JSON.stringify(newFuturesTrade))
        }
        //It's a new trade
        //Check if it's an Open or Closed Position
        if (newFuturesTrade.child_order && newFuturesTrade.child_order.order_id) {
            if (newFuturesTrade.child_order.status === "NEW" ||
                newFuturesTrade.child_order.status === "PARTIALLY_FILLED") {
                //Yes it'S an Opened Position, we'll add it to the state, at the end of the list
                if (state.futuresOpenedPositions) {
                    newOpenedPositions = state.futuresOpenedPositions.slice(0)
                    newOpenedPositionsMap = {...state.futuresOpenedPositionsMap, ...{}}
                }
                newOpenedPositions.push(computeFieldsForFuturesOpenedPosition(newFuturesTrade, true))
                newOpenedPositionsMap[newFuturesTrade.order_id] = newOpenedPositions.length - 1
            } else {
                //Let Opened Positions Untouched
                newOpenedPositions = state.futuresOpenedPositions
                newOpenedPositionsMap = state.futuresOpenedPositionsMap

                //Yes it's a Closed Position, we'll add it to the state, at the end of the list
                if (state.futuresClosedPositions) {
                    newClosedPositions = state.futuresClosedPositions.slice(0)
                    newClosedPositionsMap = {...state.futuresClosedPositionsMap, ...{}}
                }
                newClosedPositions.push(computeFieldsForFuturesClosedPosition(newFuturesTrade))
                newClosedPositionsMap[newFuturesTrade.order_id] = newClosedPositions.length - 1
            }
        } else {
            //Let everything untouched
            newOpenedPositions = state.futuresOpenedPositions
            newOpenedPositionsMap = state.futuresOpenedPositionsMap
            newClosedPositions = state.futuresClosedPositions
            newClosedPositionsMap = state.futuresClosedPositionsMap
        }
    }

    return {newOpenedPositions, newOpenedPositionsMap, newClosedPositions, newClosedPositionsMap}
}

function reAttachVisibleFuturesTrades(newState) {
    switch (newState.selectedFuturesTradesTab) {
        case 0: newState.visibleFuturesTrades = newState.futuresOrders; break;
        case 1: newState.visibleFuturesTrades = newState.futuresOpenedPositions; break;
        case 2: newState.visibleFuturesTrades = newState.futuresClosedPositions; break;
    }
}

export default function (state = {selectedFuturesTradesTab: c.FUTURES_OPENED_POSITIONS_TAB_POS }, action) {

    switch (action.type) {

        case GET_ALL_FUTURES_ORDERS: {
            let newState = {...state, ...{ selectedFuturesTradesTab: action.payload.selectedFuturesTradesTab}}

            newState.futuresOrders = []
            newState.futuresOrdersMap = {}

            if (!action.payload)
                return newState
            if (!action.payload.data)
                return newState
            if (!action.payload.data.futuresTrades)
                return newState

            //Computing fields for each trade
            Object.keys(action.payload.data.futuresTrades).forEach(function(key) {
                let record  = action.payload.data.futuresTrades[key]
                record.order_id = key
                newState.futuresOrders.push(computeFieldsForFuturesOrder(record))
            })

            //Sorting by creation date
            if (newState.futuresOrders.length > 1) {
                newState.futuresOrders = newState.futuresOrders.sort((a,b) => {
                    let at = parseInt(a.time)
                    let bt = parseInt(b.time)
                    return bt - at
                })
            }

            //Creating a map order_id - position
            for(let i in newState.futuresOrders) {
                let trade = newState.futuresOrders[i]
                //Used for finding easy the trade for a future update
                newState.futuresOrdersMap[trade.order_id] = i
            }

            newState.visibleFuturesTrades = newState.futuresOrders
            newState.totalFuturesOrdersNo = action.payload.data.totalFuturesOrdersNo
            newState.totalFuturesOpenedPositionsNo = action.payload.data.totalFuturesOpenedPositionsNo
            newState.totalFuturesClosedPositionsNo = action.payload.data.totalFuturesClosedPositionsNo

            newState.selectedFuturesTradesTab = action.payload.selectedFuturesTradesTab

            return newState
        }

        case GET_ALL_OPENED_FUTURES_POSITIONS: {
            let newState = {...state, ...{ selectedFuturesTradesTab: action.payload.selectedFuturesTradesTab}}

            newState.futuresOpenedPositions = []
            newState.futuresOpenedPositionsMap = {}

            if (!action.payload)
                return newState
            if (!action.payload.data)
                return newState
            if (!action.payload.data.futuresTrades)
                return newState

            //Computing fields for each trade
            Object.keys(action.payload.data.futuresTrades).forEach(function(key) {
                let record  = action.payload.data.futuresTrades[key]
                record.order_id = key
                newState.futuresOpenedPositions.push(computeFieldsForFuturesOpenedPosition(record))
            })
            let sortOption = cookies.get(`tab_f_${c.FUTURES_OPENED_POSITIONS_TAB_POS}_sort`)
            //Sorting by creation date
            if (newState.futuresOpenedPositions.length > 1) {
                newState.futuresOpenedPositions = newState.futuresOpenedPositions.sort((a,b) => {
                    return getOrder(a,b, sortOption, c.FUTURES_OPENED_POSITIONS_TAB_POS)
                })
            }
            //Creating a map order_id - position
            for(let i in newState.futuresOpenedPositions) {
                let trade = newState.futuresOpenedPositions[i]
                //Used for finding easy the trade for a future update
                newState.futuresOpenedPositionsMap[trade.order_id] = i
            }

            newState.visibleFuturesTrades = newState.futuresOpenedPositions
            newState.totalFuturesOrdersNo = action.payload.data.totalFuturesOrdersNo
            newState.totalFuturesOpenedPositionsNo = action.payload.data.totalFuturesOpenedPositionsNo
            newState.totalFuturesClosedPositionsNo = action.payload.data.totalFuturesClosedPositionsNo

            newState.selectedFuturesTradesTab = action.payload.selectedFuturesTradesTab

            return newState
        }

        case GET_ALL_CLOSED_FUTURES_POSITIONS: {
            let newState = {...state, ...{ selectedFuturesTradesTab: action.payload.selectedFuturesTradesTab}}

            newState.futuresClosedPositions = []
            newState.futuresClosedPositionsMap = {}

            if (!action.payload)
                return newState
            if (!action.payload.data)
                return newState
            if (!action.payload.data.futuresTrades)
                return newState

            //Computing fields for each trade
            Object.keys(action.payload.data.futuresTrades).forEach(function(key) {
                let record  = action.payload.data.futuresTrades[key]
                record.order_id = key
                newState.futuresClosedPositions.push(computeFieldsForFuturesClosedPosition(record))
            })

            let sortOption = cookies.get(`tab_f_${c.FUTURES_CLOSED_POSITIONS_TAB_POS}_sort`)
            //Sorting by creation date
            if (newState.futuresClosedPositions.length > 1) {
                newState.futuresClosedPositions = newState.futuresClosedPositions.sort((a,b) => {
                    return getOrder(a, b, sortOption, c.FUTURES_CLOSED_POSITIONS_TAB_POS)
                })
            }

            //Creating a map order_id - position
            for(let i in newState.futuresClosedPositions) {
                let trade = newState.futuresClosedPositions[i]
                //Used for finding easy the trade for a future update
                newState.futuresClosedPositionsMap[trade.order_id] = i
            }

            newState.visibleFuturesTrades = newState.futuresClosedPositions
            newState.totalFuturesOrdersNo = action.payload.data.totalFuturesOrdersNo
            newState.totalFuturesOpenedPositionsNo = action.payload.data.totalFuturesOpenedPositionsNo
            newState.totalFuturesClosedPositionsNo = action.payload.data.totalFuturesClosedPositionsNo

            newState.selectedFuturesTradesTab = action.payload.selectedFuturesTradesTab

            return newState
        }

        case OPEN_FUTURES_TRADE: {
            return {...state,
                ...{
                    openedFuturesTrade: action.payload
                }
            }
        }

        case GET_HISTORY_FUTURES: {
            return {
                ...state, ...{
                    history: action.payload.history,
                    stats: action.payload.stats,
                    historyFilter: action.payload.historyFilter,
                    historyFilterSelectedYear: action.payload.historyFilterSelectedYear,
                    historyFilterSelectedMonth: action.payload.historyFilterSelectedMonth,
                    selectedFuturesTradesTab: c.FUTURES_HISTORY_TAB_POS
                }
            }
        }

        case UPDATE_FUTURES_TRADE_DISCARD: {
            let newState = {...state, ...{random: Math.random() * 10000}}
            let discardedFuturesTrade = action.payload
            if (newState.openedFuturesTrade && newState.openedFuturesTrade.order_id === discardedFuturesTrade.order_id) {
                newState.openedFuturesTrade = undefined
            }
            discardFuturesTradeFromAllLists(newState, discardedFuturesTrade)
            return newState
        }

        case GET_FUTURES_TRADE_UPDATE: {
            let _logPrefix = "reducer:updateFTrade"
            // console.log("%s GET_FUTURES_TRADE_UPDATE %s", _logPrefix, JSON.stringify(action.payload))
            if (UPDATE_FUTURES_TRADE_CONDITIONS_LOCK)
                return state

            if (!state.visibleFuturesTrades) {
                //Means that we have no visible trades
                return state
            }

            let newFuturesTrade = action.payload

            let objectType = -1

            if (!hasTradeChild(newFuturesTrade)) {
                objectType = 1 //Futures Order
                // console.log("%s objectType Order", _logPrefix)
            } else if (hasTradeChild(newFuturesTrade)) {
                if (newFuturesTrade.child_order.status === "NEW" || newFuturesTrade.child_order.status === "PARTIALLY_FILLED") {
                    objectType = 2 //Opened Position
                    // console.log("%s objectType Opened Position", _logPrefix)
                } else {
                    objectType = 3 //Closed Position
                    // console.log("%s objectType Closed Position", _logPrefix)
                }
            }
            if (objectType === 1) {
                let {newFuturesOrders, newFuturesOrdersMap} = getNewFuturesOrdersListAndMap(state, newFuturesTrade)
                let newState = {
                    ...state,
                    ...{futuresOrders: newFuturesOrders,
                        futuresOrdersMap: newFuturesOrdersMap,
                        openedFuturesTrade: getNewOpenedFuturesTrade(state, newFuturesTrade)}
                }
                reAttachVisibleFuturesTrades(newState)
                return newState
            } else if (objectType === 2) {
                //Testing the case when a Waiting Trade has just become an Active Trade and we have to remove it
                //from the Waiting Trades list.
                let presentStillInFuturesOrdersList = -1
                if (state.futuresOrdersMap) {
                    presentStillInFuturesOrdersList = parseInt(state.futuresOrdersMap[newFuturesTrade.order_id])
                    // console.log("%s objectType presentStillInFuturesOrdersList %s", _logPrefix, presentStillInFuturesOrdersList)
                }
                // console.log("presentStillInFuturesOrdersList ", presentStillInFuturesOrdersList)
                let newFuturesOrders, newFuturesOrdersMap
                if (presentStillInFuturesOrdersList >= 0) {
                    // console.log("%s processing waitTradesList even though we are in the active trades list", _logPrefix)
                    let q = getNewFuturesOrdersListAndMap(state, newFuturesTrade)
                    newFuturesOrders = q.newFuturesOrders
                    newFuturesOrdersMap = q.newFuturesOrdersMap
                }
                let {newOpenedPositions, newOpenedPositionsMap} = getNewOpenedPositionsListAndMap(state, newFuturesTrade)
                if (presentStillInFuturesOrdersList >= 0) {
                    let newState = {
                        ...state,
                        ...{futuresOrders: newFuturesOrders,
                            futuresOrdersMap: newFuturesOrdersMap,
                            futuresOpenedPositions: newOpenedPositions,
                            futuresOpenedPositionsMap: newOpenedPositionsMap,
                            openedFuturesTrade: getNewOpenedFuturesTrade(state, newFuturesTrade)
                        }
                    }
                    reAttachVisibleFuturesTrades(newState)
                    return newState
                } else {
                    let newState = {
                        ...state,
                        ...{futuresOpenedPositions: newOpenedPositions,
                            futuresOpenedPositionsMap: newOpenedPositionsMap,
                            openedFuturesTrade: getNewOpenedFuturesTrade(state, newFuturesTrade)
                        }
                    }
                    reAttachVisibleFuturesTrades(newState)
                    return newState
                }

            } else if (objectType === 3) {
                let {newOpenedPositions, newOpenedPositionsMap, newClosedPositions, newClosedPositionsMap} = getNewOpenedPositionsListAndMap(state, newFuturesTrade)
                let newState = {
                    ...state,
                    ...{futuresOpenedPositions: newOpenedPositions,
                        futuresOpenedPositionsMap: newOpenedPositionsMap,
                        futuresClosedPositions: newClosedPositions,
                        futuresClosedPositionsMap: newClosedPositionsMap,
                        openedFuturesTrade: getNewOpenedFuturesTrade(state, newFuturesTrade)
                    }
                }
                reAttachVisibleFuturesTrades(newState)
                return newState
            }
            return state
        }

        case UPDATE_FUTURES_TRADE_CANCEL: {
            return state
            // return {...state,
            //     ...{openedFuturesTrade: {
            //             ...state.openedFuturesTrade,
            //             ...{status: "CANCELED"}
            //         }
            //     }
            // }
        }

        case UPDATE_FUTURES_TRADE_CONDITIONS: {
            UPDATE_FUTURES_TRADE_CONDITIONS_LOCK = true
            let newFuturesTrade = action.payload

            if (newFuturesTrade.unchanged) {
                //trade conditions unchanged; doing nothing...
                return state
            }

            let {newFuturesOrders, newFuturesOrdersMap} = getNewFuturesOrdersListAndMap(state, newFuturesTrade)
            let {newOpenedPositions, newOpenedPositionsMap} = getNewOpenedPositionsListAndMap(state, newFuturesTrade)
            let newState = {
                ...state,
                ...{futuresOrders: newFuturesOrders,
                    futuresOrdersMap: newFuturesOrdersMap,
                    futuresOpenedPositions: newOpenedPositions,
                    futuresOpenedPositionsMap: newOpenedPositionsMap}
            }
            newState.openedFuturesTrade = getNewOpenedFuturesTrade(state, newFuturesTrade)
            UPDATE_FUTURES_TRADE_CONDITIONS_LOCK = false
            return newState
        }

        case UPDATE_FUTURES_TRADE_EXECUTE_TAKE_PROFIT: {
            return {...state, ...{random: Math.random() * 10000}}
        }

        case LAUNCH_NEW_FUTURES: {
            return {...state, ...{selectedFuturesTradesTab: c.FUTURES_OPEN_ORDERS_TAB_POS} }
        }

        case UPDATE_FUTURES_TRADE_CONNECTION_TO_SIGNAL: {
            return {...state,
                ...{
                    openedFuturesTrade: action.payload
                }
            }
        }

        default:
            return state
    }
}
