import axios from "./axiosFabric";
import {
  GET_BALANCES,
  GET_BALANCES_RECENT_DEPOSITS_AND_WITHDRAWS,
  ERROR_VALIDATING_ADDRESS,
  WITHDRAW,
  GET_SPECIFIC_BALANCE,
  CONFIRM_WITHDRAWAL,
  TRANSFER,
  GET_RECENT_TRANSFERS,
  CONFIRM_TRANSFER,
  CHANGE_WALLET,
  CHANGE_TO_DEPOSITS_TAB,
  CHANGE_TO_WITHDRAWALS_TAB,
  CHANGE_TAB,
  CHANGE_TO_TRANSFERS_TAB,
  CHANGE_TRANSFER,
  CHANGE_SELECTED_WITHDRAWAL_COIN,
  CHANGE_SELECTED_WITHDRAWAL_COIN_BALANCE_ENTRY,
  TRANSFER_INTERNAL,
  GET_MARGIN_BALANCES,
  GET_RECENT_DEPOSITS,
  GET_RECENT_WITHDRAWALS,
  GET_FUTURES_BALANCES,
  GET_RECENT_INTERNAL_TRANSFERS,
  GET_SPECIFIC_FUTURES_BALANCE,
  TRANSACTIONS_GET_INITIAL,
  TRANSACTIONS_GET_ALL,
  CLEAR_WALLET_ERRORS,
  ERROR_VALIDATING_MEMO, GET_RECENT_INVESTMENT_PURCHASES,
  GET_BALANCES_AND_BACK_TO_MAIN_WALLET_SECTION, GET_FUTURES_MARGIN_BALANCES, CHANGE_TO_STAKING_TAB, STAKE,
} from "./actionTypes";
import { handleError } from "./errorHandler";
import { clearErrors } from "./errors";
import { isAuthorizationHeaderPresent } from "./authentication";
import c from "../../constants";

export const getTotals = (props, cb) => (dispatch) => {
  if (!isAuthorizationHeaderPresent(axios)) return;
  let markets = props ? props.markets : c.MARKETS_SPOT;
  let url = "/wallet/getBalancesTotals";
  if (markets === c.MARKETS_FUTURES) {
    url = "/wallet/getBalancesTotalsFutures";
  }
  axios
    .post(url)
    .then((res) => {
      if (cb) cb(res.data);
      // dispatch({
      //     type: GET_BALANCES_TOTALS_ONLY,
      //     payload: res.data
      // })
    })
    .catch((error) => handleError(error, dispatch));
};

export const getBalances = (props, cb) => (dispatch) => {
  if (!isAuthorizationHeaderPresent(axios)) return;
  axios
    .post(`/wallet/getBalances`)
    .then((res) => {
      if (cb) cb(res.data);
      dispatch({
        type: GET_BALANCES,
        payload: res.data,
      });
    })
    .catch((error) => handleError(error, dispatch));
};

export const getRecentDeposits = (props) => (dispatch) => {
  axios.post(`/wallet/getRecentDeposits`).then((res) => {
    dispatch({
      type: GET_RECENT_DEPOSITS,
      payload: res.data,
    });
  });
};

export const getRecentWithdrawals = (props) => (dispatch) => {
  axios.post(`/wallet/getRecentWithdrawals`).then((res) => {
    dispatch({
      type: GET_RECENT_WITHDRAWALS,
      payload: res.data,
    });
  });
};

export const getRecentInvestmentPurchases = (props) => (dispatch) => {
    axios.post(`/inv/getmyinvestments`).then((res) => {
        dispatch({
            type: GET_RECENT_INVESTMENT_PURCHASES,
            payload: res.data,
        });
    });
};

export const getSpecificBalance = (props, cb) => (dispatch) => {
  if (!isAuthorizationHeaderPresent(axios)) return;
  axios
    .post(`/wallet/getSpecificBalance`, props)
    .then((res) => {
      if (cb) cb(res.data);
      dispatch({
        type: GET_SPECIFIC_BALANCE,
        payload: res.data,
      });
    })
    .catch((error) => handleError(error, dispatch));
};

export const getSpecificFuturesBalance = (props, cb) => (dispatch) => {
  if (!isAuthorizationHeaderPresent(axios)) return;
  axios
    .post(`/wallet/getSpecificFuturesBalance`, props)
    .then((res) => {
      if (cb) cb(res.data);
      dispatch({
        type: GET_SPECIFIC_FUTURES_BALANCE,
        payload: res.data,
      });
    })
    .catch((error) => handleError(error, dispatch));
};

export const getSpecificBalanceForUser = (props, cb) => (dispatch) => {
  if (!isAuthorizationHeaderPresent(axios)) return;
  axios
    .post(`/wallet/getSpecificBalanceForUser`, props)
    .then((res) => {
      if (cb) cb(res.data);
      dispatch({
        type: GET_SPECIFIC_BALANCE,
        payload: res.data,
      });
    })
    .catch((error) => handleError(error, dispatch));
};

export const getBalancesRecentDepositsAndWithdraws = (props) => (dispatch) => {
  if (!isAuthorizationHeaderPresent(axios)) return;
  axios
    .post(`/wallet/getBalances`)
    .then((res) => {
      let wallet = res.data;
      axios
        .post(`/wallet/getRecentDeposits`)
        .then((res) => {
          let recentDeposits = res.data;
          axios.post(`/wallet/getRecentWithdrawals`).then((res) => {
            let recentWithdrawals = res.data;
            dispatch({
              type: GET_BALANCES_RECENT_DEPOSITS_AND_WITHDRAWS,
              payload: {
                balances: wallet.balances,
                totals: wallet.totals,
                recentDeposits: recentDeposits,
                recentWithdrawals: recentWithdrawals,
              },
            });
          });
        })
        .catch((error) => handleError(error, dispatch));
    })
    .catch((error) => handleError(error, dispatch));
};

export const withdraw = (props, cb) => (dispatch) => {
  if (!isAuthorizationHeaderPresent(axios)) return;
  axios
    .post(`/wallet/withdraw`, props)
    .then((res) => {
      dispatch({
        type: WITHDRAW,
        payload: res.data,
      });
      if (cb) cb(res.data);
    })
    .catch((error) => handleError(error, dispatch));
};

export const validateAddress = (props, cb) => (dispatch) => {
  if (!isAuthorizationHeaderPresent(axios)) return;
  axios
    .post(`/wallet/validateAddress`, props)
    .then((res) => {
      if (cb) cb(res.data);
      //do nothing
    })
    .catch((error) => handleError(error, dispatch, ERROR_VALIDATING_ADDRESS));
};

export const validateMemo = (props, cb) => (dispatch) => {
  if (!isAuthorizationHeaderPresent(axios)) return;
  axios
    .post(`/wallet/validateMemo`, props)
    .then((res) => {
      if (cb) cb(res.data);
      //do nothing
    })
    .catch((error) => handleError(error, dispatch, ERROR_VALIDATING_MEMO));
};

export const confirmWithdrawal = (token) => (dispatch) => {
  axios
    .post(`/wallet/confirmwithdrawal/${token}`)
    .then((res) => {
      dispatch(clearErrors());
      dispatch({
        type: CONFIRM_WITHDRAWAL,
      });
    })
    .catch((error) => handleError(error, dispatch));
};

export const resendWithdrawalConfirmationEmail = (props, cb) => (dispatch) => {
  if (!isAuthorizationHeaderPresent(axios)) return;
  axios
    .post(`/wallet/resendwce`, props)
    .then((res) => {
      if (cb) cb(res.data);
      //do nothing
    })
    .catch((error) => {});
};

export const resendTransferConfirmationEmail = (props, cb) => (dispatch) => {
  if (!isAuthorizationHeaderPresent(axios)) return;
  axios
    .post(`/wallet/resendtce`, props)
    .then((res) => {
      if (cb) cb(res.data);
      //do nothing
    })
    .catch((error) => {});
};

export const generateNewEthAddress = (cb) => (dispatch) => {
  if (!isAuthorizationHeaderPresent(axios)) return;
  axios
    .post(`/wallet/generateethaddress`)
    .then((res) => {
      if (cb) cb(res.data);
      //do nothing
    })
    .catch((error) => {});
};

export const transfer = (props, cb) => (dispatch) => {
  if (!isAuthorizationHeaderPresent(axios)) return;
  axios
    .post(`/wallet/transfer`, props)
    .then((res) => {
      if (cb) cb({status: "OK"});
      dispatch({
        type: TRANSFER,
        payload: res.data,
      });
    })
    .catch(error => {if (cb) cb({error: "Something went wrong."})});
}

export const sendToStake = (props, cb) => (dispatch) => {
  if (!isAuthorizationHeaderPresent(axios)) return;
  axios
    .post(`/wallet/sendtostake`, props)
    .then((res) => {
      if (cb) cb({status: "OK"});
      dispatch({
        type: STAKE,
        payload: res.data,
      });
    })
    .catch(error => {if (cb) cb({error: "Something went wrong."})});
}

export const getRecentTransfers = () => (dispatch) => {
  if (!isAuthorizationHeaderPresent(axios)) return;
  axios
    .post(`/wallet/getRecentTransfers`)
    .then((res) => {
      dispatch({
        type: GET_RECENT_TRANSFERS,
        payload: res.data,
      });
    })
    .catch((error) => handleError(error, dispatch));
};

export const confirmTransfer = (token) => (dispatch) => {
  axios
    .post(`/wallet/confirmtransfer/${token}`)
    .then((res) => {
      dispatch(clearErrors());
      dispatch({
        type: CONFIRM_TRANSFER,
      });
    })
    .catch((error) => handleError(error, dispatch));
};

export const getRecentInternalTransfers = () => (dispatch) => {
  if (!isAuthorizationHeaderPresent(axios)) return;
  axios
    .post(`/wallet/getRecentInternalTransfers`)
    .then((res) => {
      dispatch({
        type: GET_RECENT_INTERNAL_TRANSFERS,
        payload: res.data,
      });
    })
    .catch((error) => handleError(error, dispatch));
};

export const changeTab = (tab) => (dispatch) => {
  dispatch({
    type: CHANGE_TAB,
    payload: tab,
  });
};

export const changeWallet = (props) => (dispatch) => {
  dispatch({
    type: CHANGE_WALLET,
    payload: props.wallet,
  });
};

export const changeToDepositsTab = (props) => (dispatch) => {
  dispatch({
    type: CHANGE_TO_DEPOSITS_TAB,
    payload: props,
  });
};

export const changeToWithdrawalsTab = (props) => (dispatch) => {
  dispatch({
    type: CHANGE_TO_WITHDRAWALS_TAB,
    payload: props,
  });
};

export const changeToTransfersTab = (props) => (dispatch) => {
  dispatch({
    type: CHANGE_TO_TRANSFERS_TAB,
    payload: props,
  });
};

export const changeToStakingTab = (props) => (dispatch) => {
  dispatch({
    type: CHANGE_TO_STAKING_TAB,
    payload: props,
  });
};

export const changeTransfer = (props) => (dispatch) => {
  dispatch({
    type: CHANGE_TRANSFER,
    payload: props.transfer,
  });
};

export const changeSelectedWithdrawalCoin = (coin) => (dispatch) => {
  dispatch({
    type: CHANGE_SELECTED_WITHDRAWAL_COIN,
    payload: coin,
  });
};

export const changeSelectedWithdrawalCoinBalanceEntry = (cbe) => (dispatch) => {
  dispatch({
    type: CHANGE_SELECTED_WITHDRAWAL_COIN_BALANCE_ENTRY,
    payload: cbe,
  });
};

export const transferInternal = (props, cb) => (dispatch) => {
  if (!isAuthorizationHeaderPresent(axios)) return;
  axios
    .post(`/wallet/transferinternal`, props)
    .then((res) => {
          if (cb) cb()
          dispatch({
            type: TRANSFER_INTERNAL,
            payload: res.data,
          })
    }).catch(error => {
      if (cb) cb(error)
      // dispatch({
      //   type: TRANSFER_INTERNAL_ERROR,
      // });
    });
};

export const clearWalletErrors = () => (dispatch) => {
  dispatch({
    type: CLEAR_WALLET_ERRORS,
  });
};

export const getFuturesBalances = (props, cb) => (dispatch) => {
  if (!isAuthorizationHeaderPresent(axios)) return;
  axios
    .post(`/wallet/getFuturesBalances`)
    .then((res) => {
      if (cb) cb(res.data);
      dispatch({
        type: GET_FUTURES_BALANCES,
        payload: res.data,
      });
    })
    .catch((error) => handleError(error, dispatch));
};

export const getFutureMarginBalances = (props, cb) => (dispatch) => {
    if (!isAuthorizationHeaderPresent(axios)) return;
    axios
        .post(`/wallet/getFuturesMargin`)
        .then((res) => {
            if (cb) cb(res.data);
            dispatch({
                type: GET_FUTURES_MARGIN_BALANCES,
                payload: res.data,
            });
        })
        .catch((error) => handleError(error, dispatch));
};

export const getMarginBalances = (props, cb) => (dispatch) => {
  if (!isAuthorizationHeaderPresent(axios)) return;
  axios
    .post(`/wallet/getMarginBalances`)
    .then((res) => {
      if (cb) cb(res.data);
      dispatch({
        type: GET_MARGIN_BALANCES,
        payload: res.data,
      });
    })
    .catch((error) => handleError(error, dispatch));
};

export const getTransactionsInitial = (props) => (dispatch) => {
  if (!isAuthorizationHeaderPresent(axios)) return;
  props.page = 0;
  axios
    .post(`/wallet/transactions/getByPage`, props)
    .then((res) => {
      dispatch({
        type: TRANSACTIONS_GET_INITIAL,
        payload: res.data,
      });
    })
    .catch((error) => handleError(error, dispatch));
};

export const getTransactionsByPage = (props) => (dispatch) => {
  if (!isAuthorizationHeaderPresent(axios)) return;

  axios
    .post(`/wallet/transactions/getByPage`, props)
    .then((res) => {
      dispatch({
        type: TRANSACTIONS_GET_ALL,
        payload: res.data,
      });
    })
    .catch((error) => handleError(error, dispatch));
};
