import {
    BOTS_GET_ACTIVE_INITIAL,
    BOTS_GET_ACTIVE_ALL,
    BOTS_GET_STOPPED_INITIAL,
    BOTS_GET_STOPPED_ALL,
    BOTS_SELECT_TAB,
    BOTS_CLEAR_CURRENT_BOT,
    BOTS_ERRORS,
    BOTS_CLEAR_ERRORS,
    BOTS_PREVIEW,
    BOTS_SAVE,
    BOTS_CLEAR_SAVE_RESULT,
} from "../actions/actionTypes";

export default function (state = {selectedTab: 0}, action) {
    switch (action.type) {

        case BOTS_PREVIEW: {
            return {
                ...state,
                ...{
                    bot: action.payload.bot,
                    errors: action.payload.errors,
                }
            }
        }

        case BOTS_SAVE: {
            return {
                ...state,
                ...{
                    botSavedResult: action.payload.result,
                    bot: action.payload.bot,
                    errors: action.payload.errors
                }
            }
        }

        case BOTS_CLEAR_SAVE_RESULT: {
            return {
                ...state,
                ...{
                    botSavedResult: undefined
                }
            }
        }

        case BOTS_ERRORS: {
            return {
                ...state,
                ...{
                    errors: action.payload
                }
            }
        }

        case BOTS_CLEAR_ERRORS: {
            return {
                ...state,
                ...{
                    errors: undefined
                }
            }
        }

        // case SIGNALS_CLEAR_SEND_RESULT: {
        //     return {
        //         ...state,
        //         ...{
        //             signalSentResult: undefined
        //         }
        //     }
        // }


        case BOTS_GET_ACTIVE_ALL: {
            let newState =  {
                ...state,
                ab_docs: [...state.ab_docs, ...action.payload.docs],
                ab_totalDocs: action.payload.totalDocs,
                ab_totalPages: action.payload.totalPages,
                ab_pagesDownloaded: {...state.ab_pagesDownloaded, ...{[action.payload.page]: true}}
            }
            return newState
        }

        case BOTS_GET_ACTIVE_INITIAL: {
            let newState =  {
                ...state,
                ab_docs: action.payload.docs,
                ab_totalDocs: action.payload.totalDocs,
                ab_totalPages: action.payload.totalPages,
                ab_pagesDownloaded: {[action.payload.page]: true}
            }
            return newState
        }

        case BOTS_GET_STOPPED_INITIAL: {
            let newState =  {
                ...state,
                sb_docs: action.payload.docs,
                sb_totalDocs: action.payload.totalDocs,
                sb_totalPages: action.payload.totalPages,
                sb_pagesDownloaded: {[action.payload.page]: true}
            }
            return newState
        }

        case BOTS_GET_STOPPED_ALL: {
            let newState =  {
                ...state,
                sb_docs: [...state.sb_docs, ...action.payload.docs],
                sb_totalDocs: action.payload.totalDocs,
                sb_totalPages: action.payload.totalPages,
                sb_pagesDownloaded: {...state.sb_pagesDownloaded, ...{[action.payload.page]: true}}
            }
            return newState
        }

        case BOTS_SELECT_TAB: {
            let newState =  {
                ...state,
                selectedTab: action.payload
            }
            return newState
        }

        case BOTS_CLEAR_CURRENT_BOT: {
            let newState =  {
                ...state,
                ...{bot: undefined}
            }
            return newState
        }

        default:
            return state
    }
}
