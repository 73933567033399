import c from '../../constants'
import {LOGOUT, RECEIVED_AUTH_ERRORS, RECEIVED_ERRORS} from "./actionTypes";

//Error for status 401
// {
//     "message": "Request failed with status code 401",
//     "name": "Error",
//     "stack": "Error: Request failed with status code 401\n    at createError (http://localhost:3000/static/js/1.chunk.js:15325:15)\n    at settle (http://localhost:3000/static/js/1.chunk.js:15541:12)\n    at XMLHttpRequest.handleLoad (http://localhost:3000/static/js/1.chunk.js:14848:7)",
//     "config": {
//     "url": "http://localhost:3000/api/login",
//         "method": "post",
//         "data": "{\"email\":\"damian.danielss@gmail.com\",\"password\":\"****\"}",
//         "headers": {
//         "Accept": "application/json, text/plain, */*",
//             "Content-Type": "application/json;charset=utf-8"
//     },
//     "baseURL": "http://localhost:3000/api",
//         "transformRequest": [null],
//         "transformResponse": [null],
//         "timeout": 0,
//         "xsrfCookieName": "XSRF-TOKEN",
//         "xsrfHeaderName": "X-XSRF-TOKEN",
//         "maxContentLength": -1
// }
// }

// Error.response for status 401
// error.response {
//     "data": {
//         "code": "a03",
//             "message": "Invalid credentials"
//     },
//     "status": 401,
//     "statusText": "Unauthorized",
//     "headers": {
//         "date": "Thu, 20 Jun 2019 07:59:44 GMT",
//         "etag": "W/\"2e-ALpKTL3PnDzosaN6Me7JcDC+Puc\"",
//         "connection": "close",
//         "x-powered-by": "Express",
//         "content-length": "46",
//         "vary": "Accept-Encoding",
//         "content-type": "application/json; charset=utf-8"
//     },
//     "config": {
//         "url": "http://localhost:3000/api/login",
//             "method": "post",
//             "data": "{\"email\":\"damian.danielss@gmail.com\",\"password\":\"***\"}",
//             "headers": {
//             "Accept": "application/json, text/plain, */*",
//                 "Content-Type": "application/json;charset=utf-8"
//         },
//         "baseURL": "http://localhost:3000/api",
//             "transformRequest": [null],
//             "transformResponse": [null],
//             "timeout": 0,
//             "xsrfCookieName": "XSRF-TOKEN",
//             "xsrfHeaderName": "X-XSRF-TOKEN",
//             "maxContentLength": -1
//     },
//     "request": {}
// }

let handleError = (error, dispatch, actionType) => {
    if (error === c.LOCAL_JWT_ERROR_REFRESH_TOKEN_NOT_FOUND) {
        // console.error(c.LOCAL_JWT_ERROR_REFRESH_TOKEN_NOT_FOUND + " detected -> LOGOUT")
        if (dispatch)
            return dispatch({
                type: LOGOUT
            })
        else
            return
    }

    if (error.response) {

        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        let message = `[ERROR] Status: ${error.response.status} - ${error.response.statusText},` +
            ` Data: ${JSON.stringify(error.response.data)}`
        console.error(message)
        if (dispatch) {
            return dispatch({
                type: !actionType ? RECEIVED_ERRORS : actionType,
                payload: error.response.data
            })
        } else {
            return
        }

    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error(`[ERROR] No response. Request ${ error.request}`)
    } else {
        // Something happened in setting up the request that triggered an Error
        console.error(`[ERROR] Something weird happened: ${error.message}`)
    }
}

let handleAuthenticationError = (error, dispatch, actionType) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        let message = `[ERROR] Status: ${error.response.status} - ${error.response.statusText},` +
            ` Data: ${JSON.stringify(error.response.data)}`
        console.error(message)
        dispatch({
            type: !actionType ? RECEIVED_AUTH_ERRORS : actionType,
            payload: error.response.data
        })
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.error(`[ERROR] No response. Request ${ error.request}`)
    } else {
        // Something happened in setting up the request that triggered an Error
        console.error(`[ERROR] Something weird happened: ${error.message}`)
    }
}

export {handleError, handleAuthenticationError}
