import {
    AFFILIATE_GET_COMMISSIONS_GET_ALL, AFFILIATE_GET_COMMISSIONS_GET_INITIAL,
    GENERATE_2FA,
    GET_NO_OF_REFERRED_USERS,
    GET_USER_SETTINGS,
    REMOVE_ANTI_PHISHING_CODE,
    SET_ANTI_PHISHING_CODE,
    SIGNAL_SETTINGS_UPDATE_ERRORS,
    SIGNAL_SETTINGS_UPDATED, SIGNAL_SETTINGS_UPDATED_RESET,
    SIGNALS_SETTINGS_UPDATE_CLEAR_ERRORS, USER_HAS_FUTURES_ACTIVATED
} from "../actions/actionTypes";

export default function (state = {}, action) {
    switch (action.type) {

        case GENERATE_2FA: {
            return {
                ...state,
            ...{
                twoFASecret: action.payload.twoFASecret,
                otpAuthUrl: action.payload.otpAuthUrl
                }
            }
        }

        case GET_USER_SETTINGS: {
            return {
                ...state,
                ...{
                    isTrader: action.payload.isTrader,
                    antiPhishingCode: action.payload.antiPhishingCode,
                    notificationSettings: action.payload.notificationSettings,
                    activeBTDS: action.payload.activeBTDS,
                    maxAllowedActiveBTDS: action.payload.maxAllowedActiveBTDS,
                    userTraderApplication: action.payload.userTraderApplication
                }
            }
        }

        case SET_ANTI_PHISHING_CODE: {
            return {
                ...state,
                ...{
                    antiPhishingCode: action.payload.code
                }
            }
        }

        case REMOVE_ANTI_PHISHING_CODE: {
            return {
                ...state,
                ...{
                    antiPhishingCode: undefined
                }
            }
        }

        case SIGNAL_SETTINGS_UPDATE_ERRORS: {
            return {
                ...state,
                ...{
                    signalSettingsUpdateErrors: action.payload
                }
            }
        }

        case SIGNAL_SETTINGS_UPDATED: {
            return {
                ...state,
                ...{
                    signalsSettingsSaved: true
                }
            }
        }

        case SIGNAL_SETTINGS_UPDATED_RESET: {
            return {
                ...state,
                ...{
                    signalsSettingsSaved: false
                }
            }
        }

        case SIGNALS_SETTINGS_UPDATE_CLEAR_ERRORS: {
            return {
                ...state,
                ...{
                    signalSettingsUpdateErrors: undefined
                }
            }
        }

        case GET_NO_OF_REFERRED_USERS: {
            return {
                ...state,
                ...{
                    referredUsersNo: action.payload.referredUsersNo
                }
            }
        }

        case AFFILIATE_GET_COMMISSIONS_GET_ALL: {
            return {
                ...state,
                ...{
                    commissions: [...state.commissions, ...action.payload.docs],
                    totalDocs: action.payload.totalDocs,
                    totalPages: action.payload.totalPages,
                    pagesDownloaded: {...state.pagesDownloaded, ...{[action.payload.page]: true}}
                },
            }
        }

        case AFFILIATE_GET_COMMISSIONS_GET_INITIAL: {
            return {
                ...state,
                commissions: action.payload.docs,
                totalDocs: action.payload.totalDocs,
                totalPages: action.payload.totalPages,
                pagesDownloaded: {[action.payload.page]: true}
            }
        }

        case USER_HAS_FUTURES_ACTIVATED: {
            return {...state, ...{hasFuturesActivated: action.payload.hasFuturesActivated}}
        }

        default:
            return state
    }
}
