import axios from './axiosFabric'
import {handleError} from "./errorHandler"
import {isAuthorizationHeaderPresent} from "./authentication"
import {
    BOTS_GET_ACTIVE_ALL, BOTS_GET_ACTIVE_INITIAL,
    BOTS_GET_STOPPED_ALL, BOTS_GET_STOPPED_INITIAL,
    BOTS_SELECT_TAB, BOTS_CLEAR_CURRENT_BOT,
    BOTS_ERRORS, BOTS_CLEAR_ERRORS, BOTS_PREVIEW, BOTS_SAVE, BOTS_CLEAR_SAVE_RESULT
} from "./actionTypes";

export const getNewBotId = (cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.get(`/bots/getnewbotid`)
        .then(res => {
            if (cb) cb(res.data)
        }).catch(error => handleError(error, dispatch))
}

export const preview = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/bots/preview`, props)
        .then(res => {
            if (cb) cb(res.data)
            dispatch({type: BOTS_CLEAR_ERRORS})
            dispatch({type: BOTS_CLEAR_SAVE_RESULT})
            dispatch({
                type: BOTS_PREVIEW,
                payload: res.data
            })
        }).catch(error => {
        if (cb) cb()
        handleError(error, dispatch, BOTS_ERRORS)
    })
}

export const save = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/bots/save`, props)
        .then(res => {
            if (cb) cb(res.data)
            dispatch({type: BOTS_CLEAR_ERRORS})
            dispatch({
                type: BOTS_SAVE,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch, BOTS_ERRORS))
}

export const getActiveBotsInitial = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    props.page = 0
    axios.post(`/bots/getactivebypage`, props)
        .then(res => {
            dispatch({
                type: BOTS_GET_ACTIVE_INITIAL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getActiveBotsByPage = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/bots/getactivebypage`, props)
        .then(res => {
            dispatch({
                type: BOTS_GET_ACTIVE_ALL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getStoppedBotsInitial = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    props.page = 0
    axios.post(`/bots/getstoppedbypage`, props)
        .then(res => {
            dispatch({
                type: BOTS_GET_STOPPED_INITIAL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getStoppedBotsByPage = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/bots/getstoppedbypage`, props)
        .then(res => {
            dispatch({
                type: BOTS_GET_STOPPED_ALL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const selectTab = (tab) => dispatch => {
    dispatch({
        type: BOTS_SELECT_TAB,
        payload: tab
    })
}

export const getBotById = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/bots/getbyid`, props)
        .then(res => {
            if (cb) cb(res.data)
        }).catch(error => handleError(error, dispatch))
}

export const clearCurrentBot = (cb) => dispatch => {
    dispatch({
        type: BOTS_CLEAR_CURRENT_BOT
    })
    if (cb) cb()
}

export const stopBot = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/bots/stop`, props)
        .then(res => {
            delete props.signalId
            axios.post(`/bots/getactivebypage`, props)
                .then(res => {
                    dispatch({
                        type: BOTS_GET_ACTIVE_INITIAL,
                        payload: res.data
                    })
                }).catch(error => handleError(error, dispatch))
        }).catch(error => handleError(error, dispatch))
}
