import c from '../../constants'
import axios from "./axiosFabric"
import {handleError} from "./errorHandler"
import {
    GET_ALL_CLOSED_FUTURES_POSITIONS,
    GET_ALL_FUTURES_ORDERS,
    GET_ALL_OPENED_FUTURES_POSITIONS,
    GET_HISTORY_FUTURES,
    OPEN_FUTURES_TRADE,
    TRADES_RE_SORT_ON_VISIBLE_FUTURES_TRADES, UPDATE_FUTURES_MODIFY_ISOLATED_MARGIN,
    UPDATE_FUTURES_TRADE_CANCEL,
    UPDATE_FUTURES_TRADE_CONDITIONS, UPDATE_FUTURES_TRADE_CONNECTION_TO_SIGNAL,
    UPDATE_FUTURES_TRADE_DISCARD,
    UPDATE_FUTURES_TRADE_EXECUTE_TAKE_PROFIT,
    UPDATE_TRADE_CONDITIONS
} from "./actionTypes";
import {isAuthorizationHeaderPresent} from "./authentication";


export const openFuturesTrade = (futuresTrade) => dispatch => {
    dispatch({
        type: OPEN_FUTURES_TRADE,
        payload: futuresTrade
    })
}

export const getHistory = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/futures/gethistory`, props)
        .then(res => {
            if (cb) {
                cb(res.data.historyFilterSelectedMonth, res.data.historyFilterSelectedYear)
            }
            dispatch({
                type: GET_HISTORY_FUTURES,
                payload: res.data
            })
        }).catch(error => {
        if (error.response && error.response.status === 404) {
            dispatch({
                type: GET_HISTORY_FUTURES,
                payload: null
            })
        } else {
            return handleError(error, dispatch)
        }
    })
}

export const downloadHistoryForUser = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/futures/downloadhistoryforuser`, props)
        .then(response => {
            if (cb) cb(response.data)
        }).catch(error => {
    })
}

export const getAllVisibleFuturesTrades = (selectedFuturesTradesTab) => async dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    let res = {}
    try {
        switch (selectedFuturesTradesTab) {
            case c.FUTURES_OPEN_ORDERS_TAB_POS: res = await axios.post(`/futures/getallopenedorders`); break;
            case c.FUTURES_OPENED_POSITIONS_TAB_POS: res = await axios.post(`/futures/getallopenedpositions`);  break;
            case c.FUTURES_CLOSED_POSITIONS_TAB_POS: res = await axios.post(`/futures/getallclosedpositions`);  break;
        }
    } catch (err) {
        res.data = {}
    }

    let data = res.data
    switch (selectedFuturesTradesTab) {
        case c.FUTURES_OPEN_ORDERS_TAB_POS: dispatch({ type: GET_ALL_FUTURES_ORDERS, payload: {data, selectedFuturesTradesTab}}); break;
        case c.FUTURES_OPENED_POSITIONS_TAB_POS: dispatch({ type: GET_ALL_OPENED_FUTURES_POSITIONS, payload: {data, selectedFuturesTradesTab}}); break;
        case c.FUTURES_CLOSED_POSITIONS_TAB_POS: dispatch({ type: GET_ALL_CLOSED_FUTURES_POSITIONS, payload: {data, selectedFuturesTradesTab}}); break;
    }

}

export const reSortVisibleFuturesTrades = () => dispatch => {
    dispatch({
        type: TRADES_RE_SORT_ON_VISIBLE_FUTURES_TRADES,
    })
}

export const updateFuturesTradeDiscardIt = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/updatefutures/discard`, props)
        .then(res => {
            dispatch({
                type: UPDATE_FUTURES_TRADE_DISCARD,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const updateFuturesTradeCancelIt = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/updatefutures/cancel`, props)
        .then(res => {
            dispatch({
                type: UPDATE_FUTURES_TRADE_CANCEL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const updateEnterTradeConditions = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/updatefutures/enterconditions`, props)
        .then(res => {
            dispatch({
                type: UPDATE_FUTURES_TRADE_CONDITIONS,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const updateTradeConditions = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/updatefutures/conditions`, props)
        .then(res => {
            dispatch({
                type: UPDATE_FUTURES_TRADE_CONDITIONS,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const updateTradeFromSUCTradeConditions = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/updatefutures/conditions2`, props)
        .then(res => {
            dispatch({
                type: UPDATE_TRADE_CONDITIONS,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const updateTradeExecuteTakeProfit = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/updatefutures/executetakeprofit`, props)
        .then(res => {
            if (cb) cb()
            dispatch({
                type: UPDATE_FUTURES_TRADE_EXECUTE_TAKE_PROFIT,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const updateTradeFromSUCConnectionToSignal = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/updatefutures/updateconnectiontosignal`, props)
        .then(res => {
            dispatch({
                type: UPDATE_FUTURES_TRADE_CONNECTION_TO_SIGNAL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const openCompletedFuturesTrade = (fTrade) => dispatch => {
    dispatch({
        type: OPEN_FUTURES_TRADE,
        payload: fTrade
    })
}

export const getNoOfRunningTradesOnContract = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/futures/getnoofrunningtradesoncontract`, props)
        .then(res => {
           if (cb) cb(res.data)
        }).catch(error => handleError(error, dispatch))
}

export const modifyIsolatedMargin = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/updateFutures/${props.operation.toLowerCase()}isolatedmargin`, props)
        .then(res => {
            if (cb) cb(res)
            dispatch({
                type: UPDATE_FUTURES_MODIFY_ISOLATED_MARGIN,
                payload: res.data
            })
        }).catch(error => {
            if (cb) cb({error: true})
            handleError(error, dispatch)
        })
}

export const computeLiquidationPrice = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/futures/computeLP`, props)
        .then(res => {
            if (cb) cb(res.data? res.data.liquidationPrice: 0)
        }).catch(error => handleError(error, dispatch))
}

export const computeAdditionalIsoMarg = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    // console.log("computeAdditionalIsoMarg", JSON.stringify(props))
    axios.post(`/futures/computeAIM`, props)
        .then(res => {
            if (cb) cb(res.data? res.data.additionalIsoMargin: 0)
        }).catch(error => handleError(error, dispatch))
}



export const getRates = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    // console.log("getRates", JSON.stringify(props))
    axios.post(`/futures/getRates`, props)
        .then(res => {
            if (cb) cb(res.data)
        }).catch(error => handleError(error, dispatch))
}