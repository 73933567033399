import React, {Component} from "react"
import {getUserSignalSettings, saveUserSignalSettings} from "../../../redux/actions/profile"
import {connect} from "react-redux"
import withGetScreenExt from "../../../withGetScreenExt"
import c from "../../../constants"
import UserSignalSettingsForm from "../../fncomponents/UserSignalSettingsForm"
import {brushValue, getValueWithPrecision} from "../../fncomponents/UISupportFns"
import {debounce} from "../../hoc/myUtils"
import UserFuturesSignalSettingsForm from "../../fncomponents/UserFuturesSignalSettingsForm";

const DEBOUNCE_DELAY_IN_MS = 2000

class UserSignalSettingsC extends Component {

    constructor(props) {
        super(props)
        this.state = {
            sucPosSizeType: undefined,
            sucPosSize: undefined,
            acceptSUCs: undefined,
            autoStartTradeFromSUC: undefined,
            takeProfitTarget1BTC: undefined,
        }

        this.handleChangeSUCPosSizeType = this.handleChangeSUCPosSizeType.bind(this)
        this.handleChangeAcceptSUCs = this.handleChangeAcceptSUCs.bind(this)
        this.handleChangeAutoStartTradeFromSUC = this.handleChangeAutoStartTradeFromSUC.bind(this)
        this.handleChangeSUCPosSize = this.handleChangeSUCPosSize.bind(this)
        this.fixPosSize = this.fixPosSize.bind(this)
        this.debounceFixPosSize = debounce(this.fixPosSize, DEBOUNCE_DELAY_IN_MS)
        this.handleChangeTakeProfitTarget1 = this.handleChangeTakeProfitTarget1.bind(this)
        this.fixTakeProfitTarget1 = this.fixTakeProfitTarget1.bind(this)
        this.debounceFixTakeProfitTarget1 = debounce(this.fixTakeProfitTarget1, DEBOUNCE_DELAY_IN_MS)
        this.handleChangeTakeProfitTarget2 = this.handleChangeTakeProfitTarget2.bind(this)
        this.fixTakeProfitTarget2 = this.fixTakeProfitTarget2.bind(this)
        this.debounceFixTakeProfitTarget2 = debounce(this.fixTakeProfitTarget2, DEBOUNCE_DELAY_IN_MS)

        this.handleChangeMaximumRiskCapital = this.handleChangeMaximumRiskCapital.bind(this)
        this.calculatePosSize = this.calculatePosSize.bind(this)
        this.debounceCalculatePosSize = debounce(this.calculatePosSize, DEBOUNCE_DELAY_IN_MS)

        this.handleChangeRiskRewardRatio = this.handleChangeRiskRewardRatio.bind(this)

        this.handleSave = this.handleSave.bind(this)

        if (this.props.type === "SPOT") {
            this.form = UserSignalSettingsForm
        } else if (this.props.type === c.SIGNAL_SETTINGS_TYPE_FUTURES){
            this.form = UserFuturesSignalSettingsForm
        }


    }

    handleSave() {
        this.props.saveUserSignalSettings({
            coin: this.props.coin,
            type: this.props.type,
            sucPosSizeFixed: this.state.sucPosSizeFixed,
            sucPosSizeType: this.state.sucPosSizeType,
            takeProfitTarget1: this.state.takeProfitTarget1,
            takeProfitTarget2: this.state.takeProfitTarget2,
            acceptSUCs: this.state.acceptSUCs,
            autoStartTradeFromSUC: this.state.autoStartTradeFromSUC,
            maximumRiskCapital: this.state.maximumRiskCapital,
            riskRewardRatio: this.state.riskRewardRatio,
        }, () => {
            this.setState({
                snackIsOpen: true,
                snackMessage: `${this.state.coin} Settings saved`
            })
            setTimeout(() => {
                this.setState({
                    snackIsOpen: false})
            }, 5000)
        })
    }

    handleChangeRiskRewardRatio(e) {
        let q = e.target.value ? e.target.value.replace(",", "."): ""
        this.setState({
            riskRewardRatio: q
        })
        this.debounceCalculatePosSize()
    }

    calculatePosSize() {
        let _maximumRiskCapital = parseFloat(this.state.maximumRiskCapital)
        let _riskRewardRatio = 0
        try {
            _riskRewardRatio = parseFloat(this.state.riskRewardRatio)
        } catch(err) {}

        if (isNaN(_maximumRiskCapital))
            _maximumRiskCapital = 0
        if (isNaN(_riskRewardRatio))
            _riskRewardRatio = 0
        this.setState({
            calculatedPositionSize: brushValue(_maximumRiskCapital * _riskRewardRatio / 100 / 0.1),
            riskRewardRatio: _riskRewardRatio
        })
    }

    handleChangeMaximumRiskCapital(e) {
        this.setState({
            maximumRiskCapital: e.target.value
        })
        this.debounceCalculatePosSize()
    }

    fixTakeProfitTarget2() {
        if (this.state.takeProfitTarget2.length > 0) {
            let tpt2 = getValueWithPrecision(this.state.takeProfitTarget2, 0)
            tpt2 = Math.abs(tpt2)
            if (tpt2 > 100)
                tpt2 = 100
            let tpt1 = 100 - tpt2
            this.setState({
                takeProfitTarget1: tpt1,
                takeProfitTarget2: tpt2,
            })
        }
    }

    handleChangeTakeProfitTarget2(e) {
        this.setState({
            takeProfitTarget2: e.target.value
        })
        this.debounceFixTakeProfitTarget2()
    }


    fixTakeProfitTarget1() {
        if (this.state.takeProfitTarget1.length > 0) {
            let tpt1 = getValueWithPrecision(this.state.takeProfitTarget1, 0)
            tpt1 = Math.abs(tpt1)
            if (tpt1 > 100)
                tpt1 = 100
            let tpt2 = 100 - tpt1
            this.setState({
                takeProfitTarget1: tpt1,
                takeProfitTarget2: tpt2,
            })
        }
    }


    handleChangeTakeProfitTarget1(e) {
        this.setState({
            takeProfitTarget1: e.target.value
        })
        this.debounceFixTakeProfitTarget1()
    }

    handleChangeSUCPosSizeType(e) {
        if (this.props.type === c.SIGNAL_SETTINGS_TYPE_FUTURES)
            return
        this.setState({
            sucPosSizeType: e.target.value
        })
    }

    handleChangeAcceptSUCs() {
        this.setState({
            acceptSUCs: !this.state.acceptSUCs
        })
    }

    handleChangeAutoStartTradeFromSUC() {
        this.setState({
            autoStartTradeFromSUC: !this.state.autoStartTradeFromSUC
        })
    }

    handleChangeSUCPosSize(e) {
        if (this.state.sucPosSizeType == c.SUC_POS_SIZE_TYPE_FIXED)
            this.setState({
                sucPosSizeFixed: e.target.value
            })
        else if (this.state.sucPosSizeType == c.SUC_POS_SIZE_TYPE_PERCENTAGE)
            this.setState({
                sucPosSizePercentage: e.target.value
            })
        this.debounceFixPosSize()
    }

    fixPosSize() {
        if (this.state.sucPosSizeType == c.SUC_POS_SIZE_TYPE_FIXED) {
            let posSizeF = parseFloat(this.state.sucPosSizeFixed)
            if (isNaN(posSizeF)) {
                posSizeF = this.state.sucMinPosSize
            }
            if (posSizeF < this.state.sucMinPosSize) {
                posSizeF = this.state.sucMinPosSize
            }
            this.setState({
                sucPosSizeFixed: getValueWithPrecision(posSizeF, this.state.precision)
            })
        }
    }

    componentDidMount() {
        if (this.props.user && this.props.user.signalsState === c.USER_SIGNALS_STATE_RECEIVING) {
            this.props.getUserSignalSettings((data) => {

                let sucMinPosSize, sucMaxPosSize, precision, coin = this.props.coin, sucPosSizeType, type = ""
                if (this.props.type === c.SIGNAL_SETTINGS_TYPE_FUTURES) {
                    type = "Futures"
                    sucPosSizeType = 1 //Fixed
                    sucMinPosSize = c.SUC_MIN_POS_SIZE_FU
                    sucMaxPosSize = c.SUC_MAX_POS_SIZE_FUTURES_USDT
                } else {
                    if (coin === "BTC") {
                        sucMinPosSize = c.SUC_MIN_POS_SIZE_BTC
                        sucMaxPosSize = c.SUC_MAX_POS_SIZE_BTC
                        precision = 8
                        sucPosSizeType = data.sucPosSizeTypeBTC
                    } else if (this.props.coin === "USDT") {
                        sucMinPosSize = c.SUC_MIN_POS_SIZE_USDT
                        sucMaxPosSize = c.SUC_MAX_POS_SIZE_USDT
                        precision = 6
                        sucPosSizeType = data.sucPosSizeTypeUSDT
                    }
                }

                this.setState({
                    sucPosSizeFixed: data[`sucPosSizeFixed${type}${coin}`],
                    sucPosSizeType,
                    takeProfitTarget1: data[`takeProfitTarget1${type}${coin}`],
                    takeProfitTarget2: data[`takeProfitTarget2${type}${coin}`],
                    acceptSUCs: data[`acceptSUCs${type}${coin}`],
                    autoStartTradeFromSUC: data[`autoStartTradeFromSUC${type}${coin}`],
                    maximumRiskCapital: data[`maximumRiskCapital${coin}`],
                    riskRewardRatio: data[`riskRewardRatio${coin}`],
                    sucMaxPosSize,
                    sucMinPosSize,
                    precision
                })
            })
        }
    }


    render() {
        return <this.form
            coin={this.props.coin}
            sucPosSizeType={this.state.sucPosSizeType}
            handleChangeSUCPosSizeType={this.handleChangeSUCPosSizeType}
            sucPosSize={this.state.sucPosSizeFixed}
            acceptSUCs={this.state.acceptSUCs}
            handleChangeAcceptSUCs={this.handleChangeAcceptSUCs}
            autoStartTradeFromSUC={this.state.autoStartTradeFromSUC}
            handleChangeAutoStartTradeFromSUC={this.handleChangeAutoStartTradeFromSUC}
            sucMinPosSize={this.state.sucMinPosSize}
            sucMaxPosSize={this.state.sucMaxPosSize}
            handleChangeSUCPosSize={this.handleChangeSUCPosSize}
            takeProfitTarget1={this.state.takeProfitTarget1}
            handleChangeTakeProfitTarget1={this.handleChangeTakeProfitTarget1}
            takeProfitTarget2={this.state.takeProfitTarget2}
            handleChangeTakeProfitTarget2={this.handleChangeTakeProfitTarget2}
            maximumRiskCapital={this.state.maximumRiskCapital}
            handleChangeMaximumRiskCapital={this.handleChangeMaximumRiskCapital}
            riskRewardRatio={this.state.riskRewardRatio}
            handleChangeRiskRewardRatio={this.handleChangeRiskRewardRatio}
            calculatedPositionSize={this.state.calculatedPositionSize}
            handleSave={this.handleSave}
            snackIsOpen={this.state.snackIsOpen}
        />
    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    signalSettingsUpdateErrors: rootState.profile.signalSettingsUpdateErrors
})

let mapDispatchToProps = dispatch => {
    return {
        getUserSignalSettings: (cb) => {dispatch(getUserSignalSettings(cb))},
        saveUserSignalSettings: (props, cb) => {dispatch(saveUserSignalSettings(props, cb))},
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (withGetScreenExt(UserSignalSettingsC))
