import React from 'react'
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

export default function MyProgressBar(props) {
    const {percent, maxWidth, height} = props


    return (
        <div style={{maxWidth, height, display: "flex", marginBottom: "1.6rem"}}>
            <Progress percent={percent} theme={{
                active: {
                    symbol: ` `,
                    color: "#FE6B8B",
                    height
                }
            }}
            className={"progressBar"}
            symbolClassName={"progressBarSymbol"}
            style={{height: "40px", color: "white"}}
            />
            {percent > 0 && <div style={{fontSize: "1.6rem", fontWeight: "lighter"}}>{percent}%</div>}
        </div>

    )
}