import React, {Component} from 'react'
import { connect } from 'react-redux'
import Cookies from 'universal-cookie'
import {Redirect} from "react-router-dom"
import c from '../../../constants'

class RegisterViaAffiliateCatt extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cookie: "unknown"
        }
    }

    componentDidMount() {
        const cookies = new Cookies()
        //The cookie will expire after 7 days
        cookies.set(c.REFERRAL_COOKIE, "1i4evmk4kkomw82pg", {path: "/", maxAge: 604800})
    }

    render() {
        return <Redirect to="/register"/>
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const mapStateToProps = rootState => ({
})

export default
connect(mapStateToProps, mapDispatchToProps) (RegisterViaAffiliateCatt)
