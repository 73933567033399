import React, {Component} from 'react'
import {connect} from "react-redux"
import withGetScreenExt from "../../../withGetScreenExt"
import {Helmet} from "react-helmet/es/Helmet"
import {getTitle} from "../../fncomponents/PageTitleUtils"
import BuyCryptoStep1 from "../../fncomponents/BuyCryptoStep1"
import {debounce} from "../../hoc/myUtils"
import c from '../../../constants'

const DELAY_DEBOUNCE_MS = 500

class BuyCrypto extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fiatAmount: 100,
            fees: 100 * 0.5/100,
            currency: "EUR",
            cryptoAmount: 0,
            crypto: "BTC",
            paymentMethod: c.BUY_CRYPTO_PAYMENT_METHOD_SEPA,
            userWallets: {
                "ETH": "0xb65a818a819e7fda463adecb0ed22e7dbc6997fd",
                "BTC": "3AGwks2beAxvfNoYQvwmK3m3AeCSnmw1s9"
            }
        }

        this.handleChangeFiatAmount = this.handleChangeFiatAmount.bind(this)
        this.fixFiatAmount = this.fixFiatAmount.bind(this)
        this.debounceFiatAmount = debounce(this.fixFiatAmount, DELAY_DEBOUNCE_MS)
        this.handleChangeCurrency = this.handleChangeCurrency.bind(this)
        this.handleChangeCrypto = this.handleChangeCrypto.bind(this)

        this.handleChangePaymentMethod = this.handleChangePaymentMethod.bind(this)
    }

    handleChangePaymentMethod(paymentMethod) {
        this.setState({
            paymentMethod
        })
    }

    handleChangeFiatAmount(e) {
        let amountF = parseFloat(e.target.value)
        let fees = (amountF * 0.5/100).toFixed(2)
        this.setState({
            fiatAmount: e.target.value,
            fees
        })
        this.debounceFiatAmount()
    }

    fixFiatAmount() {

    }

    handleChangeCurrency(e) {
        this.setState({
            currency: e.target.value
        })
    }

    handleChangeCrypto(e) {
        this.setState({
            crypto: e.target.value
        })
    }


    render() {
        return (
            <div className={"AppMainContent"}>
                <Helmet>
                    <title>{getTitle()}</title>
                </Helmet>
                <BuyCryptoStep1
                    fiatAmount={this.state.fiatAmount}
                    handleChangeFiatAmount={this.handleChangeFiatAmount}
                    fees={this.state.fees}
                    paymentMethod={this.state.paymentMethod}
                    handleChangePaymentMethod={this.handleChangePaymentMethod}
                    currency={this.state.currency}
                    handleChangeCurrency={this.handleChangeCurrency}
                    crypto={this.state.crypto}
                    handleChangeCrypto={this.handleChangeCrypto}
                    cryptoAmount={this.state.cryptoAmount}
                    userWallets={this.state.userWallets}
                />
            </div>
        )
    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
})

let mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (withGetScreenExt(BuyCrypto))
