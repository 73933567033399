import React from 'react'
import c from '../../constants'
import {
    getQuoteAndBase,
    timeFormatter,
    brushValue,
    formatTimeDuration,
    getValueWithPrecision,
    getPosSize,
    getExecutedQtyAndCummulativeQuoteQty
} from "./UISupportFns";
import {SettingsInputAntenna, Android, Warning} from "@material-ui/icons"

export default function CompletedTrade(props) {
    const {completedTrade, openCompletedTrade, isUserSuperAdmin} = props
    const ACTION_TYPE = completedTrade.action_type
    const {quote, base} = getQuoteAndBase(completedTrade)
    const profit = brushValue(completedTrade.profit, c.BASE_PRECISIONS[completedTrade.profit_currency])
    const _profit = parseFloat(completedTrade.profit)
    const profitEquivalentInBtc = completedTrade.profit_equivalent_in_btc ?
        brushValue(completedTrade.profit_equivalent_in_btc, c.BASE_PRECISIONS[completedTrade.profit_currency]): 0
    const profitPercentage = brushValue(completedTrade.profit_percentage, 2)
    // const executedQty = brushValue(completedTrade.executed_qty)

    let posSize = 0
    // let cummulativeQuoteQty = brushValue(completedTrade.cummulative_quote_qty)

    let {executedQty, cummulativeQuoteQty} = getExecutedQtyAndCummulativeQuoteQty(completedTrade.original_trade)

    if (ACTION_TYPE === c.ACTION_TYPE_BUY) {
       posSize =  getPosSize(completedTrade.original_trade)
    } else if (ACTION_TYPE === c.ACTION_TYPE_SELL) {
        posSize = getPosSize(completedTrade.original_trade)
        cummulativeQuoteQty = getValueWithPrecision(completedTrade.cummulative_quote_qty, c.BASE_PRECISIONS[base])
    }
    let lastActionTime = new Date(completedTrade.last_action_time)
    return (
        <div id={completedTrade.id} className={"completedTradeContainer"} onClick={() => openCompletedTrade(completedTrade)}>
            <div className={"tradeSubContainerLeft"}>
                <div style={{display: "flex", justifyContent: "space-between", width: "9em"}}>
                    <div>
                        <span style={{fontSize: "1.2em", fontWeight: "bold"}}>{quote}</span>
                        <span style={{fontSize: "1em"}}> / {base}</span>
                    </div>
                </div>

                {ACTION_TYPE === c.ACTION_TYPE_BUY &&
                <div className={"activeTradePosAndQty"} title={"What are you trading"}><small>{executedQty} {quote}</small>&nbsp;
                    {completedTrade.has_child && <span><span className={"buy"}>bought</span> with <b>{posSize} {base}</b></span>}
                    {!completedTrade.has_child &&
                        <span><span className={"buy"}>bought</span> with <b>{cummulativeQuoteQty} {base}</b> <span title={"Original position size"}>({posSize} {base})</span></span>}
                    {isUserSuperAdmin && <div>buy price <b>{completedTrade.calculated_buy_price}</b></div>}
                </div>
                }
                {ACTION_TYPE === c.ACTION_TYPE_SELL &&
                <div className={"activeTradePosAndQty"} title={"What are you trading"}>
                    {completedTrade.has_child && <span><small>{posSize} {quote}</small>&nbsp;
                        <span className={"sell"}>sold</span> for <b>{cummulativeQuoteQty} {base}</b></span>}
                    {!completedTrade.has_child && <span>
                        <span title={"Original position size"}>({posSize} {quote})</span>&nbsp;
                        <small>{executedQty} {quote}</small>&nbsp;
                        <span className={"sell"}>sold</span> for <b>{cummulativeQuoteQty} {base}</b></span>}
                </div>}

                <div className={"activeTradePosAndQty"}><b>{timeFormatter(completedTrade.start_time, true)}</b></div>
                {completedTrade.from_SUC && <div  title={"Trade coming from a Signal"}>
                    <SettingsInputAntenna style={{color: "#64FE7C", position: "relative", top: "0.4rem", fontSize: "16"}}/>
                </div>}
                {completedTrade.from_BOT && <div  title={"Trade coming from a Bot"}>
                    <Android style={{color: "#64FE7C", position: "relative", top: "0.4rem", fontSize: "16"}}/>
                </div>}
            </div>
            <div className={"tradeSubContainerRight"}>
                <div style={{textAlign: "right"}}>
                    {_profit > 0 && <div>
                        <div className={"madeProfit"}>↑ {profitPercentage}% <smal>&nbsp;{profit}&nbsp;
                            {ACTION_TYPE === c.ACTION_TYPE_BUY && <span>{base}</span>}
                            {ACTION_TYPE === c.ACTION_TYPE_SELL && <span>{quote}</span>}
                            </smal>
                        </div>
                        {profitEquivalentInBtc !== 0 && <div className={"madeProfit btcEquivProfit"}>( {profitEquivalentInBtc} BTC )</div>}
                    </div>}
                    {_profit === 0 && <div className={"zeroProfitZeroLoss"}>{profitPercentage}% <small>&nbsp;{profit}&nbsp;
                        {ACTION_TYPE === c.ACTION_TYPE_BUY && <span>{base}</span>}
                        {ACTION_TYPE === c.ACTION_TYPE_SELL && <span>{quote}</span>}
                    </small>
                    </div>}
                    {_profit < 0 && <div>
                        <div className={"lostSome"}>↓ {profitPercentage}% <small>&nbsp;{profit}&nbsp;
                            {ACTION_TYPE === c.ACTION_TYPE_BUY && <span>{base}</span>}
                            {ACTION_TYPE === c.ACTION_TYPE_SELL && <span>{quote}</span>}
                        </small>
                        </div>
                        {profitEquivalentInBtc !== 0 && <div className={"lostSome btcEquivProfit"}>( {profitEquivalentInBtc} BTC )</div>}
                        </div>
                    }

                </div>
            </div>
            <div style={{display:"flex", justifyContent:"center", width: "100%"}}>
                <div className={"buyPriceContainer"}>
                    {completedTrade.last_action === c.TRADE_OP_USER_TAKES_PROFIT && <span>You took profit</span>}
                    {completedTrade.last_action === c.TRADE_OP_AUTO_TAKE_PROFIT && <span>Took profit</span>}
                    {completedTrade.last_action === c.TRADE_OP_AUTO_TAKE_PROFIT_TARGET_1 && <span>Took profit target #1</span>}
                    {completedTrade.last_action === c.TRADE_OP_AUTO_TAKE_PROFIT_TARGET_2 && <span>Took profit target #2</span>}
                    {completedTrade.last_action === c.TRADE_OP_AUTO_TAKE_LOSS && <span>Stopped loss</span>}
                    {(completedTrade.last_action === c.TRADE_OP_USER_CANCELS_TRADE && completedTrade.has_child) && <span>Canceled</span>}
                    {(completedTrade.last_action === c.TRADE_OP_USER_CANCELS_TRADE && !completedTrade.has_child) && <span>Canceled while <b>waiting</b></span>}
                    {completedTrade.last_action === c.TRADE_OP_AUTO_CLOSE && <span>Closed</span>}
                    &nbsp;on <b>{timeFormatter(completedTrade.last_action_time, true)}</b>
                    {completedTrade.child_status === "ERROR" && <span>&nbsp;but got an <span style={{color: "#FB4A4A"}} title={completedTrade.child_error}>Error</span></span>}
                </div>
                <div className={"buyPriceContainer"}>,&nbsp;took <b>{formatTimeDuration(completedTrade.stop_time, completedTrade.start_time, " ")}</b></div>
            </div>
            <div style={{display:"flex", justifyContent:"space-between", width: "100%"}} className={"recordId"}>
                <div style={{position: "relative", top: "0.52rem"}}>
                    {completedTrade.id}
                    {completedTrade.from_btd && completedTrade.from_btd !== "undefined" && (<span title={`from BTD ${completedTrade.from_btd}`}>&nbsp;&nbsp;(<b>from a BTD</b>)</span>)}
                    {completedTrade.from_SUC && completedTrade.from_SUC !== "undefined" && (<span title={`from Signal ${completedTrade.signal_id}`}>&nbsp;&nbsp;(<b>{completedTrade.signal_id}</b>)</span>)}
                    {completedTrade.from_BOT && completedTrade.from_BOT !== "undefined" && (<span title={`from Bot ${completedTrade.bot_id}`}>&nbsp;&nbsp;(<b>{completedTrade.bot_id}</b>)</span>)}
                </div>
            </div>
            {/*{(ACTION_TYPE === c.ACTION_TYPE_SELL && (quote !== "BTC" && quote !== "ETH" && quote !== "USDT")) &&*/}
            {/*<div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "20rem", opacity: "0.6", marginTop: "1rem"}}>*/}
            {/*    <Warning/><span style={{fontSize: "0.76rem"}}>*/}
            {/*    {(lastActionTimeYear <= 2020 && lastActionTimeMonth <= 8 && lastActionTimeDay < 19) && <span>This trade did not impact statistics.</span>}*/}
            {/*    {(lastActionTimeYear <= 2020 && lastActionTimeMonth <= 8 && lastActionTimeDay >= 19) && <span>BTC equivalent was considered towards statistics.</span>}*/}
            {/*</span>*/}
            {/*</div>}*/}
            </div>)
}
