import React from "react"
import {TextField} from "@material-ui/core"
import MenuItem from "@material-ui/core/MenuItem"
import makeStyles from "@material-ui/core/styles/makeStyles"
import SwapHoriz from '@material-ui/icons/SwapHoriz'
import {brushValue, isValidPair, isBLVT} from "./UISupportFns"
import c from '../../constants'
import s from '../../settings'
import {Warning} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 130,
        backgroundColor: '#101A26'
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    }
}))


export default function SelectPair(props) {
    const {quote, base, handleChangeQuote,
        handleChangeBase, quoteCurrenciesNom, baseCurrenciesNom,
        userBaseBalance, userQuoteBalance, actionType, tradeType,
        selectPairErr, selectMaxQuoteBalance, selectMaxBaseBalance,
        signalId, botId, blvtRealLeverage, hideAvailableBalances, invalidPairError} = props
    const classes = useStyles()
    const [errorOnQuote, setErrorOnQuote] = React.useState(false)
    const [quoteHelperText, setQuoteHelperText] = React.useState(false)
    const [_isBLVT, setIsBLVT] = React.useState(false)
    let userBaseBalance2 = brushValue(userBaseBalance)
    let userQuoteBalance2 = brushValue(userQuoteBalance)

    React.useEffect(() =>{
        if (invalidPairError) {
            setErrorOnQuote(true)
            setQuoteHelperText("Invalid quote or pair")
        } else {
            if (!isValidPair(quote, base, quoteCurrenciesNom)) {
                setErrorOnQuote(true)
                setQuoteHelperText("Invalid quote or pair")
            } else {
                setErrorOnQuote(false)
                setQuoteHelperText("")
            }
        }

        setIsBLVT(isBLVT(quote))
    })


    function _handleChangeQuote(e) {
        if (!isValidPair(e.target.value, base, quoteCurrenciesNom)) {
            setErrorOnQuote(true)
            setQuoteHelperText("Invalid quote")
        } else {
            setErrorOnQuote(false)
            setQuoteHelperText("")
        }
        handleChangeQuote(e)
    }

    function formatBLVTRealLeverage(blvtRealLeverage) {
        if (blvtRealLeverage >= 0) {
            return `+${blvtRealLeverage}X`
        } else {
            return `${blvtRealLeverage}X`
        }
    }

    return (
        <div className={"wizardSectionContainer"}>
            <div className={"wizardTitle"}>
                <span className={"wizardTitleIcon"}><SwapHoriz/></span> Pair
            </div>
            {selectPairErr && <div className={"errorContainer"} style={{marginLeft: "0.6rem"}}>{selectPairErr}</div>}
            <div style={{display: "flex"}}>
                <div style={{width: "8.6rem"}}>
                    <div>
                        <TextField variant="outlined" margin="dense"
                                   className={classes.textField}
                                   label={"Quote currency"}
                                   error={errorOnQuote}
                                   helperText={quoteHelperText}
                                   value={quote} onChange={_handleChangeQuote}
                                   disabled={(signalId && signalId.length > 0) || (botId && botId.length > 0)}
                        />
                    </div>
                    {!hideAvailableBalances && <div>
                        <div style={{display: "flex", justifyContent: "flex-end", width: "7.2rem", fontSize: "0.8rem", opacity: "0.8", marginLeft: "1rem"}}>
                            <div style={{textAlign: "center", width: "7.2rem"}}>available<br/>
                                <span style={{cursor: "pointer"}} onClick={()=>{selectMaxQuoteBalance(userQuoteBalance2)}}>{userQuoteBalance2} {quote}</span>
                            </div>
                        </div>
                        {(isBLVT && blvtRealLeverage) &&<div className={"blvtRealLeverage"} title={"Real Leverage"}>{formatBLVTRealLeverage(blvtRealLeverage)}</div>}
                    </div>}
                </div>
                <div style={{position: "relative", top: "1em", left: "0.6rem", width: "1rem"}}> / </div>
                <div style={{width: "8.6rem", display: "flex", flexDirection: "column"}}>
                    <div>
                        <TextField
                            select
                            label="Base currency"
                            className={classes.textField}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            disabled={(signalId && signalId.length > 0) || (botId && botId.length > 0)}
                            value={base}
                            onChange={handleChangeBase}
                            margin="dense"
                            variant="outlined">
                            {baseCurrenciesNom && baseCurrenciesNom.map(el => {
                                if (el == base) return (<MenuItem key={el} value={el} selected={true}>
                                    {el}
                                </MenuItem>)
                                else return (<MenuItem key={el} value={el}>
                                    {el}
                                </MenuItem>)
                            })}
                        </TextField>
                    </div>
                    {(!hideAvailableBalances && userBaseBalance >= 0) &&
                        <div style={{display: "flex", justifyContent: "flex-end", fontSize: "0.8rem", opacity: "0.8", marginLeft: "0.2rem"}}>
                            <div style={{textAlign: "center", width: "7.2rem"}}>available<br/>
                                <span style={{cursor: "pointer"}} onClick={()=>{selectMaxBaseBalance(userBaseBalance2)}}>{userBaseBalance2} {base}</span>
                            </div>
                        </div>}
                </div>
            </div>
            {(_isBLVT && actionType === c.ACTION_TYPE_BUY) &&
            <div className={"warningGeneralMessageSmall"} style={{width: "22rem"}}>
                <div><Warning/></div>
                <div style={{marginLeft: "0.6rem"}}>
                    <div><a href={"https://academy.binance.com/economics/a-beginners-guide-to-binance-leveraged-tokens-blvt"} target="_blank" rel="noopener noreferrer">BLVTs</a> are not intended for long term holding.</div>
                    <div>Holding a BLVT carries a {s.BLVT_DAILY_MANAGEMENT_FEE_PERCENTAGE}% daily management fee.</div>
                </div>
            </div>}
        </div>)
}
