import React, {useState, useEffect} from "react";
import c from '../../../constants'
import { Button } from "@material-ui/core";
import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from "react-redux";
import { withdraw } from "../../../redux/actions/wallet";
import { WithdrawAllowCustomHeader } from "../../fncomponents/CustomHeaders";
import { CHANGE_TO_BALANCE_TAB } from "../../../redux/actions/actionTypes";
import {brushValue} from "../../fncomponents/UISupportFns";

const WithdrawAllow = ({ transactionDetails, changeRenderCustomHeader }) => {

    const [withdrawError, setWithdrawError] = useState(null);
    const [confirmButtonEnabled, setConfirmButtonEnabled] = useState(true);
    const [showLoading, setShowLoading] = useState(false);
    const [otherErrorReturned, setOtherErrorReturned] = useState(false);

    function isOtherErrorPresent() {
        return otherErrorReturned
    }

    useEffect(() => {
        changeRenderCustomHeader(() => <WithdrawAllowCustomHeader />);
      }, []);

    const {
    selectedWithdrawCoin,
    withdrawAmount,
    recipientAddress,
    withdrawalFee,
    newBalance,
    toMemo,
  } = transactionDetails;

  const { name, coin } = selectedWithdrawCoin;
  const dispatch = useDispatch();

  return (
    <div>
      <div className="withdraw-allow__top">
        <img
          src={`imgs/coins/${coin?.toUpperCase()}_${c.COIN_LOGOS_PIC_VERSION}.png`}
          className="withdraw-allow__coinLogo"
          alt="icon"
        />
        <div className="withdraw-allow__title">
          <span>{coin}</span>
          <span className="withdraw-allow__dot"> · </span>
          <span>{name}</span>
        </div>
        <div className="withdraw-allow__text-amount">
          WITHDRAW <span className="withdraw-allow__amount">{brushValue(withdrawAmount - withdrawalFee)}</span>{" "}
          {coin}
        </div>
        <div className="withdraw-allow__details">
            <div>to <b>{recipientAddress}</b></div>
          <div>fee {brushValue(withdrawalFee)} {coin}</div>
            <div>new balance <b>{brushValue(newBalance)}</b> {coin}</div>
        </div>
        <Button
          style={{
            backgroundColor: "#17BEBB",
            display: "flex",
            justifyContent: "space-evenly",
            width: "161px",
            margin: "20px auto",
            borderRadius: "32px",
            height: "48px",
            textAlign: "center",
            color: "#080F18",
          }}

          onClick={() => {if (!confirmButtonEnabled) return; setConfirmButtonEnabled(false); setShowLoading(true);
              withdraw({
                coin,
                toAddress: recipientAddress,
                ...toMemo ? {toMemo}: {},
                amount: withdrawAmount,
                finalAmount: String(withdrawAmount - withdrawalFee),
              }, result => {
                if (result.status === "OK") {
                    setShowLoading(false)
                  setWithdrawError(null);
                    selectedWithdrawCoin.totalBalance = result.newBalance.totalBalance
                    selectedWithdrawCoin.availableBalance = result.newBalance.availableBalance
                    selectedWithdrawCoin.inOrderBalance = result.newBalance.inOrderBalance
                    dispatch({type: CHANGE_TO_BALANCE_TAB,  payload: { selectedBalanceCoin: { ...selectedWithdrawCoin, initialTab: "withdrawals"}},});
                } else if (result.status === "ERROR") {
                    setShowLoading(false)
                    setWithdrawError(result.message);
                    setOtherErrorReturned(true)
                }})(dispatch)
            }}
            >
            {!showLoading && <PlayArrowRoundedIcon />}
            {showLoading && <CircularProgress size={18} style={{color: "black"}}/>}
          CONFIRM
        </Button>
        {withdrawError && <span>{withdrawError}</span>}
      </div>
      </div>

  );
};

export default WithdrawAllow;
