import React, {Component} from 'react'
import {connect} from "react-redux"
import NavTabs from "../../fncomponents/NavTabs"
import TradesHistory from "./TradesHistory"
import c from '../../../constants'
import CsvDownloader from 'react-csv-downloader'
import {
    getHistory,
    downloadHistoryForUser,
    getAllVisibleTrades, openTrade, openBTD, openOrder, reSortVisibleTrades
} from "../../../redux/actions/trades"
import {initWS, closeWS} from "../../../redux/actions/wsPrices"
import {getTitle} from "../../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"
import AlertDialog from "../../fncomponents/AlertDialog"
import withGetScreenExt from "../../../withGetScreenExt"
import {getActiveSuspendedActivities} from "../../../redux/actions/administration"
import {Info, Sort} from "@material-ui/icons"
import VirtualScrollerContainer from "./VirtualScrollerContainer"
import {getTradeMainState} from "../../fncomponents/UISupportFns"
import {withRouter} from "react-router-dom"
import SimplePopOver from "../../fncomponents/SimplePopOver"
import TradesSortingRadioGroup from "../../fncomponents/TradesSortingRadioGroup"
import {setIndex} from "../../../redux/virtualScrollerState"
import Cookies from 'universal-cookie'
const cookies = new Cookies()

class Trades extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedStats: "monthly",
            historyFilterSelectedMonth: 0,
            historyFilterSelectedYear: 0,
            isSortPopupOpened: false,
            tabsSortOptions: {}
        }
        this.changeTab = this.changeTab.bind(this)
        this.changeSelectedStats = this.changeSelectedStats.bind(this)
        this.changeHistoryFilter = this.changeHistoryFilter.bind(this)

        this.getTotalsPeriodically = this.getTotalsPeriodically.bind(this)
        this.confirmAction = this.confirmAction.bind(this)
        this.downloadHistory = this.downloadHistory.bind(this)
        this.closeConfirmationDialog = this.closeConfirmationDialog.bind(this)

        this.openRecord = this.openRecord.bind(this)
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        this.handleOpenSortPopup = this.handleOpenSortPopup.bind(this)
        this.handleCloseSortPopup = this.handleCloseSortPopup.bind(this)
        this.handleChangeSortOption = this.handleChangeSortOption.bind(this)

        //Moved here from componentDidMount
        this.props.user && initWS(this.props.user.userId)
        this.props.getActiveSuspendedActivities()

        //Load sorting options from Cookies before getting all visible trades which must be sorted
        let q = undefined
        q = cookies.get(`tab_${c.ACTIVE_TRADES_TAB_POS}_sort`)
        if (q) {
            // console.log(`cookies value for tab_${c.ACTIVE_TRADES_TAB_POS}_sort=`, q)
            this.state.tabsSortOptions[c.ACTIVE_TRADES_TAB_POS] = q
        } else {
            // console.log(`cookies value for tab_${c.ACTIVE_TRADES_TAB_POS}_sort=null`)
            this.state.tabsSortOptions[c.ACTIVE_TRADES_TAB_POS] = "newest"
            cookies.set(`tab_${c.ACTIVE_TRADES_TAB_POS}_sort`, "newest", { path: '/' })
        }
        q = cookies.get(`tab_${c.CLOSED_TRADES_TAB_POS}_sort`)
        if (q) {
            // console.log(`cookies value for tab_${c.CLOSED_TRADES_TAB_POS}_sort=`, q)
            this.state.tabsSortOptions[c.CLOSED_TRADES_TAB_POS] = q
        } else {
            // console.log(`cookies value for tab_${c.CLOSED_TRADES_TAB_POS}_sort=null`)
            this.state.tabsSortOptions[c.CLOSED_TRADES_TAB_POS] = "newest"
            cookies.set(`tab_${c.CLOSED_TRADES_TAB_POS}_sort`, "newest", { path: '/' })
        }


        this.props.getAllVisibleTrades(this.props.selectedTradesTab)
        setIndex(0)
    }

    handleChangeSortOption(e) {
        cookies.set(`tab_${this.props.selectedTradesTab}_sort`, e.target.value, { path: '/' })
        this.state.tabsSortOptions[this.props.selectedTradesTab] = e.target.value
        this.setState({
            isSortPopupOpened: false,
        })
        this.props.reSortVisibleTrades()
    }

    handleCloseSortPopup(event) {
        this.setState({
            isSortPopupOpened: false,
        })
    }

    handleOpenSortPopup(event) {
        this.setState({
            isSortPopupOpened: true,
            sortPopOverAnchorEl: document.getElementById("anchor_for_sort_pop_over")
        })
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    openRecord(record) {

        if (record.record_type && record.record_type === "SUC") {
            if (record.status === c.SUC_STATUS_WAITING) {
                // this.setState({
                //     openSUCDetails: true
                // })
                this.props.history.push("/sucdetails")
            } else {
                // this.setState({
                //     openSUCSummary: true
                // })
                this.props.history.push("/sucsummary")
            }
            this.props.openSUC(record)
        } else if (record.buy_the_dip_id) {
            if (record.status === c.BTD_STATUS_WAITING) {
                // this.setState({
                //     openBTDDetails: true
                // })
                this.props.history.push("/btddetails")
            } else {
                // this.setState({
                //     openBTDSummary: true
                // })
                this.props.history.push("/btdsummary")
            }
            this.props.openBTD(record)
        } else if (record.record_type === "ORDER") {
            if (record.status === c.ORDER_STATUS_NEW ||
                record.status === c.ORDER_STATUS_PARTIALLY_FILLED) {
                // this.setState({
                //     openOrderDetails: true
                // })
                this.props.history.push("/orderdetails")
            } else {
                // this.setState({
                //     openOrderSummary: true
                // })
                this.props.history.push("/ordersummary")
            }

            this.props.openOrder(record)
        } else {
            let tradeMainState = getTradeMainState(record)
            if (tradeMainState === c.TRADE_MAIN_STATE_ONGOING) {
                this.props.history.push("/tradedetails")
            } else if (tradeMainState === c.TRADE_MAIN_STATE_FINISHED) {
                // this.setState({
                //     openTradeSummary: true
                // })
                this.props.history.push("/tradesummary")
            }
            this.props.openTrade(record)
        }
    }

    closeConfirmationDialog() {
        this.setState({
            confirmationDialogOpen: false
        })
    }

    confirmAction(code) {
        if (code === "downloadHistory") {
            this.setState({
                confirmationDialogOpen: true,
                confirmationDialogTitle: "Download history",
                confirmationDialogMessages: ["Are your sure you want to download history?",
                    "Download a .csv file with all your trades history."],
                handleConfirmationFromDialog: this.downloadHistory
            })
        }
    }

    downloadHistory() {
        this.props.downloadHistoryForUser({}, (csvJSONEnvelope) => {
            this.setState({
                columns: csvJSONEnvelope.columns,
                datas: csvJSONEnvelope.datas,
                confirmationDialogOpen: false,
            })
            this.downloadButton.click()
        })
    }

    changeTab(event, newValue) {
        if (newValue === 4) {
            this.props.getHistory({}, (historyFilterSelectedMonth, historyFilterSelectedYear) => {
                this.setState({
                    historyFilterSelectedMonth,
                    historyFilterSelectedYear
                })
            })
            //Clicked on the History tab
        } else {
            this.props.getAllVisibleTrades(newValue)
        }

    }

    changeSelectedStats(newValue) {
        this.setState({
            selectedStats: newValue
        })
    }

    changeHistoryFilter(month, year) {
        if (this.state.historyFilterSelectedYear !== year || this.state.historyFilterSelectedMonth !== month) {
            this.state.historyFilterSelectedMonth = month
            this.state.historyFilterSelectedYear = year
            this.props.getHistory({month: month, year: year})
        }
    }

    getTotalsPeriodically() {
        this.getTotalsInterval = setInterval(() => {
            this.props.getTotals()
        }, 60000)
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions)
    }

    render() {
        let tabs = []
        let {totalWaitingNo, totalActiveNo, totalClosedNo, totalOrdersNo} = this.props
        let noWT = totalWaitingNo ? totalWaitingNo: 0
        let noAT = totalActiveNo ? totalActiveNo: 0
        let noCT = totalClosedNo ? totalClosedNo: 0
        let noOrders = totalOrdersNo ? totalOrdersNo: 0
        noWT = noWT > 0 ? `${noWT}`: ""
        noAT = noAT > 0 ? `${noAT}`: ""
        noCT = noCT > 0 ? `${noCT}`: ""
        noOrders = noOrders > 0 ? `${noOrders}`: ""

        let tabsTitles = {}
        if (this.props.isMobile()) {
            tabsTitles["Waiting"] = <div><span style={{fontSize: "0.6rem"}}>Waiting</span><br/>{noWT}</div>
            tabsTitles["Active"] = <div><span style={{fontSize: "0.6rem"}}>Active</span><br/>{noAT}</div>
            tabsTitles["Closed"] = <div><span style={{fontSize: "0.6rem"}}>Closed</span><br/>{noCT}</div>
            tabsTitles["Orders"] = <div><span style={{fontSize: "0.6rem"}}>Orders</span><br/>{noOrders}</div>
            tabsTitles["History"] = <span style={{fontSize: "0.6rem"}}>History</span>
        } else {
            tabsTitles["Waiting"] = <div>Waiting <b>{noWT}</b></div>
            tabsTitles["Active"] = <div>Active <b>{noAT}</b></div>
            tabsTitles["Closed"] = <div>Closed <b>{noCT}</b></div>
            tabsTitles["Orders"] = <div>Orders <b>{noOrders}</b></div>
            tabsTitles["History"] = <div>History</div>
        }

        tabs.push({title: tabsTitles["Waiting"]})
        tabs.push({title: tabsTitles["Active"]})
        tabs.push({title: tabsTitles["Closed"]})
        tabs.push({title: tabsTitles["Orders"]})
        tabs.push({title: tabsTitles["History"]})

        switch (this.props.selectedTradesTab) {
            case 0: tabs[0].content = <VirtualScrollerContainer type="WAITING_TRADES"  trades={this.props.visibleTrades} openRecord={this.openRecord} windowHeight={this.state.height}/>; break;
            case 1: tabs[1].content = <VirtualScrollerContainer type="ACTIVE_TRADES"  trades={this.props.visibleTrades} openRecord={this.openRecord} windowHeight={this.state.height}/>; break;
            case 2: tabs[2].content = <VirtualScrollerContainer type="CLOSED_TRADES"  trades={this.props.visibleTrades} openRecord={this.openRecord} windowHeight={this.state.height}/>; break;
            case 3: tabs[3].content = <VirtualScrollerContainer type="ORDERS"  trades={this.props.visibleTrades} openRecord={this.openRecord} windowHeight={this.state.height}/>; break;
            case 4: tabs[4].content = <TradesHistory
                history={this.props.history}
                historyData={this.props.tradesHistory}
                                                                     stats={this.props.stats}
                                                                     historyFilter={this.props.historyFilter}
                                                                     historyFilterSelectedYear={this.props.historyFilterSelectedYear}
                                                                     historyFilterSelectedMonth={this.props.historyFilterSelectedMonth}
                                                                     selectedStats={this.state.selectedStats}
                                                                     changeSelectedStats={this.changeSelectedStats}
                                                                     changeHistoryFilter={this.changeHistoryFilter}
                                                                     confirmAction={this.confirmAction}/>; break;
        }

        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>

            </Helmet>
            {(this.props.ASAS && this.props.ASAS.showWarningMessage === "true") &&
            <div className={"platformInformationContainer"}>
                <div><Info/></div>
                <div style={{marginLeft: "0.6rem"}}>
                    {this.props.ASAS.warningMessage}
                </div>
            </div>}

            {(this.props.selectedTradesTab === 1 || this.props.selectedTradesTab === 2) && <div  className={"tradersSortContainer"}>
                <div id={"anchor_for_sort_pop_over"} className={"tradesSortButton"} onClick={this.handleOpenSortPopup}
                     style={{width: "9rem"}}>
                    <Sort/>
                    {!this.props.isMobile() && <span style={{marginLeft: "0.4rem"}}>SORT BY</span>}
                </div>
            </div>}
            {(this.props.selectedTradesTab === 0 || this.props.selectedTradesTab === 3 || this.props.selectedTradesTab === 4) &&
                <div  className={"tradersSortContainer"} style={{color: "gray"}}>
                <div id={"anchor_for_sort_pop_over"} className={"tradesSortButton"} style={{width: "6rem"}}>
                    <Sort/>
                    {!this.props.isMobile() && <span style={{marginLeft: "0.4rem"}}>SORT BY</span>}
                </div>
            </div>}
            <NavTabs tabs={tabs}
                     selectedTab={this.props.selectedTradesTab}
                     changeTab={this.changeTab}
                     tabContainerClass={"tabsContainer"} isMobile={this.props.isMobile()}
                     showFloatingButton={true}
                     type={"SPOT"}
            />
            <AlertDialog
                open={this.state.confirmationDialogOpen}
                title={this.state.confirmationDialogTitle}
                texts={this.state.confirmationDialogMessages}
                handleOk={this.state.handleConfirmationFromDialog}
                handleCancel={this.closeConfirmationDialog}
            />
            <div style={{display: "none"}}>
                <CsvDownloader
                    filename="history.csv"
                    separator=","
                    columns={this.state.columns}
                    datas={this.state.datas ? this.state.datas : []}>
                    <button ref={(r) => this.downloadButton = r}>Download</button>
                </CsvDownloader>
            </div>
            <SimplePopOver open={this.state.isSortPopupOpened}
                           anchorEl={this.state.sortPopOverAnchorEl}
                           handleClose={this.handleCloseSortPopup}
                           content={
                               <TradesSortingRadioGroup
                                   value={this.state.tabsSortOptions[this.props.selectedTradesTab]}
                                   handleChange={this.handleChangeSortOption}/>}/>
        </div>
    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    totalWaitingNo: rootState.trades.totalWaitingNo,
    totalActiveNo: rootState.trades.totalActiveNo,
    totalClosedNo: rootState.trades.totalClosedNo,
    totalOrdersNo: rootState.trades.totalOrdersNo,
    visibleTrades: rootState.trades.visibleTrades,
    tradesHistory: rootState.trades.history,
    historyFilter: rootState.trades.historyFilter,
    historyFilterSelectedYear: rootState.trades.historyFilterSelectedYear,
    historyFilterSelectedMonth: rootState.trades.historyFilterSelectedMonth,
    stats: rootState.trades.stats,
    selectedTradesTab: rootState.trades.selectedTradesTab,
    //totals: rootState.wallet.totals,
    ASAS: rootState.administration.asas
})

let mapDispatchToProps = dispatch => {
    return {
        openTrade: (trade) => dispatch(openTrade(trade)),
        openBTD: (btd) => dispatch(openBTD(btd)),
        openOrder: (trade) => dispatch(openOrder(trade)),
        getHistory: (props, cb) => {dispatch(getHistory(props, cb))},
        //getTotals: (props, cb) => {dispatch(getTotals(props, cb))},
        downloadHistoryForUser: (props, cb) => {dispatch(downloadHistoryForUser(props, cb))},
        getActiveSuspendedActivities: (cb) => dispatch(getActiveSuspendedActivities(cb)),
        getAllVisibleTrades: (selectedTradesTab) => dispatch(getAllVisibleTrades(selectedTradesTab)),
        reSortVisibleTrades: () => dispatch(reSortVisibleTrades())
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(withGetScreenExt(Trades)))
