import makeStyles from "@material-ui/core/styles/makeStyles";
import Fab from "@material-ui/core/Fab";
import React from "react";
import c from '../../constants'
import {getTradeEnterVerb, getTradeExitVerb} from "./UISupportFns";
import {Warning} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    fabCancel: {
        margin: theme.spacing(1),
        width: 130,
        background: "#101926",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "white",
        '&:hover': {
            background: "#424242"
        }
    },
    fabTakeProfit: {
        margin: theme.spacing(1),
        background: "#152B40",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "white",
        '&:hover': {
            background: "#424242"
        }
    },
    fabDiscard: {
        margin: theme.spacing(1),
        background: "#FB4A4A",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "black",
        '&:hover': {
            background: "#424242"
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    }
}));

export default function TradeDetailsMainButtons(props) {
    const classes = useStyles()
    let {trade, takeProfit, cancelTrade, discardTrade, ASAS} = props
    let verb = getTradeEnterVerb(trade)


    if (ASAS && ASAS.suspendActivities === "true" && ASAS.suspendedActivitiesMap[c.SUSPENDED_ACTIVITIES_MANUAL_TAKE_PROFIT])
        return <div className={"maintenanceWarning"} style={{width: "20rem"}}>
            <div><Warning/></div>
            <div style={{marginLeft: "0.6rem"}}>
                Cancel Trade or Manual Take Profit are temporarily disabled.
            </div>
        </div>

    if (trade.child_order && trade.child_order.order_id) {
        if (trade.child_order.status === c.ORDER_STATUS_NEW) {
            //This means we have an active trade upon we can exert actions like: take profit/cancel trade
            return (
                <div className={"tradeDetailsMainButtonsContainer"}>
                    <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabCancel}
                         onClick={() => {
                             cancelTrade()
                         }}>
                        <div style={{color: "white"}}>Cancel</div>
                    </Fab>
                    <Fab variant="extended" color="primary" aria-label="Preview" className={classes.fabTakeProfit}
                         onClick={() => {
                             takeProfit()
                         }}>
                        <div>Take profit&nbsp;&nbsp;</div>
                    </Fab>
                </div>
            )
        } else if (!trade.from_SUC && trade.child_order.status === c.ORDER_STATUS_PARTIALLY_FILLED) {
            return (
                <div>
                    <div className={"tradeDetailsMainButtonsContainer"} style={{justifyContent: "center"}}>
                        <div>Trade is filling...</div>
                    </div>
                    <div className={"tradeDetailsMainButtonsContainer"} style={{justifyContent: "center"}}>
                        <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabCancel}
                             onClick={() => {
                                 cancelTrade()
                             }}>
                            <div style={{color: "white"}}>Cancel</div>
                        </Fab>
                    </div>
                </div>)
        } else if (trade.from_SUC && trade.child_order.status === c.ORDER_STATUS_PARTIALLY_FILLED) {
            if (trade.take_profit_target_1_reached) {
                return (
                    <div>
                        <div className={"tradeDetailsMainButtonsContainer"} style={{justifyContent: "center"}}>
                            {trade.child_order.sub_type === "TAKE_PROFIT_TARGET_1" && <div>Trade is filling...</div>}
                            {trade.child_order.sub_type === "TAKE_PROFIT_TARGET_1_FILLED" &&
                            <div>Take profit target #{trade.last_take_profit_target_reached} reached.</div>}
                        </div>
                        <div className={"tradeDetailsMainButtonsContainer"} style={{justifyContent: "center"}}>
                            <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabCancel}
                                 onClick={() => {
                                     cancelTrade()
                                 }}>
                                <div style={{color: "white"}}>Cancel</div>
                            </Fab>
                            <Fab variant="extended" color="primary" aria-label="Preview" className={classes.fabTakeProfit}
                                 onClick={() => {
                                     takeProfit()
                                 }}>
                                <div>Take profit&nbsp;&nbsp;</div>
                            </Fab>
                        </div>
                    </div>)
            } else {
                return (
                    <div>
                        <div className={"tradeDetailsMainButtonsContainer"} style={{justifyContent: "center"}}>
                            {trade.child_order.sub_type === "TAKE_PROFIT_TARGET_1" && <div>Trade is filling...</div>}
                            {trade.child_order.sub_type === "TAKE_PROFIT_TARGET_1_FILLED" && <div>Take profit target #{trade.last_take_profit_target_reached} reached.</div>}
                        </div>
                        <div className={"tradeDetailsMainButtonsContainer"} style={{justifyContent: "center"}}>
                            <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabCancel}
                                 onClick={() => {
                                     cancelTrade()
                                 }}>
                                <div style={{color: "white"}}>Cancel</div>
                            </Fab>
                        </div>
                    </div>)
            }
        } else if (trade.child_order.status === c.ORDER_STATUS_FILLED || trade.child_order.status === c.ORDER_STATUS_CANCELED
            || trade.child_order.status === c.ORDER_STATUS_ERROR) {
            return (<div>
                {trade.child_order.status === c.ORDER_STATUS_CANCELED && <div className={"tradeDetailsMainButtonsContainer2"}>
                    <div style={{width: "16rem", margin: "auto"}}>Trade canceled</div>
                </div>}

                    <div className={"tradeDetailsMainButtonsContainer"} style={{justifyContent: "center"}}>

                        <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabDiscard}
                             onClick={() => {
                                 discardTrade()
                             }}>
                            <div style={{color: "white"}}>Discard</div>
                        </Fab>
                    </div>
                </div>)
        }
    } else {
        if (trade.status === c.ORDER_STATUS_NEW) {
            //This is a waiting trade
            return (
                <div className={"tradeDetailsMainButtonsContainer"} style={{justifyContent: "center"}}>
                    <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabCancel}
                         onClick={() => {
                             cancelTrade()
                         }}>
                        <div style={{color: "white"}}>Cancel</div>
                    </Fab>
                </div>
            )
        } else if (trade.status === c.ORDER_STATUS_PARTIALLY_FILLED) {
            //This is a waiting trade
            return (<div>
                    <div className={"tradeDetailsMainButtonsContainer2"}>
                        <div style={{width: "16rem", margin: "auto"}}>Trade is filling...</div>
                    </div>
                    <div className={"tradeDetailsMainButtonsContainer"} style={{justifyContent: "center"}}>
                        <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabCancel}
                             onClick={() => {
                                 cancelTrade()
                             }}>
                            <div style={{color: "white"}}>Cancel</div>
                        </Fab>
                    </div>
                </div>)
        } else if (trade.status === c.ORDER_STATUS_FILLED && trade.record_type !== "ORDER") {
            //This is a waiting trade
            return (
                <div className={"tradeDetailsMainButtonsContainer"} style={{justifyContent: "center", height: "2rem"}}>
                    <div>Trade filled and started.</div>
                </div>
            )
        } else if (trade.status === c.ORDER_STATUS_FILLED && trade.record_type === "ORDER") {
            //This is a waiting trade
            return (
                <div>
                    <div className={"tradeDetailsMainButtonsContainer2"}>
                        <div style={{width: "16rem", margin: "auto"}}>ORDER FILLED</div>
                    </div>
                    <div className={"tradeDetailsMainButtonsContainer"} style={{justifyContent: "center"}}>
                        <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabDiscard}
                             onClick={() => {
                                 discardTrade()
                             }}>
                            <div style={{color: "white"}}>Discard</div>
                        </Fab>
                    </div>
                </div>
            )
        } else if (trade.status === c.ORDER_STATUS_CANCELED || trade.status === c.ORDER_STATUS_ERROR) {
            //This is a waiting trade
            return (
                <div>
                    <div className={"tradeDetailsMainButtonsContainer2"}>
                        {trade.record_type !== "ORDER" && <div style={{width: "16rem", margin: "auto"}}>Trade canceled before {verb}</div>}
                        {trade.record_type === "ORDER" && <div style={{width: "16rem", margin: "auto"}}>Order canceled before being filled.</div>}
                    </div>
                    <div className={"tradeDetailsMainButtonsContainer"} style={{justifyContent: "center"}}>
                        <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabDiscard}
                             onClick={() => {
                                 discardTrade()
                             }}>
                            <div style={{color: "white"}}>Discard</div>
                        </Fab>
                    </div>
                </div>
            )
        }
    }
}
