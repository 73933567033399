import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import {
    openTrade, updateTradeConditions, updateEnterTradeConditions, updateTradeExecuteTakeProfit,
    updateTradeCancelIt, updateTradeDiscardIt, updateTradeFromSUCConnectionToSignal, updateTradeFromSUCTradeConditions
} from "../../../redux/actions/trades"
import {
    getCurrentPriceOnPair,
} from "../../../redux/actions/newTrade"
import {closeWS, initWS} from "../../../redux/actions/wsOrderBook"
import {connect} from "react-redux"
import TimeAgo from 'react-timeago'
import {Create, Clear, ArrowBack} from "@material-ui/icons"
import {
    brushValue,
    capitalize,
    getActionTypeOptionDetailsExplicit,
    getAllowedToEditEnterTradeConditions,
    getAllowedToEditTradeConditions,
    getCurrentBuyAndSellPrice,
    getCurrentPriceAndDeltaPercent,
    getHRStateForTrade,
    getMaxMinAndDeltaPercent,
    getPosSize, getProgress, getProgressForWaitingTrade,
    getQuoteAndBase,
    getTradeConditionsContentForTradeDetails,
    getTradeEnterVerb,
    getTradeEnterVerbPastTense,
    getTradeState,
    getTradeStateTitle,
    getValueWithPrecision,
    timeAgoFormatter,
    timeFormatter,
    getCummulativeQuoteQty
} from "../../fncomponents/UISupportFns"
import {FormControlLabel, Paper, withStyles} from "@material-ui/core"
import TradeConditions from "../../fncomponents/TradeConditions"
import EnterTradeConditions from "../../fncomponents/EnterTradeConditions"
import c from "../../../constants"
import TakeProfitConditions from "../../fncomponents/TakeProfitConditions"
import Fade from '@material-ui/core/Fade'
import CancelItem from "../../fncomponents/CancelItem"
import TradeDetailsMainButtons from "../../fncomponents/TradeDetailsMainButtons"
import DiscardItem from "../../fncomponents/DiscardItem"
import {debounce} from "../../hoc/myUtils"
import OrderBook from "../../fncomponents/OrderBook"
import {getTitle} from "../../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"
import TradeConditionsFromSUC from "../../fncomponents/TradeConditionsFromSUC"
import Checkbox from "@material-ui/core/Checkbox"

const DEBOUNCE_DELAY_IN_MS = 500

const CustomColorCheckbox = withStyles({
    root: {
        color: "#ffffff",
        '&$checked': {
            color: "#ffffff",
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);

class TradeDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isEditTradeConditionsPanelOpen: false,
            isTradeDetailsPanelOpen: true,
            isTakeProfitPanelOpen: false,
            isCancelTradePanelOpen: false,
            isDiscardTradePanelOpen: false,
            isEditEnterTradeConditionsPanelOpen: false,
            goBack: false,
            executeTakeProfitOption: "market",
            executeTakeProfitPrice: 0,
            //Flag to avoid double submit
            executeTakeProfitOngoing: false
        }

        this.updateTradeFromSUCConnection = this.updateTradeFromSUCConnection.bind(this)

        this.openEditTradeConditionsPanel = this.openEditTradeConditionsPanel.bind(this)
        this.closeEditTradeConditionsPanel = this.closeEditTradeConditionsPanel.bind(this)
        this.handleChangeTakeProfitOption = this.handleChangeTakeProfitOption.bind(this)
        this.handleChangeTakeProfitOption2 = this.handleChangeTakeProfitOption2.bind(this)
        this.handleChangeTakeProfitPriceOrPercent = this.handleChangeTakeProfitPriceOrPercent.bind(this)
        this.fixTakeProfitPriceOrPercent = this.fixTakeProfitPriceOrPercent.bind(this)
        this.debounceFixTakeProfitPriceOrPercent = debounce(this.fixTakeProfitPriceOrPercent, DEBOUNCE_DELAY_IN_MS)
        this.handleChangeTakeProfitTarget = this.handleChangeTakeProfitTarget.bind(this)
        this.fixTakeProfitTarget = this.fixTakeProfitTarget.bind(this)
        this.debounceFixTakeProfitTarget = debounce(this.fixTakeProfitTarget, DEBOUNCE_DELAY_IN_MS)
        this.handleChangeStopLossOption = this.handleChangeStopLossOption.bind(this)
        this.handleChangeStopLossPriceOrPercent = this.handleChangeStopLossPriceOrPercent.bind(this)
        this.fixStopLossPriceOrPercent = this.fixStopLossPriceOrPercent.bind(this)
        this.debounceFixStopLossPriceOrPercent = debounce(this.fixStopLossPriceOrPercent, DEBOUNCE_DELAY_IN_MS)
        this.updateTradeConditions = this.updateTradeConditions.bind(this)
        this.updateTradeFromSUCTradeConditions = this.updateTradeFromSUCTradeConditions.bind(this)
        this.updateTradeConditionsGeneric = this.updateTradeConditionsGeneric.bind(this)
        this.goBack = this.goBack.bind(this)
        this.openTakeProfitPanel = this.openTakeProfitPanel.bind(this)
        this.closeTakeProfitPanel = this.closeTakeProfitPanel.bind(this)
        this.handleChangeExecuteTakeProfitOption = this.handleChangeExecuteTakeProfitOption.bind(this)
        this.handleChangeExecuteTakeProfitPrice = this.handleChangeExecuteTakeProfitPrice.bind(this)
        this.fixExecuteTakeProfitPrice = this.fixExecuteTakeProfitPrice.bind(this)
        this.debounceFixExecuteTakeProfitPrice = debounce(this.fixExecuteTakeProfitPrice, DEBOUNCE_DELAY_IN_MS)
        this.executeTakeProfit = this.executeTakeProfit.bind(this)
        this.openCancelTradePanel = this.openCancelTradePanel.bind(this)
        this.closeCancelTradePanel = this.closeCancelTradePanel.bind(this)
        this.cancelTrade = this.cancelTrade.bind(this)
        this.openDiscardTradePanel = this.openDiscardTradePanel.bind(this)
        this.closeDiscardTradePanel = this.closeDiscardTradePanel.bind(this)
        this.discardTrade = this.discardTrade.bind(this)
        this.openEditEnterTradeConditionsPanel = this.openEditEnterTradeConditionsPanel.bind(this)
        this.closeEditEnterTradeConditionsPanel = this.closeEditEnterTradeConditionsPanel.bind(this)
        this.updateEnterTradeConditions = this.updateEnterTradeConditions.bind(this)
        this.handleChangeActionTypeOption = this.handleChangeActionTypeOption.bind(this)
        this.handleChangeFixedPrice = this.handleChangeFixedPrice.bind(this)
        this.fixFixedPrice = this.fixFixedPrice.bind(this)
        this.debounceFixFixedPrice = debounce(this.fixFixedPrice, DEBOUNCE_DELAY_IN_MS)

        this.handleChangeTakeProfitTargetPercentageExecute = this.handleChangeTakeProfitTargetPercentageExecute.bind(this)
        this.fixTakeProfitTargetPercentageExecute = this.fixTakeProfitTargetPercentageExecute.bind(this)
        this.debounceFixTakeProfitTargetExecute = debounce(this.fixTakeProfitTargetPercentageExecute, DEBOUNCE_DELAY_IN_MS)

        this.switchWithOrWithoutStopLossTarget = this.switchWithOrWithoutStopLossTarget.bind(this)
        this.handleChangeCandlesType = this.handleChangeCandlesType.bind(this)

        this.checkExecuteTakeProfitPrice = this.checkExecuteTakeProfitPrice.bind(this)
    }

    handleChangeCandlesType(e) {
        this.setState({
            candlesType: e.target.value
        })
    }

    switchWithOrWithoutStopLossTarget() {
        if (this.state.stopLossOption === c.STOP_LOSS_OPTION_MANUAL) {
            this.setState({
                stopLossOption: c.STOP_LOSS_OPTION_PERCENTAGE,
                stopLossPriceOrPercent: 10,
            })
        } else {
            this.setState({
                stopLossOption: c.STOP_LOSS_OPTION_MANUAL
            })
        }
    }

    updateTradeFromSUCConnection(rand) {
        this.props.updateTradeFromSUCConnectionToSignal({tradeId: this.props.trade.order_id})
    }

    openEditTradeConditionsPanel() {

        let newState = {
            isEditTradeConditionsPanelOpen: true,
            isTradeDetailsPanelOpen: false,
            stopLossOption: this.props.trade.stop_loss_option,
            takeProfitOption: this.props.trade.take_profit_option,
            stopLossPriceOrPercent: this.props.trade.stop_loss_price_or_percent,
        }
        if (this.props.trade.fromSUC) {
            newState.takeProfitTargets = []
            for(let i=0; i < this.props.trade.take_profit_targets.length; i++) {
                newState.takeProfitTargets.push(this.props.trade.take_profit_targets[i])
            }
        } else {
            newState.takeProfitPriceOrPercent = this.props.trade.take_profit_price_or_percent
        }

        this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, result => {
            newState.currentPrice = brushValue(result.current_price, result.tick_size)
            this.setState(newState)
        })
    }

    closeEditTradeConditionsPanel() {
        this.setState({
            isEditTradeConditionsPanelOpen: false,
            isTradeDetailsPanelOpen: true,
        })
    }

    updateTradeConditions() {
        this.state.isEditTradeConditionsPanelOpen = false
        this.state.isTradeDetailsPanelOpen = true
        this.props.updateTradeConditions({
            tradeId: this.props.trade.order_id,
            takeProfitOption: this.state.takeProfitOption,
            takeProfitPriceOrPercent: this.state.takeProfitPriceOrPercent,
            stopLossOption: this.state.stopLossOption,
            stopLossPriceOrPercent: this.state.stopLossPriceOrPercent,
            candlesType: this.state.candlesType
        })
    }

    updateTradeFromSUCTradeConditions() {
        this.state.isEditTradeConditionsPanelOpen = false
        this.state.isTradeDetailsPanelOpen = true
        this.props.updateTradeFromSUCTradeConditions({
            tradeId: this.props.trade.order_id,
            takeProfitOption: this.state.takeProfitOption,
            takeProfitTargets: this.state.takeProfitTargets,
            stopLossOption: this.state.stopLossOption,
            stopLossPriceOrPercent: this.state.stopLossPriceOrPercent,
            takeProfitTarget1PercentageExecute: this.state.take_profit_target_1_percentage_execute,
            takeProfitTarget2PercentageExecute: this.state.take_profit_target_2_percentage_execute
        })
    }

    updateTradeConditionsGeneric() {
        if (this.props.trade.from_SUC) {
            this.updateTradeFromSUCTradeConditions()
        } else {
            this.updateTradeConditions()
        }
    }

    handleChangeTakeProfitOption(option) {
        //If there is no change, do nothing
        if (this.state.takeProfitOption === option)
            return

        let sign = 1
        if (this.state.actionType === c.ACTION_TYPE_SELL)
            sign = -1

        if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_MANUAL) {
            //If the transition is from Manual, don't do any calculations
        } else {
            let {currentPrice} = getCurrentBuyAndSellPrice(this.props.trade)
            if (currentPrice === "0")
                currentPrice = this.state.currentPrice
            //Else if the transition is from Percentage or Price, transform
            //percentage to price and vice-versa
            if (option === c.TAKE_PROFIT_OPTION_MANUAL) {
                this.state.takeProfitPriceOrPercent = ""
            } else if (option === c.TAKE_PROFIT_OPTION_PRICE) {
                let oldPercent = parseFloat(this.state.takeProfitPriceOrPercent)
                let price = (1 + sign * oldPercent/100) * parseFloat(currentPrice)
                this.state.takeProfitPriceOrPercent = brushValue(price, 8)
            } else if (option === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
                let oldPrice = parseFloat(this.state.takeProfitPriceOrPercent)
                let percent = ((oldPrice - currentPrice) / currentPrice) * 100
                percent *= sign
                if ( percent < 0) {
                    percent = -1 * percent
                }

                this.state.takeProfitPriceOrPercent = brushValue(percent, 1)
            }
        }

        this.setState({
            takeProfitOption: option
        })
    }

    handleChangeTakeProfitOption2(option) {
        //If there is no change, do nothing
        if (this.state.takeProfitOption === option)
            return

        let sign = 1
        if (this.state.actionType === c.ACTION_TYPE_SELL)
            sign = -1

        if (option === c.TAKE_PROFIT_OPTION_PRICE) {
            for(let i = 0; i < this.state.takeProfitTargets.length; i++) {
                let oldPercent = parseFloat(this.state.takeProfitTargets[i])
                let {currentPrice} = getCurrentBuyAndSellPrice(this.props.trade)
                let price = (1 + sign * oldPercent/100) * parseFloat(currentPrice)
                this.state.takeProfitTargets[i] = brushValue(price, 8)
            }
        } else if (option === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
            for(let i = 0; i < this.state.takeProfitTargets.length; i++) {
                let oldPrice = parseFloat(this.state.takeProfitTargets[i])
                let {currentPrice} = getCurrentBuyAndSellPrice(this.props.trade)
                let percent = ((oldPrice - currentPrice) / currentPrice) * 100
                percent *= sign
                if ( percent < 0) {
                    percent = -1 * percent
                }
                this.state.takeProfitTargets[i] = brushValue(percent, 1)
            }
        }

        this.setState({
            takeProfitOption: option
        })
    }

    handleChangeTakeProfitPriceOrPercent(e) {
        let value = e.target && e.target.value ? e.target.value : ""
        if (value === 0 || value.length === 0) {
            this.state.takeProfitOption = c.TAKE_PROFIT_OPTION_MANUAL
        } else if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_MANUAL) {
            this.state.takeProfitOption = c.TAKE_PROFIT_OPTION_PRICE
        }
        this.setState({
            takeProfitPriceOrPercent: value
        })

        this.debounceFixTakeProfitPriceOrPercent()
    }

    fixTakeProfitPriceOrPercent() {
        if (`${this.state.takeProfitPriceOrPercent}`.length > 0) {
            if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
                this.setState({
                    takeProfitPriceOrPercent: getValueWithPrecision(this.state.takeProfitPriceOrPercent, 2)
                })
            } else this.setState({
                takeProfitPriceOrPercent: getValueWithPrecision(this.state.takeProfitPriceOrPercent, this.state.tickSize)
            })
        }
    }

    handleChangeTakeProfitTarget(e) {
        let i = parseInt(e.target.name)
        let value = e.target && e.target.value ? e.target.value : ""
        this.state.takeProfitTargets[i] = value
        this.setState({
            lastTakeProfitTargetEdited: i
        })

        this.debounceFixTakeProfitTarget()

    }

    fixTakeProfitTarget() {

        let i = this.state.lastTakeProfitTargetEdited
        if (`${this.state.takeProfitTargets[i]}`.length > 0) {
            if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE)
                this.state.takeProfitTargets[i] = getValueWithPrecision(this.state.takeProfitTargets[i], 2)
            else
                this.state.takeProfitTargets[i] = getValueWithPrecision(this.state.takeProfitTargets[i], this.state.tickSize)
            this.setState({
                randomValue: Math.random()
            })
        }
    }

    handleChangeStopLossOption(option) {

        if (option === this.state.stopLossOption)
            return

        let oldSLOption = this.state.stopLossOption
        let oldSLOptionType = 0
        switch (oldSLOption) {
            case c.STOP_LOSS_OPTION_PRICE: oldSLOptionType = 1; break;
            case c.STOP_LOSS_OPTION_CONDITIONAL: oldSLOptionType = 1; break;
            case c.STOP_LOSS_OPTION_PERCENTAGE: oldSLOptionType = 2; break;
            case c.STOP_LOSS_OPTION_TRAILING: oldSLOptionType = 2; break;
        }

        //Attention:
        //STOP_LOSS_OPTION_TRAILING - means that the stop loss option is Trailing
        //STOP_LOSS_OPTION_PERCENTAGE_FROM_TRAILING - means that the user switched off the Trailing option and we default to Percentage
        if (option === c.STOP_LOSS_OPTION_PERCENTAGE_FROM_TRAILING) {
            option = c.STOP_LOSS_OPTION_PERCENTAGE
        }

        let optionType = 0
        switch (option) {
            case c.STOP_LOSS_OPTION_PRICE: optionType = 1; break;
            case c.STOP_LOSS_OPTION_CONDITIONAL: optionType = 1; break;
            case c.STOP_LOSS_OPTION_PERCENTAGE: optionType = 2; break;
            case c.STOP_LOSS_OPTION_TRAILING: optionType = 2; break;
        }

        let transitionDelta = oldSLOptionType - optionType

        if (transitionDelta === 0) {
            //no need for calculations
        } else {
            let {currentPrice} = getCurrentBuyAndSellPrice(this.props.trade)
            if (currentPrice === "0")
                currentPrice = this.state.currentPrice
            else if (transitionDelta === -1) {
                //transition from price to percentage
                let oldPrice = parseFloat(this.state.stopLossPriceOrPercent)
                // let currentPrice = this.props.currentLastBidPrice
                let percent = ((currentPrice - oldPrice) / currentPrice) * 100
                this.state.stopLossPriceOrPercent = getValueWithPrecision(percent, 1)
            } else if (transitionDelta === 1) {
                //transition from percentage to price
                let oldPercent = parseFloat(this.state.stopLossPriceOrPercent)
                // let currentPrice = this.props.currentLastBidPrice
                let sign = -1
                if (this.state.actionType === c.ACTION_TYPE_SELL)
                    sign = 1
                let price = (1 + sign * oldPercent/100) * parseFloat(currentPrice)
                this.state.stopLossPriceOrPercent = getValueWithPrecision(price, this.state.tickSize)
            }
        }

        this.setState({
            stopLossOption: option
        })
    }

    handleChangeStopLossPriceOrPercent(e) {
        this.setState({
            stopLossPriceOrPercent: e.target.value
        })

        this.debounceFixStopLossPriceOrPercent()
    }

    fixStopLossPriceOrPercent() {
        if (`${this.state.stopLossPriceOrPercent}`.length > 0) {
            if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PRICE || this.state.stopLossOption === c.STOP_LOSS_OPTION_CONDITIONAL)
                this.setState({
                    stopLossPriceOrPercent: getValueWithPrecision(this.state.stopLossPriceOrPercent, this.state.tickSize)
                })
            else this.setState({
                stopLossPriceOrPercent: getValueWithPrecision(this.state.stopLossPriceOrPercent, 2)
            })
        }
    }

    goBack() {
        // this.setState({
        //     goBack: true
        // })
        this.props.history.push("/")
    }

    openTakeProfitPanel() {
        this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, result => {
            let currentPrice = parseFloat(result.current_price).toFixed(parseInt(result.tick_size))
            this.setState({
                isTakeProfitPanelOpen: true,
                isTradeDetailsPanelOpen: false,
                currentPrice: currentPrice,
                executeTakeProfitOption: "market",
                executeTakeProfitPrice: currentPrice,
            })
        })
    }

    closeTakeProfitPanel() {
        this.setState({
            isTakeProfitPanelOpen: false,
            isTradeDetailsPanelOpen: true,
        })
    }

    handleChangeExecuteTakeProfitOption(option) {
        if (option !== c.ACTION_TYPE_OPTION_MARKET) {
            let currentPrice = this.state.currentPrice

            let executeTakeProfitPrice =  currentPrice?
                parseFloat(currentPrice) * (option === c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE ? c.LAST_BID_PRICE_MULTIPLIER : 1) : 0
            this.state.executeTakeProfitPrice = brushValue(executeTakeProfitPrice, this.state.tickSize)
        }
        this.setState({
            executeTakeProfitOption: option
        })
    }

    handleChangeExecuteTakeProfitPrice(price) {
        this.setState({
            executeTakeProfitPrice: price
        })

        this.debounceFixExecuteTakeProfitPrice()
    }

    checkExecuteTakeProfitPrice() {
        //This method takes into account that all symbols on binance have the following configuration
        //      {
        // 			"filterType": "PERCENT_PRICE",
        // 			"multiplierUp": "5",
        // 			"multiplierDown": "0.2",
        // 			"avgPriceMins": 5
        // 		}
        //Which means that the higher price cannot be more than 5 x current price
        //and the lower price cannot be lower than 0.2 x current price.

        let executeTakeProfitPrice = parseFloat(this.state.executeTakeProfitPrice)
        let deltaPercent = (executeTakeProfitPrice - this.state.currentPrice) / this.state.currentPrice * 100
        let result = {isError: false, isWarning: false, message: ""}
        if (deltaPercent > 0) {
            if (deltaPercent > 10 && deltaPercent < 500) {
                result.isWarning = true
                result.message = <span>Your price is <b>{deltaPercent.toFixed(0)}%</b> higher than current price.</span>
            } else if (deltaPercent > 500) {
                result.isError = true
                result.message = <span>Your price cannot be <b>{deltaPercent.toFixed(0)}%</b> higher than current price!</span>
            }
        } else if (deltaPercent < 0) {
            if (deltaPercent < -10 && deltaPercent > -20) {
                result.isWarning = true
                result.message = <span>Your price is <b>{deltaPercent.toFixed(0)}%</b> lower than current price.</span>
            } else if (deltaPercent <= -20) {
                result.isError = true
                result.message = <span>Your price cannot be <b>{deltaPercent.toFixed(0)}%</b> lower than current price!</span>
            }
        }
        return result
    }

    fixExecuteTakeProfitPrice() {
        if (`${this.state.executeTakeProfitPrice}`.length > 0) {
            let result = this.checkExecuteTakeProfitPrice()
            this.setState({
                executeTakeProfitPriceWarning: result.isWarning ? result.message: undefined,
                executeTakeProfitPriceError: result.isError ? result.message: undefined,
                executeTakeProfitPrice: getValueWithPrecision(this.state.executeTakeProfitPrice, this.state.tickSize)
            })
        }
    }

    executeTakeProfit() {
        if (this.state.executeTakeProfitOngoing)
            return

        let executeTakeProfitPrice = parseFloat(this.state.executeTakeProfitPrice)
        if ( executeTakeProfitPrice === 0) {
            return
        }

        let result = this.checkExecuteTakeProfitPrice()
        if (result.isError) {
            return
        }

        this.state.executeTakeProfitOngoing = true
        this.state.isTakeProfitPanelOpen = false
        this.state.isTradeDetailsPanelOpen = true
        this.props.updateTradeExecuteTakeProfit({
            tradeId: this.props.trade.order_id,
            executeTakeProfitOption: this.state.executeTakeProfitOption,
            executeTakeProfitPrice: this.state.executeTakeProfitPrice
        }, setTimeout(()=>{
            this.state.executeTakeProfitOngoing = false
        }, 1000))
    }

    openCancelTradePanel() {
        this.setState({
            isCancelTradePanelOpen: true,
            isTradeDetailsPanelOpen: false
        })
    }

    closeCancelTradePanel() {
        this.setState({
            isCancelTradePanelOpen: false,
            isTradeDetailsPanelOpen: true,
        })
    }

    cancelTrade() {
        this.state.isCancelTradePanelOpen = false
        this.state.isTradeDetailsPanelOpen = true
        this.props.updateTradeCancelIt({tradeId: this.props.trade.order_id})
    }

    openDiscardTradePanel() {
        this.setState({
            isDiscardTradePanelOpen: true,
            isTradeDetailsPanelOpen: false
        })
    }

    closeDiscardTradePanel() {
        this.setState({
            isDiscardTradePanelOpen: false,
            isTradeDetailsPanelOpen: true,
        })
    }

    discardTrade() {
        this.state.isDiscardTradePanelOpen = false
        this.state.isTradeDetailsPanelOpen = true
        this.props.updateTradeDiscardIt({tradeId: this.props.trade.order_id})
    }

    openEditEnterTradeConditionsPanel() {
        this.setState({
            isEditEnterTradeConditionsPanelOpen: true,
            isTradeDetailsPanelOpen: false
        })
    }

    closeEditEnterTradeConditionsPanel() {
        this.setState({
            isEditEnterTradeConditionsPanelOpen: false,
            isTradeDetailsPanelOpen: true,
        })
    }

    updateEnterTradeConditions() {
        this.state.isEditEnterTradeConditionsPanelOpen = false
        this.state.isTradeDetailsPanelOpen = true
        this.props.updateEnterTradeConditions({
            tradeId: this.props.trade.order_id,
            fixedPrice: this.state.fixedPrice,
            actionTypeOption: this.state.actionTypeOption
        })
    }

    handleChangeActionTypeOption(actTypeOption) {
        if (this.state.quote && this.state.quote.length < 2)
            return

        if (this.state.tradeType === c.TRADE_TYPE_OLD) {
            if (actTypeOption !== c.ACTION_TYPE_OPTION_FIXED_PRICE)
                return
        }

        this.setState({
            actionTypeOption: actTypeOption
        })
        if (this.state.quote.length > 1 && actTypeOption !== c.ACTION_TYPE_OPTION_MARKET)
            this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, result => {
                let multiplier = 1
                if (actTypeOption === c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE) {
                    multiplier = c.LAST_BID_PRICE_MULTIPLIER
                } else if (actTypeOption === c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE) {
                    multiplier = c.FIRST_ASK_PRICE_MULTIPLIER
                }
                let currentPrice = (parseFloat(result.current_price) * multiplier).toFixed(parseInt(result.tick_size))
                this.setState({
                    fixedPrice: currentPrice
                })
                this.debounceFixFixedPrice()
            })
    }

    getCurrentPrices() {
        this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base})
    }

    fixFixedPrice() {
        if (`${this.state.fixedPrice}`.length > 0) {
            this.setState({
                fixedPrice: getValueWithPrecision(this.state.fixedPrice, this.state.tickSize)
            })
        }
    }

    handleChangeFixedPrice(e) {
        this.setState({
            fixedPrice: e.target.value
        })
        this.debounceFixFixedPrice()
    }

    handleChangeTakeProfitTargetPercentageExecute(e) {
        this.state.lastTakeProfitPercentateExecuteIndex = e.target.name
        this.setState({
            [`take_profit_target_${e.target.name}_percentage_execute`]: e.target.value
        })

        this.debounceFixTakeProfitTargetExecute()
    }

    fixTakeProfitTargetPercentageExecute() {
        let i = parseInt(this.state.lastTakeProfitPercentateExecuteIndex)
        let tpt1, tpt2
        if (i === 1) {
            if (`${this.state.take_profit_target_1_percentage_execute}`.length > 0) {
                tpt1 = getValueWithPrecision(this.state.take_profit_target_1_percentage_execute, 0)
                tpt1 = Math.abs(tpt1)
                if (tpt1 > 100)
                    tpt1 = 100

                tpt2 = 100 - tpt1
            }
        } else if (i === 2) {
            if (`${this.state.take_profit_target_2_percentage_execute}`.length > 0) {
                tpt2 = getValueWithPrecision(this.state.take_profit_target_2_percentage_execute, 0)
                tpt2 = Math.abs(tpt2)
                if (tpt2 > 100)
                    tpt2 = 100
                tpt1 = 100 - tpt2
            }
        }

        if (tpt1 && tpt2) {
            this.setState({
                take_profit_target_1_percentage_execute: tpt1,
                take_profit_target_2_percentage_execute: tpt2,
            })
        }
    }

    componentDidMount() {
        if (!this.props.trade)
            return

        let {currentPrice} = getCurrentBuyAndSellPrice(this.props.trade)
        const {quote, base} = getQuoteAndBase(this.props.trade)
        let {trade} = this.props
        this.state.actionType = trade.action_type
        this.state.takeProfitOption = trade.take_profit_option
        this.state.takeProfitPriceOrPercent = trade.take_profit_price_or_percent
        if (trade.from_SUC) {
            this.state.takeProfitTargets = []
            let l = parseInt(trade.take_profit_targets)
            for(let i = 0; i < l; i++) {
                this.state.takeProfitTargets.push(trade[`take_profit_price_or_percent_${i+1}`])
            }
            this.state.take_profit_target_1_percentage_execute = trade.take_profit_target_1_percentage_execute
            this.state.take_profit_target_2_percentage_execute = trade.take_profit_target_2_percentage_execute
        }

        this.state.stopLossOption = trade.stop_loss_option
        this.state.candlesType = trade.candles_type
        this.state.stopLossPriceOrPercent = trade.stop_loss_price_or_percent
        this.state.executeTakeProfitPrice = currentPrice
        this.state.actionTypeOption = trade.action_type_option
        if (trade.action_type === c.ACTION_TYPE_BUY)
            this.state.fixedPrice = trade.buy_price
        else if (trade.action_type === c.ACTION_TYPE_SELL)
            this.state.fixedPrice = trade.sell_price
        this.state.tradeType = trade.trade_type
        this.state.quote = quote
        this.state.base = base
        this.state.tickSize = parseInt(this.props.trade.tick_size)
        // this.getOrderBookPeriodically()
        this.props.user && initWS(this.props.user.userId, `${quote}${base}`)
    }


    render() {

        // if (this.state.goBack)
        //     return <Redirect to="/"/>

        let {trade} = this.props
        if (!trade)
            return <Redirect to="/"/>
        if (trade.child_order && trade.child_order.order_id) {
            let s = trade.child_order.status
            if (s !== "NEW" && s !== "PARTIALLY_FILLED") {
                return <Redirect to="/tradesummary"/>
            }
        } else {
            let s = trade.status
            if (s === "CANCELED" || s === "ERROR") {
                return <Redirect to="/tradesummary"/>
            }

        }

        const {quote, base} = getQuoteAndBase(trade)
        const ACTION_TYPE = trade.action_type
        const verb = getTradeEnterVerb(trade)
        const verbFirstCaps = capitalize(verb)
        const verbPastTense = getTradeEnterVerbPastTense(trade)
        const posSize = getPosSize(trade)
        const origQty = brushValue(trade.orig_qty)
        const executedQty = brushValue(trade.executed_qty)
        const cummulativeQuoteQty = getCummulativeQuoteQty(trade)
        const {currentPriceDeltaPercent, currentPrice, currentPriceClass} = getCurrentPriceAndDeltaPercent(trade)
        const {maxPrice, maxDelta, minPrice, minDelta} = getMaxMinAndDeltaPercent(trade)
        const {tpContent, slContent} = getTradeConditionsContentForTradeDetails(trade)
        const stateTitle = getTradeStateTitle(trade)
        const tradeState = getTradeState(trade)
        const {hrStateText, hrStateSymbol, hrStateSymbolColor, blinking} = getHRStateForTrade(trade)
        const allowedToEditTradeConditions = getAllowedToEditTradeConditions(trade)
        const allowedToEditEnterTradeConditions = getAllowedToEditEnterTradeConditions(trade, this.props.ASAS)
        const {buyPrice, sellPrice} = getCurrentBuyAndSellPrice(trade)
        const actionTypeOptionExplicit = getActionTypeOptionDetailsExplicit(trade)
        let progress = 0



        if (trade.child_order && trade.child_order.order_id) {
            progress = getProgress(trade)
        } else {
            progress = getProgressForWaitingTrade(trade)
        }


        return <div className={"TradeContent"} style={{display: "flex"}}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <div style={{marginLeft: "0"}}>
                <div style={{maxWidth: "30rem", minWidth: "22rem"}}>
                    {this.state.isTradeDetailsPanelOpen &&
                    <Fade in={this.state.isTradeDetailsPanelOpen}>
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.goBack()}}><ArrowBack/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>Trade <span style={{opacity: "0.5"}}>{trade.order_id}</span> details</div>
                                    <div style={{width: "2.6rem"}}/>
                                </div>
                            </div>
                            <div className={"tradeDetailsPair"}>
                                {quote} <span className={"tradeDetailsPairSlash"}>/</span> {base}
                            </div>
                            {(tradeState === c.TRADE_STATE_WITH_CHILD_ONGOING || tradeState === c.TRADE_STATE_WITH_CHILD_PARTIALLY_FILLED) &&
                            <div className={"tradeDetailsOngoingAction"}>
                                <div className={"vertSpacer1"}/>
                                {blinking && <div className="blink liveBig" style={{textAlign: "center", color: hrStateSymbolColor}}>{hrStateText}{hrStateSymbol}</div>}
                            </div>
                            }
                            <div className={"vertSpacer1"}/>
                            <div className={"tradeDetailsCreationDate"}>
                                {(tradeState === c.TRADE_STATE_PARENT_ONGOING || tradeState === c.TRADE_STATE_PARENT_PARTIALLY_FILLED ||
                                    tradeState === c.TRADE_STATE_WITH_CHILD_ONGOING || tradeState === c.TRADE_STATE_WITH_CHILD_PARTIALLY_FILLED) && <span><b>{stateTitle}</b> for <TimeAgo date={parseInt(trade.time)} formatter={timeAgoFormatter}/> · </span>} created on {timeFormatter(trade.time, true)}
                            </div>

                            {(tradeState === c.TRADE_STATE_PARENT_ONGOING || tradeState === c.TRADE_STATE_PARENT_PARTIALLY_FILLED)&&
                            <div>
                                {ACTION_TYPE === c.ACTION_TYPE_BUY &&
                                    <div>
                                        <div className={"tradeDetailsPosAndQty"}>
                                            <b>{posSize} {base}</b> {verb} <small>{executedQty}/{origQty} {quote}</small>
                                        </div>
                                        <div className={"tradeDetailsPosAndQty"}>
                                        at price <b>{buyPrice} {base}</b>
                                        </div>
                                    </div>}
                                {ACTION_TYPE === c.ACTION_TYPE_SELL &&
                                    <div>
                                        <div className={"tradeDetailsPosAndQty"}>
                                            <b>{posSize} {quote}</b> {verb} for <small>{base}</small>
                                        </div>
                                        <div className={"tradeDetailsPosAndQty"}>
                                            at price <b>{sellPrice} {base}</b>
                                        </div>
                                    </div>}

                                <div style={{margin: "auto", width: "7.6rem"}}>
                                    <div className={"w3-progress-sizer"}>
                                        <div className="w3-progress-container w3-round-xlarge">
                                            <div className="w3-progressbar w3-round-xlarge" style={{width:`${progress}%`}}/>
                                        </div>
                                    </div>
                                    <div className={"progressBarText"}>{progress}% FILLED</div>
                                </div>
                            </div>}

                            {(tradeState === c.TRADE_STATE_WITH_CHILD_ONGOING || tradeState === c.TRADE_STATE_WITH_CHILD_PARTIALLY_FILLED) &&
                                <div>
                                    {ACTION_TYPE === c.ACTION_TYPE_BUY && <div>
                                        <div className={"tradeDetailsPosAndQty"}>
                                            <b>{posSize} {base}</b> {verbPastTense} <small>{executedQty} {quote}</small>
                                        </div>
                                        <div className={"tradeDetailsPosAndQty"}>
                                           at price <b>{buyPrice} {base}</b>
                                        </div>
                                    </div>}
                                    {ACTION_TYPE === c.ACTION_TYPE_SELL && <div>
                                        <div className={"tradeDetailsPosAndQty"}>
                                            <b>{posSize} {quote}</b> {verbPastTense} for <small>{cummulativeQuoteQty} {base}</small>
                                        </div>
                                        <div className={"tradeDetailsPosAndQty"}>
                                            at price <b>{sellPrice} {base}</b>
                                        </div>
                                    </div>}
                                </div>
                            }

                            <div className={`tradeDetailsCurrentPrice ${currentPriceClass}`} title={"Current price"}>
                                {currentPriceDeltaPercent}% · {currentPrice}
                            </div>
                            <div className={"tradeDetailsMaxPrice"}>
                                {maxPrice > 0 && <div className={"maxPriceContainer2"}>max price {maxDelta}% · {maxPrice}</div>}
                                {minPrice > 0 && <div className={"minPriceContainer2"}>min price {minDelta}% · {minPrice}</div>}
                            </div>
                            <div className={"vertSpacer2"}/>
                            {trade.from_SUC && <div style={{padding: "0.6rem"}}>
                                <Paper className={"tradeDetailsTradeConditionsPaper"}>
                                    <div>
                                        {(trade.connected_to_signal === true || trade.connected_to_signal === "true") && <div><FormControlLabel
                                            control={<CustomColorCheckbox checked={true}/>}
                                            label={"Connected"}
                                            onChange={()=>{this.updateTradeFromSUCConnection(Math.random())}}
                                        />
                                            <div className={"smallExplanations2"} style={{marginLeft: "1rem"}}>Receiving updates from Signal. You cannot edit it.</div>
                                        </div>}
                                        {(trade.connected_to_signal === false || trade.connected_to_signal === "false") && <div><FormControlLabel
                                            control={<CustomColorCheckbox checked={false}/>}
                                            label={"Connected"}
                                            onChange={()=>{this.updateTradeFromSUCConnection(Math.random())}}
                                        />
                                            <div className={"smallExplanations2"} style={{marginLeft: "1rem"}}>Won't receive updates from Signal. You can edit it.</div>
                                        </div>}
                                    </div>
                                </Paper>
                            </div>}
                            <div style={{padding: "0.6rem"}}>
                                <Paper className={"tradeDetailsTradeConditionsPaper"}>
                                    <div style={{display: "flex", justifyContent: "space-between" ,fontSize: "0.7rem"}}>
                                        <span>ENTER TRADE CONDITIONS</span>
                                        {allowedToEditEnterTradeConditions && <span style={{cursor: "pointer"}} onClick={()=> {this.openEditEnterTradeConditionsPanel()}}><Create/></span>}
                                    </div>
                                    {trade.action_type_option !== c.ACTION_TYPE_OPTION_MARKET && <div className={"takeProfitStopLossConfigTradeDetails"}>
                                        {verbFirstCaps} price&nbsp;
                                        {ACTION_TYPE === c.ACTION_TYPE_BUY && <b>{buyPrice}</b>}
                                        {ACTION_TYPE === c.ACTION_TYPE_SELL && <span><b>{sellPrice}</b> {base}</span>}
                                    </div>}
                                    <div className={"takeProfitStopLossConfigTradeDetails"}>
                                        {verbFirstCaps} <b>{actionTypeOptionExplicit}</b>
                                    </div>
                                    {(this.props.ASAS &&
                                        this.props.ASAS.suspendActivities === "true" && this.props.ASAS.suspendedActivitiesMap &&
                                        this.props.ASAS.suspendedActivitiesMap[c.SUSPENDED_ACTIVITIES_EDIT_ENTER_TRADE_CONDITIONS])
                                    && <div className={"maintenanceWarningSmall"} style={{marginTop: "0.6rem"}}>Editing enter trade conditions is temporary disabled.</div>}
                                </Paper>
                            </div>
                            <div style={{padding: "0.6rem"}}>
                                <Paper className={"tradeDetailsTradeConditionsPaper"}>
                                    <div style={{display: "flex", justifyContent: "space-between" ,fontSize: "0.7rem"}}>
                                        <span>TRADE CONDITIONS</span>
                                        {allowedToEditTradeConditions && <span style={{cursor: "pointer"}} onClick={()=> {this.openEditTradeConditionsPanel()}}><Create/></span>}
                                    </div>
                                    {tpContent && <div className={"takeProfitStopLossConfigTradeDetails"}>
                                        {tpContent}
                                    </div>}
                                    <div className={"takeProfitStopLossConfigTradeDetails"}>
                                        {slContent}
                                    </div>
                                </Paper>
                            </div>
                            <div style={{padding: "0.6rem"}}>
                                <TradeDetailsMainButtons trade={trade} takeProfit={this.openTakeProfitPanel} cancelTrade={this.openCancelTradePanel}
                                                         discardTrade={this.openDiscardTradePanel} state={tradeState} ASAS={this.props.ASAS}/>
                            </div>
                        </Paper>
                    </Fade>}
                    {this.state.isEditTradeConditionsPanelOpen &&
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeEditTradeConditionsPanel()}}><Clear/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>Trade conditions</div>
                                    <div className={"linkButton"} style={{marginRight: "0.6rem"}} onClick={()=> {this.updateTradeConditionsGeneric()}}>SAVE</div>
                                </div>
                            </div>
                            <div className={"tradeDetailsTradeConditionsPaper"}>
                                {!trade.from_SUC && <TradeConditions
                                                actionType={trade.action_type}
                                                takeProfitOption={this.state.takeProfitOption}
                                                 takeProfitPriceOrPercent={this.state.takeProfitPriceOrPercent}
                                                 stopLossOption={this.state.stopLossOption}
                                                 stopLossPriceOrPercent={this.state.stopLossPriceOrPercent}
                                                 switchWithOrWithoutStopLossTarget={this.switchWithOrWithoutStopLossTarget}
                                                 handleChangeTakeProfitOption={this.handleChangeTakeProfitOption}
                                                 handleChangeTakeProfitPriceOrPercent={this.handleChangeTakeProfitPriceOrPercent}
                                                 handleChangeStopLossOption={this.handleChangeStopLossOption}
                                                 handleChangeStopLossPriceOrPercent={this.handleChangeStopLossPriceOrPercent}
                                                 candlesType={this.state.candlesType}
                                                 handleChangeCandlesType={this.handleChangeCandlesType}
                                                 base={this.state.base}
                                />}
                                {trade.from_SUC && <TradeConditionsFromSUC
                                    actionType={trade.action_type}
                                    takeProfitOption={this.state.takeProfitOption}
                                    takeProfitTargets={this.state.takeProfitTargets}
                                    stopLossOption={this.state.stopLossOption}
                                    stopLossPriceOrPercent={this.state.stopLossPriceOrPercent}
                                    switchWithOrWithoutStopLossTarget={this.switchWithOrWithoutStopLossTarget}
                                    handleChangeTakeProfitOption={this.handleChangeTakeProfitOption2}
                                    handleChangeTakeProfitTarget={this.handleChangeTakeProfitTarget}
                                    handleChangeStopLossOption={this.handleChangeStopLossOption}
                                    handleChangeStopLossPriceOrPercent={this.handleChangeStopLossPriceOrPercent}
                                    take_profit_target_1_percentage_execute={this.state.take_profit_target_1_percentage_execute}
                                    take_profit_target_2_percentage_execute={this.state.take_profit_target_2_percentage_execute}
                                    handleChangeTakeProfitTargetPercentageExecute={this.handleChangeTakeProfitTargetPercentageExecute}
                                    base={this.state.base}
                                />}
                            </div>
                        </Paper>}
                    {this.state.isTakeProfitPanelOpen &&
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeTakeProfitPanel()}}><Clear/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>Take profit</div>
                                    <div style={{width: "2.6rem"}}/>
                                </div>
                            </div>
                            <div className={"tradeDetailsTradeConditionsPaper"}>
                                <TakeProfitConditions trade={trade}
                                                      currentPrice={this.state.currentPrice}
                                                      executeTakeProfitOption={this.state.executeTakeProfitOption}
                                                      executeTakeProfitPrice={this.state.executeTakeProfitPrice}
                                                      executeTakeProfitPriceWarning={this.state.executeTakeProfitPriceWarning}
                                                      executeTakeProfitPriceError={this.state.executeTakeProfitPriceError}
                                                      handleChangeExecuteTakeProfitOption={this.handleChangeExecuteTakeProfitOption}
                                                      handleChangeExecuteTakeProfitPrice={this.handleChangeExecuteTakeProfitPrice}
                                                      executeTakeProfit={this.executeTakeProfit}
                                                      nevermind={this.closeTakeProfitPanel}
                                />
                            </div>
                        </Paper>}
                    {this.state.isCancelTradePanelOpen &&
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeCancelTradePanel()}}><Clear/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>Cancel trade</div>
                                    <div style={{width: "2.6rem"}}/>
                                </div>
                            </div>
                            <div className={"tradeDetailsTradeConditionsPaper"}>
                                <CancelItem cancelItem={this.cancelTrade} nevermind={this.closeCancelTradePanel}
                                    question={"Are you sure you want to cancel this trade?"}
                                    cancelText={"Cancel"} nevermindText={"Nevermind"}/>
                            </div>
                        </Paper>}
                    {this.state.isDiscardTradePanelOpen &&
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeDiscardTradePanel()}}><Clear/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>Discard trade</div>
                                    <div style={{width: "2.6rem"}}/>
                                </div>
                            </div>
                            <div className={"tradeDetailsTradeConditionsPaper"}>
                                <DiscardItem discardItem={this.discardTrade} nevermind={this.closeDiscardTradePanel}
                                             question={"Are you sure you want to discard this trade?"}
                                             discardWarningText={"Discarding this trade will remove it from your waiting or active trades list."}/>
                            </div>
                        </Paper>}
                    {this.state.isEditEnterTradeConditionsPanelOpen &&
                    <Paper style={{backgroundColor: "#101926"}}>
                        <div className={"tradeDetailsTopBar"}>
                            <div className={"tradeDetailsTopBarVerticalCenter"}>
                                <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeEditEnterTradeConditionsPanel()}}><Clear/></div>
                                <div className={"tradeDetailsTopBarTitle"}>Enter trade conditions</div>
                                <div className={"linkButton"} style={{marginRight: "0.6rem"}} onClick={()=> {this.updateEnterTradeConditions()}}>SAVE</div>
                            </div>
                        </div>
                        <div className={"tradeDetailsTradeConditionsPaper"}>
                            <EnterTradeConditions
                                actionType={trade.action_type}
                                fixedPriceLabel={`${verbFirstCaps} price`}
                                actionTypeOptionLabel={`${verbFirstCaps} @`}
                                actionTypeOption={this.state.actionTypeOption}
                                handleChangeActionTypeOption={this.handleChangeActionTypeOption}
                                fixedPrice={this.state.fixedPrice}
                                handleChangeFixedPrice={this.handleChangeFixedPrice}
                                quote={quote}
                                base={base}
                                currentPrice={this.state.executeTakeProfitPrice}
                                hideTitle={false}
                            />
                        </div>
                    </Paper>}
                </div>
            </div>
            <div key={"orderBook"} className={"OrderBook"}>
                <OrderBook base={base} quote={quote} orderBook={this.props.orderBook} size={12}/>
            </div>
            <div className={"vertSpacer1_5"}/>
        </div>
    }

    componentWillUnmount() {
        closeWS()
    }
}


let mapStateToProps = rootState =>({
    user: rootState.authentication.user,
    trade: rootState.trades.openedTrade,
    //orderBook: rootState.newTrade.orderBook,
    orderBook: rootState.orderBook.orderBook,
    ASAS: rootState.administration.asas,
    random: rootState.trades.random, //used to refresh the page for example after a take profit is issued
})

let mapDispatchToProps = dispatch => {
    return {
        openTrade: (trade) => dispatch(openTrade(trade)),
        updateTradeConditions: (props) => dispatch(updateTradeConditions(props)),
        updateTradeFromSUCTradeConditions: (props) => dispatch(updateTradeFromSUCTradeConditions(props)),
        updateEnterTradeConditions: (props) => dispatch(updateEnterTradeConditions(props)),
        updateTradeExecuteTakeProfit: (props, cb) => dispatch(updateTradeExecuteTakeProfit(props, cb)),
        updateTradeFromSUCConnectionToSignal: (props) => dispatch(updateTradeFromSUCConnectionToSignal(props)),
        updateTradeCancelIt: (props) => dispatch(updateTradeCancelIt(props)),
        updateTradeDiscardIt: (props) => dispatch(updateTradeDiscardIt(props)),
        getCurrentPriceOnPair: (props, cb) => dispatch(getCurrentPriceOnPair(props, cb)),
        // getOrderBook: (props) => dispatch(getOrderBook(props)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps) (TradeDetails)
