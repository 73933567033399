import {
    GET_ORDER_BOOK,
} from "../actions/actionTypes"
import {cond, equals, sort} from "ramda"
import Big from "big.js"


let mapToArray = (theMap, compareFn) => {
    let prices = Object.keys(theMap)
    prices = sort(compareFn, prices)
    let theArray = []
    for(let i = 0; i < prices.length; i++) {
        theArray.push([prices[i], theMap[prices[i]]])
    }
    return theArray
}

let orderBookMapToArray = mapOrderBook => {
    if (!mapOrderBook)
        return
    const compareValueFn = cond([
        [equals('asks'), () => (a, b) => (new Big(a)).minus(b)],
        [equals('bids'), () => (a, b) => (new Big(b)).minus(a)],
    ])
    mapOrderBook.asks = mapToArray(mapOrderBook.asks, compareValueFn('asks'))
    mapOrderBook.bids = mapToArray(mapOrderBook.bids, compareValueFn('bids'))
}

export default function (state = {}, action) {
    switch (action.type) {

        case GET_ORDER_BOOK: {
            let orderBook = JSON.parse(action.payload)
            if (orderBook.isFutures) {
                orderBookMapToArray(orderBook)
            }
            let bids = orderBook.bids
            let currentPrice = 0
            currentPrice = bids[0] ? bids[0][0]: 0

            return {
                ...state,
                ...{orderBook, currentPrice}
            }
        }

        default:
            return state
    }
}
