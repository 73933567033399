import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {brushValue, timeFormatter2} from "./UISupportFns";
import c from "../../constants";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

export default function SignalBox(props) {
    const {signal, autoClosed, signalActionOrFuturesType, stopTargetTitle, isSuperAdmin, newV} = props
    if (!signal)
        return null
    let actionOrFuturesType = undefined
    if (signal.signalType === c.SIGNAL_TYPE_SPOT) {
        actionOrFuturesType = signal.actionType
    } else if (signal.signalType === c.SIGNAL_TYPE_FUTURES) {
        actionOrFuturesType = signal.futuresType
    }
    return (
        <div className={`${signal.futuresType ? signal.futuresType.toLowerCase(): ''}_FuturesTradeBand`} style={{opacity: "0.8", padding: "1rem", width: "22rem", backgroundColor: "#1A222F"}}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div id={"leftContainer"}>
                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                        <div style={{marginBottom: "1rem"}}>
                            <span style={{fontSize: "1.2rem"}}>{signal.quoteAsset}</span>/<span style={{fontSize: "1.2rem"}}>{signal.baseAsset}</span>
                        </div>
                        <div style={{marginLeft:"1rem"}}>
                            {signal.status === c.SIGNALS_STATUS_WAITING && <span className="blink live" style={{color: "#68FF7D"}}>▶</span>}
                            {signal.status === c.SIGNALS_STATUS_RUNNING && <span className="blink live" style={{color: "#68FF7D"}}>▶</span>}
                            {signal.status === c.SIGNALS_STATUS_CLOSED && <span><span className="live" style={{color: "#FE484F"}}>◼</span>
                                {!autoClosed && <span style={{marginLeft: "1rem"}} title={"Closed manually"}>(man)</span>}
                                {autoClosed && <span style={{marginLeft: "1rem"}} title={"Closed automatically"}>(auto)</span>}
                                                    </span>}
                        </div>
                    </div>
                    <div>
                        {signal.actionType === c.ACTION_TYPE_BUY && <span style={{opacity: "0.7"}}>buy </span>}
                        {signal.actionType === c.ACTION_TYPE_SELL && <span style={{opacity: "0.7"}}>sell </span>}
                        {(signal.futuresType === c.FUTURES_TYPE_LONG || signal.futuresType === c.FUTURES_TYPE_SHORT) && <span style={{opacity: "0.7"}}>entry </span>}
                        {signal.enterTradePriceFrom === signal.enterTradePriceTo && <b>{brushValue(signal.enterTradePriceFrom)}</b>}
                        {signal.enterTradePriceFrom !== signal.enterTradePriceTo && <span> <b>{brushValue(signal.enterTradePriceFrom)}</b> - <b>{brushValue(signal.enterTradePriceTo)}</b></span>}
                       </div>
                    {(signal.takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE || signal.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE) &&  signal.takeProfitTargets.map((elem, index) => {
                        let showPlus = signal.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE
                        let showPercentage = signal.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE
                        let title = elem.reached ? "Target reached" : ""
                        return <div title={title} key={`takeProfitTarget_${elem._id}`}>
                            <span style={{opacity: "0.7"}}>{`TP target #${index+1}`}</span> <b>{showPlus && <span>+</span>}{brushValue(elem.price)}{showPercentage && <span>%</span>}</b>
                            {elem.reached && <span style={{color: "#64FE7C", marginLeft: "0.6rem"}}>✓</span>}
                        </div>
                    })}
                    {signal.takeProfitOption === c.TAKE_PROFIT_OPTION_MANUAL && <div key={`takeProfitTarget_MANUAL`}>
                        <span style={{opacity: "0.7"}}>TP</span> <b>BY TRADER</b>
                    </div>}
                    {(signal.stopLossOption !== c.STOP_LOSS_OPTION_MANUAL && signal.stopLossOption !== c.STOP_LOSS_OPTION_CONDITIONAL) && <div title={stopTargetTitle}>
                        <span style={{opacity: "0.7"}}>SL target</span> <b>{signal.stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE && <span>-</span>}{brushValue(signal.stopLossTarget.price)}{signal.stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE &&
                    <span>%</span>}</b>
                        {signal.stopLossTarget.reached && <span style={{color: "#FB4A4A", marginLeft: "0.6rem"}}>✓</span>}
                    </div>}
                    {signal.stopLossOption === c.STOP_LOSS_OPTION_CONDITIONAL && <div style={{fontWeight: "bold"}} title={stopTargetTitle}>
                        <div><span style={{opacity: "0.7"}}>SL</span> <span style={{opacity: "1"}}>{signal.stopLossTarget.price} {signal.stopLossTarget.reached && <span style={{color: "#FB4A4A", marginLeft: "0.6rem"}}>✓</span>}</span></div>
                        <div style={{fontWeight: "normal", opacity: "0.8", marginLeft: "0.2rem"}}>on close of {c.CANDLES_TYPES_HR_MAP[signal.candlesType]} candles</div>

                    </div>}
                    {signal.stopLossOption === c.STOP_LOSS_OPTION_MANUAL && <div title={stopTargetTitle}>
                        <span style={{opacity: "0.7"}}>SL</span> <b>BY TRADER</b>
                    </div>}
                    {(signal.timeFrame && signal.timeFrame.length > 0) && <div>time frame {signal.timeFrame},</div>}
                    {(signal.tvChartURL && signal.tvChartURL.length > 0) && <div>chart <a href={signal.tvChartURL} target={"blank"}>here</a></div>}
                    {(signal.comments && signal.comments.length > 0) && <div>
                        <div>comments:</div>
                        {signal.comments.map((elem, index) => {
                            return <div style={{fontSize: "0.76rem", opacity: "0.66"}}>
                                <div style={{marginLeft: "0.2rem"}} title={`${elem.author} on ${timeFormatter2(elem.creationDate, true)}`}>{elem.comment}</div>
                            </div>
                        })}
                    </div>}
                </div>
                <div id={"rightContainer"}>
                    {signal.signalType === c.SIGNAL_TYPE_SPOT &&
                        <div style={{textAlign: "right"}}><span style={{fontWeight: "bold", opacity: "0.8"}}>S</span><span style={{fontWeight: "bold", opacity: "0.7"}}>PO</span><span style={{fontWeight: "bold", opacity: "0.85"}}>T</span>
                            {/*<div style={{fontSize: "0.7rem", opacity: "0.7"}}>{signalActionOrFuturesType[actionOrFuturesType]}</div>*/}
                        </div>}
                    {signal.signalType === c.SIGNAL_TYPE_FUTURES && <div style={{textAlign: "right"}}><span style={{fontWeight: "bold", opacity: "0.8"}}>FU</span><span style={{fontWeight: "bold", opacity: "0.7"}}>TURE</span><span style={{fontWeight: "bold", opacity: "0.8"}}>S</span>
                        <div><span style={{opacity: "0.8"}}>{signal.leverage}</span><b>X</b> <span style={{opacity: "0.8"}}>{signalActionOrFuturesType[actionOrFuturesType]}</span></div>
                    </div>}
                    {signal.currentPrice && <div>
                        <div className={"currentPriceStatsContainer"} title={"Current price"}>
                            <div className={signal.currentPriceClass}>{signal.currentPriceDeltaPercent}%</div>
                            <span className={"horizSpacer"}/>
                            <div className={signal.currentPriceClass}>{signal.currentPrice}</div>
                        </div>
                        {signal.maxPrice !== "0" &&<div className={"maxPriceContainer"} title={"Max price during signal lifetime"}>
                            <span>{signal.maxDelta}% · {signal.maxPrice}</span>
                        </div>}
                        {signal.minPrice !== "0" && <div className={"minPriceContainer"} title={"Min price during signal lifetime"}>
                            <span>{signal.minDelta}% · {signal.minPrice}</span>
                        </div>}
                    </div>}
                </div>
            </div>
            {(signal.riskRewardRatio > 0 || signal.potentialProfit > 0 || signal.potentialLoss > 0) &&
            <div style={{marginTop: "0.6rem", marginBottom: "0.6rem", opacity: "0.6"}}>
                {signal.riskRewardRatio > 0 && <div>RRR <b>{signal.riskRewardRatio}</b>:1</div>}
                {signal.potentialProfit > 0 && <div>P.Profit <b>+{signal.potentialProfit}</b> {signal.baseAsset}</div>}
                {signal.potentialLoss > 0 && <div>P.Loss <b>-{signal.potentialProfit}</b> {signal.baseAsset}</div>}

            </div>}

            {signal.activeStateStats && <div>
                {(signal.activeStateStats.sucsNo > 0 || signal.activeStateStats.tradesNo > 0) && <div style={{height: "1rem", width: "100%", borderTop: "1px solid #103856", marginTop: "1rem"}}/>}
                {signal.activeStateStats.sucsNo > 0 && <div>Signal sent to {signal.activeStateStats.sucsNo} user{signal.activeStateStats.sucsNo > 1 &&<span>s</span>}</div>}
                {signal.activeStateStats.tradesNo > 0 && <div>Signal produced {signal.activeStateStats.tradesNo} trade{signal.activeStateStats.tradesNo > 1 && <span>s</span>}
                    {signal.activeStateStats.disconnectedTradesNo === 0 && <small> (all connected)</small>}
                    {signal.activeStateStats.disconnectedTradesNo > 0 && <small> ({signal.activeStateStats.disconnectedTradesNo} disconnected)</small>}
                </div>}
            </div>}
            {signal.status === c.SIGNALS_STATUS_CLOSED && <div>
                <div style={{height: "1rem", width: "100%", borderBottom: "1px solid #3badff", marginBottom: "0.6rem"}}/>
                {signal.profitPercentage >= 0 &&  <div>Profit <span style={{fontWeight: "bold", color: "#68FF7D"}}>+{signal.profitPercentage.toFixed(2)}%</span></div>}
                {signal.profitPercentage < 0 &&  <div>Profit <span style={{fontWeight: "bold", color: "#FE484F"}}>{signal.profitPercentage.toFixed(2)}%</span></div>}
            </div>}

            {signal.closedStateStats && <div>
                <div style={{height: "1rem", width: "100%", borderBottom: "1px solid #3badff", marginBottom: "0.6rem"}}/>
                <div>Closed trades: {signal.closedStateStats.tradesNo}</div>
                <div>{signal.closedStateStats.profitTradesNo} Winners/ {signal.closedStateStats.lossTradesNo} Losers</div>
                <div>Winners gained {brushValue(signal.closedStateStats.profitSum)}</div>
                <div>Losers lost {brushValue(signal.closedStateStats.lossSum)}</div>
                <div>Overall made {brushValue(signal.closedStateStats.profitSum + signal.closedStateStats.lossSum)}</div>
                <div style={{height: "1rem", width: "100%", borderTop: "1px solid #3badff", marginTop: "0.6rem"}}/>
            </div>}
            {(newV && isSuperAdmin) && <div style={{fontSize: "0.8rem", opacity: "0.8", marginTop: "0.6rem"}}>Created by {signal.authorFullName}</div>}
            {!newV && signal.authorFullName && <div style={{fontSize: "0.8rem", opacity: "0.8", marginTop: "0.6rem"}}>Created by {signal.authorFullName}</div>}
            {(!autoClosed && signal.closerFullName) && signal.status === c.SIGNALS_STATUS_CLOSED && <div style={{fontSize: "0.8rem", opacity: "0.8", marginTop: "0.6rem"}}>Closed by {signal.closerFullName} on {timeFormatter2(signal.lastUpdateDate, true)}</div>}
            <div style={{fontSize: "0.8rem", opacity: "0.8", display: "flex", justifyContent: "space-between", marginTop: "1rem"}}>
                <div>{signal.id}</div>
                <div>{timeFormatter2(signal.creationDate, true)}</div>
            </div>
        </div>
    )
}
