import { SettingsInputAntenna } from "@material-ui/icons";
import c from "../../../constants";
import React, { Component } from "react";
import NavTabs from "../../fncomponents/NavTabs";
import UserSignalSettingsC from "./UserSignalSettingsC";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withGetScreenExt from "../../../withGetScreenExt";
import { hasFuturesActivated } from "../../../redux/actions/profile";

class SignalSettings2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
    };
    this.changeTab = this.changeTab.bind(this);
  }

  changeTab(event, newValue) {
    this.setState({ selectedTab: newValue });
  }

  componentDidMount() {
    this.props.hasFuturesActivated();
  }

  render() {
    let { user, isMobile, signalSettingsUpdateErrors } = this.props;

    if (user.signalsState === c.USER_SIGNALS_STATE_RECEIVING) {
      let tabs = [];
      tabs.push({
        title: "BTC",
        content: <UserSignalSettingsC type="SPOT" coin={"BTC"} />,
      });
      tabs.push({
        title: "USDT",
        content: <UserSignalSettingsC type="SPOT" coin={"USDT"} />,
      });

      if (this.props._hasFuturesActivated) {
        tabs.push({
          title: "USDT Futures",
          content: <UserSignalSettingsC type="FUTURES" coin={"USDT"} />,
        });
      }

      return (
        <div style={{ display: "flex", width: "22rem", marginTop: "2rem" }}>
          <div className={"normalIconContainer"}>
            <SettingsInputAntenna />
          </div>
          <div>
            <b>Signals Settings</b>
            <br />
            <div className={"smallExplanations"}>
              Configure your account Signals behaviour.
            </div>
            {signalSettingsUpdateErrors && (
              <div
                style={{
                  marginLeft: "1.2rem",
                  marginTop: "0.46rem",
                  minHeight: "0.66rem",
                  color: "#FE484F",
                  opacity: "0.6",
                }}
              >
                {signalSettingsUpdateErrors.map((elem, index) => {
                  return <div>{elem}</div>;
                })}
              </div>
            )}
            <div></div>
            <NavTabs
              tabs={tabs}
              selectedTab={this.state.selectedTab}
              changeTab={this.changeTab}
              tabContainerClass={"tabsContainer"}
              isMobile={isMobile}
            />
          </div>
        </div>
      );
    } else if (user.signalsState === c.USER_SIGNALS_STATE_JOIN_REQUEST_SENT) {
    } else if (user.signalsState === c.USER_SIGNALS_STATE_NOT_RECEIVING) {
      return null;
    }
  }
}

let mapStateToProps = (rootState) => ({
  _hasFuturesActivated: rootState.profile.hasFuturesActivated,
  signalSettingsUpdateErrors: rootState.profile.signalSettingsUpdateErrors
});

let mapDispatchToProps = (dispatch) => {
  return {
    hasFuturesActivated: (cb) => dispatch(hasFuturesActivated(cb)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withGetScreenExt(SignalSettings2)));
