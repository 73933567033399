import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { List, ListItem } from "@material-ui/core"
import { format } from "date-fns"
import {
  getRecentDeposits, getRecentInternalTransfers, getRecentInvestmentPurchases,
  getRecentTransfers,
  getRecentWithdrawals,
} from "../../../redux/actions/wallet"
import {AllTransactionsCustomHeader} from "../../fncomponents/CustomHeaders"
import constants from "../../../constants"
import {brushValue} from "../../fncomponents/UISupportFns"
import {
  isDecrement,
  renderType,
  enhanceWithType,
  updateStatus
} from "./WalletUtilities"

const { TRANSACTION_MINUS_COLOR, TRANSACTION_PLUS_COLOR } = constants;


const mapRecordToRow = (myId) => (el) =>
  (
    <ListItem key={el.creationDate}>
      <div className="all-transactions__list__record">
        <div
          className="all-transactions__list__record__amount"
          style={{
            color: isDecrement(myId, el)
              ? TRANSACTION_MINUS_COLOR
              : TRANSACTION_PLUS_COLOR,
          }}
        >
          {isDecrement(myId, el) ? "-" : "+"} {brushValue(el.amount)}
        </div>
        <div className="all-transactions__list__record__coin">{el.coin} </div>
        <div className="all-transactions__list__record__recordedDate">
          {format(new Date(el.creationDate), "MMM d, yyyy hh:mm")}
        </div>
        <div className="all-transactions__list__record__status">
          {el.status}{" "}
        </div>

        <div className="all-transactions__list__record__informations">
          {renderType(myId, el)}
        </div>
      </div>
    </ListItem>
  );

export const AllTransactions = ({ changeRenderCustomHeader }) => {
  const recentWithdrawals = useSelector((state) => state.wallet.recentWithdrawals);
  const recentDeposits = useSelector((state) => state.wallet.recentDeposits);
  const recentTransfers = useSelector((state) => state.wallet.recentTransfers);
  const recentInternalTransfers = useSelector((state) => state.wallet.recentInternalTransfers);
  const recentInvestmentPurchases = useSelector((state) => state.wallet.recentInvestmentPurchases);
  const user = useSelector((state) => state.authentication.user);

  const dispatch = useDispatch();

  useEffect(() => {
    getRecentTransfers()(dispatch);
    getRecentInternalTransfers()(dispatch)
    getRecentDeposits()(dispatch);
    getRecentWithdrawals()(dispatch);
    getRecentInvestmentPurchases()(dispatch);
    changeRenderCustomHeader(() => <AllTransactionsCustomHeader />);
  }, []);

  const allRecords = enhanceWithType("transfer")(recentTransfers)
      ?.concat(enhanceWithType("internalTransfer")(recentInternalTransfers))
      ?.concat(enhanceWithType("deposit")(recentDeposits))
      ?.concat(enhanceWithType("withdrawal")(recentWithdrawals))
      ?.concat(enhanceWithType("investment")(recentInvestmentPurchases));

  return (
    <div>
      {recentWithdrawals && recentDeposits && recentTransfers && recentInternalTransfers && recentInvestmentPurchases && (
        <List
          className="all-transactions__list"
          style={{ margin: "0 auto", textAlign: "center" }}
        >
          {allRecords.length > 0
            ? updateStatus(allRecords).sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()).map(mapRecordToRow(user._id))
            : "No transactions"}
        </List>
      )}
    </div>
  );
};
