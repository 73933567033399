import axios from './axiosFabric'
import {handleError} from "./errorHandler";
import {
    WHA_NAME,
    WHA_REGISTER_LINK,
} from './actionTypes'

export const getWhaRegisterLink = () => dispatch => {
    axios.get(`/wha/getregisterlink`)
        .then(res => {
            dispatch({
                type: WHA_REGISTER_LINK,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getName = () => dispatch => {
    axios.get(`/wha/getname`)
        .then(res => {
            dispatch({
                type: WHA_NAME,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}