import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import {connect} from "react-redux"
import {ArrowBack} from "@material-ui/icons"
import {
    brushValue,
    formatTimeDuration,
    getCurrentBuyAndSellPrice,
    getExecutedQtyAndCummulativeQuoteQty,
    getExecutedQtyAtInput,
    getExecutedQtyAtOutput,
    getMaxMinAndDeltaPercent,
    getPosSize,
    getQuoteAndBase,
    getReceivedPosSize,
    getTradeConditionsContentForTradeDetails, getTradeEnterPastTenseVerb,
    getTradeEnterVerb,
    getTradeExitVerb,
    getTradeProfitOrLoss,
    getTradeState,
    hasTradeChild,
    timeFormatter,
} from "../../fncomponents/UISupportFns";
import {Paper} from "@material-ui/core";
import c from "../../../constants";
import Fade from '@material-ui/core/Fade';
import {getTitle} from "../../fncomponents/PageTitleUtils";
import {Helmet} from "react-helmet/es/Helmet";



class TradeSummary extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isTradeDetailsPanelOpen: true,
            isDiscardTradePanelOpen: false,
            goBack: false,
        }

        this.goBack = this.goBack.bind(this)
    }

    goBack() {
       this.props.history.goBack()
    }

    render() {

        let {trade} = this.props
        if (!trade)
            return <Redirect to="/"/>

        const {quote, base} = getQuoteAndBase(trade)
        const ACTION_TYPE = trade.action_type
        const verb = getTradeEnterVerb(trade)
        const verbPast = getTradeEnterPastTenseVerb(trade)
        const exitVerb = getTradeExitVerb(trade)
        const {maxPrice, maxDelta, minPrice, minDelta} = getMaxMinAndDeltaPercent(trade)
        const {tpContent, slContent} = getTradeConditionsContentForTradeDetails(trade)
        const tradeState = getTradeState(trade)
        const tradeProfitOrLoss = getTradeProfitOrLoss(trade)
        const posSize = getPosSize(trade)
        const receivedPosSize = getReceivedPosSize(trade)
        const executedQtyInput = getExecutedQtyAtInput(trade)
        const {executedQtyAtOutputNo, executedQtyAtOutput} = getExecutedQtyAtOutput(trade)
        const origQty = brushValue(trade.orig_qty, trade.precision)
        const {buyPrice, sellPrice} = getCurrentBuyAndSellPrice(trade)
        const hasATradeChild = hasTradeChild(trade)
        let {executedQty, cummulativeQuoteQty} = getExecutedQtyAndCummulativeQuoteQty(trade)

        return <div className={"TradeContent"} style={{display: "flex"}}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
                <div style={{maxWidth: "30rem", minWidth: "22rem"}}>
                    {this.state.isTradeDetailsPanelOpen &&
                    <Fade in={this.state.isTradeDetailsPanelOpen}>
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.goBack()}}><ArrowBack/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>Completed trade <span style={{opacity: "0.5"}}>{trade.order_id}</span> details</div>
                                    <div style={{width: "2.6rem"}}/>
                                </div>
                            </div>

                            <div className={"tradeDetailsPair"} >
                                {quote} <span className={"tradeDetailsPairSlash"}>/</span> {base}
                            </div>
                            <div className={"vertSpacer1"}/>
                            {(tradeState === c.TRADE_STATE_WITH_CHILD_FILLED || tradeState === c.TRADE_STATE_WITH_CHILD_CANCELED) &&
                                <div>
                                    {tradeProfitOrLoss.profit &&
                                        <div>
                                            <div style={{color: "#64FE7C", textAlign: "center"}}>You made ↑</div>
                                            <div style={{color: "#64FE7C", textAlign: "center", fontSize: "1.6rem", lineHeight: "1.8rem"}}>
                                                +{tradeProfitOrLoss.deltaPercent}% <small>{tradeProfitOrLoss.deltaValue}&nbsp;
                                                {ACTION_TYPE === c.ACTION_TYPE_BUY && <span>{base}</span>}
                                                {ACTION_TYPE === c.ACTION_TYPE_SELL && <span>{quote}</span>}</small>
                                            </div>
                                        </div>
                                    }
                                    {tradeProfitOrLoss.loss &&
                                        <div>
                                            <div style={{color: "#FB4A4A", textAlign: "center"}}>You lost ↓</div>
                                            <div style={{color: "#FB4A4A", textAlign: "center", fontSize: "1.6rem", lineHeight: "1.8rem"}}>
                                                {tradeProfitOrLoss.deltaPercent}% <small>{tradeProfitOrLoss.deltaValue}&nbsp;
                                                {ACTION_TYPE === c.ACTION_TYPE_BUY && <span>{base}</span>}
                                                {ACTION_TYPE === c.ACTION_TYPE_SELL && <span>{quote}</span>}
                                            </small></div>
                                        </div>
                                    }
                                </div>
                            }
                            <div className={"vertSpacer1"}/>
                            {trade.last_action === c.TRADE_OP_AUTO_TAKE_PROFIT &&
                                <div style={{textAlign: "center"}}>
                                    <span>by <b>Taking profit</b> (auto)</span><br/>
                                </div>
                            }
                            {trade.last_action === c.TRADE_OP_AUTO_TAKE_LOSS &&
                                <div style={{textAlign: "center"}}>
                                    <span>by <b>Stopping the loss</b> (auto)</span><br/>
                                </div>
                            }
                            {trade.last_action === c.TRADE_OP_USER_TAKES_PROFIT &&
                                <div style={{textAlign: "center"}}>
                                    <span>by <b>Taking profit</b> (by you)</span><br/>
                                </div>
                            }
                            {(tradeState === c.TRADE_STATE_WITH_CHILD_CANCELED || tradeState === c.TRADE_STATE_WITH_CHILD_CANCELED_AND_UNFULFILLED ||
                                tradeState === c.TRADE_STATE_PARENT_CANCELED) &&
                                <div style={{color: "#E8E288", textAlign: "center", fontSize: "1.6rem", lineHeight: "1.8rem"}}>
                                    Canceled
                                </div>
                            }
                            {tradeState === c.TRADE_STATE_PARENT_IN_ERROR &&
                            <div>
                                <div style={{color: "#FB4A4A", textAlign: "center", fontSize: "1.6rem", lineHeight: "1.8rem"}}>
                                    Error
                                </div>
                                <div style={{textAlign: "center", width: "22rem"}}>
                                    {trade.error}
                                </div>
                            </div>
                            }
                            {tradeState === c.TRADE_STATE_WITH_CHILD_IN_ERROR &&
                                <div>
                                    <div style={{color: "#FB4A4A", textAlign: "center", fontSize: "1.6rem", lineHeight: "1.8rem"}}>
                                        Error
                                    </div>
                                    <div style={{textAlign: "center", width: "22rem"}}>
                                        {trade.child_order.error}
                                    </div>
                                </div>
                            }
                            <div className={"vertSpacer2"}/>
                            <div className={"tradeDetailsCreationDate"} style={{fontSize: "0.9rem", textAlign: "center"}}>
                                <span title={"Created on"}>{timeFormatter(trade.time, true)}</span>
                                {(tradeState !== c.TRADE_STATE_PARENT_CANCELED && tradeState !== c.TRADE_STATE_PARENT_IN_ERROR) && <span title={"Closed on"}>&nbsp;→&nbsp;{timeFormatter(trade.last_action_time, true)}</span>}
                                <br/>
                                {(tradeState !== c.TRADE_STATE_PARENT_CANCELED && tradeState !== c.TRADE_STATE_PARENT_IN_ERROR) && <span title={"Duration"}>{formatTimeDuration(trade.last_action_time, trade.time)}</span>}
                            </div>
                            {(!hasATradeChild && ACTION_TYPE === c.ACTION_TYPE_BUY) &&
                                <div className={"tradeDetailsPosAndQty"}>
                                    <b>{posSize} {base}</b> {verb} <small>{origQty} {quote}</small> at price <b>{buyPrice}</b><br/>
                                    {tradeState === c.TRADE_STATE_PARENT_CANCELED && <div>(canceled at {cummulativeQuoteQty} {base} {verbPast} {executedQty} {quote})</div>}
                                </div>

                            }
                            {(!hasATradeChild && ACTION_TYPE === c.ACTION_TYPE_SELL) &&
                            <div className={"tradeDetailsPosAndQty"}>
                                <b>{posSize} {quote}</b> {verb} for <small>{quote}</small> at price <b>{sellPrice}</b> {base}<br/>
                            </div>
                            }
                            {(hasATradeChild && ACTION_TYPE === c.ACTION_TYPE_BUY) &&
                                <div className={"tradeDetailsPosAndQty"}>
                                    <b>{posSize} {base}</b> {verb} <small>{executedQtyInput} {quote}</small> at price <b>{buyPrice}</b><br/>
                                    {(executedQtyAtOutputNo > 0 && receivedPosSize > 0) && <div><small>{executedQtyAtOutput} {quote}</small> {exitVerb} at price <b>{tradeProfitOrLoss.exitPrice}</b> for <b>{receivedPosSize} {base}</b></div>}
                                    {executedQtyAtOutputNo === 0 && <div><small>{executedQtyAtOutput} {quote}</small> {exitVerb}</div>}
                                </div>}
                            {(hasATradeChild && ACTION_TYPE === c.ACTION_TYPE_SELL) &&
                            <div className={"tradeDetailsPosAndQty"}>
                                <b>{posSize} {quote}</b> {verb} for <small>{cummulativeQuoteQty} {base}</small> at price <b>{sellPrice}</b><br/>
                                {(cummulativeQuoteQty > 0 && receivedPosSize > 0) && <div><small>{cummulativeQuoteQty} {base}</small> {exitVerb} <b>{receivedPosSize} {quote}</b> at price <b>{tradeProfitOrLoss.exitPrice}</b></div>}
                                {cummulativeQuoteQty === 0 && <div><small>{cummulativeQuoteQty} {base}</small> {exitVerb}</div>}
                            </div>}

                            <div className={"vertSpacer"}/>
                            <div className={"tradeDetailsCreationDate"}>
                                <div><b>During trade</b></div>
                                {maxPrice > 0 && <div className={"maxPriceContainer2"}>max price {maxDelta}% · {maxPrice}</div>}
                                {minPrice > 0 && <div className={"minPriceContainer2"}>min price {minDelta}% · {minPrice}</div>}
                            </div>
                            <div className={"vertSpacer2"}/>
                            <div className={"tradeDetailsCreationDate"}>
                                <span><b>Trade conditions</b></span><br/>
                                <div style={{width: "18rem", margin: "auto"}}>{tpContent}</div>
                                <span>{slContent}</span>
                            </div>
                        </Paper>
                    </Fade>}
                </div>
            </div>
    }
}


let mapStateToProps = rootState =>({
    trade: rootState.trades.openedTrade,
})

let mapDispatchToProps = dispatch => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps) (TradeSummary)
