import React from "react"
import Button from '@material-ui/core/Button'
import InputAdornment from "@material-ui/core/InputAdornment"
import {TextField} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Gavel from '@material-ui/icons/Gavel'
import c from '../../constants'
import {scientificToDecimal, brushValue} from "./UISupportFns"

const useStyles = makeStyles(theme => ({

    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 270,
        backgroundColor: '#101A26'
    },
    button: {
        minWidth: 100,
        marginRight: 10,
        marginTop: 10,
        borderRadius: 100,
        height: 50
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    }
}))

export default function FuturesOrderConditions({fixedPriceLabel,
                                      actionTypeOptionLabel, futuresType, actionTypeOption,
                                      handleChangeActionTypeOption,
                                      quote,
                                      base,
                                      fixedPrice,
                                      handleChangeFixedPrice,
                                      currentPrice}) {
    const classes = useStyles()
    const [selectedActionTypeOption, setSelectedActionTypeOption] = React.useState(0)
    const [textFieldEnabled, setTextFieldEnabled] = React.useState(true)
    const [_currentPrice, setCurrentPrice] = React.useState("0")

    React.useEffect(()=> {
        if (quote.length < 2) {
            setSelectedActionTypeOption(-1)
            return
        }

        if (currentPrice >= 0) {
            setCurrentPrice(scientificToDecimal(`${currentPrice}`))
        }

        switch (actionTypeOption) {
            case c.FUTURES_ACTION_TYPE_OPTION_FIXED_PRICE: setSelectedActionTypeOption(0); break
            case c.FUTURES_ACTION_TYPE_OPTION_MARKET: setSelectedActionTypeOption(1); break
            case c.FUTURES_ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE: setSelectedActionTypeOption(2); break
            case c.FUTURES_ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE: setSelectedActionTypeOption(4); break
            default : setSelectedActionTypeOption(-1); break
        }

        if (actionTypeOption === c.FUTURES_ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE ||
            actionTypeOption === c.FUTURES_ACTION_TYPE_OPTION_MARKET) {
            setTextFieldEnabled(false)
        } else {
            setTextFieldEnabled(true)
        }
    })

    return (<div className={"wizardSectionContainer"}>
        <div>{fixedPriceLabel}</div>
        <div style={{marginTop: "1em"}}>
            <TextField
                id="outlined-adornment-weight_enter_trade_conditions"
                className={classes.textField}
                variant="outlined"
                margin="dense"
                disabled={!textFieldEnabled}
                value={fixedPrice}
                onChange={handleChangeFixedPrice}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {selectedActionTypeOption === 3 && <span>&le;</span>}
                        </InputAdornment>
                    ),
                    endAdornment: <InputAdornment position="end">
                        <div style={{display: "flex"}}>
                            {base}
                        </div>
                    </InputAdornment>,
                }}
            />
            {currentPrice > 0 && <div className={"currentLastBidPrice"}>Last price <b>&nbsp;{_currentPrice}&nbsp;{base}</b></div>}
        </div>
        <div className={"vertSpacer"}/>
        <div>{actionTypeOptionLabel}</div>
        <div style={{display: "flex", justifyContent: "flex-start", flexWrap: "wrap", marginBottom: "0.6em", marginLeft: "0.6em"}}>
            <Button className={classes.button} variant={selectedActionTypeOption === 0 ? "contained": "outlined"} color={"primary"}
                    onClick={()=>{handleChangeActionTypeOption(c.FUTURES_ACTION_TYPE_OPTION_FIXED_PRICE)}}>Fixed price</Button>
            <Button className={classes.button} variant={selectedActionTypeOption === 1 ? "contained": "outlined"} color={"primary"}
                    onClick={()=>{handleChangeActionTypeOption(c.FUTURES_ACTION_TYPE_OPTION_MARKET)}}>Market</Button>
            {futuresType === c.FUTURES_TYPE_LONG && <Button className={classes.button} variant={selectedActionTypeOption === 2 ? "contained": "outlined"} color={"primary"}
                    onClick={()=>{handleChangeActionTypeOption(c.FUTURES_ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE)}}>Just above bid</Button>}
            {futuresType === c.FUTURES_TYPE_SHORT && <Button className={classes.button} variant={selectedActionTypeOption === 4 ? "contained": "outlined"} color={"primary"}
                                                         onClick={()=>{handleChangeActionTypeOption(c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE)}}>Just below ask</Button>}
                    <br/>
        </div>
        {selectedActionTypeOption === 0 &&
        <div style={{marginLeft: "0.8em", fontSize: "0.8rem", width: "20rem"}}>
            Order to buy at a fixed price.
        </div>}
        {selectedActionTypeOption === 1 &&
        <div style={{marginLeft: "0.8em", fontSize: "0.8rem", width: "20rem"}}>
            Order to buy at market price.
        </div>}
        {selectedActionTypeOption === 2 &&
        <div style={{marginLeft: "0.8em", fontSize: "0.8rem", width: "20rem"}}>
            Order to buy just above the 1st bid price.
        </div>}
        {selectedActionTypeOption === 4 &&
        <div style={{marginLeft: "0.8em", fontSize: "0.8rem", width: "20rem"}}>
            Order to buy just below 1st ask price.
        </div>}
    </div>)
}
