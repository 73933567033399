import React from "react"
import {TextField} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import InputAdornment from "@material-ui/core/InputAdornment"
import {SettingsInputAntenna} from "@material-ui/icons"
import CustomSwitch from "./CustomSwitch"
import c from "../../constants"
import Fab from "@material-ui/core/Fab"
import {brushValue} from "./UISupportFns"

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 270,
        backgroundColor: '#101A26'
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    fab: {
        // margin: theme.spacing(1),
        marginLeft: theme.spacing(0.6),
        backgroundColor: '#101A26',
        border: "1px solid #ffffff",
        color: "#ffffff"
    },
    fabSelected: {
        // margin: theme.spacing(),
        marginLeft: theme.spacing(0.6),
        backgroundColor: '#ffffff',
        border: "1px solid #000000",
        color: "#000000"
    },
    switcher: {
        checked: {
            backgroundColor: "#17BEBB"
        }
    }}
))

export default function SUCConditions(props) {
    const {actionType, futuresType, enterTradePrice, handleChangeEnterTradePrice,
        posSize, handleChangePosSize,
        posSizeType, handleChangePosSizeType,
        autoStartTrade, handleChangeAutoStartTrade,
        base, tickSize} = props

    const classes = useStyles()

    return (<div className={"wizardSectionContainer"}>
        <div className={"wizardTitle"}>
            <span className={"wizardTitleIcon"}><SettingsInputAntenna/></span> Set your Signal conditions
        </div>
        <div id={"stopLoss"} style={{display: "flex", marginTop: "1.6em",
            justifyContent: "space-between", flexWrap: "wrap", maxWidth: "25em"}}>
            <div>
                <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8"}}>
                    {actionType === c.ACTION_TYPE_BUY && <span>Buy price</span>}
                    {actionType === c.ACTION_TYPE_SELL && <span>Sell price</span>}
                    {(futuresType === c.FUTURES_TYPE_LONG || futuresType === c.FUTURES_TYPE_SHORT) && <span>Entry price</span>}
                </div>
                <TextField
                    className={classes.textField}
                    variant={"outlined"}
                    margin={"dense"}
                    id="input-with-icon-textfield_suc_cond"
                    value={brushValue(enterTradePrice, tickSize)}
                    onChange={handleChangeEnterTradePrice}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{base}</InputAdornment>
                    }}
                />
            </div>
        </div>
        <div id={"posSizeAndPosSizeType"} style={{display: "flex", marginTop: "1.6em",
            justifyContent: "space-between", flexWrap: "wrap", maxWidth: "17.6rem"}}>
            <div>
                <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8"}}>Position size</div>
                <TextField
                    className={classes.textField}
                    variant={"outlined"}
                    margin={"dense"}
                    id="input-with-icon-textfield_suc_cond_2"
                    value={posSize}
                    onChange={handleChangePosSize}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{posSizeType == c.SUC_POS_SIZE_TYPE_FIXED ? base: "%"}</InputAdornment>
                    }}
                    inputProps={{ maxLength: 10 }}
                    style={{width: "10rem"}}
                />
            </div>
            <div style={{marginTop: "1.05rem", width: "6.4rem", display: "flex", justifyContent: "space-between"}}>
                <Fab color={posSizeType == c.SUC_POS_SIZE_TYPE_PERCENTAGE? "primary": "default"} aria-label="Add"
                     onClick={()=> {handleChangePosSizeType(c.SUC_POS_SIZE_TYPE_PERCENTAGE)}}
                     className={posSizeType==c.SUC_POS_SIZE_TYPE_PERCENTAGE ? classes.fabSelected : classes.fab} variant={"extended"}>
                    %
                </Fab>
                <Fab color={posSizeType==c.SUC_POS_SIZE_TYPE_FIXED? "primary": "default"} aria-label="Add"
                     onClick={()=>{handleChangePosSizeType(c.SUC_POS_SIZE_TYPE_FIXED)}}
                     className={posSizeType==c.SUC_POS_SIZE_TYPE_FIXED ? classes.fabSelected : classes.fab} variant={"extended"}>
                    #
                </Fab>
            </div>
        </div>
        <div id={"takeProfit"} style={{display: "flex", marginTop: "1em",
            justifyContent: "space-between", flexWrap: "wrap", maxWidth: "13em"}}>
                <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8", marginTop: "0.66rem"}}>Auto start Trade {(autoStartTrade === true || autoStartTrade === "true") ? "ON": "OFF"}</div>
                <CustomSwitch checked={autoStartTrade === true || autoStartTrade === "true"} color="primary"
                              inputProps={{ 'aria-label': 'checkbox with default color' }}
                              onChange={()=>{handleChangeAutoStartTrade()}} classes={{checked: classes.switcher.checked}}
                />
        </div>
    </div>)
}
