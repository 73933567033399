import React from 'react'
import Fab from "@material-ui/core/Fab";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
        fabCancel: {
            margin: theme.spacing(1),
            width: 140,
            background: "#101926",
            border: "1px solid rgba(255, 255, 255, 0.12)",
            boxSizing: "border-box",
            borderRadius: 32,
            color: "white",
            '&:hover': {
                background: "#424242"
            }
        },
        cancelTrade: {
            margin: theme.spacing(1),
            background: "white",
            border: "1px solid rgba(255, 255, 255, 0.12)",
            boxSizing: "border-box",
            borderRadius: 32,
            color: "#101926",
            '&:hover': {
                background: "#424242",
                color: "white"
            }
        }
}
))

export default function CancelItem(props) {
    const {cancelItem, nevermind, cancelText, nevermindText, question} = props
    const classes = useStyles()

    return <div style={{display: "flex", marginTop: "1em",
        justifyContent: "flex-start", flexWrap: "wrap", maxWidth: "22em"}}>
        <div>{question}</div>
        <div style={{display: "flex", marginTop: "1em",
            justifyContent: "flex-start", flexWrap: "wrap", maxWidth: "22em"}}>
            <div className={"tradeDetailsMainButtonsContainer"}>
                <Fab color="primary"
                     aria-label="Cancel" className={classes.cancelTrade}
                     variant={"extended"}
                    onClick={()=>{cancelItem()}}>
                    {cancelText}
                </Fab>
                <Fab color="default"
                     aria-label="Yes, take profit" className={classes.fabCancel}
                     variant={"extended"}
                    onClick={()=>{nevermind()}}>{nevermindText}</Fab>
            </div>
        </div>
    </div>
}
