import React, {Component} from "react"
import {connect} from "react-redux"
import VirtualScroller from "../../fncomponents/VirtualScroller"
import ActiveTrade from "../../fncomponents/ActiveTrade"
import WaitingTrade from "../../fncomponents/WaitingTrade"
import WaitingOrder from "../../fncomponents/WaitingOrder"
import BTD from "../../fncomponents/BTD"

class VirtualScrollerContainer extends Component {
    constructor(props) {
        super(props);

        let itemHeight = 184
        let amount = parseInt(this.props.windowHeight / itemHeight)
        this.state = {
            SETTINGS: {
                itemHeight,
                amount,
                tolerance: 1,
                minIndex: 0,
                maxIndex: this.props.trades ? this.props.trades.length -1: 0,
                startIndex: 0
            }
        }
        this.getData = this.getData.bind(this)
        this.rowTemplate = this.rowTemplate.bind(this)
    }

    getData = (offset, limit) => {
        const data = [];
        const start = Math.max(this.state.SETTINGS.minIndex, offset);
        const end = Math.min(offset + limit - 1, this.state.SETTINGS.maxIndex);
        if (start <= end) {
            for (let i = start; i <= end; i++) {
                data.push({ index: i, trade: this.props.trades[i]});
            }
        }
        return data;
    }

     rowTemplate = (record) => {
        if (this.props.type === "WAITING_TRADES") {
            if (record.trade.buy_the_dip_id) {
                return <BTD key={record.trade.order_id} btd={record.trade} openRecord={()=>{this.props.openRecord(record.trade)}}/>
            }
            return <WaitingTrade key={record.trade.order_id} trade={record.trade} openRecord={()=>{this.props.openRecord(record.trade)}}/>
        } else if (this.props.type === "ACTIVE_TRADES") {
            return <ActiveTrade key={record.trade.order_id} trade={record.trade} openRecord={()=>{this.props.openRecord(record.trade)}}/>
        } else if (this.props.type === "CLOSED_TRADES") {
            return <ActiveTrade key={record.trade.order_id} trade={record.trade} openRecord={()=>{this.props.openRecord(record.trade)}}/>
        } else if (this.props.type === "ORDERS") {
            return <WaitingOrder key={record.trade.order_id} trade={record.trade} openRecord={()=>{this.props.openRecord(record.trade)}}/>
        }
    }

    render() {

        if (this.props.trades && this.props.trades.length > 0) {
            return <div className={"viewPortContainer"}>
                <VirtualScroller
                    className="viewport"
                    get={this.getData}
                    settings={this.state.SETTINGS}
                    rowTemplate={this.rowTemplate}
                    //scrollIndex={this.props.scrollIndex}
                    //setScrollIndex={this.props.setScrollIndex}
                />
            </div>
        } else {
            return <div style={{paddingTop: "2rem", display: "flex", justifyContent: "center"}}>
                {this.props.type === "WAITING_TRADES" && <span>You have no <b>W</b>aiting Trades</span>}
                {this.props.type === "ACTIVE_TRADES" && <span>You have no <b>A</b>ctive Trades</span>}
                {this.props.type === "CLOSED_TRADES" && <span>You have no <b>C</b>losed Trades</span>}
                {this.props.type === "ORDERS" && <span>You have no <b>O</b>rders</span>}
            </div>
        }
    }
}

let mapStateToProps = rootState =>({
    //scrollIndex: rootState.trades.scrollIndex,
})

let mapDispatchToProps = dispatch => {
    return {
        //setScrollIndex: (scrollIndex) => dispatch(setScrollIndex(scrollIndex)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (VirtualScrollerContainer)
