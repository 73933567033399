import React from 'react'
import {ReactComponent as UpTrendGreen} from '../../svgs/up_trend_green.svg'
import {ReactComponent as DownTrendRed} from '../../svgs/down_trend_red.svg'
import {ReactComponent as InlineTrendWhite} from '../../svgs/inline_trend_white.svg'
import {
    timeAgoFormatter,
    timeFormatter,
} from "./UISupportFns"
import TimeAgo from 'react-timeago'
import c from '../../constants'
import {SettingsInputAntenna, Android} from "@material-ui/icons"

export default function ActiveTrade(props) {

    const {trade, openRecord} = props
    const ACTION_TYPE = trade.action_type
    // const {quote, base} = getQuoteAndBase(trade)
    //const posSize = getPosSize(trade)
    // const cummulativeQuoteQty = getCummulativeQuoteQty(trade)
    // const executedQty = brushValue(trade.executed_qty)
    // const {currentPrice, buyPrice, sellPrice} = getCurrentBuyAndSellPrice(trade)
    // const progress = getProgress(trade)
    //const {trend, currentPriceDeltaPercent, currentPriceClass} = getCurrentPriceAndDeltaPercent(trade)
    // const {tpContent, slContent} = getTradeConditionsContentForActiveTrade(trade)
    // const tradeState = getTradeState(trade)
    // const tradeProfitOrLoss = getTradeProfitOrLoss(trade, tradeState)
    // const {maxPrice, maxDelta, minPrice, minDelta} = getMaxMinAndDeltaPercent(trade)
    // const {hrStateText, hrStateSymbol, hrStateSymbolColor, blinking} = getHRStateForTrade(trade)
    let antenaColor = "gray"
    if (trade.from_SUC && (trade.connected_to_signal === true || trade.connected_to_signal === "true"))
        antenaColor = "#68FF7D"
    return (
        <div className={"tradeContainer"} onClick={openRecord}>
            {/*================================== LEFT SUB-CONTAINER ========================================*/}
            <div className={"tradeSubContainerLeft"}>
                <div style={{display: "flex", justifyContent: "space-between", width: "9em"}} title={"Trade pair"}>
                    <div>
                        <span style={{fontSize: "1.2em", fontWeight: "bold"}}>{trade.quote}</span>
                        <span style={{fontSize: "1em"}}> / {trade.base}</span>
                    </div>
                    {(trade.tradeState === c.TRADE_STATE_WITH_CHILD_ONGOING || trade.tradeState === c.TRADE_STATE_WITH_CHILD_PARTIALLY_FILLED) &&
                        <div style={{top: "0.8rem"}}>
                            {trade.trend === 1 && <UpTrendGreen/>}
                            {trade.trend === 0 && <InlineTrendWhite/>}
                            {trade.trend === -1 && <DownTrendRed/>}
                        </div>
                    }
                </div>
                {ACTION_TYPE === c.ACTION_TYPE_BUY && <div className={"activeTradePosAndQtyStart"} title={"What are you trading"}>
                    <small>{trade.executedQty} {trade.quote}</small> <span style={{fontSize: "0.7rem"}} title={"bought with"}>/</span> <b>{trade.posSize} {trade.base}</b>
                </div>}
                {ACTION_TYPE === c.ACTION_TYPE_SELL && <div className={"activeTradePosAndQtyStart"} title={"What are you trading"}>
                    <small>{trade.posSize} {trade.quote}</small> <span style={{fontSize: "0.7rem"}} title={"sold for"}>/</span> <b>{trade.cummulativeQuoteQty} {trade.base}</b>
                </div>}
                <div style={{marginTop: "0.2rem", marginBottom: "0.2rem"}}>
                    {trade.tpContent && <div className={"takeProfitStopLossConfig"} title={"How the trade will take profit"}>
                        {trade.tpContent}
                    </div>}
                    <div className={"takeProfitStopLossConfig"} title={"How the trade will stop loss"}>
                        {trade.slContent}
                    </div>
                </div>
                {trade.tradeState === c.TRADE_STATE_WITH_CHILD_PARTIALLY_FILLED &&
                    <div>
                        <div className={"w3-progress-sizer"}>
                            <div className="w3-progress-container w3-round-xlarge">
                                <div className="w3-progressbar w3-round-xlarge" style={{width: `${trade.progress}%`}}></div>
                            </div>
                        </div>
                        <div className={"progressBarText"}>{trade.progress}% FILLED</div>
                    </div>
                }
                {trade.from_SUC && <div>
                    <SettingsInputAntenna style={{color: antenaColor, position: "relative", top: "0.4rem", fontSize: "42"}} />
                </div>}
                {trade.from_BOT && <div>
                    <Android style={{color: "#68FF7D", position: "relative", top: "0.4rem", fontSize: "42"}} />
                </div>}
            </div>
            {/*================================== RIGHT SUB-CONTAINER ========================================*/}
            <div style={{display: "flex"}}>
                {/*<div className={"horizSpacerOnSmallScreens"}/>*/}
                {(trade.tradeState === c.TRADE_STATE_WITH_CHILD_ONGOING || trade.tradeState === c.TRADE_STATE_WITH_CHILD_PARTIALLY_FILLED) &&
                    <div className={"tradeSubContainerRight"}>
                        {/*---------------------- FIRST ROW: CURRENT PRICE/ MADE X/ LOST X -----------------------*/}
                        <div className={"currentPriceStatsContainer"} title={"Current price"}>
                            <div className={trade.currentPriceClass}>{trade.currentPriceDeltaPercent}%</div>
                            <span className={"horizSpacer"}/>
                            <div className={trade.currentPriceClass}>{trade.currentPrice}</div>
                        </div>
                        {ACTION_TYPE === c.ACTION_TYPE_BUY && <div className={"activeTradePosAndQtyEnd"}><span className={"buy"}>buy</span> price <b>{trade.buyPrice}</b></div>}
                        {ACTION_TYPE === c.ACTION_TYPE_SELL && <div className={"activeTradePosAndQtyEnd"}><span className={"sell"}>sell</span> price <b>{trade.sellPrice}</b></div>}

                        {trade.maxPrice !== "0" &&<div className={"maxPriceContainer"} title={"Max price during the trade"}>
                            <span>{trade.maxDelta}% · {trade.maxPrice}</span>
                        </div>}
                        {trade.minPrice !== "0" && <div className={"minPriceContainer"} title={"Min price during the trade"}>
                            <span>{trade.minDelta}% · {trade.minPrice}</span>
                        </div>}
                        {trade.child_order.candle_close_price && <div className={"candleClosePriceContainer"} title={"Last candle close price"}>
                            <span>{c.CANDLES_TYPES_HR_MAP[trade.candles_type]} c.c. {trade.child_order.candle_close_price}</span>
                        </div>}
                        {trade.tradeState === c.TRADE_STATE_WITH_CHILD_CANCELED &&
                        <div className={"currentPriceStatsContainer"}>
                            <span style={{color: " #E8E288"}}>Canceled</span>
                        </div>
                        }
                        {trade.tradeState === c.TRADE_STATE_WITH_CHILD_IN_ERROR &&
                        <div className={"currentPriceStatsContainer"}>
                            <span style={{color: " #FB4A4A"}}>Error</span>
                        </div>
                        }
                        {(trade.from_SUC && trade.trader_name) && <div style={{display: "flex", justifyContent: "flex-end"}}>
                            <span style={{fontSize: "0.7rem", marginTop: "0.3rem"}}>from&nbsp;</span>{trade.trader_name}</div>}
                    </div>
                }
                {(trade.tradeState !== c.TRADE_STATE_WITH_CHILD_ONGOING && trade.tradeState !== c.TRADE_STATE_WITH_CHILD_PARTIALLY_FILLED) &&
                <div className={"tradeSubContainerRight"}>
                    {/*---------------------- FIRST ROW: CURRENT PRICE/ MADE X/ LOST X -----------------------*/}
                    <div className={"currentPriceStatsContainer"} title={"Current price"}>
                        <div className={trade.currentPriceClass}>{trade.currentPriceDeltaPercent}%</div>
                        <span className={"horizSpacer"}/>
                        <div className={trade.currentPriceClass}>{trade.currentPrice}</div>
                    </div>
                    {ACTION_TYPE === c.ACTION_TYPE_BUY && <div className={"activeTradePosAndQtyEnd"}><span className={"buy"}>buy</span> price <b>{trade.buyPrice}</b></div>}
                    {ACTION_TYPE === c.ACTION_TYPE_SELL && <div className={"activeTradePosAndQtyEnd"}><span className={"sell"}>sell</span> price <b>{trade.sellPrice}</b></div>}

                    {((trade.tradeState === c.TRADE_STATE_WITH_CHILD_FILLED || trade.tradeState === c.TRADE_STATE_WITH_CHILD_CANCELED) && trade.tradeProfitOrLoss.profit) &&
                    <div className={"profitOrLossContainer"}>
                        <div style={{color: " #64FE7C"}}>↑&nbsp;</div>
                        <div style={{color: " #64FE7C"}}>+{trade.tradeProfitOrLoss.deltaPercent}% <small>{trade.tradeProfitOrLoss.deltaValue} {trade.tradeProfitOrLoss.currency}&nbsp;
                            {/*{ACTION_TYPE === c.ACTION_TYPE_BUY && <span>{trade.base}</span>}*/}
                            {/*{ACTION_TYPE === c.ACTION_TYPE_SELL && <span>{trade.quote}</span>}*/}
                            </small>
                        </div>
                    </div>}

                    {((trade.tradeState === c.TRADE_STATE_WITH_CHILD_FILLED || trade.tradeState === c.TRADE_STATE_WITH_CHILD_CANCELED) && trade.tradeProfitOrLoss.loss) &&
                    <div className={"profitOrLossContainer"}>
                        <div style={{color: " #FB4A4A"}}>↓&nbsp;</div>
                        <div style={{color: " #FB4A4A"}}>{trade.tradeProfitOrLoss.deltaPercent}% <small>{trade.tradeProfitOrLoss.deltaValue} {trade.tradeProfitOrLoss.currency}&nbsp;
                            {/*{ACTION_TYPE === c.ACTION_TYPE_BUY && <span>{trade.base}</span>}*/}
                            {/*{ACTION_TYPE === c.ACTION_TYPE_SELL && <span>{trade.quote}</span>}*/}
                        </small>
                        </div>
                    </div>
                    }
                    {(trade.tradeState === c.TRADE_STATE_WITH_CHILD_CANCELED || trade.tradeState === c.TRADE_STATE_WITH_CHILD_CANCELED_AND_UNFULFILLED) &&
                    <div className={"currentPriceStatsContainer"}>
                        <span style={{color: "#E8E288"}}>Canceled</span>
                    </div>
                    }
                    {trade.tradeState === c.TRADE_STATE_WITH_CHILD_IN_ERROR &&
                    <div className={"currentPriceStatsContainer"}>
                        <span style={{color: " #FB4A4A"}}>Error</span>
                    </div>
                    }
                    {(trade.from_SUC && trade.trader_name) && <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <span style={{fontSize: "0.7rem", marginTop: "0.3rem"}}>from&nbsp;</span>{trade.trader_name}</div>}
                </div>
                }
            </div>
            {/*================================== BOTTOM last ROW (WIDTH 100%) ====================================*/}
            <div style={{display:"flex", justifyContent:"center", width: "100%"}}>
                {trade.tradeState === c.TRADE_STATE_WITH_CHILD_FILLED &&
                <div className={"buyPriceContainer"}>
                    {trade.last_action === c.TRADE_OP_USER_TAKES_PROFIT && <span>You took profit</span>}
                    {trade.last_action === c.TRADE_OP_AUTO_TAKE_PROFIT_TARGET_1 && <span>Took profit #1</span>}
                    {trade.last_action === c.TRADE_OP_AUTO_TAKE_PROFIT_TARGET_2 && <span>Took profit #2</span>}
                    {trade.last_action === c.TRADE_OP_AUTO_TAKE_PROFIT && <span>Took profit</span>}
                    {trade.last_action === c.TRADE_OP_AUTO_TAKE_LOSS && <span>Stopped loss</span>}
                    {trade.last_action === c.TRADE_OP_AUTO_CLOSE && <span>Closed</span>}
                    &nbsp;on {timeFormatter(trade.last_action_time, true)}
                </div>
                }
                {(trade.tradeState === c.TRADE_STATE_WITH_CHILD_CANCELED || trade.tradeState === c.TRADE_STATE_WITH_CHILD_CANCELED_AND_UNFULFILLED) &&
                <div className={"buyPriceContainer"}>
                    You canceled on {timeFormatter(trade.last_action_time, true)}
                </div>
                }
                {trade.tradeState === c.TRADE_STATE_WITH_CHILD_IN_ERROR &&
                <div className={"buyPriceContainer"}>
                    Errored on {timeFormatter(trade.last_action_time, true)}
                </div>}
                {trade.tradeState === c.TRADE_STATE_WITH_CHILD_ONGOING &&
                <div className={"activeForContainer"}>
                    <span>Active for <TimeAgo date={parseInt(trade.time)} formatter={timeAgoFormatter}/></span>
                </div>
                }
            </div>

            <div style={{display:"flex", justifyContent:"space-between", width: "100%"}} className={"recordId"}>
                <div style={{position: "relative", top: "0.52rem"}}>
                    {trade.order_id}
                    {trade.from_btd && trade.from_btd !== "undefined" && (<span title={`from BTD ${trade.from_btd}`}>&nbsp;&nbsp;(<b>from a BTD</b>)</span>)}
                    {trade.from_SUC && trade.from_SUC !== "undefined" && (<span title={`from Signal ${trade.signal_id}`}>&nbsp;&nbsp;(<b>{trade.signal_id}</b>)</span>)}
                    {trade.from_BOT && trade.from_BOT !== "undefined" && (<span title={`from Bot ${trade.bot_id}`}>&nbsp;&nbsp;(<b>{trade.bot_id}</b>)</span>)}
                    <span>,&nbsp;{timeFormatter(trade.time, true)},&nbsp;</span>
                    <span>#{trade.idx}</span>
                </div>
                <div>
                    {trade.hrStateText && <div>
                        {trade.blinking && <div className={"hrState"}>{trade.hrStateText} <span className="blink live" style={{color: trade.hrStateSymbolColor}}>{trade.hrStateSymbol}</span></div>}
                        {!trade.blinking && <div className={"hrState"}>{trade.hrStateText} <span className="live" style={{color: trade.hrStateSymbolColor}}>{trade.hrStateSymbol}</span></div>}
                    </div>}
                </div>
            </div>
        </div>)
}
