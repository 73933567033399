import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import {Edit, Close} from "@material-ui/icons";
import {TextField} from "@material-ui/core";
import SmallButton from "../../fncomponents/SmallButton";

const useStyles = makeStyles(theme => ({


    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        backgroundColor: '#101A26'
    }
}))


export default function EditableAboutSection(props) {
    const {
        trader,
        about,
        editable,
        switchOnEditable,
        switchOffEditable,
        handleChangeAbout,
        handleSave,
        handleCancel} = props
    const classes = useStyles()

    if (editable)
        return (<div>
            <div className={"traderSalesPageEditableSectionContainer"} style={{width: "50rem"}}>
                <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}} title={"Close edit mode"}>
                    <Close className={"smallMenuIcon"} onClick={switchOffEditable}/>
                </div>
                <div className={"traderSalesPageAbout"} style={{width: "100%"}}>
                    <TextField
                        value={about}
                        onChange={handleChangeAbout}
                        multiline
                        rows={14}
                        fullWidth
                        className={classes.textField}
                        style={{fontSize: "0.8rem"}}
                        inputProps={{
                            style: {fontSize: 12}
                        }}
                    />
                </div>
                <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
                    <SmallButton label={"Save"} variant="outlined" onClick={handleSave}/>
                    <SmallButton label={"Cancel"} variant="outlined" onClick={handleCancel}/>
                </div>
            </div>
        </div>)
    else return (<div>
        <div className={"traderSalesPageEditableSectionContainer"} style={{width: "50rem"}}>
            <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}} title={"Open edit mode"}>
                <Edit className={"smallMenuIcon"} onClick={switchOnEditable}/>
            </div>
            <div className={"traderSalesPageAbout"}>
                <div dangerouslySetInnerHTML={{ __html: trader.about }} />
            </div>
        </div>
    </div>)
}
