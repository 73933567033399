import React from "react"
import Fab from '@material-ui/core/Fab'
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    fabCancel: {
        margin: theme.spacing(1),
        width: 160,
        background: "#101926",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "white",
        '&:hover': {
            background: "#424242"
        }
    },
    fabPreview: {
        margin: theme.spacing(1),
        width: 160,
        background: "#3BADFF",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "black",
        '&:hover': {
            background: "#424242", color: "white"
        }
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default function FormButtons({handleCancel, handleSave, labelSave="Save"}) {
    const classes = useStyles()
    return(
        <div style={{display: "flex", justifyContent: "space-between", maxWidth: "24em", padding: "2em 0.6em 2em 0.6em"}}>
        <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabCancel} onClick={handleCancel}>
                Cancel&nbsp;&nbsp;
        </Fab>
        <Fab variant="extended" color="primary" aria-label="Preview" className={classes.fabPreview} onClick={handleSave}>
            {labelSave}&nbsp;&nbsp;
        </Fab>
        </div>
    )
}
