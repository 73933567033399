import React, { Component } from "react";
import { Link as RouterLink, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Cookies from 'universal-cookie'
import c from '../../constants'
import { getJWT } from "../../redux/actions/utils/tokenUtils";
import WHATitleText from "../fncomponents/WHATitleText";
import WHAConfirmUrlText from "../fncomponents/WHAConfirmUrlText";
import {getWhaRegisterLink} from "../../redux/actions/wha";
import {logIn} from "../../redux/actions/authentication";

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
        };

        this.handleChange = this.handleChange.bind(this)
        this.logIn = this.logIn.bind(this)
        this.logInOnEnter = this.loginOnEnter.bind(this)
        this.isAuthenticated = this.isAuthenticated.bind(this)
        this.has2FAEnabled = this.has2FAEnabled.bind(this)
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    loginOnEnter(e) {
        if (e.keyCode == '13')
            this.logIn()
    }

    logIn() {
        this.props.logIn({
            email: this.state.email,
            password: this.state.password
        })
    }


    isAuthenticated() {
        if (this.props.user)
            return true
        return false
    }

    has2FAEnabled() {
        let user = this.props.user
        if (user && user.permissions && user.permissions[0] === "OTPONLY")
                return true
        return false
    }

    componentDidMount() {
        //Check to see if the user logs in just after he accessed an investment program referral link
        const cookies = new Cookies()
        let ipId = cookies.get(`${c.INVESTMENT_PROGRAM_REFERRAL_COOKIE}_IP_ID`)
        let isTheFirstTime = cookies.get(`${c.INVESTMENT_PROGRAM_REFERRAL_COOKIE}_NEW`)
        if (ipId && isTheFirstTime) {

            cookies.remove(`${c.INVESTMENT_PROGRAM_REFERRAL_COOKIE}_NEW`)
            this.setState({
                shouldRedirectToIP: true
            })
        }
        this.props.getWhaRegisterLink()
    }

    render() {
        if (this.isAuthenticated() && getJWT()) {
            if (this.has2FAEnabled()) {
                return (
                    <Redirect to="/2fa"/>
                )
            } else if (this.state.shouldRedirectToIP) {
                return (
                    <Redirect to="/contributions"/>
                )
            } else {
                return (
                    <Redirect to="/"/>
                )
            }
        } else {
        }


        let localCustomStyle = {width: "16em"}
        let errors = undefined
        if (this.props.errors)
            errors = Object.keys(this.props.errors).length > 0 ? this.props.errors.message: undefined
        return (
          <div className={"loginForm"}>
              <WHATitleText />
              <div>
                  <div className={"loginFormTitle"}>Log In</div>
                  <div className={"loginFormWarning"}>Please check that you are visiting</div>
                  <div className={"loginFormWarning"}><b>https:</b>//<WHAConfirmUrlText/></div>
                  <div className={"vertSpacer"} />
              </div>
              <Paper style={{ padding: "1rem" }}>
                  <div id={"loginPaperContainer"} style={{ width: "16rem", margin: "auto" }}>
                      <TextField
                        id={"email"}
                            variant={"outlined"}
                            autoFocus
                            name={"email"}
                            label={"Email"}
                            placeholder={"Email"}
                            type={"email"}
                            style={localCustomStyle}
                            onChange = {this.handleChange}
                            value = {this.state.email}
                            error={errors !== undefined}
                            helperText={errors}
                            onKeyDown={this.logInOnEnter}
                            autoComplete={"false"}
                        />
                        <br/><br/>
                        <TextField
                            id={"password"}
                            variant={"outlined"}
                            type="password"
                            name={"password"}
                            placeholder={"password"}
                            label={"Password"}
                            style={localCustomStyle}
                            onChange = {this.handleChange}
                            value = {this.state.password}
                            onKeyDown={this.logInOnEnter}
                            autoComplete={"false"}
                        />
                        <br/><br/>
                        <Button variant="contained"  color="primary" fullWidth label="LogIn"
                                onClick={() => this.logIn()}>Login</Button>
                        <br/><br/>
                        <div className={"loginLinks"}>
                            <RouterLink to={"/reset-password"}>
                                <Link component="button" variant="body2">
                                    Forgot password
                                </Link>
                            </RouterLink>
                            <RouterLink to={!this.props.registerLink ? "/register": "#"}>
                                <Link component="button" variant="body2" onClick={() => {
                                    if (this.props.registerLink) {
                                        window.location=this.props.registerLink
                                    }}}>
                                    <span style={{opacity: "0.6"}}>Don't have an account?</span> Sign&nbsp;up
                                </Link>
                            </RouterLink>
                        </div>
                    </div>
                </Paper>
            </div>
        )
    }
}

const mapStateToProps = rootState => ({
    errors: rootState.errors.authError,
    user: rootState.authentication.user,
    registerLink: rootState.wha.registerLink
})

const mapDispatchToProps =  dispatch => {
    return {
        logIn: (credentials) => {dispatch(logIn(credentials))},
        getWhaRegisterLink: () => {dispatch(getWhaRegisterLink())}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
