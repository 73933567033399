import React from 'react'
import c from '../../../constants'
import s from '../../../settings'
import {Parser} from 'html-to-react'
import {formatNumberWithCommas} from "../../fncomponents/UISupportFns"
import Button from "@material-ui/core/Button"
import {makeStyles} from "@material-ui/core/styles"
import {brushValue} from "../../fncomponents/UISupportFns"
import MyProgressBar from "../../fncomponents/MyProgressBar";
import {Tooltip} from "@material-ui/core";
import {ChevronLeft} from "@material-ui/icons";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";


const useStyles = makeStyles(theme => ({
    investButton: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    label: {
        textTransform: 'capitalize',
    },
    inviteButton: {
        background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        padding: '5 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

export default function ActiveInvestmentProgram(props) {
    const {program: p, invest, yourInvestedStatus, progressBarPercentage, soldAllocation, back, showInvestConfirmation, openTC} = props
    const [linkCopied, setLinkCopied] = React.useState(0)

    const classes = useStyles()

    if (!p)
        return null

    const currencySymbols = {
        "USD": "$",
        "USDT": "USDT",
        "EURO": "€"
    }

    function copyLink() {
        setLinkCopied(1)
        setTimeout(() => {
            setLinkCopied(0)
        }, 1000)
    }

    return (
        <div style={{marginTop: "1rem"}}>
            {back && <Button onClick={()=>{back()}}><ChevronLeft/> Contributions</Button>}
            <h1>{p.title}</h1>
            <div style={{marginTop: "1rem"}}>
                <div style={{fontSize: "1.3rem", lineWeight: "0.5rem"}}>{p.saleRound} sale</div>
                <table style={{border: "1px solid white", borderCollapse: "collapse"}}>
                    <thead>
                        <tr style={{background: "white", color: "black"}}>
                            <td style={{width: "10rem", paddingLeft: "0.6rem"}}>Token price</td>
                            <td style={{width: "12rem", paddingLeft: "0.6rem"}}>Maximum allocation</td>
                            <td style={{width: "12rem", paddingLeft: "0.6rem"}}>Total tokens</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{paddingLeft: "0.6rem", borderRight: "1px solid white", height: "2.6rem", minWidth: "4rem"}}>
                                {p.tokenPrice} {currencySymbols[p.allocationCurrency]}
                            </td>
                            <td style={{paddingLeft: "0.6rem", borderLeft: "1px solid white", minWidth: "7rem"}}>
                                {formatNumberWithCommas(p.allocation, 0)} {currencySymbols[p.allocationCurrency]}
                            </td>
                            <td style={{paddingLeft: "0.6rem", borderLeft: "1px solid white", minWidth: "12rem"}}>
                                {formatNumberWithCommas(p.allocatedTokens, 0)}
                                &nbsp;{p.token}
                            </td>
                        </tr>
                    </tbody>
                </table>
                {p.saleStatus === c.INVESTMENT_PROGRAM_SALE_NOT_STARTED && <div id={"saleNotStarted"}>
                    <div style={{fontSize: "1.3rem", lineWeight: "0.5rem", marginTop: "2rem", marginBottom: "-1rem"}}>Sale starts <b>{p.saleStartingDate}</b></div>
                </div>}
                {p.saleStatus === c.INVESTMENT_PROGRAM_SALE_STARTED && <div id={"saleStarted"}>
                    <div style={{fontSize: "1.3rem", lineWeight: "0.5rem", marginTop: "2rem", marginBottom: "-1rem"}}>Sale progress</div>
                    <div style={{marginTop: "1.6rem", marginBottom: "0.6rem"}}>
                        <MyProgressBar maxWidth={"36rem"} height={20} percent={progressBarPercentage ? progressBarPercentage.toFixed(1): 0}/>
                    </div>

                    <div style={{display: "flex"}}><div style={{minWidth: "6rem", }}>Sold till now</div>&nbsp;
                        <div style={{fontWeight: "bold", minWidth: "6.6rem"}}>{formatNumberWithCommas(brushValue(soldAllocation, 2))} {currencySymbols[p.allocationCurrency]}</div>
                        &nbsp;&nbsp;<div style={{minWidth: "12rem"}}>(<b>{formatNumberWithCommas((soldAllocation / p.tokenPrice).toFixed(p.tokenDecimals), 0)}</b> tokens)</div>
                    </div>
                    {p.saleEndingDate && <div>Sale ends <b>{p.saleEndingDate}</b></div>}
                    <div style={{marginTop: "1rem"}}>
                        {yourInvestedStatus === "ALLOWED" && <Button
                            classes={{
                                root: classes.investButton, // class name, e.g. `classes-nesting-root-x`
                                label: classes.label, // class name, e.g. `classes-nesting-label-x`
                            }}
                            onClick={() => invest(p._id)}>
                            JUMP IN
                        </Button>}
                        {showInvestConfirmation && <div style={{marginTop: "0.2rem", opacity: "0.9", fontWeight: "bold", color: "#FE6B8B"}}>Your investment has been processed. Thank you!</div>}
                        {yourInvestedStatus === "ALREADY_INVESTED" && <div style={{fontSize: "1.3rem", opacity: "0.7", fontWeight: "lighter"}}>Thank you for your participation!
                            <div style={{fontSize: "0.8rem", opacity: "0.7"}}>You've reached the limit of max. {p.purchasesAllowedPerUser} purchases/user.</div>
                        </div>}
                    </div>

                </div>}
                {p.saleStatus === c.INVESTMENT_PROGRAM_SALE_ENDED && <div id={"saleNotStarted"}>
                    <div style={{fontSize: "1.3rem", lineWeight: "0.5rem", marginTop: "2rem", marginBottom: "-1rem"}}>
                        {progressBarPercentage < 100 ? <span>Sale progress</span> : <span>Sold out</span>}</div>
                    <div style={{marginTop: "1.6rem", marginBottom: "0.6rem"}}>
                        <MyProgressBar maxWidth={"36rem"} height={20} percent={progressBarPercentage ? progressBarPercentage.toFixed(1): 0}/>
                    </div>
                    <div>Sale ended on <b>{p.saleEndingDate}</b></div>
                </div>}
                {(p.status === c.INVESTMENT_PROGRAM_STATUS_ACTIVE && p.withReferralProgram === 1) &&
                <div>
                    <div style={{marginTop:"1.5rem", fontSize: "1.2rem",
                        paddingTop: "0.5rem", paddingLeft: "1rem", paddingRight: "0.5rem", paddingBottom: "0.8rem",
                        backgroundImage: "linear-gradient(to right top, #051937, #004063, #006871, #008e58, #83ac28)",
                        maxWidth: "34.6rem", borderRadius: "5px"}}>
                        <div style={{textAlign: "center", fontSize: "1.4rem"}}>Refer a friend and get up to 10%!<sup>*</sup></div>
                        <div>
                            <div style={{fontSize: "0.86rem", marginTop: "1rem", textAlign: "center"}}>Click on your unique link, below, to copy it</div>
                            <div style={{fontSize: "0.86rem", marginTop: "0.4rem", marginBottom: "1rem",
                                marginLeft: "0.5rem", border: "1px dashed gray", padding: "0.5rem", textAlign: "center"}}>
                                <Tooltip title={`Copy link`}>
                                    <CopyToClipboard text={`${s.HTTP}://${s.HOST}/iprl?c=${p.userReferralLinkUid}`}>
                                        <span onClick={()=>copyLink()}
                                              style={{fontSize: "0.9rem", opacity: "1",
                                                  background: linkCopied === 1? "yellow": "",
                                                  color: linkCopied === 1? "black": "",
                                                  cursor: "pointer", padding: "0.2rem", borderRadius: "2px", wordBreak: "break-all"}}>
                                            {`${s.HTTP}://${s.HOST}/iprl?c=${p.userReferralLinkUid}`}</span>
                                    </CopyToClipboard>
                                </Tooltip>
                            </div>
                            <div style={{fontSize: "0.6rem", marginLeft: "0.44rem"}}>* = Terms and conditions apply. <span className={"linkButton"} onClick={openTC} style={{fontSize: "0.6rem"}}>Find out more...</span></div>
                        </div>
                    </div>
                </div>
                }
            </div>
            <div style={{marginTop: "4rem", maxWidth: "40rem", textAlign: "justify", textJustify:"inter-word"}}>
                {p.content && <div>{new Parser().parse(p.content)}</div>}
            </div>
        </div>)
}
