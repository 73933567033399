import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import {connect} from "react-redux"
import {ArrowBack} from "@material-ui/icons"
import {
    brushValue,
    formatTimeDuration, getClosedFuturesTradeExitPriceAndReceivedNotionalValue, getEnterTradePriceForFutures,
    getExecutedQtyAtInput,
    getExecutedQtyAtOutput,
    getFuturesTradeEnterVerb, getFuturesTradeExitVerb, getFuturesTradeState,
    getMaxMinAndDeltaPercentFutures,
    getQuoteAndBase,
    getTradeConditionsContentForFuturesTradeDetails,
    getTradeProfitOrLossFutures,
    hasTradeChild,
    timeFormatter,
} from "../../fncomponents/UISupportFns";
import {Paper} from "@material-ui/core";
import c from "../../../constants";
import Fade from '@material-ui/core/Fade';
import {getTitle} from "../../fncomponents/PageTitleUtils";
import {Helmet} from "react-helmet/es/Helmet";



class FuturesTradeSummary extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isTradeDetailsPanelOpen: true,
            isDiscardTradePanelOpen: false,
            goBack: false,
        }

        this.goBack = this.goBack.bind(this)
    }

    goBack() {
       this.props.history.goBack()
    }

    render() {

        let {futuresTrade} = this.props
        if (!futuresTrade)
            return <Redirect to="/futures"/>

        const {quote, base} = getQuoteAndBase(futuresTrade)
        const verb = getFuturesTradeEnterVerb(futuresTrade)
        const exitVerb = getFuturesTradeExitVerb(futuresTrade)
        const {maxPrice, maxDelta, minPrice, minDelta} = getMaxMinAndDeltaPercentFutures(futuresTrade)
        const {tpContent, slContent} = getTradeConditionsContentForFuturesTradeDetails(futuresTrade)
        const tradeState = getFuturesTradeState(futuresTrade)
        const tradeProfitOrLoss = getTradeProfitOrLossFutures(futuresTrade)
        const notionalValue = futuresTrade.notional_value
        const {exitPrice, receivedNotionalValue} = getClosedFuturesTradeExitPriceAndReceivedNotionalValue(futuresTrade)
        const executedQtyInput = getExecutedQtyAtInput(futuresTrade)
        const {executedQtyAtOutputNo, executedQtyAtOutput} = getExecutedQtyAtOutput(futuresTrade)

        const origQty = brushValue(futuresTrade.orig_qty, futuresTrade.precision)
        const buyPrice = getEnterTradePriceForFutures(futuresTrade)
        const hasATradeChild = hasTradeChild(futuresTrade)

        return <div className={"TradeContent"} style={{display: "flex"}}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
                <div style={{maxWidth: "30rem", minWidth: "22rem"}}>
                    {this.state.isTradeDetailsPanelOpen &&
                    <Fade in={this.state.isTradeDetailsPanelOpen}>
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.goBack()}}><ArrowBack/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>Futures Trade  <span style={{opacity: "0.5"}}>{futuresTrade.order_id}</span> details</div>
                                    <div style={{width: "2.6rem"}}/>
                                </div>
                            </div>

                            <div className={"tradeDetailsPair"} >
                                {quote} <span className={"tradeDetailsPairSlash"}>/</span> {base}
                            </div>
                            <div className={"vertSpacer1"}/>
                            {(tradeState === c.TRADE_STATE_WITH_CHILD_FILLED || tradeState === c.TRADE_STATE_WITH_CHILD_CANCELED) &&
                                <div>
                                    {tradeProfitOrLoss.profit &&
                                        <div>
                                            <div style={{color: "#64FE7C", textAlign: "center"}}>You made ↑</div>
                                            <div style={{color: "#64FE7C", textAlign: "center", fontSize: "1.6rem", lineHeight: "1.8rem"}}>
                                                +{tradeProfitOrLoss.deltaPercent}% <small>{tradeProfitOrLoss.deltaValue}&nbsp;<span>{base}</span></small>
                                            </div>
                                        </div>
                                    }
                                    {tradeProfitOrLoss.loss &&
                                        <div>
                                            <div style={{color: "#FB4A4A", textAlign: "center"}}>You lost ↓</div>
                                            <div style={{color: "#FB4A4A", textAlign: "center", fontSize: "1.6rem", lineHeight: "1.8rem"}}>
                                                {tradeProfitOrLoss.deltaPercent}% <small>{tradeProfitOrLoss.deltaValue}&nbsp;<span>{base}</span>
                                            </small></div>
                                        </div>
                                    }
                                </div>
                            }
                            <div className={"vertSpacer1"}/>
                            {futuresTrade.last_action === c.TRADE_OP_AUTO_TAKE_PROFIT &&
                                <div style={{textAlign: "center"}}>
                                    <span>by <b>Taking profit</b> (auto)</span><br/>
                                </div>
                            }
                            {futuresTrade.last_action === c.TRADE_OP_AUTO_TAKE_LOSS &&
                                <div style={{textAlign: "center"}}>
                                    <span>by <b>Stopping the loss</b> (auto)</span><br/>
                                </div>
                            }
                            {futuresTrade.last_action === c.TRADE_OP_USER_TAKES_PROFIT &&
                                <div style={{textAlign: "center"}}>
                                    <span>by <b>Taking profit</b> (by you)</span><br/>
                                </div>
                            }
                            {(tradeState === c.TRADE_STATE_WITH_CHILD_CANCELED || tradeState === c.TRADE_STATE_WITH_CHILD_CANCELED_AND_UNFULFILLED ||
                                tradeState === c.TRADE_STATE_PARENT_CANCELED) &&
                                <div style={{color: "#E8E288", textAlign: "center", fontSize: "1.6rem", lineHeight: "1.8rem"}}>
                                    Canceled
                                </div>
                            }
                            {tradeState === c.TRADE_STATE_PARENT_IN_ERROR &&
                            <div>
                                <div style={{color: "#FB4A4A", textAlign: "center", fontSize: "1.6rem", lineHeight: "1.8rem"}}>
                                    Error
                                </div>
                                <div style={{textAlign: "center", width: "22rem"}}>
                                    {futuresTrade.error}
                                </div>
                            </div>
                            }
                            {tradeState === c.TRADE_STATE_WITH_CHILD_IN_ERROR &&
                                <div>
                                    <div style={{color: "#FB4A4A", textAlign: "center", fontSize: "1.6rem", lineHeight: "1.8rem"}}>
                                        Error
                                    </div>
                                    <div style={{textAlign: "center", width: "22rem"}}>
                                        {futuresTrade.child_order.error}
                                    </div>
                                </div>
                            }
                            <div className={"vertSpacer2"}/>
                            <div className={"tradeDetailsCreationDate"} style={{fontSize: "0.9rem", textAlign: "center"}}>
                                <span title={"Created on"}>{timeFormatter(futuresTrade.time, true)}</span>
                                {(tradeState !== c.TRADE_STATE_PARENT_CANCELED && tradeState !== c.TRADE_STATE_PARENT_IN_ERROR) && <span title={"Closed on"}>&nbsp;→&nbsp;{timeFormatter(futuresTrade.last_action_time, true)}</span>}
                                <br/>
                                {(tradeState !== c.TRADE_STATE_PARENT_CANCELED && tradeState !== c.TRADE_STATE_PARENT_IN_ERROR) && <span title={"Duration"}>{formatTimeDuration(futuresTrade.last_action_time, futuresTrade.time)}</span>}
                            </div>
                            {!hasATradeChild &&
                            <div className={"tradeDetailsPosAndQty"}>
                                <b>{notionalValue} {base}</b> {verb} <small>{origQty} {quote}</small> at price <b>{buyPrice}</b><br/>
                            </div>
                            }
                            {hasATradeChild &&
                            <div className={"tradeDetailsPosAndQty"}>
                                <b>{notionalValue} {base}</b> {verb} <small>{executedQtyInput} {quote}</small> at price <b>{buyPrice}</b><br/>
                                {executedQtyAtOutputNo > 0 && <div><small>{executedQtyAtOutput} {quote}</small> {exitVerb} at price <b>{exitPrice}</b> for <b>{receivedNotionalValue} {base}</b></div>}
                                {executedQtyAtOutputNo === 0 && <div><small>{executedQtyAtOutput} {quote}</small> {exitVerb}</div>}
                            </div>}

                            <div className={"vertSpacer"}/>
                            <div className={"tradeDetailsCreationDate"}>
                                <div><b>During trade</b></div>
                                {maxPrice > 0 && <div className={"maxPriceContainer2"}>max price {maxDelta}% · {maxPrice}</div>}
                                {minPrice > 0 && <div className={"minPriceContainer2"}>min price {minDelta}% · {minPrice}</div>}
                            </div>
                            <div className={"vertSpacer2"}/>
                            <div className={"tradeDetailsCreationDate"}>
                                <span><b>Trade conditions</b></span><br/>
                                <div style={{width: "18rem", margin: "auto"}}>{tpContent}</div>
                                <span>{slContent}</span>
                            </div>
                            <div className={"vertSpacer2"}/>
                        </Paper>
                    </Fade>}
                </div>
            </div>
    }
}


let mapStateToProps = rootState =>({
    futuresTrade: rootState.futures.openedFuturesTrade,
})

let mapDispatchToProps = dispatch => {
    return {
    }
}


export default connect(mapStateToProps, mapDispatchToProps) (FuturesTradeSummary)
