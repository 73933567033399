import React from "react"
import Button from '@material-ui/core/Button'
import InputAdornment from "@material-ui/core/InputAdornment"
import {TextField} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Gavel from '@material-ui/icons/Gavel'
import c from '../../constants'
import {scientificToDecimal, brushValue} from "./UISupportFns"

const useStyles = makeStyles(theme => ({

    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 270,
        backgroundColor: '#101A26'
    },
    button: {
        minWidth: 100,
        marginRight: 10,
        marginTop: 10,
        borderRadius: 100,
        height: 50
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    }
}))

export default function BuySellForBot({actionType, tradeType, actionTypeOption,
                                      handleChangeActionTypeOption,
                                      quote,
                                      base,
                                      currentLastBidPrice, hideTitle}) {
    const classes = useStyles()
    const [selectedActionTypeOption, setSelectedActionTypeOption] = React.useState(0)
    const [_currentLastBidPrice, setCurrentLastBidPrice] = React.useState("0")

    React.useEffect(()=> {
        if (quote.length < 2) {
            setSelectedActionTypeOption(-1)
            return
        }

        if (currentLastBidPrice >= 0) {
            setCurrentLastBidPrice(scientificToDecimal(`${currentLastBidPrice}`))
        }

        switch (actionTypeOption) {
            case c.ACTION_TYPE_OPTION_MARKET: setSelectedActionTypeOption(1); break
            case c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE: setSelectedActionTypeOption(2); break
            case c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE: setSelectedActionTypeOption(3); break
            default : setSelectedActionTypeOption(-1); break
        }
    })

    function getTitle() {
        if (actionType === c.ACTION_TYPE_BUY) {
            if (tradeType === c.TRADE_TYPE_NEW_TRADE)
                return "How will you buy"
            else return "How did you buy"
        } else if (actionType === c.ACTION_TYPE_SELL) {
            if (tradeType === c.TRADE_TYPE_NEW_TRADE)
                return "How will you sell"
            else return "How did you sell"
        }
    }

    function getPosSizeCurrency() {
        return base ? base.toUpperCase() : "..."
    }

    return (<div className={"wizardSectionContainer"}>
        {!hideTitle && <div className={"wizardTitle"}>
            <span className={"wizardTitleIcon"}><Gavel/></span> {getTitle()}
        </div>}
        <div style={{display: "flex", justifyContent: "flex-start", flexWrap: "wrap", marginBottom: "0.6em", marginLeft: "0.6em"}}>
            <Button className={classes.button} variant={selectedActionTypeOption === 1 ? "contained": "outlined"} color={"primary"}
                    onClick={()=>{handleChangeActionTypeOption(c.ACTION_TYPE_OPTION_MARKET)}}>Market</Button>
            {actionType === c.ACTION_TYPE_BUY && <Button className={classes.button} variant={selectedActionTypeOption === 2 ? "contained": "outlined"} color={"primary"}
                    onClick={()=>{handleChangeActionTypeOption(c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE)}}>Just above bid</Button>}
            {actionType === c.ACTION_TYPE_SELL && <Button className={classes.button} variant={selectedActionTypeOption === 3 ? "contained": "outlined"} color={"primary"}
                                                         onClick={()=>{handleChangeActionTypeOption(c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE)}}>Just below ask</Button>}
                    <br/>
        </div>
        {selectedActionTypeOption === 1 &&
        <div style={{marginLeft: "0.8em", fontSize: "0.8rem", width: "20rem"}}>
            {actionType === c.ACTION_TYPE_BUY && <span>Enter trade by buying at market price.</span>}
            {actionType === c.ACTION_TYPE_SELL && <span>Enter trade by selling at market price.</span>}
        </div>}
        {selectedActionTypeOption === 2 &&
        <div style={{marginLeft: "0.8em", fontSize: "0.8rem", width: "20rem"}}>
            Enter trade by buying at just above the 1st bid price.
        </div>}
        {selectedActionTypeOption === 3 &&
        <div style={{marginLeft: "0.8em", fontSize: "0.8rem", width: "20rem"}}>
            Enter trade by selling at just below the 1st ask price.
        </div>}
        <div style={{marginTop: "1em"}}>
            {currentLastBidPrice && <div className={"currentLastBidPrice"}>Last price <b>&nbsp;{_currentLastBidPrice}&nbsp;{base}</b></div>}
        </div>
    </div>)
}
