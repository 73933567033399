import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import {connect} from "react-redux"
import TimeAgo from 'react-timeago'
import {Create, Clear, ArrowBack} from "@material-ui/icons"
import {
    brushValue, getSUCStateTitle,
    getCurrentAndBuyPriceForSUC,
    getCurrentPriceAndDeltaPercent, getHRStateForSUC, getMaxMinAndDeltaPercent,
    getPosSize,
    getQuoteAndBase,
    getTradeEnterVerbFutureTense, getValueWithPrecision,
    timeAgoFormatter,
    timeFormatter, getTradeConditionsContentForSUCDetails,
} from "../../fncomponents/UISupportFns"
import {FormControlLabel, Paper, withStyles} from "@material-ui/core"
import SUCTradeConditions from "../../fncomponents/SUCTradeConditions"
import SUCConditions from "../../fncomponents/SUCConditions"
import c from "../../../constants"
import Fade from '@material-ui/core/Fade'
import CancelItem from "../../fncomponents/CancelItem"
import SUCDetailsMainButtons from "../../fncomponents/SUCDetailsMainButtons"
import DiscardItem from "../../fncomponents/DiscardItem"
import {
    openSUC,
    updateSUCCancelIt,
    updateSUCConditions, updateSUCConnectionToSignal,
    updateSUCDiscardIt,
    updateSUCTradeConditions
} from "../../../redux/actions/sucs"
import {debounce} from "../../hoc/myUtils"
import {getTitle} from "../../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"
import Checkbox from "@material-ui/core/Checkbox";

const DEBOUNCE_DELAY = 500

const CustomColorCheckbox = withStyles({
    root: {
        color: "#ffffff",
        '&$checked': {
            color: "#ffffff",
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);

class SUCDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isEditSUCTradeConditionsPanelOpen: false,
            isEditSUCConditionsPanelOpen: false,
            isSUCDetailsPanelOpen: true,
            isCancelSUCPanelOpen: false,
            isDiscardSUCPanelOpen: false,
            goBack: false,

            buyPrice: 0,
            posSize: 0,
            posSizeType: 0,
            autoStartTrade: true,

            takeProfitOption: 0,
            takeProfitTargets: [],
            stopLossOption: 0,
            stopLossTarget: 0
        }

        this.updateSUCConnection = this.updateSUCConnection.bind(this)

        this.openEditSUCConditionsPanel = this.openEditSUCConditionsPanel.bind(this)
        this.closeEditSUCConditionsPanel = this.closeEditSUCConditionsPanel.bind(this)

        this.handleChangeEnterTradePrice = this.handleChangeEnterTradePrice.bind(this)
        this.fixEnterTradePrice = this.fixEnterTradePrice.bind(this)
        this.debounceEnterTradePrice = debounce(this.fixEnterTradePrice, DEBOUNCE_DELAY)


        this.handleChangePosSize = this.handleChangePosSize.bind(this)
        this.fixPosSize = this.fixPosSize.bind(this)
        this.debounceFixPosSize = debounce(this.fixPosSize, DEBOUNCE_DELAY)
        this.handleChangePosSizeType = this.handleChangePosSizeType.bind(this)

        this.handleChangeAutoStartTrade = this.handleChangeAutoStartTrade.bind(this)


        this.openEditSUCTradeConditionsPanel = this.openEditSUCTradeConditionsPanel.bind(this)
        this.closeEditSUCTradeConditionsPanel = this.closeEditSUCTradeConditionsPanel.bind(this)


        this.handleChangeTakeProfitOption = this.handleChangeTakeProfitOption.bind(this)
        this.handleChangeTakeProfitTarget = this.handleChangeTakeProfitTarget.bind(this)
        this.fixTakeProfitTarget = this.fixTakeProfitTarget.bind(this)
        this.debounceFixTakeProfitTarget = debounce(this.fixTakeProfitTarget, DEBOUNCE_DELAY)
        this.handleChangeStopLossOption = this.handleChangeStopLossOption.bind(this)
        this.handleChangeStopLossTarget = this.handleChangeStopLossTarget.bind(this)
        this.fixStopLossTarget = this.fixStopLossTarget.bind(this)
        this.debounceFixStopLossTarget = debounce(this.fixStopLossTarget, DEBOUNCE_DELAY)
        this.updateSUCTradeConditions = this.updateSUCTradeConditions.bind(this)

        this.goBack = this.goBack.bind(this)

        this.openCancelSUCPanel = this.openCancelSUCPanel.bind(this)
        this.closeCancelSUCPanel = this.closeCancelSUCPanel.bind(this)
        this.cancelSUC = this.cancelSUC.bind(this)

        this.openDiscardSUCPanel = this.openDiscardSUCPanel.bind(this)
        this.closeDiscardSUCPanel = this.closeDiscardSUCPanel.bind(this)
        this.discardSUC = this.discardSUC.bind(this)

        this.switchWithOrWithoutStopLossTarget = this.switchWithOrWithoutStopLossTarget.bind(this)

        this.handleChangeCandlesType = this.handleChangeCandlesType.bind(this)
    }

    handleChangeCandlesType(e) {
        this.setState({
            candlesType: e.target.value
        })
    }

    switchWithOrWithoutStopLossTarget() {
        if (this.state.stopLossOption === c.STOP_LOSS_OPTION_MANUAL) {
            this.setState({
                stopLossOption: c.STOP_LOSS_OPTION_PERCENTAGE,
                stopLossTarget: 10,
            })
        } else {
            this.setState({
                stopLossOption: c.STOP_LOSS_OPTION_MANUAL
            })
        }
    }

    updateSUCConnection(rand) {
        this.props.updateSUCConnectionToSignal({sucId: this.props.suc.suc_id}, openedSUC => {
            if (openedSUC.action_type === c.ACTION_TYPE_BUY)
                this.setState({
                    enterTradePrice: openedSUC.buy_price,
                })
            else if (openedSUC.action_type === c.ACTION_TYPE_SELL) {
                this.setState({
                    enterTradePrice: openedSUC.sell_price,
                })
            }
        })
    }

    openEditSUCTradeConditionsPanel() {
        let newState = {
            isEditSUCTradeConditionsPanelOpen: true,
            isSUCDetailsPanelOpen: false,
            stopLossOption: this.props.suc.stop_loss_option,
            stopLossTarget: this.props.suc.stop_loss_target,
            takeProfitOption: this.props.suc.take_profit_option,
        }
        newState.takeProfitTargets = []
        for(let i=0; i < this.props.suc.take_profit_targets.length; i++) {
            newState.takeProfitTargets.push(this.props.suc.take_profit_targets[i])
        }
        this.setState(newState)
    }

    closeEditSUCTradeConditionsPanel() {
        this.setState({
            isEditSUCTradeConditionsPanelOpen: false,
            isSUCDetailsPanelOpen: true,
        })
    }

    openEditSUCConditionsPanel() {
        this.setState({
            isEditSUCConditionsPanelOpen: true,
            isSUCDetailsPanelOpen: false
        })
    }

    closeEditSUCConditionsPanel() {
        this.setState({
            isEditSUCConditionsPanelOpen: false,
            isSUCDetailsPanelOpen: true,
        })
    }


    handleChangeTakeProfitOption(option) {
        //If there is no change, do nothing
        if (this.state.takeProfitOption === option)
            return

        if (option === c.TAKE_PROFIT_OPTION_PRICE) {
            for(let i = 0; i < this.state.takeProfitTargets.length; i++) {
                let oldPercent = parseFloat(this.state.takeProfitTargets[i])
                let {currentPrice} = getCurrentAndBuyPriceForSUC(this.props.suc)
                let sign = 1
                if (this.state.actionType === c.ACTION_TYPE_SELL)
                    sign = -1
                let price = (1 + sign * oldPercent/100) * parseFloat(currentPrice)
                this.state.takeProfitTargets[i] = brushValue(price, 8)
            }
        } else if (option === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
            for(let i = 0; i < this.state.takeProfitTargets.length; i++) {
                let oldPrice = parseFloat(this.state.takeProfitTargets[i])
                let {currentPrice} = getCurrentAndBuyPriceForSUC(this.props.suc)
                let percent = ((oldPrice - currentPrice) / currentPrice) * 100
                if (this.state.actionType === c.ACTION_TYPE_SELL)
                    percent *= -1
                this.state.takeProfitTargets[i] = brushValue(percent, 1)
            }
        }

        this.setState({
            takeProfitOption: option
        })
    }

    handleChangeTakeProfitTarget(e) {
        let i = parseInt(e.target.name)
        let value = e.target && e.target.value ? e.target.value : ""
        this.state.takeProfitTargets[i] = value
        this.setState({
            lastTakeProfitTargetEdited: i
        })
        this.debounceFixTakeProfitTarget()
    }

    fixTakeProfitTarget() {
        let i = this.state.lastTakeProfitTargetEdited
        if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE)
            this.state.takeProfitTargets[i] = getValueWithPrecision(this.state.takeProfitTargets[i], 1)
        else
            this.state.takeProfitTargets[i] = getValueWithPrecision(this.state.takeProfitTargets[i], 8)
        this.setState({
            randomValue: Math.random()
        })
    }

    handleChangeStopLossOption(option) {
        //If there is no change, do nothing
        if (this.state.stopLossOption === option)
            return

        let oldSLOption = this.state.stopLossOption
        let oldSLOptionType = 0
        switch (oldSLOption) {
            case c.STOP_LOSS_OPTION_PRICE: oldSLOptionType = 1; break;
            case c.STOP_LOSS_OPTION_CONDITIONAL: oldSLOptionType = 1; break;
            case c.STOP_LOSS_OPTION_PERCENTAGE: oldSLOptionType = 2; break;
        }

        let optionType = 0
        switch (option) {
            case c.STOP_LOSS_OPTION_PRICE: optionType = 1; break;
            case c.STOP_LOSS_OPTION_CONDITIONAL: optionType = 1; break;
            case c.STOP_LOSS_OPTION_PERCENTAGE: optionType = 2; break;
        }

        let transitionDelta = oldSLOptionType - optionType

        if (transitionDelta === 0) {
            //no need for calculations
        } else if (transitionDelta === -1) {
            //transition from price to percentage
            let oldPrice = parseFloat(this.state.stopLossTarget)
            let {currentPrice} = getCurrentAndBuyPriceForSUC(this.props.suc)
            let percent = ((currentPrice - oldPrice) / currentPrice) * 100
            if (this.state.actionType === c.ACTION_TYPE_SELL)
                percent *= -1
            this.state.stopLossTarget = brushValue(percent, 1)
        } else if (transitionDelta === 1) {
            //transition from percentage to price
            let oldPercent = parseFloat(this.state.stopLossTarget)
            let {currentPrice} = getCurrentAndBuyPriceForSUC(this.props.suc)
            let sign = 1
            if (this.state.actionType === c.ACTION_TYPE_SELL)
                sign = -1
            let price = (1 - sign * oldPercent/100) * parseFloat(currentPrice)
            this.state.stopLossTarget = brushValue(price, 8)
        }

        this.setState({
            stopLossOption: option
        })
    }

    handleChangeStopLossTarget(e) {
        this.setState({
            stopLossTarget: e.target.value
        })

        this.debounceFixStopLossTarget()
    }

    fixStopLossTarget() {
        if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PRICE || this.state.stopLossOption === c.STOP_LOSS_OPTION_CONDITIONAL)
            this.setState({
                stopLossTarget: getValueWithPrecision(this.state.stopLossTarget, 8)
            })
        else this.setState({
            stopLossTarget: getValueWithPrecision(this.state.stopLossTarget, 1)
        })
    }

    updateSUCTradeConditions() {
        let takeProfitTargetsNotInSuccession = false
        if (this.state.takeProfitTargets.length > 1) {
            if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
                for (let i = 0; i < this.state.takeProfitTargets.length - 1 && !takeProfitTargetsNotInSuccession; i++) {
                    if (parseFloat(this.state.takeProfitTargets[i]) > parseFloat(this.state.takeProfitTargets[i+1]))
                        takeProfitTargetsNotInSuccession = true
                }
            } else if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE) {
                for (let i = 0; i < this.state.takeProfitTargets.length - 1 && !takeProfitTargetsNotInSuccession; i++) {
                    if (this.state.actionType === c.ACTION_TYPE_BUY &&
                        parseFloat(this.state.takeProfitTargets[i]) > parseFloat(this.state.takeProfitTargets[i+1])) {
                        takeProfitTargetsNotInSuccession = true
                    } else if (this.state.actionType === c.ACTION_TYPE_SELL &&
                        parseFloat(this.state.takeProfitTargets[i]) < parseFloat(this.state.takeProfitTargets[i+1])) {
                        takeProfitTargetsNotInSuccession = true
                    }

                }
            }

        }
        if (takeProfitTargetsNotInSuccession) {
            if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE)
                this.setState({
                    sucTradeConditionsError: "Take profit targets are not in ascending succession."
                })
            else if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE) {
                if (this.state.actionType === c.ACTION_TYPE_BUY)
                    this.setState({
                        sucTradeConditionsError: "Take profit targets are not in ascending succession."
                    })
                else if (this.state.actionType === c.ACTION_TYPE_SELL)
                    this.setState({
                        sucTradeConditionsError: "Take profit targets are not in descending succession."
                    })
            }
        } else {
            this.state.isEditSUCTradeConditionsPanelOpen = false
            this.state.isSUCDetailsPanelOpen = true

            this.props.updateSUCTradeConditions({
                sucId: this.props.suc.suc_id,
                takeProfitOption: this.state.takeProfitOption,
                takeProfitTargets: this.state.takeProfitTargets,
                stopLossOption: this.state.stopLossOption,
                candlesType: this.state.candlesType,
                stopLossTarget: this.state.stopLossTarget
            })
        }
    }

    goBack() {
        this.setState({
            goBack: true
        })
    }

    handleChangeEnterTradePrice(e) {
            this.setState({
                enterTradePrice: e.target.value
            })

        this.debounceEnterTradePrice()
    }

    fixEnterTradePrice() {
        this.setState({
            enterTradePrice: getValueWithPrecision(this.state.enterTradePrice, 8)
        })
    }

    handleChangePosSize(e) {
        this.setState({
            posSize: e.target.value
        })

        this.debounceFixPosSize()
    }

    fixPosSize() {
        if (this.state.posSizeType === c.SUC_POS_SIZE_TYPE_FIXED)
            this.setState({
                posSize: getValueWithPrecision(this.state.posSize, 8)
            })
        else if (this.state.posSizeType === c.SUC_POS_SIZE_TYPE_PERCENTAGE)
            this.setState({
                posSize: getValueWithPrecision(this.state.posSize, 0)
            })
    }

    handleChangePosSizeType() {
        if (this.state.posSizeType == c.SUC_POS_SIZE_TYPE_PERCENTAGE)
            this.setState({
                posSizeType: c.SUC_POS_SIZE_TYPE_FIXED
            })
        else
            this.setState({
                posSizeType: c.SUC_POS_SIZE_TYPE_PERCENTAGE
            })
    }


    handleChangeAutoStartTrade() {
        this.setState({
            autoStartTrade: !this.state.autoStartTrade
        })
    }

    updateSUCConditions() {
        this.state.isEditSUCConditionsPanelOpen = false
        this.state.isSUCDetailsPanelOpen = true
        this.props.updateSUCConditions({
            sucId: this.props.suc.suc_id,
            enterTradePrice: this.state.enterTradePrice,
            posSize: this.state.posSize,
            posSizeType: this.state.posSizeType,
            autoStartTrade: this.state.autoStartTrade
        })
    }

    openCancelSUCPanel() {
        this.setState({
            isCancelSUCPanelOpen: true,
            isSUCDetailsPanelOpen: false
        })
    }

    closeCancelSUCPanel() {
        this.setState({
            isCancelSUCPanelOpen: false,
            isSUCDetailsPanelOpen: true,
        })
    }

    cancelSUC() {
        this.state.isCancelSUCPanelOpen = false
        this.state.isSUCDetailsPanelOpen = true
        this.props.updateSUCCancelIt({sucId: this.props.suc.suc_id})
    }

    openDiscardSUCPanel() {
        this.setState({
            isDiscardSUCPanelOpen: true,
            isSUCDetailsPanelOpen: false
        })
    }

    closeDiscardSUCPanel() {
        this.setState({
            isDiscardSUCPanelOpen: false,
            isSUCDetailsPanelOpen: true,
        })
    }

    discardSUC() {
        this.state.isDiscardSUCPanelOpen = false
        this.state.isSUCDetailsPanelOpen = true
        this.props.updateSUCDiscardIt({sucId: this.props.suc.suc_id})
    }

    componentDidMount() {
        if (!this.props.suc)
            return

        let suc = this.props.suc

        let enterTradePrice = 0
        if (suc.action_type === c.ACTION_TYPE_BUY)
            enterTradePrice = suc.buy_price
        else if (suc.action_type === c.ACTION_TYPE_SELL)
            enterTradePrice = suc.sell_price
        else if (suc.futures_type === c.FUTURES_TYPE_LONG || suc.futures_type === c.FUTURES_TYPE_SHORT)
            enterTradePrice = suc.entry_price
        this.setState( {
            actionType: suc.action_type,
            posSize: suc.pos_size,
            posSizeType: suc.pos_size_type,
            enterTradePrice,
            autoStartTrade: suc.auto_start_trade,
            takeProfitOption: suc.take_profit_option,
            takeProfitTargets: suc.take_profit_targets,
            stopLossOption: suc.stop_loss_option,
            candlesType: suc.candles_type,
            stopLossTarget: suc.stop_loss_target,
        })
    }

    render() {
        if (this.state.goBack)
            return <Redirect to="/sucs"/>

        let {suc} = this.props
        if (!suc)
            return <Redirect to="/sucs"/>
        const ACTION_TYPE = suc.action_type
        const FUTURES_TYPE = suc.futures_type
        const {quote, base} = getQuoteAndBase(suc)
            const verbFutureTense = getTradeEnterVerbFutureTense(suc)
        const posSize = getPosSize(suc)
        const {currentPriceDeltaPercent, currentPrice, currentPriceClass} = getCurrentPriceAndDeltaPercent(suc)
        const {maxPrice, maxDelta, minPrice, minDelta} = getMaxMinAndDeltaPercent(suc)
        const {tpContent, slContent} = getTradeConditionsContentForSUCDetails(suc)
        const stateTitle = getSUCStateTitle(suc)
        const {hrStateText, hrStateSymbol, hrStateSymbolColor, blinking} = getHRStateForSUC(suc)
        const allowedToEditSUCConditions = suc.connected_to_signal === "false" || suc.connected_to_signal === false

        return <div className={"TradeContent"} style={{display: "flex"}}>
                <Helmet>
                    <title>{getTitle()}</title>
                </Helmet>
                <div style={{maxWidth: "30rem", minWidth: "22rem"}}>
                    {this.state.isSUCDetailsPanelOpen &&
                    <Fade in={this.state.isSUCDetailsPanelOpen}>
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.goBack()}}><ArrowBack/></div>
                                    <div className={"tradeDetailsTopBarTitle"}><span title={"Signal user's copy"}>Signal</span> <span style={{opacity: "0.5"}}>{suc.suc_id}</span></div>
                                    <div style={{width: "2.6rem"}}/>
                                </div>
                            </div>
                            <div className={"vertSpacer1"}/>
                            <div className={"tradeDetailsCreationDate"}>
                               <span><b>{stateTitle}</b> for <TimeAgo date={parseInt(suc.time)} formatter={timeAgoFormatter}/> · </span> created on {timeFormatter(suc.time, true)}
                            </div>
                            <div className={"tradeDetailsOngoingAction"}>
                                <div className={"vertSpacer1"}/>
                                {hrStateText} {blinking && <div className="blink liveBig" style={{color: hrStateSymbolColor}}>{hrStateSymbol}</div>}
                            </div>
                            <div className={"vertSpacer1"}/>
                            <div className={"tradeDetailsPair"}>
                                {quote} <span className={"tradeDetailsPairSlash"}>/</span> {base}
                            </div>
                            <div className={"tradeDetailsPosAndQty"}>
                                <span style={{fontWeight: "bold"}}
                                      title={suc.pos_size_type == c.SUC_POS_SIZE_TYPE_PERCENTAGE? `Percentage from your available ${base} balance`: ''}>{posSize}{suc.pos_size_type == c.SUC_POS_SIZE_TYPE_PERCENTAGE ? "%":""} {base}</span> {verbFutureTense} <small>{quote}</small>
                            </div>
                            <div className={`tradeDetailsCurrentPrice ${currentPriceClass}`} title={"Current price"}>
                                {currentPriceDeltaPercent}% · {currentPrice}
                            </div>
                            <div className={"tradeDetailsMaxPrice"}>
                                {maxPrice && <span>max price {maxDelta}% · {maxPrice}</span>}
                            </div>
                            <div className={"tradeDetailsMaxPrice"} style={{marginTop: "0"}}>
                                {minPrice && <span>min price {minDelta}% · {minPrice}</span>}
                            </div>
                            <div className={"vertSpacer2"}/>
                            <div style={{padding: "0.6rem"}}>
                                <Paper className={"tradeDetailsTradeConditionsPaper"}>
                                    <div>
                                        {(suc.connected_to_signal === true || suc.connected_to_signal === "true") && <div><FormControlLabel
                                                    control={<CustomColorCheckbox checked={true}/>}
                                                    label={"Connected"}
                                                    onChange={()=>{this.updateSUCConnection(Math.random())}}
                                        />
                                        <div className={"smallExplanations2"} style={{marginLeft: "1rem"}}>Signal receives updates. You cannot edit it.</div>
                                        </div>}
                                        {(suc.connected_to_signal === false || suc.connected_to_signal === "false") && <div><FormControlLabel
                                            control={<CustomColorCheckbox checked={false}/>}
                                            label={"Connected"}
                                            onChange={()=>{this.updateSUCConnection(Math.random())}}
                                        />
                                            <div className={"smallExplanations2"} style={{marginLeft: "1rem"}}>Signal won't receive updates. You can edit it.</div>
                                        </div>}
                                    </div>
                                </Paper>
                            </div>
                            <div style={{padding: "0.6rem"}}>
                                <Paper className={"tradeDetailsTradeConditionsPaper"}>
                                    <div style={{display: "flex", justifyContent: "space-between" ,fontSize: "0.7rem"}}>
                                        <span>SIGNAL CONDITIONS</span>
                                        {allowedToEditSUCConditions && <span style={{cursor: "pointer"}} onClick={()=> {this.openEditSUCConditionsPanel()}}><Create/></span>}
                                    </div>
                                    <div className={"takeProfitStopLossConfigTradeDetails"}>
                                        {ACTION_TYPE === c.ACTION_TYPE_BUY && <span><b>Buy price</b> {brushValue(suc.buy_price, suc.tick_size)} {base}</span>}
                                        {ACTION_TYPE === c.ACTION_TYPE_SELL && <span><b>Sell price</b> {suc.sell_price} {base}</span>}
                                        {(FUTURES_TYPE === c.FUTURES_TYPE_LONG || FUTURES_TYPE === c.FUTURES_TYPE_SHORT) && <span><b>Entry price</b> {suc.entry_price} {base}</span>}
                                    </div>
                                    {ACTION_TYPE === c.ACTION_TYPE_BUY &&
                                    <div className={"takeProfitStopLossConfigTradeDetails"}>
                                        {suc.pos_size_type == c.SUC_POS_SIZE_TYPE_FIXED && <div><b>Position size</b> {suc.pos_size} {base}</div>}
                                        {suc.pos_size_type == c.SUC_POS_SIZE_TYPE_PERCENTAGE && <div><b>Position size</b> {suc.pos_size}% {base} <span style={{fontSize: "0.6rem"}}>(from your {base} balance)</span></div>}
                                    </div>}
                                    {ACTION_TYPE === c.ACTION_TYPE_SELL &&
                                    <div className={"takeProfitStopLossConfigTradeDetails"}>
                                        {suc.pos_size_type == c.SUC_POS_SIZE_TYPE_FIXED && <div><b>Position size</b> {suc.pos_size} {quote}</div>}
                                        {suc.pos_size_type == c.SUC_POS_SIZE_TYPE_PERCENTAGE && <div><b>Position size</b> {suc.pos_size}% {quote} <span style={{fontSize: "0.6rem"}}>(from your {quote} balance)</span></div>}
                                    </div>}
                                    {(FUTURES_TYPE === c.FUTURES_TYPE_LONG || FUTURES_TYPE === c.FUTURES_TYPE_SHORT) &&
                                    <div className={"takeProfitStopLossConfigTradeDetails"}>
                                        {suc.pos_size_type == c.SUC_POS_SIZE_TYPE_FIXED && <div><b>Position size</b> {suc.pos_size} {quote}</div>}
                                        {suc.pos_size_type == c.SUC_POS_SIZE_TYPE_PERCENTAGE && <div><b>Position size</b> {suc.pos_size}% {quote} <span style={{fontSize: "0.6rem"}}>(from your {quote} balance)</span></div>}
                                    </div>}

                                    <div className={"takeProfitStopLossConfigTradeDetails"}>
                                        {(suc.auto_start_trade === true || suc.auto_start_trade === "true") && <div><b>Auto start Trade</b> is ON</div>}
                                        {(suc.auto_start_trade === false || suc.auto_start_trade === "false") && <div><b>Auto start Trade</b> is OFF</div>}
                                    </div>
                                </Paper>
                            </div>
                            <div style={{padding: "0.6rem"}}>
                                <Paper className={"tradeDetailsTradeConditionsPaper"}>
                                    <div style={{display: "flex", justifyContent: "space-between" ,fontSize: "0.7rem"}}>
                                        <span>TRADE CONDITIONS</span>
                                        {allowedToEditSUCConditions && <span style={{cursor: "pointer"}} onClick={()=> {this.openEditSUCTradeConditionsPanel()}}><Create/></span>}
                                    </div>
                                    {tpContent && <div className={"takeProfitStopLossConfigTradeDetails"}>
                                        {tpContent}
                                    </div>}
                                    <div className={"takeProfitStopLossConfigTradeDetails"}>
                                        {slContent}
                                    </div>
                                </Paper>
                            </div>
                            <div style={{padding: "0.6rem"}}>
                                <SUCDetailsMainButtons suc={suc} cancelSUC={this.openCancelSUCPanel}
                                                         discardSUC={this.openDiscardSUCPanel}/>
                            </div>
                        </Paper>
                    </Fade>}
                    {this.state.isEditSUCConditionsPanelOpen &&
                    <Paper style={{backgroundColor: "#101926"}}>
                        <div className={"tradeDetailsTopBar"}>
                            <div className={"tradeDetailsTopBarVerticalCenter"}>
                                <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeEditSUCConditionsPanel()}}><Clear/></div>
                                <div className={"tradeDetailsTopBarTitle"}>Signal conditions</div>
                                <div className={"linkButton"} style={{marginRight: "0.6rem"}} onClick={()=> {this.updateSUCConditions()}}>SAVE</div>
                            </div>
                        </div>
                        <div className={"tradeDetailsTradeConditionsPaper"}>
                            <SUCConditions
                                            actionType={ACTION_TYPE}
                                            futuresType={FUTURES_TYPE}
                                            enterTradePrice={this.state.enterTradePrice}
                                            handleChangeEnterTradePrice={this.handleChangeEnterTradePrice}
                                            tickSize={parseInt(suc.tick_size)}
                                            posSize={this.state.posSize}
                                            handleChangePosSize={this.handleChangePosSize}
                                            posSizeType={this.state.posSizeType}
                                            handleChangePosSizeType={this.handleChangePosSizeType}
                                            autoStartTrade={this.state.autoStartTrade}
                                            handleChangeAutoStartTrade={this.handleChangeAutoStartTrade}
                                            base={base}
                            />
                        </div>
                    </Paper>}
                    {this.state.isEditSUCTradeConditionsPanelOpen &&
                    <Paper style={{backgroundColor: "#101926"}}>
                        <div className={"tradeDetailsTopBar"}>
                            <div className={"tradeDetailsTopBarVerticalCenter"}>
                                <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeEditSUCTradeConditionsPanel()}}><Clear/></div>
                                <div className={"tradeDetailsTopBarTitle"}>Trade conditions</div>
                                <div className={"linkButton"} style={{marginRight: "0.6rem"}} onClick={()=> {this.updateSUCTradeConditions()}}>SAVE</div>
                            </div>
                        </div>
                        <div className={"tradeDetailsTradeConditionsPaper"}>
                            <SUCTradeConditions takeProfitOption={this.state.takeProfitOption}
                                                takeProfitTargets={this.state.takeProfitTargets}
                                                stopLossOption={this.state.stopLossOption}
                                                stopLossTarget={this.state.stopLossTarget}
                                                handleChangeTakeProfitOption={this.handleChangeTakeProfitOption}
                                                handleChangeTakeProfitTarget={this.handleChangeTakeProfitTarget}
                                                handleChangeStopLossOption={this.handleChangeStopLossOption}
                                                handleChangeStopLossTarget={this.handleChangeStopLossTarget}
                                                switchWithOrWithoutStopLossTarget={this.switchWithOrWithoutStopLossTarget}
                                                sucTradeConditionsError={this.state.sucTradeConditionsError}
                                                base={base}
                                                candlesType={this.state.candlesType}
                                                handleChangeCandlesType={this.handleChangeCandlesType}
                            />
                        </div>
                    </Paper>}
                    {this.state.isCancelSUCPanelOpen &&
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeCancelSUCPanel()}}><Clear/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>Cancel Signal</div>
                                    <div style={{width: "2.6rem"}}/>
                                </div>
                            </div>
                            <div className={"tradeDetailsTradeConditionsPaper"}>
                                <CancelItem cancelItem={this.cancelSUC} nevermind={this.closeCancelSUCPanel}
                                            question={"Are you sure you want to cancel this Signal?"}
                                            cancelText={"Cancel"} nevermindText={"Nevermind"}/>
                            </div>
                        </Paper>}
                    {this.state.isDiscardSUCPanelOpen &&
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeDiscardSUCPanel()}}><Clear/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>Discard SUC</div>
                                    <div style={{width: "2.6rem"}}/>
                                </div>
                            </div>
                            <div className={"tradeDetailsTradeConditionsPaper"}>
                                <DiscardItem discardItem={this.discardSUC} nevermind={this.closeDiscardSUCPanel}
                                             question={"Are you sure you want to discard this SUC?"}
                                             discardWarningText={"Discarding this SUC will remove it from your waiting SUC list."}/>
                            </div>
                        </Paper>}
                </div>
            </div>
    }
}


let mapStateToProps = rootState =>({
    suc: rootState.sucs.openedSUC,
})

let mapDispatchToProps = dispatch => {
    return {
        openSUC: (suc) => dispatch(openSUC(suc)),
        updateSUCConnectionToSignal: (props, cb) => dispatch(updateSUCConnectionToSignal(props, cb)),
        updateSUCConditions: (props) => dispatch(updateSUCConditions(props)),
        updateSUCTradeConditions: (props) => dispatch(updateSUCTradeConditions(props)),
        updateSUCCancelIt: (props) => dispatch(updateSUCCancelIt(props)),
        updateSUCDiscardIt: (props) => dispatch(updateSUCDiscardIt(props))
    }
}


export default connect(mapStateToProps, mapDispatchToProps) (SUCDetails)
