import Button from "@material-ui/core/Button";
import { CardMembership, ChevronRight, SettingsApplications } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet/es/Helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import c from "../../../constants";
import { getAllSUCs } from "../../../redux/actions/sucs";
import {
  changeAutoRenewOnSubscriptionByFollower,
  getUserTraderSubscriptions,
  removeSubscriptionByFollower,
  reSubscribeByFollower,
  unsubscribeByFollower,
} from "../../../redux/actions/traders";
import AlertDialog from "../../fncomponents/AlertDialog";
import { getTitle } from "../../fncomponents/PageTitleUtils";
import NavTabs from "../../fncomponents/NavTabs";
import SignalSettings2 from "./SignalSettings2";
import TraderFollowerSubscriptions from "./TraderFollowerSubscriptions";
import SUCsList from "./components/SUCsList";
import withGetScreenExt from "../../../withGetScreenExt"

const SUCs_NEW = ({ isMobile }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [traderSubscriptions, setTraderSubscriptions] = useState([]);
  //popups related state
  const [subscriptionId, setSubscriptionId] = useState();
  const [traderFollowerActiveSubscriptionId, setTraderFollowerActiveSubscriptionId] = useState();

  //titles and texts
  const [unsubscribeTitle, setUnsubscribeTitle] = useState();
  const [unsubscribeTexts, setUnsubscribeTexts] = useState();
  const [autoRenewTitle, setAutoRenewTitle] = useState();
  const [autoRenewTexts, setAutoRenewTexts] = useState();
  const [autoRenewOkLabel, setAutoRenewOkLabel] = useState();
  const [removeTitle, setRemoveTitle] = useState();
  const [removeTexts, setRemoveTexts] = useState();
  const [reSubscribeTitle, setReSubscribeTitle] = useState();
  const [reSubscribeTexts, setReSubscribeTexts] = useState();

  //open popups state
  const [isUnsubscribeAlertDialogOpen, setIsUnsubscribeAlertDialogOpen] = useState();
  const [isReSubscribeAlertDialogOpen, setIsReSubscribeAlertDialogOpen] = useState();
  const [isAutoRenewAlertDialogOpen, setIsAutoRenewAlertDialogOpen] = useState();
  const [isRemoveAlertDialogOpen, setIsRemoveAlertDialogOpen] = useState();

  //actions
  const act_getAllActiveSUCs = useCallback(() => dispatch(getAllSUCs({ types: 0 })), [dispatch]);
  const act_getAllClosedSUCs = useCallback(() => dispatch(getAllSUCs({ types: 1 })), [dispatch]);
  const act_getUserTraderSubscriptions = useCallback(
    () =>
      dispatch(
        getUserTraderSubscriptions((traderSubscriptions) => {
          setTraderSubscriptions(traderSubscriptions);
        })
      ),
    [dispatch]
  );
  const act_unsubscribeByFollower = useCallback((props, cb) => dispatch(unsubscribeByFollower(props, cb)), [dispatch]);
  const act_changeAutoRenewOnSubscriptionByFollower = useCallback((props, cb) => dispatch(changeAutoRenewOnSubscriptionByFollower(props, cb)), [dispatch]);
  const act_removeSubscriptionByFollower = useCallback((props, cb) => dispatch(removeSubscriptionByFollower(props, cb)), [dispatch]);
  const act_reSubscribeByFollower = useCallback((props, cb) => dispatch(reSubscribeByFollower(props, cb)), [dispatch]);

  //redux state selectors
  const user = useSelector((state) => state.authentication.user);
  const activeSUCsNo = useSelector((state) => state.sucs.activeSUCsNo) || "";
  const closedSUCsNo = useSelector((state) => state.sucs.closedSUCsNo) || "";
  // const closedSUCsNo = "10";

  const history = useHistory();

  //init ,
  useEffect(() => {
    act_getAllActiveSUCs();
    act_getAllClosedSUCs();
    act_getUserTraderSubscriptions();
  }, [act_getAllActiveSUCs, act_getAllClosedSUCs, act_getUserTraderSubscriptions]);

  // //tab change
  // useEffect(() => {
  //   if (activeTab === 0) {
  //     act_getAllActiveSUCs();
  //   }
  //   if (activeTab === 1) {
  //     act_getAllClosedSUCs();
  //   }
  // }, [act_getAllActiveSUCs, act_getAllClosedSUCs, activeTab]);

  // redirect to login page when user not logged in
  if (!user) {
    history.replace("/login");
    return null;
  }

  if (user?.signalsState !== c.USER_SIGNALS_STATE_RECEIVING) {
    history.replace("/traders");
    return null;
  }

  //event handlers
  function handleOpenUnsubscribeDialog(subscription) {
    setSubscriptionId(subscription._id);
    setTraderFollowerActiveSubscriptionId(subscription.traderFollowerActiveSubscriptionId);
    setUnsubscribeTitle(`Unsubscribe from ${subscription.name}`);
    setUnsubscribeTexts([
      `Are your sure you want to unsubscribe from ${subscription.name}?`,
      `You will continue to receive signals from this subscription until the end of the period.`,
    ]);
    setIsUnsubscribeAlertDialogOpen(true);
  }

  function handleCloseUnsubscribeDialog() {
    setIsUnsubscribeAlertDialogOpen(false);
  }

  function handleOpenRemoveDialog(subscription) {
    setSubscriptionId(subscription._id);
    setTraderFollowerActiveSubscriptionId(subscription.traderFollowerActiveSubscriptionId);
    setRemoveTitle(`Remove ${subscription.name}`);
    setRemoveTexts(["Are you sure you want to remove this subscription?", "After removal you won't receive any more signals."]);
    setIsRemoveAlertDialogOpen(true);
  }

  function handleOpenReSubscribeDialog(subscription) {
    setSubscriptionId(subscription._id);
    setTraderFollowerActiveSubscriptionId(subscription.traderFollowerActiveSubscriptionId);
    setReSubscribeTitle(`Re-subscribe to ${subscription.name}`);
    setReSubscribeTexts(["Are you sure you want to re-subscribe to this subscription?", "You can re-subscribe only while the subscription is still active."]);
    setIsReSubscribeAlertDialogOpen(true);
  }

  function handleUnsubscribe() {
    act_unsubscribeByFollower(
      {
        _id: subscriptionId,
        traderFollowerActiveSubscriptionId: traderFollowerActiveSubscriptionId,
      },
      () => {
        for (let i = 0; i < traderSubscriptions.length; i++) {
          if (traderSubscriptions[i].traderFollowerActiveSubscriptionId === traderFollowerActiveSubscriptionId) {
            if (traderSubscriptions[i].free) {
              traderSubscriptions.splice(i, 1);
            } else {
              traderSubscriptions[i].unsubscribed = true;
            }
            setIsUnsubscribeAlertDialogOpen(false);
            return;
          }
        }
      }
    );
  }

  //auto renew
  function handleOpenAutoRenewDialog(subscription) {
    if (subscription.autoRenew) {
      //disabling autoRenew
      setAutoRenewTexts([
        `Are your sure you want to disable the automatic renewal of this subscription?`,
        `Once the availability period expires you will be unsubscribed automatically and you won't receive any more signals.`,
      ]);
      setAutoRenewOkLabel("Disable");
    } else {
      //enabling autoRenew
      setAutoRenewTexts([
        `Are your sure you want to enable the automatic renewal for this subscription?`,
        `Each time the availability period expires your account will be automatically debited for the cost of this subscription and you will continue to receive signals.`,
      ]);
      setAutoRenewOkLabel("Enable");
    }
    setSubscriptionId(subscription._id);
    setTraderFollowerActiveSubscriptionId(subscription.traderFollowerActiveSubscriptionId);
    setAutoRenewTitle(`Change auto renew for ${subscription.name}`);
    setIsAutoRenewAlertDialogOpen(true);
  }
  function handleCloseAutoRenewDialog() {
    setSubscriptionId(undefined);
    setIsAutoRenewAlertDialogOpen(false);
  }
  function handleChangeAutoRenew() {
    act_changeAutoRenewOnSubscriptionByFollower({ _id: subscriptionId, traderFollowerActiveSubscriptionId: traderFollowerActiveSubscriptionId }, () => {
      for (let i = 0; i < traderSubscriptions.length; i++) {
        if (traderSubscriptions[i].traderFollowerActiveSubscriptionId === traderFollowerActiveSubscriptionId) {
          traderSubscriptions[i].autoRenew = !traderSubscriptions[i].autoRenew;
          setIsAutoRenewAlertDialogOpen(false);
          return;
        }
      }
    });
  }

  //remove dialog
  function handleCloseRemoveDialog() {
    setSubscriptionId(undefined);
    setIsRemoveAlertDialogOpen(false);
  }

  function handleRemove() {
    act_removeSubscriptionByFollower(
      {
        _id: subscriptionId,
        traderFollowerActiveSubscriptionId: traderFollowerActiveSubscriptionId,
      },
      () => {
        for (let i = 0; i < traderSubscriptions.length; i++) {
          if (traderSubscriptions[i].traderFollowerActiveSubscriptionId === traderFollowerActiveSubscriptionId) {
            traderSubscriptions.splice(i, 1);
            setIsRemoveAlertDialogOpen(false);
            return;
          }
        }
      }
    );
  }

  // re-subscribe dialog
  function handleCloseReSubscribeDialog() {
    setSubscriptionId(undefined);
    setIsReSubscribeAlertDialogOpen(false);
  }

  function handleReSubscribe() {
    act_reSubscribeByFollower(
      {
        _id: subscriptionId,
        traderFollowerActiveSubscriptionId: traderFollowerActiveSubscriptionId,
      },
      () => {
        for (let i = 0; i < traderSubscriptions.length; i++) {
          if (traderSubscriptions[i].traderFollowerActiveSubscriptionId === traderFollowerActiveSubscriptionId) {
            traderSubscriptions[i].unsubscribed = false;
            traderSubscriptions[i].autoRenew = true;
            setIsReSubscribeAlertDialogOpen(false);
            return;
          }
        }
      }
    );
  }

  return (
    <div className={"AppMainContent"}>
      <Helmet>
        <title>{getTitle()}</title>
      </Helmet>
      <Button
        onClick={() => {
          history.push("/traders");
        }}
        color="primary"
        variant="outlined"
      >
        Traders <ChevronRight />
      </Button>
      <NavTabs
        tabs={[
          {
            title: (
              <TabTitleContainer>
                Active<span>{activeSUCsNo}</span>
              </TabTitleContainer>
            ),
            content: <SUCsList type={c.RUNNING_SUCS_TAB} title={"Active"} user={user} />,
          },
          {
            title: (
              <TabTitleContainer>
                Closed<span>{closedSUCsNo}</span>
              </TabTitleContainer>
            ),
            content: <SUCsList type={c.CLOSED_SUCS_TAB} title={"Closed"} user={user} />,
          },
          {
            title: (
              <TabTitleContainer withWrap={isMobile()}>
                <SettingsApplications />
                <span>Settings</span>
              </TabTitleContainer>
            ),
            content: <SignalSettings2 user={user} />,
          },
          {
            title: (
              <TabTitleContainer withWrap={isMobile()}>
                <CardMembership />
                <span>Subscriptions</span>
              </TabTitleContainer>
            ),
            content: (
              <TraderFollowerSubscriptions
                traderSubscriptions={traderSubscriptions}
                handleUnsubscribe={handleOpenUnsubscribeDialog}
                handleChangeAutoRenew={handleOpenAutoRenewDialog}
                handleRemove={handleOpenRemoveDialog}
                handleReSubscribe={handleOpenReSubscribeDialog}
              />
            ),
          },
        ]}
        selectedTab={activeTab}
        changeTab={(evt, tab) => {
          setActiveTab(tab);
        }}
        tabContainerClass={"tabsContainer"}
      />
      <AlertDialog
        open={isUnsubscribeAlertDialogOpen}
        title={unsubscribeTitle}
        texts={unsubscribeTexts}
        handleOk={handleUnsubscribe}
        handleCancel={handleCloseUnsubscribeDialog}
        okLabel={"Go ahead"}
      />

      <AlertDialog
        open={isAutoRenewAlertDialogOpen}
        title={autoRenewTitle}
        texts={autoRenewTexts}
        handleOk={handleChangeAutoRenew}
        handleCancel={handleCloseAutoRenewDialog}
        okLabel={autoRenewOkLabel}
      />

      <AlertDialog
        open={isRemoveAlertDialogOpen}
        title={removeTitle}
        texts={removeTexts}
        handleOk={handleRemove}
        handleCancel={handleCloseRemoveDialog}
        okLabel={"OK"}
      />

      <AlertDialog
        open={isReSubscribeAlertDialogOpen}
        title={reSubscribeTitle}
        texts={reSubscribeTexts}
        handleOk={handleReSubscribe}
        handleCancel={handleCloseReSubscribeDialog}
        okLabel={"OK"}
      />
    </div>
  );
};

export default withGetScreenExt(SUCs_NEW);

const TabTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
  font-size: clamp(0.7rem, 0.5687rem + 0.5833vw, 0.875rem);
  ${({ withWrap }) => (withWrap ? "flex-wrap: wrap;" : "")}
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
