import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import {
    openTrade, updateEnterTradeConditions,
    updateTradeCancelIt, updateTradeAsOrderDiscardIt
} from "../../../redux/actions/trades"
import {
    getCurrentPriceOnPair,
    // getOrderBook
} from "../../../redux/actions/newTrade"
import {closeWS, initWS} from "../../../redux/actions/wsOrderBook"
import {connect} from "react-redux"
import TimeAgo from 'react-timeago'
import {Create, Clear, ArrowBack} from "@material-ui/icons"
import {
    brushValue,
    capitalize,
    getActionTypeOptionDetailsExplicit,
    getCurrentBuyAndSellPrice,
    getCurrentPriceAndDeltaPercent,
    getHRStateForTrade,
    getMaxMinAndDeltaPercent,
    getPosSize, getProgress, getProgressForWaitingTrade,
    getQuoteAndBase,
    getTradeEnterVerb,
    getTradeEnterVerbPastTense,
    getTradeState,
    getTradeStateTitle,
    getValueWithPrecision,
    timeAgoFormatter,
    timeFormatter,
    getCummulativeQuoteQty,
    getAllowedToEditEnterOrderConditions
} from "../../fncomponents/UISupportFns";
import {FormControlLabel, Paper, withStyles} from "@material-ui/core";
import EnterTradeConditions from "../../fncomponents/EnterTradeConditions";
import c from "../../../constants";
import Fade from '@material-ui/core/Fade';
import CancelItem from "../../fncomponents/CancelItem";
import TradeDetailsMainButtons from "../../fncomponents/TradeDetailsMainButtons";
import DiscardItem from "../../fncomponents/DiscardItem";
import {debounce} from "../../hoc/myUtils";
import OrderBook from "../../fncomponents/OrderBook";
import {getTitle} from "../../fncomponents/PageTitleUtils";
import {Helmet} from "react-helmet/es/Helmet";
import Checkbox from "@material-ui/core/Checkbox";

const DEBOUNCE_DELAY_IN_MS = 500

const CustomColorCheckbox = withStyles({
    root: {
        color: "#ffffff",
        '&$checked': {
            color: "#ffffff",
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);

class OrderDetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isEditTradeConditionsPanelOpen: false,
            isTradeDetailsPanelOpen: true,
            isTakeProfitPanelOpen: false,
            isCancelTradePanelOpen: false,
            isDiscardTradePanelOpen: false,
            isEditEnterTradeConditionsPanelOpen: false,
            goBack: false,
            executeTakeProfitOption: "market",
            executeTakeProfitPrice: 0,
            //Flag to avoid double submit
            executeTakeProfitOngoing: false
        }

        this.goBack = this.goBack.bind(this)
        this.openCancelTradePanel = this.openCancelTradePanel.bind(this)
        this.closeCancelTradePanel = this.closeCancelTradePanel.bind(this)
        this.cancelTrade = this.cancelTrade.bind(this)
        this.openDiscardTradePanel = this.openDiscardTradePanel.bind(this)
        this.closeDiscardTradePanel = this.closeDiscardTradePanel.bind(this)
        this.discardTrade = this.discardTrade.bind(this)
        this.openEditEnterTradeConditionsPanel = this.openEditEnterTradeConditionsPanel.bind(this)
        this.closeEditEnterTradeConditionsPanel = this.closeEditEnterTradeConditionsPanel.bind(this)
        this.updateEnterTradeConditions = this.updateEnterTradeConditions.bind(this)
        this.handleChangeActionTypeOption = this.handleChangeActionTypeOption.bind(this)
        this.handleChangeFixedPrice = this.handleChangeFixedPrice.bind(this)
        this.fixFixedPrice = this.fixFixedPrice.bind(this)
        this.debounceFixFixedPrice = debounce(this.fixFixedPrice, DEBOUNCE_DELAY_IN_MS)

    }

    goBack() {
        this.setState({
            goBack: true
        })
    }

    openCancelTradePanel() {
        this.setState({
            isCancelTradePanelOpen: true,
            isTradeDetailsPanelOpen: false
        })
    }

    closeCancelTradePanel() {
        this.setState({
            isCancelTradePanelOpen: false,
            isTradeDetailsPanelOpen: true,
        })
    }

    cancelTrade() {
        this.state.isCancelTradePanelOpen = false
        this.state.isTradeDetailsPanelOpen = true
        this.props.updateTradeCancelIt({tradeId: this.props.tradeAsOrder.order_id})
    }

    openDiscardTradePanel() {
        this.setState({
            isDiscardTradePanelOpen: true,
            isTradeDetailsPanelOpen: false
        })
    }

    closeDiscardTradePanel() {
        this.setState({
            isDiscardTradePanelOpen: false,
            isTradeDetailsPanelOpen: true,
        })
    }

    discardTrade() {
        this.state.isDiscardTradePanelOpen = false
        this.state.isTradeDetailsPanelOpen = true
        this.props.updateTradeAsOrderDiscardIt({tradeId: this.props.tradeAsOrder.order_id})
    }

    openEditEnterTradeConditionsPanel() {
        this.setState({
            isEditEnterTradeConditionsPanelOpen: true,
            isTradeDetailsPanelOpen: false
        })
    }

    closeEditEnterTradeConditionsPanel() {
        this.setState({
            isEditEnterTradeConditionsPanelOpen: false,
            isTradeDetailsPanelOpen: true,
        })
    }

    updateEnterTradeConditions() {
        this.state.isEditEnterTradeConditionsPanelOpen = false
        this.state.isTradeDetailsPanelOpen = true
        this.props.updateEnterTradeConditions({
            tradeId: this.props.tradeAsOrder.order_id,
            fixedPrice: this.state.fixedPrice,
            actionTypeOption: this.state.actionTypeOption
        })
    }

    handleChangeActionTypeOption(actTypeOption) {
        if (this.state.quote && this.state.quote.length < 2)
            return

        if (this.state.tradeType === c.TRADE_TYPE_OLD) {
            if (actTypeOption !== c.ACTION_TYPE_OPTION_FIXED_PRICE)
                return
        }

        this.setState({
            actionTypeOption: actTypeOption
        })
        if (this.state.quote.length > 1 && actTypeOption !== c.ACTION_TYPE_OPTION_MARKET)
            this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, result => {
                let multiplier = 1
                if (actTypeOption === c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE) {
                    multiplier = c.LAST_BID_PRICE_MULTIPLIER
                } else if (actTypeOption === c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE) {
                    multiplier = c.FIRST_ASK_PRICE_MULTIPLIER
                }
                let currentPrice = (parseFloat(result.current_price) * multiplier).toFixed(parseInt(result.tick_size))
                this.setState({
                    fixedPrice: currentPrice
                })
                this.debounceFixFixedPrice()
            })
    }

    getCurrentPrices() {
        this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base})
    }

    fixFixedPrice() {
        if (`${this.state.fixedPrice}`.length > 0) {
            this.setState({
                fixedPrice: getValueWithPrecision(this.state.fixedPrice, this.state.tickSize)
            })
        }
    }

    handleChangeFixedPrice(e) {
        this.setState({
            fixedPrice: e.target.value
        })
        this.debounceFixFixedPrice()
    }

    componentDidMount() {
        if (!this.props.tradeAsOrder)
            return

        const {quote, base} = getQuoteAndBase(this.props.tradeAsOrder)
        let {tradeAsOrder} = this.props
        this.state.actionType = tradeAsOrder.action_type
        this.state.actionTypeOption = tradeAsOrder.action_type_option
        if (tradeAsOrder.action_type === c.ACTION_TYPE_BUY)
            this.state.fixedPrice = tradeAsOrder.buy_price
        else if (tradeAsOrder.action_type === c.ACTION_TYPE_SELL)
            this.state.fixedPrice = tradeAsOrder.sell_price
        this.state.tradeType = tradeAsOrder.trade_type
        this.state.quote = quote
        this.state.base = base
        this.state.tickSize = parseInt(tradeAsOrder.tick_size)
        // this.getOrderBookPeriodically()
        this.props.user && initWS(this.props.user.userId, `${quote}${base}`)
    }

    componentWillUnmount() {
        closeWS()
    }

    render() {

        if (this.state.goBack)
            return <Redirect to="/"/>

        let {tradeAsOrder} = this.props
        if (!tradeAsOrder)
            return <Redirect to="/"/>
        if (tradeAsOrder.child_order && tradeAsOrder.child_order.order_id) {
            let s = tradeAsOrder.child_order.status
            if (s !== "NEW" && s !== "PARTIALLY_FILLED") {
                return <Redirect to="/tradesummary"/>
            }
        } else {
            let s = tradeAsOrder.status
            if (s === "CANCELED" || s === "ERROR") {
                return <Redirect to="/tradesummary"/>
            }

        }

        const {quote, base} = getQuoteAndBase(tradeAsOrder)
        const ACTION_TYPE = tradeAsOrder.action_type
        const verb = getTradeEnterVerb(tradeAsOrder)
        const verbFirstCaps = capitalize(verb)
        const verbPastTense = getTradeEnterVerbPastTense(tradeAsOrder)
        const posSize = getPosSize(tradeAsOrder)
        const origQty = brushValue(tradeAsOrder.orig_qty)
        const executedQty = brushValue(tradeAsOrder.executed_qty)
        const cummulativeQuoteQty = getCummulativeQuoteQty(tradeAsOrder)
        const {currentPriceDeltaPercent, currentPrice, currentPriceClass} = getCurrentPriceAndDeltaPercent(tradeAsOrder)
        const {maxPrice, maxDelta, minPrice, minDelta} = getMaxMinAndDeltaPercent(tradeAsOrder)
        const stateTitle = getTradeStateTitle(tradeAsOrder)
        const tradeState = getTradeState(tradeAsOrder)
        const {hrStateText, hrStateSymbol, hrStateSymbolColor, blinking} = getHRStateForTrade(tradeAsOrder)
        const allowedToEditEnterOrderConditions = getAllowedToEditEnterOrderConditions(tradeAsOrder, this.props.ASAS)
        const {buyPrice, sellPrice} = getCurrentBuyAndSellPrice(tradeAsOrder)
        const actionTypeOptionExplicit = getActionTypeOptionDetailsExplicit(tradeAsOrder)
        let progress = 0

        if (tradeAsOrder.child_order && tradeAsOrder.child_order.order_id) {
            progress = getProgress(tradeAsOrder)
        } else {
            progress = getProgressForWaitingTrade(tradeAsOrder)
        }


        return <div className={"TradeContent"} style={{display: "flex"}}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <div style={{marginLeft: "0"}}>
                <div style={{maxWidth: "30rem", minWidth: "22rem"}}>
                    {this.state.isTradeDetailsPanelOpen &&
                    <Fade in={this.state.isTradeDetailsPanelOpen}>
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.goBack()}}><ArrowBack/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>Order <span style={{opacity: "0.5"}}>{tradeAsOrder.order_id}</span> details</div>
                                    <div style={{width: "2.6rem"}}/>
                                </div>
                            </div>
                            <div className={"tradeDetailsPair"}>
                                {quote} <span className={"tradeDetailsPairSlash"}>/</span> {base}
                            </div>
                            {(tradeState === c.TRADE_STATE_WITH_CHILD_ONGOING || tradeState === c.TRADE_STATE_WITH_CHILD_PARTIALLY_FILLED) &&
                            <div className={"tradeDetailsOngoingAction"}>
                                <div className={"vertSpacer1"}/>
                                {blinking && <div className="blink liveBig" style={{textAlign: "center", color: hrStateSymbolColor}}>{hrStateText}{hrStateSymbol}</div>}
                            </div>
                            }
                            <div className={"vertSpacer1"}/>
                            <div className={"tradeDetailsCreationDate"}>
                                {(tradeState === c.TRADE_STATE_PARENT_ONGOING || tradeState === c.TRADE_STATE_WITH_CHILD_ONGOING) && <span><b>{stateTitle}</b> for <TimeAgo date={parseInt(tradeAsOrder.time)} formatter={timeAgoFormatter}/> · </span>} created on {timeFormatter(tradeAsOrder.time, true)}
                            </div>

                            {tradeState === c.TRADE_STATE_PARENT_ONGOING &&
                            <div>
                                {ACTION_TYPE === c.ACTION_TYPE_BUY &&
                                    <div>
                                        <div className={"tradeDetailsPosAndQty"}>
                                            <b>{posSize} {base}</b> {verb} <small>{executedQty}/{origQty} {quote}</small>
                                        </div>
                                        <div className={"tradeDetailsPosAndQty"}>
                                        at price <b>{buyPrice} {base}</b>
                                        </div>
                                    </div>}
                                {ACTION_TYPE === c.ACTION_TYPE_SELL &&
                                    <div>
                                        <div className={"tradeDetailsPosAndQty"}>
                                            <b>{posSize} {quote}</b> {verb} for <small>{base}</small>
                                        </div>
                                        <div className={"tradeDetailsPosAndQty"}>
                                            at price <b>{sellPrice} {base}</b>
                                        </div>
                                    </div>}

                                <div style={{margin: "auto", width: "7.6rem"}}>
                                    <div className={"w3-progress-sizer"}>
                                        <div className="w3-progress-container w3-round-xlarge">
                                            <div className="w3-progressbar w3-round-xlarge" style={{width:`${progress}%`}}/>
                                        </div>
                                    </div>
                                    <div className={"progressBarText"}>{progress}% FILLED</div>
                                </div>
                            </div>}

                            {tradeState === c.TRADE_STATE_WITH_CHILD_ONGOING &&
                                <div>
                                    {ACTION_TYPE === c.ACTION_TYPE_BUY && <div>
                                        <div className={"tradeDetailsPosAndQty"}>
                                            <b>{posSize} {base}</b> {verbPastTense} <small>{executedQty} {quote}</small>
                                        </div>
                                        <div className={"tradeDetailsPosAndQty"}>
                                           at price <b>{buyPrice} {base}</b>
                                        </div>
                                    </div>}
                                    {ACTION_TYPE === c.ACTION_TYPE_SELL && <div>
                                        <div className={"tradeDetailsPosAndQty"}>
                                            <b>{posSize} {quote}</b> {verbPastTense} for <small>{cummulativeQuoteQty} {base}</small>
                                        </div>
                                        <div className={"tradeDetailsPosAndQty"}>
                                            at price <b>{sellPrice} {base}</b>
                                        </div>
                                    </div>}
                                </div>
                            }

                            <div className={`tradeDetailsCurrentPrice ${currentPriceClass}`} title={"Current price"}>
                                {currentPriceDeltaPercent}% · {currentPrice}
                            </div>
                            <div className={"tradeDetailsMaxPrice"}>
                                {maxPrice > 0 && <div className={"maxPriceContainer2"}>max price {maxDelta}% · {maxPrice}</div>}
                                {minPrice > 0 && <div className={"minPriceContainer2"}>min price {minDelta}% · {minPrice}</div>}
                            </div>
                            <div className={"vertSpacer2"}/>
                            {tradeAsOrder.from_SUC && <div style={{padding: "0.6rem"}}>
                                <Paper className={"tradeDetailsTradeConditionsPaper"}>
                                    <div>
                                        {(tradeAsOrder.connected_to_signal === true || tradeAsOrder.connected_to_signal === "true") && <div><FormControlLabel
                                            control={<CustomColorCheckbox checked={true}/>}
                                            label={"Connected"}
                                            onChange={()=>{this.updateTradeFromSUCConnection(Math.random())}}
                                        />
                                            <div className={"smallExplanations2"} style={{marginLeft: "1rem"}}>Receiving updates from Signal. You cannot edit it.</div>
                                        </div>}
                                        {(tradeAsOrder.connected_to_signal === false || tradeAsOrder.connected_to_signal === "false") && <div><FormControlLabel
                                            control={<CustomColorCheckbox checked={false}/>}
                                            label={"Connected"}
                                            onChange={()=>{this.updateTradeFromSUCConnection(Math.random())}}
                                        />
                                            <div className={"smallExplanations2"} style={{marginLeft: "1rem"}}>Won't receive updates from Signal. You can edit it.</div>
                                        </div>}
                                    </div>
                                </Paper>
                            </div>}
                            <div style={{padding: "0.6rem"}}>
                                <Paper className={"tradeDetailsTradeConditionsPaper"}>
                                    <div style={{display: "flex", justifyContent: "space-between" ,fontSize: "0.7rem"}}>
                                        <span>ORDER CONDITIONS</span>
                                        {allowedToEditEnterOrderConditions && <span style={{cursor: "pointer"}} onClick={()=> {this.openEditEnterTradeConditionsPanel()}}><Create/></span>}
                                    </div>
                                    {tradeAsOrder.action_type_option !== c.ACTION_TYPE_OPTION_MARKET && <div className={"takeProfitStopLossConfigTradeDetails"}>
                                        {verbFirstCaps} price&nbsp;
                                        {ACTION_TYPE === c.ACTION_TYPE_BUY && <b>{buyPrice}</b>}
                                        {ACTION_TYPE === c.ACTION_TYPE_SELL && <span><b>{sellPrice}</b> {base}</span>}
                                    </div>}
                                    <div className={"takeProfitStopLossConfigTradeDetails"}>
                                        {verbFirstCaps} <b>{actionTypeOptionExplicit}</b>
                                    </div>
                                    {(this.props.ASAS &&
                                        this.props.ASAS.suspendActivities === "true" && this.props.ASAS.suspendedActivitiesMap &&
                                        this.props.ASAS.suspendedActivitiesMap[c.SUSPENDED_ACTIVITIES_NEW_CONVERSION_ID])
                                    && <div className={"maintenanceWarningSmall"} style={{marginTop: "0.6rem"}}>Editing enter order conditions is temporary disabled.</div>}
                                </Paper>
                            </div>
                            <div style={{padding: "0.6rem"}}>
                                <TradeDetailsMainButtons trade={tradeAsOrder} takeProfit={this.openTakeProfitPanel} cancelTrade={this.openCancelTradePanel}
                                                         discardTrade={this.openDiscardTradePanel} state={tradeState}/>
                            </div>
                        </Paper>
                    </Fade>}
                    {this.state.isCancelTradePanelOpen &&
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeCancelTradePanel()}}><Clear/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>Cancel order</div>
                                    <div style={{width: "2.6rem"}}/>
                                </div>
                            </div>
                            <div className={"tradeDetailsTradeConditionsPaper"}>
                                <CancelItem cancelItem={this.cancelTrade} nevermind={this.closeCancelTradePanel}
                                    question={"Are you sure you want to cancel this order?"}
                                    cancelText={"Cancel"} nevermindText={"Nevermind"}/>
                            </div>
                        </Paper>}
                    {this.state.isDiscardTradePanelOpen &&
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeDiscardTradePanel()}}><Clear/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>Discard order</div>
                                    <div style={{width: "2.6rem"}}/>
                                </div>
                            </div>
                            <div className={"tradeDetailsTradeConditionsPaper"}>
                                <DiscardItem discardItem={this.discardTrade} nevermind={this.closeDiscardTradePanel}
                                             question={"Are you sure you want to discard this order?"}
                                             discardWarningText={"Discarding this order will remove it from your waiting list."}/>
                            </div>
                        </Paper>}
                    {this.state.isEditEnterTradeConditionsPanelOpen &&
                    <Paper style={{backgroundColor: "#101926"}}>
                        <div className={"tradeDetailsTopBar"}>
                            <div className={"tradeDetailsTopBarVerticalCenter"}>
                                <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeEditEnterTradeConditionsPanel()}}><Clear/></div>
                                <div className={"tradeDetailsTopBarTitle"}>Order conditions</div>
                                <div className={"linkButton"} style={{marginRight: "0.6rem"}} onClick={()=> {this.updateEnterTradeConditions()}}>SAVE</div>
                            </div>
                        </div>
                        <div className={"tradeDetailsTradeConditionsPaper"}>
                            <EnterTradeConditions
                                actionType={tradeAsOrder.action_type}
                                fixedPriceLabel={`${verbFirstCaps} price`}
                                actionTypeOptionLabel={`${verbFirstCaps} @`}
                                actionTypeOption={this.state.actionTypeOption}
                                handleChangeActionTypeOption={this.handleChangeActionTypeOption}
                                fixedPrice={this.state.fixedPrice}
                                handleChangeFixedPrice={this.handleChangeFixedPrice}
                                quote={quote}
                                base={base}
                                currentPrice={this.state.executeTakeProfitPrice}
                                hideTitle={false}
                            />
                        </div>
                    </Paper>}
                </div>
            </div>
            <div key={"orderBook"} className={"OrderBook"}>
                <OrderBook base={base} quote={quote} orderBook={this.props.orderBook} size={12}/>
            </div>
            <div className={"vertSpacer1_5"}/>
        </div>
    }
}

let mapStateToProps = rootState =>({
    user: rootState.authentication.user,
    tradeAsOrder: rootState.trades.openedOrder,
    // orderBook: rootState.newTrade.orderBook,
    orderBook: rootState.orderBook.orderBook,
    ASAS: rootState.administration.asas
})

let mapDispatchToProps = dispatch => {
    return {
        openTrade: (trade) => dispatch(openTrade(trade)),
        updateEnterTradeConditions: (props) => dispatch(updateEnterTradeConditions(props)),
        updateTradeCancelIt: (props) => dispatch(updateTradeCancelIt(props)),
        updateTradeAsOrderDiscardIt: (props) => dispatch(updateTradeAsOrderDiscardIt(props)),
        getCurrentPriceOnPair: (props, cb) => dispatch(getCurrentPriceOnPair(props, cb)),
        // getOrderBook: (props) => dispatch(getOrderBook(props)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps) (OrderDetails)
