import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Fab from "@material-ui/core/Fab";
import {Link as RouterLink} from "react-router-dom";
import {Check} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 270,
        backgroundColor: '#101A26'
    },
    aboutTextField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 540,
        backgroundColor: '#101A26'
    },
    fabCancel: {
        margin: theme.spacing(1),
        width: 160,
        background: "#101926",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "white",
        '&:hover': {
            background: "#424242"
        }
    },
    fabPreview: {
        margin: theme.spacing(1),
        width: 160,
        background: "#3BADFF",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "black",
        '&:hover': {
            background: "#424242", color: "white"
        }
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default function TradeConditions(props) {
    const classes = useStyles();
    let {

        _id,
        applicationSaved,
        applicationNotSaved,
        about,
        handleChangeAbout,
        telegramChannel,
        handleChangeTelegramChannel,
        website,
        handleChangeWebsite,
        followers,
        handleChangeFollowers,
        apply
    } = props
    return (<div key={"theForm"}>
            <div className={"vertSpacer2"}/>
            <div className={"wizardSectionContainer"}>
                <div className={"wizardTitle2"}>
                    Application description
                </div>
                <TextField
                    className={classes.aboutTextField}
                    multiline
                    rows="4"
                    rowsMax="20"
                    helperText={"Please tell us more about you"}
                    variant={"outlined"}
                    margin={"dense"}
                    id="aboutTheTrader"
                    value={about}
                    onChange={handleChangeAbout}
                />
            </div>
            <div className={"wizardSectionContainer"}>
                <div className={"wizardTitle2"}>
                    Telegram channel
                </div>
                <TextField
                    className={classes.textField}
                    variant={"outlined"}
                    margin={"dense"}
                    id="telegramChannel"
                    value={telegramChannel}
                    onChange={handleChangeTelegramChannel}
                />
            </div>
            <div className={"wizardSectionContainer"}>
                <div className={"wizardTitle2"}>
                    Website
                </div>
                <TextField
                    className={classes.textField}
                    variant={"outlined"}
                    margin={"dense"}
                    id="website"
                    value={website}
                    onChange={handleChangeWebsite}
                />
            </div>
            <div className={"wizardSectionContainer"}>
                <div className={"wizardTitle2"}>
                    Followers
                </div>
                <TextField
                    className={classes.textField}
                    helperText={"If you have already some followers"}
                    variant={"outlined"}
                    margin={"dense"}
                    id="followers"
                    value={followers}
                    onChange={handleChangeFollowers}
                />
            </div>
            {applicationSaved && <div className={"wizardSectionContainer"}>
                <div className={"wizardTitle2 formFeedback"}>Thank you for your application</div>
            </div>}
            {applicationNotSaved && <div className={"wizardSectionContainer"}>
                <div className={"wizardTitle2 formNegativeFeedback"}>Your application could not be saved at the time</div>
            </div>}
            <div style={{display: "flex", justifyContent: "space-between", maxWidth: "24em", padding: "2em 0.6em 2em 0.6em"}}>
                <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabCancel}>
                    <RouterLink to={'/myprofile'} style={{color:"white"}}>
                        Cancel&nbsp;&nbsp;
                    </RouterLink>
                </Fab>
                <Fab variant="extended" color="primary" aria-label="Preview" className={classes.fabPreview} onClick={apply}>
                    <Check className={classes.extendedIcon} />
                    {!_id && <span>APPLY&nbsp;&nbsp;</span>}
                    {_id && <span>UPDATE&nbsp;&nbsp;</span>}
                </Fab>
            </div>
        </div>
    )
}
