import axios from './axiosFabric'
import {handleError} from "./errorHandler"
import {clearErrors} from "./errors"
import {
    ENABLE_2FA,
    ENABLE_2FA_ERROR,
    GENERATE_2FA,
    DISABLE_2FA,
    DISABLE_2FA_ERROR,
    GET_USER_SETTINGS,
    SET_ANTI_PHISHING_CODE,
    REMOVE_ANTI_PHISHING_CODE,
    SIGNAL_SETTINGS_UPDATE_ERRORS,
    SIGNALS_SETTINGS_UPDATE_CLEAR_ERRORS,
    GET_NO_OF_REFERRED_USERS,
    AFFILIATE_GET_COMMISSIONS_GET_INITIAL,
    AFFILIATE_GET_COMMISSIONS_GET_ALL,
    USER_HAS_FUTURES_ACTIVATED,
    SIGNAL_SETTINGS_UPDATED,
    SIGNAL_SETTINGS_UPDATED_RESET
} from "./actionTypes"
import {isAuthorizationHeaderPresent} from "./authentication"

export const generate2FA = () => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/profile/generate2fa`)
        .then(res => {
            dispatch({
                type: GENERATE_2FA,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

// export const cancel2FA = () => dispatch => {
//     if (!isAuthorizationHeaderPresent(axios))
//         return
//     axios.post(`/profile/cancel2fa`)
//         .then(() => {
//             dispatch({
//                 type: CANCEL_2FA
//             })
//         }).catch(error => handleError(error, dispatch))
// }

export const enable2FA = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/profile/enable2fa`, props)
        .then(res => {
            dispatch(clearErrors())
            if (cb) cb()
            // const { token } = res.data
            // saveJWT(token)
            // saveJWTOnAxios(token, axios)
            // dispatch({
            //     type: LOGIN,
            //     payload: jwt_decode(token)
            // })
            dispatch({
                type: ENABLE_2FA
            })
        }).catch(error => handleError(error, dispatch, ENABLE_2FA_ERROR))
}

export const disable2FA = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/profile/disable2fa`, props)
        .then(res => {
            dispatch(clearErrors())
            if (cb) cb()
            // const { token } = res.data
            // saveJWT(token)
            // saveJWTOnAxios(token)
            // dispatch({
            //     type: LOGIN,
            //     payload: jwt_decode(token)
            // })
            dispatch({
                type: DISABLE_2FA
            })
        }).catch(error => handleError(error, dispatch, DISABLE_2FA_ERROR))
}

export const getUserSettings = (cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/profile/getusersettings`)
        .then(res => {
            if (cb) cb(res.data)
            dispatch({
                type: GET_USER_SETTINGS,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch, GET_USER_SETTINGS))
}

export const getUserNotificationSettings = ({userDbId, unsubscribeToken}, cb) => dispatch => {
    axios.post(`/profile/getusernotificationsettings`, {userDbId, unsubscribeToken})
        .then(res => {
            if (cb) cb(res.data)
        }).catch(error => handleError(error, dispatch, GET_USER_SETTINGS))
}

export const saveUserNotificationsSettings2 = (props) => dispatch => {
    axios.post(`/profile/saveusernotificationssettings2`, props)
        .then().catch(error => handleError(error, dispatch, GET_USER_SETTINGS))
}

export const setUserAntiPhishingCode = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/profile/setantiphishingcode`, props)
        .then(res => {
            dispatch({
                type: SET_ANTI_PHISHING_CODE,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch, GET_USER_SETTINGS))
}

export const removeUserAntiPhishingCode = () => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/profile/removeantiphishingcode`)
        .then(res => {
            dispatch({
                type: REMOVE_ANTI_PHISHING_CODE,
            })
        }).catch(error => handleError(error, dispatch, GET_USER_SETTINGS))
}

export const getUserSignalSettings = (cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/profile/getsignalssettings`)
        .then(res => {
            if (cb) cb(res.data)
        }).catch(error => handleError(error))
}

export const saveUserSignalSettings = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    dispatch({
        type: SIGNALS_SETTINGS_UPDATE_CLEAR_ERRORS,
    })
    axios.post(`/profile/savesignalssettings`, props)
        .then(res => {
            if (res.data.errors) {
                dispatch({
                    type: SIGNAL_SETTINGS_UPDATE_ERRORS,
                    payload: res.data.errors
                })
            } else {
                if (cb) cb()
                // dispatch({
                //     type: SIGNAL_SETTINGS_UPDATED,
                //     payload: res.data
                // })
            }
        }).catch(error => handleError(error))
}

export const saveUserNotificationsSettings = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/profile/saveusernotificationssettings`, props)
        .then().catch(error => handleError(error, dispatch, GET_USER_SETTINGS))
}

export const getNoOfReferredUsers = (cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/profile/getnoofreferredusers`)
        .then(res => {
            if (cb) cb(res.data)
            dispatch({
                type: GET_NO_OF_REFERRED_USERS,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch, GET_NO_OF_REFERRED_USERS))
}

export const getAffiliateCommissionsInitial = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    props.page = 0
    axios.post(`/profile/commissions/getbypage`, props)
        .then(res => {
            dispatch({
                type: AFFILIATE_GET_COMMISSIONS_GET_INITIAL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getAffiliateCommissionsByPage = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/profile/commissions/getbypage`, props)
        .then(res => {
            dispatch({
                type: AFFILIATE_GET_COMMISSIONS_GET_ALL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const hasFuturesActivated = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/profile/futures/hasfuturesactivated`, props)
        .then(res => {
            if (cb) cb(res.data)
            dispatch({
                type: USER_HAS_FUTURES_ACTIVATED,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const activateFuturesOption = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/profile/futures/activatefutures`, props)
        .then(res => {
            if (cb) cb(res.data)
            dispatch({
                type: USER_HAS_FUTURES_ACTIVATED,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const resetSignalsSettingsSaved = () => dispatch => {
    dispatch({
        type: SIGNAL_SETTINGS_UPDATED_RESET
    })
}


//TODO TO BE REMOVED IN PRODUCTION
export const r3203 = () => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/profile/r3203`)
        .then(res => {
            //do nothing
        }).catch(error => handleError(error, dispatch))
}