import React, {Component} from 'react'
import {Helmet} from "react-helmet/es/Helmet"
import {getTitle} from "../../fncomponents/PageTitleUtils"
import Button from "@material-ui/core/Button"
import {ChevronLeft} from "@material-ui/icons"
import TraderBox2 from "../../fncomponents/TraderBox2"
import {connect} from "react-redux"
import {getTrader} from "../../../redux/actions/traders";

const qs = require("query-string")

class TraderPresentationBox extends Component {


    constructor(props) {
        super(props)
        this.state = {
        }

    }
    componentDidMount() {
        let f = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).f
        if ("es3" === f) {
            this.setState({
                back: "/trader/mypage"
            })
        }
        this.state.traderId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).tid
        this.props.getTrader({_id: this.state.traderId})
    }


    render() {
        let {trader} = this.props
        let _t, _s
        if (trader) {
            _t = trader.trader
        }

        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <Button onClick={()=>{this.props.history.push(this.state.back)}} color="primary" variant="outlined"><ChevronLeft/> My Page</Button>
            {_t && <TraderBox2 trader={_t}/>}
        </div>
    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    trader: rootState.traders.trader
})

let mapDispatchToProps = dispatch => {
    return {
        getTrader: (props) => dispatch(getTrader(props))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (TraderPresentationBox)
