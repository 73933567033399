import React, {Component} from 'react'
import {connect} from 'react-redux'
import SelectPair from "../fncomponents/SelectPair"
import SetPosSize from "../fncomponents/SetPosSize"
import BuySellAt from "../fncomponents/BuySellAt"
import TradeConditions from "../fncomponents/TradeConditions"
import SelectActionTypeRadios from "../fncomponents/SelectActionTypeRadios"
import NewTradeStep1Buttons from "../fncomponents/NewTradeStep1Buttons"
import {initWS, closeWS} from "../../redux/actions/wsOrderBook"
import c from '../../constants'

import {
    getCurrenciesMap, getLastUsedParamsOnNewTrade,
    getCurrentPriceOnPair,
    previewNewTrade, checkUserBalance, getUserBaseBalance, getUserQuoteBalance,
    clearNotEnoughBalance
} from "../../redux/actions/newTrade"
import CircularIndeterminate from "../fncomponents/loading";
import {
    brushValue,
    getValueWithPrecision,
    isValidPair,
    scientificToDecimal
} from "../fncomponents/UISupportFns";
import {Polymer, Warning, Info} from "@material-ui/icons";
import {clearErrors} from "../../redux/actions/errors";
import {debounce} from "../hoc/myUtils";
import OrderBook from "../fncomponents/OrderBook";
import {getTitle} from "../fncomponents/PageTitleUtils";
import {Helmet} from "react-helmet/es/Helmet";
import {getActiveSuspendedActivities} from "../../redux/actions/administration";


const DEBOUNCE_DELAY_IN_MS = 500

class NewTrade extends Component {

    constructor(props) {
        super(props)
        this.state = {
            quote: '',
            base: 'BTC',
            posSize: 0, //position size
            posSizeTick: 8, //position size tick (the amount of decimals are accepted regarding the quantity by the current currency)
            exchange: c.EXCHANGE_BINANCE, //exchange_binance or exchange_coinex
            tradeType: c.TRADE_TYPE_NEW_TRADE, //new or old
            actionType: c.ACTION_TYPE_BUY, //action_type_BUY or action_type_SELL
            actionTypeOption: '', //fixedPrice, market, justAboveBid
            fixedPrice: 0,
            currentPrice: 0,
            takeProfitOption: '', //TAKE_PROFIT_OPTION_MANUAL, auto: TAKE_PROFIT_OPTION_AT_A_SPECIFIC_PERCENTAGE/TAKE_PROFIT_OPTION_AT_A_SPECIFIC_PRICE
            takeProfitPriceOrPercent: 0,
            stopLossOption: '',//auto: price/percent, trailing, conditional
            stopLossPriceOrPercent: 0,
            candlesType: 2,
            waitForADip: false,
            waitForADipPrice: 0,
            showLoading: true,
        }

        this.handleChangeActionType = this.handleChangeActionType.bind(this)
        this.handleChangeQuote = this.handleChangeQuote.bind(this)
        this.validatePairAndGetUserQuoteBalance = this.validatePairAndGetUserQuoteBalance.bind(this)
        this.debounceValidatePairAndGetUserQuoteBalance = debounce(this.validatePairAndGetUserQuoteBalance, DEBOUNCE_DELAY_IN_MS)
        this.handleChangeBase = this.handleChangeBase.bind(this)
        this.handleChangeTradeType = this.handleChangeTradeType.bind(this)
        this.handleChangePosSize = this.handleChangePosSize.bind(this)
        this.handleChangeFixedPrice = this.handleChangeFixedPrice.bind(this)
        this.handleChangeActionTypeOption = this.handleChangeActionTypeOption.bind(this)
        this.getCurrentPrices = this.getCurrentPrices.bind(this)
        this.handleChangeTakeProfitOption = this.handleChangeTakeProfitOption.bind(this)
        this.handleChangeTakeProfitPriceOrPercent = this.handleChangeTakeProfitPriceOrPercent.bind(this)
        this.fixTakeProfitPriceOrPercent = this.fixTakeProfitPriceOrPercent.bind(this)
        this.debounceFixTakeProfitPriceOrPercent = debounce(this.fixTakeProfitPriceOrPercent, DEBOUNCE_DELAY_IN_MS)
        this.handleChangeStopLossOption = this.handleChangeStopLossOption.bind(this)
        this.handleChangeStopLossPriceOrPercent = this.handleChangeStopLossPriceOrPercent.bind(this)
        this.fixStopLossPriceOrPercent = this.fixStopLossPriceOrPercent.bind(this)
        this.debounceFixStopLossPriceOrPercent = debounce(this.fixStopLossPriceOrPercent, DEBOUNCE_DELAY_IN_MS)
        this.previewNewTrade = this.previewNewTrade.bind(this)
        this.checkUserBalance = this.checkUserBalance.bind(this)
        this.switchWaitForADip = this.switchWaitForADip.bind(this)
        this.handleChangeWaitForADipPrice = this.handleChangeWaitForADipPrice.bind(this)
        this.fixPosSize = this.fixPosSize.bind(this)
        this.debounceFixPosSize = debounce(this.fixPosSize, DEBOUNCE_DELAY_IN_MS)
        this.fixFixedPrice = this.fixFixedPrice.bind(this)
        this.debounceFixFixedPrice = debounce(this.fixFixedPrice, DEBOUNCE_DELAY_IN_MS)

        this.selectMaxBaseBalance = this.selectMaxBaseBalance.bind(this)
        this.selectMaxQuoteBalance = this.selectMaxQuoteBalance.bind(this)
        this.actualizeTakeProfitAndStopLoss = this.actualizeTakeProfitAndStopLoss.bind(this)

        this.getPosSizeCurrency = this.getPosSizeCurrency.bind(this)

        this.updateFixedPriceToAvgOldBuyPrice = this.updateFixedPriceToAvgOldBuyPrice.bind(this)

        this.switchWithOrWithoutStopLossTarget = this.switchWithOrWithoutStopLossTarget.bind(this)

        this.handleChangeCandlesType = this.handleChangeCandlesType.bind(this)

        this.getCurrentPriceFromOrderBook = this.getCurrentPriceFromOrderBook.bind(this)
    }

    getCurrentPriceFromOrderBook() {
        if (this.props.orderBook && this.props.orderBook.bids && this.props.orderBook.bids[0])
            return brushValue(this.props.orderBook.bids[0][0], this.props.orderBook.tickSize)
        return 0
    }

    handleChangeCandlesType(e) {
        this.setState({
            candlesType: e.target.value
        })
    }

    switchWithOrWithoutStopLossTarget() {
        if (this.state.stopLossOption === c.STOP_LOSS_OPTION_MANUAL) {
            this.setState({
                stopLossOption: c.STOP_LOSS_OPTION_PERCENTAGE,
                stopLossPriceOrPercent: 10,
            })
        } else {
            this.setState({
                stopLossOption: c.STOP_LOSS_OPTION_MANUAL
            })
        }
    }

    handleChangeActionType() {
        this.props.clearNotEnoughBalance()
        if (this.state.actionType === c.ACTION_TYPE_BUY) {
            if (this.state.actionTypeOption === c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE) {
                this.state.actionTypeOption = c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE
            } else if (this.state.actionTypeOption === c.ACTION_TYPE_OPTION_WAIT_FOR_A_DIP) {
                this.state.actionTypeOption = c.ACTION_TYPE_OPTION_FIXED_PRICE
            }
            this.setState({
                actionType: c.ACTION_TYPE_SELL,
                posSize: 0,
                posSizeCurrency: this.state.quote,
            })
        } else {
            if (this.state.actionTypeOption === c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE) {
                this.state.actionTypeOption = c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE
            }
            this.setState({
                actionType: c.ACTION_TYPE_BUY,
                posSize: 0,
                posSizeCurrency: this.state.base,
            })
        }
        this.actualizeTakeProfitAndStopLoss(this.state.currentLastBidPrice)
    }


    actualizeTakeProfitAndStopLoss(currentLastBidPrice) {
        let signForTP = 1
        let signForSL = -1
        if (this.state.actionType === c.ACTION_TYPE_SELL) {
            signForTP = -1
            signForSL = 1
        }

        if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE) {
            this.state.takeProfitPriceOrPercent = getValueWithPrecision(currentLastBidPrice * (1 + signForTP * 0.1), this.state.tickSize)
        } else if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
            this.state.takeProfitPriceOrPercent = 10
        }

        if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PRICE) {
            this.state.stopLossPriceOrPercent = getValueWithPrecision(currentLastBidPrice * (1 + signForSL * 0.05), this.state.tickSize)
        } else if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE ||
            this.state.stopLossOption === c.STOP_LOSS_OPTION_TRAILING) {
            this.state.stopLossPriceOrPercent = 5
        }
    }

    handleChangeQuote(e) {
        let newQuote = e.target.value.toUpperCase()
        this.setState({
            quote: newQuote
        })
        this.debounceValidatePairAndGetUserQuoteBalance()
    }

    validatePairAndGetUserQuoteBalance() {
        this.props.clearNotEnoughBalance()
        this.props.getUserQuoteBalance({currency: this.state.quote}, result => {
            this.setState({
                userQuotePrecision: parseInt(result.precision),
                userQuoteBalance: result.value ? getValueWithPrecision(result.value, result.precision) : 0,
            })
        })

        if(isValidPair(this.state.quote, this.state.base, this.props.quoteCurrenciesNom)) {
            //reset WS for OrderBook
            closeWS()
            this.props.user && initWS(this.props.user.userId, `${this.state.quote}${this.state.base}`)
            this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, result => {
                if (result.error) {
                    this.setState({
                        invalidPairError: true
                    })
                    return
                } else {
                    this.setState({
                        invalidPairError: false
                    })
                }
                this.state.tickSize = parseInt(result.tick_size)
                let currentLastBidPrice = getValueWithPrecision(result.current_price, this.state.tickSize)
                this.actualizeTakeProfitAndStopLoss(currentLastBidPrice)
                this.setState({
                    fixedPrice: result.current_price ? currentLastBidPrice : 0,
                    currentPrice: result.current_price,
                    userQuotePrecision: parseInt(result.precision),
                    blvtRealLeverage: result.blvtRealLeverage
                })
            })
        } else {
            this.setState({
                fixedPrice: 0,
                currentPrice: 0,
            })
        }
    }


    updateFixedPriceToAvgOldBuyPrice(state, sourceForAvgBuyPrices) {
        let {avgBuyPriceBTCForQuoteOldTrade,
            avgBuyPriceUSDTForQuoteOldTrade,
            avgBuyPriceETHForQuoteOldTrade} = sourceForAvgBuyPrices
        if (avgBuyPriceBTCForQuoteOldTrade ||
            avgBuyPriceUSDTForQuoteOldTrade ||
            avgBuyPriceETHForQuoteOldTrade) {
            let avgBuyPriceByBase = 0
            if (this.state.base === "BTC")
                avgBuyPriceByBase = avgBuyPriceBTCForQuoteOldTrade
            else if (this.state.base === "USDT")
                avgBuyPriceByBase = avgBuyPriceUSDTForQuoteOldTrade
            else if (this.state.base === "ETH")
                avgBuyPriceByBase = avgBuyPriceETHForQuoteOldTrade
            state.fixedPrice = avgBuyPriceByBase
            state.userAvgBuyPriceForQuote = avgBuyPriceByBase
        }
    }

    handleChangeBase(e) {
        let newBase = e.target.value
        this.state.base = newBase

        if (this.state.tradeType === c.TRADE_TYPE_NEW_TRADE) {
            this.props.getCurrentPriceOnPair({quote: this.state.quote, base:  newBase}, result => {
                if (result.error) {
                    this.setState({
                        invalidPairError: true
                    })
                    return
                } else {
                    this.setState({
                        invalidPairError: false
                    })
                }
                this.state.tickSize = parseInt(result.tick_size)
                let currentLastBidPrice = getValueWithPrecision(result.current_price, this.state.tickSize)
                this.actualizeTakeProfitAndStopLoss(currentLastBidPrice)
                this.setState({
                    fixedPrice: result.current_price ? currentLastBidPrice : 0,
                    currentPrice: result.current_price
                })
            })
        } else if (this.state.tradeType === c.TRADE_TYPE_OLD) {
            if (this.state.actionType === c.ACTION_TYPE_BUY) {
                this.updateFixedPriceToAvgOldBuyPrice(this.state, this.props)
            }
        }

        this.props.getUserBaseBalance({currency: newBase})
        this.debounceValidatePairAndGetUserQuoteBalance()
    }


    handleChangePosSize(e) {
        this.setState({
            posSize: e.target.value
        })
        this.debounceFixPosSize()
    }

    fixPosSize() {
        if (this.state.posSize.length === 0)
            return

        let precision = 8

        precision = c.BASE_PRECISIONS[this.state.base]
        this.setState({
            posSize: getValueWithPrecision(this.state.posSize, precision)
        })
    }

    handleChangeTradeType() {
        this.props.clearNotEnoughBalance()
        if (this.state.actionType === c.ACTION_TYPE_BUY) {
            //Switch to OLD TRADE
            if (this.state.tradeType === c.TRADE_TYPE_NEW_TRADE) {
                let  state = {
                    tradeType: c.TRADE_TYPE_OLD,
                    actionTypeOption: c.ACTION_TYPE_OPTION_FIXED_PRICE,
                    posSize: 0,
                    posSizeCurrency: this.state.quote,
                }

                this.updateFixedPriceToAvgOldBuyPrice(state, this.props)
                this.setState(state)
            }
            // Switch to NEW TRADE
            else {
                this.setState({
                    tradeType: c.TRADE_TYPE_NEW_TRADE,
                    actionTypeOption: c.ACTION_TYPE_OPTION_FIXED_PRICE,
                    posSize: 0,
                    posSizeCurrency: this.state.base,
                })
            }
        } else if (this.state.actionType === c.ACTION_TYPE_SELL) {
            //Switch to OLD TRADE
            if (this.state.tradeType === c.TRADE_TYPE_NEW_TRADE) {
                this.setState({
                    tradeType: c.TRADE_TYPE_OLD,
                    actionTypeOption: c.ACTION_TYPE_OPTION_FIXED_PRICE,
                    posSize: 0,
                    posSizeCurrency: this.state.base,
                })
            }
            //Switch to NEW TRADE
            else {
                this.setState({
                    tradeType: c.TRADE_TYPE_NEW_TRADE,
                    actionTypeOption: c.ACTION_TYPE_OPTION_FIXED_PRICE,
                    posSize: 0,
                    posSizeCurrency: this.state.quote,
                })
            }
        }
    }

    handleChangeFixedPrice(e) {
        if (this.state.tradeType === c.TRADE_TYPE_OLD && this.props.userAvgBuyPriceForQuote)
            return
        this.setState({
            fixedPrice: e.target.value
        })
        this.debounceFixFixedPrice()
    }

    fixFixedPrice() {
        if (this.state.fixedPrice.length === 0)
            return
        let fixedPriceError = undefined
        if (this.state.actionTypeOption === c.ACTION_TYPE_OPTION_FIXED_PRICE) {
            let fixedPriceF = parseFloat(this.state.fixedPrice)
            let currentPriceF = parseFloat(this.state.currentPrice)
            let delta = (fixedPriceF - currentPriceF) / currentPriceF
            if (this.state.actionType === c.ACTION_TYPE_SELL)
                delta *= -1
            if (delta >= 0.1) {
                if (this.state.actionType === c.ACTION_TYPE_BUY) {
                    fixedPriceError = `buy price is current price +${(delta * 100).toFixed(2)}%`
                } else if (this.state.actionType === c.ACTION_TYPE_SELL) {
                    fixedPriceError = `sell price is current price -${(delta * 100).toFixed(2)}%`
                }
            }
        }

        this.setState({
            fixedPrice: getValueWithPrecision(this.state.fixedPrice, this.state.tickSize),
            fixedPriceError
        })
    }

    handleChangeActionTypeOption(actTypeOption) {
        if (this.state.quote && this.state.quote.length < 2)
            return

        if (this.state.tradeType === c.TRADE_TYPE_OLD) {
            if (actTypeOption !== c.ACTION_TYPE_OPTION_FIXED_PRICE)
                return
        }

        this.setState({
            actionTypeOption: actTypeOption
        })

        if (this.state.quote.length > 1 && actTypeOption !== c.ACTION_TYPE_OPTION_MARKET) {
            this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, result => {
                if (result.error) {
                    this.setState({
                        invalidPairError: true
                    })
                    return
                } else {
                    this.setState({
                        invalidPairError: false
                    })
                }
                let currentPrice = result.current_price
                let price = 0
                if (this.state.actionType === c.ACTION_TYPE_BUY)
                    price = currentPrice ? parseFloat(currentPrice) * (actTypeOption === c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE ? c.LAST_BID_PRICE_MULTIPLIER : 1): 0
                else if (this.state.actionType === c.ACTION_TYPE_SELL)
                    price = currentPrice ? parseFloat(currentPrice) * (actTypeOption === c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE ? c.FIRST_ASK_PRICE_MULTIPLIER : 1): 0

                this.state.fixedPrice = scientificToDecimal(getValueWithPrecision(price, this.state.tickSize))
                this.currentPrice = result.current_price
            })
        }
    }

    getCurrentPrices() {
        this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, result => {
            if (result.error) {
                this.setState({
                    invalidPairError: true
                })
                return
            } else {
                this.setState({
                    invalidPairError: false
                })
            }
        })
    }

    handleChangeTakeProfitOption(option) {
        //If there is no change, do nothing
        if (this.state.takeProfitOption === option) {
            this.setState({
                takeProfitOption: c.TAKE_PROFIT_OPTION_MANUAL,
                takeProfitPriceOrPercent: ""
            })
            return
        }

        if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_MANUAL) {
            //If the transition is from Manual, don't do any calculations
        } else {
            //Else if the transition is from Percentage or Price, transform
            //percentage to price and vice-versa
            if (option === c.TAKE_PROFIT_OPTION_MANUAL) {
                this.state.takeProfitPriceOrPercent = ""
            } else if (option === c.TAKE_PROFIT_OPTION_PRICE) {
                let oldPercent = parseFloat(this.state.takeProfitPriceOrPercent)
                let currentPrice = this.props.currentLastBidPrice
                let sign = 1
                if (this.state.actionType === c.ACTION_TYPE_SELL)
                    sign = -1
                let price = (1 + sign * oldPercent/100) * parseFloat(currentPrice)
                this.state.takeProfitPriceOrPercent = getValueWithPrecision(price, this.state.tickSize)
            } else if (option === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
                let oldPrice = parseFloat(this.state.takeProfitPriceOrPercent)
                let currentPrice = this.props.currentLastBidPrice
                let percent = ((oldPrice - currentPrice) / currentPrice) * 100
                this.state.takeProfitPriceOrPercent = getValueWithPrecision(percent, 1)
            }
        }

        this.setState({
            takeProfitOption: option
        })
    }

    handleChangeTakeProfitPriceOrPercent(e) {
        this.setState({
            takeProfitPriceOrPercent: e.target.value
        })

        this.debounceFixTakeProfitPriceOrPercent()
    }

    fixTakeProfitPriceOrPercent() {
        if (this.state.takeProfitPriceOrPercent.length  === 0)
            return

        if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE)
            this.setState({
                takeProfitPriceOrPercent: getValueWithPrecision(this.state.takeProfitPriceOrPercent, 2)
            })
        else {
            if (this.state.takeProfitPriceOrPercent.length > 0)
                this.setState({
                    takeProfitPriceOrPercent: getValueWithPrecision(this.state.takeProfitPriceOrPercent, this.state.tickSize)
                })
        }
    }

    handleChangeStopLossOption(option) {
        //If there is no change, do nothing
        if (option === this.state.stopLossOption)
            return

        let oldSLOption = this.state.stopLossOption
        let oldSLOptionType = 0
        switch (oldSLOption) {
            case c.STOP_LOSS_OPTION_PRICE: oldSLOptionType = 1; break;
            case c.STOP_LOSS_OPTION_CONDITIONAL: oldSLOptionType = 1; break;
            case c.STOP_LOSS_OPTION_PERCENTAGE: oldSLOptionType = 2; break;
            case c.STOP_LOSS_OPTION_TRAILING: oldSLOptionType = 2; break;
        }

        //Attention:
        //STOP_LOSS_OPTION_TRAILING - means that the stop loss option is Trailing
        //STOP_LOSS_OPTION_PERCENTAGE_FROM_TRAILING - means that the user switched off the Trailing option and we default to Percentage
        if (option === c.STOP_LOSS_OPTION_PERCENTAGE_FROM_TRAILING) {
            option = c.STOP_LOSS_OPTION_PERCENTAGE
        }

        let optionType = 0
        switch (option) {
            case c.STOP_LOSS_OPTION_PRICE: optionType = 1; break;
            case c.STOP_LOSS_OPTION_CONDITIONAL: optionType = 1; break;
            case c.STOP_LOSS_OPTION_PERCENTAGE: optionType = 2; break;
            case c.STOP_LOSS_OPTION_TRAILING: optionType = 2; break;
        }

        let transitionDelta = oldSLOptionType - optionType

        if (transitionDelta === 0) {
            //no need for calculations
        } else if (transitionDelta === -1) {
            //transition from price to percentage
            let oldPrice = parseFloat(this.state.stopLossPriceOrPercent)
            let currentPrice = this.props.currentLastBidPrice
            let percent = ((currentPrice - oldPrice) / currentPrice) * 100
            this.state.stopLossPriceOrPercent = getValueWithPrecision(percent, 1)
        } else if (transitionDelta === 1) {
            //transition from percentage to price
            let oldPercent = parseFloat(this.state.stopLossPriceOrPercent)
            let currentPrice = this.props.currentLastBidPrice
            let sign = -1
            if (this.state.actionType === c.ACTION_TYPE_SELL)
                sign = 1
            let price = (1 + sign * oldPercent/100) * parseFloat(currentPrice)
            this.state.stopLossPriceOrPercent = getValueWithPrecision(price, this.state.tickSize)
        }

        this.setState({
            stopLossOption: option
        })
    }

    handleChangeStopLossPriceOrPercent(e) {
        this.setState({
            stopLossPriceOrPercent: e.target.value
        })
        this.debounceFixStopLossPriceOrPercent()
    }

    fixStopLossPriceOrPercent() {
        if (this.state.stopLossPriceOrPercent.length === 0)
            return

        if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PRICE || this.state.stopLossOption === c.STOP_LOSS_OPTION_CONDITIONAL)
            this.setState({
                stopLossPriceOrPercent: getValueWithPrecision(this.state.stopLossPriceOrPercent, this.state.tickSize)
            })
        else this.setState({
            stopLossPriceOrPercent: getValueWithPrecision(this.state.stopLossPriceOrPercent, 2)
        })
    }

    setLastUsedParamsToState(input) {
        this.state.actionType = input.actionType
        this.state.tradeType = input.tradeType
        this.state.quote = input.quote ? input.quote: ""
        this.state.base = input.base ? input.base : ""
        this.state.posSize = input.posSize ? input.posSize : 0
        this.state.posSizeTick = input.posSizeTick ? input.posSizeTick: 8
        this.state.actionTypeOption = input.actionTypeOption //fixed/market/just above bid/just below ask
        this.state.takeProfitOption = input.takeProfitOption //manual/percentage/fixed price
        this.state.takeProfitPriceOrPercent = input.takeProfitPriceOrPercent //value for percentage or fixed
        this.state.stopLossOption = input.stopLossOption //trailing/percentage/fixed price
        this.state.candlesType = input.candlesType ? parseInt(input.candlesType) : this.state.candlesType
        this.state.blvtRealLeverage = input.blvtRealLeverage
        switch (input.stopLoss) {
            case c.STOP_LOSS_OPTION_PERCENTAGE: this.state.stopLossTab = 0; break;
            case c.STOP_LOSS_OPTION_PRICE: this.state.stopLossTab = 0; break;
            case c.STOP_LOSS_OPTION_TRAILING: this.state.stopLossTab = 0; break;
            case c.STOP_LOSS_OPTION_CONDITIONAL: this.state.stopLossTab = 1; break;
            default: this.state.stopLossTab = 0; break;
        }
        this.state.stopLossPriceOrPercent = input.stopLossPriceOrPercent //value for each case
        this.state.fixedPrice = scientificToDecimal(input.currentLastBidPrice)
        this.state.precision = parseInt(input.precision)
        this.state.tickSize = parseInt(input.tickSize)

        this.actualizeTakeProfitAndStopLoss(input.currentLastBidPrice)
        this.setState({
            showLoading: false
        })
    }


    getPosSizeCurrency() {
        if (this.state.actionType === c.ACTION_TYPE_BUY) {
            if (this.state.tradeType === c.TRADE_TYPE_NEW_TRADE) {
                return this.state.base
            } else if (this.state.tradeType === c.TRADE_TYPE_OLD) {
                return this.state.quote
            }
        } else if (this.state.actionType === c.ACTION_TYPE_SELL) {
            if (this.state.tradeType === c.TRADE_TYPE_NEW_TRADE) {
                return this.state.quote
            } else if (this.state.tradeType === c.TRADE_TYPE_OLD) {
                return this.state.base
            }
        }
    }

    checkUserBalance() {
        this.props.checkUserBalance({currency: this.getPosSizeCurrency(), value: this.state.posSize})
    }

    previewNewTrade() {

        if (this.state.tradeType === c.TRADE_TYPE_OLD) {
            return this.setState({
                otherPosSizeErrors: "Unsupported operation"
            })
        }

        if (this.state.posSize <= 0) {
            return this.setState({
                otherPosSizeErrors: "Please correct value"
            })
        }

        if (this.state.invalidPairError) {
            return
        }

        this.state.posSizeCurrency = this.getPosSizeCurrency()
        this.props.clearErrors()

        this.props.checkUserBalance({currency: this.state.posSizeCurrency, value: this.state.posSize}, result => {
            if (result.status === c.USER_HAS_BALANCE) {
                this.props.previewNewTrade(this.state, ()=>{
                    this.props.history.push("/previewtrade")
                })
            }
        })

    }

    switchWaitForADip() {
        this.setState({
            waitForADip: !this.state.waitForADip
        })
    }

    handleChangeWaitForADipPrice(e) {
        this.setState({
            waitForADipPrice: e.target.value
        })
    }

    selectMaxBaseBalance(maxBaseBalance) {
        this.setState({
            posSize: getValueWithPrecision(maxBaseBalance, c.BASE_PRECISIONS[this.state.base])
        })
    }

    selectMaxQuoteBalance(maxQuoteBalance) {
        this.setState({
            posSize: getValueWithPrecision(maxQuoteBalance, this.props.userQuotePrecision)
        })
    }

    componentDidMount() {
        let {user} = this.props

        if (!this.props.baseCurrenciesNom) {
            this.props.getCurrenciesMap()
        }

        this.props.getLastUsedParamsOnNewTrade({}, data => {
            this.setLastUsedParamsToState(data)
            this.props.getUserBaseBalance({currency: data.base})
            this.props.getUserQuoteBalance({currency: data.quote})

            // closeWS()
            user && initWS(this.props.user.userId, `${data.quote}${data.base}`)

            this.props.getCurrentPriceOnPair({quote: data.quote, base: data.base}, result => {
                if (result.error) {
                    this.setState({
                        invalidPairError: true
                    })
                    return
                } else {
                    this.setState({
                        invalidPairError: false
                    })
                }

                let currentPrice = result.current_price
                let price = 0
                if (this.state.actionType === c.ACTION_TYPE_BUY)
                    price = currentPrice ? parseFloat(currentPrice) * (data.actionTypeOption === c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE ? c.LAST_BID_PRICE_MULTIPLIER : 1): 0
                else if (this.state.actionType === c.ACTION_TYPE_SELL)
                    price = currentPrice ? parseFloat(currentPrice) * (data.actionTypeOption === c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE ? c.FIRST_ASK_PRICE_MULTIPLIER : 1): 0

                if (this.state.tradeType === c.TRADE_TYPE_NEW_TRADE) {
                    this.state.fixedPrice = scientificToDecimal(getValueWithPrecision(price, this.state.tickSize))
                } else if (this.state.tradeType === c.TRADE_TYPE_OLD && this.props.userAvgBuyPriceForQuote) {
                    this.state.fixedPrice = this.props.userAvgBuyPriceForQuote
                }
                this.state.currentPrice = result.current_price
            })
        })

        this.props.clearErrors()
        this.props.clearNotEnoughBalance()

        this.props.getActiveSuspendedActivities(data => {
            this.state.ASAS = data
        })
    }


    componentWillUnmount() {
        closeWS()
    }

    render() {
        let {user} = this.props

        if (this.state.showLoading)
            return <div>
                <Helmet>
                    <title>{getTitle()}</title>
                </Helmet>
                <div className="loading">
                    <CircularIndeterminate/>
                </div>
                <div className={"loadingText"}>Getting current price...</div>
            </div>
        else return (
            <div className={"TradeContent"} style={{display: "flex"}}>
                <Helmet>
                    <title>{getTitle(`${this.state.quote}/${this.state.base}`, this.getCurrentPriceFromOrderBook())}</title>
                </Helmet>
                <div key={"theForm"}>
                    {(this.state.ASAS && this.state.ASAS.showWarningMessage === "true") &&
                    <div className={"platformInformationContainer"}>
                        <div><Info/></div>
                        <div style={{marginLeft: "0.6rem"}}>
                            {this.state.ASAS.warningMessage}
                        </div>
                    </div>}
                    {this.state.ASAS && this.state.ASAS.suspendActivities === "true" && this.state.ASAS.suspendedActivitiesMap && this.state.ASAS.suspendedActivitiesMap[c.SUSPENDED_ACTIVITIES_NEW_TRADE_ID] &&
                    <div className={"maintenanceWarning"}>
                        <div><Warning/></div>
                        <div style={{marginLeft: "0.6rem"}}>
                            Placing new trades is temporary disabled.
                        </div>
                    </div>}
                    <div className={"wizardTitle"}>
                        <span className={"wizardTitleIcon"}><Polymer/></span> Launch Spot Trade on <b>Binance</b>
                    </div>
                    <div className={"vertSpacer1_5"}/>
                    <div className={"horizSeparator"}/>
                    <div className={"vertSpacer1"}/>
                    <SelectActionTypeRadios actionType={this.state.actionType}
                                      handleChangeActionType={this.handleChangeActionType} user={user}/>
                    <div className={"vertSpacer1_5"}/>
                    <div className={"horizSeparator"}/>
                    <div className={"vertSpacer1_5"}/>
                    <SelectPair handleChangeQuote={this.handleChangeQuote}
                                handleChangeBase={this.handleChangeBase}
                                quote={this.state.quote}
                                base={this.state.base}
                                quoteCurrenciesNom={this.props.quoteCurrenciesNom}
                                baseCurrenciesNom={this.props.baseCurrenciesNom}
                                userBaseBalance={this.props.userBaseBalance}
                                userQuoteBalance={this.props.userQuoteBalance}
                                actionType={this.state.actionType}
                                tradeType={this.state.tradeType}
                                selectMaxBaseBalance={this.selectMaxBaseBalance}
                                selectMaxQuoteBalance={this.selectMaxQuoteBalance}
                                blvtRealLeverage={this.state.blvtRealLeverage}
                                invalidPairError={this.state.invalidPairError}
                    />
                    <div className={"vertSpacer1_5"}/>
                    <div className={"horizSeparator"}/>
                    <div className={"vertSpacer1_5"}/>
                    <SetPosSize posSize={this.state.posSize}
                                handleChangePosSize={this.handleChangePosSize}
                                amountAlreadyOwned={this.state.userQuoteBalance}
                                quote={this.state.quote}
                                base={this.state.base}
                                actionType={this.state.actionType}
                                tradeType={this.state.tradeType}
                                handleChangeTradeType={this.handleChangeTradeType}
                                checkUserBalance={this.checkUserBalance}
                                notEnoughBalance={this.props.notEnoughBalance}
                                otherPosSizeErrors={this.state.otherPosSizeErrors}
                                hideVariants={true}
                    />
                    <div className={"vertSpacer1_5"}/>
                    <div className={"horizSeparator"}/>
                    <div className={"vertSpacer1_5"}/>
                    <BuySellAt actionType={this.state.actionType}
                               tradeType={this.state.tradeType}
                               actionTypeOption={this.state.actionTypeOption}
                               handleChangeActionTypeOption={this.handleChangeActionTypeOption}
                               fixedPrice={this.state.fixedPrice}
                               fixedPriceError={this.state.fixedPriceError}
                               handleChangeFixedPrice={this.handleChangeFixedPrice}
                               quote={this.state.quote}
                               base={this.state.base}
                               currentLastBidPrice={this.props.currentLastBidPrice}
                               userAvgBuyPriceForQuote={this.state.userAvgBuyPriceForQuote}
                    />
                    <div className={"vertSpacer1_5"}/>
                    <div className={"horizSeparator"}/>
                    <div className={"vertSpacer1_5"}/>
                    <TradeConditions takeProfitOption={this.state.takeProfitOption}
                                     takeProfitPriceOrPercent={this.state.takeProfitPriceOrPercent}
                                     stopLossOption={this.state.stopLossOption}
                                     stopLossPriceOrPercent={this.state.stopLossPriceOrPercent}
                                     conditionalStopLossPrice={this.state.conditionalStopLossPrice}
                                     handleChangeTakeProfitOption={this.handleChangeTakeProfitOption}
                                     handleChangeTakeProfitPriceOrPercent={this.handleChangeTakeProfitPriceOrPercent}
                                     handleChangeStopLossOption={this.handleChangeStopLossOption}
                                     handleChangeStopLossPriceOrPercent={this.handleChangeStopLossPriceOrPercent}
                                     switchWithOrWithoutStopLossTarget={this.switchWithOrWithoutStopLossTarget}
                                     base={this.state.base}
                                     waitForADipOption={this.state.waitForADip}
                                     switchWaitForADip={this.switchWaitForADip}
                                     waitForADipPrice={this.state.waitForADipPrice}
                                     handleChangeWaitForADipPrice={this.handleChangeWaitForADipPrice}
                                     actionType={this.state.actionType}
                                     candlesType={this.state.candlesType}
                                     handleChangeCandlesType={this.handleChangeCandlesType}

                    />
                    <div className={"vertSpacer1_5"}/>
                    <div className={"horizSeparator"}/>
                    <div className={"vertSpacer1"}/>
                    <NewTradeStep1Buttons preview={this.previewNewTrade} ASAS={this.state.ASAS}/>
                </div>
                <div key={"orderBook"} className={"OrderBook"}>
                    <OrderBook base={this.state.base} quote={this.state.quote} orderBook={this.props.orderBook} size={12}/>
                </div>
                <div className={"vertSpacer1_5"}/>
            </div>
        )
    }

}



let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    base: rootState.newTrade.lastUsed.base,
    quote: rootState.newTrade.lastUsed.quote,
    tradeType: rootState.newTrade.lastUsed.tradeType,
    actionType: rootState.newTrade.lastUsed.actionType,
    posSize: rootState.newTrade.lastUsed.posSize,
    actionTypeOption: rootState.newTrade.lastUsed.actionTypeOption,
    takeProfitOption: rootState.newTrade.lastUsed.takeProfitOption,
    takeProfitPriceOrPercent: rootState.newTrade.lastUsed.takeProfitPriceOrPercent,
    stopLossOption: rootState.newTrade.lastUsed.stopLossOption,
    stopLossPriceOrPercent: rootState.newTrade.lastUsed.stopLossPriceOrPercent,


    baseCurrenciesNom: rootState.newTrade.baseCurrenciesNom,
    quoteCurrenciesNom: rootState.newTrade.quoteCurrenciesNom,
    currentLastBidPrice: rootState.newTrade.currentLastBidPrice,

    notEnoughBalance: rootState.newTrade.notEnoughBalance,
    userBaseBalance: rootState.newTrade.userBaseBalance,
    userQuoteBalance: rootState.newTrade.userQuoteBalance,
    userQuotePrecision: rootState.newTrade.userQuotePrecision,

    avgBuyPriceBTCForQuoteOldTrade: rootState.newTrade.avgBuyPriceBTCForQuoteOldTrade,
    avgBuyPriceUSDTForQuoteOldTrade: rootState.newTrade.avgBuyPriceUSDTForQuoteOldTrade,
    avgBuyPriceETHForQuoteOldTrade: rootState.newTrade.avgBuyPriceETHForQuoteOldTrade,

    // orderBook: rootState.newTrade.orderBook
    orderBook: rootState.orderBook.orderBook
})

let mapDispatchToProps = dispatch => {
    return {
        getCurrenciesMap: (props) => dispatch(getCurrenciesMap(props)),
        getLastUsedParamsOnNewTrade: (props, cb) => dispatch(getLastUsedParamsOnNewTrade(props, cb)),
        getCurrentPriceOnPair: (props, cb) => dispatch(getCurrentPriceOnPair(props, cb)),
        previewNewTrade: (props, cb) => dispatch(previewNewTrade(props, cb)),
        checkUserBalance: (props, cb) => dispatch(checkUserBalance(props, cb)),
        getUserBaseBalance: (props) => dispatch(getUserBaseBalance(props)),
        getUserQuoteBalance: (props, cb) => dispatch(getUserQuoteBalance(props, cb)),
        // getOrderBook: (props) => dispatch(getOrderBook(props)),
        clearErrors: () => dispatch(clearErrors()),
        clearNotEnoughBalance: () => dispatch(clearNotEnoughBalance()),
        getActiveSuspendedActivities: (cb) => dispatch(getActiveSuspendedActivities(cb))
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (NewTrade)
