import React from "react";
import {FormControlLabel, withStyles} from "@material-ui/core"
import CardMembership from '@material-ui/icons/CardMembership'
import Checkbox from "@material-ui/core/Checkbox"
import FormGroup from "@material-ui/core/FormGroup"
import { green } from '@material-ui/core/colors'

const CustomColorCheckbox = withStyles({
    root: {
        color: "#ffffff",
        '&$checked': {
            color: "#ffffff",
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);

export default function SetReceivingSubscriptions(props) {
    const {receivingSubscriptions,  handleChangeReceivingSubscriptions, receivingSubscriptionsErr,
        subscriptions, signalId} = props
    return (
    <div className={"wizardSectionContainer"}>
        <div className={"wizardTitle"}>
            <span className={"wizardTitleIcon"}><CardMembership/></span> Receiving subscriptions
            {(signalId && signalId.length > 0) && <div style={{marginLeft:"2.6rem", fontSize:"0.8rem", opacity: "0.8"}}>(cannot update after signal was sent)</div>}
        </div>
        <div style={{marginLeft: "0.6rem"}}>
            {receivingSubscriptionsErr && <div className={"errorContainer"}>{receivingSubscriptionsErr}</div>}
            <FormGroup row={true}>
                {subscriptions && subscriptions.map((elem, index) => {
                    if (receivingSubscriptions[elem._id]) {
                        return <FormControlLabel
                            control={<CustomColorCheckbox checked={true} disabled={signalId && signalId.length > 0}
                                                          onChange={handleChangeReceivingSubscriptions} value={elem._id} />}
                            label={elem.name}
                        />
                    } else {
                        return <FormControlLabel
                            control={<CustomColorCheckbox checked={false} disabled={signalId && signalId.length > 0}
                                                          onChange={handleChangeReceivingSubscriptions} value={elem._id} />}
                            label={elem.name}
                        />
                    }
                })}
            </FormGroup>
        </div>
    </div>)
}
