import React, {Component} from 'react'
import { connect } from 'react-redux'
import {Helmet} from "react-helmet/es/Helmet"
import {getTitle} from "../../fncomponents/PageTitleUtils"
import Cookies from 'universal-cookie'
import c from '../../../constants'

class RegisterViaAffiliate1 extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cookie: "unknown"
        }
    }

    componentDidMount() {
        const cookies = new Cookies()
        this.setState({
            cookie: cookies.get(c.REFERRAL_COOKIE)
        })
    }

    render() {
        return (<div>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <div className={'loginForm'} style={{marginTop: "6em", width: "20rem", textAlign: "center"}}>
                <div>Referral cookie {this.state.cookie}</div>
            </div>
        </div>)
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const mapStateToProps = rootState => ({
})

export default
connect(mapStateToProps, mapDispatchToProps) (RegisterViaAffiliate1)
