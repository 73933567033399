import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import DrawerContent from "./DrawerContent";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        backgroundColor: "#1B212F"
    },

    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        backgroundColor: "#1B212F",

    },
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: "#1B212F",
        borderRight: "0px"
    }
}));

export default function PermanentDrawer(props) {
    const {isAdmin, isSuperAdmin, isSignalsManager, isTrader, user, isAuthenticated,
        handleLogout, isMobile, location, totals,
        markets, handleChangeMarkets, handleExpandTraderPanel, traderPanelExpanded} = props
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={true}
                classes={{
                    paper: classes.drawerPaper,
                }}>
                <DrawerContent user={user}
                               isAuthenticated={isAuthenticated}
                               isAdmin={isAdmin}
                               isSuperAdmin={isSuperAdmin}
                               isSignalsManager={isSignalsManager}
                               isTrader={isTrader}
                               handleLogout={handleLogout}
                               isMobile={isMobile}
                               location={location}
                               totals={totals}
                               markets={markets}
                               handleChangeMarkets={handleChangeMarkets}
                               handleExpandTraderPanel={handleExpandTraderPanel}
                               traderPanelExpanded={traderPanelExpanded}
                />
            </Drawer>
        </div>
    );
}
