import React, { Component } from "react"
import {debounce} from "../hoc/myUtils"
import {setScrollIsOff, setScrollIsOn, getIndex, setIndex} from "../../redux/virtualScrollerState"

const setInitialState = settings => {
    const {
        itemHeight,
        amount,
        tolerance,
        minIndex,
        maxIndex,
        startIndex
    } = settings;
    const viewportHeight = amount * itemHeight;
    const totalHeight = (maxIndex - minIndex + 1) * itemHeight;
    const toleranceHeight = tolerance * itemHeight;
    const bufferHeight = viewportHeight + 2 * toleranceHeight;
    const bufferedItems = amount + 2 * tolerance;
    const itemsAbove = startIndex - tolerance - minIndex;
    const topPaddingHeight = itemsAbove * itemHeight;
    const bottomPaddingHeight = totalHeight - topPaddingHeight;
    const initialPosition = topPaddingHeight + toleranceHeight;
    return {
        settings,
        viewportHeight,
        totalHeight,
        toleranceHeight,
        bufferHeight,
        bufferedItems,
        topPaddingHeight,
        bottomPaddingHeight,
        initialPosition,
        data: [],
        timesRunScrollerRun: 0
    };
};

class VirtualScroller extends Component {
    constructor(props) {
        super(props)
        this.state = setInitialState(props.settings);
        this.viewportElement = React.createRef()
        this.scrollIsOn = this.scrollIsOn.bind(this)
        this.scrollIsOff = this.scrollIsOff.bind(this)
        this.debounceScrollIsOff = debounce(this.scrollIsOff, 1500)
    }

    scrollIsOn() {
        setScrollIsOn()
    }

    scrollIsOff() {
        setScrollIsOff()
    }

    componentDidMount() {
        if (!this.state.initialPosition) {
            this.runScroller({ target: { scrollTop: getIndex()} });
            this.viewportElement.current.scrollTop = getIndex()
        } else {
            this.viewportElement.current.scrollTop = this.state.initialPosition;
        }
    }

    runScroller = ({ target: { scrollTop } }) => {
        this.state.timesRunScrollerRun++
        //Lock trades update when user start scrolling
        this.state.timesRunScrollerRun > 2 && this.scrollIsOn()

        const {
            totalHeight,
            toleranceHeight,
            bufferedItems,
            settings: { itemHeight, minIndex }
        } = this.state;
        let index =
            minIndex + Math.floor((scrollTop - toleranceHeight) / itemHeight)
        this.state.index = index
        setIndex(scrollTop)
        const data = this.props.get(index, bufferedItems);
        const topPaddingHeight = Math.max((index - minIndex) * itemHeight, 0);
        const bottomPaddingHeight = Math.max(
            totalHeight - topPaddingHeight - data.length * itemHeight,
            0
        );

        this.setState({
            topPaddingHeight,
            bottomPaddingHeight,
            data
        })

        //Unlock trades update when user finishes scrolling
        this.state.timesRunScrollerRun > 2 && this.debounceScrollIsOff()
    };

    render() {
        const {
            viewportHeight,
            topPaddingHeight,
            bottomPaddingHeight,
            data
        } = this.state;
        return (
            <div>
                {/*<div style={{opacity: "0.5"}}>{getIndex()}</div>*/}
                <div
                    className="viewport"
                    ref={this.viewportElement}
                    onScroll={this.runScroller}
                    style={{ height: viewportHeight ? viewportHeight: 0 }}
                >
                    <div style={{ height: topPaddingHeight }} />
                    {data.map(record => this.props.rowTemplate(record))}
                    <div style={{ height: bottomPaddingHeight }} />
                </div>
            </div>

        );
    }
}

export default VirtualScroller
