import React from 'react'
import { ReactComponent as UpTrendGreen } from '../../svgs/up_trend_green.svg'
import { ReactComponent as DownTrendRed } from '../../svgs/down_trend_red.svg'
import { ReactComponent as InlineTrendWhite } from '../../svgs/inline_trend_white.svg'
import c from '../../constants'

import {
    // getQuoteAndBase,
    // getCurrentBuyAndSellPrice,
    // getCurrentPriceAndDeltaPercent,
    // getTradeState,
    // brushValue,
    timeFormatter2,
    timeFormatter,
    // getMaxMinAndDeltaPercent,
    // getProgressForWaitingTrade
} from "./UISupportFns";

import {SettingsInputAntenna, Android} from "@material-ui/icons";

export default function WaitingTrade(props) {
    const {trade, openRecord} = props
    const ACTION_TYPE = trade.action_type
    // const {quote, base} = getQuoteAndBase(trade)
    //const posSize = brushValue(trade.pos_size)
    //const {currentPrice, buyPrice, sellPrice} = getCurrentBuyAndSellPrice(trade)
    // const progress = getProgressForWaitingTrade(trade)
    // const {trend, currentPriceDeltaPercent, currentPriceClass} = getCurrentPriceAndDeltaPercent(trade)
    //const origQty = brushValue(trade.orig_qty)
    //const tradeState = getTradeState(trade)
    //const {maxPrice, maxDelta, minPrice, minDelta} = getMaxMinAndDeltaPercent(trade)
    return (
        <div className={"tradeContainer"} onClick={openRecord}>
            <div className={"tradeSubContainerLeft"}>
                <div style={{display: "flex", justifyContent: "space-between", width: "9em"}}>
                    <div>
                        <span style={{fontSize: "1.2em", fontWeight: "bold"}}>{trade.quote}</span>
                        <span style={{fontSize: "1em"}}> / {trade.base}</span>
                    </div>
                    <div style={{top: "0.8rem"}}>
                        {trade.trend === 1 && <UpTrendGreen/>}
                        {trade.trend === 0 && <InlineTrendWhite/>}
                        {trade.trend === -1 && <DownTrendRed/>}
                    </div>
                </div>
                {ACTION_TYPE === c.ACTION_TYPE_BUY && <div className={"activeTradePosAndQtyStart"} title={"What are you trading"}>
                    <small>{trade.origQty} {trade.quote}</small> <span style={{fontSize: "0.7rem"}} title={"buying with"}>/</span> <b>{trade.posSize} {trade.base}</b></div>}
                {ACTION_TYPE === c.ACTION_TYPE_SELL && <div className={"activeTradePosAndQtyStart"} title={"What are you trading"}>
                    <small>{trade.origQty} {trade.quote}</small> <span style={{fontSize: "0.7rem"}} title={"selling for"}>/</span> <b>{trade.base}</b></div>}
                <div>
                    <div className={"w3-progress-sizer"}>
                        <div className="w3-progress-container w3-round-xlarge">
                            <div className="w3-progressbar w3-round-xlarge" style={{width:`${trade.progress}%`}}/>
                        </div>
                    </div>
                    <div className={"progressBarText"}>{trade.progress}% FILLED</div>
                    {(ACTION_TYPE === c.ACTION_TYPE_BUY && trade.status === "CANCELED") && <div className={"activeTradePosAndQtyStart"}>
                        <small>({trade.executed_qty} {trade.quote} bought with {trade.cummulative_quote_qty} {trade.base})</small>
                    </div>}
                    {(ACTION_TYPE === c.ACTION_TYPE_SELL && trade.status === "CANCELED") && <div className={"activeTradePosAndQtyStart"}>
                        <small>({trade.executed_qty} {trade.quote} sold for {trade.cummulative_quote_qty} {trade.base})</small>
                    </div>}
                </div>
                {trade.from_SUC &&
                <div style={{display: "flex", justifyContent: "flex-start"}}>
                    <SettingsInputAntenna style={{color: "#64FE7C", fontSize: "42"}} />
                </div>}
                {trade.from_BOT &&
                <div style={{display: "flex", justifyContent: "flex-start"}}>
                    <Android style={{color: "#64FE7C", fontSize: "42"}} />
                </div>}
            </div>
            {(trade.tradeState === c.TRADE_STATE_PARENT_ONGOING || trade.tradeState === c.TRADE_STATE_PARENT_PARTIALLY_FILLED) &&
            <div className={"tradeSubContainerRight"}>
                <div className={"currentPriceStatsContainer"} title={"Current price"}>
                    <div className={trade.currentPriceClass}>{trade.currentPriceDeltaPercent}%</div>
                    <span className={"horizSpacer"}/>
                    <div className={trade.currentPriceClass}>{trade.currentPrice}</div>
                </div>
                {ACTION_TYPE === c.ACTION_TYPE_BUY && <div className={"activeTradePosAndQtyEnd"}><span className={"buy"}>buy</span>  price <b>{trade.buyPrice}</b></div>}
                {ACTION_TYPE === c.ACTION_TYPE_SELL && <div className={"activeTradePosAndQtyEnd"}><span className={"sell"}>sell</span>  price <b>{trade.sellPrice}</b></div>}
                {trade.maxPrice !== "0" && <div className={"maxPriceContainer"} title={"Max price while waiting"}>
                    {trade.maxPrice} ({trade.maxDelta}%)
                </div>}
                {trade.minPrice !== "0" && <div className={"minPriceContainer"} title={"Min price while waiting"}>
                    {trade.minPrice} ({trade.minDelta}%)
                </div>}
                {(trade.from_SUC && trade.trader_name) && <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <span style={{fontSize: "0.7rem", marginTop: "0.3rem"}}>from&nbsp;</span>{trade.trader_name}</div>}
            </div>}
            {(trade.tradeState === c.TRADE_STATE_PARENT_CANCELED || trade.tradeState === c.TRADE_STATE_PARENT_IN_ERROR ||
                trade.tradeState === c.TRADE_STATE_WITH_CHILD_FILLED) &&
            <div className={"tradeSubContainerRight"}>
                <div className={"currentPriceStatsContainer"} title={"Current price"}>
                    <div className={trade.currentPriceClass}>{trade.currentPriceDeltaPercent}%</div>
                    <span className={"horizSpacer"}/>
                    <div className={trade.currentPriceClass}>{trade.currentPrice}</div>
                </div>
                {trade.tradeState === c.TRADE_STATE_PARENT_CANCELED &&
                <div className={"currentPriceStatsContainer"}>
                    <span style={{color: " #E8E288"}}>Canceled</span>
                </div>
                }
                {trade.tradeState === c.TRADE_STATE_PARENT_IN_ERROR &&
                <div className={"currentPriceStatsContainer"}>
                    <span style={{color: "#FB4A4A"}}>Error</span>
                </div>
                }
                {(trade.from_SUC && trade.trader_name) && <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <span style={{fontSize: "0.7rem", marginTop: "0.3rem"}}>from&nbsp;</span>{trade.trader_name}</div>}
            </div>}

            <div style={{display:"flex", justifyContent:"center", width: "100%"}}>
                <div className={"buyPriceContainer"}>
                    {trade.status === c.ORDER_STATUS_FILLED &&
                    <div>
                        {ACTION_TYPE === c.ACTION_TYPE_BUY && <span>Filled at {trade.buyPrice}</span>}
                        {ACTION_TYPE === c.ACTION_TYPE_SELL && <span>Filled at {trade.sellPrice}</span>}
                    </div>}
                    {trade.status === c.ORDER_STATUS_CANCELED && <div>Canceled on {timeFormatter2(trade.last_update_time, true)}</div>}
                </div>
                {(trade.status === c.ORDER_STATUS_NEW || trade.status === c.ORDER_STATUS_PARTIALLY_FILLED) &&
                <div>
                    <div className={"hrState"}>
                        <span className="blink live" style={{color: "#68FF7D"}}>▶</span>
                    </div>
                </div>}
            </div>
            <div style={{display:"flex", justifyContent:"space-between", width: "100%"}} className={"recordId"}>
                <span>{trade.last_order_id}
                    {trade.from_btd && trade.from_btd !== "undefined" && (<span title={`from BTD ${trade.from_btd}`}>&nbsp;&nbsp;(<b>from a BTD</b>)</span>)}
                    {trade.from_SUC && trade.from_SUC !== "undefined" && (<span title={`from Signal ${trade.signal_id}`}>&nbsp;&nbsp;(<b>{trade.signal_id}</b>)</span>)}
                    {trade.from_BOT && trade.from_BOT !== "undefined" && (<span title={`from Bot ${trade.bot_id}`}>&nbsp;&nbsp;(<b>{trade.bot_id}</b>)</span>)}</span>
                <span>{timeFormatter(trade.time, true)}</span>
            </div>
        </div>)
}
