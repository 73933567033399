import React, {Component} from 'react'
import {connect} from "react-redux"
import withGetScreenExt from "../../../withGetScreenExt"
import {Helmet} from "react-helmet/es/Helmet";
import {getTitle} from "../../fncomponents/PageTitleUtils";
import transakSDK from '@transak/transak-sdk'
import Iframe from 'react-iframe'

class BuyCrypto extends Component {

    constructor(props) {
        super(props)
        // let transak = new transakSDK({
        //     apiKey: 'e58e1f27-b5e1-44ab-bd13-9b6fa46da131',  // Your API Key (Required)
        //     environment: 'STAGING', // STAGING/PRODUCTION (Required)
        //     defaultCryptoCurrency: 'ETH',
        //     walletAddress: '', // Your customer wallet address
        //     themeColor: '000000', // App theme color in hex
        //     email: '', // Your customer email address (Optional)
        //     redirectURL: '',
        //     hostURL: window.location.origin, // Required field
        //     widgetHeight: '800px',
        //     widgetWidth: '500px'
        // });
        // transak.init();
        //
        // // To get all the events
        // transak.on(transak.ALL_EVENTS, (data) => {
        //     console.log(data)
        // });
        //
        // // This will trigger when the user closed the widget
        // transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (orderData) => {
        //     transak.close();
        // });
        //
        // // This will trigger when the user marks payment is made.
        // transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
        //     console.log(orderData);
        //     transak.close();
        // });
    }

    render() {
        return (
            <div className={"AppMainContent"}>
                <Helmet>
                    <title>{getTitle()}</title>
                </Helmet>
                <div style={{height: "800px", width: "350px", position: "relative"}}>
                <Iframe url="https://staging-global.transak.com/?apiKey=4fcd6904-706b-4aff-bd9d-77422813bbb7&cryptoCurrencyCode=BTC&walletAddress=0xF1363D3D55d9e679cC6aa0a0496fD85BDfCF7464&fiatCurrency=EUR&partnerCustomerId=0xF1363D3D55d9e679cC6aa0a0496fD85BDfCF7464&disableWalletAddressForm=true&hideMenu=true"
                        width="350px"
                        height="800px"
                        id="myId"
                        className="myClassname"
                        display="initial"
                        position="relative"/>
                </div>
                <div style={{width: "100px", height: "20px", background: "#ffffff", border: "1px solid red", position: "absolute", right: "0px", top: "0px"}}/>
            </div>
        )
    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
})

let mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (withGetScreenExt(BuyCrypto))
