import axios from 'axios'
import s from '../../settings'
import c from '../../constants'
import {getRJWT, saveJWT, saveJWTOnAxios} from "./utils/tokenUtils";

const axiosInstance = axios.create({
    baseURL: s.API_BACKEND()
})

axiosInstance.interceptors.response.use(
    response => {
        // If the request succeeds, we don't have to do anything and just return the response
        return response
    },
    error => {
        const errorResponse = error.response
        if (isTokenExpiredError(errorResponse)) {
            // console.error("expired JWT")
            return resetTokenAndReattemptRequest(error)
        }
        // If the error is due to other reasons, we just throw it back to axios
        return Promise.reject(error)
    }
)

function isTokenExpiredError(errorResponse) {
    // Your own logic to determine if the error is due to JWT token expired returns a boolean value
    if (errorResponse &&
        errorResponse.data &&
        errorResponse.data.err &&
        errorResponse.data.err.name === "TokenExpiredError" &&
        errorResponse.data.err.message === "jwt expired")
            return true
    return false
}

let isAlreadyFetchingAccessToken = false;

// This is the list of waiting requests that will retry after the JWT refresh complete
let subscribers = [];

async function resetTokenAndReattemptRequest(error) {
    try {
        //Destructuring - assigning to new variable name (response -> errorResponse)
        const { response: errorResponse } = error;
        const refreshToken = getRJWT() // Your own mechanism to get the refresh token to refresh the JWT token

        if (!refreshToken || refreshToken === 'undefined') {
            // We can't refresh, throw the error anyway
            console.error("We can't refresh, throw the error anyway")
            return Promise.reject(c.LOCAL_JWT_ERROR_REFRESH_TOKEN_NOT_FOUND)
        }
        /* Proceed to the token refresh procedure
        We create a new Promise that will retry the request,
        clone all the request configuration from the failed
        request in the error object. */
        const retryOriginalRequest = new Promise(resolve => {
            /* We need to add the request retry to the queue
            since there another request that already attempt to
            refresh the token */
            addSubscriber((newAccessToken) => {
                errorResponse.config.headers.Authorization = newAccessToken;
                resolve(axiosInstance(errorResponse.config));
            });
        });
        if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;
            // console.log("about to call refresh token with token ", refreshToken)
            let response = await new Promise((resolve, reject) => {
                axiosInstance.post("/auth/refreshtoken",
                    {
                        token: refreshToken
                    }).then(response => {
                        // console.log("Got refresh token", JSON.stringify(response))
                        return resolve(response)
                }).catch(err => {
                    // console.error("Error getting refresh token", err)
                    return resolve({})
                })
            })
            if (!response.data) {
                // console.log("Error refreshing token")
                return Promise.reject(error);
            }
            const newAccessToken = response.data.token;
            saveJWT(newAccessToken)
            saveJWTOnAxios(newAccessToken, axiosInstance)
            isAlreadyFetchingAccessToken = false;
            onAccessTokenFetched(newAccessToken);
        } else {
            //resetTokenAndReattemptRequest fetch new access token already in progress
        }
        return retryOriginalRequest;
    } catch (err) {
        return Promise.reject(err);
    }
}

function onAccessTokenFetched(newAccessToken) {
    // When the refresh is successful, we start retrying the requests one by one and empty the queue
    subscribers.forEach(callback => callback(newAccessToken));
    subscribers = [];
}

function addSubscriber(callback) {
    subscribers.push(callback);
}

export default axiosInstance
