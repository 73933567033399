import React, {Component} from 'react'
import { connect } from 'react-redux'
import {Link as RouterLink} from "react-router-dom"
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import {signUp} from "../../redux/actions/authentication"
import {getTitle} from "../fncomponents/PageTitleUtils"
import c from '../../constants'
import s from '../../settings'
import {Helmet} from "react-helmet/es/Helmet"
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

let SignUp = ({state, handleChange, handleChangeCountry, signUp, errors, hide, group}) => {
    const classes = useStyles();
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <div className={"loginFormTitle"}>Sign Up</div>
                <br/>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            autoComplete="fname"
                            name="firstName"
                            variant="outlined"
                            required
                            fullWidth
                            autoFocus
                            id="firstName"
                            error={errors.firstName}
                            label={errors.firstName ? errors.firstName : "First Name"}
                            value={state.firstName}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="lastName"
                            name="lastName"
                            autoComplete="lname"
                            error={errors.lastName}
                            label={errors.lastName ? errors.lastName : "Last Name"}
                            value={state.lastName}
                            onChange={handleChange}
                        />
                    </Grid>
                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*    <CountriesSelect value={state.country} onChange={handleChangeCountry} errors={errors.country}/>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="email"
                            name="email"
                            autoComplete="email"
                            error={errors.email}
                            label={errors.email ? errors.email : "Email"}
                            value={state.email}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            name="password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            error={errors.password}
                            label={"Password"}
                            value={state.password}
                            onChange={handleChange}
                        />
                    </Grid>
                    {errors.password && <div style={{color: "red", padding: "1rem", margin: "0.6rem"}}>{errors.password}</div>}
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            name="passwordConfirmation"
                            type="password"
                            id="passwordConfirmation"
                            autoComplete="current-password"
                            error={errors.passwordConfirmation}
                            label={"Password confirmation"}
                            value={state.passwordConfirmation}
                            onChange={handleChange}
                        />
                    </Grid>
                    {/*{group.key === XXX && <Grid item xs={12}>*/}
                    {/*    <TextField*/}
                    {/*        variant="outlined"*/}
                    {/*        required*/}
                    {/*        fullWidth*/}
                    {/*        id="discordId"*/}
                    {/*        name="discordId"*/}
                    {/*        autoComplete="discordId"*/}
                    {/*        error={errors.discordId}*/}
                    {/*        label={errors.discordId ? errors.discordId : "Discord Id"}*/}
                    {/*        value={state.discordId}*/}
                    {/*        onChange={handleChange}*/}
                    {/*    />*/}
                    {/*</Grid>}*/}
                </Grid>
                <Button type="submit" fullWidth variant="contained" color="primary"
                    className={classes.submit} onClick={()=>{signUp()}}>Sign Up
                </Button>
                <Grid container justify="flex-end">
                    <Grid item>
                        <RouterLink to={"/login"}>
                            <Link variant="body3">
                                <span style={{opacity: "0.6"}}>Already have an account?</span> Log&nbsp;in<br/>
                            </Link>
                        </RouterLink>
                    </Grid>
                </Grid>
                <br/>
                {state.ref && <div style={{marginTop: "3rem"}}>
                    {s.mode === "prod" && <img src={"https://app.venbot.io/imgs/logo.png"} style={{display: "block", width: "60%", marginLeft: "auto", marginRight: "auto"}} />}
                    {s.mode !== "prod" && <img src={"/imgs/logo.png"} style={{display: "block", width: "60%", marginLeft: "auto", marginRight: "auto"}} />}
                        <div style={{marginTop: "1rem"}}>
                            <div className={"vbtStmt"}><span style={{color: "#3399ff"}}>✓</span> Free to use trading app</div>
                            <div className={"vbtStmt"}><span style={{color: "#3399ff"}}>✓</span>  The safest, most intuitive and powerful crypto machine</div>
                            <div className={"vbtStmt"}><span style={{color: "#3399ff"}}>✓</span>  Designed by exceptional traders</div>
                            <div className={"vbtStmt"}><span style={{color: "#3399ff"}}>✓</span>  Auto launch trades without blocking the funds</div>
                            <div className={"vbtStmt"}><span style={{color: "#3399ff"}}>✓</span>  Revolutionary concept of unique wallet</div>
                            <div className={"vbtStmt"}><span style={{color: "#3399ff"}}>✓</span>  Soon available for <b>iOs</b> and <b>Android</b></div>
                            <div style={{height: "2rem"}}/>
                            <div style={{marginTop: "1rem"}}>
                                <img src={"imgs/exchanges/binance_rs.png"} style={{marginLeft: "5rem"}}/>
                                <div style={{display: "flex", marginLeft: "3.3rem"}}>
                                    <img src={"imgs/exchanges/crypto_dot_com_rs.png"}/>
                                    <img src={"imgs/exchanges/kraken_rs.png"}/>
                                </div>
                            </div>
                            <div style={{height: "1rem"}}/>
                            <div className={"vbtStmt"}>Runs on <b>Binance</b> and soon to integrate <b>Crypto.com</b> and <b>Kraken</b>.</div>
                            <div style={{height: "3rem"}}/>
                        </div>
                </div>}
            </div>
        </Container>
    );
}

class Register extends Component {

    constructor(props) {
        super(props)
        this.state = {
            firstName: "",
            lastName: "",
            countryIso2: "AF",
            email: "",
            password: "",
            discordId: "",
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeCountry = this.handleChangeCountry.bind(this)
        this.signUp = this.signUp.bind(this)
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleChangeCountry(e) {
        this.setState({
            countryIso2: e.target.value
        })
    }

    signUp() {

        this.props.signUp({
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            countryIso2: this.state.countryIso2,
            email: this.state.email,
            password: this.state.password,
            passwordConfirmation: this.state.passwordConfirmation,
            discordId: this.state.discordId,
            domain: window.location.hostname,
            ref: cookies.get(c.REFERRAL_COOKIE)
        })
    }

    render() {
        return (<div>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            {this.props.signupSuccess &&
            <div className={'loginForm'} style={{marginTop: "6em", width: "20rem", textAlign: "center"}}>
                <div>Thank you for registering!</div>
                <div>A confirmation email has been sent. Please confirm your email.</div>
                <div style={{marginTop: "0.5rem"}}>
                    <Button onClick={()=> {this.props.history.push("/login")}} fullWidth variant="contained" color="primary">Login</Button>
                </div>
            </div>}
            {!this.props.signupSuccess && <SignUp state={this.state} handleChange={this.handleChange}
                                                  signUp={this.signUp} handleChangeCountry={this.handleChangeCountry}
                                                  errors={this.props.errors}/>}
        </div>)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        signUp: (props) => dispatch(signUp(props))
    }
}

const mapStateToProps = rootState => ({
    signupSuccess: rootState.authentication.signupSuccess,
    errors: rootState.errors
})

export default
connect(mapStateToProps, mapDispatchToProps) (Register)
