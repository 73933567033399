import React, {Component} from 'react'
import {connect} from 'react-redux'
import {confirmSignup} from '../../redux/actions/authentication'
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import {getTitle} from "../fncomponents/PageTitleUtils";
import {Helmet} from "react-helmet/es/Helmet";

const qs = require("query-string")


class ConfirmSignup extends Component {

    componentDidMount() {
        let token = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).token
        this.props.confirmSignup(token)
    }

    render() {
        return (
            <div className={'loginForm'} style={{marginTop: "6em", width: "20rem", textAlign: "center"}}>
                <h3>Confirm Email</h3>
                <Helmet>
                    <title>{getTitle()}</title>
                </Helmet>
            {this.props.signupConfirmSuccess && <div>
                <div>Your account has been confirmed.</div>
                <RouterLink to={"/login"}>
                    <Link variant="body4">
                        You can now login.
                    </Link>
                </RouterLink>
            </div>}
            {Object.keys(this.props.errors).length > 0 && <div>
                <div>We apologise, but token seems missing or expired...</div>
            </div>}
            </div>)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        confirmSignup: (token) => dispatch(confirmSignup(token))
    }
}

const mapStateToProps = rootState => ({
    signupConfirmSuccess: rootState.authentication.signupConfirmSuccess,
    errors: rootState.errors
})
export default connect(mapStateToProps, mapDispatchToProps) (ConfirmSignup)
