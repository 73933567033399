import React, {Component} from 'react'
import {connect} from "react-redux"
import ReferredUsersTable from "./ReferredUsersTable"
import {debounce} from '../../hoc/myUtils'
import {getReferredUsersInitial, getReferredUsersByPage} from '../../../redux/actions/users'
import {getTitle} from "../../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"
import Button from "@material-ui/core/Button"
import {ChevronLeft} from "@material-ui/icons"

class ReferredUsers extends Component {

    constructor(props) {
        super(props)
        this.state = {
            rows: [],
            page: 0,
            rowsPerPage: 10,
            filtersMap: {},
            sort: {},
        }

        this.setRowsPerPage = this.setRowsPerPage.bind(this)
        this.setPage = this.setPage.bind(this)
        this.onFilterChange = this.onFilterChange.bind(this)
        this.onFilter = this.onFilter.bind(this)
        this.debouncedFilter = debounce(this.onFilter, 500)

    }

    setRowsPerPage (value) {
        this.state.rowsPerPage = value
        this.state.page = 0
        this.props.getReferredUsersInitial({filters: this.state.filtersMap,
            sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
    }

    setPage (value) {
        let page = value
        if (!this.props.pagesDownloaded[page+1]) {
            this.state.page = page
            this.props.getReferredUsersByPage({filters: this.state.filtersMap,
                sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
        } else {
            this.setState({
                page: page
            })
        }
    }

    onFilterChange(e) {
        let filterEntry = {[e.target.name]: e.target.value}
        this.setState({
            filtersMap: {...this.state.filtersMap, ...filterEntry}
        })
        this.debouncedFilter()
    }

    onFilter() {
        this.props.getReferredUsersInitial({filters: this.state.filtersMap,
            sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
    }

    componentDidMount() {
        this.props.getReferredUsersInitial({filters: this.state.filtersMap,
            sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
    }

    render() {
        let {users, totalDocs} = this.props

        return (
            <div className={"AppMainContent"}>
                <div className={"vertSpacer2"}/>
                <Helmet>
                    <title>{getTitle()}</title>
                </Helmet>
                <Button onClick={()=>{this.props.history.push("/myprofile")}}><ChevronLeft/> Your Profile</Button>
                <ReferredUsersTable
                    rows={users}
                    rowsPerPage={this.state.rowsPerPage}
                    setRowsPerPage={this.setRowsPerPage}
                    page={this.state.page}
                    setPage={this.setPage}
                    totalDocs={totalDocs ? totalDocs : 0}
                    filtersMap={this.state.filtersMap}
                    onFilterChange={this.onFilterChange}
                />
            </div>
        )
    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    users: rootState.users.docs,
    totalDocs: rootState.users.totalDocs,
    totalPages: rootState.users.totalPages,
    pagesDownloaded: rootState.users.pagesDownloaded,
    totals: rootState.users.totals,
})

let mapDispatchToProps = dispatch => {
    return {
        getReferredUsersInitial: (props) => dispatch(getReferredUsersInitial(props)),
        getReferredUsersByPage: (props) => dispatch(getReferredUsersByPage(props)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ReferredUsers)
