import React from 'react'
import {FormControlLabel, TextField, withStyles, Radio, RadioGroup, FormControl} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import InputAdornment from "@material-ui/core/InputAdornment"
import Checkbox from "@material-ui/core/Checkbox"
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";


const useStyles = makeStyles(theme => ({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
            backgroundColor: '#101A26'
        },
        formControl: {
            margin: theme.spacing(3),
        },
        dense: {
            marginTop: theme.spacing(2),
        },
        fab: {
            // margin: theme.spacing(1),
            marginLeft: theme.spacing(0.6),
            backgroundColor: '#101A26',
            border: "1px solid #ffffff",
            color: "#ffffff"
        },
        fabSelected: {
            // margin: theme.spacing(),
            marginLeft: theme.spacing(0.6),
            backgroundColor: '#ffffff',
            border: "1px solid #000000",
            color: "#000000"
        }}

))

const CustomColorCheckbox = withStyles({
    root: {
        color: "#ffffff",
        '&$checked': {
            color: "#ffffff",
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />)


export default function UserFuturesSignalSettingsForm(props) {
    const classes = useStyles()
    let {
        coin,
        acceptSUCs, handleChangeAcceptSUCs,
        autoStartTradeFromSUC, handleChangeAutoStartTradeFromSUC,
        sucPosSize, handleChangeSUCPosSize,
        sucMinPosSize, sucMaxPosSize,
        sucPosSizeType, handleChangeSUCPosSizeType,
        takeProfitTarget1, handleChangeTakeProfitTarget1,
        takeProfitTarget2, handleChangeTakeProfitTarget2,
        maximumRiskCapital, handleChangeMaximumRiskCapital,
        riskRewardRatio, handleChangeRiskRewardRatio,
        calculatedPositionSize,
        snackIsOpen,
        handleSave
    } = props

    return <div className={"wizardSectionContainer"}>
        <div style={{marginTop: "1.6rem", fontSize: "1.2rem"}}>Settings for {coin} <small><b>Futures</b></small> signals</div>
        <FormControl component="fieldset" id={`form-control-${Math.random()}`}>
            <div style={{marginLeft:"-2px", width: "20.3rem"}}>
            <div id={`acceptSUCs-${Math.random()}`} style={{display: "flex", marginTop: "1em",
                justifyContent: "space-between", flexWrap: "wrap", maxWidth: "20.3rem"}}>
                {acceptSUCs && <div>
                    <FormControlLabel
                        id={`accept-signals-off-${Math.random()}`}
                        control={<CustomColorCheckbox checked={true}
                                                      onChange={handleChangeAcceptSUCs}/>}
                        label={"Accept Signals"}
                    />
                    <div className={"smallExplanations"} style={{marginLeft: "1.8rem", marginTop:"-0.4rem"}}>You will receive Futures {coin} signals.</div>
                </div>
                }
                {!acceptSUCs && <div>
                    <FormControlLabel
                        id={`accept-signals-on-${Math.random()}`}
                        control={<CustomColorCheckbox checked={false}
                                                      onChange={handleChangeAcceptSUCs}/>}
                        label={"Accept Signals"}
                    />
                    <div className={"smallExplanations"} style={{marginLeft: "1.8rem", marginTop:"-0.4rem"}}>You won't receive Futures {coin} signals.</div>
                </div>
                }
            </div>
            <div id={`autoStartTradeFromSUC-${Math.random()}`} style={{display: "flex", marginTop: "1em",
                justifyContent: "space-between", flexWrap: "wrap", maxWidth: "20.3rem"}}>
                {autoStartTradeFromSUC && <div>
                    <FormControlLabel
                        id={`auto-start-trade-from-suc-off-${Math.random()}`}
                        control={<CustomColorCheckbox checked={true}
                                                      onChange={handleChangeAutoStartTradeFromSUC}/>}
                        label={"Auto start Trades from Signals"}
                    />
                    <div className={"smallExplanations"} style={{marginLeft: "1.8rem", marginTop:"-0.4rem"}}>When a futures {coin} signal reaches the entry point a new <b>futures trade</b> will be started automatically for you.</div>
                </div>
                }
                {!autoStartTradeFromSUC && <div>
                    <FormControlLabel
                        id={`auto-start-trade-from-suc-on-${Math.random()}`}
                        control={<CustomColorCheckbox checked={false}
                                                      onChange={handleChangeAutoStartTradeFromSUC}/>}
                        label={"Auto start Trades from Signals"}
                    />
                    <div className={"smallExplanations"} style={{marginLeft: "1.8rem", marginTop:"-0.4rem"}}>The system won't react any how when the futures {coin} signal reaches the entry point.</div>
                </div>
                }
            </div>
            <div style={{marginTop: "2rem"}}>How much will you put in each trade</div>
            <RadioGroup id={`radio-group-pos-size-types-${Math.random()}`} aria-label="position" name="posSizeType" value={sucPosSizeType} onChange={handleChangeSUCPosSizeType} row>
                <div id={`posSizeAndPosSizeType-${Math.random()}`} style={{display: "flex", marginTop: "0.2em",
                    justifyContent: "space-between", flexWrap: "wrap", maxWidth: "20.3rem"}}>
                        <div>
                            <FormControlLabel
                                value={1}
                                id={`fixed-pos-size-1-${Math.random()}`}
                                checked={sucPosSizeType == 1}
                                control={<Radio color="primary" />}
                                label={<span style={{fontSize:"0.8rem", opacity: "0.8"}}>Fixed position size</span>}
                                labelPlacement="end"
                            />
                            <div style={{border: (sucPosSizeType == 1 ? "1px solid white": ""), borderRadius: "6px", paddingTop: "1rem", paddingBottom: "1rem"}}>
                                <div style={{marginLeft: "20px", fontSize:"0.8rem", opacity: "0.8"}}>Position size</div>
                                <TextField
                                    className={classes.textField}
                                    variant={"outlined"}
                                    margin={"dense"}
                                    disabled={sucPosSizeType != 1}
                                    id={`suc-pos-size-dynamic-1-${Math.random()}`}
                                    value={sucPosSize}
                                    onChange={handleChangeSUCPosSize}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{coin}</InputAdornment>
                                    }}
                                    inputProps={{ maxLength: 16 }}
                                />
                                <div className={"smallExplanations"} style={{marginLeft: "20px", width: "18rem"}}>
                                    The amount of {coin} that you put in each Trade started from a {coin} Signal.<br/>
                                    min. <b>{sucMinPosSize}</b> {coin}
                                </div>
                            </div>
                        </div>
                </div>
            </RadioGroup>

            <div id={`takeProfitTarget#1-${Math.random()}`} style={{display: "flex", marginTop: "1em",
                justifyContent: "space-between", flexWrap: "wrap", maxWidth: "20.3rem"}}>
                <div>
                    <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8"}}>Take profit target #1</div>
                    <TextField
                        className={classes.textField}
                        variant={"outlined"}
                        margin={"dense"}
                        id={`take-profit-target-1-${Math.random()}`}
                        value={takeProfitTarget1}
                        onChange={handleChangeTakeProfitTarget1}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                        inputProps={{ maxLength: 16 }}
                    />
                </div>
            </div>
            <div className={"smallExplanations"} style={{marginLeft: "1.8rem"}}>What percentage will you execute (sell/buy) when the Signal reaches the #1 take profit target.</div>
            <div id={`takeProfitTarget#2-${Math.random()}`} style={{display: "flex", marginTop: "1em",
                justifyContent: "space-between", flexWrap: "wrap", maxWidth: "20.3rem"}}>
                <div>
                    <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8"}}>Take profit target #2</div>
                    <TextField
                        className={classes.textField}
                        variant={"outlined"}
                        margin={"dense"}
                        id={`take-profit-target-2-${Math.random()}`}
                        value={takeProfitTarget2}
                        onChange={handleChangeTakeProfitTarget2}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                        inputProps={{ maxLength: 16 }}
                    />
                </div>
            </div>
            <div className={"smallExplanations"} style={{marginLeft: "1.8rem"}}>What percentage will you execute (sell/buy) when the Signal reaches the #2 take profit target.</div>
            <div className={"smallExplanations"} style={{marginLeft: "1.8rem"}}>The sum of Take profit target #1 and #2 must be 100%.</div>
            <br/>
            <div style={{marginLeft: "0.6rem", marginBottom: "1rem"}}>
                {snackIsOpen && <div style={{marginBottom: "1rem"}}>
                    <Paper style={{padding: "0.6rem"}}>
                    <Typography>{`${coin} Futures signal settings saved`}</Typography>
                </Paper>
                </div>}
                <Button variant="contained" color="primary" size="small" onClick={handleSave}>
                    Save
                </Button>
            </div>

        </div>
        </FormControl>
    </div>
}
