import React, {Component} from 'react'
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import {connect} from "react-redux"
import {resetPassword} from "../../redux/actions/authentication"
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {Link as RouterLink} from "react-router-dom";
import Link from "@material-ui/core/Link";
import {Helmet} from "react-helmet/es/Helmet";
import {getTitle} from "../fncomponents/PageTitleUtils";
import {getName} from "../../redux/actions/wha";

class ResetPassword extends Component {

    constructor(props) {
        super(props)
        this.state = {
            email: "",
            emailProviderUrl: "",
            step: 1
        }
        this.handleChange = this.handleChange.bind(this)
        this.resetPassword = this.resetPassword.bind(this)
    }

    handleChange(e) {
        this.setState({
            email: e.target.value
        })
    }

    resetPassword() {
        this.props.resetPassword(this.state.email, (emailProviderUrl)=> {this.setState({
            step: 2,
            emailProviderUrl: emailProviderUrl
        })})
    }

    componentDidMount() {
        this.props.getName()
    }

    render() {
        let localCustomStyle = {width: "16em"}
        let {errors} = this.props
        errors = errors ? errors: {}

        return (<div className={'loginForm'} style={{marginTop: "6em"}}>
            <div>
                <div className={"loginFormTitle"}>Reset Password</div>
                <div className={"vertSpacer"}/>
            </div>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <Paper style={{padding: "1em", width: "16em"}}>
                {this.state.step === 1 &&
                <div>
                    <TextField
                        id={"email"}
                        variant={"outlined"}
                        autoFocus
                        name={"email"}
                        label={"Email"}
                        placeholder={"Email"}
                        style={localCustomStyle}
                        onChange = {this.handleChange}
                        value = {this.state.email}
                        error={errors && errors["email"]}
                        helperText={errors["email"]}
                        autoComplete={"false"}
                    />
                    <br/><br/>
                    <Button variant="contained"  color="primary" fullWidth label="LogIn"
                            onClick={() => this.resetPassword()}>Submit</Button>
                </div>}
                {this.state.step === 2 &&
                    <div>
                        <div className={"stepperStepTitle2"}>Email confirmation required</div>
                        <div className={"stepperStepSubtitle2"}>
                            We've sent you an email detailing the next steps for resetting your password. Please follow the instructions in the email.
                        </div>
                        <div className={"vertSpacer"}/>
                        <Button variant="contained"  color="primary" fullWidth label="LogIn"
                                onClick={() => {window.open(this.state.emailProviderUrl, '_blank')}}>Go to email and verify</Button>
                        <div className={"vertSpacer"}/>
                        <div className={"stepperStepSubtitle2"}>If you haven't received the email, please try the following</div>
                        <div className={"stepperStepSubtitle2"} style={{opacity: "0.8"}}>
                            <ul>
                                <li>Make sure the email address you provided is correct.</li>
                                <li>Check your Spam or Junk mail folders.</li>
                                <li>Add {this.props.name} to your email address whitelist.</li>
                                <li>Make sure your email is functioning normally.</li>
                            </ul>
                        </div>
                    </div>
                }
                <br/>
                <Grid container justify="flex-end">
                    <Grid item>
                        <RouterLink to={"/login"}>
                            <Link variant="body3" style={{fontSize: "0.86rem"}}>
                                <span style={{opacity: "0.6"}}>Back to</span> Log&nbsp;in<br/>
                            </Link>
                        </RouterLink>
                    </Grid>
                </Grid>

                </Paper>
        </div>)

    }
}

const mapStateToProps = rootState => ({
    errors: rootState.errors.resetPasswordError,
    name: rootState.wha.name
})

const mapDispatchToProps =  dispatch => {
    return {
        resetPassword: (email, cb) => {dispatch(resetPassword(email, cb))},
        getName: () => {dispatch(getName())}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
