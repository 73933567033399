import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import {connect} from "react-redux"
import KeyboardBackArrow from '@material-ui/icons/KeyboardBackspace'
import Warning from '@material-ui/icons/Warning'
import c from '../../constants'
import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import {launchNewExchange, launchNewTrade} from "../../redux/actions/newTrade"
import {brushValue} from "../fncomponents/UISupportFns"
import {getTitle} from "../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"

const useStyles = makeStyles(theme => ({
    fab: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

function getFixedPriceWarning(actionType, actionTypeOption, fixedPrice, currentPrice) {
    if (actionTypeOption === c.ACTION_TYPE_OPTION_FIXED_PRICE) {
        let fixedPriceF = parseFloat(fixedPrice)
        let currentPriceF = parseFloat(currentPrice)
        let delta = (fixedPriceF - currentPriceF) / currentPriceF
        if (actionType === c.ACTION_TYPE_SELL)
            delta *= -1
        if (delta >= 0.1) {
            if (actionType === c.ACTION_TYPE_BUY) {
                return <div className={"warning"}>
                    <Warning/> buy price is current price +{(delta * 100).toFixed(2)}%
                </div>
            } else if (actionType === c.ACTION_TYPE_SELL) {
                return <div className={"warning"}>
                    <Warning/> sell price is current price -{(delta * 100).toFixed(2)}%
                </div>
            }
        }
    }
}

function LaunchExchangeButton(props) {
    const classes = useStyles()
    const {actionType, onClick} = props

    function getLabel() {
        if (actionType === c.ACTION_TYPE_BUY)
            return "CONVERT"
        else if (actionType === c.ACTION_TYPE_SELL)
            return "CONVERT"
    }

    return (
        <div>
            <Fab variant="extended" aria-label="Delete" className={classes.fab} onClick={onClick}>
                {getLabel()}
            </Fab>
        </div>
    )
}

class PreviewConversion extends Component {

    constructor(props) {
        super(props)
        this.state = {
            exchangeLaunched: this.props.exchangeLaunched
        }

        this.getFirstParagraph = this.getFirstParagraph.bind(this)

        this.didNewExchangeLaunched = this.didNewExchangeLaunched.bind(this)
    }

    getFirstParagraph() {
        let {actionType, tradeType, actionTypeOption,
            base, quote, posSize, amount, userQuoteBalance, fixedPrice, currentPrice} = this.props
        fixedPrice = brushValue(fixedPrice)
        let firstPart = ''
        let secondPart = []

        if (actionType === c.ACTION_TYPE_BUY) {
            firstPart = <div>New order for <u>buying</u> <span className={"newTPHBold"}>{amount}</span> {quote} <span style={{fontSize: "0.8rem"}}>(with {posSize} {base})</span></div>
            if (actionTypeOption === c.ACTION_TYPE_OPTION_MARKET) {
                secondPart.push(<div>at <span className={"newTPHBold"}>market price</span>.</div>)
            } else if (actionTypeOption === c.ACTION_TYPE_OPTION_FIXED_PRICE) {
                secondPart.push(<div>at a <span className={"newTPHBold"}>fixed price</span> of <span className={"newTPHBold"}>{fixedPrice}</span>.</div>)
                secondPart.push(getFixedPriceWarning(actionType, actionTypeOption, fixedPrice, currentPrice))
            } else if (actionTypeOption === c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE) {
                secondPart.push(<div>at <span className={"newTPHBold"}>just above the first bid ({fixedPrice})</span>.</div>)
            }
        } else if (actionType === c.ACTION_TYPE_SELL) {
            firstPart = <div>New order for <u>selling</u> <span className={"newTPHBold"}>{amount}</span> {quote}</div>
            if (actionTypeOption === c.ACTION_TYPE_OPTION_MARKET) {
                secondPart.push(<div>at <span className={"newTPHBold"}>market price</span>.</div>)
            } else if (actionTypeOption === c.ACTION_TYPE_OPTION_FIXED_PRICE) {
                secondPart.push(<div>at a <span className={"newTPHBold"}>fixed price</span> of <span className={"newTPHBold"}>{fixedPrice}</span>.</div>)
                secondPart.push(getFixedPriceWarning(actionType, actionTypeOption, fixedPrice, currentPrice))
            } else if (actionTypeOption === c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE) {
                secondPart.push(<div><span className={"newTPHBold"}>below the first ask ({fixedPrice})</span>.</div>)
            }
        }

        return <div>{firstPart} {secondPart}</div>
    }

    launchNewExchange() {
        this.props.launchNewExchange()
    }

    didNewExchangeLaunched() {
        return this.state.exchangeLaunched !== this.props.exchangeLaunched
    }

    render() {
        if (this.didNewExchangeLaunched() || !this.props.posSize)
            return <Redirect to="/"/>

        let error = this.props.error
        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <div className={"vertSpacer1"}/>
            <div style={{display: "flex", justifyContent: "flex-start", width: "20em", cursor: "pointer"}} onClick={()=>{this.props.history.push("/converter")}} >
                <KeyboardBackArrow/>
                <div className={"pageTitle"} style={{marginLeft: "1rem"}}>Back</div>
            </div>
            <div className={"vertSpacer2"}/>
            <div className={"newTradePreviewHighlight"}>
                {this.getFirstParagraph()}
            </div>
            <br/>
            <div className={"vertSpacer2"}/>
            {error &&
            <div>
                <div >
                    <div className={"errorIntroduction"}>We apologise, but we could not start this exchange for you.</div>
                    <div className={"errorContainer"}>{error}</div>
                </div>
                <div className={"vertSpacer3"}/>
            </div>}
            <div>
                <LaunchExchangeButton onClick={()=>{this.launchNewExchange()}} actionType={this.props.actionType}/>
            </div>
            <div className={"vertSpacer1_5"}/>
        </div>
    }
}


let mapStateToProps = rootState => ({
    base: rootState.newTrade.newExchange.base,
    quote: rootState.newTrade.newExchange.quote,
    tradeType: rootState.newTrade.newExchange.tradeType,
    actionType: rootState.newTrade.newExchange.actionType,
    posSize: rootState.newTrade.newExchange.posSize,
    amount: rootState.newTrade.newExchange.amount,
    userQuoteBalance: rootState.newTrade.newExchange.userQuoteBalance,
    fixedPrice: rootState.newTrade.newExchange.fixedPrice,
    currentPrice: rootState.newTrade.newExchange.currentPrice,
    actionTypeOption: rootState.newTrade.newExchange.actionTypeOption,
    exchangeLaunched: rootState.newTrade.newExchange.exchangeLaunched,
    error: rootState.errors.launchNewExchangeError
})

let mapDispatchToProps = dispatch => {
    return {
        launchNewExchange: () => dispatch(launchNewExchange())
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (PreviewConversion)
