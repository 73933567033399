import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import { connect } from 'react-redux'
import {Helmet} from "react-helmet/es/Helmet";
import {getTitle} from "../fncomponents/PageTitleUtils";
import {Notifications} from "@material-ui/icons";
import UserNotificationsSettings from "../fncomponents/UserNotificationsSettings";
import {
    getUserNotificationSettings,
    saveUserNotificationsSettings,
    saveUserNotificationsSettings2
} from "../../redux/actions/profile";
const qs = require("query-string")

class Unsubscribe extends Component {

    constructor(props) {
        super(props)
        this.state = {
            invalid: true,
            notificationsSettings: {
                onNewSignal: false,
                onTradeClosed: false,
            },
        }

        this.handleChangeOnNewSignalNotif = this.handleChangeOnNewSignalNotif.bind(this)
        this.handleChangeOnTradeClosedNotif = this.handleChangeOnTradeClosedNotif.bind(this)
    }

    handleChangeOnNewSignalNotif() {
        this.setState({
            notificationsSettings: {
                ...this.state.notificationsSettings,
                ...{onNewSignal: !this.state.notificationsSettings.onNewSignal}
            }
        })
        this.props.saveUserNotificationsSettings2({
            userDbId: this.state.userDbId,
            unsubscribeToken: this.state.unsubscribeToken,
            onNewSignal: !this.state.notificationsSettings.onNewSignal,
            onTradeClosed: this.state.notificationsSettings.onTradeClosed
        })

    }

    handleChangeOnTradeClosedNotif() {
        this.setState({
            notificationsSettings: {
                ...this.state.notificationsSettings,
                ...{onTradeClosed: !this.state.notificationsSettings.onTradeClosed}
            }
        })
        this.props.saveUserNotificationsSettings2({
            userDbId: this.state.userDbId,
            unsubscribeToken: this.state.unsubscribeToken,
            onNewSignal: this.state.notificationsSettings.onNewSignal,
            onTradeClosed: !this.state.notificationsSettings.onTradeClosed
        })
    }

    componentDidMount() {
        let unsubscribeToken = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).q
        let userDbId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).w
        if (unsubscribeToken && userDbId) {
            this.props.getUserNotificationSettings({userDbId, unsubscribeToken}, data => {
                if (data.notificationsSettings) {
                    this.setState({
                        invalid: false,
                        userDbId,
                        unsubscribeToken,
                        notificationsSettings: data.notificationsSettings
                    })
                }

            })
        }

    }

    render() {
        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            {this.state.invalid && <div>Invalid data</div>}
            {!this.state.invalid && <div>
                <div className={"profileControlContainer"}>
                    <div style={{display: "flex"}}>
                        <div className={"normalIconContainer"}>
                            <Notifications/>
                        </div>
                        <div>
                            <b>Your email subscriptions</b>
                        </div>
                    </div>
                </div>
                {this.state.notificationsSettings && <UserNotificationsSettings
                    onNewSignalNotif={this.state.notificationsSettings.onNewSignal}
                    onTradeClosedNotif={this.state.notificationsSettings.onTradeClosed}
                    handleChangeOnNewSignalNotif={ this.handleChangeOnNewSignalNotif}
                    handleChangeOnTradeClosedNotif={this.handleChangeOnTradeClosedNotif}
                />}
            </div>}
        </div>
    }
}

const mapStateToProps = rootState => ({
})

const mapDispatchToProps =  dispatch => {
    return {
        getUserNotificationSettings: (props, cb) => dispatch(getUserNotificationSettings(props, cb)),
        saveUserNotificationsSettings2: (props) => {dispatch(saveUserNotificationsSettings2(props))},
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Unsubscribe)
