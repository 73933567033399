import React from 'react'
import PropTypes from 'prop-types'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import TableHead from "@material-ui/core/TableHead"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import c from "../../../constants"
import SignalBox from "../../fncomponents/SignalBox"

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, columns } = props
    const createSortHandler = property => event => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {columns.map(column => (
                    <TableCell
                        key={column.id}
                        align={column.numeric ? 'right' : 'left'}
                        padding={'default'}
                        sortDirection={orderBy === column.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === column.id}
                            direction={order}
                            onClick={createSortHandler(column.id)}
                        >
                            {column.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
}
const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5),
    },
}))

function TablePaginationActions(props) {
    const classes = useStyles1()
    const theme = useTheme()
    const { count, page, rowsPerPage, onChangePage } = props

    function handleFirstPageButtonClick(event) {
        onChangePage(event, 0)
    }

    function handleBackButtonClick(event) {
        onChangePage(event, page - 1)
    }

    function handleNextButtonClick(event) {
        onChangePage(event, page + 1)
    }

    function handleLastPageButtonClick(event) {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    )
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
}))

export default function SignalsEnhancedTableV1(props) {
    const {columns, rows, rowsPerPage, setRowsPerPage, page, setPage, totalDocs,
        filtersMap, onFilterChange, subscriptionsHash, editSignal, closeSignal, isSuperAdmin, tab} = props
    const classes = useStyles()
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('value')
    const signalActionOrFuturesType = {
        [c.ACTION_TYPE_BUY]: 'Starts with a Buy',
        [c.ACTION_TYPE_SELL]: 'Starts with a Sell',
        [c.FUTURES_TYPE_LONG]: 'Long',
        [c.FUTURES_TYPE_SHORT]: 'Short'}


    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc'
        setOrder(isDesc ? 'asc' : 'desc')
        setOrderBy(property)
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalDocs - page * rowsPerPage)

    function handleChangePage(event, newPage) {
        setPage(newPage)
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}>
                    <Table
                        className={classes.table}
                        aria-label={"simple table"}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            columns={columns}
                        />
                        <TableBody>
                            {(rows && rows.length > 0) && rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
                                let stopTargetTitle = row.stopLossTarget ? (row.stopLossTarget.reached ? "Reached": "") : ""
                                let takeProfitTargetsReached = 0
                                for(let i =0; i < row.takeProfitTargets.length; i++) {
                                    if (row.takeProfitTargets[i].reached)
                                        takeProfitTargetsReached++
                                }
                                let autoClosed = false
                                if (takeProfitTargetsReached === row.takeProfitTargets.length || (row.stopLossTarget && row.stopLossTarget.reached))
                                    autoClosed = true

                                return (<TableRow tabIndex={0} key={`row_${row._id}`}>
                                    <TableCell component="th" scope="row" key={`row_${row._id}_signal`}>
                                        <SignalBox signal={row}
                                                   autoClosed={autoClosed}
                                                   signalActionOrFuturesType={signalActionOrFuturesType}
                                                   stopTargetTitle={stopTargetTitle}
                                                   newV={true}
                                                   isSuperAdmin={isSuperAdmin}
                                        />
                                    </TableCell>
                                    <TableCell key={`row_${row._id}_receivingGroups`}>
                                        {row.receivingSubscriptions.map((elem, index) => {
                                            return <div key={`receivingGroups_${Math.random()}`}>{(subscriptionsHash && subscriptionsHash[elem]) && subscriptionsHash[elem].name}</div>
                                        })}
                                    </TableCell>
                                    <TableCell align="left" key={`row_${row._id}_actions`}>
                                       <div style={{display: "flex", justifyContent: "space-between", width: "5rem"}}>
                                           {editSignal && <div className={"hrefButton"} style={{verticalAlign: "middle", height: "1rem", position: "relative", textAlign: "left"}}>
                                                <span onClick={()=>{editSignal(row._id, row.signalType)}} style={{marginLeft: "0.8rem"}}>Edit</span>&nbsp;&nbsp;
                                            </div>}
                                           {closeSignal && <div className={"hrefButton"} style={{verticalAlign: "middle", height: "1rem", position: "relative", textAlign: "left"}}>
                                                <span onClick={()=>{closeSignal(row._id, tab)}} style={{marginLeft: "0.8rem"}}>Close</span>
                                            </div>}
                                       </div>
                                    </TableCell>
                                </TableRow>)
                            })
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    colSpan={6}
                                    count={totalDocs}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </div>
            </Paper>
        </div>
    )
}
