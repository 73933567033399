import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet/es/Helmet";

const WHAConfirmUrlText = () => {
  const [text, setText] = useState("");

  useEffect(() => {
    // Replace 'your-server-url' with the actual URL of your backend server
    fetch("/api/wha/getconfirmationurl")
      .then(response => response.text())
      .then(data => {
        console.log("data", data);
        setText(data);
      })
      .catch(error => setText("Error"));
  }, []);

  return <span>{text}</span>;
};

export default WHAConfirmUrlText;



