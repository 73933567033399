import React, {Component} from 'react'
import StatsComponent from "../../fncomponents/StatsComponent";
import HistoryFilter from "../../fncomponents/HistoryFilter";
import {Paper} from "@material-ui/core";
import CloudDownload from "@material-ui/icons/CloudDownload"
import CompletedTrade from "../../fncomponents/CompletedTrade";
import CompletedOrder from "../../fncomponents/CompletedOrder";
import {openCompletedTrade} from "../../../redux/actions/trades";
import {connect} from "react-redux";

class TradesHistory extends Component {

    constructor(props) {
        super(props)
        this.state = {}
        this.openCompletedTrade = this.openCompletedTrade.bind(this)
    }

    openCompletedTrade(completedTrade) {
        this.props.openCompletedTrade(completedTrade.original_trade)
        this.props.history.push("/completedtradedetails")
    }


    render() {

        let gClass = this.props.selectedStats === "global" ? "selectedStats" : "normalStats"
        let mClass = this.props.selectedStats === "monthly" ? "selectedStats" : "normalStats"
        let dClass = this.props.selectedStats === "daily" ? "selectedStats" : "normalStats"

        const {stats, selectedStats, historyData, historyFilter, historyFilterSelectedYear, historyFilterSelectedMonth} = this.props
        let statsByCoins = undefined
        if (stats) {
            let attrs = Object.keys(stats)
            statsByCoins = []
            for(let i = 0; i < attrs.length; i++) {
                if (stats[attrs[i]].global) {
                    statsByCoins.push({coin: attrs[i].toUpperCase(), stats: stats[attrs[i]]})
                }
            }
        }

        return (
            <div>
                <div className={"vertSpacer"}/>
                {historyData &&
                <div>
                    {stats &&
                    <div>
                        <HistoryFilter historyFilter={historyFilter}
                                       selectedYear={historyFilterSelectedYear}
                                       selectedMonth={historyFilterSelectedMonth}
                                       changeFilter={this.props.changeHistoryFilter}
                        />
                        <div className={"vertSpacer"}/>
                        <Paper className={"statsContainer"}>
                            <div key="3434343" style={{paddingTop: "0.8rem", marginLeft: "-0.5rem"}}>
                                <div key="g343" title={"Global stats"} className={gClass} onClick={() => {this.props.changeSelectedStats("global")}}>G</div>
                                <div key="m303" title={"Monthly stats"} className={mClass} onClick={() => {this.props.changeSelectedStats("monthly")}}>M</div>
                                <div key="d933" title={"Daily stats"} className={dClass} onClick={() => {this.props.changeSelectedStats("daily")}}>D</div>
                            </div>
                            {statsByCoins && <div>
                                {statsByCoins.map(elem =>
                                    <StatsComponent stats={elem.stats} base={elem.coin} selected={selectedStats}
                                                    selectedMonth={historyFilterSelectedMonth} selectedYear={historyFilterSelectedYear} id={elem.coin} key={elem.coin}/>
                                )}
                            </div>}

                            {/*{(stats && stats.btc) && <StatsComponent stats={stats.btc} base={"BTC"} selected={selectedStats}*/}
                            {/*                selectedMonth={historyFilterSelectedMonth} selectedYear={historyFilterSelectedYear} id={"BTC"} key={"BTC"}/>}*/}
                            {/*{(stats && stats.eth) && <StatsComponent stats={stats.eth} base={"ETH"} selected={selectedStats}*/}
                            {/*                selectedMonth={historyFilterSelectedMonth} selectedYear={historyFilterSelectedYear} id={"ETH"} key={"ETH"}/>}*/}
                            {/*{(stats && stats.usdt) && <StatsComponent stats={stats.usdt} base={"USDT"} selected={selectedStats}*/}
                            {/*                selectedMonth={historyFilterSelectedMonth} selectedYear={historyFilterSelectedYear} id={"USDT"} key={"USDT"}/>}*/}
                            <div style={{display: "flex", justifyContent: "flex-end", marginRight: "0.6rem"}}>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "4rem", cursor: "pointer"}}
                                    onClick={() => {this.props.confirmAction("downloadHistory")}}>
                                    <CloudDownload/><span>CSV</span>
                                </div>
                            </div>
                        </Paper>
                    </div>}
                    {!stats && <Paper className={"statsContainer"}><div style={{padding: "1rem"}}>No statistics recorded yet...</div></Paper>}
                    {(historyData && historyData.length > 0) && <div>
                        {historyData.map((completedTrade, index) =>{
                            if (completedTrade.record_type !== "ORDER") {
                                return (<CompletedTrade key={completedTrade.id} completedTrade={completedTrade} openCompletedTrade={this.openCompletedTrade}/>)
                            } else if (completedTrade.record_type === "ORDER") {
                                return (<CompletedOrder key={completedTrade.id} completedTrade={completedTrade}/>)
                            }
                        })}
                    </div>}
                    {(!historyData || (historyData && historyData.length === 0)) &&
                    <div style={{marginLeft: "2.6rem", marginTop: "2rem", width: "60rem", opacity: "0.6"}}>
                        No records found.
                    </div>
                    }
                </div>}
                {!historyData &&
                    <div style={{textAlign: "center", marginTop: "2rem", opacity: "0.6"}}>You have no <b>H</b>istory records</div>
                }

                <div className={"vertSpacer3"}/>
            </div>
        );
    }
}

let mapStateToProps = rootState =>({

})

let mapDispatchToProps = dispatch => {
    return {
        openCompletedTrade: (trade) => dispatch(openCompletedTrade(trade))
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (TradesHistory)
