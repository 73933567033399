import io from 'socket.io-client'
import s from '../../settings'
import {GET_ORDER_BOOK} from "./actionTypes"
import {unflatten} from 'flat'
import sum from './utils/hash-sum'

let storeRef = undefined
let clientWebSocket = undefined
let lastPayloadHash = ""
let clientWebSocketF = undefined
let lastPayloadHashF = ""

export const initStore = store => {
    storeRef = store
}

const logPrefix = "wsOrderBook"
const LOGGING = false

const S = "SPOT"
const F = "FUTURES"
const PATH_S = "/wso"
const PATH_F = "/wsof"

let lastPingEventTime = new Date()
let _initClientWS = (type, path, userId, symbol) => {

    let lPHash = undefined
    switch (type) {
        case S: lPHash = lastPayloadHash; break;
        case F: lPHash = lastPayloadHashF; break;
    }

    console.log("_initClientWS type %s, clientWebSocket %s, clientWebSocketF %s", type, clientWebSocket ? "OBJ": "null", clientWebSocketF ? "OBJ": "null")

    if (type === S && clientWebSocket || type === F && clientWebSocketF) {
        (s.mode !== "prod") && console.log("_initClientWS already initialized")
        return
    }

    let clientWS = undefined
    s.mode !== "prod" && console.log(`%s >> initWS ORDER_BOOK ${type} ${symbol} < ${userId} >`, logPrefix)
    clientWS = io(`${s.WS_API_BACKEND()}`, {
        path,
        query: {
            userId,
            symbol,
            withCredentials: true
        }
    })


    clientWS.on("fromOB", message => {
        let objFromServer = unflatten(message)
        if (s.mode !== "prod" && LOGGING) {
            console.log("%s ws[%s] message received", logPrefix, objFromServer.socketId)
        }
        let newPayloadHash = sum(objFromServer)
        if (lPHash !== newPayloadHash) {
            lPHash = newPayloadHash
        } else {
            return
        }

        storeRef.dispatch({
            type: GET_ORDER_BOOK,
            payload: objFromServer.orderBook
        })
    })

    clientWS.on("error", error => {
        if (s.mode !== "prod" && LOGGING)
            console.log("%s ws error %s", logPrefix, error)
    })

    clientWS.on("disconnect", reason => {
        if (s.mode !== "prod" && LOGGING)
            console.log("%s ws disconnect reason %s", logPrefix, reason)
    })

    clientWS.on("connect_error", () => {
        if (s.mode !== "prod" && LOGGING)
            console.log("%s ws connect_error", logPrefix)
    })

    clientWS.on("connect_timeout", () => {
        if (s.mode !== "prod" && LOGGING)
            console.log("%s ws connect_timeout", logPrefix)
    })

    clientWS.on("reconnect", () => {
        if (s.mode !== "prod" && LOGGING)
            console.log("%s ws reconnect", logPrefix)
    })

    clientWS.on("reconnect_attempt", () => {
        if (s.mode !== "prod" && LOGGING)
            console.log("%s ws reconnect_attempt", logPrefix)
    })

    clientWS.on("reconnecting", () => {
        if (s.mode !== "prod" && LOGGING)
            console.log("%s ws reconnecting", logPrefix)
    })

    clientWS.on("reconnect_error", () => {
        if (s.mode !== "prod" && LOGGING)
            console.log("%s ws reconnect_error", logPrefix)
    })

    clientWS.on("reconnect_failed", () => {
        if (s.mode !== "prod" && LOGGING)
            console.log("%s ws reconnect_failed", logPrefix)
    })

    clientWS.on("ping", () => {
        if (s.mode !== "prod" && LOGGING)
            console.log("%s ws ping", logPrefix)
        lastPingEventTime = new Date()
    })

    clientWS.on("pong", () => {
        let deltaTime = new Date().getTime() - lastPingEventTime.getTime()
        if (s.mode !== "prod" && LOGGING)
            console.log("%s ws pong after %s ms", logPrefix, deltaTime)
    })

    switch (type) {
        case S: clientWebSocket = clientWS; break;
        case F: clientWebSocketF = clientWS; break;
    }
}
let _closeWS = (type) => {

    if (type === S && clientWebSocket) {
        (s.mode !== "prod") && console.log(`%s << closeWS ${type} ws ${clientWebSocket}`, logPrefix)
        clientWebSocket.close()
        clientWebSocket = undefined
    } else if (type === F && clientWebSocketF) {
        (s.mode !== "prod") && console.log(`%s << closeWS ${type} ws ${clientWebSocketF}`, logPrefix)
        clientWebSocketF.close()
        clientWebSocketF = undefined
    } else {
        (s.mode !== "prod") && console.log(`%s << closeWS ${type} (clientWebSocket undefined)`, logPrefix)
    }
}


export const initWS = (userId, symbol) => {
    if (!userId) {
        return
    }
    (s.mode !== "prod") && console.log(`%s << initWS Spot`, logPrefix)
    _initClientWS(S, PATH_S, userId,  symbol)
}
export const closeWS = () => {
    (s.mode !== "prod") && console.log(`%s << closeWS Spot`, logPrefix)
    _closeWS(S)
}

export const initWSF = (userId, symbol) => {
    if (!userId) {
        return
    }
    (s.mode !== "prod") && console.log(`%s << initWS Futures`, logPrefix)
    _initClientWS(F, PATH_F, userId,  symbol)
}
export const closeWSF = () => {
    (s.mode !== "prod") && console.log(`%s << closeWS Futures`, logPrefix)
    _closeWS(F)
}


