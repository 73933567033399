import React, {Component} from "react"
import {connect} from "react-redux"
import VirtualScroller from "../../fncomponents/VirtualScroller"
import FuturesPositionCard from "../../fncomponents/FuturesPositionCard"
import FuturesOrderCard from "../../fncomponents/FuturesOrderCard"

class VirtualScrollerContainer extends Component {
    constructor(props) {
        super(props);

        let itemHeight = 260
        let amount = parseInt(this.props.windowHeight / itemHeight)
        this.state = {
            SETTINGS: {
                itemHeight,
                amount,
                tolerance: 1,
                minIndex: 0,
                maxIndex: this.props.futuresTrades ? this.props.futuresTrades.length -1: 0,
                startIndex: 0
            }
        }
        this.getData = this.getData.bind(this)
        this.rowTemplate = this.rowTemplate.bind(this)
    }

    getData = (offset, limit) => {
        const data = [];
        const start = Math.max(this.state.SETTINGS.minIndex, offset);
        const end = Math.min(offset + limit - 1, this.state.SETTINGS.maxIndex);
        if (start <= end) {
            for (let i = start; i <= end; i++) {
                data.push({ index: i, futuresTrade: this.props.futuresTrades[i]});
            }
        }
        return data;
    }

     rowTemplate = (record) => {
        if (this.props.type === "FUTURES_OPENED_ORDERS") {
            return <FuturesOrderCard key={record.futuresTrade.order_id} futuresTrade={record.futuresTrade} openRecord={()=>{this.props.openRecord(record.futuresTrade)}}/>
        } else if (this.props.type === "FUTURES_OPENED_POSITIONS") {
            return <FuturesPositionCard key={record.futuresTrade.order_id} futuresTrade={record.futuresTrade} openRecord={()=>{this.props.openRecord(record.futuresTrade)}} addRemoveMargin={this.props.addRemoveMargin}/>
        } else if (this.props.type === "FUTURES_CLOSED_POSITIONS") {
            return <FuturesPositionCard key={record.futuresTrade.order_id} futuresTrade={record.futuresTrade} openRecord={()=>{this.props.openRecord(record.futuresTrade)}}/>
        }
    }

    render() {

        if (this.props.futuresTrades && this.props.futuresTrades.length > 0) {
            return <div className={"viewPortContainer"}>
                <VirtualScroller
                    className="viewport"
                    get={this.getData}
                    settings={this.state.SETTINGS}
                    rowTemplate={this.rowTemplate}
                    //scrollIndex={this.props.scrollIndex}
                    //setScrollIndex={this.props.setScrollIndex}
                />
            </div>
        } else {
            return <div style={{paddingTop: "2rem", display: "flex", justifyContent: "center"}}>
                {this.props.type === "FUTURES_OPENED_ORDERS" && <span>You have no <b>O</b>rders</span>}
                {this.props.type === "FUTURES_OPENED_POSITIONS" && <span>You have no <b>P</b>ositions</span>}
                {this.props.type === "FUTURES_CLOSED_POSITIONS" && <span>You have no <b>C</b>losed Positions</span>}
            </div>
        }
    }
}

let mapStateToProps = rootState =>({
    //scrollIndex: rootState.trades.scrollIndex,
})

let mapDispatchToProps = dispatch => {
    return {
        //setScrollIndex: (scrollIndex) => dispatch(setScrollIndex(scrollIndex)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (VirtualScrollerContainer)
