import React, {Component} from 'react'
import {connect} from 'react-redux'
import c from '../../constants'
import s from '../../settings'
import {
    AccountCircle,
    Security,
    Notifications,
    VpnKey,
    SettingsInputAntenna,
    RecordVoiceOver
} from "@material-ui/icons"
import StepperOTPActivation from "../fncomponents/StepperOTPActivation"
import FullScreenDialog from "../fncomponents/FullScreenModalDialog"

import {
    generate2FA,
    enable2FA,
    disable2FA,
    getUserSettings,
    setUserAntiPhishingCode,
    removeUserAntiPhishingCode,
    saveUserNotificationsSettings,
    getNoOfReferredUsers, r3203
} from "../../redux/actions/profile"
import OTPDeactivation from "../fncomponents/OTPDeactivation"
import {getTitle} from "../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"
import AntiPhishingCodeDialog from "../fncomponents/AntiPhishingCodeDialog"
import SmallButton from "../fncomponents/SmallButton"
import UserNotificationsSettings from "../fncomponents/UserNotificationsSettings";
import ChangeLoginPassword from "../fncomponents/ChangeLoginPassword";
import {changePassword2, isUserSuperAdmin} from "../../redux/actions/authentication";
import UserPermissions from "../fncomponents/UserPermissions";
import {timeFormatter2} from "../fncomponents/UISupportFns";
import Button from "@material-ui/core/Button";

class MyProfile extends Component {

    constructor(props) {
        super(props)
        this.state  = {
            isOTPActivationStepperOpen: false,
            isOTPDeactivationDialogOpen: false,
            otp: '',
            password: '',
            isShowAntiPhishingCode: false,
            isShowAntiPhishingCodeDialogOpen: false,
            removeAntiPhisingCode: false,
            sucPosSizeFixed: 0,
            sucPosSizePercentage: 0,
            sucPosSizeType: 0,
            autoMaterializeSUC: 0,
            takeProfitTarget1: 0,
            takeProfitTarget2: 0,
            acceptSUCs: undefined,
            autoStartTradeFromSUC: undefined,
            notificationsSettings: {
                onNewSignal: false,
                onTradeClosed: false,
            },
            isChangePasswordDialogOpen: false,
            oldPassword: '',
            newPassword: '',
            newPasswordConfirmation: '',
            passwordChanged: false,
        }
        this.showOTPActivationStepper = this.showOTPActivationStepper.bind(this)
        this.hideOTPActivationStepper = this.hideOTPActivationStepper.bind(this)
        this.onOtpChange = this.onOtpChange.bind(this)
        this.onPasswordChange = this.onPasswordChange.bind(this)
        this.enable2FA = this.enable2FA.bind(this)
        // this.isOTPActivationStepperOpen = this.isOTPActivationStepperOpen.bind(this)
        this.showOTPDeactivationDialog = this.showOTPDeactivationDialog.bind(this)
        this.hideOTPDeactivationDialog = this.hideOTPDeactivationDialog.bind(this)
        this.disable2FA = this.disable2FA.bind(this)
        this.showAntiPhishingCode = this.showAntiPhishingCode.bind(this)
        this.hideAntiPhishingCode = this.hideAntiPhishingCode.bind(this)

        this.showAntiPhishingCodeDialog = this.showAntiPhishingCodeDialog.bind(this)
        this.hideAntiPhishingCodeDialog = this.hideAntiPhishingCodeDialog.bind(this)
        this.setAntiPhishingCode = this.setAntiPhishingCode.bind(this)
        this.removeAntiPhishingCode = this.removeAntiPhishingCode.bind(this)
        this.handleChangeAntiPhishingCode = this.handleChangeAntiPhishingCode.bind(this)

        this.handleChangeOnNewSignalNotif = this.handleChangeOnNewSignalNotif.bind(this)
        this.handleChangeOnTradeClosedNotif = this.handleChangeOnTradeClosedNotif.bind(this)

        this.handleChangePasswordInputs = this.handleChangePasswordInputs.bind(this)
        this.showChangePasswordDialog = this.showChangePasswordDialog.bind(this)
        this.hideChangePasswordDialog = this.hideChangePasswordDialog.bind(this)
        this.changePassword = this.changePassword.bind(this)

        this.applyForTrader = this.applyForTrader.bind(this)

        this.getUserApplicationForTraderContent = this.getUserApplicationForTraderContent.bind(this)

        this.props.getNoOfReferredUsers()
    }

    getUserApplicationForTraderContent(uaft) {
        if (!uaft)
            return null
        let content
        if (uaft.status === c.USER_TRADER_APPLICATION_STATUS_PENDING_APPROVAL) {
            content = <div className={"myProfileSettingOn"}>
                Application waiting review...<br/>
                {uaft.reapplied === 0 && <small style={{fontWeight: "normal"}}>(&nbsp;applied on {timeFormatter2(uaft.lastUpdateDate, true)})</small>}
                {uaft.reapplied === 1 && <small style={{fontWeight: "normal"}}>(&nbsp;app updated on {timeFormatter2(uaft.lastUpdateDate, true)})</small>}
                {uaft.reapplied > 1 && <small style={{fontWeight: "normal"}}>(&nbsp;app re-updated on {timeFormatter2(uaft.lastUpdateDate, true)})</small>}
            </div>
        } else if (uaft.status === c.USER_TRADER_APPLICATION_STATUS_REJECTED) {
            content = <div><div className={"myProfileSettingOff"}>Application rejected</div>
                <small><b>Rejection reason:</b>{uaft.rejectedReason}</small>
            </div>
        } else if (uaft.status === c.USER_TRADER_APPLICATION_STATUS_PERMANENTLY_REJECTED) {
            content = <div className={"myProfileSettingOff"}>Application permanently rejected</div>
        }
        return content
    }

    applyForTrader() {
        this.props.history.push("/applyfortrader")
    }


    showOTPActivationStepper() {
        this.state.isOTPActivationStepperOpen = true
        this.state.isOTPDeactivationDialogOpen = false

        this.props.generate2FA()
    }

    hideOTPActivationStepper() {
        this.setState({
            isOTPActivationStepperOpen: false
        })
        // this.props.cancel2FA()
    }

    onOtpChange(otp) {
        this.setState({
            otp: otp
        })
    }

    onPasswordChange(e) {
        if (e.target) {
            this.setState({
                password: e.target.value
            })
        }
    }

    enable2FA() {
        this.props.enable2FA({password: this.state.password, otp: this.state.otp}, () => {
            this.setState({
                isOTPActivationStepperOpen: false
            })
        })
    }

    showOTPDeactivationDialog() {
        this.setState({
            isOTPActivationStepperOpen: false,
            isOTPDeactivationDialogOpen: true
        })
    }

    hideOTPDeactivationDialog() {
        this.setState({
            isOTPDeactivationDialogOpen: false
        })
    }

    disable2FA() {
        this.props.disable2FA({password: this.state.password, otp: this.state.otp}, ()=>{
            this.setState({
                isOTPDeactivationDialogOpen: false
            })
        })
    }

    getUserLevelHR(user) {
        if (user.type === "basic") {
            return "Basic"
        } else {
            return "Unknown"
        }
    }

    showAntiPhishingCode() {
        this.setState({
            isShowAntiPhishingCode: true
        })
    }

    hideAntiPhishingCode() {
        this.setState({
            isShowAntiPhishingCode: false
        })
    }

    showAntiPhishingCodeDialog(remove=false) {
        this.setState({
            isShowAntiPhishingCodeDialogOpen: true,
            removeAntiPhisingCode: remove
        })
    }

    hideAntiPhishingCodeDialog() {
        this.setState({
            isShowAntiPhishingCodeDialogOpen: false,
        })
    }

    handleChangeAntiPhishingCode(e) {
        if (e.target.value && e.target.value.length < 30)
            this.setState({
                antiPhishingCode: e.target.value
            })
    }

    setAntiPhishingCode() {
        this.state.isShowAntiPhishingCodeDialogOpen = false
        this.props.setUserAntiPhishingCode({code: this.state.antiPhishingCode})
    }

    removeAntiPhishingCode() {
        this.state.isShowAntiPhishingCodeDialogOpen = false
        this.props.removeUserAntiPhishingCode()
    }

    maskCode(code) {
        return `${code.substring(0, 3)}********`
    }

    handleChangeOnNewSignalNotif() {
        this.setState({
            notificationsSettings: {
                ...this.state.notificationsSettings,
                ...{onNewSignal: !this.state.notificationsSettings.onNewSignal}
            }
        })
        this.props.saveUserNotificationsSettings({
            onNewSignal: !this.state.notificationsSettings.onNewSignal,
            onTradeClosed: this.state.notificationsSettings.onTradeClosed
        })

    }

    handleChangeOnTradeClosedNotif() {
        this.setState({
            notificationsSettings: {
                ...this.state.notificationsSettings,
                ...{onTradeClosed: !this.state.notificationsSettings.onTradeClosed}
            }
        })
        this.props.saveUserNotificationsSettings({
            onNewSignal: this.state.notificationsSettings.onNewSignal,
            onTradeClosed: !this.state.notificationsSettings.onTradeClosed
        })
    }

    showChangePasswordDialog() {
        this.setState({
            isChangePasswordDialogOpen: true,
            passwordChanged: false
        })
    }

    hideChangePasswordDialog() {
        this.setState({
            isChangePasswordDialogOpen: false,
            passwordChanged: false
        })
    }

    handleChangePasswordInputs(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    changePassword() {
        this.props.changePassword2({oldPassword: this.state.oldPassword,
            newPassword: this.state.newPassword,
            newPasswordConfirmation: this.state.newPasswordConfirmation}, ()=>{
            this.setState({
                passwordChanged: true
            })
        })
    }



    componentDidMount() {
        this.props.getUserSettings(settings => {
            this.setState({
                notificationsSettings: settings.notificationsSettings
            })
        })
    }



    render() {
        if (!this.props.user)
            return null

        let twoFAEnabled = this.props.user.twoFAEnabled
        let antiPhishingCode = this.props.antiPhishingCode
        let antiPhishingCodeMasked = antiPhishingCode ? this.maskCode(antiPhishingCode): ""
        let user = this.props.user

        const _domain = window.location.hostname
        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <div className={"vertSpacer2"}/>
            <div className={"profileControlContainer"}>
                <div style={{display: "flex"}}>
                    <div className={"normalIconContainer"}>
                        <AccountCircle/>
                    </div>
                    <div>
                        <b>{this.props.user.displayName}</b><br/>
                        <UserPermissions permissions={this.props.user.permissions}/>
                        {this.props.user.email}<br/>
                        ID: {this.props.user.userId}<br/>
                        {(isUserSuperAdmin(this.props.user) ||
                            s.mode.indexOf("dev") >= 0 ||
                            s.mode === "test")&& <div>
                            _id: {this.props.user._id}<br/>
                            group: {this.props.user.group}
                        </div>}
                        <div style={{marginTop: "0.6rem"}}>
                            Level <b>{this.getUserLevelHR(this.props.user)}</b><br/>
                            <b>{this.props.activeBTDS}</b>/<b>{this.props.maxAllowedActiveBTDS}</b> active BTDs
                        </div>
                    </div>
                </div>
            </div>
            {s.mode === "devNginx" && <div style={{cursor: "pointer", color: "#68ff7d", fontWeight: "bold", marginLeft: "2rem"}} onClick={this.props.r3203}>r3203</div>}
            {user.isTrader &&
            <div>
                <div className={"vertSpacer2"}/>
                <div className={"profileControlContainer"} >
                    <div style={{display: "flex"}}>
                        <div className={"normalIconContainer"}>
                            <SettingsInputAntenna/>
                        </div>
                        <div>
                            <b>Trader profile</b>
                        </div>
                    </div>
                </div>
            </div>}
            <div className={"vertSpacer2"}/>
            <div className={"profileControlContainer"} >
                <div style={{display: "flex"}}>
                    <div className={"normalIconContainer"}>
                        <RecordVoiceOver/>
                    </div>
                    <div>
                        <b>Affiliate program</b>
                        <div className={"mediumExplanations"}>Invite new users using your affiliate link and earn up to <b>50%</b> from the fees they generate for life!</div>
                        <div className={"mediumExplanations"}>Your affiliate link: <a href={`/ref/${this.props.user.userId}`}>
                            https://{_domain}/ref/{this.props.user.userId}</a></div>
                        <div style={{fontSize: "0.72rem", fontStyle: "italic", marginTop:"0.4rem"}}>Please note that on detecting improper use of the program any generated commissions will be lost.</div>
                        <div>
                            <div style={{marginTop: "0.7rem"}}>
                                <Button onClick={()=>{this.props.history.push("/referredusers")}} color="primary">({this.props.referredUsersNo}) Referred Users</Button>
                                <Button onClick={()=>{this.props.history.push("/affiliate/commissions")}} color="primary">Commissions</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"vertSpacer2"}/>
            <div className={"profileControlContainer"}>
                <div style={{display: "flex"}}>
                    <div className={"normalIconContainer"}>
                        <img src={'imgs/2fa/google2faicon.png'} className={"normalIcon"} alt={"2faicon"}/>
                    </div>
                    <div>
                        <b>Google Authentication</b><br/>
                        <div className={"smallExplanations"}>Used for authentication, withdrawals and other sensitive operations.</div>
                        {twoFAEnabled && <div className={"myProfileSettingOn"} style={{marginTop: "0.6em"}}>ENABLED</div>}
                        {!twoFAEnabled && <div className={"myProfileSettingOff"} style={{marginTop: "0.6em"}}>DISABLED</div>}
                    </div>
                </div>
                <div>
                    {!twoFAEnabled && <SmallButton label={"Enable"}  variant="contained" onClick={this.showOTPActivationStepper}/>}
                    {twoFAEnabled && <SmallButton label={"Disable"} variant="outlined" onClick={this.showOTPDeactivationDialog}/>}
                </div>
            </div>
            <FullScreenDialog open={this.state.isOTPActivationStepperOpen}
                              title={"Enable Google Authenticator"}
                              mainContent={<StepperOTPActivation otpAuthUrl={this.props.otpAuthUrl}
                                                                 twoFASecret={this.props.twoFASecret}
                                                                 otp={this.state.otp}
                                                                 onOtpChange={this.onOtpChange}
                                                                 password={this.state.password}
                                                                 onPasswordChange={this.onPasswordChange}
                                                                 validationErrors={this.props.twoFAValidationErrors}
                                                                 finish={this.enable2FA}
                              />}
                              onClose={this.hideOTPActivationStepper}
                />
            <FullScreenDialog open={this.state.isOTPDeactivationDialogOpen}
                              title={"Disable Google Authenticator"}
                              mainContent={<OTPDeactivation otp={this.state.otp}
                                                            onOtpChange={this.onOtpChange}
                                                            password={this.state.password}
                                                            onPasswordChange={this.onPasswordChange}
                                                            validationErrors={this.props.twoFAValidationErrors}
                                                            finish={this.disable2FA}/>}
                              onClose={this.hideOTPDeactivationDialog}
            />
            <div className={"vertSpacer2"}/>
            <div className={"profileControlContainer"}>
                <div style={{display: "flex"}}>
                    <div className={"normalIconContainer"}>
                        <VpnKey/>
                    </div>
                    <div>
                        <b>Password</b><br/>
                        <div className={"smallExplanations"}>Change your login password.</div>
                    </div>
                </div>
                <div>
                    <SmallButton label={"Change"}  variant="contained" onClick={()=>{this.showChangePasswordDialog()}}/>
                </div>
            </div>
            <FullScreenDialog open={this.state.isChangePasswordDialogOpen}
                              title={"Change Login Password"}
                              mainContent={<ChangeLoginPassword handleChangePasswordInputs={this.handleChangePasswordInputs}
                                                                handleSave={this.changePassword}
                                                                handleCancel={this.hideChangePasswordDialog}
                                                                errors={this.props.changePasswordError ? this.props.changePasswordError: {}}
                                                                passwordChanged={this.state.passwordChanged}
                              />}
                              onClose={this.hideChangePasswordDialog}
            />
            <div className={"vertSpacer2"}/>
            <div className={"profileControlContainer"}>
                <div style={{display: "flex"}}>
                    <div className={"normalIconContainer"}>
                        <Security/>
                    </div>
                    <div>
                        <b>Anti-phishing code</b><br/>
                        <div className={"smallExplanations"}>The Anti-phishing code will appear in all emails received from us, <br/>
                        so that you will be able to tell apart a phishing attempt from our genuine communication.<br/>
                        Can have a maximum of 30 characters.</div>
                        {antiPhishingCode && <div className={"myProfileSettingOn"} style={{marginTop: "0.6em"}}>
                            {this.state.isShowAntiPhishingCode &&
                                <div style={{display: "flex", justifyContent: "space-between", width: `${antiPhishingCode.length*0.7}rem`, minWidth: "8rem", maxWidth: "22rem"}}>{antiPhishingCode}
                                <img src={'imgs/showHide/icons8-hide-50.png'}
                                    className={"showHidePasswordIcon"}
                                    style={{cursor: "pointer", marginLeft:"0.4rem"}}
                                    onClick={()=>{this.hideAntiPhishingCode()}} alt={"showHidePasswordIcon"}/></div>}
                            {!this.state.isShowAntiPhishingCode &&
                                <div style={{display: "flex", justifyContent: "space-between", width: `${antiPhishingCode.length*0.7}rem`, minWidth: "8rem", maxWidth: "22rem"}}>
                                    {antiPhishingCodeMasked}
                                <img src={'imgs/showHide/icons8-eye-50.png'}
                                     className={"showHidePasswordIcon"}
                                     style={{cursor: "pointer", marginLeft:"0.4rem"}}
                                     onClick={()=>{this.showAntiPhishingCode()}} alt={"showHidePasswordIcon"}/></div>}
                        </div>}
                        {!antiPhishingCode && <div className={"myProfileSettingOff"} style={{marginTop: "0.6em"}}>not set</div>}
                    </div>
                </div>
                <div>
                    {!antiPhishingCode && <SmallButton label={"Configure"}  variant="contained" onClick={()=>{this.showAntiPhishingCodeDialog()}}/>}
                    {antiPhishingCode && <SmallButton label={"Remove"} variant="outlined" onClick={()=>{this.showAntiPhishingCodeDialog(true)}}/>}
                </div>
            </div>
            <AntiPhishingCodeDialog
                open={this.state.isShowAntiPhishingCodeDialogOpen}
                handleSet={this.setAntiPhishingCode}
                handleRemove={this.removeAntiPhishingCode}
                handleCancel={this.hideAntiPhishingCodeDialog}
                antiPhishingCode={this.state.antiPhishingCode}
                handleChangeAntiPhishingCode={this.handleChangeAntiPhishingCode}
                remove={this.state.removeAntiPhisingCode}
            />
            <div className={"vertSpacer2"}/>
            <div className={"profileControlContainer"}>
                <div style={{display: "flex"}}>
                    <div className={"normalIconContainer"}>
                        <Notifications/>
                    </div>
                    <div>
                        <b>Email notifications Settings</b>
                    </div>
                </div>
            </div>
            {this.state.notificationsSettings && <UserNotificationsSettings
                onNewSignalNotif={this.state.notificationsSettings.onNewSignal}
                onTradeClosedNotif={this.state.notificationsSettings.onTradeClosed}
                handleChangeOnNewSignalNotif={ this.handleChangeOnNewSignalNotif}
                handleChangeOnTradeClosedNotif={this.handleChangeOnTradeClosedNotif}
            />}
            <div className={"vertSpacer2"}/>
            {(!user.isTrader && user.group !== 5) &&
            <div>
                <div className={"profileControlContainer"} >
                    <div style={{display: "flex"}}>
                        <div className={"normalIconContainer"}>
                            <SettingsInputAntenna/>
                        </div>
                        <div>
                            <b>Become a Trader</b><br/>
                            <div className={"smallExplanations"}>
                                Apply to become a Trader and provide signals to our users.<br/><br/>
                                <span>NOTICE</span><br/>
                                <span style={{fontWeight: "bold"}}>Currently we are accepting only traders that can prove the existence of their followers group.
                                As such, a Venbot representative will join your group and check its validity.</span>
                            </div>
                            <div style={{marginTop: "0.6rem"}}>
                                {this.getUserApplicationForTraderContent(this.props.userTraderApplication)}
                            </div>
                        </div>
                    </div>
                    <div>
                       {!this.props.userTraderApplication && <SmallButton label={"APPLY"} variant="outlined" onClick={this.applyForTrader}/>}
                        {(this.props.userTraderApplication && this.props.userTraderApplication.status === c.USER_TRADER_APPLICATION_STATUS_REJECTED) &&
                            <SmallButton label={"UPDATE"} variant="outlined" onClick={this.applyForTrader}/>}
                    </div>
                </div>
                <div className={"vertSpacer3"}/>
            </div>}
        </div>
    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    referredUsersNo: rootState.profile.referredUsersNo,
    twoFASecret: rootState.profile.twoFASecret,
    otpAuthUrl: rootState.profile.otpAuthUrl,
    twoFAValidationErrors: rootState.errors.twoFAValidationErrors,
    antiPhishingCode: rootState.profile.antiPhishingCode,
    notificationSettings: rootState.profile.notificationSettings,
    userTraderApplication: rootState.profile.userTraderApplication,
    activeBTDS: rootState.profile.activeBTDS,
    maxAllowedActiveBTDS: rootState.profile.maxAllowedActiveBTDS,
    changePasswordError: rootState.errors.changePasswordError,
})

let mapDispatchToProps = dispatch => {
    return {
        generate2FA: () => {dispatch(generate2FA())},
        // cancel2FA: () => {dispatch(cancel2FA())},
        enable2FA: (props) => {dispatch(enable2FA(props))},
        disable2FA: (props) => {dispatch(disable2FA(props))},
        getUserSettings: (cb) => {dispatch(getUserSettings(cb))},
        setUserAntiPhishingCode: (props) => {dispatch(setUserAntiPhishingCode(props))},
        removeUserAntiPhishingCode: () => {dispatch(removeUserAntiPhishingCode())},
        saveUserNotificationsSettings: (props) => {dispatch(saveUserNotificationsSettings(props))},
        changePassword2: (props, cb) => {dispatch(changePassword2(props, cb))},
        getNoOfReferredUsers: (props, cb) => {dispatch(getNoOfReferredUsers(props, cb))},
        r3203: (props, cb) => {dispatch(r3203(props, cb))}
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (MyProfile)
