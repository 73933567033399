import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {TextField} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

export default function StakeUnstakeDialog(props) {

    const {open, title, saveLabel,
        coin, stakingAmt, handleChangeStakingAmt, stakingBalance, max, useMax,
        handleSave, handleCancel} = props

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title} {coin}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormControl fullWidth variant="outlined">
                        <TextField name={"stakingAmt"} value={stakingAmt}
                                   onChange={handleChangeStakingAmt} placeholder={"Amount"} labelWidth={60}/>
                        <div style={{display: "flex", justifyContent: "flex-start", marginTop: "0.6rem", marginLeft: "0.3rem"}}>
                            <div style={{fontSize: "0.8rem", marginRight: "1rem"}}>
                                {max && <span>Max. {max} {coin}</span>}
                            </div>
                            <div style={{marginLeft: "1rem", fontSize: "0.8rem"}} className={"hrefButton"} onClick={useMax}>Use Max. Amount</div>
                        </div>
                    </FormControl>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave} color="primary">
                    {saveLabel}
                </Button>
                <Button onClick={handleCancel} color="primary" autoFocus>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
