import React, {Component} from 'react'
import { connect } from 'react-redux'
import {getMyInvestmentPurchases, getMyReferralInvestmentPurchases} from "../../../redux/actions/investment";
import MyInvestmentPurchsesTable from "./MyInvestmentPurchasesTable";


class MyInvestmentPurchasesTab extends Component {

    constructor(props) {
        super(props)
        this.state = {}

        this.renderReferralInvestmentPurchases = this.renderReferralInvestmentPurchases.bind(this)
    }

    componentDidMount() {
        this.props.getMyReferralInvestmentPurchases()
        this.props.getMyInvestmentPurchases()
    }

    renderReferralInvestmentPurchases() {
        if (!this.props.myReferralInvestmentPurchases)
            return null
        if (this.props.myReferralInvestmentPurchases.length === 0)
            return null
        let content = []
        for(let i = 0; i < this.props.myReferralInvestmentPurchases.length; i++) {
            let q = this.props.myReferralInvestmentPurchases[i]
            content.push(<div style={{marginTop: "0.2rem", marginLeft: "0.2rem"}}>
                <span style={{fontSize: "1.2rem", fontWeight: "bold"}}>{q.ip.title}</span>,&nbsp;
                <span style={{fontSize: "1.6rem", fontWeight: "bold"}}>{q.percent}%</span>&nbsp;-&nbsp;
                <span style={{fontSize: "1.4rem", fontWeight: "bold"}}>{q.referralTokens} {q.token}</span>&nbsp;
                from {q.no} users who bought {q.tokens} {q.token} for {q.amount} {q.tokenPriceCurrency}.
            </div>)
        }
        return <div>
            <div style={{marginTop: "0.8rem", opacity: "0.7", fontSize: "0.8rem"}}>Referral programs</div>
            {content}
        </div>
    }
    render() {
        return <div>
            {this.renderReferralInvestmentPurchases()}
            {this.props.myInvestmentPurchases && <>
                <div style={{marginTop: "0.8rem", marginBottom: "-0.8rem", opacity: "0.7", fontSize: "0.8rem"}}>Contributions</div>
            <MyInvestmentPurchsesTable
                rows={this.props.myInvestmentPurchases}
                history={this.props.history}
                changeTabToPrograms={this.props.changeTabToPrograms}
            />
            </>
            }
        </div>
    }
}

const mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    myInvestmentPurchases: rootState.investment.myInvestmentPurchases,
    myReferralInvestmentPurchases: rootState.investment.myReferralInvestmentPurchases
})

const mapDispatchToProps = dispatch => {
    return {
        getMyInvestmentPurchases: (props, cb) => dispatch(getMyInvestmentPurchases(props, cb)),
        getMyReferralInvestmentPurchases: (props, cb) => dispatch(getMyReferralInvestmentPurchases(props, cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (MyInvestmentPurchasesTab)
