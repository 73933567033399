import React, {Component} from 'react'
import {connect} from 'react-redux'
import SelectPair from "../fncomponents/SelectPair"
import c from '../../constants'

import {
    getCurrenciesMap,
    getLastUsedParamsOnNewTrade,
    getCurrentPriceOnPair,
    checkUserBalance,
    getUserBaseBalance,
    getUserQuoteBalance,
    // getOrderBook,
    clearNotEnoughBalance,
    previewNewExchange
} from "../../redux/actions/newTrade"
import {initWS, closeWS} from "../../redux/actions/wsOrderBook"
import CircularIndeterminate from "../fncomponents/loading";
import {
    brushValue,
    getValueWithPrecision,
    isValidPair,
    scientificToDecimal
} from "../fncomponents/UISupportFns";
import {CompareArrows, Info, Warning} from "@material-ui/icons";
import {clearErrors} from "../../redux/actions/errors";
import {debounce} from "../hoc/myUtils";
import OrderBook from "../fncomponents/OrderBook";
import {getTitle} from "../fncomponents/PageTitleUtils";
import {Helmet} from "react-helmet/es/Helmet";
import ExchangeControl from "../fncomponents/ExchangeControl";
import NewConversionStep1Buttons from "../fncomponents/NewConversionStep1Buttons";
import {getActiveSuspendedActivities} from "../../redux/actions/administration";


const DEBOUNCE_DELAY_IN_MS = 500

class NewConversion extends Component {

    constructor(props) {
        super(props)
        this.state = {
            quote: '',
            base: 'BTC',
            posSize: 0, //position size
            posSizeTick: 8, //position size tick (the amount of decimals are accepted regarding the quantity by the current currency)
            amount: 0,
            exchange: c.EXCHANGE_BINANCE, //exchange_binance or exchange_coinex
            tradeType: c.TRADE_TYPE_NEW_TRADE, //new or old
            actionType: c.ACTION_TYPE_BUY, //action_type_BUY or action_type_SELL
            actionTypeOption: c.ACTION_TYPE_OPTION_MARKET, //fixedPrice, market, justAboveBid
            fixedPrice: 0,
            currentPrice: 0,
            showLoading: true,
        }

        this.handleChangeActionType = this.handleChangeActionType.bind(this)
        this.handleChangeQuote = this.handleChangeQuote.bind(this)
        this.validatePairAndGetUserQuoteBalance = this.validatePairAndGetUserQuoteBalance.bind(this)
        this.debounceValidatePairAndGetUserQuoteBalance = debounce(this.validatePairAndGetUserQuoteBalance, DEBOUNCE_DELAY_IN_MS)
        this.handleChangeBase = this.handleChangeBase.bind(this)
        this.handleChangeTradeType = this.handleChangeTradeType.bind(this)
        this.handleChangeFixedPrice = this.handleChangeFixedPrice.bind(this)
        this.handleChangeActionTypeOption = this.handleChangeActionTypeOption.bind(this)
        this.getCurrentPrices = this.getCurrentPrices.bind(this)
        this.previewNewExchange = this.previewNewExchange.bind(this)
        this.checkUserBalance = this.checkUserBalance.bind(this)
        this.handleChangePosSize = this.handleChangePosSize.bind(this)
        this.fixPosSize = this.fixPosSize.bind(this)
        this.debounceFixPosSize = debounce(this.fixPosSize, DEBOUNCE_DELAY_IN_MS)
        this.fixFixedPrice = this.fixFixedPrice.bind(this)
        this.debounceFixFixedPrice = debounce(this.fixFixedPrice, DEBOUNCE_DELAY_IN_MS)
        // this.getOrderBookPeriodically = this.getOrderBookPeriodically.bind(this)

        this.selectMaxBaseBalance = this.selectMaxBaseBalance.bind(this)
        this.selectMaxQuoteBalance = this.selectMaxQuoteBalance.bind(this)
        this.getPosSizeCurrency = this.getPosSizeCurrency.bind(this)

        this.handleChangeAmount = this.handleChangeAmount.bind(this)
        this.fixAmount = this.fixAmount.bind(this)
        this.debounceFixAmount = debounce(this.fixAmount, DEBOUNCE_DELAY_IN_MS)

        this.getCurrentPriceFromOrderBook = this.getCurrentPriceFromOrderBook.bind(this)
    }

    getCurrentPriceFromOrderBook() {
        if (this.props.orderBook && this.props.orderBook.bids && this.props.orderBook.bids[0])
            return brushValue(this.props.orderBook.bids[0].price, this.props.orderBook.tickSize)
        return 0
    }

    fixAmount() {
        if (`${this.state.amount}`.length === 0)
            return

        let amount = parseFloat(this.state.amount)
        if (amount === 0)
            return
        if (!isNaN(amount)) {
            let parts = `${amount}`.split(".")
            if (parts[1] && parts[1].length > this.state.precision) {
                amount = `${amount}`.substr(0, parts[0].length + 1 + this.state.precision)
            }
        }
        this.setState({
            amount
        })
    }

    handleChangeAmount(e) {
        let amount = parseFloat(e.target.value)
        if (isNaN(amount)) {
            this.setState({
                amount: e.target.value
            })
            return
        }

        let posSize = amount * parseFloat(this.state.fixedPrice)
        let parts = `${posSize}`.split('.')
        if (parts[1] && parts[1].length > this.state.posSizeTick) {
            posSize = posSize.toFixed(this.state.posSizeTick)
        }
        this.setState({
            amount: e.target.value,
            posSize
        })

        this.debounceFixAmount()
    }

    handleChangeActionType() {
        this.props.clearNotEnoughBalance()
        if (this.state.actionType === c.ACTION_TYPE_BUY) {
            if (this.state.actionTypeOption === c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE) {
                this.state.actionTypeOption = c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE
            } else if (this.state.actionTypeOption === c.ACTION_TYPE_OPTION_WAIT_FOR_A_DIP) {
                this.state.actionTypeOption = c.ACTION_TYPE_OPTION_FIXED_PRICE
            }
            this.setState({
                actionType: c.ACTION_TYPE_SELL,
            })
        } else {
            if (this.state.actionTypeOption === c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE) {
                this.state.actionTypeOption = c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE
            }
            this.setState({
                actionType: c.ACTION_TYPE_BUY,
            })
        }
    }

    handleChangeQuote(e) {
        let newQuote = e.target.value.toUpperCase()
        this.setState({
            quote: newQuote
        })
        this.debounceValidatePairAndGetUserQuoteBalance()
    }

    validatePairAndGetUserQuoteBalance() {
        this.props.clearNotEnoughBalance()
        this.props.getUserQuoteBalance({currency: this.state.quote}, result => {
            this.setState({
                userQuotePrecision: parseInt(result.precision),
                userQuoteBalance: result.value ? getValueWithPrecision(result.value, result.precision) : 0,
            })
        })

        if(isValidPair(this.state.quote, this.state.base, this.props.quoteCurrenciesNom)) {
            this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, result => {
                this.state.tickSize = parseInt(result.tick_size)
                this.state.precision = parseInt(result.precision)
                let currentLastBidPrice = getValueWithPrecision(result.current_price, this.state.tickSize)
                this.setState({
                    fixedPrice: result.current_price ? currentLastBidPrice : 0,
                    currentPrice: result.current_price,
                    userQuotePrecision: parseInt(result.precision)
                })
                closeWS()
                this.props.user && initWS(this.props.user.userId, `${this.state.quote}${this.state.base}`)
            })
        } else {
            this.setState({
                fixedPrice: 0,
                currentPrice: 0,
            })
        }
    }

    handleChangeBase(e) {
        let newBase = e.target.value
        this.state.base = newBase

        if (this.state.tradeType === c.TRADE_TYPE_NEW_TRADE) {
            this.props.getCurrentPriceOnPair({quote: this.state.quote, base:  newBase}, result => {
                this.state.tickSize = parseInt(result.tick_size)
                this.state.precision = parseInt(result.precision)
                let currentLastBidPrice = getValueWithPrecision(result.current_price, this.state.tickSize)
                this.setState({
                    fixedPrice: result.current_price ? currentLastBidPrice : 0,
                    currentPrice: result.current_price
                })
            })
        } else if (this.state.tradeType === c.TRADE_TYPE_OLD) {
            if (this.state.actionType === c.ACTION_TYPE_BUY) {
                this.updateFixedPriceToAvgOldBuyPrice(this.state, this.props)
            }
        }

        this.props.getUserBaseBalance({currency: newBase})
    }

    handleChangePosSize(e) {
        if (this.state.actionTypeOption !== c.ACTION_TYPE_OPTION_MARKET || e.force) {
            this.setState({
                posSize: e.target.value
            })
            // let posSize = parseFloat(e.target.value)
            // let dotIndex = e.target.value.indexOf(".")
            // if (e.target.value.length === 0 || isNaN(posSize) || dotIndex === e.target.value.length -1) {
            //     return this.setState({
            //         posSize: e.target.value
            //     })
            // }
            //
            // let amount = (posSize / this.state.fixedPrice).toFixed(this.state.precision)
            // this.setState({
            //     amount,
            //     posSize
            // })
            this.debounceFixPosSize()
        }
    }

    fixPosSize() {
        if (this.state.posSize.length === 0)
            return
        let posSize = parseFloat(this.state.posSize)
        if (isNaN(posSize) || posSize === 0)
            return
        let amount = 0
        if (this.state.fixedPrice > 0) {
            amount = (posSize / this.state.fixedPrice).toFixed(this.state.precision)
        }
        let precision = 8
        precision = c.BASE_PRECISIONS[this.state.base]
        this.setState({
            posSize: getValueWithPrecision(this.state.posSize, precision),
            amount
        })
    }

    handleChangeTradeType() {
        this.props.clearNotEnoughBalance()
        if (this.state.actionType === c.ACTION_TYPE_BUY) {
            //Switch to OLD TRADE
            if (this.state.tradeType === c.TRADE_TYPE_NEW_TRADE) {
                let  state = {
                    tradeType: c.TRADE_TYPE_OLD,
                    actionTypeOption: c.ACTION_TYPE_OPTION_FIXED_PRICE,
                    posSize: 0,
                    posSizeCurrency: this.state.quote,
                }

                this.updateFixedPriceToAvgOldBuyPrice(state, this.props)
                this.setState(state)
            }
            // Switch to NEW TRADE
            else {
                this.setState({
                    tradeType: c.TRADE_TYPE_NEW_TRADE,
                    actionTypeOption: c.ACTION_TYPE_OPTION_FIXED_PRICE,
                    posSize: 0,
                    posSizeCurrency: this.state.base,
                })
            }
        } else if (this.state.actionType === c.ACTION_TYPE_SELL) {
            //Switch to OLD TRADE
            if (this.state.tradeType === c.TRADE_TYPE_NEW_TRADE) {
                this.setState({
                    tradeType: c.TRADE_TYPE_OLD,
                    actionTypeOption: c.ACTION_TYPE_OPTION_FIXED_PRICE,
                    posSize: 0,
                    posSizeCurrency: this.state.base,
                })
            }
            //Switch to NEW TRADE
            else {
                this.setState({
                    tradeType: c.TRADE_TYPE_NEW_TRADE,
                    actionTypeOption: c.ACTION_TYPE_OPTION_FIXED_PRICE,
                    posSize: 0,
                    posSizeCurrency: this.state.quote,
                })
            }
        }
    }

    handleChangeFixedPrice(e) {
        if (this.state.tradeType === c.TRADE_TYPE_OLD && this.props.userAvgBuyPriceForQuote)
            return
        this.setState({
            fixedPrice: e.target.value
        })
        this.debounceFixFixedPrice()
    }

    fixFixedPrice() {
        if (this.state.fixedPrice.length === 0)
            return
        let fixedPriceError = undefined
        if (this.state.actionTypeOption === c.ACTION_TYPE_OPTION_FIXED_PRICE) {
            let fixedPriceF = parseFloat(this.state.fixedPrice)
            let currentPriceF = parseFloat(this.state.currentPrice)
            let delta = (fixedPriceF - currentPriceF) / currentPriceF
            if (this.state.actionType === c.ACTION_TYPE_SELL)
                delta *= -1
            if (delta >= 0.1) {
                if (this.state.actionType === c.ACTION_TYPE_BUY) {
                    fixedPriceError = `buy price is current price +${(delta * 100).toFixed(2)}%`
                } else if (this.state.actionType === c.ACTION_TYPE_SELL) {
                    fixedPriceError = `sell price is current price -${(delta * 100).toFixed(2)}%`
                }
            }
        }

        this.setState({
            fixedPrice: getValueWithPrecision(this.state.fixedPrice, this.state.tickSize),
            fixedPriceError
        })
    }

    handleChangeActionTypeOption(actTypeOption) {
        if (this.state.quote && this.state.quote.length < 2)
            return

        if (this.state.tradeType === c.TRADE_TYPE_OLD) {
            if (actTypeOption !== c.ACTION_TYPE_OPTION_FIXED_PRICE)
                return
        }

        this.setState({
            actionTypeOption: actTypeOption
        })

        if (this.state.quote.length > 1 && actTypeOption !== c.ACTION_TYPE_OPTION_MARKET) {
            this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, result => {
                let currentPrice = result.current_price
                let price = 0
                if (this.state.actionType === c.ACTION_TYPE_BUY)
                    price = currentPrice ? parseFloat(currentPrice) * (actTypeOption === c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE ? c.LAST_BID_PRICE_MULTIPLIER : 1): 0
                else if (this.state.actionType === c.ACTION_TYPE_SELL)
                    price = currentPrice ? parseFloat(currentPrice) * (actTypeOption === c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE ? c.FIRST_ASK_PRICE_MULTIPLIER : 1): 0

                this.state.fixedPrice = scientificToDecimal(getValueWithPrecision(price, this.state.tickSize))
                this.currentPrice = result.current_price
            })
        }
    }

    getCurrentPrices() {
        this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base})
    }

    setLastUsedParamsToState(input) {
        this.state.actionType = input.actionType
        this.state.tradeType = input.tradeType
        this.state.quote = input.quote ? input.quote: ""
        this.state.base = input.base ? input.base : ""
        // this.state.posSize = input.posSize ? input.posSize : 0
        this.state.amount = 0
        this.state.posSize = 0
        this.state.posSizeTick = input.posSizeTick ? input.posSizeTick: 8
        this.state.actionTypeOption = input.actionTypeOption //fixed/market/just above bid/just below ask
        if (this.state.actionTypeOption === c.ACTION_TYPE_OPTION_WAIT_FOR_A_DIP) {
            this.state.actionTypeOption = c.ACTION_TYPE_OPTION_FIXED_PRICE
        }
        this.state.fixedPrice = scientificToDecimal(input.currentLastBidPrice)
        this.state.precision = parseInt(input.precision)
        this.state.tickSize = parseInt(input.tickSize)

        this.setState({
            showLoading: false
        })
    }

    getPosSizeCurrency() {
        if (this.state.actionType === c.ACTION_TYPE_BUY) {
            if (this.state.tradeType === c.TRADE_TYPE_NEW_TRADE) {
                return this.state.base
            } else if (this.state.tradeType === c.TRADE_TYPE_OLD) {
                return this.state.quote
            }
        } else if (this.state.actionType === c.ACTION_TYPE_SELL) {
            if (this.state.tradeType === c.TRADE_TYPE_NEW_TRADE) {
                return this.state.quote
            } else if (this.state.tradeType === c.TRADE_TYPE_OLD) {
                return this.state.base
            }
        }
    }

    checkUserBalance() {
        this.props.checkUserBalance({currency: this.getPosSizeCurrency(), value: this.state.posSize})
    }

    previewNewExchange() {

        if (this.state.posSize <= 0) {
            return this.setState({
                otherPosSizeErrors: "Please correct value"
            })
        }
        if (this.state.amount <= 0) {
            return this.setState({
                otherAmountErrors: "Please correct value"
            })
        }

        if (!isValidPair(this.state.quote, this.state.base, this.props.quoteCurrenciesNom)) {
            return
        }

        this.state.posSizeCurrency = this.getPosSizeCurrency()
        this.props.clearErrors()

        if (this.state.actionType === c.ACTION_TYPE_BUY) {
            this.props.checkUserBalance({currency: this.state.posSizeCurrency, value: this.state.posSize}, result => {
                if (result.status === c.USER_HAS_BALANCE) {
                    this.props.previewNewExchange(this.state, ()=>{
                        this.props.history.push("/previewconversion")
                    })
                } else {
                    this.setState({
                        otherPosSizeErrors: "Insufficient balance"
                    })
                }
            })
        } else if (this.state.actionType === c.ACTION_TYPE_SELL) {
            this.props.checkUserBalance({currency: this.state.quote, value: this.state.amount}, result => {
                if (result.status === c.USER_HAS_BALANCE) {
                    //We need to pass the amount to the pos_size as this is how it is correct from the Trade perspective.
                    //And we are actually creating a new trade (under the mask of a new New Order) we need to set
                    //the parameters correctly.
                    this.state.posSize = this.state.amount
                    this.props.previewNewExchange(this.state, ()=>{
                        this.props.history.push("/previewconversion")
                    })
                } else {
                    this.setState({
                        otherAmountErrors: "Insufficient balance"
                    })
                }
            })
        }
    }

    switchWaitForADip() {
        this.setState({
            waitForADip: !this.state.waitForADip
        })
    }

    handleChangeWaitForADipPrice(e) {
        this.setState({
            waitForADipPrice: e.target.value
        })
    }

    selectMaxBaseBalance(maxBaseBalance) {
        if (this.state.actionType === c.ACTION_TYPE_BUY) {
            this.handleChangePosSize({target: {value: getValueWithPrecision(maxBaseBalance, c.BASE_PRECISIONS[this.state.base])}, force: true})
        }
    }

    selectMaxQuoteBalance(maxQuoteBalance) {
        if (this.state.actionType === c.ACTION_TYPE_SELL) {
            this.handleChangeAmount({target:{value: maxQuoteBalance}})
        }
    }

    componentDidMount() {
        if (!this.props.baseCurrenciesNom) {
            this.props.getCurrenciesMap()
        }

        this.props.getLastUsedParamsOnNewTrade({}, data => {
            this.setLastUsedParamsToState(data)
            this.props.getUserBaseBalance({currency: data.base})
            this.props.getUserQuoteBalance({currency: data.quote}, data => {
                if (this.state.tradeType === c.TRADE_TYPE_OLD) {
                    if (this.state.actionType === c.ACTION_TYPE_BUY) {
                        this.updateFixedPriceToAvgOldBuyPrice(this.state, {
                            avgBuyPriceBTCForQuoteOldTrade: data.avgBuyPriceBTC,
                            avgBuyPriceUSDTForQuoteOldTrade: data.avgBuyPriceUSDT,
                            avgBuyPriceETHForQuoteOldTrade: data.avgBuyPriceETH
                        })
                        this.setState(this.state)
                    }
                }
            })
            // this.props.getOrderBook({base: data.base, quote: data.quote})
            // this.getOrderBookPeriodically()
            this.props.user && initWS(this.props.user.userId, `${data.quote}${data.base}`)
            this.props.getCurrentPriceOnPair({quote: data.quote, base: data.base}, result => {
                let currentPrice = result.current_price
                let price = 0
                if (this.state.actionType === c.ACTION_TYPE_BUY)
                    price = currentPrice ? parseFloat(currentPrice) * (data.actionTypeOption === c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE ? c.LAST_BID_PRICE_MULTIPLIER : 1): 0
                else if (this.state.actionType === c.ACTION_TYPE_SELL)
                    price = currentPrice ? parseFloat(currentPrice) * (data.actionTypeOption === c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE ? c.FIRST_ASK_PRICE_MULTIPLIER : 1): 0

                if (this.state.tradeType === c.TRADE_TYPE_NEW_TRADE) {
                    this.state.fixedPrice = scientificToDecimal(getValueWithPrecision(price, this.state.tickSize))
                } else if (this.state.tradeType === c.TRADE_TYPE_OLD && this.props.userAvgBuyPriceForQuote) {
                    this.state.fixedPrice = this.props.userAvgBuyPriceForQuote
                }
                this.state.currentPrice = result.current_price
            })
        })

        this.props.clearErrors()
        this.props.clearNotEnoughBalance()

        this.props.getActiveSuspendedActivities(data => {
            this.state.ASAS = data
        })

    }

    componentWillUnmount() {
        closeWS()
    }

    render() {
        if (this.state.showLoading)
            return <div>
                <Helmet>
                    <title>{getTitle()}</title>
                </Helmet>
                <div className="loading">
                    <CircularIndeterminate/>
                </div>
                <div className={"loadingText"}>Getting current price...</div>
            </div>
        else return (
            <div className={"TradeContent"} style={{display: "flex"}}>
                <Helmet>
                    <title>{getTitle(`${this.state.quote}/${this.state.base}`, this.getCurrentPriceFromOrderBook())}</title>
                </Helmet>
                <div key={"theForm"}>
                    {(this.state.ASAS && this.state.ASAS.showWarningMessage === "true") &&
                    <div class={"platformInformationContainer"}>
                        <div><Info/></div>
                        <div style={{marginLeft: "0.6rem"}}>
                            {this.state.ASAS.warningMessage}
                        </div>
                    </div>}
                    {this.state.ASAS && this.state.ASAS.suspendActivities === "true" && this.state.ASAS.suspendedActivitiesMap[c.SUSPENDED_ACTIVITIES_NEW_CONVERSION_ID] &&
                    <div className={"maintenanceWarning"}>
                        <div><Warning/></div>
                        <div style={{marginLeft: "0.6rem"}}>
                            Starting new conversions is temporary disabled.
                        </div>
                    </div>}
                    <div className={"wizardTitle"}>
                        <span className={"wizardTitleIcon"}><CompareArrows/></span> Convert coins on <b>Binance</b>
                    </div>
                    <div className={"vertSpacer1_5"}/>
                    <div className={"horizSeparator"}/>
                    <div className={"vertSpacer1"}/>
                    <SelectPair handleChangeQuote={this.handleChangeQuote}
                                handleChangeBase={this.handleChangeBase}
                                quote={this.state.quote}
                                base={this.state.base}
                                quoteCurrenciesNom={this.props.quoteCurrenciesNom}
                                baseCurrenciesNom={this.props.baseCurrenciesNom}
                                userBaseBalance={this.props.userBaseBalance}
                                userQuoteBalance={this.props.userQuoteBalance}
                                actionType={this.state.actionType}
                                tradeType={this.state.tradeType}
                                selectMaxBaseBalance={this.selectMaxBaseBalance}
                                selectMaxQuoteBalance={this.selectMaxQuoteBalance}
                    />
                    <div className={"vertSpacer1_5"}/>
                    <div className={"horizSeparator"}/>
                    <div className={"vertSpacer1_5"}/>
                    <ExchangeControl actionType={this.state.actionType}
                                     handleChangeActionType={this.handleChangeActionType}
                                     quote={this.state.quote}
                                     base={this.state.base}
                                     actionTypeOption={this.state.actionTypeOption}
                                     handleChangeActionTypeOption={this.handleChangeActionTypeOption}
                                     fixedPrice={this.state.fixedPrice}
                                     fixedPriceError={this.state.fixedPriceError}
                                     handleChangeFixedPrice={this.handleChangeFixedPrice}
                                     posSize={this.state.posSize}
                                     handleChangePosSize={this.handleChangePosSize}
                                     otherPosSizeErrors={this.state.otherPosSizeErrors}
                                     amount={this.state.amount}
                                     handleChangeAmount={this.handleChangeAmount}
                                     otherAmountErrors={this.state.otherAmountErrors}
                    />
                    <div className={"vertSpacer1_5"}/>
                    <div className={"horizSeparator"}/>
                    <div className={"vertSpacer1"}/>
                    <NewConversionStep1Buttons preview={this.previewNewExchange} ASAS={this.state.ASAS}/>
                </div>
                <div key={"orderBook"} className={"OrderBook"}>
                    <OrderBook base={this.state.base} quote={this.state.quote} orderBook={this.props.orderBook} size={12}/>
                </div>
                <div className={"vertSpacer1_5"}/>
            </div>
        )
    }

}



let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    base: rootState.newTrade.lastUsed.base,
    quote: rootState.newTrade.lastUsed.quote,
    tradeType: rootState.newTrade.lastUsed.tradeType,
    actionType: rootState.newTrade.lastUsed.actionType,
    posSize: rootState.newTrade.lastUsed.posSize,
    actionTypeOption: rootState.newTrade.lastUsed.actionTypeOption,

    baseCurrenciesNom: rootState.newTrade.baseCurrenciesNom,
    quoteCurrenciesNom: rootState.newTrade.quoteCurrenciesNom,
    currentLastBidPrice: rootState.newTrade.currentLastBidPrice,

    notEnoughBalance: rootState.newTrade.notEnoughBalance,
    userBaseBalance: rootState.newTrade.userBaseBalance,
    userQuoteBalance: rootState.newTrade.userQuoteBalance,
    userQuotePrecision: rootState.newTrade.userQuotePrecision,

    // orderBook: rootState.newTrade.orderBook
    orderBook: rootState.orderBook.orderBook
})

let mapDispatchToProps = dispatch => {
    return {
        getCurrenciesMap: (props) => dispatch(getCurrenciesMap(props)),
        getLastUsedParamsOnNewTrade: (props, cb) => dispatch(getLastUsedParamsOnNewTrade(props, cb)),
        getCurrentPriceOnPair: (props, cb) => dispatch(getCurrentPriceOnPair(props, cb)),
        previewNewExchange: (props, cb) => dispatch(previewNewExchange(props, cb)),
        checkUserBalance: (props, cb) => dispatch(checkUserBalance(props, cb)),
        getUserBaseBalance: (props) => dispatch(getUserBaseBalance(props)),
        getUserQuoteBalance: (props, cb) => dispatch(getUserQuoteBalance(props, cb)),
        // getOrderBook: (props) => dispatch(getOrderBook(props)),

        clearErrors: () => dispatch(clearErrors()),
        clearNotEnoughBalance: () => dispatch(clearNotEnoughBalance()),

        getActiveSuspendedActivities: (cb) => dispatch(getActiveSuspendedActivities(cb))
    }
}
export default connect(mapStateToProps, mapDispatchToProps) (NewConversion)
