import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import s from '../../settings'
import c from '../../constants'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Slider from "@material-ui/core/Slider"
import InputAdornment from "@material-ui/core/InputAdornment"
import {ReactComponent as FuturesWalletIcon} from "../../svgs/futuresWallet.svg"
import {addCommasToLeftSideOfTheNumber} from "./UISupportFns";
import {Info} from "@material-ui/icons";
import {Link as RouterLink} from "react-router-dom";
import Fab from "@material-ui/core/Fab";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    sliderContainer: {
        marginTop: 10,
        width: 300,
    },
    leverageInput: {
        width: 76
    },
    fabCancel: {
        margin: theme.spacing(1),
        width: 100,
        height: 30,
        background: "#101926",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "white",
        '&:hover': {
            background: "#424242"
        }
    },

    fabConfirm: {
        margin: theme.spacing(1),
        width: 100,
        height: 30,
        background: "#3BADFF",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "black",
        '&:hover': {
            background: "#424242"
        }
    },
}))

export default function SetAccountLeverageFutures(props) {
    const {leverage, handleChangeLeverage,
        maxLeverage,
        maxNotionalValue,
        initialMarginRate,
        leverageSlider, handleChangeLeverageSlider,
        increaseLeverage, decreaseLeverage, quote, base,
        isEditable,
        adjustLeverage, cancelAdjustLeverage, confirmNewLeverage, noOfRunningTrades} = props
    const classes = useStyles()

    function getMarks(maxLeverage) {
        if (maxLeverage === 125)
            return c.marks125
        else if (maxLeverage === 75)
            return c.marks75
        else if (maxLeverage === 50)
            return c.marks50
        else if (maxLeverage === 25)
            return c.marks25
        else if (maxLeverage === 20)
            return c.marks20
        return c.marks25
    }

    function valueLabelFormat(value) {
        return `${value}X`
    }

    //Just showing the Leverage on this Symbol
    if (!isEditable)
        return (
            <div className={"wizardSectionContainer"}>
                <div className={"wizardTitle"} style={{display: "flex", textAlign: "center"}}>
                    <div style={{display: "flex", textAlign: "center", paddingTop:"0.3rem"}}><span className={"wizardTitleIcon"}><FuturesWalletIcon/></span> <span style={{marginLeft: "0.5rem"}}>Leverage</span></div>
                    {leverage && <div className={"leverageButton"} onClick={adjustLeverage}>{leverage}X</div>}
                    {!leverage && <div className={"leverageButton"}>...</div>}
                </div>
            </div>
        )
    //Showing the editable mode
    if (!noOfRunningTrades || noOfRunningTrades === 0)
        return (
            <div className={"wizardSectionContainer"}>
                <div className={"wizardTitle"}>
                    <span className={"wizardTitleIcon"}><FuturesWalletIcon/></span> <span style={{marginLeft: "0.3rem"}}>{quote}{base} leverage</span>
                </div>
                <div style={{marginLeft: "0.6em"}}>
                    <div style={{display: "flex-start", justifyContent: "space-between"}}>
                        <Button variant="contained" onClick={decreaseLeverage} className={classes.button} style={{marginTop: "0.5rem"}}>-</Button>
                        <TextField id="outlined-basic" className={classes.leverageInput} style={{marginLeft: "1rem"}}
                                   inputProps={{style: { textAlign: 'right' }}}
                                   variant="outlined" value={leverage} onChange={handleChangeLeverage}
                                   InputProps={{
                                       endAdornment: <InputAdornment position="end">X</InputAdornment>,
                                   }}
                        />
                        <Button variant="contained" onClick={increaseLeverage} className={classes.button} style={{marginLeft: "1rem", marginTop: "0.5rem"}}>+</Button>
                    </div>
                    <div className={classes.sliderContainer}>
                        <Slider
                            defaultValue={20}
                            valueLabelFormat={valueLabelFormat}
                            aria-labelledby="discrete-slider-restrict"
                            step={1}
                            value={leverageSlider}
                            onChange={handleChangeLeverageSlider}
                            valueLabelDisplay="auto"
                            marks={getMarks(maxLeverage)}
                            min={1}
                            max={maxLeverage}
                        />
                    </div>
                    {maxNotionalValue &&
                        <div style={{opacity: 0.5}}>
                        <div>Maximum notional value at current leverage is <span style={{color: "#3BADFF", fontWeight: "bold"}}>{addCommasToLeftSideOfTheNumber(maxNotionalValue)}</span> {base}.</div>
                        <div className={"smallExplanations2"}>Initial Margin rate is {initialMarginRate}%</div>
                        </div>
                    }
                    {leverage > 20 && <div style={{marginTop: "0.6rem", fontSize: "0.8rem", opacity: "0.8", color: "#FE484F",
                            display: "flex", alignItems: "center", justifyContent: "flex-start"}}><Info/> <span style={{marginLeft: "0.2rem"}}>Your leverage is high, please manage your risk accordingly.</span></div>}
                </div>
                <div style={{marginTop: "0.6rem"}}>
                    <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabCancel} onClick={cancelAdjustLeverage}>
                            Cancel&nbsp;&nbsp;
                    </Fab>
                    <Fab variant="extended" color="default" aria-label="Confirm" className={classes.fabConfirm} onClick={confirmNewLeverage}>
                        Confirm&nbsp;&nbsp;
                    </Fab>
                </div>
            </div>)
    //Showing the leverage on this symbol with the explanations that you can not edit the leverage
    //while having at least one running trade on that symbol
    let q = setTimeout(() => {
        cancelAdjustLeverage()
        q = undefined
    }, 60000)
    return (
        <div className={"wizardSectionContainer"}>
            <div className={"wizardTitle"} style={{display: "flex", textAlign: "center"}}>
                <div style={{display: "flex", textAlign: "center", paddingTop:"0.3rem"}}><span className={"wizardTitleIcon"}><FuturesWalletIcon/></span> <span style={{marginLeft: "0.5rem"}}>Leverage</span></div>
                {leverage && <div className={"leverageButton"}>{leverage}X</div>}
            </div>
            <div style={{marginTop: "0.6rem", marginBottom: "1rem", fontSize: "0.8rem", opacity: "0.8", width: "22rem"}}>
                <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                    <Info/>
                    <span style={{marginLeft: "0.2rem"}}>
                            <div style={{textAlign: "justify"}}>Leverage cannot be changed.<br/>You have  <b>{noOfRunningTrades}</b> running {quote}{base} trades.</div>
                    </span>
                </div>
            </div>
        </div>)
}
