import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import { connect } from 'react-redux'
import {ddd239} from '../../redux/actions/authentication'
import {Helmet} from "react-helmet/es/Helmet";
import {getTitle} from "../fncomponents/PageTitleUtils";
import CircularIndeterminate from "../fncomponents/loading";
const qs = require("query-string")

class DDD239 extends Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        let token = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).q
        this.props.ddd239(token, (err, data) => {
            if (err) {
                this.setState({
                    error: true
                })
            } else {
                this.setState({
                    ok: true
                })
            }
        })
    }

    render() {
        if (this.state.error) {
            return <Redirect to={"/login"}/>
        } else if (this.state.ok){
            return <Redirect to={"/"}/>
        } else {
            return <div>
                <Helmet>
                    <title>{getTitle()}</title>
                </Helmet>
                <div className="loading">
                    <CircularIndeterminate/>
                </div>
                <div className={"loadingText"}>Loading...</div>
            </div>
        }

    }
}

const mapStateToProps = rootState => ({
})

const mapDispatchToProps =  dispatch => {
    return {
        ddd239: (token) => {dispatch(ddd239(token))},
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DDD239)
