import React from 'react'
import { ReactComponent as UpTrendGreen } from '../../svgs/up_trend_green.svg'
import { ReactComponent as DownTrendRed } from '../../svgs/down_trend_red.svg'
import { ReactComponent as InlineTrendWhite } from '../../svgs/inline_trend_white.svg'
import c from '../../constants'

import {
    getQuoteAndBase,
    getCurrentBuyAndSellPrice,
    getCurrentPriceAndDeltaPercent,
    getTradeState,
    brushValue,
    timeFormatter2,
    timeFormatter, getMaxMinAndDeltaPercent, getProgressForWaitingTrade
} from "./UISupportFns";

import {CompareArrows} from "@material-ui/icons";

export default function WaitingOrder(props) {
    const {trade, openRecord} = props
    const ACTION_TYPE = trade.action_type
    const {quote, base} = getQuoteAndBase(trade)
    const {currentPrice, buyPrice, sellPrice} = getCurrentBuyAndSellPrice(trade)
    const progress = getProgressForWaitingTrade(trade)
    const {trend, currentPriceDeltaPercent, currentPriceClass} = getCurrentPriceAndDeltaPercent(trade)
    const posSize = brushValue(trade.pos_size)
    const origQty = brushValue(trade.orig_qty)
    const tradeState = getTradeState(trade)
    const {maxPrice, maxDelta, minPrice, minDelta} = getMaxMinAndDeltaPercent(trade)
    const cummulativeQuoteQty = trade.cummulative_quote_qty
    return (
        <div className={"tradeContainer"} onClick={openRecord}>
            <div className={"tradeSubContainerLeft"}>
                <div style={{display: "flex", justifyContent: "space-between", width: "9em"}}>
                    <div>
                        <span style={{fontSize: "1.2em", fontWeight: "bold"}}>{quote}</span>
                        <span style={{fontSize: "1em"}}> / {base}</span>
                    </div>
                    <div style={{top: "0.8rem"}}>
                        {trend === 1 && <UpTrendGreen/>}
                        {trend === 0 && <InlineTrendWhite/>}
                        {trend === -1 && <DownTrendRed/>}
                    </div>
                </div>
                {ACTION_TYPE === c.ACTION_TYPE_BUY && <div className={"activeTradePosAndQtyStart"} title={"What are you trading"}>
                    <small>Buying <b>{origQty} {quote}</b> </small> with <b>{posSize} {base}</b></div>}
                {ACTION_TYPE === c.ACTION_TYPE_SELL && <div className={"activeTradePosAndQtyStart"} title={"What are you trading"}>
                    <small>Selling <b>{origQty} {quote}</b></small> for <b>{cummulativeQuoteQty} {base}</b></div>}
                <div>
                    <div className={"w3-progress-sizer"}>
                        <div className="w3-progress-container w3-round-xlarge">
                            <div className="w3-progressbar w3-round-xlarge" style={{width:`${progress}%`}}/>
                        </div>
                    </div>
                    <div className={"progressBarText"}>{progress}% FILLED</div>
                    {(ACTION_TYPE === c.ACTION_TYPE_BUY && trade.status === "CANCELED") && <div className={"activeTradePosAndQtyStart"}>
                        <small>({trade.executed_qty} {quote} bought with {trade.cummulative_quote_qty} {base})</small>
                    </div>}
                    {(ACTION_TYPE === c.ACTION_TYPE_SELL && trade.status === "CANCELED") && <div className={"activeTradePosAndQtyStart"}>
                        <small>({trade.executed_qty} {quote} sold for {trade.cummulative_quote_qty} {base})</small>
                    </div>}
                </div>
                <div>
                    <div><CompareArrows style={{color: "#64FE7C", fontSize: "42"}} /></div>
                    <div style={{fontSize: "0.8rem", marginTop: "-0.8rem", color: "#64FE7C"}}>ORDER</div>
                </div>
            </div>
            {(tradeState === c.TRADE_STATE_PARENT_ONGOING || tradeState === c.TRADE_STATE_PARENT_PARTIALLY_FILLED) &&
            <div className={"tradeSubContainerRight"}>
                <div className={"currentPriceStatsContainer"} title={"Current price"}>
                    <div className={currentPriceClass}>{currentPriceDeltaPercent}%</div>
                    <span className={"horizSpacer"}/>
                    <div className={currentPriceClass}>{currentPrice}</div>
                </div>
                {ACTION_TYPE === c.ACTION_TYPE_BUY && <div className={"activeTradePosAndQtyEnd"}><span className={"buy"}>buy</span>  price <b>{buyPrice}</b></div>}
                {ACTION_TYPE === c.ACTION_TYPE_SELL && <div className={"activeTradePosAndQtyEnd"}><span className={"sell"}>sell</span>  price <b>{sellPrice}</b></div>}
                {maxPrice !== "0" && <div className={"maxPriceContainer"} title={"Max price while waiting"}>
                    {maxPrice} ({maxDelta}%)
                </div>}
                {minPrice !== "0" && <div className={"minPriceContainer"} title={"Min price while waiting"}>
                    {minPrice} ({minDelta}%)
                </div>}
            </div>}
            {(tradeState === c.TRADE_STATE_PARENT_CANCELED ||
                tradeState === c.TRADE_STATE_PARENT_IN_ERROR ||
                tradeState === c.TRADE_STATE_WITH_CHILD_IN_ERROR ||
                tradeState === c.TRADE_STATE_WITH_CHILD_FILLED) &&
            <div className={"tradeSubContainerRight"}>
                <div className={"currentPriceStatsContainer"} title={"Current price"}>
                    <div className={currentPriceClass}>{currentPriceDeltaPercent}%</div>
                    <span className={"horizSpacer"}/>
                    <div className={currentPriceClass}>{currentPrice}</div>
                </div>
                {tradeState === c.TRADE_STATE_PARENT_CANCELED &&
                <div className={"currentPriceStatsContainer"}>
                    <span style={{color: " #E8E288"}}>Canceled</span>
                </div>
                }
                {(tradeState === c.TRADE_STATE_PARENT_IN_ERROR || tradeState === c.TRADE_STATE_WITH_CHILD_IN_ERROR) &&
                <div className={"currentPriceStatsContainer"}>
                    <span style={{color: "#FB4A4A"}}>Error</span>
                </div>
                }
            </div>}
            <div style={{display:"flex", justifyContent:"center", width: "100%"}}>
                <div className={"buyPriceContainer"}>
                    {trade.status === c.ORDER_STATUS_FILLED &&
                    <div>
                        {ACTION_TYPE === c.ACTION_TYPE_BUY && <span>Filled at {buyPrice}</span>}
                        {ACTION_TYPE === c.ACTION_TYPE_SELL && <span>Filled at {sellPrice}</span>}
                    </div>}
                    {trade.status === c.ORDER_STATUS_CANCELED && <div>Canceled on {timeFormatter2(trade.last_update_time, true)}</div>}
                </div>
                {(trade.status === c.ORDER_STATUS_NEW || trade.status === c.ORDER_STATUS_PARTIALLY_FILLED) &&
                <div>
                    <div className={"hrState"}>
                        <span className="blink live" style={{color: "#68FF7D"}}>▶</span>
                    </div>
                </div>}
            </div>
            <div style={{display:"flex", justifyContent:"space-between", width: "100%"}} className={"recordId"}>
                <span>{trade.last_order_id}
                    {trade.from_btd && trade.from_btd !== "undefined" && (<span title={`from BTD ${trade.from_btd}`}>&nbsp;&nbsp;(<b>from a BTD</b>)</span>)}
                    {trade.from_SUC && trade.from_SUC !== "undefined" && (<span title={`from Signal ${trade.signal_id}`}>&nbsp;&nbsp;(<b>{trade.signal_id}</b>)</span>)}
                    {trade.from_BOT && trade.from_BOT !== "undefined" && (<span title={`from Bot ${trade.bot_id}`}>&nbsp;&nbsp;(<b>{trade.bot_id}</b>)</span>)}</span>
                <span>{timeFormatter(trade.time, true)}</span>
            </div>
        </div>)
}
