import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";

const CustomSwitch = withStyles({
    switchBase: {
        '&$checked': {
            color: "#17BEBB",
        },
        '&$checked + $track': {
            backgroundColor: "#17BEB0",
        },
    },
    checked: {},
    track: {},
})(Switch)

export default CustomSwitch
