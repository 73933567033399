import React, { useState } from "react";
import { Modal, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { generateNewEthAddress, getBalances } from "../../redux/actions/wallet";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 340,
    backgroundColor: "#101926",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    float: "left",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const getAddressType = (coin) => {
  if (coin === "BTC") {
    return "BTC"
  }

  if (coin === "USDT" || coin === "ETH") {
    return "ERC20"
  }
}

const DepositModal = ({ open, handleClose, currency }) => {

  const [openPopover, setOpenPopover] = useState(false);
  const [openPopover2, setOpenPopover2] = useState(false);

  const dispatch = useDispatch();

  const classes = useStyles();
  const { coin, name, depositAddress, depositAddressTRC20 } = currency;

  return (
    <div>
      <Modal open={open} onClose={handleClose} onClick={(e) => e.stopPropagation()}>
        <div className={classes.paper}>
          <div className="deposit-modal__title">
            Deposit
            <span className="deposit-modal__dot"> · </span>
            <span>{coin}</span>
            <span className="deposit-modal__dot"> · </span>
            <span>{name}</span>
          </div>

          <div className="deposit-modal__text-bold">
            Use the addresses below to make a {coin} deposit
          </div>

          <div className="deposit-modal__text">

            {coin === "USDT" && <div>Send only <b>{coin}</b> to these deposit addresses.</div>}
            {coin !== "USDT" && <div>Send only <b>{coin}</b> to this deposit address.</div>}
            <div>Sending anything else other than <b>{coin}</b> may result in the loss of your deposit.</div>
          </div>
          <div className="deposit-modal__text_smaller" style={{marginTop: "0.6rem", fontStyle: "italic"}}>
            <div>Click on address to copy it.</div>
          </div>
          {(coin === "ETH") && <div className={"deposit-modal__type"}>ERC20 Address</div>}
          {(coin !== "TRX" && coin !== "USDT") && <div className="deposit-modal__hash" style={{backgroundColor: openPopover ? "cadetblue" : " rgba(255, 255, 255, 0.12)"}}>
           <div className="deposit-modal__hash__text" onClick={async (e) => {
             if (!depositAddress)
               return
             // const queryOpts = { name: 'clipboard-read', allowWithoutGesture: false };
             // const permissionStatus = await navigator.permissions.query(queryOpts);
             // if(permissionStatus.state === "granted" || permissionStatus.state === "prompt"){
             try {
               navigator.clipboard.writeText(depositAddress ? depositAddress : "");
             } catch (err) {
               console.log("Failed to copy", err);
             }
             // }
             setOpenPopover(true);
             setTimeout(() => handleClose(), 2000);
           }}>
             {depositAddress ? depositAddress : `no ${getAddressType(coin)} address`}
           </div>
         </div>}
           {openPopover && <div className="deposit-modal__popover" >
             <div style={{width: "7rem", margin: "auto", opacity: "0.9"}}>{`${getAddressType(coin)} address copied`}</div>
        </div>}
        {(coin === "TRX" || coin === "USDT") && <div className={"deposit-modal__type"}>TRC20 Address</div>}
        {(coin === "TRX" || coin === "USDT") && <div className="deposit-modal__hash" style={{backgroundColor: openPopover2 ? "cadetblue" : " rgba(255, 255, 255, 0.12)"}}>
            <div className="deposit-modal__hash__text" onClick={async (e) => {
              if (!depositAddressTRC20)
                return
              // const queryOpts = { name: 'clipboard-read', allowWithoutGesture: false };
              // const permissionStatus = await navigator.permissions.query(queryOpts);
              // if(permissionStatus.state === "granted" || permissionStatus.state === "prompt"){
              try {
                navigator.clipboard.writeText(depositAddressTRC20 ? depositAddressTRC20: "");
              } catch (err) {
                console.log("Failed to copy", err);
              }
              // }
              setOpenPopover2(true);
              setTimeout(() => handleClose(), 2000);
            }}>
              {depositAddressTRC20 ? depositAddressTRC20 : "no TRC20 address"}
            </div>
          </div>}
          {openPopover2 && <div className="deposit-modal__popover" >
            <div style={{width: "7rem", margin: "auto", opacity: "0.9"}}>TRC20 address copied</div>
          </div>}

          {/*{depositAddress ? <Button*/}
          {/*  style={{*/}
          {/*    backgroundColor: "#3BADFF",*/}
          {/*    display: "flex",*/}
          {/*    justifyContent: "space-evenly",*/}
          {/*    width: "240px",*/}
          {/*    margin: "54px auto 20px auto",*/}
          {/*    borderRadius: "32px",*/}
          {/*    height: "48px",*/}
          {/*    textAlign: "center",*/}
          {/*    color: "#080F18",*/}
          {/*  }}*/}
          {/*  onClick={async (e) => {*/}

          {/*      // const queryOpts = { name: 'clipboard-read', allowWithoutGesture: false };*/}
          {/*      // const permissionStatus = await navigator.permissions.query(queryOpts);*/}
          {/*      // if(permissionStatus.state === "granted" || permissionStatus.state === "prompt"){*/}
          {/*        try {*/}
          {/*          navigator.clipboard.writeText(depositAddress);*/}
          {/*        } catch (err) {*/}
          {/*          console.log("Failed to copy", err);*/}
          {/*        }*/}
          {/*      // }*/}
          {/*      setOpenPopover(true);*/}
          {/*      setTimeout(() => handleClose(), 2000);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <FileCopyOutlinedIcon />*/}
          {/*  COPY & CLOSE*/}
          {/*</Button> : <Button*/}
          {/*  style={{*/}
          {/*    backgroundColor: "#3BADFF",*/}
          {/*    display: "flex",*/}
          {/*    justifyContent: "space-evenly",*/}
          {/*    width: "240px",*/}
          {/*    margin: "54px auto 20px auto",*/}
          {/*    borderRadius: "32px",*/}
          {/*    height: "48px",*/}
          {/*    textAlign: "center",*/}
          {/*    color: "#080F18",*/}
          {/*  }}onClick={()=> {*/}
          {/*  generateNewEthAddress( () => */}
          {/*    getBalances()(dispatch)*/}
          {/*  )();*/}
          {/*  setTimeout(() => handleClose(), 2000);*/}
          {/*}}>Generate new address!</Button>}*/}
        </div>
      </Modal>
    </div>
  );
};

export default DepositModal;
