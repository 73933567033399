import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import {Edit, Close} from "@material-ui/icons";
import {TextField, Button} from "@material-ui/core";
import SmallButton from "../../fncomponents/SmallButton";
import Avatar from 'react-avatar-edit'

const useStyles = makeStyles(theme => ({
    textField: {
        width: 400,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        backgroundColor: '#101A26'
    }
}))


export default function EditableShortDescriptionSection(props) {
    const {
        trader,
        shortDescription,
        editable,
        switchOnEditable,
        switchOffEditable,
        handleChangeShortDescription,
        handleSave,
        handleCancel} = props
    const classes = useStyles()

    if (editable)
        return (<div>
            <div className={"traderSalesPageEditableSectionContainer"} style={{width: "50rem"}}>
                <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}} title={"Close edit mode"}>
                    <Close className={"smallMenuIcon"} onClick={switchOffEditable}/>
                </div>
                <div className={"traderSalesPageAbout"} style={{width: "100%"}}>
                    <TextField
                        value={shortDescription}
                        onChange={handleChangeShortDescription}
                        className={classes.textField}
                        helperText={"max 100 chars"}
                        maxLength="100"
                        multiline
                        rows={2}
                        rowsMax={2}
                    />
                </div>
                <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
                    <SmallButton label={"Save"} variant="outlined" onClick={handleSave}/>
                    <SmallButton label={"Cancel"} variant="outlined" onClick={handleCancel}/>
                </div>
            </div>
        </div>)
    else return (<div>
        <div className={"traderSalesPageEditableSectionContainer"} style={{width: "50rem"}}>
            <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}} title={"Open edit mode"}>
                <Edit className={"smallMenuIcon"} onClick={switchOnEditable}/>
            </div>
            <div className={"traderSalesPageAbout"}>
                <div style={{fontSize: "1.2rem"}}>{trader.shortDescription}</div>
            </div>
        </div>
    </div>)
}
