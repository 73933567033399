import React from 'react'
import SwapVert from '@material-ui/icons/SwapVert'
import c from '../../constants'
import {FormControlLabel, RadioGroup} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";

export default function SelectFuturesTypeRadios({futuresType, actionTypeErr, handleChangeFuturesType, componentLabel="Futures type", signals}) {
    const [selectedIndex, setSelectedIndex] = React.useState(-1)

    React.useEffect(() => {

        if (futuresType === c.FUTURES_TYPE_LONG)
            setSelectedIndex(0)
        else if (futuresType === c.FUTURES_TYPE_SHORT)
            setSelectedIndex(1)
        else
            setSelectedIndex(2)
    })

    return (
        <div className={"wizardSectionContainer"}>
            <div className={"wizardTitle"}>
                <span className={"wizardTitleIcon"}><SwapVert/></span> {componentLabel}
            </div>
            <div style={{marginLeft: "0.6em"}}>
                <div id="buyOrSellRadioGroup" style={{marginLeft: "0.6rem"}}>
                    <RadioGroup row>
                        <FormControlLabel value={c.FUTURES_TYPE_LONG} onChange={handleChangeFuturesType}
                                          control={<Radio color="primary" style={{color: `${futuresType === c.FUTURES_TYPE_LONG ? "#3BADFF": "white"}`}}/>} label={`LONG`} checked={futuresType === c.FUTURES_TYPE_LONG}
                                          style={{color: `${futuresType === c.FUTURES_TYPE_LONG ? "#3BADFF": "white"}`}}/>
                        <FormControlLabel value={c.FUTURES_TYPE_SHORT} onChange={handleChangeFuturesType}
                                          control={<Radio color="primary" style={{color: `${futuresType === c.FUTURES_TYPE_SHORT ? "#FE484F": "white"}`}}/>} label={`SHORT`} checked={futuresType === c.FUTURES_TYPE_SHORT}
                                          style={{color: `${futuresType === c.FUTURES_TYPE_SHORT ? "#FE484F": "white"}`}}
                        />
                    </RadioGroup>
                </div>
            </div>
            {selectedIndex === 0 && <div className={"smallExplanationsSelectionActionType"}>
                {!signals && <div>Open a Long futures position</div>}
                {signals && <div>Create a Long futures signal</div>}
            </div>}
            {selectedIndex === 1 && <div className={"smallExplanationsSelectionActionType"}>
                {!signals && <div>Open a Short futures position</div>}
                {signals && <div>Create a Short futures signal</div>}
            </div>}
            {actionTypeErr && <div className={"errorContainer"} style={{marginLeft: "0.6rem"}}>{actionTypeErr}</div>}
        </div>
    )
}
