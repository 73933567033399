import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

export default function     SimplePopover(props) {
    const classes = useStyles();
    const {open, content, handleClose, anchorEl} = props

    const _handleClose = () => {
        if (handleClose) handleClose()
    };

    const id = open ? 'simple-popover' : undefined;

    return (
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={_handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography className={classes.typography}>{content}</Typography>
            </Popover>
    );
}
