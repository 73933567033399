import axios from "./axiosFabric"
import {isAuthorizationHeaderPresent} from "./authentication";
import {handleError} from "./errorHandler";
import {
    INVEST,
    INVESTMENT_CHANGE_TAB,
    INVESTMENT_GET_MY_INVESTMENT_PURCHASES, INVESTMENT_GET_MY_REFERRAL_INVESTMENT_PURCHASES,
    INVESTMENT_PROGRAMS_GET_ACTIVE, INVESTMENT_PROGRAMS_GET_CLOSED
} from "./actionTypes";


export const changeTab = tab => dispatch => {
    dispatch({
        type: INVESTMENT_CHANGE_TAB,
        payload: tab
    })
}

export const getActiveInvestments = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/inv/getActive`, props)
        .then(res => {
            if (cb) cb(res.data)
            if (!props.doNotDispatch) {
                dispatch({
                    type: INVESTMENT_PROGRAMS_GET_ACTIVE,
                    payload: res.data
                })
            }
        }).catch(error => handleError(error, dispatch))
}

export const getClosedInvestments = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/inv/getClosed`, props)
        .then(res => {
            if (cb) cb(res.data)
            dispatch({
                type: INVESTMENT_PROGRAMS_GET_CLOSED,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const invest = (props, cb) => (dispatch) => {
    if (!isAuthorizationHeaderPresent(axios)) return;
    axios.post(`/inv/invest`, props)
        .then((res) => {
            if (cb) cb(res.data);
            dispatch({
                type: INVEST,
                payload: res.data,
            });
        })
        .catch((error) => handleError(error, dispatch));
}

export const getSaleProgress = (props, cb) => (dispatch) => {
    if (!isAuthorizationHeaderPresent(axios)) return;
    axios.post(`/inv/getSaleProgress`, props)
        .then((res) => {
            if (cb) cb(res.data)
        })
        .catch((error) => handleError(error, dispatch));
}

export const getMyInvestmentPurchases = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/inv/getmyinvestments`, props)
        .then(res => {
            if (cb) cb(res.data)
            dispatch({
                type: INVESTMENT_GET_MY_INVESTMENT_PURCHASES,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getMyReferralInvestmentPurchases = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/inv/getmyreferralinvestments`, props)
        .then(res => {
            if (cb) cb(res.data)
            dispatch({
                type: INVESTMENT_GET_MY_REFERRAL_INVESTMENT_PURCHASES,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getInvestmentProgramReferralLinkDetails = (props, cb) => dispatch => {
    // if (!isAuthorizationHeaderPresent(axios))
    //     return
    axios.post(`/inv/getreferrallinkdetails`, props)
        .then(res => {
            if (cb) cb(res.data)
        }).catch(error => handleError(error, dispatch))
}

export const getInvestmentTermsAndCond = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/inv/gettc`, props)
        .then(res => {
            if (cb) cb(res.data)
        }).catch(error => handleError(error, dispatch))
}