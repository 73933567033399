import c from '../../constants'
import {
    INVESTMENT_CHANGE_TAB, INVESTMENT_GET_MY_INVESTMENT_PURCHASES,
    INVESTMENT_GET_MY_REFERRAL_INVESTMENT_PURCHASES,
    INVESTMENT_PROGRAMS_GET_ACTIVE, INVESTMENT_PROGRAMS_GET_CLOSED,
} from "../actions/actionTypes"

export default function (state = {selectedTab: c.INVESTMENT_ACTIVE_INVESTMENT_TAB,}, action) {
    switch (action.type) {

        case INVESTMENT_CHANGE_TAB: {
            return {
                ...state,
                selectedTab: action.payload
            }
        }

        case INVESTMENT_PROGRAMS_GET_ACTIVE: {
            return {
                ...state,
                activeInvPs: action.payload
            }
        }

        case INVESTMENT_PROGRAMS_GET_CLOSED: {
            return {
                ...state,
                closedInvPs: action.payload
            }
        }

        case INVESTMENT_GET_MY_INVESTMENT_PURCHASES: {
            return {
                ...state,
                myInvestmentPurchases: action.payload
            }
        }

        case INVESTMENT_GET_MY_REFERRAL_INVESTMENT_PURCHASES: {
            return {
                ...state,
                myReferralInvestmentPurchases: action.payload
            }
        }


        default:
            return state
    }
}
