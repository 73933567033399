import axios from './axiosFabric'
import {handleAuthenticationError, handleError} from "./errorHandler";
import jwt_decode from 'jwt-decode';
import {clearErrors} from "./errors";
import {closeWS as closePricesWS} from "./wsPrices";
import {closeWS as closeOrderBookWS} from "./wsOrderBook";

import {
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_ERROR, CLEAR_SUCS_STATE, CLEAR_TRADES_STATE,
    CONFIRM_SIGNUP, LATE_ACTIVATION,
    LOGIN,
    LOGOUT, REGISTER_WHA_LINK,
    RESET_PASSWORD,
    RESET_PASSWORD_ERROR,
    SIGNUP, UPDATE_USER_BASED_ON_JWT_TOKEN
} from './actionTypes'
import {isJWTActive} from "../../components/hoc/withAuth";
import {getJWT, removeJWT, removeJWTFromAxios, removeRJWT, saveJWT, saveJWTOnAxios, saveRJWT} from "./utils/tokenUtils";


export const logIn = (credentials) => dispatch => {
    axios.post(`/auth/login`, credentials)
        .then(res => {
            const { token, refreshToken } = res.data
            saveJWT(token)
            saveJWTOnAxios(token, axios)
            saveRJWT(refreshToken)
            dispatch(clearErrors())
            dispatch({
                type: LOGIN,
                payload: jwt_decode(token)
            })
        }).catch(error => handleAuthenticationError(error, dispatch))
}

export const twoFAAuth = (credentials) => dispatch => {
    axios.post(`/auth/2fa`, credentials)
        .then(res => {
            const { token, refreshToken } = res.data
            saveJWT(token)
            saveJWTOnAxios(token, axios)
            saveRJWT(refreshToken)
            dispatch(clearErrors())
            dispatch({
                type: LOGIN,
                payload: jwt_decode(token)
            })
        }).catch(error => handleAuthenticationError(error, dispatch))
}

export const logOut = () => dispatch => {
    removeJWT()
    removeRJWT()
    removeJWTFromAxios()
    closePricesWS()
    closeOrderBookWS()
    dispatch(clearErrors())
    dispatch({
        type: CLEAR_TRADES_STATE
    })
    dispatch({
        type: CLEAR_SUCS_STATE
    })
    dispatch({
        type: LOGOUT
    })
}

export const updateAuthStatePerJWT = (cb) => dispatch => {
    let token = getJWT()
    if (token) {
        saveJWTOnAxios(token, axios)
        axios.post("/auth/getusersubscriptions").then(res => {
            if (cb) cb()
            try{
                dispatch({
                    type: UPDATE_USER_BASED_ON_JWT_TOKEN,
                    payload: {decodedToken: jwt_decode(token), subscriptions: res.data}
                })
            } catch(err) {
                dispatch(logOut())
            }
        })
    } else {
        if (cb) cb()
        dispatch(logOut())
    }
}

export const checkJWT = () => dispatch => {
    if (!isJWTActive())
        dispatch(logOut())
}

export const signUp = (user) => dispatch => {
    axios.post(`/auth/signUp`, user)
        .then(res => {
            dispatch(clearErrors())
            dispatch({
                type: SIGNUP
            })
        }).catch(error => handleError(error, dispatch))
}

export const confirmSignup = (token) => dispatch => {
    axios.post(`/auth/confirmSignup/${token}`)
        .then(res => {
            dispatch(clearErrors())
            dispatch({
                type: CONFIRM_SIGNUP
            })
        }).catch(error => handleError(error, dispatch))
}

export const lateActivation = (userId) => dispatch => {
    axios.post(`/auth/activatepending/${userId}`)
        .then(res => {
            dispatch(clearErrors())
            dispatch({
                type: LATE_ACTIVATION,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const resetPassword = (email, cb) => dispatch => {
    axios.post(`/auth/resetpassword`, {email: email})
        .then(res => {
            if (cb) cb(res.data.emailProviderUrl)
            dispatch(clearErrors())
            dispatch({
                type: CHANGE_PASSWORD
            })
    }).catch(error => handleError(error, dispatch, RESET_PASSWORD_ERROR))
}

export const changePassword = (props, cb) => dispatch => {
    axios.post(`/auth/changepassword`, props)
        .then(res => {
            if (cb) cb()
            dispatch(clearErrors())
            dispatch({
                type: RESET_PASSWORD
            })
        }).catch(error => handleError(error, dispatch,CHANGE_PASSWORD_ERROR))
}

export const changePassword2 = (props, cb) => dispatch => {
    axios.post(`/auth/changepassword2`, props)
        .then(res => {
            if (cb) cb()
            dispatch(clearErrors())
        }).catch(error => handleError(error, dispatch,CHANGE_PASSWORD_ERROR))
}

export const isAuthorizationHeaderPresent = axios => {
    if (axios.defaults.headers.common['Authorization'])
        return true
    return false
}

export const isUserAdmin = user => {
    if (user && user.permissions && user.permissions.length > 0) {
        for(let i in user.permissions) {
            if (user.permissions[i] === "admin")
                return true
            if (user.permissions[i] === "superAdmin")
                return true

        }
    }
    return false
}

export const isUserSuperAdmin = user => {
    if (user && user.permissions && user.permissions.length > 0) {
        for(let i in user.permissions) {
            if (user.permissions[i] === "superAdmin")
                return true
        }
    }
    return false
}

export const isUserSignalsManager = user => {
    if (user && user.permissions && user.permissions.length > 0) {
        for(let i in user.permissions) {
            if (user.permissions[i] === "signalsManager")
                return true
        }
    }
    return false
}

export const isTrader = user => {
    if (user && user.permissions && user.permissions.length > 0) {
        for(let i in user.permissions) {
            if (user.permissions[i] === "trader")
                return true
        }
    }
    return false
}

export const ddd239 = (token) => dispatch => {
    axios.post(`/auth/ddd239red39ie/${token}`)
        .then(res => {
            const { token, refreshToken } = res.data
            saveJWT(token)
            saveJWTOnAxios(token, axios)
            saveRJWT(refreshToken)
            dispatch(clearErrors())
            dispatch({
                type: LOGIN,
                payload: jwt_decode(token)
            })
        }).catch(error => handleAuthenticationError(error, dispatch))
}