import React from 'react'
import { ReactComponent as UpTrendGreen } from '../../svgs/up_trend_green.svg'
import { ReactComponent as DownTrendRed } from '../../svgs/down_trend_red.svg'
import { ReactComponent as InlineTrendWhite } from '../../svgs/inline_trend_white.svg'
import c from '../../constants'
import {PlayForWork} from "@material-ui/icons";

import {
    getQuoteAndBase,
    getCurrentBuyAndSellPrice,
    getCurrentPriceAndDeltaPercent,
    brushValue,
    getTradeEnterVerbFutureTense, timeAgoFormatter, timeFormatter, getMaxMinAndDeltaPercent
} from "./UISupportFns";
import TimeAgo from "react-timeago";

export default function BTD(props) {
    const {btd, openRecord} = props
    const {quote, base} = getQuoteAndBase(btd)
    const {currentPrice} = getCurrentBuyAndSellPrice(btd)
    const {trend, currentPriceDeltaPercent, currentPriceClass} = getCurrentPriceAndDeltaPercent(btd)
    const posSize = brushValue(btd.pos_size)
    const verbFutureTense = getTradeEnterVerbFutureTense(btd)
    const {maxPrice, maxDelta, minPrice, minDelta} = getMaxMinAndDeltaPercent(btd)

    let barColor = "#68FF7D"
    if (btd.status === c.BTD_STATUS_CANCELED)
        barColor = "gray"
    else if (btd.status === c.BTD_STATUS_DIP_REACHED)
        barColor = "gray"
    else if (btd.status === c.BTD_STATUS_ERROR)
        barColor = "#FE484F"

    return (<div className={"btdContainer"} onClick={openRecord}>
                <div className={"tradeSubContainerLeft"}>
                    <div style={{display: "flex", justifyContent: "space-between", width: "9em"}}>
                        <div>
                            <span style={{fontSize: "1.2em", fontWeight: "bold"}}>{quote}</span>
                            <span style={{fontSize: "1em"}}> / {base}</span>
                        </div>
                        <div style={{top: "0.8rem"}}>
                            {trend === 1 && <UpTrendGreen/>}
                            {trend === 0 && <InlineTrendWhite/>}
                            {trend === -1 && <DownTrendRed/>}
                        </div>
                    </div>
                    <div className={"activeTradePosAndQtyStart"}><b>{posSize} {base}</b> {verbFutureTense} <b>{quote}</b></div>
                    <div>
                        {/*<div className={"w3-progress-sizer"}>*/}
                        {/*    <div className="w3-progress-container-btd w3-round-xlarge" style={{backgroundColor: barColor}}>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <PlayForWork style={{color: barColor, position: "relative", top: "0.4rem", fontSize: "42"}} />
                        {(btd.status === c.BTD_STATUS_WAITING || btd.status === c.BTD_STATUS_CANCELED) &&
                        <div className={"progressBarText"} style={{position: "relative", top: "-0.1rem"}}>
                            WAITING PRICE DIP
                        </div>}
                        {(btd.status === c.BTD_STATUS_DIP_REACHED || btd.status === c.BTD_STATUS_ERROR) && <div className={"progressBarText"}>
                            PRICE DIP REACHED
                        </div>}
                    </div>
                </div>
                {btd.status === c.BTD_STATUS_WAITING &&
                <div className={"tradeSubContainerRight"}>
                    <div className={"currentPriceStatsContainer"} title={"Current price"}>
                        <div className={currentPriceClass}>{currentPriceDeltaPercent}%</div>
                        <span className={"horizSpacer"}/>
                        <div className={currentPriceClass}>{currentPrice}</div>
                    </div>
                    <div className={"activeTradePosAndQtyEnd"}>Dip price <b>{btd.dip_price}</b></div>
                    {maxPrice !== "0" && <div className={"maxPriceContainer"} title={"Max price while waiting for dip"}>
                        {maxPrice} ({maxDelta}%)
                    </div>}
                    {minPrice !== "0" && <div className={"minPriceContainer"} title={"Min price while waiting for dip"}>
                        {minPrice} ({minDelta}%)
                    </div>}
                    <div className={"activeForContainer"}>
                        <span>Active for <TimeAgo date={parseInt(btd.time)} formatter={timeAgoFormatter}/></span>
                    </div>
                </div>}
                {btd.status !== c.BTD_STATUS_WAITING &&
                <div className={"tradeSubContainerRight"}>
                    <div className={"currentPriceStatsContainer"} title={"Current price"}>
                        <div className={currentPriceClass}>{currentPriceDeltaPercent}%</div>
                        <span className={"horizSpacer"}/>
                        <div className={currentPriceClass}>{currentPrice}</div>
                    </div>
                    {btd.status === c.BTD_STATUS_CANCELED &&
                    <div className={"currentPriceStatsContainer"}>
                        <span style={{color: "#E8E288"}}>Canceled</span>
                    </div>
                    }
                    {btd.status === c.BTD_STATUS_DIP_REACHED &&
                    <div className={"currentPriceStatsContainer"}>
                        <span style={{color: "gray"}}>Dip reached</span>
                    </div>
                    }
                    {btd.status === c.BTD_STATUS_ERROR &&
                    <div className={"currentPriceStatsContainer"}>
                        <span style={{color: " #FE484F"}}>Error</span>
                    </div>
                    }
                </div>}

                <div style={{display:"flex", justifyContent:"center", width: "100%"}}>
                    <div className={"buyPriceContainer"}>
                        {btd.status === c.BTD_STATUS_CANCELED && <div>Canceled on {timeFormatter(btd.last_update_time, true)}</div>}
                        {btd.status === c.BTD_STATUS_DIP_REACHED && <div>Dip reached on {timeFormatter(btd.last_update_time, true)}</div>}
                        {btd.status === c.BTD_STATUS_ERROR && <div>Errored on {timeFormatter(btd.last_update_time, true)}</div>}
                    </div>
                    {btd.status === c.BTD_STATUS_WAITING &&
                    <div>
                        <div className={"hrState"}>
                            <span className="blink live" style={{color: "#68FF7D"}}>▶</span></div>
                    </div>}
                </div>
                <div style={{display:"flex", justifyContent:"space-between", width: "100%"}} className={"recordId"}>
                    <span>{btd.buy_the_dip_id}</span>
                    <span>{timeFormatter(btd.time, true)}</span>
                </div>
            </div>)
}
