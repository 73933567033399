import c from "../../constants";
import {
  CHANGE_SELECTED_SUCS_TAB,
  CLEAR_SUCS_STATE,
  GET_ALL_SUCS,
  GET_JOIN_SIGNALS_GROUP_REQUEST_STATE,
  GET_SUC_UPDATE,
  OPEN_SUC,
  UPDATE_SUC_CANCEL,
  UPDATE_SUC_CONDITIONS,
  UPDATE_SUC_CONNECTION_TO_SIGNAL,
  UPDATE_SUC_DISCARD,
  UPDATE_SUC_TRADE_CONDITIONS,
} from "../actions/actionTypes";

const runningSucStatuses = [c.SUC_STATUS_WAITING];

function discardSUCFromRunningOrClosedLists(state, suc) {
  const { runningSUCs, closedSUCs } = state;
  discardSUCFromListIfFound(runningSUCs, suc);
  discardSUCFromListIfFound(closedSUCs, suc);
  return {
    runningSUCs,
    closedSUCs,
  };
}

function discardSUCFromListIfFound(list, suc) {
  const idx = list.findIndex((s) => s.suc_id === suc.suc_id);
  if (idx >= 0) {
    list.splice(idx, 1);
  }
}

function safeSortArrayDescByTime(arr) {
  const sortedArray = arr.sort((a, b) => {
    let intTimeA = 0;
    let intTimeB = 0;
    try {
      intTimeA = parseInt(a.time, 10);
      intTimeB = parseInt(b.time, 10);
    } catch (error) {
      console.log(error);
    }
    return intTimeB - intTimeA;
  });
  return sortedArray;
}

let UPDATE_SUC_CONDITIONS_LOCK = false;

export default function (state = { selectedSUCsTab: 0 }, action) {
  switch (action.type) {
    case GET_ALL_SUCS: {
      if (!action.payload) return state;
      const { usersSUCS, types } = action.payload;
      const userSucsArray = Object.entries(usersSUCS)
        .map(([, i]) => i)
        .sort((a, b) => parseInt(a.time) - parseInt(b.time));

      switch (types) {
        case 0:
          return {
            ...state,
            runningSUCs: safeSortArrayDescByTime(userSucsArray),
            activeSUCsNo: userSucsArray.length,
          };
        case 1:
          return {
            ...state,
            closedSUCs: safeSortArrayDescByTime(userSucsArray),
            closedSUCsNo: userSucsArray.length,
          };
        default:
          return state;
      }
    }

    case GET_SUC_UPDATE: {
      if (UPDATE_SUC_CONDITIONS_LOCK) return state;
      let newSUC = action.payload;
      if (!newSUC?.suc_id) {
        console.log("SUC_ID not found in GET_SUC_UPDATE");
        return state;
      }
      const { runningSUCs = [], closedSUCs = [] } = state;
      const allSucs = [...runningSUCs, ...closedSUCs];

      const idx = allSucs.findIndex((s) => s.suc_id === newSUC.suc_id);
      if (idx >= 0) {
        //merge with the existing SUC
        allSucs[idx] = {
          ...allSucs[idx],
          ...newSUC,
        };
      } else {
        //add the new SUC
        allSucs.push(newSUC);
      }

      const newRunningSUCs = allSucs.filter((suc) => runningSucStatuses.includes(suc.status));
      const newClosedSUCs = allSucs.filter((suc) => !runningSucStatuses.includes(suc.status)); //if not in runningSucStatuses, it is closed

      let newState = {
        ...state,
        runningSUCs: safeSortArrayDescByTime(newRunningSUCs),
        closedSUCs: safeSortArrayDescByTime(newClosedSUCs),
        activeSUCsNo: newRunningSUCs?.length,
        closedSUCsNo: newClosedSUCs?.length,
      };
      return newState;
    }

    case OPEN_SUC: {
      return {
        ...state,
        ...{
          openedSUC: action.payload,
        },
      };
    }

    case UPDATE_SUC_CONNECTION_TO_SIGNAL: {
      return {
        ...state,
        ...{
          openedSUC: action.payload,
        },
      };
    }

    case UPDATE_SUC_CONDITIONS: {
      return {
        ...state,
        ...{
          openedSUC: action.payload,
        },
      };
    }

    case UPDATE_SUC_TRADE_CONDITIONS: {
      return {
        ...state,
        ...{
          openedSUC: action.payload,
        },
      };
    }

    case UPDATE_SUC_CANCEL: {
      return {
        ...state,
        ...{
          openedSUC: {
            ...state.openedSUC,
            ...{ status: c.SUC_STATUS_CANCELED },
          },
        },
      };
    }

    case UPDATE_SUC_DISCARD: {
      let newState = { ...state, ...{ random: Math.random() * 10000 } };
      let discardedSUC = action.payload;
      newState.openedSUC = undefined;
      const { runningSUCs, closedSUCs } = discardSUCFromRunningOrClosedLists(newState, discardedSUC);
      return {
        runningSUCs,
        closedSUCs,
        activeSUCsNo: runningSUCs?.length,
        closedSUCsNo: closedSUCs?.length,
      };
    }

    case CHANGE_SELECTED_SUCS_TAB: {
      return {
        ...state,
        ...{
          selectedSUCsTab: action.payload,
        },
      };
    }

    case GET_JOIN_SIGNALS_GROUP_REQUEST_STATE: {
      return {
        ...state,
        ...{
          userJoinSignalsRequestState: action.payload.state,
        },
      };
    }

    case CLEAR_SUCS_STATE: {
      return { selectedSUCsTab: 0 };
    }

    default:
      return state;
  }
}
