import React, { Component } from "react";
import { connect } from "react-redux";
import Wallets from "./Wallets";
import Withdraw from "./Withdraw";
import {ReactComponent as FuturesWalletIcon} from "../../../svgs/futuresWallet.svg"
import {
  changeTab,
  changeToWithdrawalsTab,
  changeToTransfersTab,
  getFuturesBalances,
} from "../../../redux/actions/wallet";
import { getTitle } from "../../fncomponents/PageTitleUtils";
import { Helmet } from "react-helmet/es/Helmet";
import BalanceTab from "../../fncomponents/BalanceTab";
import {CHANGE_RENDER_CUSTOM_HEADER, CHANGE_TAB} from "../../../redux/actions/actionTypes";
import WithdrawAllow from "./WithdrawAllow";
import Transfer from "./Transfer";
import { AllTransactions } from "./AllTransactions";
import {List as ListIcon} from "@material-ui/icons";
import {Button} from "@material-ui/core";
import {hasFuturesActivated} from "../../../redux/actions/profile";
import Transactions from "./Transactions/Transactions";
import SendToStake from "./SendToStake";

const qs = require("query-string");

class WalletsDWT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDepositCoin: undefined,
      selectedDepositCoinAddress: undefined,
      withdrawAmount: 0,
      coinBalanceEntry: undefined,
      selectedTab: 0,
      selectedWithdrawCoin: undefined,
      selectedWithdrawCoinAddress: undefined,
      withdrawFromWalletList: false,
    };

    this.changeTab = this.changeTab.bind(this);
    // this.props.getBalancesRecentDepositsAndWithdraws()
  }

  changeTab(event, newValue) {
    if (this.props.selectedTab === newValue) return;

    this.props.changeTab(newValue);
  }

  componentDidMount() {
    this.props.getFuturesBalances();

    this.state.withdrawalConfirmationToken = qs.parse(
      this.props.location.search,
      { ignoreQueryPrefix: true }
    ).wtkdjf;

    if (
      this.state.withdrawalConfirmationToken &&
      this.state.withdrawalConfirmationToken.length > 0
    ) {
      this.props.confirmWithdrawal({
        token: this.state.withdrawalConfirmationToken,
      });
      setTimeout(() => {
        this.setState({
          withdrawalConfirmationToken: undefined,
        });
      }, 10000);
    }
    this.props.hasFuturesActivated()
  }

  componentWillUnmount() {
    this.props.changeRenderCustomHeader();
  }

  render() {
    return (
      <div className={"AppMainContent"}>
        <Helmet>
          <title>{getTitle()}</title>
        </Helmet>
        {this.props.selectedTab === 0 && <Button style={{ color: "#3BADFF", marginLeft: "0.2rem"}} onClick={this.props.goToAllTransactions}>
          <ListIcon />
          All Wallet Txs
        </Button>}
        {(this.props.selectedTab === 0 && this.props._hasFuturesActivated === 1) && <Button style={{ color: "#3BADFF"}} onClick={this.props.goToFuturesTransactions}>
          <FuturesWalletIcon /> &nbsp;
          Futures Txs
        </Button>}

        {this.props.selectedTab === 0 && (
          <Wallets
            changeTab={changeTab}
            changeRenderCustomHeader={this.props.changeRenderCustomHeader}
            hasFuturesActivated={this.props._hasFuturesActivated === 1}
          />
        )}
        {this.props.selectedTab === 1 && (
          <BalanceTab
            currency={this.props.selectedBalanceCoin}
            recentTransfers={this.props.recentTransfers.filter(
              (transfer) =>
                transfer.coin === this.props.selectedBalanceCoin.coin
            )}
            recentInternalTransfers={this.props.recentInternalTransfers.filter(
                (internalTransfer) => {
                    if(internalTransfer && internalTransfer.coin === this.props.selectedBalanceCoin.coin)
                      return internalTransfer

                }
            )}
            recentDeposits={this.props.recentDeposits.filter(
              (deposit) => deposit.coin === this.props.selectedBalanceCoin.coin
            )}
            recentWithdrawals={this.props.recentWithdrawals.filter(
              (withdrawal) =>
                withdrawal.coin === this.props.selectedBalanceCoin.coin
            )}
            recentInvestmentPurchases={this.props.recentInvestmentPurchases.filter(
                (investment) =>
                    investment.coin === this.props.selectedBalanceCoin.coin
            )}
            futuresBalances={this.props.futuresBalances}
            changeRenderCustomHeader={this.props.changeRenderCustomHeader}
            user={this.props.user}
          />
        )}
        {this.props.selectedTab === 2 && (
          <Withdraw
            selectedWithdrawCoin={this.props.selectedWithdrawCoin}
            changeRenderCustomHeader={this.props.changeRenderCustomHeader}
          />
        )}
        {this.props.selectedTab === 3 && (
          <WithdrawAllow transactionDetails={this.props.transactionDetails} 
          changeRenderCustomHeader={this.props.changeRenderCustomHeader}
          />
        )}
        {this.props.selectedTab === 4 && <Transfer  changeRenderCustomHeader={this.props.changeRenderCustomHeader}/>}
        {this.props.selectedTab === 5 && <AllTransactions  changeRenderCustomHeader={this.props.changeRenderCustomHeader}/>}
        {this.props.selectedTab === 6 && <Transactions changeRenderCustomHeader={this.props.changeRenderCustomHeader}/>}
        {this.props.selectedTab === 7 && <SendToStake changeRenderCustomHeader={this.props.changeRenderCustomHeader}/>}
      </div>
    );
  }
}

const mapStateToProps = (rootState) => ({
  user: rootState.authentication.user,
  selectedTab: rootState.wallet.selectedTab,
  selectedBalanceCoin: rootState.wallet.selectedBalanceCoin || {},
  balances: rootState.wallet.balances,
  recentTransfers: rootState.wallet.recentTransfers || [],
  recentInternalTransfers: rootState.wallet.recentInternalTransfers || [],
  recentDeposits: rootState.wallet.recentDeposits || [],
  recentWithdrawals: rootState.wallet.recentWithdrawals || [],
  recentInvestmentPurchases: rootState.wallet.recentInvestmentPurchases || [],
  futuresBalances: rootState.wallet.futuresBalances,
  selectedWithdrawCoin: rootState.wallet.selectedWithdrawCoin || {},
  transactionDetails: rootState.wallet.transactionDetails || {},
  selectedTransferCoin: rootState.wallet.selectedTransferCoin || {},
  _hasFuturesActivated: rootState.profile.hasFuturesActivated,
});

const mapDispatchToProps = (dispatch) => {
  return {
    changeTab: (tab) => dispatch(changeTab(tab)),
    changeToWithdrawalsTab: (props) => dispatch(changeToWithdrawalsTab(props)),
    changeToTransfersTab: (props) => dispatch(changeToTransfersTab(props)),
    getFuturesBalances: (props, cb) => dispatch(getFuturesBalances(props, cb)),
    changeRenderCustomHeader: (renderCustomHeader) =>
      dispatch({
        type: CHANGE_RENDER_CUSTOM_HEADER,
        payload: { renderCustomHeader },
      }),
    goToAllTransactions: () => dispatch({type: CHANGE_TAB, payload: 5}),
    goToFuturesTransactions: () => dispatch({type: CHANGE_TAB, payload: 6}),
    hasFuturesActivated: (cb) => dispatch(hasFuturesActivated(cb))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WalletsDWT);
