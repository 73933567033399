import React, {Component} from 'react'
import SignalsEnhancedTableV1 from "./SignalsEnhancedTableV1";

class ClosedSignalsV1 extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        const columns = [
            { id: 'signal', numeric: false, disablePadding: false, label: 'Signal' },
            { id: 'receivingSubscriptions', numeric: false, disablePadding: false, label: 'Receiving Subscriptions' },
            { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' }
        ]
        return (
            <div>
                <h2>Closed Signals</h2>
                <SignalsEnhancedTableV1
                    rows={this.props.closedSignals ? this.props.closedSignals : []}
                    columns={columns}
                    rowsPerPage={this.props.rowsPerPage}
                    setRowsPerPage={this.props.setRowsPerPage}
                    page={this.props.page}
                    setPage={this.props.setPage}
                    totalDocs={this.props.totalDocs ? this.props.totalDocs : 0}
                    filtersMap={this.props.filtersMap}
                    onFilterChange={this.props.onFilterChange}
                    subscriptionsHash={this.props.subscriptionsHash}
                    isSuperAdmin={this.props.isSuperAdmin}
                />
            </div>
        )
    }
}

export default ClosedSignalsV1
