import React from 'react'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import Rating from '@material-ui/lab/Rating'
import Avatar from '@material-ui/core/Avatar'
import {brushValue, scientificToDecimal, timeFormatter2} from "./UISupportFns";


const StyledRating = withStyles({
    iconFilled: {
        color: '#D79600',
    }
})(Rating);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(13),
        height: theme.spacing(13),
    },
}));


export default function AffiliateCommissionBox(props) {
    const classes = useStyles();


    const {affiliateCommissions: _t} = props
    if (!_t)
        return null
    let btcFees, usdtFees, ethFees

    function extractFees() {
        for(let i =0; i < _t.totals.length; i++) {
            if (_t.totals[i].coin === "BTC") {
                btcFees = _t.totals[i]
            } else if (_t.totals[i].coin === "USDT") {
                usdtFees = _t.totals[i]
            } else if (_t.totals[i].coin === "ETH") {
                ethFees = _t.totals[i]
            }
        }
    }

    extractFees()

    return (
        <div>
            <div style={{color: "gray", fontSize: "0.66rem"}}>Last update at {new Date(_t.lastRun).toUTCString()}</div>
            {(btcFees && btcFees.commissionsTotal) !== 0 && <div><span style={{fontSize: "1.1rem"}}>{scientificToDecimal(btcFees.commissionsTotal, 8)} <b>BTC</b></span> from {btcFees.fees} fees</div>}
            {(usdtFees && usdtFees.commissionsTotal) !== 0 && <div><span style={{fontSize: "1.1rem"}}>{scientificToDecimal(usdtFees.commissionsTotal, 6)} <b>USDT</b></span> from {usdtFees.fees} fees</div>}
            {(ethFees && ethFees.commissionsTotal) !== 0 && <div><span style={{fontSize: "1.1rem"}}>{scientificToDecimal(ethFees.commissionsTotal, 6)} <b>ETH</b></span> from {ethFees.fees} fees</div>}
            {_t.payments && <div>
                {_t.payments.map(p => <div style={{marginTop: "0.2rem", marginLeft: "0.6rem", fontStyle: "italic", opacity: "0.8"}}>Paid <b>{brushValue(p.amount)} {p.coin}</b> on {timeFormatter2(p.creationDate)}, payment id <span style={{color: "#64FE7C"}}>{p._id}</span></div>) }
            </div>}
        </div>
    )
}
