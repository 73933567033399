import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getTitle} from "../../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"
import {
    acceptRequestToJoin, discardRequestToJoin,
    getSignalsNotificationsByPage, getSignalsNotificationsInitial,
    readUnreadSignalsNotification, rejectRequestToJoin
} from "../../../redux/actions/signals"
import {isUserSuperAdmin} from "../../../redux/actions/authentication"
import SignalsNotificationsTable from "./SignalsNotificationsTable"
import KeyboardBackArrow from '@material-ui/icons/KeyboardBackspace'
import AlertDialog from "../../fncomponents/AlertDialog";
import {debounce} from "../../hoc/myUtils";


class SignalsNotifications extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isAcceptDialogOpen: false,
            isRejectDialogOpen: false,
            isDiscardDialogOpen: false,
            selectedNotificationId: 0,
            page: 0,
            rowsPerPage: 10,
            text: ''
        }
        this.readUnread = this.readUnread.bind(this)
        this.accept = this.accept.bind(this)
        this.reject = this.reject.bind(this)
        this.discard = this.discard.bind(this)
        this.openAcceptDialog = this.openAcceptDialog.bind(this)
        this.closeAcceptDialog = this.closeAcceptDialog.bind(this)
        this.openRejectDialog = this.openRejectDialog.bind(this)
        this.closeRejectDialog = this.closeRejectDialog.bind(this)
        this.openDiscardDialog = this.openDiscardDialog.bind(this)
        this.closeDiscardDialog = this.closeDiscardDialog.bind(this)

        this.setRowsPerPage = this.setRowsPerPage.bind(this)
        this.setPage = this.setPage.bind(this)
        this.handleChangeText = this.handleChangeText.bind(this)
        this.onTextChanged = this.onTextChanged.bind(this)
        this.debouncedTextChanged = debounce(this.onTextChanged, 500)
    }

    readUnread(_id) {
        this.props.readUnread({notifId: _id})
    }

    openAcceptDialog(_id) {
        this.setState({
            isAcceptDialogOpen: true,
            selectedNotificationId: _id
        })
    }

    closeAcceptDialog(_id) {
        this.setState({
            isAcceptDialogOpen: false,
            selectedNotificationId: 0
        })
    }

    openRejectDialog(_id) {
        this.setState({
            isRejectDialogOpen: true,
            selectedNotificationId: _id
        })
    }

    closeRejectDialog(_id) {
        this.setState({
            isRejectDialogOpen: false,
            selectedNotificationId: 0
        })
    }

    openDiscardDialog(_id) {
        this.setState({
            isDiscardDialogOpen: true,
            selectedNotificationId: _id
        })
    }

    closeDiscardDialog(_id) {
        this.setState({
            isDiscardDialogOpen: false,
            selectedNotificationId: 0
        })
    }

    accept() {
        if (this.state.selectedNotificationId)
            this.props.acceptRequestToJoin({notifId: this.state.selectedNotificationId}, () => {
                this.setState({
                    isAcceptDialogOpen: false,
                    selectedNotificationId: 0
                })
            })
    }

    reject() {
        if (this.state.selectedNotificationId)
            this.props.rejectRequestToJoin({notifId: this.state.selectedNotificationId}, () => {
                this.setState({
                    isRejectDialogOpen: false,
                    selectedNotificationId: 0
                })
            })
    }

    discard() {
        if (this.state.selectedNotificationId)
            this.props.discardRequestToJoin({notifId: this.state.selectedNotificationId}, () => {
                this.setState({
                    isDiscardDialogOpen: false,
                    selectedNotificationId: 0
                })
            })
    }

    setRowsPerPage (value) {
        this.state.rowsPerPage = value
        this.state.page = 0
        this.props.getSignalsNotificationsInitial({isUserSuperAdmin: isUserSuperAdmin(this.props.user), page: this.state.page, rowsPerPage: this.state.rowsPerPage})
    }

    setPage (value) {
        let page = value
        if (!this.props.pagesDownloaded[page+1]) {
            this.state.page = page
            this.props.getSignalsNotificationsByPage({text: this.state.text,
                page: this.state.page, rowsPerPage: this.state.rowsPerPage,
                isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
        } else {
            this.setState({
                page: page
            })
        }
    }

    handleChangeText(e) {
        this.setState({
            text: e.target.value
        })
        this.debouncedTextChanged()
    }

    onTextChanged() {
        this.props.getSignalsNotificationsInitial({text: this.state.text,
            page: this.state.page, rowsPerPage: this.state.rowsPerPage,
            isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
    }

    componentDidMount() {
        this.props.getSignalsNotificationsInitial({isUserSuperAdmin: isUserSuperAdmin(this.props.user), page: this.state.page, rowsPerPage: this.state.rowsPerPage})
    }

    render() {
        let {docs, totalDocs} = this.props
        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <div style={{display: "flex", justifyContent: "flex-start", width: "20em", cursor: "pointer"}}
                 onClick={()=>{this.props.history.push("/signals")}} >
                <KeyboardBackArrow/>
                <div className={"pageTitle"} style={{marginLeft: "1rem"}}>Back</div>
            </div>
            <div>
                {docs && <SignalsNotificationsTable
                    rows={docs}
                    rowsPerPage={this.state.rowsPerPage}
                    setRowsPerPage={this.setRowsPerPage}
                    page={this.state.page}
                    setPage={this.setPage}
                    totalDocs={totalDocs ? totalDocs : 0}
                    text={this.state.text}
                    handleChangeText={this.handleChangeText}
                    isSuperAdmin={isUserSuperAdmin(this.props.user)}
                    readUnread={this.readUnread}
                    accept={this.openAcceptDialog}
                    reject={this.openRejectDialog}
                    discard={this.openDiscardDialog}
                />}
            </div>
            <AlertDialog
                open={this.state.isAcceptDialogOpen}
                title={"Accept request to join?"}
                texts={["Accept this user's request to join a signal group?",
                    "Please note that you should include the user in a signal group in order form him/her to receive future signals."]}
                okLabel={"Accept"}
                handleOk={this.accept}
                handleCancel={this.closeAcceptDialog}
            />
            <AlertDialog
                open={this.state.isRejectDialogOpen}
                title={"Reject request to join?"}
                texts={["Are your sure you want to reject this user's request to join?"]}
                okLabel={"Reject"}
                handleOk={this.reject}
                handleCancel={this.closeRejectDialog}
            />
            <AlertDialog
                open={this.state.isDiscardDialogOpen}
                title={"Discard request to join?"}
                texts={["Are your sure you want to discard this request?"]}
                okLabel={"Discard"}
                handleOk={this.discard}
                handleCancel={this.closeDiscardDialog}
            />
        </div>
    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    docs: rootState.signals.docs,
    totalDocs: rootState.signals.totalDocs,
    totalPages: rootState.signals.totalPages,
    pagesDownloaded: rootState.signals.pagesDownloaded,
    totals: rootState.signals.totals,
})

let mapDispatchToProps = dispatch => {
    return {
        getSignalsNotificationsInitial: (props) => dispatch(getSignalsNotificationsInitial(props)),
        getSignalsNotificationsByPage: (props) => dispatch(getSignalsNotificationsByPage(props)),
        readUnread: (props) => dispatch(readUnreadSignalsNotification(props)),
        acceptRequestToJoin: (props, cb) => dispatch(acceptRequestToJoin(props, cb)),
        rejectRequestToJoin: (props, cb) => dispatch(rejectRequestToJoin(props, cb)),
        discardRequestToJoin: (props, cb) => dispatch(discardRequestToJoin(props, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (SignalsNotifications)
