import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import {connect} from "react-redux"
import {Clear, ArrowBack} from "@material-ui/icons"
import {
    brushValue, formatTimeDuration,
    getCurrentPriceAndDeltaPercent, getMaxMinAndDeltaPercent,
    getPosSize,
    getQuoteAndBase, getTradeConditionsContentForSUCDetails,
    getTradeEnterVerbFutureTense,
    timeFormatter,
} from "../../fncomponents/UISupportFns"
import {Paper} from "@material-ui/core"
import c from "../../../constants"
import Fade from '@material-ui/core/Fade'
import SUCDetailsMainButtons from "../../fncomponents/SUCDetailsMainButtons"
import DiscardItem from "../../fncomponents/DiscardItem"
import {updateSUCDiscardIt} from "../../../redux/actions/sucs"
import {openTradeFromSUC} from "../../../redux/actions/trades"
import {getTitle} from "../../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"


class SUCSummary extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isEditSUCTradeConditionsPanelOpen: false,
            isEditSUCConditionsPanelOpen: false,
            isSUCDetailsPanelOpen: true,
            isCancelSUCPanelOpen: false,
            isDiscardSUCPanelOpen: false,
            goBack: false,
            tradeOpened: false
        }

        this.goBack = this.goBack.bind(this)
        this.openDiscardSUCPanel = this.openDiscardSUCPanel.bind(this)
        this.closeDiscardSUCPanel = this.closeDiscardSUCPanel.bind(this)
        this.discardSUC = this.discardSUC.bind(this)
        this.openTrade = this.openTrade.bind(this)
    }

    goBack() {
        this.setState({
            goBack: true
        })
    }

    openDiscardSUCPanel() {
        this.setState({
            isDiscardSUCPanelOpen: true,
            isSUCDetailsPanelOpen: false
        })
    }

    closeDiscardSUCPanel() {
        this.setState({
            isDiscardSUCPanelOpen: false,
            isSUCDetailsPanelOpen: true,
        })
    }

    discardSUC() {
        this.state.isDiscardSUCPanelOpen = false
        this.state.isSUCDetailsPanelOpen = true
        this.props.updateSUCDiscardIt({sucId: this.props.suc.suc_id})
    }

    openTrade() {
        this.props.openTradeFromSUC(this.props.suc.trade_order_id)
        this.setState({
            tradeOpened: true
        })
    }

    componentDidMount() {
        if (!this.props.suc)
            return
    }

    render() {
        if (this.state.goBack)
            return <Redirect to="/sucs"/>
        if (this.state.tradeOpened)
            return <Redirect to="/tradedetails"/>

        let {suc} = this.props
        if (!suc)
            return <Redirect to="/sucs"/>
        const ACTION_TYPE = suc.action_type
        const {quote, base} = getQuoteAndBase(suc)
        const verbFutureTense = getTradeEnterVerbFutureTense(suc)
        const posSize = getPosSize(suc)
        const {currentPriceDeltaPercent, currentPrice, currentPriceClass} = getCurrentPriceAndDeltaPercent(suc)
        const {maxPrice, maxDelta, minPrice, minDelta} = getMaxMinAndDeltaPercent(suc)
        const {tpContent, slContent} = getTradeConditionsContentForSUCDetails(suc)
        const buyPrice = brushValue(suc.buy_price)
        const sellPrice = brushValue(suc.sell_price)

        return <div className={"formContainer"} style={{paddingTop: "3.8em"}}>
                <Helmet>
                    <title>{getTitle()}</title>
                </Helmet>
                <div style={{maxWidth: "30rem", minWidth: "22rem"}}>
                    {this.state.isSUCDetailsPanelOpen &&
                    <Fade in={this.state.isSUCDetailsPanelOpen}>
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.goBack()}}><ArrowBack/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>Signal <span style={{opacity: "0.5"}}>{suc.suc_id}</span> summary</div>
                                    <div style={{width: "2.6rem"}}/>
                                </div>
                            </div>
                            <div className={"vertSpacer1"}/>
                            <div className={"tradeDetailsPair"} >
                                {quote} <span className={"tradeDetailsPairSlash"}>/</span> {base}
                            </div>
                            <div className={`tradeDetailsCurrentPrice ${currentPriceClass}`} title={"Current price"}>
                                {currentPriceDeltaPercent}% · {currentPrice}
                            </div>
                            <div className={"vertSpacer1"}/>
                            {suc.status === c.SUC_STATUS_MATERIALIZED &&
                            <div>
                                <div style={{
                                    color: "gray",
                                    textAlign: "center",
                                    fontSize: "1.6rem",
                                    lineHeight: "1.8rem"
                                }}>
                                    MATERIALIZED
                                </div>
                                <div style={{textAlign: "center", marginTop: "0.6rem"}} className={"action"}>
                                    {(suc.trade_order_id && suc.trade_order_id !== "null") && <div style={{cursor: "pointer"}} onClick={()=>{this.openTrade()}}>Open Trade {suc.trade_order_id}</div>}
                                </div>
                            </div>
                            }
                            {suc.status === c.SUC_STATUS_CANCELED &&
                            <div>
                                <div style={{
                                    color: "#E8E288",
                                    textAlign: "center",
                                    fontSize: "1.6rem",
                                    lineHeight: "1.8rem"
                                }}>
                                    Canceled
                                </div>
                            </div>
                            }
                            {(suc.status === c.SUC_STATUS_ERROR || suc.error) &&
                            <div>
                                <div style={{color: "#FB4A4A", textAlign: "center", fontSize: "1.6rem", lineHeight: "1.8rem"}}>
                                    Error
                                </div>
                                <div style={{textAlign: "center", width: "18rem", margin: "auto"}}>
                                    {suc.error}
                                </div>
                            </div>
                            }
                            <div className={"vertSpacer2"}/>
                            <div className={"tradeDetailsCreationDate"} style={{fontSize: "0.9rem", textAlign: "center"}}>
                                <span title={"Created on"}>{timeFormatter(suc.time, true)}</span>
                                <span title={"Closed on"}>&nbsp;→&nbsp;{timeFormatter(suc.last_update_time, true)}</span>
                                <br/>
                                <span title={"Duration"}>{formatTimeDuration(suc.last_update_time, suc.time)}</span>
                            </div>
                            <div className={"tradeDetailsPosAndQty"}>
                                <b>{posSize} {base}</b> {verbFutureTense} <small>{quote}</small><br/>
                                {ACTION_TYPE === c.ACTION_TYPE_BUY && <span><b>buy price {buyPrice}</b></span>}
                                {ACTION_TYPE === c.ACTION_TYPE_SELL && <span><b>sell price {sellPrice}</b></span>}
                            </div>
                            {(maxPrice > 0 && minPrice > 0) && <div>
                                <div className={"vertSpacer"}/>
                                <div className={"tradeDetailsCreationDate"}>
                                    <div><b>During Signal</b></div>
                                    {maxPrice > 0 && <div className={"maxPriceContainer2"}>max price {maxDelta}% · {maxPrice}</div>}
                                    {minPrice > 0 && <div className={"minPriceContainer2"}>min price {minDelta}% · {minPrice}</div>}
                                </div>
                            </div>}
                            <div className={"vertSpacer2"}/>
                            <div className={"tradeDetailsCreationDate"}>
                                <span><b>Trade conditions</b></span><br/>
                                <span>{tpContent}</span><br/>
                                <span>{slContent}</span>
                            </div>
                            <div style={{padding: "0.6rem"}}>
                                <SUCDetailsMainButtons suc={suc} cancelSUC={this.openCancelSUCPanel}
                                                         discardSUC={this.openDiscardSUCPanel}/>
                            </div>
                        </Paper>
                    </Fade>}
                    {this.state.isDiscardSUCPanelOpen &&
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeDiscardSUCPanel()}}><Clear/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>Discard Signal</div>
                                    <div style={{width: "2.6rem"}}/>
                                </div>
                            </div>
                            <div className={"tradeDetailsTradeConditionsPaper"}>
                                <DiscardItem discardItem={this.discardSUC} nevermind={this.closeDiscardSUCPanel}
                                             question={"Are you sure you want to discard this Signal?"}
                                             discardWarningText={"Discarding this Signal will remove it from your list."}/>
                            </div>
                        </Paper>}
                </div>
            </div>
    }
}


let mapStateToProps = rootState =>({
    suc: rootState.sucs.openedSUC,
})

let mapDispatchToProps = dispatch => {
    return {
        openTradeFromSUC: (order_id) => dispatch(openTradeFromSUC(order_id)),
        updateSUCDiscardIt: (props) => dispatch(updateSUCDiscardIt(props))

    }
}


export default connect(mapStateToProps, mapDispatchToProps) (SUCSummary)
