import React from "react"
import {TextField} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import InputAdornment from "@material-ui/core/InputAdornment"
import {PlayForWork} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 270,
        backgroundColor: '#101A26'
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    fab: {
        // margin: theme.spacing(1),
        marginLeft: theme.spacing(0.6),
        backgroundColor: '#101A26',
        border: "1px solid #ffffff",
        color: "#ffffff"
    },
    fabSelected: {
        // margin: theme.spacing(),
        marginLeft: theme.spacing(0.6),
        backgroundColor: '#ffffff',
        border: "1px solid #000000",
        color: "#000000"
    },
    switcher: {
        checked: {
            backgroundColor: "#17BEBB"
        }
    }}
))

export default function BTDConditions(props) {
    const {posSize, dipPrice,
        handleChangePosSize, handleChangeDipPrice,
        base} = props

    const classes = useStyles()

    return (<div className={"wizardSectionContainer"}>
            <div className={"wizardTitle"}>
                <span className={"wizardTitleIcon"}><PlayForWork/></span> Set your BTD conditions
            </div>
            <div id={"stopLoss"} style={{display: "flex", marginTop: "1.6em",
                justifyContent: "space-between", flexWrap: "wrap", maxWidth: "25em"}}>
                <div>
                    <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8"}}>Dip price</div>
                    <TextField
                        className={classes.textField}
                        variant={"outlined"}
                        margin={"dense"}
                        id="input-with-icon-textfield_btd_cond"
                        value={dipPrice}
                        onChange={handleChangeDipPrice}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{base}</InputAdornment>
                        }}
                    />
                </div>
            </div>
            <div id={"takeProfit"} style={{display: "flex", marginTop: "1em",
                justifyContent: "space-between", flexWrap: "wrap", maxWidth: "26em"}}>
                <div>
                    <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8"}}>Position size</div>
                    <TextField
                        className={classes.textField}
                        variant={"outlined"}
                        margin={"dense"}
                        id="input-with-icon-textfield_btd_cond_2"
                        value={posSize}
                        onChange={handleChangePosSize}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{base}</InputAdornment>
                        }}
                    />
                </div>
            </div>
        </div>)
}
