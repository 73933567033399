import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { lighten, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import {brushValue, timeFormatter2} from "./UISupportFns"
import c from '../../constants'
import {Refresh, Mail} from "@material-ui/icons"

function desc(a, b, orderBy) {
    if (!a || !b)
        return 0
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis.map(el => el[0])
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, columns } = props
    const createSortHandler = property => event => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {columns.map(column => (
                    <TableCell
                        key={column.id}
                        align={column.numeric ? 'right' : 'left'}
                        padding={'default'}
                        sortDirection={orderBy === column.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === column.id}
                            direction={order}
                            onClick={createSortHandler(column.id)}
                        >
                            {column.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
}

const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
}))

const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles()
    const {tableTitle} = props
    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: false,
            })}
        >
            <div className={classes.title}>
                <Typography variant="h6" id="tableTitle">
                    {tableTitle}
                </Typography>
            </div>
            <div className={classes.spacer} />
        </Toolbar>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
}))


export default function EnhancedTableFuturesBalance(props) {
    let {tableTitle, rows, columns} = props
    const classes = useStyles()
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('value')

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc'
        setOrder(isDesc ? 'asc' : 'desc')
        setOrderBy(property)
    }

    function addDefaultSrc(ev) {
        ev.target.src ='imgs/coins/noLogo.png'
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar tableTitle={tableTitle}/>
                <div className={classes.tableWrapper}>
                    {(!rows || (rows && rows.length === 0)) && <div className={"noPositionsInWallet"}>You have no positions at the moment</div>}

                    {(rows && rows.length > 0) && <Table
                        id={`Table${Math.random()}`}
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows ? rows.length: 0}
                            columns={columns}
                        />
                        <TableBody>
                            {stableSort(rows, getSorting(order, orderBy))
                                .map((row, rowIndex) => {
                                    if (!row)
                                        return <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={Math.random()}>
                                            <TableCell colSpan={1}/>
                                            <TableCell colSpan={2}>Incomplete data for this entry.</TableCell>
                                            </TableRow>
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={`${row._id}_${Math.random()}`}
                                        >
                                            <TableCell component="th"
                                                       key={`enhanced_table_${rowIndex}_symbol`}
                                                       scope="row" padding={'default'}>
                                                <div style={{display: "table"}}>
                                                    <div className={`${row.type.toLowerCase()}FuturesTradeBandBalance`}>{row.symbol}</div>
                                                </div>
                                            </TableCell>
                                            <TableCell>{row.type}</TableCell>
                                            <TableCell key={`enhanced_table_${rowIndex}_balance`} align={'right'} padding={'default'}>
                                                {(row.type === c.FUTURES_TYPE_SHORT_s && row["balance"] > 0) && <span>-</span>}{row["balance"]}
                                            </TableCell>
                                            <TableCell key={`enhanced_table_${rowIndex}_empty`} align={'right'} padding={'default'}>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>}
                </div>
            </Paper>
        </div>
    )
}
