import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getTitle} from "../../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"
import Button from "@material-ui/core/Button"
import NavTabs from "../../fncomponents/NavTabs"
import {
    clearCurrentSignal, closeRunningSignalV1,
    closeWaitingSignalV1,
    getClosedSignalsByPageV1,
    getClosedSignalsInitialV1,
    getRunningSignalsByPageV1,
    getRunningSignalsInitialV1,
    getWaitingSignalsByPageV1,
    getWaitingSignalsInitialV1,
    selectTab, setCurrentPage, setRowsPerPage
} from "../../../redux/actions/signals"
import {debounce} from "../../hoc/myUtils"
import {isUserSuperAdmin} from "../../../redux/actions/authentication"
import AlertDialog from "../../fncomponents/AlertDialog"
import WaitingSignalsV1 from "./WaitingSignalsV1"
import ClosedSignalsV1 from "./ClosedSignalsV1"
import RunningSignalsV1 from "./RunningSignalsV1"
import {getThisMonthTraderCommissions, getTradersSubscriptions} from "../../../redux/actions/traders"
import {initWS, closeWS} from "../../../redux/actions/wsPrices"

import c from '../../../constants'
import {showThisMonthTraderCommissions} from "../../fncomponents/UISupportFns";

const WAITING_SIGNALS_TAB = 0
const RUNNING_SIGNALS_TAB = 1
const CLOSED_SIGNALS_TAB = 2

class SignalsV1 extends Component {

    constructor(props) {
        super(props)
        this.state = {
            //Waiting Signals
            ws_page: 0,
            ws_rowsPerPage: 10,
            ws_filtersMap: {},
            ws_sort: {},
            //Running Signals
            rs_page: 0,
            rs_rowsPerPage: 10,
            rs_filtersMap: {},
            rs_sort: {},
            //Closed Signals
            cs_page: 0,
            cs_rowsPerPage: 10,
            cs_filtersMap: {},
            cs_sort: {},
            closeSignalDialogOpen: false,
            closeSignalPressed: false
        }

        this.changeTab = this.changeTab.bind(this)

        //Waiting Signals
        this.setRowsPerPageWS = this.setRowsPerPageWS.bind(this)
        this.setPageWS = this.setPageWS.bind(this)
        this.onFilterChangeWS = this.onFilterChangeWS.bind(this)
        this.onFilterWS = this.onFilterWS.bind(this)
        this.debouncedFilterWS = debounce(this.onFilterWS, 500)

        //Running Signals
        this.setRowsPerPageRS = this.setRowsPerPageRS.bind(this)
        this.setPageRS = this.setPageRS.bind(this)
        this.onFilterChangeRS = this.onFilterChangeRS.bind(this)
        this.onFilterRS = this.onFilterRS.bind(this)
        this.debouncedFilterRS = debounce(this.onFilterRS, 500)

        //Closed Signals
        this.setRowsPerPageCS = this.setRowsPerPageCS.bind(this)
        this.setPageCS = this.setPageCS.bind(this)
        this.onFilterChangeCS = this.onFilterChangeCS.bind(this)
        this.onFilterCS = this.onFilterCS.bind(this)
        this.debouncedFilterCS = debounce(this.onFilterCS, 500)

        this.editSignal = this.editSignal.bind(this)
        this.newSignal = this.newSignal.bind(this)
        this.newSignalF = this.newSignalF.bind(this)

        this.openCloseSignalDialog  = this.openCloseSignalDialog.bind(this)
        this.hideCloseSignalDialog  = this.hideCloseSignalDialog.bind(this)
        this.closeSignal = this.closeSignal.bind(this)

        this.props.user && initWS(this.props.user.userId)
        this.props.setCurrentPage(0)
        this.props.setRowsPerPage(10)

    }

    changeTab(event, newValue) {
        if (this.props.selectedTab != newValue) {
            this.props.selectTab(newValue)
            if (newValue == WAITING_SIGNALS_TAB) {
                this.props.getWaitingSignalsInitial({filters: this.state.ws_filtersMap,
                    sort: this.state.ws_sort, page: this.state.ws_page, rowsPerPage: this.state.ws_rowsPerPage,
                    isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
            } else if (newValue == RUNNING_SIGNALS_TAB) {
                this.props.getRunningSignalsInitial({filters: this.state.rs_filtersMap,
                    sort: this.state.rs_sort, page: this.state.rs_page, rowsPerPage: this.state.rs_rowsPerPage,
                    isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
            } else if (newValue == CLOSED_SIGNALS_TAB) {
                this.props.getClosedSignalsInitial({filters: this.state.cs_filtersMap,
                    sort: this.state.cs_sort, page: this.state.cs_page, rowsPerPage: this.state.cs_rowsPerPage,
                    isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
            }
        }
    }

    setRowsPerPageWS (value) {
        this.state.ws_rowsPerPage = value
        this.state.ws_page = 0
        this.props.setRowsPerPage(value)
        this.props.getWaitingSignalsInitial({filters: this.state.ws_filtersMap,
            sort: this.state.ws_sort, page: this.state.ws_page, rowsPerPage: this.state.ws_rowsPerPage,
            isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
    }

    setPageWS (value) {
        let page = value
        if (!this.props.ws_pagesDownloaded[page+1]) {
            this.state.ws_page = page
            this.props.getWaitingSignalsByPage({filters: this.state.ws_filtersMap,
                sort: this.state.ws_sort, page: this.state.ws_page, rowsPerPage: this.state.ws_rowsPerPage,
                isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
        } else {
            this.setState({
                ws_page: page
            })
        }
        this.props.setCurrentPage(value)
    }

    onFilterChangeWS(e) {
        let filterEntry = {[e.target.name]: e.target.value}
        this.setState({
            ws_filtersMap: {...this.state.ws_filtersMap, ...filterEntry}
        })
        this.debouncedFilterWS()
    }

    onFilterWS() {
        this.props.getWaitingSignalsInitial({filters: this.state.ws_filtersMap,
            sort: this.state.ws_sort, page: this.state.ws_page, rowsPerPage: this.state.ws_rowsPerPage,
            isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
    }


    setRowsPerPageRS (value) {
        this.state.rs_rowsPerPage = value
        this.state.rs_page = 0
        this.props.setRowsPerPage(value)
        this.props.getRunningSignalsInitial({filters: this.state.rs_filtersMap,
            sort: this.state.rs_sort, page: this.state.rs_page, rowsPerPage: this.state.rs_rowsPerPage,
            isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
    }

    setPageRS (value) {
        let page = value
        if (!this.props.rs_pagesDownloaded[page+1]) {
            this.state.rs_page = page
            this.props.getRunningSignalsByPage({filters: this.state.rs_filtersMap,
                sort: this.state.rs_sort, page: this.state.rs_page, rowsPerPage: this.state.rs_rowsPerPage,
                isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
        } else {
            this.setState({
                rs_page: page
            })
        }
        this.props.setCurrentPage(value)
    }

    onFilterChangeRS(e) {
        let filterEntry = {[e.target.name]: e.target.value}
        this.setState({
            rs_filtersMap: {...this.state.rs_filtersMap, ...filterEntry}
        })
        this.debouncedFilterRS()
    }

    onFilterRS() {
        this.props.getRunningSignalsInitial({filters: this.state.rs_filtersMap,
            sort: this.state.rs_sort, page: this.state.rs_page, rowsPerPage: this.state.rs_rowsPerPage,
            isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
    }

    setRowsPerPageCS (value) {
        this.state.cs_rowsPerPage = value
        this.state.cs_page = 0
        this.props.setRowsPerPage(value)
        this.props.getClosedSignalsInitial({filters: this.state.cs_filtersMap,
            sort: this.state.cs_sort, page: this.state.cs_page, rowsPerPage: this.state.cs_rowsPerPage,
            isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
    }

    setPageCS (value) {
        let page = value
        if (!this.props.cs_pagesDownloaded[page+1]) {
            this.state.cs_page = page
            this.props.getClosedSignalsByPage({filters: this.state.cs_filtersMap,
                sort: this.state.cs_sort, page: this.state.cs_page, rowsPerPage: this.state.cs_rowsPerPage,
                isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
        } else {
            this.setState({
                cs_page: page
            })
        }
        this.props.setCurrentPage(value)
    }

    onFilterChangeCS(e) {
        let filterEntry = {[e.target.name]: e.target.value}
        this.setState({
            cs_filtersMap: {...this.state.cs_filtersMap, ...filterEntry}
        })
        this.debouncedFilterCS()
    }

    onFilterCS() {
        this.props.getClosedSignalsInitial({filters: this.state.cs_filtersMap,
            sort: this.state.cs_sort, page: this.state.cs_page, rowsPerPage: this.state.cs_rowsPerPage,
            isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
    }

    editSignal(signalId, signalType) {
        if (signalType === c.SIGNAL_TYPE_SPOT) {
            this.props.history.push(`/editsignal?signalId=${signalId}`)
        } else if (signalType === c.SIGNAL_TYPE_FUTURES) {
            this.props.history.push(`/editsignalF?signalId=${signalId}`)
        }

    }

    newSignal() {
        this.props.clearCurrentSignal(() => {
            this.props.history.push("/newsignal")
        })
    }

    newSignalF() {
        this.props.clearCurrentSignal(() => {
            this.props.history.push("/newsignalF")
        })
    }

    gotoSignalsNotifications() {
        this.props.history.push("/signalsnotifs")
    }

    openCloseSignalDialog(signalId, tab) {
        this.setState({
            closeSignalDialogOpen: true,
            signalId: signalId,
            tab
        })
    }

    hideCloseSignalDialog() {
        this.setState({
            closeSignalDialogOpen: false
        })
    }

    closeSignal() {
        if (this.state.closeSignalPressed) {
            return
        } else {
            this.setState({
                closeSignalPressed: true
            })
        }
        this.state.closeSignalDialogOpen = false
        if (this.state.tab === WAITING_SIGNALS_TAB) {
            this.props.closeWaitingSignal({signalId: this.state.signalId,
                isUserSuperAdmin: isUserSuperAdmin(this.props.user),
                page: 0,
                rowsPerPage: this.state.rs_rowsPerPage}, () => {
                this.setState({
                    closeSignalPressed: false
                })
            })
        } else if (this.state.tab === RUNNING_SIGNALS_TAB) {
            this.props.closeRunningSignal({signalId: this.state.signalId,
                isUserSuperAdmin: isUserSuperAdmin(this.props.user),
                page: 0,
                rowsPerPage: this.state.rs_rowsPerPage}, () => {
                this.setState({
                    closeSignalPressed: false
                })
            })
        }

    }



    componentDidMount() {
        if (!this.props.selectedTab || this.props.selectedTab === WAITING_SIGNALS_TAB) {
            this.props.getWaitingSignalsInitial({filters: this.state.ws_filtersMap,
                sort: this.state.ws_sort, page: this.state.ws_page, rowsPerPage: this.state.ws_rowsPerPage,
                isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
            this.props.getTradersSubscriptions()
        } else if (!this.props.selectedTab || this.props.selectedTab === RUNNING_SIGNALS_TAB) {
            this.props.getRunningSignalsInitial({filters: this.state.rs_filtersMap,
                sort: this.state.rs_sort, page: this.state.rs_page, rowsPerPage: this.state.rs_rowsPerPage,
                isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
            this.props.getTradersSubscriptions()
        }
        this.props.getThisMonthTraderCommissions()
    }

    render() {
        let tabs = []
        let waitingTitle = 'Waiting', runningTitle = 'Running', closedTitle = 'Closed'
        if (this.props.ws_docs && this.props.ws_docs.length > 0)
            waitingTitle = `Waiting (${this.props.ws_docs.length})`
        if (this.props.rs_docs && this.props.rs_docs.length > 0)
            runningTitle = `Running (${this.props.rs_docs.length})`
        if (this.props.cs_docs && this.props.cs_docs.length > 0)
            closedTitle = `Closed (${this.props.cs_docs.length})`

        let subscriptionsHash = {}
        if (this.props.subscriptions) {
            this.props.subscriptions.map((elem, index) => {
                subscriptionsHash[elem._id] = elem
            })
        }

        let isSuperAdmin = isUserSuperAdmin(this.props.user)

        tabs.push({title: waitingTitle, content: <WaitingSignalsV1
                tab={WAITING_SIGNALS_TAB}
                waitingSignals={this.props.ws_docs}
                page={this.state.ws_page}
                rowsPerPage={this.state.ws_rowsPerPage}
                totalDocs={this.props.ws_totalDocs}
                filtersMap={this.state.ws_filtersMap}
                setRowsPerPage={this.setRowsPerPageWS}
                setPage={this.setPageWS}
                onFilterChange={this.onFilterChangeWS}
                subscriptionsHash={subscriptionsHash}
                editSignal={this.editSignal}
                closeSignal={this.openCloseSignalDialog}
                isSuperAdmin={isSuperAdmin}
            />})
        tabs.push({title: runningTitle, content: <RunningSignalsV1
                tab={RUNNING_SIGNALS_TAB}
                runningSignals={this.props.rs_docs}
                page={this.state.rs_page}
                rowsPerPage={this.state.rs_rowsPerPage}
                totalDocs={this.props.rs_totalDocs}
                filtersMap={this.state.rs_filtersMap}
                setRowsPerPage={this.setRowsPerPageRS}
                setPage={this.setPageRS}
                onFilterChange={this.onFilterChangeRS}
                subscriptionsHash={subscriptionsHash}
                editSignal={this.editSignal}
                closeSignal={this.openCloseSignalDialog}
                isSuperAdmin={isSuperAdmin}
            />})
        tabs.push({title: closedTitle, content: <ClosedSignalsV1
                closedSignals={this.props.cs_docs}
                page={this.state.cs_page}
                rowsPerPage={this.state.cs_rowsPerPage}
                totalDocs={this.props.cs_totalDocs}
                filtersMap={this.state.cs_filtersMap}
                setRowsPerPage={this.setRowsPerPageCS}
                setPage={this.setPageCS}
                onFilterChange={this.onFilterChangeCS}
                subscriptionsHash={subscriptionsHash}
                isSuperAdmin={isSuperAdmin}
            />})

        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <div style={{display: "flex"}}>
                <div style={{marginLeft: "1.2rem", marginTop: "0.4rem", marginRight:"0.6rem"}}>
                    <span style={{opacity: "1", fontWeight: "bold"}}>new</span> <span style={{opacity: "0.6"}}>signal on</span>
                </div>
                <Button onClick={()=>{this.newSignal()}}>Spot</Button>
                <Button onClick={()=>{this.newSignalF()}}>Futures</Button>
                {showThisMonthTraderCommissions(this.props.thisMonthTraderCommissions)}
            </div>

            {/*<div>Current page {this.props.currentPage}/ Rows per page {this.props.rowsPerPage}, Tab {this.props.selectedTab}</div>*/}
            <NavTabs tabs={tabs} selectedTab={this.props.selectedTab >=0 ? this.props.selectedTab: RUNNING_SIGNALS_TAB}
                     changeTab={this.changeTab}
                     tabContainerClass={"tabsContainer"}/>
            <AlertDialog
                open={this.state.closeSignalDialogOpen}
                title={"Close Signal"}
                texts={["Are you sure you want to close this Signal?", "Attention: this operation cannot be undone."]}
                handleOk={this.closeSignal}
                handleCancel={this.hideCloseSignalDialog}
            />
</div>
}
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    selectedTab: rootState.signals.selectedTab,
    currentPage: rootState.signals.currentPage,
    rowsPerPage: rootState.signals.rowsPerPage,

    ws_docs: rootState.signals.wsv1_docs,
    ws_totalDocs: rootState.signals.wsv1_totalDocs,
    ws_totalPages: rootState.signals.wsv1_totalPages,
    ws_pagesDownloaded: rootState.signals.wsv1_pagesDownloaded,

    rs_docs: rootState.signals.rsv1_docs,
    rs_totalDocs: rootState.signals.rsv1_totalDocs,
    rs_totalPages: rootState.signals.rsv1_totalPages,
    rs_pagesDownloaded: rootState.signals.rsv1_pagesDownloaded,

    cs_docs: rootState.signals.csv1_docs,
    cs_totalDocs: rootState.signals.csv1_totalDocs,
    cs_totalPages: rootState.signals.csv1_totalPages,
    cs_pagesDownloaded: rootState.signals.csv1_pagesDownloaded,

    subscriptions: rootState.traders.subscriptions,
    thisMonthTraderCommissions: rootState.traders.thisMonthTraderCommissions
})

let mapDispatchToProps = dispatch => {
    return {
        selectTab: (tab) => dispatch(selectTab(tab)),
        getWaitingSignalsInitial: (props) => dispatch(getWaitingSignalsInitialV1(props)),
        getWaitingSignalsByPage: (props) => dispatch(getWaitingSignalsByPageV1(props)),
        getRunningSignalsInitial: (props) => dispatch(getRunningSignalsInitialV1(props)),
        getRunningSignalsByPage: (props) => dispatch(getRunningSignalsByPageV1(props)),
        getClosedSignalsInitial: (props) => dispatch(getClosedSignalsInitialV1(props)),
        getClosedSignalsByPage: (props) => dispatch(getClosedSignalsByPageV1(props)),
        getTradersSubscriptions: (props) => dispatch(getTradersSubscriptions(props)),
        clearCurrentSignal: (cb) => dispatch(clearCurrentSignal(cb)),
        closeWaitingSignal: (props, cb) => dispatch(closeWaitingSignalV1(props, cb)),
        closeRunningSignal: (props, cb) => dispatch(closeRunningSignalV1(props, cb)),
        setCurrentPage: currentPage => dispatch(setCurrentPage(currentPage)),
        setRowsPerPage: rowsPerPage => dispatch(setRowsPerPage(rowsPerPage)),
        getThisMonthTraderCommissions: rowsPerPage => dispatch(getThisMonthTraderCommissions(rowsPerPage))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (SignalsV1)
