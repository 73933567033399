import React, { useState, useEffect } from "react";
import c from '../../../constants'
import { TextField, InputAdornment } from "@material-ui/core";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { validateAddress, validateMemo } from "../../../redux/actions/wallet";
import { CHANGE_TO_WITHDRAWALS_ALLOW_TAB } from "../../../redux/actions/actionTypes";
import { WithdrawCustomHeader } from "../../fncomponents/CustomHeaders";
import {brushValue} from "../../fncomponents/UISupportFns";

const Withdraw = ({ selectedWithdrawCoin, changeRenderCustomHeader }) => {
  
  const { coin, availableBalance, name, withdrawalFee, hasMemo, minimumWithdrawal, isFuture } =
    selectedWithdrawCoin;

  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [withdrawAmountError, setWithdrawAmountError] = useState(false);
  const [recipientAddress, setRecipientAddress] = useState("");
  const [recipientAddressError, setRecipientAddressError] = useState(false);
  const [withdrawMemo, setWithdrawMemo] = useState(null);
  const [withdrawMemoError, setWithdrawMemoError] = useState(false);

  const dispatch = useDispatch();
  

  useEffect(() => {
    changeRenderCustomHeader(() => <WithdrawCustomHeader />);
  }, []);

  useEffect(() => {
    if( withdrawAmount === '') {
      return;
    }

    if (withdrawAmount > availableBalance) {
      setWithdrawAmountError("Insufficient balance");
    } else if (withdrawAmount < 0) {
      setWithdrawAmountError("Amount should be positive");
    } else if (withdrawAmount < minimumWithdrawal) {
      setWithdrawAmountError(`Below minimum withdrawal: ${minimumWithdrawal}`);
    } else {
      setWithdrawAmountError(null);
    }
  }, [withdrawAmount]);

  useEffect(() => {
    setRecipientAddressError(null);
  }, [recipientAddress]);

  useEffect(() => {
    setWithdrawMemoError(null);
  }, [withdrawMemo]);

  const handlePaste = async () => {
    // const queryOpts = { name: "clipboard-read", allowWithoutGesture: false };
    // const permissionStatus = await navigator.permissions.query(queryOpts);
    // if (
    //   permissionStatus.state === "granted" ||
    //   permissionStatus.state === "prompt"
    // ) {

    try {
      navigator.clipboard
        .readText()
        .then((clipText) => {setRecipientAddress(clipText); checkAdress(clipText)});
    } catch(err){
      console.log("Failed to paste: ", err)
  }
  };

  const checkAdress = async (rA) => {
      let _recipientAddress = recipientAddress
      if (rA && !(rA instanceof Object)) {
          _recipientAddress = rA
      }
        await validateAddress(
          {
            coin: coin,
            address: _recipientAddress,
          },
          (result) => {
            if (result.status === "valid") {
              setRecipientAddressError(null);
            } else {
              setRecipientAddressError("Invalid address");
            }
          }
        )(dispatch);
  };

  const checkMemo = async () => {
    await validateMemo(
      {
        coin: coin,
        memo: withdrawMemo,
      },
      (result) => {
        if (result.status === "valid") {
          setWithdrawMemoError(null);
        } else {
          setWithdrawMemoError("Memo error");
        }
      }
    )(dispatch);
  };

  return (
    <div>
      <div className="withdraw__top">
        <img
          src={`imgs/coins/${coin?.toUpperCase()}_${c.COIN_LOGOS_PIC_VERSION}.png`}
          className="balance__coinLogo"
          alt="icon"
        />
        <div className="balance__title">
          <span>{coin}</span>
          <span className="balance__dot"> · </span>
          {isFuture ? "FUTURES" : <span>{name}</span>}
          {" "}
          <span>Available</span>
        </div>
        <div className="balance__amount">{brushValue(availableBalance)}</div>
        <Button
          style={{
            color: "#17BEBB",
            display: "flex",
            width: "161px",
            margin: "0 auto",
            height: "30px",
            textAlign: "center",
            textTransform: "none",
          }}
          onClick={() => setWithdrawAmount(availableBalance)}
        >
          Use Max.
        </Button>
      </div>
      <div className="withdraw__form">
        <TextField
          style={{ borderRadius: "12px" }}
          variant="outlined"
          error={recipientAddressError}
          value={recipientAddress}
          label={`Recipient\'s ${coin}  Address`}
          placeholder={`Recipient\'s ${coin}  Address`}
          margin={"normal"}
          helperText={recipientAddressError}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button onClick={handlePaste} style={{ minWidth: "0" }}>
                  <AssignmentOutlinedIcon />
                </Button>
              </InputAdornment>
            ),
          }}
          onBlur={checkAdress}
          onChange={(e) => setRecipientAddress(e.target.value)}
        />
        {hasMemo && (
          <TextField
          style={{ borderRadius: "12px" }}
            variant="outlined"
            error={withdrawMemoError}
            value={withdrawMemo}
            label={`Memo/Tag`}
            margin={"normal"}
            onChange={(e) => setWithdrawMemo(e.target.value)}
            onBlur={checkMemo}
          />
        )}
      <TextField
          style={{ borderRadius: "12px" }}
          type="number"
          variant="outlined"
          label="Amount"
          placeholder="Amount"
          margin={"normal"}
          value={withdrawAmount}
          helperText={withdrawAmountError}
          onChange={(e) => setWithdrawAmount(e.target.value)}
          InputProps={{
              endAdornment: (
                  <InputAdornment position="end">{coin}</InputAdornment>
              ),
          }}
          error={withdrawAmountError}
      />
          <div className={"withdraw-allow__details"} style={{opacity: "0.8", marginLeft: "0.7rem", marginTop: "0.4rem"}}>
              <div style={{marginBottom: "0.4rem"}}>Minimum withdrawal {brushValue(minimumWithdrawal)} {coin}</div>
              <div style={{marginBottom: "0.2rem"}}>Transaction fee {brushValue(withdrawalFee)} {coin}</div>
              {withdrawAmount >= minimumWithdrawal && <div>Final amount <b>{brushValue(withdrawAmount - withdrawalFee)}</b> {coin}</div>}
          </div>

        <Button
          style={{
            backgroundColor: "#17BEBB",
            display: "flex",
            justifyContent: "space-evenly",
            width: "161px",
            margin: "20px auto",
            borderRadius: "32px",
            height: "48px",
            textAlign: "center",
            color: "#080F18",
          }}
          onClick={ () => {
            if (
              withdrawAmountError !== null ||
              (hasMemo && withdrawMemoError !== null) ||
              recipientAddressError !== null
            )
              return;
              
            dispatch({
              type: CHANGE_TO_WITHDRAWALS_ALLOW_TAB,
              payload: {
                recipientAddress,
                withdrawAmount,
                newBalance: availableBalance - withdrawAmount,
                withdrawalFee,
                toMemo: withdrawMemo,
                selectedWithdrawCoin,
              },
            });
          }}
        >
          <VisibilityIcon />
          PREVIEW
        </Button>
      </div>
    </div>
  );
};

export default Withdraw;
