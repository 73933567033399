import React from "react"
import Button from '@material-ui/core/Button'
import InputAdornment from "@material-ui/core/InputAdornment"
import {TextField} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Gavel from '@material-ui/icons/Gavel'
import c from '../../constants'
import {scientificToDecimal, brushValue} from "./UISupportFns"

const useStyles = makeStyles(theme => ({

    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 270,
        backgroundColor: '#101A26'
    },
    button: {
        minWidth: 100,
        marginRight: 10,
        marginTop: 10,
        borderRadius: 100,
        height: 50
    },
    buttonError: {
        borderColor: '#FB4A4A',
        minWidth: 100,
        marginRight: 10,
        marginTop: 10,
        borderRadius: 100,
        height: 50
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    }
}))

export default function BuySellAtFutures({futuresType, actionTypeOption,
                                             actionTypeOptionError,
                                      handleChangeActionTypeOption,
                                      quote,
                                      base,
                                      fixedPrice, fixedPriceError,
                                      handleChangeFixedPrice,
                                      currentLastBidPrice,
                                      currentLastBidPriceOrderBook,
                                      hideTitle,
                                      tickSize
                                  }) {
    const classes = useStyles()
    const [selectedActionTypeOption, setSelectedActionTypeOption] = React.useState(0)
    const [textFieldEnabled, setTextFieldEnabled] = React.useState(true)
    const [_currentLastBidPrice, setCurrentLastBidPrice] = React.useState("0")

    React.useEffect(()=> {
        if (quote.length < 2) {
            setSelectedActionTypeOption(-1)
            return
        }

        if (currentLastBidPrice >= 0) {
            setCurrentLastBidPrice(brushValue(currentLastBidPrice, tickSize))
        }

        switch (actionTypeOption) {
            case c.FUTURES_ACTION_TYPE_OPTION_FIXED_PRICE: setSelectedActionTypeOption(0); break
            case c.FUTURES_ACTION_TYPE_OPTION_MARKET: setSelectedActionTypeOption(1); break
            case c.FUTURES_ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE: setSelectedActionTypeOption(2); break
            case c.FUTURES_ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE: setSelectedActionTypeOption(2); break
            default : setSelectedActionTypeOption(-1); break
        }

        if (actionTypeOption === c.FUTURES_ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE ||
            actionTypeOption === c.FUTURES_ACTION_TYPE_OPTION_MARKET) {
            setTextFieldEnabled(false)
        } else {
            setTextFieldEnabled(true)
        }
    })

    function getTitle() {
        if (futuresType === c.FUTURES_TYPE_LONG) {
            return <div>How will you <u>buy</u></div>
        } else if (futuresType === c.FUTURES_TYPE_SHORT) {
            return <div>How will you <u>sell</u></div>
        }
    }

    function getPosSizeCurrency() {
        return base ? base.toUpperCase() : "..."
    }

    function getPercentForDipPrice() {
        let percent = brushValue((fixedPrice - _currentLastBidPrice) / _currentLastBidPrice * 100, 1)
        let sign = percent > 0 ? '+': ''
        let color = percent < 0 ? '#FB4A4A': 'white'
        return <div style={{color: color, marginLeft: "0.2rem"}}>{`(${sign}${percent}%)`}</div>
    }

    function getVerb() {
        if (futuresType === c.FUTURES_TYPE_LONG)
            return "buying"
        else if (futuresType === c.FUTURES_TYPE_SHORT)
            return "selling"
    }

    function getButtonClass() {
        if (actionTypeOptionError) {
            return classes.buttonError
        }
        return classes.button
    }

    return (<div className={"wizardSectionContainer"}>
        {!hideTitle && <div className={"wizardTitle"}>
            <span className={"wizardTitleIcon"}><Gavel/></span> {getTitle()}
        </div>}
        <div>
            <div style={{display: "flex", justifyContent: "flex-start", flexWrap: "wrap", marginBottom: "0.6em", marginLeft: "0.6em"}}>
                <Button className={getButtonClass()} variant={selectedActionTypeOption === 0 ? "contained": "outlined"} color={"primary"}
                        onClick={()=>{handleChangeActionTypeOption(c.FUTURES_ACTION_TYPE_OPTION_FIXED_PRICE)}}>Fixed price</Button>
                <Button className={getButtonClass()} variant={selectedActionTypeOption === 1 ? "contained": "outlined"} color={"primary"}
                        onClick={()=>{handleChangeActionTypeOption(c.FUTURES_ACTION_TYPE_OPTION_MARKET)}}>Market</Button>
                {futuresType === c.FUTURES_TYPE_LONG && <Button className={getButtonClass()} variant={selectedActionTypeOption === 2 ? "contained": "outlined"} color={"primary"}
                        onClick={()=>{handleChangeActionTypeOption(c.FUTURES_ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE)}}>Just above bid</Button>}
                {futuresType === c.FUTURES_TYPE_SHORT && <Button className={getButtonClass()} variant={selectedActionTypeOption === 2 ? "contained": "outlined"} color={"primary"}
                        onClick={()=>{handleChangeActionTypeOption(c.FUTURES_ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE)}}>Just below ask</Button>}<br/>
                <br/>
            </div>
            {actionTypeOptionError && <span className={"errorGeneralMessageSmall"} style={{marginLeft: "0.76rem", marginTop: "-1rem", opacity: "0.9"}}>{actionTypeOptionError}</span>}
            {selectedActionTypeOption === 0 &&
            <div style={{marginLeft: "0.8em", fontSize: "0.8rem", width: "20rem"}}>
                Open futures position by {getVerb()} at a fixed price.
            </div>}
            {selectedActionTypeOption === 1 &&
            <div style={{marginLeft: "0.8em", fontSize: "0.8rem", width: "20rem"}}>
                Open futures position by {getVerb()} at market price.
            </div>}
            {selectedActionTypeOption === 2 &&
            <div style={{marginLeft: "0.8em", fontSize: "0.8rem", width: "20rem"}}>
                {futuresType === c.FUTURES_TYPE_LONG && <span>Open futures position by {getVerb()} at just above the 1st bid price.</span>}
                {futuresType === c.FUTURES_TYPE_SHORT && <span>Open futures position by {getVerb()} at just below the 1st ask price.</span>}
            </div>}
        </div>
        <div style={{marginTop: "1em"}}>
            <TextField
                id="outlined-adornment-weight_buy_sell_at_futures"
                className={classes.textField}
                variant="outlined"
                margin="dense"
                disabled={!textFieldEnabled}
                value={fixedPrice}
                error={fixedPriceError}
                onChange={handleChangeFixedPrice}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {selectedActionTypeOption === 3 && <span>&le;</span>}
                        </InputAdornment>
                    ),
                    endAdornment: <InputAdornment position="end">
                        <div style={{display: "flex"}}>
                        {getPosSizeCurrency()}
                        {selectedActionTypeOption === 3 && getPercentForDipPrice()}
                        </div>
                    </InputAdornment>,
                }}
                inputProps={{ maxLength: 16 }}
            />
            {fixedPriceError && <div className={"warning"} style={{fontSize: "0.8rem", marginLeft: "0.6rem"}}>{fixedPriceError}</div>}
            {/*{currentLastBidPrice && <div className={"currentLastBidPrice"}>Last price <b>&nbsp;{_currentLastBidPrice}&nbsp;{base}</b></div>}*/}
            {currentLastBidPriceOrderBook && <div className={"currentLastBidPrice"}>Last price <b>&nbsp;{currentLastBidPriceOrderBook}&nbsp;{base}</b></div>}
        </div>
    </div>)
}
