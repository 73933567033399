import React from "react";
import {TextField, Tooltip} from "@material-ui/core"
import InputAdornment from "@material-ui/core/InputAdornment"
import makeStyles from "@material-ui/core/styles/makeStyles"
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet'
import Slider from '@material-ui/core/Slider'
import {addCommasToLeftSideOfTheNumber, brushValue} from "./UISupportFns"
import s from '../../settings'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(1),
        width: 270,
        backgroundColor: '#101A26'
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },

    sliderContainer: {
        marginTop: 10,
        width: 300,
    },

    buySellContainer: {
        marginTop: 10,
        width: 300,
        display: "flex",
        justifyContent: "space-between"
    }
}))

export default function SetPosSizeFutures(props) {
    const {posSize, handleChangePosSize,
        posSizeSlider, handleChangePosSizeSlider,
        quote, base,
        notionalValue,
        initialMargin,
        additionalIsolatedMargin,
        openLoss,
        size,
        cost,
        totalCost,
        max,
        precision,
        tickSize,
        otherPosSizeErrors,
        price,
        liquidationPrice,
        maintenanceMargin,
        mMarginRate,
        percentageLiquidationToEntry
    } = props
    const classes = useStyles()

    const marks = [
        {
            value: 0,
            label: <span style={{marginLeft: "1rem"}}>0%</span>,
        },
        {
            value: 25,
            label: '25%',
        },
        {
            value: 50,
            label: '50%',
        },
        {
            value: 75,
            label: '75%',
        },
        {
            value: 100,
            label: '100%',
        },
    ];

    function valueLabelFormat(value) {
        return `${value}%`
    }

    function getExplanationsForSize() {
        let indexOfP = -1
        try {
            indexOfP = posSize.indexOf("%")
        } catch (e) {}
        if (indexOfP > 0) {
            return <span style={{fontSize: "0.7rem"}}>(calculated)</span>
        } else {
            return <span style={{fontSize: "0.7rem"}}>(user filled)</span>
        }
    }

    function showOpenOrderDetails() {
        const enterTouchDelay = s.enterTouchDelay
        return <div>
            <div style={{marginTop: "0.4rem"}}>
                <Tooltip title={<span className={"toolTip"}>{`The size of your trade`}</span>} placement="right" enterTouchDelay={enterTouchDelay}>
                    <span className={"newFTradeFieldsWithExplanations"}>Size:</span>
                </Tooltip>
                <span style={{fontSize: "1rem", fontWeight: "bold"}}>{size}</span> <b>{quote}</b> (&nbsp;&nbsp;<Tooltip title={<span className={"toolTip"}>Notional value = size * entry price</span>} placement="right" enterTouchDelay={enterTouchDelay}>
                <span className={"newFTradeFieldsWithExplanations"}>{addCommasToLeftSideOfTheNumber(notionalValue)} USDT</span></Tooltip>)
            </div>
            <div style={{marginTop: "0.4rem"}}>
                <Tooltip title={<span className={"toolTip"}>The price at which you will open the futures trade</span>} placement="right" enterTouchDelay={enterTouchDelay}>
                <span className={"newFTradeFieldsWithExplanations"}>Entry price:</span>
                </Tooltip>{price}
            </div>
            <div style={{marginTop: "0.8rem"}}>
                <Tooltip title={<span className={"toolTip"}>Initial margin + additional isolated margin</span>} placement="right" enterTouchDelay={enterTouchDelay}>
                    <span className={"newFTradeFieldsWithExplanations"}>Total cost:</span>
                </Tooltip>
                <span style={{fontSize: "1rem", fontWeight: "bold"}}>{totalCost} USDT</span>
            </div>
            <div style={{marginTop: "0.8rem"}}>
                <Tooltip title={<span className={"toolTip"}>The value you commit to open this position</span>} placement="right" enterTouchDelay={enterTouchDelay}>
                <span className={"newFTradeFieldsWithExplanations"}>Initial margin:</span>
                </Tooltip>
            {initialMargin}
            </div>
            <div style={{marginTop: "0.4rem"}}>
                <Tooltip title={<span className={"toolTip"}>{`The additional margin (besides the initial margin) needed to move the liquidation price past over your stop loss. In order to start a trade you need at least the following amount in your wallet: initial margin + open loss + additional margin.`}</span>} placement="right" enterTouchDelay={enterTouchDelay}>
                    <span className={"newFTradeFieldsWithExplanations"}>Additional margin:</span>
                </Tooltip>
                {additionalIsolatedMargin}
            </div>
            {/*<div style={{marginTop: "0.4rem"}}>*/}
            {/*    <Tooltip title={<span className={"toolTip"}>Initial margin + open loss</span>} placement="right" enterTouchDelay={enterTouchDelay}>*/}
            {/*        <span className={"newFTradeFieldsWithExplanations"}>Cost:</span>*/}
            {/*    </Tooltip>*/}
            {/*    {cost}*/}
            {/*</div>*/}

            {/*<div style={{marginTop: "0.4rem"}}>*/}
            {/*    <Tooltip title={<span className={"toolTip"}>Additional cost due to differences between your entry price and current mark price</span>} enterTouchDelay={enterTouchDelay}>*/}
            {/*        <span className={"newFTradeFieldsWithExplanations"}>Open loss:</span>*/}
            {/*    </Tooltip>*/}
            {/*    {openLoss}</div>*/}
            <div style={{marginTop: "0.4rem"}}>
                <Tooltip title={<span className={"toolTip"}>{`The minimum margin needed to keep the position open. Calculated as ${mMarginRate}% from the notional value.`}</span>} placement="right" enterTouchDelay={enterTouchDelay}>
                    <span className={"newFTradeFieldsWithExplanations"}>Maintenance margin:</span>
                </Tooltip>
                {brushValue(maintenanceMargin, tickSize)}
            </div>

            <div style={{marginTop: "0.4rem"}}>
                <Tooltip title={<span className={"toolTip"}>{`The price at which your trade will be liquidated. Usually should be past over your stop loss so that you wont be liquidated and your trade be suddenly terminated.`}</span>} placement="right" enterTouchDelay={enterTouchDelay}>
                    <span className={"newFTradeFieldsWithExplanations"}>Liq. Price:</span>
                </Tooltip>
                {liquidationPrice} <small>({percentageLiquidationToEntry}%)</small></div>
            {/*<div style={{marginTop: "0.4rem"}}>*/}
            {/*    <Tooltip title={<span className={"toolTip"}>{`The maximum size you can afford with the available margin in your wallet.`}</span>} placement="right" enterTouchDelay={enterTouchDelay}>*/}
            {/*        <span className={"newFTradeFieldsWithExplanations"}>Max:</span>*/}
            {/*    </Tooltip>*/}
            {/*    {max}*/}
            {/*    </div>*/}
        </div>
    }

    return (
    <div className={"wizardSectionContainer"}>
        <div className={"wizardTitle"}>
            <span className={"wizardTitleIcon"}><AccountBalanceWallet/></span> Position size
        </div>
        <div style={{marginLeft: "0.6em"}}>
            <div style={{marginLeft:"-2px"}}>
                <TextField
                    id="outlined-adornment-weight_set_pos_size_futures"
                    className={classes.textField}
                    helperText={otherPosSizeErrors}
                    margin="dense"
                    variant="outlined"
                    placeholder={"Amount to trade"}
                    value={posSize}
                    onChange={handleChangePosSize}
                    error={otherPosSizeErrors !== undefined}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{quote}</InputAdornment>,
                    }}
                    inputProps={{ maxLength: 16 }}
                />
            </div>
            <div className={classes.sliderContainer}>
                <Slider
                    defaultValue={20}
                    valueLabelFormat={valueLabelFormat}
                    aria-labelledby="discrete-slider-restrict"
                    step={5}
                    value={posSizeSlider}
                    onChange={handleChangePosSizeSlider}
                    valueLabelDisplay="auto"
                    marks={marks}
                />
            </div>
            <div className={classes.buySellContainer}>
                {showOpenOrderDetails()}
            </div>
        </div>
    </div>)
}
