import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Tabs from "@material-ui/core/Tabs"
import Tab from '@material-ui/core/Tab'
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import {TextField} from "@material-ui/core";


function TabContainer(props) {
    const {className} = props
    return (
        <Typography component="div" className={className}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={event => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

const AntTabs = withStyles({
    root: {
        color: "white"
    },
    indicator: {
        backgroundColor: '#17BEBB',
    },
})(Tabs);

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 0,
        backgroundColor: "#101A26",
        position: 'relative',
        minHeight: 300,
    },
    textField: {
        width: "100%",
        backgroundColor: '#424242',
        fontSize: "0.8rem",
    },
    //style for font size
    resize:{
        fontSize:18
    },
}));

export default function AddRemoveIsolatedMarginDialog(props) {
    const classes = useStyles()
    const {open, handleOk, handleCancel, margin, marginError, handleChangeMargin, marginOperation, handleChangeMarginOperation, changeMarginToMax, changeMarginToMaxRemovable, maxRemovable, maxAvailableBalance, estimatedLiqPrice, marginForCurrentTrade} = props
    const [value, setValue] = React.useState(0)

    function handleChange(event, newValue) {
        setValue(newValue)
    }

    React.useEffect(() => {
        if (marginOperation === "ADD") {
            setValue(0)
        } else if (marginOperation === "REMOVE") {
            setValue(1)
        }
    })

    function getAddMarginTabContent() {
        return <div style={{marginTop: "1rem", marginBottom: "1rem"}} className={"addRemoveMarginText"}>
            <div>Amount(USDT)</div>
            <div style={{marginTop: "0.8rem", marginBottom: "1.2rem"}}>
                <TextField
                    id="outlined-adornment-added_margin"
                    className={classes.textField}
                    value={margin}
                    error={marginError}
                    onChange={handleChangeMargin}
                    placeholder={"Type the amount"}
                    InputProps={{
                        classes: {
                            input: classes.resize,
                        },
                        endAdornment: <InputAdornment position="end">
                            <div style={{display: "flex", cursor: "pointer"}} className={"addRemoveMarginText"} onClick={changeMarginToMax}>
                                Max: {maxAvailableBalance} USDT
                            </div>
                        </InputAdornment>,
                    }}
                    inputProps={{ maxLength: 16 }}
                />
            </div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div>Current Margin for Trade</div>
                <div className={"addRemoveMarginAmounts"}>{marginForCurrentTrade} USDT</div>
            </div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div>Available Balance</div>
                <div className={"addRemoveMarginAmounts"}>{maxAvailableBalance} USDT</div>
            </div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div>Est. Liq. Price after increment</div>
                <div className={"addRemoveMarginAmounts"}>{estimatedLiqPrice} USDT</div>
            </div>
        </div>
    }

    function getRemoveMarginTabContent() {
        return <div style={{marginTop: "1rem", marginBottom: "1rem"}} className={"addRemoveMarginText"}>
            <div>Amount(USDT)</div>
            <div style={{marginTop: "0.8rem", marginBottom: "1.2rem"}}>
                <TextField
                    id="outlined-adornment-added_margin"
                    className={classes.textField}
                    value={margin}
                    error={marginError}
                    onChange={handleChangeMargin}
                    placeholder={"Type the amount"}
                    InputProps={{
                        classes: {
                            input: classes.resize,
                        },
                        endAdornment: <InputAdornment position="end">
                            <div style={{display: "flex", cursor: "pointer"}} className={"addRemoveMarginText"} onClick={changeMarginToMaxRemovable}>
                                Max: {maxRemovable} USDT
                            </div>
                        </InputAdornment>,
                    }}
                    inputProps={{ maxLength: 16 }}
                />
            </div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div>Current Margin for Trade</div>
                <div className={"addRemoveMarginAmounts"}>{marginForCurrentTrade} USDT</div>
            </div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div>Max. removable</div>
                <div className={"addRemoveMarginAmounts"}>{maxRemovable} USDT</div>
            </div>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div>Est. Liq. Price after decrement</div>
                <div className={"addRemoveMarginAmounts"}>{estimatedLiqPrice} USDT</div>
            </div>
        </div>
    }

    return (
        <Dialog
            open={open ? open : false}
            onClose={handleCancel}
        >
            <DialogContent>
                <div>
                    <AntTabs variant="fullWidth" value={value} onChange={handleChange} key={Math.random()}>
                        <LinkTab key={`arm_tabs_link_${Math.random()}`} label={"Add Margin"} className={classes.linkTab} onClick={() => handleChangeMarginOperation("ADD")}/>
                        <LinkTab key={`arm_tabs_link_${Math.random()}`} label={"Remove Margin"} className={classes.linkTab} onClick={() => handleChangeMarginOperation("REMOVE")}/>
                    </AntTabs>
                </div>
                {value === 0 && <TabContainer>
                    {getAddMarginTabContent()}
                </TabContainer>}
                {value === 1 && <TabContainer>
                    {getRemoveMarginTabContent()}
                </TabContainer>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOk} color="primary">
                    <span>Confirm</span>
                </Button>
                <Button onClick={handleCancel} color="primary" autoFocus>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
