import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import DrawerContent from "./DrawerContent";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
    list: {
        width: 250,
    }
})

export default function TemporaryDrawer(props) {
    const {isDrawerOpen, toggleDrawer, user,
        isAdmin, isSuperAdmin, isSignalsManager, isTrader,
        isAuthenticated, handleLogout, isMobile, location, totals,
        markets, handleChangeMarkets
    } = props
    return (
        <div>
            <Drawer open={isDrawerOpen} onClose={toggleDrawer}>
                {/*<div className={classes.list} role="presentation"*/}
                {/*     onClick={toggleDrawer} onKeyDown={toggleDrawer}>*/}
                    <DrawerContent user={user} isAuthenticated={isAuthenticated}
                                   isAdmin={isAdmin}
                                   isSuperAdmin={isSuperAdmin}
                                   isSignalsManager={isSignalsManager}
                                   isTrader={isTrader}
                                   handleLogout={handleLogout}
                                   isMobile={isMobile}
                                   location={location}
                                   totals={totals}
                                   toggleDrawer={toggleDrawer}
                                   markets={markets}
                                   handleChangeMarkets={handleChangeMarkets}
                    />
                {/*</div>*/}
            </Drawer>
        </div>
    )
}
