import React, {Component} from 'react'
import {connect} from "react-redux"
import NavTabs from "../../fncomponents/NavTabs"
import FuturesTradesHistory from "./FuturesTradesHistory"
import c from '../../../constants'
import CsvDownloader from 'react-csv-downloader'
import {initWS, closeWS} from "../../../redux/actions/wsPrices"
import {getTitle} from "../../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"
import AlertDialog from "../../fncomponents/AlertDialog"
import withGetScreenExt from "../../../withGetScreenExt"
import {getActiveSuspendedActivities} from "../../../redux/actions/administration"
import {Info, Sort} from "@material-ui/icons"
import VirtualScrollerContainer from "./VirtualScrollerContainer"
import {
    brushValue, getCurrentPrice,
    isFuturesClosedOrder,
    isFuturesClosedPosition,
    isFuturesOpenedOrder,
    isFuturesOpenedPosition,
} from "../../fncomponents/UISupportFns"
import {withRouter} from "react-router-dom"
import SimplePopOver from "../../fncomponents/SimplePopOver"
import TradesSortingRadioGroup from "../../fncomponents/TradesSortingRadioGroup"
import {setIndex} from "../../../redux/virtualScrollerState"
import Cookies from 'universal-cookie'
import {
    openFuturesTrade,
    getHistory,
    downloadHistoryForUser,
    getAllVisibleFuturesTrades, reSortVisibleFuturesTrades, modifyIsolatedMargin, computeLiquidationPrice
} from "../../../redux/actions/futures"
import {activateFuturesOption, hasFuturesActivated} from "../../../redux/actions/profile"
import ActivateFuturesOption from "../../fncomponents/ActivateFuturesOption"
import AddRemoveIsolatedMarginDialog from "../../fncomponents/AddRemoveIsolatedMarginDialog"
import {getUserBaseBalance} from "../../../redux/actions/newFutures";
import {debounce} from "../../hoc/myUtils";
const cookies = new Cookies()


const DEBOUNCE_DELAY_IN_MS = 500

class Futures extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedStats: "monthly",
            historyFilterSelectedMonth: 0,
            historyFilterSelectedYear: 0,
            isSortPopupOpened: false,
            tabsSortOptions: {}
        }
        this.changeTab = this.changeTab.bind(this)
        this.changeSelectedStats = this.changeSelectedStats.bind(this)
        this.changeHistoryFilter = this.changeHistoryFilter.bind(this)

        this.getTotalsPeriodically = this.getTotalsPeriodically.bind(this)
        this.confirmAction = this.confirmAction.bind(this)
        this.downloadHistory = this.downloadHistory.bind(this)
        this.closeConfirmationDialog = this.closeConfirmationDialog.bind(this)

        this.openRecord = this.openRecord.bind(this)
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        this.handleOpenSortPopup = this.handleOpenSortPopup.bind(this)
        this.handleCloseSortPopup = this.handleCloseSortPopup.bind(this)
        this.handleChangeSortOption = this.handleChangeSortOption.bind(this)

        //Moved here from componentDidMount
        this.props.user && initWS(this.props.user.userId)
        //TOTALS this.props.getTotals()
        //TOTALS this.getTotalsPeriodically()
        this.props.getActiveSuspendedActivities()

        //Load sorting options from Cookies before getting all visible trades which must be sorted
        let q = undefined
        q = cookies.get(`tab_f_${c.FUTURES_OPENED_POSITIONS_TAB_POS}_sort`)
        if (q) {
            // console.log(`cookies value for tab_${c.ACTIVE_TRADES_TAB_POS}_sort=`, q)
            this.state.tabsSortOptions[c.FUTURES_OPENED_POSITIONS_TAB_POS] = q
        } else {
            // console.log(`cookies value for tab_${c.ACTIVE_TRADES_TAB_POS}_sort=null`)
            this.state.tabsSortOptions[c.FUTURES_OPENED_POSITIONS_TAB_POS] = "newest"
            cookies.set(`tab_f_${c.FUTURES_OPENED_POSITIONS_TAB_POS}_sort`, "newest", { path: '/futures' })
        }

        q = cookies.get(`tab_f_${c.FUTURES_OPEN_ORDERS_TAB_POS}_sort`)
        if (q) {
            // console.log(`cookies value for tab_${c.CLOSED_TRADES_TAB_POS}_sort=`, q)
            this.state.tabsSortOptions[c.FUTURES_OPEN_ORDERS_TAB_POS] = q
        } else {
            // console.log(`cookies value for tab_${c.CLOSED_TRADES_TAB_POS}_sort=null`)
            this.state.tabsSortOptions[c.FUTURES_OPEN_ORDERS_TAB_POS] = "newest"
            cookies.set(`tab_f_${c.FUTURES_OPEN_ORDERS_TAB_POS}_sort`, "newest", { path: '/futures' })
        }


        this.props.getAllVisibleFuturesTrades(this.props.selectedFuturesTradesTab)
        setIndex(0)

        this.activateFuturesOption = this.activateFuturesOption.bind(this)
        this.showAddRemoveMarginDialog = this.showAddRemoveMarginDialog.bind(this)
        this.closeAddRemoveMarginDialog = this.closeAddRemoveMarginDialog.bind(this)
        this.addRemoveMargin = this.addRemoveMargin.bind(this)
        this.handleChangeMarginOperation = this.handleChangeMarginOperation.bind(this)
        this.handleChangeMargin = this.handleChangeMargin.bind(this)
        this.changeMarginToMax = this.changeMarginToMax.bind(this)
        this.changeMarginToMaxRemovable = this.changeMarginToMaxRemovable.bind(this)
        this.fixAfterChangeMargin = this.fixAfterChangeMargin.bind(this)
        this.debounceFixAfterChangeMarign = debounce(this.fixAfterChangeMargin, DEBOUNCE_DELAY_IN_MS)
    }

    handleChangeMarginOperation(op) {
        if (this.state.marginOperation === op)
            return

        this.setState({
            marginOperation: op,
            addRemoveMarginError: false,
            marginToBeAddedOrRemoved: 0
        })
    }

    fixAfterChangeMargin() {
        let fixedMarginNo = parseFloat(this.state.marginToBeAddedOrRemoved)

        if (this.state.marginOperation === "REMOVE" && fixedMarginNo > this.state.maxRemovable) {
            return this.setState({
                addRemoveMarginError: true
            })
        }

        // if (fixedMarginNo === 0) {
        //     return this.setState({
        //         addRemoveMarginError: true
        //     })
        // }

        let fixedMargin = brushValue(this.state.marginToBeAddedOrRemoved, this.state.fTrade.tick_size)
        let sign = 0
        if (this.state.marginOperation === "ADD") {
            sign = 1
        } else if (this.state.marginOperation === "REMOVE") {
            sign = -1
        }

        let newMargin = parseFloat(this.state.fTrade.margin) + sign * parseFloat(fixedMargin)
        this.props.computeLiquidationPrice({symbol: `${this.state.fTrade.pair}`, type: this.state.fTrade.futures_type,
            leverage: this.state.fTrade.leverage, wb: newMargin,
            entryPrice: this.state.fTrade.price,
            quantity: this.state.fTrade.orig_qty}, liquidationPrice => {
            this.setState({
                marginToBeAddedOrRemoved: fixedMargin,
                estimatedLiqPrice: liquidationPrice
            })
        })
    }

    changeMarginToMax() {
        this.setState({
            marginToBeAddedOrRemoved: this.state.maxAvailableBalance
        })
        this.debounceFixAfterChangeMarign()
    }

    changeMarginToMaxRemovable() {
        this.setState({
            marginToBeAddedOrRemoved: this.state.maxRemovable
        })
        this.debounceFixAfterChangeMarign()
    }


    handleChangeMargin(e) {
        this.setState({
            marginToBeAddedOrRemoved: e.target.value
        })
        this.debounceFixAfterChangeMarign()
    }

    showAddRemoveMarginDialog(e, fTrade) {
        e.stopPropagation()
        let maxRemovable = fTrade.additionalIsolatedMargin

        this.props.getUserBaseBalance({currency: fTrade.base}, getUserBaseBalanceResult => {
            this.setState({
                fTrade,
                isAddRemoveMarginDialogOpen: true,
                addRemoveMarginFTradeId: fTrade.order_id,
                maxAvailableBalance: getUserBaseBalanceResult.value,
                maxRemovable,
                marginForCurrentTrade: fTrade.margin,
                estimatedLiqPrice: fTrade.liquidationPrice,
                random: Math.random(),
                marginOperation: "ADD"
            })
        })
    }

    closeAddRemoveMarginDialog() {
        this.setState({
            isAddRemoveMarginDialogOpen: false,
            addRemoveMarginFTradeId: 0
        })
    }

    addRemoveMargin() {
        let margin = parseFloat(this.state.marginToBeAddedOrRemoved)
        let maxRemovable = parseFloat(this.state.maxRemovable)
        if (isNaN(margin)) {
            return this.setState({
                addRemoveMarginError: true
            })
        }

        if (this.state.marginOperation === "REMOVE" && margin > maxRemovable) {
            return this.setState({
                addRemoveMarginError: true
            })
        }

        if (margin === 0) {
            return this.setState({
                addRemoveMarginError: true
            })
        }

        this.setState({isAddRemoveMarginDialogOpen: false})
        this.props.modifyIsolatedMargin({
            futuresTradeId: this.state.fTrade.order_id,
            operation: this.state.marginOperation,
            margin,
            random: this.state.random
        }, () => {
            this.setState({addRemoveMarginFTradeId: 0})
        })
    }

    activateFuturesOption() {
        this.props.activateFuturesOption()
    }

    handleChangeSortOption(e) {
        cookies.set(`tab_f_${this.props.selectedFuturesTradesTab}_sort`, e.target.value, { path: '/futures' })
        this.state.tabsSortOptions[this.props.selectedFuturesTradesTab] = e.target.value
        this.setState({
            isSortPopupOpened: false,
        })
        this.props.reSortVisibleFuturesTrades()
    }

    handleCloseSortPopup(event) {
        this.setState({
            isSortPopupOpened: false,
        })
    }

    handleOpenSortPopup(event) {
        this.setState({
            isSortPopupOpened: true,
            sortPopOverAnchorEl: document.getElementById("anchor_for_sort_pop_over")
        })
    }
A
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    openRecord(record) {
        if (isFuturesOpenedOrder(record)) {
            this.props.history.push("/futuresorderdetails")
        } else if (isFuturesClosedOrder(record)) {
            this.props.history.push("/futuresordersummary")
        } else if (isFuturesOpenedPosition(record)) {
            this.props.history.push("/futuresposdetails")
        } else if (isFuturesClosedPosition(record)) {
            this.props.history.push("/futurespossummary")
        }
        this.props.openFuturesTrade(record)
    }

    closeConfirmationDialog() {
        this.setState({
            confirmationDialogOpen: false
        })
    }

    confirmAction(code) {
        if (code === "downloadHistory") {
            this.setState({
                confirmationDialogOpen: true,
                confirmationDialogTitle: "Download history",
                confirmationDialogMessages: ["Are your sure you want to download history?",
                    "Download a .csv file with all your trades history."],
                handleConfirmationFromDialog: this.downloadHistory
            })
        }
    }

    downloadHistory() {
        this.props.downloadHistoryForUser({}, (csvJSONEnvelope) => {
            this.setState({
                columns: csvJSONEnvelope.columns,
                datas: csvJSONEnvelope.datas,
                confirmationDialogOpen: false,
            })
            this.downloadButton.click()
        })
    }

    changeTab(event, newValue) {
        if (newValue === c.FUTURES_HISTORY_TAB_POS) {
            this.props.getHistory({}, (historyFilterSelectedMonth, historyFilterSelectedYear) => {
                this.setState({
                    historyFilterSelectedMonth,
                    historyFilterSelectedYear
                })
            })
            //Clicked on the History tab
        } else {
            this.props.getAllVisibleFuturesTrades(newValue)
        }
    }

    changeSelectedStats(newValue) {
        this.setState({
            selectedStats: newValue
        })
    }

    changeHistoryFilter(month, year) {
        if (this.state.historyFilterSelectedYear !== year || this.state.historyFilterSelectedMonth !== month) {
            this.state.historyFilterSelectedMonth = month
            this.state.historyFilterSelectedYear = year
            this.props.getHistory({month: month, year: year})
        }
    }

    getTotalsPeriodically() {
        this.getTotalsInterval = setInterval(() => {
            this.props.getTotals()
        }, 60000)
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions)
        this.props.hasFuturesActivated()
    }

    render() {

        if (this.props._hasFuturesActivated === undefined || this.props._hasFuturesActivated === null)
            return null

        if (this.props._hasFuturesActivated !== c.USER_HAS_FUTURES_ACTIVATED) {
            if (this.props._hasFuturesActivated === c.USER_HAS_FUTURES_PENDING) {
                return <div className={"AppMainContent"}>
                    <Helmet>
                        <title>{getTitle()}</title>
                    </Helmet>
                    <div>
                        <p>Thank you for your interest in <b>Venbot Futures</b>!</p>
                        <p>Your application is being reviewed and your futures account will be active in the shortest possible time.</p>
                        <p><small>The Venbot Team</small></p>
                    </div>
                </div>
            } else {
                return <div className={"AppMainContent"}>
                    <Helmet>
                        <title>{getTitle()}</title>
                    </Helmet>
                    <ActivateFuturesOption activateFutures={this.activateFuturesOption}/>
                </div>
            }
        }

        let tabs = []
        let {totalFuturesOrdersNo, totalFuturesOpenedPositionsNo, totalFuturesClosedPositionsNo} = this.props
        let noFO = totalFuturesOrdersNo ? totalFuturesOrdersNo: 0
        let noFOP = totalFuturesOpenedPositionsNo ? totalFuturesOpenedPositionsNo: 0
        let noFCP = totalFuturesClosedPositionsNo ? totalFuturesClosedPositionsNo: 0
        noFO = noFO > 0 ? noFO: ""
        noFOP = noFOP > 0 ? noFOP: ""
        noFCP = noFCP > 0 ? noFCP: ""

        let tabsTitles = {}
        if (this.props.isMobile()) {
            tabsTitles["FuturesOpenedOrders"] = <div><span style={{fontSize: "0.6rem"}}>Orders</span><br/>{noFO}</div>
            tabsTitles["FuturesOpenedPositions"] = <div><span style={{fontSize: "0.6rem"}}>Positions</span><br/>{noFOP}</div>
            tabsTitles["FuturesClosedPositions"] = <div><span style={{fontSize: "0.6rem"}}>C. Positions</span><br/>{noFCP}</div>
            tabsTitles["History"] = <span style={{fontSize: "0.6rem"}}>History</span>
        } else {
            tabsTitles["FuturesOpenedOrders"] = <div>Orders <b>{noFO}</b></div>
            tabsTitles["FuturesOpenedPositions"] = <div>Positions <b>{noFOP}</b></div>
            tabsTitles["FuturesClosedPositions"] = <div>Closed Positions <b>{noFCP}</b></div>
            tabsTitles["History"] = <div>History</div>
        }

        tabs.push({title: tabsTitles["FuturesOpenedOrders"]})
        tabs.push({title: tabsTitles["FuturesOpenedPositions"]})
        tabs.push({title: tabsTitles["FuturesClosedPositions"]})
        tabs.push({title: tabsTitles["History"]})

        switch (this.props.selectedFuturesTradesTab) {
            case 0: tabs[0].content = <VirtualScrollerContainer type="FUTURES_OPENED_ORDERS"  futuresTrades={this.props.visibleFuturesTrades} openRecord={this.openRecord} windowHeight={this.state.height}/>; break;
            case 1: tabs[1].content = <VirtualScrollerContainer type="FUTURES_OPENED_POSITIONS"  futuresTrades={this.props.visibleFuturesTrades} openRecord={this.openRecord} addRemoveMargin={this.showAddRemoveMarginDialog}  windowHeight={this.state.height}/>; break;
            case 2: tabs[2].content = <VirtualScrollerContainer type="FUTURES_CLOSED_POSITIONS"  futuresTrades={this.props.visibleFuturesTrades} openRecord={this.openRecord} windowHeight={this.state.height}/>; break;
            case 3: tabs[3].content = <FuturesTradesHistory
                stats={this.props.stats}
                selectedStats={this.state.selectedStats}
                historyData={this.props.tradesHistory}
                historyFilter={this.props.historyFilter}
                historyFilterSelectedYear={this.props.historyFilterSelectedYear}
                historyFilterSelectedMonth={this.props.historyFilterSelectedMonth}
                history={this.props.history}
                changeSelectedStats={this.changeSelectedStats}
                changeHistoryFilter={this.changeHistoryFilter}
                confirmAction={this.confirmAction}/>; break;
        }

        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>

            </Helmet>
            {(this.props.ASAS && this.props.ASAS.showWarningMessage === "true") &&
            <div className={"platformInformationContainer"}>
                <div><Info/></div>
                <div style={{marginLeft: "0.6rem"}}>
                    {this.props.ASAS.warningMessage}
                </div>
            </div>}
            {(this.props.selectedFuturesTradesTab === c.FUTURES_OPENED_POSITIONS_TAB_POS || this.props.selectedFuturesTradesTab === c.FUTURES_CLOSED_POSITIONS_TAB_POS) &&
            <div  className={"tradersSortContainer"}>
                <div id={"anchor_for_sort_pop_over"} className={"tradesSortButton"} onClick={this.handleOpenSortPopup} style={{width: "9rem"}}>
                    <Sort/>
                    {!this.props.isMobile() && <span style={{marginLeft: "0.4rem"}}>SORT BY</span>}
                </div>
            </div>}
            {(this.props.selectedFuturesTradesTab === c.FUTURES_OPEN_ORDERS_TAB_POS || this.props.selectedFuturesTradesTab === c.FUTURES_HISTORY_TAB_POS) &&
                <div  className={"tradersSortContainer"} style={{color: "gray"}}>
                <div id={"anchor_for_sort_pop_over"} className={"tradesSortButton"} style={{width: "6rem"}}>
                    <Sort/>
                    {!this.props.isMobile() && <span style={{marginLeft: "0.4rem"}}>SORT BY</span>}
                </div>
            </div>}
            <div>
            </div>
            <NavTabs tabs={tabs}
                     selectedTab={this.props.selectedFuturesTradesTab}
                     changeTab={this.changeTab}
                     tabContainerClass={"tabsContainer"} isMobile={this.props.isMobile()}
                     showFloatingButton={true}
                     type={"FUTURES"}
            />
            <AlertDialog
                open={this.state.confirmationDialogOpen}
                title={this.state.confirmationDialogTitle}
                texts={this.state.confirmationDialogMessages}
                handleOk={this.state.handleConfirmationFromDialog}
                handleCancel={this.closeConfirmationDialog}
            />
            <div style={{display: "none"}}>
                <CsvDownloader
                    filename="history.csv"
                    separator=","
                    columns={this.state.columns}
                    datas={this.state.datas ? this.state.datas : []}>
                    <button ref={(r) => this.downloadButton = r}>Download</button>
                </CsvDownloader>
            </div>
            <SimplePopOver open={this.state.isSortPopupOpened}
                           anchorEl={this.state.sortPopOverAnchorEl}
                           handleClose={this.handleCloseSortPopup}
                           content={
                               <TradesSortingRadioGroup
                                   value={this.state.tabsSortOptions[this.props.selectedFuturesTradesTab]}
                                   handleChange={this.handleChangeSortOption}/>}/>
           <AddRemoveIsolatedMarginDialog
               open={this.state.isAddRemoveMarginDialogOpen}
               handleOk={this.addRemoveMargin}
               handleCancel={this.closeAddRemoveMarginDialog}
               marginOperation={this.state.marginOperation}
               handleChangeMarginOperation={this.handleChangeMarginOperation}
               margin={this.state.marginToBeAddedOrRemoved}
               marginError={this.state.addRemoveMarginError}
               handleChangeMargin={this.handleChangeMargin}
               changeMarginToMax={this.changeMarginToMax}
               changeMarginToMaxRemovable={this.changeMarginToMaxRemovable}
               maxRemovable={this.state.maxRemovable}
               maxAvailableBalance={this.state.maxAvailableBalance}
               estimatedLiqPrice={this.state.estimatedLiqPrice}
               marginForCurrentTrade={this.state.marginForCurrentTrade}
           />
        </div>
    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,

    visibleFuturesTrades: rootState.futures.visibleFuturesTrades,

    totalFuturesOrdersNo: rootState.futures.totalFuturesOrdersNo,
    totalFuturesOpenedPositionsNo: rootState.futures.totalFuturesOpenedPositionsNo,
    totalFuturesClosedPositionsNo: rootState.futures.totalFuturesClosedPositionsNo,

    tradesHistory: rootState.futures.history,
    historyFilter: rootState.futures.historyFilter,
    historyFilterSelectedYear: rootState.futures.historyFilterSelectedYear,
    historyFilterSelectedMonth: rootState.futures.historyFilterSelectedMonth,
    stats: rootState.futures.stats,
    selectedFuturesTradesTab: rootState.futures.selectedFuturesTradesTab,
    _hasFuturesActivated: rootState.profile.hasFuturesActivated,
    marginForCurrentTrade: rootState.futures.marginForCurrentTrade,
    //totals: rootState.wallet.totals,
    ASAS: rootState.administration.asas
})

let mapDispatchToProps = dispatch => {
    return {
        openFuturesTrade: (futuresTrade) => dispatch(openFuturesTrade(futuresTrade)),
        getHistory: (props, cb) => {dispatch(getHistory(props, cb))},
        downloadHistoryForUser: (props, cb) => {dispatch(downloadHistoryForUser(props, cb))},
        getActiveSuspendedActivities: (cb) => dispatch(getActiveSuspendedActivities(cb)),
        getAllVisibleFuturesTrades: (selectedFuturesTradesTab) => dispatch(getAllVisibleFuturesTrades(selectedFuturesTradesTab)),
        reSortVisibleFuturesTrades: () => dispatch(reSortVisibleFuturesTrades()),
        hasFuturesActivated: (cb) => dispatch(hasFuturesActivated(cb)),
        activateFuturesOption: (cb) => dispatch(activateFuturesOption()),
        getUserBaseBalance: (props, cb) => dispatch(getUserBaseBalance(props, cb)),
        modifyIsolatedMargin: (props, cb) => dispatch(modifyIsolatedMargin(props, cb)),
        computeLiquidationPrice: (props, cb) => dispatch(computeLiquidationPrice(props, cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(withGetScreenExt(Futures)))
