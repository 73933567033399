import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import {connect} from "react-redux"
import KeyboardBackArrow from '@material-ui/icons/KeyboardBackspace'
import Warning from '@material-ui/icons/Warning'
import c from '../../constants'
import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import {brushValue} from "../fncomponents/UISupportFns"
import {getTitle} from "../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"
import {launchNewFutures} from "../../redux/actions/newFutures";

const useStyles = makeStyles(theme => ({
    fabLong: {
        margin: theme.spacing(1),
        background: "#3BADFF",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "black",
        '&:hover': {
            background: "#424242", color: "white"
        }
    },
    fabShort: {
        margin: theme.spacing(1),
        background: "#FE484F",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "black",
        '&:hover': {
            background: "#424242", color: "white"
        }
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

function getFixedPriceWarning(futuresType, actionTypeOption, fixedPrice, currentPrice) {
    if (actionTypeOption === c.ACTION_TYPE_OPTION_FIXED_PRICE) {
        let fixedPriceF = parseFloat(fixedPrice)
        let currentPriceF = parseFloat(currentPrice)
        let delta = (fixedPriceF - currentPriceF) / currentPriceF
        if (futuresType === c.FUTURES_TYPE_LONG)
            delta *= -1
        if (delta >= 0.1) {
            if (futuresType === c.FUTURES_TYPE_LONG) {
                return <div className={"warning"}>
                    <Warning/> buy price is current price +{(delta * 100).toFixed(2)}%
                </div>
            } else if (futuresType === c.FUTURES_TYPE_SHORT) {
                return <div className={"warning"}>
                    <Warning/> sell price is current price -{(delta * 100).toFixed(2)}%
                </div>
            }
        }
    }
}

function OpenFuturesPositionButton(props) {
    const classes = useStyles()
    const {onClick, futuresType, enabled} = props

    return (
        <div>
            <Fab variant="extended" aria-label="Delete" disabled={!enabled}
                 className={futuresType === c.FUTURES_TYPE_LONG?classes.fabLong: classes.fabShort} onClick={onClick}>
                Open Position
            </Fab>
        </div>
    )
}

const LOGGING = true

class PreviewFutures extends Component {

    constructor(props) {
        super(props)
        this.state = {
            futuresLaunched: this.props.futuresLaunched,
            openFuturesBtnEnabled: true,
        }

        this.getFirstParagraph = this.getFirstParagraph.bind(this)
        this.getSecondParagraph = this.getSecondParagraph.bind(this)
        this.getThirdParagraph = this.getThirdParagraph.bind(this)

        this.launchNewFutures = this.launchNewFutures.bind(this)
        this.didNewFuturesLaunched = this.didNewFuturesLaunched.bind(this)
    }

    getFirstParagraph() {
        let {futuresType, actionTypeOption,
            base, quote, posSize, margin, leverage, fixedPrice, currentPrice, notionalValue, liquidationPrice, additionalIsolatedMargin} = this.props
        fixedPrice = brushValue(fixedPrice)
        let firstPart = ''
        let secondPart = ''
        let thirdPart = []
        let quoteArticle = ''
        let vowelRegex = '^[aieouAIEOU].*'
        if (quote && quote.match(vowelRegex)) {
            quoteArticle = 'an'
        } else {
            quoteArticle = 'a'
        }

        if (futuresType === c.FUTURES_TYPE_LONG) {
            firstPart = <div>Open {quoteArticle} <span className={"newTPHBold"}>{leverage}X LONG {quote}/{base}</span> Futures position,</div>
            secondPart = <div>size <span className={"newTPHBold"}>{posSize} {quote}</span> <span className={"previewNewFuturesSmallExplanations"}>(notional value {notionalValue} {base})</span>, margin(cost) {margin} {base}</div>
            if (actionTypeOption === c.ACTION_TYPE_OPTION_MARKET) {
                thirdPart.push(<div key={`thirdPart_${Math.random()}`}>by <u>buying</u> at <span className={"newTPHBold"}>market price</span>.</div>)
            } else if (actionTypeOption === c.ACTION_TYPE_OPTION_FIXED_PRICE) {
                thirdPart.push(<div key={`thirdPart_${Math.random()}`}>by <u>buying</u> at a <span className={"newTPHBold"}>fixed price</span> of <span className={"newTPHBold"}>{fixedPrice}</span>.</div>)
                thirdPart.push(<div key={`thirdPart_${Math.random()}`}>getFixedPriceWarning(futuresType, actionTypeOption, fixedPrice, currentPrice)</div>)
            } else if (actionTypeOption === c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE) {
                thirdPart.push(<div key={`thirdPart_${Math.random()}`}>by <u>buying</u> <span className={"newTPHBold"}>just above the first bid ({fixedPrice})</span>.</div>)
            }
        } else if (futuresType === c.FUTURES_TYPE_SHORT) {
            firstPart = <div>Open {quoteArticle} <span className={"newTPHBold"}>{leverage}X SHORT {quote}/{base}</span> Futures position,</div>
            secondPart = <div>size <span className={"newTPHBold"}>{posSize} {quote}</span> <span className={"previewNewFuturesSmallExplanations"}>(notional value {notionalValue} {base})</span>, margin(cost) {margin} {base}</div>
            if (actionTypeOption === c.ACTION_TYPE_OPTION_MARKET) {
                thirdPart.push(<div key={`thirdPart_${Math.random()}`}>by <u>selling</u> at <span className={"newTPHBold"}>market price</span>.</div>)
            } else if (actionTypeOption === c.ACTION_TYPE_OPTION_FIXED_PRICE) {
                thirdPart.push(<div key={`thirdPart_${Math.random()}`}>by <u>selling</u> at a <span className={"newTPHBold"}>fixed price</span> of <span className={"newTPHBold"}>{fixedPrice}</span>.</div>)
                thirdPart.push(<div key={`thirdPart_${Math.random()}`}>getFixedPriceWarning(futuresType, actionTypeOption, fixedPrice, currentPrice)</div>)
            } else if (actionTypeOption === c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE) {
                thirdPart.push(<div key={`thirdPart_${Math.random()}`}>by <u>selling</u> <span className={"newTPHBold"}>below the first ask ({fixedPrice})</span>.</div>)
            }
        }

        let currentPContent = <div style={{marginTop: "0.6rem"}} className={"previewNewFuturesSmallExplanations"}>Current price {currentPrice} {base}</div>
        let isolatedMarginContent = <div><b>+</b> an additional isolated margin of {additionalIsolatedMargin} {base}</div>

        return <div>{firstPart} {secondPart} {thirdPart} {additionalIsolatedMargin > 0 && isolatedMarginContent} {currentPContent}</div>
    }

    getSecondParagraph() {
        let {takeProfitOption, takeProfitPriceOrPercent, base, futuresType} = this.props
        takeProfitPriceOrPercent = brushValue(takeProfitPriceOrPercent)
        if (takeProfitOption === c.TAKE_PROFIT_OPTION_MANUAL) {
            return <div className={"newTPHBold"}>Manual take profit</div>
        } else if (takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE) {
            return <div><span className={"newTPHBold"}>Take profit</span> at <span className={"newTPHBold"}>{takeProfitPriceOrPercent} {base}</span></div>
        } else if (takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
            return <div><span className={"newTPHBold"}>Take profit</span> at <span className={"newTPHBold"}>+{takeProfitPriceOrPercent}%</span></div>
        }
    }

    getThirdParagraph() {
        let {stopLossOption, stopLossPriceOrPercent, base, actionType, candlesType, liquidationPrice, currentPrice, leverage} = this.props
        stopLossPriceOrPercent = brushValue(stopLossPriceOrPercent)
        let lp = parseFloat(liquidationPrice)
        let cp = parseFloat(currentPrice)
        let l = parseFloat(leverage)
        let deltaLiqPrice = Math.abs((lp - cp) / cp * 100 * leverage).toFixed(2)
        let stopLossContent
        if (stopLossOption === c.STOP_LOSS_OPTION_TRAILING) {
            stopLossContent = <div><span className={"newTPHBold"}>Trailing stop loss</span> at <span className={"newTPHBold"}>-{stopLossPriceOrPercent}%</span></div>
        } else if (stopLossOption === c.STOP_LOSS_OPTION_PRICE) {
            stopLossContent = <div><span className={"newTPHBold"}>Stop loss</span> at <span className={"newTPHBold"}>{stopLossPriceOrPercent} {base}</span></div>
        } else if (stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE) {
            stopLossContent = <div><span className={"newTPHBold"}>Stop loss</span> at <span className={"newTPHBold"}>-{stopLossPriceOrPercent}%</span></div>
        } else if (stopLossOption === c.STOP_LOSS_OPTION_MANUAL) {
            stopLossContent = <div><span className={"newTPHBold"}>Stop loss</span> <u>MANUAL</u></div>
        }

        let liqPriceContent = <div style={{marginLeft: "0.8rem", fontSize: "1.2rem"}}>Liquidation price ~<b>{liquidationPrice} {base} (-{deltaLiqPrice}%)</b></div>

        return <div>
            {stopLossContent}
            {liqPriceContent}
        </div>
    }

    launchNewFutures() {
        this.setState({
            openFuturesBtnEnabled: false
        })

        this.props.launchNewFutures(() => {
            this.setState({
                openFuturesBtnEnabled: true
            })
        })
    }

    didNewFuturesLaunched() {
        return this.state.futuresLaunched !== this.props.futuresLaunched
    }

    render() {
        LOGGING && console.log("didNewFuturesLaunched %s, this.props.posSize %s", this.didNewFuturesLaunched(), this.props.posSize)
        if (this.didNewFuturesLaunched() || !this.props.posSize)
            return <Redirect to="/futures"/>

        let error = this.props.error
        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <div className={"vertSpacer1"}/>
            <div style={{display: "flex", justifyContent: "flex-start", width: "20em", cursor: "pointer"}} onClick={()=>{this.props.history.push("/newfutures")}} >
                <KeyboardBackArrow/>
                <div className={"pageTitle"} style={{marginLeft: "1rem"}}>Back</div>
            </div>
            <div className={"vertSpacer2"}/>
            <div className={"newTradePreviewHighlight"}>
                {this.getFirstParagraph()}
            </div>
            <br/>
            <div className={"newTradePreviewHighlight"}>
                {this.getSecondParagraph()}
            </div>
            <div className={"newTradePreviewHighlight"}>
                {this.getThirdParagraph()}
            </div>
            <div className={"vertSpacer2"}/>
            {error &&
            <div>
                <div >
                    <div className={"errorIntroduction"}>We apologise, but we could not start this trade for you.</div>
                    <div className={"errorContainer"}>{error}</div>
                </div>
                <div className={"vertSpacer3"}/>
            </div>}
            <div>
                <OpenFuturesPositionButton onClick={()=>{this.launchNewFutures()}} futuresType={this.props.futuresType} enabled={this.state.openFuturesBtnEnabled}/>
            </div>
            <div className={"vertSpacer1_5"}/>
        </div>
    }
}


let mapStateToProps = rootState => ({
    futuresLaunched: rootState.newFutures.newFutures.futuresLaunched,
    futuresType: rootState.newFutures.newFutures.futuresType,
    base: rootState.newFutures.newFutures.base,
    quote: rootState.newFutures.newFutures.quote,
    actionType: rootState.newFutures.newFutures.actionType,
    posSize: rootState.newFutures.newFutures.posSize,
    margin: rootState.newFutures.newFutures.margin,
    leverage: rootState.newFutures.newFutures.leverage,
    notionalValueOrig: rootState.newFutures.newFutures.notionalValueOrig,
    notionalValue: rootState.newFutures.newFutures.notionalValue,
    fixedPrice: rootState.newFutures.newFutures.fixedPrice,
    currentPrice: rootState.newFutures.newFutures.currentPrice,
    liquidationPrice: rootState.newFutures.newFutures.liquidationPrice,
    additionalIsolatedMargin: rootState.newFutures.newFutures.additionalIsolatedMargin,
    maintenanceMargin: rootState.newFutures.newFutures.maintenanceMargin,
    mMarginRate: rootState.newFutures.newFutures.mMarginRate,
    actionTypeOption: rootState.newFutures.newFutures.actionTypeOption,
    takeProfitOption: rootState.newFutures.newFutures.takeProfitOption,
    takeProfitPriceOrPercent: rootState.newFutures.newFutures.takeProfitPriceOrPercent,
    stopLossOption: rootState.newFutures.newFutures.stopLossOption,
    stopLossPriceOrPercent: rootState.newFutures.newFutures.stopLossPriceOrPercent,
    error: rootState.errors.launchNewFuturesError
})

let mapDispatchToProps = dispatch => {
    return {
        launchNewFutures: (cb) => dispatch(launchNewFutures(cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (PreviewFutures)
