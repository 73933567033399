import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import CustomSwitch from "./CustomSwitch";

const useStyles = makeStyles(theme => ({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 270,
            backgroundColor: '#101A26'
        },
        dense: {
            marginTop: theme.spacing(2),
        },
        menu: {
            width: 200,
        },
        fab: {
            // margin: theme.spacing(1),
            marginLeft: theme.spacing(0.6),
            backgroundColor: '#101A26',
            border: "1px solid #ffffff",
            color: "#ffffff"
        },
        fabSelected: {
            // margin: theme.spacing(),
            marginLeft: theme.spacing(0.6),
            backgroundColor: '#ffffff',
            border: "1px solid #000000",
            color: "#000000"
        },
        switcher: {
            checked: {
                backgroundColor: "#17BEBB"
            }
        }}
))

export default function UserNotificationsSettings(props) {
    const {onNewSignalNotif, handleChangeOnNewSignalNotif,
        onTradeClosedNotif, handleChangeOnTradeClosedNotif} = props

    const classes = useStyles()

    return (<div>
        <div style={{display: "flex", marginTop: "1.6em",
            justifyContent: "space-between", flexWrap: "wrap",
            marginLeft: "2.3rem",
            maxWidth: "18em"}}>
                <div style={{maxWidth: "10rem"}}>
                    <div>On new signal</div>
                    <div className={"smallExplanations"}>Receive a notification each time a new signal is sent to you.</div>
                </div>
            {onNewSignalNotif && <CustomSwitch
                    className={classes.switch}
                    color="primary"
                    defaultChecked
                    onChange={handleChangeOnNewSignalNotif}
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                />}
            {!onNewSignalNotif && <CustomSwitch
                className={classes.switch}
                color="primary"
                onChange={handleChangeOnNewSignalNotif}
                inputProps={{ 'aria-label': 'checkbox with default color' }}
            />}

        </div>
        <div style={{display: "flex", marginTop: "1.6em",
            justifyContent: "space-between", flexWrap: "wrap",
            marginLeft: "2.3rem",
            maxWidth: "18em"}}>
            <div style={{maxWidth: "10rem"}}>
                <div>On trade closed</div>
                <div className={"smallExplanations"}>Receive a notification each time a trade is closed by the system (either by taking profit or by stopping a loss).</div>
            </div>
            {onTradeClosedNotif && <CustomSwitch
                className={classes.switch}
                defaultChecked
                onChange={handleChangeOnTradeClosedNotif}
                color="primary"
                inputProps={{ 'aria-label': 'checkbox with default color' }}
            />}
            {!onTradeClosedNotif && <CustomSwitch
                className={classes.switch}
                onChange={handleChangeOnTradeClosedNotif}
                color="primary"
                inputProps={{ 'aria-label': 'checkbox with default color' }}
            />}
        </div>

    </div>)
}
