import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getTitle} from "../fncomponents/PageTitleUtils";
import {Helmet} from "react-helmet/es/Helmet";
import {confirmWithdrawal} from "../../redux/actions/wallet";

const qs = require("query-string")


class ConfirmWithdrawal extends Component {

    componentDidMount() {
        let token = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).wtkdjf
        this.props.confirmWithdrawal(token)
    }

    render() {
        return (
            <div className={'loginForm'} style={{marginTop: "6em", width: "20rem", textAlign: "center"}}>
                {!this.props.user && <h3>Confirm Withdrawal</h3>}
                <Helmet>
                    <title>{getTitle()}</title>
                </Helmet>
            {this.props.withdrawalConfirmSuccess && <div>
                <div>Your withdrawal has been confirmed.</div>
            </div>}
            {Object.keys(this.props.errors).length > 0 && <div>
                <div>We apologise, but token seems missing or expired...</div>
            </div>}
            </div>)
    }
}

const mapStateToProps = rootState => ({
    withdrawalConfirmSuccess: rootState.wallet.withdrawalConfirmSuccess,
    errors: rootState.errors,
    user: rootState.authentication.user,
})

const mapDispatchToProps = dispatch => {
    return {
        confirmWithdrawal: (props) => dispatch(confirmWithdrawal(props)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ConfirmWithdrawal)
