import {
    ADMINISTRATION_GET_ACTIVE_SUSPENDED_ACTIVITIES,
    ADMINISTRATION_GET_ALL_SUSPENDED_ACTIVITIES
} from "../actions/actionTypes";

export default function (state = {}, action) {
    switch (action.type) {

        case ADMINISTRATION_GET_ALL_SUSPENDED_ACTIVITIES: {
            return {
                ...state,
                ...{
                    suspendedActivities: action.payload
                }
            }
        }

        case ADMINISTRATION_GET_ACTIVE_SUSPENDED_ACTIVITIES: {
            return {
                ...state,
                ...{
                    asas: action.payload
                }
            }
        }

        default:
            return state
    }
}
