import React, {Component} from 'react'
import {connect} from "react-redux"
import {Helmet} from "react-helmet/es/Helmet"
import {getTitle} from "../../fncomponents/PageTitleUtils"
import {
    addTraderSubscription,
    getTraderByUser, removeTraderSubscription,
    saveTrader, saveTradersLogo,
    saveTraderSubscription
} from "../../../redux/actions/traders"
import Rating from "@material-ui/lab/Rating"
import EditableAboutSection from "./EditableAboutSection"
import EditableSubscriptionSection from "./EditableSubscriptionSection"
import {AddCircle} from "@material-ui/icons"
import EditableContactsSection from "./EditableContactsSection"
import EditableLogoSection from "./EditableLogoSection"
import EditableNameSection from "./EditableNameSection"
import EditableShortDescriptionSection from "./EditableShortDescriptionSection"
import Button from "@material-ui/core/Button"
import c from '../../../constants'
import AlertDialog from "../../fncomponents/AlertDialog";
import InfoDialog from "../../fncomponents/InfoDialog";

class TraderMyPage extends Component {


    constructor(props) {
        super(props)
        this.state = {
            isNameSectionEditable: false,
            isLogoSectionEditable: false,
            isShortDescriptionSectionEditable: false,
            isAboutSectionEditable: false,
            editedTraderSubscriptionId: undefined,
            editedTraderSubscriptionElement: "",
            isContactsSectionEditable: false,
            twitter:"",
            website: "",
            email: "",
            telegram: "",
            facebook: "",
            imgSrc: null,
            imgPreview: null,
            isRemoveSubAlertDialogOpen: false
        }

        this.switchOnEditableNameSection = this.switchOnEditableNameSection.bind(this)
        this.switchOffEditableNameSection = this.switchOffEditableNameSection.bind(this)
        this.handleChangeName = this.handleChangeName.bind(this)
        this.handleSaveName = this.handleSaveName.bind(this)
        this.cancelEditNameSection = this.cancelEditNameSection.bind(this)

        this.switchOnEditableLogoSection = this.switchOnEditableLogoSection.bind(this)
        this.switchOffEditableLogoSection = this.switchOffEditableLogoSection.bind(this)
        this.imgOnCrop = this.imgOnCrop.bind(this)
        this.imgOnClose = this.imgOnClose.bind(this)
        this.handleSaveLogo = this.handleSaveLogo.bind(this)
        this.cancelEditLogoSection = this.cancelEditLogoSection.bind(this)

        this.switchOnEditableShortDescriptionSection = this.switchOnEditableShortDescriptionSection.bind(this)
        this.switchOffEditableShortDescriptionSection = this.switchOffEditableShortDescriptionSection.bind(this)
        this.handleChangeShortDescription = this.handleChangeShortDescription.bind(this)
        this.handleSaveShortDescription = this.handleSaveShortDescription.bind(this)
        this.cancelEditShortDescriptionSection = this.cancelEditShortDescriptionSection.bind(this)

        this.switchOnEditableAboutSection = this.switchOnEditableAboutSection.bind(this)
        this.switchOffEditableAboutSection = this.switchOffEditableAboutSection.bind(this)
        this.handleChangeAbout = this.handleChangeAbout.bind(this)
        this.cancelEditAboutSection = this.cancelEditAboutSection.bind(this)
        this.handleSaveAbout = this.handleSaveAbout.bind(this)



        this.switchOnEditableContactsSection = this.switchOnEditableContactsSection.bind(this)
        this.switchOffEditableContactsSection = this.switchOffEditableContactsSection.bind(this)
        this.handleChangeTraderTwitter = this.handleChangeTraderTwitter.bind(this)
        this.handleChangeTraderWebsite = this.handleChangeTraderWebsite.bind(this)
        this.handleChangeTraderEmail = this.handleChangeTraderEmail.bind(this)
        this.handleChangeTraderTelegram = this.handleChangeTraderTelegram.bind(this)
        this.handleChangeTraderFacebook = this.handleChangeTraderFacebook.bind(this)
        this.cancelEditContactsSection = this.cancelEditContactsSection.bind(this)
        this.handleSaveContacts = this.handleSaveContacts.bind(this)

        this.switchOnEditableSubscriptionSection = this.switchOnEditableSubscriptionSection.bind(this)
        this.switchOffEditableSubscriptionSection = this.switchOffEditableSubscriptionSection.bind(this)
        this.cancelEditSubscriptionSection = this.cancelEditSubscriptionSection.bind(this)

        this.handleAddTraderSubscription = this.handleAddTraderSubscription.bind(this)

        this.handleOpenRemoveSubDialog = this.handleOpenRemoveSubDialog.bind(this)
        this.handleCloseRemoveSubDialog = this.handleCloseRemoveSubDialog.bind(this)
        this.handleRemoveTraderSubscription = this.handleRemoveTraderSubscription.bind(this)

        this.handleChangeSubscriptionStatus = this.handleChangeSubscriptionStatus.bind(this)
        this.handleChangeSubscriptionName = this.handleChangeSubscriptionName.bind(this)
        this.handleChangeSubscriptionPeriodicity = this.handleChangeSubscriptionPeriodicity.bind(this)
        this.handleChangeSubscriptionDescription = this.handleChangeSubscriptionDescription.bind(this)
        this.handleChangeSubscriptionDiscountedPrice = this.handleChangeSubscriptionDiscountedPrice.bind(this)
        this.handleChangeSubscriptionDiscounted = this.handleChangeSubscriptionDiscounted.bind(this)
        this.handleChangeSubscriptionListPrice = this.handleChangeSubscriptionListPrice.bind(this)
        this.handleChangeSubscriptionFree = this.handleChangeSubscriptionFree.bind(this)
        this.handleChangeSubscriptionIsMain = this.handleChangeSubscriptionIsMain.bind(this)
        this.handleChangeSubscriptionOrderInList = this.handleChangeSubscriptionOrderInList.bind(this)

        this.handleChangeSubscriptionFeature = this.handleChangeSubscriptionFeature.bind(this)
        this.handleAddSubscriptionFeature = this.handleAddSubscriptionFeature.bind(this)
        this.handleRemoveSubscriptionFeature = this.handleRemoveSubscriptionFeature.bind(this)
        this.handleMoveUpSubscriptionFeature = this.handleMoveUpSubscriptionFeature.bind(this)
        this.handleMoveDownSubscriptionFeature = this.handleMoveDownSubscriptionFeature.bind(this)

        this.handleSaveSubscription = this.handleSaveSubscription.bind(this)

        this.handleCloseInfoDialog = this.handleCloseInfoDialog.bind(this)
    }

    handleCloseInfoDialog() {
        this.setState({
            isInfoDialogOpen: false,
            infoMessages: ''
        })
    }

    handleOpenRemoveSubDialog(sub) {
        this.setState({
            subToBeRemoveId: sub._id,
            isRemoveSubAlertDialogOpen: true,
            removeSubTitle: `Remove ${sub.name}`,
            removeSubTexts: ["Are you sure you want to remove this subscription?"]
        })
    }

    handleCloseRemoveSubDialog(sub) {
        this.setState({
            subToBeRemoveId: undefined,
            isRemoveSubAlertDialogOpen: false
        })
    }

    handleRemoveTraderSubscription() {
        this.props.removeTraderSubscription({_id: this.state.subToBeRemoveId}, response => {
            if (response.error) {
                this.setState({
                    subToBeRemoveId: undefined,
                    isRemoveSubAlertDialogOpen: false,
                    isInfoDialogOpen: true,
                    infoMessages: ["Could not remove this subscription.", response.message]
                })
            } else {
                this.setState({
                    subToBeRemoveId: undefined,
                    isRemoveSubAlertDialogOpen: false
                })
            }
        })
    }

    switchOnEditableShortDescriptionSection() {
        this.setState({
            isShortDescriptionSectionEditable: true
        })
    }

    switchOffEditableShortDescriptionSection() {
        this.setState({
            isShortDescriptionSectionEditable: false
        })
    }

    handleChangeShortDescription(e) {
        if (e.target.value && e.target.value.length > 100)
            return
        this.setState({
            shortDescription: e.target.value
        })
    }

    handleSaveShortDescription() {
        this.props.saveTrader({
            shortDescription: this.state.shortDescription
        }, () => {
            this.setState({
                isShortDescriptionSectionEditable: false
            })
        })
    }

    cancelEditShortDescriptionSection() {
        this.setState({
            isShortDescriptionSectionEditable: false
        })
    }

    switchOnEditableNameSection() {
        this.setState({
            isNameSectionEditable: true
        })
    }

    switchOffEditableNameSection() {
        this.setState({
            isNameSectionEditable: false
        })
    }

    handleChangeName(e) {
        if (e.target.value && e.target.value.length > 50)
            return
        this.setState({
            name: e.target.value
        })
    }

    handleSaveName() {
        this.props.saveTrader({
            name: this.state.name
        }, () => {
            this.setState({
                isNameSectionEditable: false
            })
        })
    }

    cancelEditNameSection() {
        this.setState({
            isNameSectionEditable: false
        })
    }

    switchOnEditableLogoSection() {
        this.setState({
            isLogoSectionEditable: true
        })
    }

    switchOffEditableLogoSection() {
        this.setState({
            isLogoSectionEditable: false
        })
    }

    imgOnClose() {
        this.setState({imgPreview: null})
    }

    imgOnCrop(preview) {
        this.setState({imgPreview: preview})
    }

    handleSaveLogo() {
        this.props.saveTradersLogo({imgSrc: this.state.imgPreview}, () => {
            this.setState({
                imgPreview: null,
                isLogoSectionEditable: false
            })
        })
    }

    cancelEditLogoSection() {
        this.setState({
            imgPreview: null,
            isLogoSectionEditable: false
        })
    }

    switchOnEditableContactsSection() {
        let trader = this.props.trader.trader
        this.setState({
            twitter: trader.twitter,
            website: trader.website,
            email: trader.email,
            telegram: trader.telegram,
            facebook: trader.facebook,
            isContactsSectionEditable: true
        })
    }

    switchOffEditableContactsSection() {
        this.setState({
            isContactsSectionEditable: false
        })
    }

    handleChangeTraderTwitter(e) {
        this.setState({
            twitter: e.target.value,
            editedTraderContactsElement: "twitter"
        })
    }

    handleChangeTraderWebsite(e) {
        this.setState({
            website: e.target.value,
            editedTraderContactsElement: "website"
        })
    }

    handleChangeTraderEmail(e) {
        this.setState({
            email: e.target.value,
            editedTraderContactsElement: "email"
        })
    }

    handleChangeTraderTelegram(e) {
        this.setState({
            telegram: e.target.value,
            editedTraderContactsElement: "telegram"
        })
    }

    handleChangeTraderFacebook(e) {
        this.setState({
            facebook: e.target.value,
            editedTraderContactsElement: "facebook"
        })
    }

    cancelEditContactsSection() {
        this.setState({
            isContactsSectionEditable: false
        })
    }

    handleSaveContacts() {
        this.props.saveTrader({
            twitter: this.state.twitter,
            website: this.state.website,
            email: this.state.email,
            telegram: this.state.telegram,
            facebook: this.state.facebook
        }, () => {
            this.setState({
                isContactsSectionEditable: false
            })
        })
    }


    handleChangeSubscriptionFeature(e) {
        if (!this.state.editedSubscription.features)
            return
        let index = parseInt(e.target.name.split("_")[1])
        this.state.editedSubscription.features[index] = e.target.value
        this.setState({
            editedTraderSubscriptionElement: `feature_${index}`
        })
    }

    handleAddSubscriptionFeature(index) {
        this.state.editedSubscription.features.splice(index+1, 0, "New feature")
        this.setState({
            editedTraderSubscriptionElement: `feature_${index+1}`
        })
    }

    handleRemoveSubscriptionFeature(i) {
        this.state.editedSubscription.features.splice(i,1)
        this.setState({
            editedTraderSubscriptionElement: `feature_-1`
        })
    }

    handleMoveUpSubscriptionFeature(i) {
        let newIndex
        let features = this.state.editedSubscription.features
        if (i === 0) {
            let temp = features[0]
            features[0] = features[features.length - 1]
            features[features.length - 1] = temp
        } else {
            let temp = features[i-1]
            features[i-1] = features[i]
            features[i] = temp
        }
        this.setState({
            editedTraderSubscriptionElement: `feature_${newIndex}`
        })
    }

    handleMoveDownSubscriptionFeature(i) {
        let newIndex
        let features = this.state.editedSubscription.features
        if (i === features.length-1) {
            let temp = features[0]
            features[0] = features[features.length - 1]
            features[features.length - 1] = temp
        } else {
            let temp = features[i+1]
            features[i+1] = features[i]
            features[i] = temp
        }
        this.setState({
            editedTraderSubscriptionElement: `feature_${newIndex}`
        })
    }


    handleSaveSubscription() {
        this.props.saveTraderSubscription(this.state.editedSubscription, () => {
            this.setState({
                editedSubscriptionId: undefined,
                editedSubscription: undefined
            })
        })
    }

    handleAddTraderSubscription() {
        this.props.addTraderSubscription()
    }


    handleChangeSubscriptionStatus() {

        if (this.state.editedSubscription.status === c.TRADER_SUBSCRIPTION_ACTIVE) {
            this.state.editedSubscription.status = c.TRADER_SUBSCRIPTION_DISABLED
        } else {
            this.state.editedSubscription.status = c.TRADER_SUBSCRIPTION_ACTIVE
        }
        this.setState({
            editedTraderSubscriptionElement: "status",
        })
    }

    handleChangeSubscriptionName(e) {
        this.state.editedSubscription.name = e.target.value
        this.setState({
            editedTraderSubscriptionElement: "name",
        })
    }

    handleChangeSubscriptionPeriodicity(e) {
        this.state.editedSubscription.periodicity = e.target.value
        this.setState({
            editedTraderSubscriptionElement: "periodicity"
        })
    }

    handleChangeSubscriptionDescription(e) {
        this.state.editedSubscription.description = e.target.value
        this.setState({
            editedTraderSubscriptionElement: "description",
        })
    }

    handleChangeSubscriptionDiscountedPrice(e) {
        this.state.editedSubscription.discountedPrice = e.target.value
        this.setState({
            editedTraderSubscriptionElement: "discountedPrice",
        })
    }

    handleChangeSubscriptionDiscounted() {
        this.state.editedSubscription.discounted = !this.state.editedSubscription.discounted
        this.setState({
            editedTraderSubscriptionElement: "discounted",
        })
    }

    handleChangeSubscriptionListPrice(e) {
        this.state.editedSubscription.listPrice = e.target.value
        this.setState({
            editedTraderSubscriptionElement: "listPrice",
        })
    }

    handleChangeSubscriptionFree() {
        this.state.editedSubscription.free = !this.state.editedSubscription.free
        this.setState({
            editedTraderSubscriptionElement: "free",
        })
    }

    handleChangeSubscriptionIsMain() {
        this.state.editedSubscription.isMain = !this.state.editedSubscription.isMain
        this.setState({
            editedTraderSubscriptionElement: "isMain",
        })
    }

    handleChangeSubscriptionOrderInList(e) {
        this.state.editedSubscription.orderInList = e.target.value
        this.setState({
            editedTraderSubscriptionElement: "orderInList",
        })
    }

    switchOnEditableSubscriptionSection(subscription) {
        this.setState({
            editedSubscriptionId: subscription._id,
            editedSubscription: {...subscription, ...{}}
        })
    }

    switchOffEditableSubscriptionSection() {
        this.setState({
            editedSubscriptionId: undefined,
            editedSubscription: undefined
        })
    }

    cancelEditSubscriptionSection() {
        this.setState({
            editedSubscriptionId: undefined,
            editedSubscription: undefined
        })
    }

    switchOnEditableAboutSection() {
        this.setState({
            isAboutSectionEditable: true
        })
    }

    switchOffEditableAboutSection() {
        this.setState({
            isAboutSectionEditable: false
        })
    }

    handleChangeAbout(e) {
        this.setState({
            about: e.target.value
        })
    }
    cancelEditAboutSection() {
        this.setState({
            isAboutSectionEditable: false
        })
    }

    previewPage() {
        this.props.history.push(`/trader?tid=${this.props.trader.trader._id}&f=es3`)
    }

    previewBox() {
        this.props.history.push(`/traderbox?tid=${this.props.trader.trader._id}&f=es3`)
    }


    handleSaveAbout() {
        this.props.saveTrader({
            about: this.state.about
        }, () => {
            this.setState({
                isAboutSectionEditable: false
            })
        })
    }

    componentDidMount() {
        if (this.props.user) {
            this.props.getTraderByUser(response => {
                this.setState({
                    name: response.trader.name,
                    about: response.trader.about,
                    shortDescription: response.trader.shortDescription
                })
            })
        }
    }

    render() {
        let {trader, subscriptions} = this.props
        if (!trader) {
            return null
        }
        let _t = trader.trader

        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <div style={{display: "flex"}}>
                <div>
                    {_t && _t.ratings && <div style={{display: "flex"}}>
                        <div style={{fontSize: "1.3rem", fontWeight: "bold"}}>
                            {_t.ratings.value}
                        </div>
                        <div style={{marginLeft:"0.6rem", marginTop: "0.2rem"}}>
                            <Rating name="read-only" value={_t.ratings.value} readOnly precision={0.5} max={_t.ratings.max}/>
                        </div>
                        <div style={{fontSize: "0.8rem", marginLeft: "0.6rem", marginTop: "0.36rem"}}>{_t.ratings.votes} Ratings</div>
                    </div>}
                    {(_t && _t.followers >= 0) && <div style={{display: "flex"}}>
                        <div style={{fontSize: "1.3rem", fontWeight: "bold"}}>
                            {_t.followers}
                        </div>
                        <div style={{fontSize: "0.8rem", marginLeft: "0.6rem", marginTop: "0.36rem"}}>Followers</div>
                    </div>}
                </div>
                <div style={{marginLeft: "2rem"}}>
                    <Button onClick={()=>{this.previewPage()}} color="primary" variant="outlined">Preview</Button>
                    &nbsp;&nbsp;
                    <Button onClick={()=>{this.previewBox()}} color="primary" variant="outlined">Preview Box</Button>
                </div>
            </div>

            {_t && <div>
                <div className={"vertSpacer"}/>
                <div className={"traderSalesPageSectionTitle"}>Name</div>
                <EditableNameSection
                trader={_t}
                name={this.state.name}
                editable={this.state.isNameSectionEditable}
                switchOnEditable={this.switchOnEditableNameSection}
                switchOffEditable={this.switchOffEditableNameSection}
                handleChangeName={this.handleChangeName}
                handleSave={this.handleSaveName}
                handleCancel={this.cancelEditNameSection}
                />
            </div>}
            {_t && <div style={{marginTop: "1rem"}}>
                <div className={"traderSalesPageSectionTitle"}>Logo</div>
                <EditableLogoSection
                    trader={_t}
                    editable={this.state.isLogoSectionEditable}
                    switchOnEditable={this.switchOnEditableLogoSection}
                    switchOffEditable={this.switchOffEditableLogoSection}
                    imgSrc={this.state.imgSrc}
                    imgPreview={this.state.imgPreview}
                    imgOnCrop={this.imgOnCrop}
                    imgOnClose={this.imgOnClose}
                    handleSave={this.handleSaveLogo}
                    handleCancel={this.cancelEditLogoSection}/>
            </div>}
            {_t && <div>
                <div className={"vertSpacer"}/>
                <div className={"traderSalesPageSectionTitle"}>Short description</div>
                <EditableShortDescriptionSection
                    trader={_t}
                    shortDescription={this.state.shortDescription}
                    editable={this.state.isShortDescriptionSectionEditable}
                    switchOnEditable={this.switchOnEditableShortDescriptionSection}
                    switchOffEditable={this.switchOffEditableShortDescriptionSection}
                    handleChangeShortDescription={this.handleChangeShortDescription}
                    handleSave={this.handleSaveShortDescription}
                    handleCancel={this.cancelEditShortDescriptionSection}
                />
            </div>}
            {_t && <div style={{marginTop: "1rem"}}>
                <div className={"traderSalesPageSectionTitle"}>About</div>
                <EditableAboutSection
                    trader={_t}
                    about={this.state.about}
                    editable={this.state.isAboutSectionEditable}
                    switchOnEditable={this.switchOnEditableAboutSection}
                    switchOffEditable={this.switchOffEditableAboutSection}
                    handleChangeAbout={this.handleChangeAbout}
                    handleSave={this.handleSaveAbout}
                    handleCancel={this.cancelEditAboutSection}
                />
            </div>}
            {_t && <div style={{marginTop: "1rem"}}>
                <div className={"traderSalesPageSectionTitle"}>Contacts</div>
                <EditableContactsSection
                    trader={_t}
                    editable={this.state.isContactsSectionEditable}
                    switchOnEditable={this.switchOnEditableContactsSection}
                    switchOffEditable={this.switchOffEditableContactsSection}
                    editedTraderContactsElement={this.state.editedTraderContactsElement}
                    twitter={this.state.twitter}
                    handleChangeTwitter={this.handleChangeTraderTwitter}
                    website={this.state.website}
                    handleChangeWebsite={this.handleChangeTraderWebsite}
                    email={this.state.email}
                    handleChangeEmail={this.handleChangeTraderEmail}
                    telegram={this.state.telegram}
                    handleChangeTelegram={this.handleChangeTraderTelegram}
                    facebook={this.state.facebook}
                    handleChangeFacebook={this.handleChangeTraderFacebook}
                    handleSave={this.handleSaveContacts}
                    handleCancel={this.switchOffEditableContactsSection}
                />
            </div>}

            <div className={"traderSalesPageSectionContainer"}>
                <div className={"traderSalesPageSectionTitle"}>Subscriptions</div>
                {(subscriptions && subscriptions.length > 0) && <div style={{display: "flex"}}>
                    {subscriptions.map((elem, index) => {
                        return <EditableSubscriptionSection
                            key={Math.random()}
                            subscription={elem}
                            editedSubscriptionId={this.state.editedSubscriptionId}
                            editedSubscription={this.state.editedSubscription}
                            editedTraderSubscriptionElement={this.state.editedTraderSubscriptionElement}
                            switchOnEditable={this.switchOnEditableSubscriptionSection}
                            switchOffEditable={this.switchOffEditableSubscriptionSection}
                            handleSave={this.handleSaveSubscription}
                            handleCancel={this.cancelEditSubscriptionSection}
                            handleChangeStatus={this.handleChangeSubscriptionStatus}
                            handleChangeName={this.handleChangeSubscriptionName}
                            handleChangeDescription={this.handleChangeSubscriptionDescription}
                            handleChangePeriodicity={this.handleChangeSubscriptionPeriodicity}
                            handleChangeDiscountedPrice={this.handleChangeSubscriptionDiscountedPrice}
                            handleChangeDiscounted={this.handleChangeSubscriptionDiscounted}
                            handleChangeListPrice={this.handleChangeSubscriptionListPrice}
                            handleChangeFree={this.handleChangeSubscriptionFree}
                            handleChangeIsMain={this.handleChangeSubscriptionIsMain}
                            handleChangeOrderInList={this.handleChangeSubscriptionOrderInList}
                            handleChangeFeature={this.handleChangeSubscriptionFeature}
                            handleAddFeature={this.handleAddSubscriptionFeature}
                            handleRemoveFeature={this.handleRemoveSubscriptionFeature}
                            handleMoveUpFeature={this.handleMoveUpSubscriptionFeature}
                            handleMoveDownFeature={this.handleMoveDownSubscriptionFeature}
                            handleRemove={this.handleOpenRemoveSubDialog}
                        />
                    })}
                    <div className={"traderSalesPageEditableSectionContainer"} style={{position: "relative", border: "0px"}}>
                        <div className={"bigButtonsEditableSections"} style={{marginTop: "11rem"}} onClick={this.handleAddTraderSubscription}><AddCircle/>
                            <div style={{fontSize: "0.9rem", marginLeft: "-0.2rem"}}>Add</div>
                        </div>
                    </div>
                </div>}
                {(!subscriptions || (subscriptions && subscriptions.length === 0)) &&
                    <div className={"traderSalesPageEditableSectionContainer"} style={{position: "relative", width: "50rem", border: "0px"}}>
                    <div className={"bigButtonsEditableSections"} style={{marginTop: "11rem"}} onClick={this.handleAddTraderSubscription}><AddCircle/>
                        <div style={{fontSize: "0.9rem", marginLeft: "-0.2rem"}}>Add</div>
                    </div>
                </div>}
            </div>
            <div className={"vertSpacer2"}/>
            <AlertDialog
                open={this.state.isRemoveSubAlertDialogOpen}
                title={this.state.removeSubTitle}
                texts={this.state.removeSubTexts}
                handleOk={this.handleRemoveTraderSubscription}
                handleCancel={this.handleCloseRemoveSubDialog}
                okLabel={"OK"}
            />
            <InfoDialog
                open={this.state.isInfoDialogOpen}
                texts={this.state.infoMessages}
                handleClose={this.handleCloseInfoDialog}
            />
        </div>
    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    trader: rootState.traders.trader,
    subscriptions: rootState.traders.trader ? rootState.traders.trader.subscriptions: undefined
})

let mapDispatchToProps = dispatch => {
    return {
        getTraderByUser: (cb) => dispatch(getTraderByUser(cb)),
        saveTrader: (props, cb) => dispatch(saveTrader(props, cb)),
        saveTradersLogo: (props, cb) => dispatch(saveTradersLogo(props, cb)),
        saveTraderSubscription: (props, cb) => dispatch(saveTraderSubscription(props, cb)),
        addTraderSubscription: () => dispatch(addTraderSubscription()),
        removeTraderSubscription: (props, cb) => dispatch(removeTraderSubscription(props, cb))

    }
}

export default connect(mapStateToProps, mapDispatchToProps) (TraderMyPage)
