import React from 'react'
import SwapVert from '@material-ui/icons/SwapVert'
import c from '../../constants'
import {FormControlLabel, RadioGroup} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";

export default function SelectActionTypeRadios({actionType, actionTypeErr, handleChangeActionType, componentLabel="Trade type"}) {
    const [selectedIndex, setSelectedIndex] = React.useState(-1)

    React.useEffect(() => {

        if (actionType === c.ACTION_TYPE_BUY)
            setSelectedIndex(0)
        else if (actionType === c.ACTION_TYPE_SELL)
            setSelectedIndex(1)
        else
            setSelectedIndex(2)
    })

    return (
        <div className={"wizardSectionContainer"}>
            <div className={"wizardTitle"}>
                <span className={"wizardTitleIcon"}><SwapVert/></span> {componentLabel}
            </div>
            <div style={{marginLeft: "0.6em"}}>
                <div id="buyOrSellRadioGroup" style={{marginLeft: "0.6rem"}}>
                    <RadioGroup row>
                        <FormControlLabel value={c.ACTION_TYPE_BUY} onChange={handleChangeActionType}
                                          control={<Radio color="primary" />} label={`Start with a BUY`} checked={actionType === c.ACTION_TYPE_BUY}/>
                        <FormControlLabel value={c.ACTION_TYPE_SELL} onChange={handleChangeActionType}
                                          control={<Radio color="primary" />} label={`Start with a SELL`} checked={actionType === c.ACTION_TYPE_SELL} />
                    </RadioGroup>
                </div>
            </div>
            {selectedIndex === 0 && <div className={"smallExplanationsSelectionActionType"}>
                    <div>End with a <b>Sell</b>, ideally at a <b>higher</b> price. (~ LONG)</div>
            </div>}
            {selectedIndex === 1 && <div className={"smallExplanationsSelectionActionType"}>
                <div>End with a <b>Buy</b>, ideally at a <b>lower</b> price. (~ SHORT)</div>
            </div>}
            {actionTypeErr && <div className={"errorContainer"} style={{marginLeft: "0.6rem"}}>{actionTypeErr}</div>}
        </div>
    )
}
