import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getTitle} from "../../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"
import KeyboardBackArrow from '@material-ui/icons/KeyboardBackspace'
import FormButtons from "../../fncomponents/FormButtons"
import {addTraderFollower, getTraderFollower, getTradersSubscriptions} from "../../../redux/actions/traders"
import SearchUserByEmail from "../../fncomponents/SearchUserByEmail"
import {getUserByEmail} from "../../../redux/actions/users"
import {debounce} from "../../hoc/myUtils"
import SelectedFollower from "../../fncomponents/SelectedFollower"
import SelectFollowerSubscription from "../../fncomponents/SelectFollowerSubscription";
import InfoBox from "../../fncomponents/InfoBox";

const qs = require("query-string")

const DEBOUNCE_DELAY_IN_MS = 500

class AddTraderFollower extends Component {

    constructor(props) {
        super(props)
        this.state = {
            followerId: '',
            selectedUser: undefined,
            selectedSubscriptionId: undefined,
        }
        this.handleChangeEmail = this.handleChangeEmail.bind(this)
        this.searchUserByEmail = this.searchUserByEmail.bind(this)
        this.debounceSearchUserByEmail = debounce(this.searchUserByEmail, DEBOUNCE_DELAY_IN_MS)
        this.handleSelectUser = this.handleSelectUser.bind(this)
        this.handleSelectSubscription = this.handleSelectSubscription.bind(this)
        this.handleAddFollower = this.handleAddFollower.bind(this)
    }

    handleAddFollower() {
        if (!this.state.selectedUser) {
            return this.setState({
                error: "Please select a user"
            })
        }

        if (!this.state.selectedSubscriptionId) {
            return this.setState({
                error: "Please select a subscription"
            })
        }

        this.props.addTraderFollower({
            userId: this.state.selectedUser._id,
            subscriptionId: this.state.selectedSubscriptionId
        }, (response) => {
            if (response.status === "OK") {
                this.setState({
                    success: "User has just been added as your follower.",
                    email: '',
                    error: undefined
                })
            } else if (response.status === "ERROR") {
                this.setState({
                    success: undefined,
                    error: response.message,
                    email: '',
                })
            }

        })
    }

    handleSelectSubscription(e) {
        this.setState({
            selectedSubscriptionId: e.target.value
        })
    }

    handleSelectUser() {
        this.setState({
            selectedUser: this.props.searchedUser,
        })
    }

    handleChangeEmail(e) {
        this.setState({
            email: e.target.value
        })
        this.debounceSearchUserByEmail()
    }

    searchUserByEmail() {
        this.props.getUserByEmail({email: this.state.email})
    }

    componentDidMount() {

        this.state.followerId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).followerId
        if (this.state.followerId) {
            this.props.getTraderFollower({_id: this.state.followerId})
        }

        if (!this.props.subscriptions) {
            this.props.getTradersSubscriptions()
        }

    }

    render() {

        let {user} = this.props
        let {selectedUser, error, success} = this.state

        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <div style={{display: "flex", justifyContent: "flex-start", width: "20em", cursor: "pointer"}}
                 onClick={()=>{this.props.history.push("/trader/followers")}} >
                <KeyboardBackArrow/>
                <div className={"pageTitle"} style={{marginLeft: "1rem"}}>Back</div>
            </div>
            <div key={"theForm"}>
                <div className={"vertSpacer2"}/>
                <InfoBox message="Please note that this form is for the special case when the user already paid for your subscription outside our platform. As such the first payment of the selected subscription will have to be handled manually."
                    width={"20rem"}/>
                <SearchUserByEmail
                    email={this.state.email}
                    handleChangeEmail={this.handleChangeEmail}
                    result={this.props.searchedUser}
                    handleSelect={this.handleSelectUser}
                />
                <div className={"vertSpacer1_5"}/>
                <div className={"horizSeparator"}/>
                <div className={"vertSpacer1"}/>
                {selectedUser && <SelectedFollower selectedUser={selectedUser}/>}
                <div className={"vertSpacer2"}/>
                <SelectFollowerSubscription
                    selectedSubscriptionId={this.state.selectedSubscriptionId}
                    allSubscriptions={this.props.subscriptions}
                    handleSelect={this.handleSelectSubscription}
                />
                {error &&
                <div>
                    <div className={"vertSpacer1"}/>
                    <div style={{marginLeft: "1rem"}}>
                        <div className={"errorContainer"}>{error}</div>
                    </div>
                </div>}
                {success &&
                <div>
                    <div className={"vertSpacer1"}/>
                    <div style={{marginLeft: "1rem"}}>
                        <div className={"successContainer"}>{success}</div>
                    </div>
                </div>}
                <FormButtons
                    handleCancel={()=>{this.props.history.push("/trader/followers")}}
                    handleSave={this.handleAddFollower}
                    labelSave={"Add"}
                />
            </div>
        </div>
    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    searchedUser: rootState.users.searchedUser,
    openedTraderFollower: rootState.traders.openedTraderFollower,
    subscriptions: rootState.traders.subscriptions
})

let mapDispatchToProps = dispatch => {
    return {
        getTraderFollower: (props) => dispatch(getTraderFollower(props)),
        getUserByEmail: (props) => dispatch(getUserByEmail(props)),
        getTradersSubscriptions: () =>  dispatch(getTradersSubscriptions()),
        addTraderFollower: (props, cb) => dispatch(addTraderFollower(props, cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (AddTraderFollower)
