import React, {Component} from 'react'
import {connect} from "react-redux"
import {debounce} from '../../hoc/myUtils'
import {getTitle} from "../../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"
import TraderPaymentsTable from "./TraderPaymentsTable"
import {
    getTraderSubscriptionPaymentsByPage,
    getTraderSubscriptionPaymentsInitial
} from "../../../redux/actions/traders"
import {ChevronRight} from "@material-ui/icons";
import Button from "@material-ui/core/Button";

class TraderPayments extends Component {

    constructor(props) {
        super(props)
        this.state = {
            rows: [],
            page: 0,
            rowsPerPage: 10,
            filtersMap: {},
            sort: {}
        }

        this.setRowsPerPage = this.setRowsPerPage.bind(this)
        this.setPage = this.setPage.bind(this)
        this.onFilterChange = this.onFilterChange.bind(this)
        this.onFilter = this.onFilter.bind(this)
        this.debouncedFilter = debounce(this.onFilter, 500)
    }

    setRowsPerPage (value) {
        this.state.rowsPerPage = value
        this.state.page = 0
        this.props.getTraderSubscriptionPaymentsInitial({filters: this.state.filtersMap,
            sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
    }

    setPage (value) {
        let page = value
        if (!this.props.pagesDownloaded[page+1]) {
            this.state.page = page
            this.props.getTraderSubscriptionPaymentsByPage({filters: this.state.filtersMap,
                sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
        } else {
            this.setState({
                page: page
            })
        }
    }

    onFilterChange(e) {
        let filterEntry = {[e.target.name]: e.target.value}
        this.setState({
            filtersMap: {...this.state.filtersMap, ...filterEntry}
        })
        this.debouncedFilter()
    }

    onFilter() {
        this.props.getTraderSubscriptionPaymentsInitial({filters: this.state.filtersMap,
            sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
    }

    componentDidMount() {
        this.props.getTraderSubscriptionPaymentsInitial({filters: this.state.filtersMap,
            sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
    }

    render() {
        let {subscriptionPayments, totalDocs, user} = this.props

        return (
            <div className={"AppMainContent"}>
                <div className={"vertSpacer2"}/>
                <Helmet>
                    <title>{getTitle()}</title>
                </Helmet>
                {user && user.asTraderReceivesCommissions &&
                    <Button onClick={()=>{this.props.history.push("/trader/commissions")}} color="primary" variant="outlined">Commissions <ChevronRight/></Button>
                }
                <TraderPaymentsTable
                    rows={subscriptionPayments}
                    rowsPerPage={this.state.rowsPerPage}
                    setRowsPerPage={this.setRowsPerPage}
                    page={this.state.page}
                    setPage={this.setPage}
                    totalDocs={totalDocs ? totalDocs : 0}
                    filtersMap={this.state.filtersMap}
                    onFilterChange={this.onFilterChange}
                />
            </div>
        )
    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    subscriptionPayments: rootState.traders.subscriptionPayments,
    totalDocs: rootState.traders.totalDocs,
    totalPages: rootState.traders.totalPages,
    pagesDownloaded: rootState.traders.pagesDownloaded,
})

let mapDispatchToProps = dispatch => {
    return {
        getTraderSubscriptionPaymentsInitial: (props) => dispatch(getTraderSubscriptionPaymentsInitial(props)),
        getTraderSubscriptionPaymentsByPage: (props) => dispatch(getTraderSubscriptionPaymentsByPage(props)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (TraderPayments)
