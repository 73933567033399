import React, {Component} from 'react'
import BotsEnhancedTable from "../../fncomponents/BotsEnhancedTable";

class ClosedSignals extends Component {

    constructor(props) {
        super(props)
    }

    render() {

        const columns = [
            { id: 'bot', numeric: false, disablePadding: false, label: 'Bot' },
            { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' }
        ]
        return (
            <div>
                <h2>Stopped Bots</h2>
                <BotsEnhancedTable
                    rows={this.props.stoppedBots ? this.props.stoppedBots : []}
                    columns={columns}
                    rowsPerPage={this.props.rowsPerPage}
                    setRowsPerPage={this.props.setRowsPerPage}
                    page={this.props.page}
                    setPage={this.props.setPage}
                    totalDocs={this.props.totalDocs ? this.props.totalDocs : 0}
                    filtersMap={this.props.filtersMap}
                    onFilterChange={this.props.onFilterChange}
                />
            </div>
        )
    }
}

export default ClosedSignals
