import React from "react"
import {TextField, Tooltip} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import InputAdornment from "@material-ui/core/InputAdornment"
import {TrendingUp, TrendingDown, Timeline} from "@material-ui/icons"
import Fab from '@material-ui/core/Fab'
import c from '../../constants'
import CustomSwitch from "./CustomSwitch";
import s from "../../settings";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 180,
        backgroundColor: '#101A26'
    },
    textField2: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 100,
        backgroundColor: '#101A26'
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    fab: {
        // margin: theme.spacing(1),
        marginLeft: theme.spacing(0.6),
        backgroundColor: '#101A26',
        border: "1px solid #ffffff",
        color: "#ffffff"
    },
    fabSelected: {
        // margin: theme.spacing(),
        marginLeft: theme.spacing(0.6),
        backgroundColor: '#ffffff',
        border: "1px solid #000000",
        color: "#000000"
    },
    switcher: {
        checked: {
            backgroundColor: "#17BEBB"
        }
    }}
))

export default function FuturesTradeConditionsFromSUC(props) {
    const {
        futuresType,
        takeProfitOption, takeProfitTargets,
        stopLossOption, stopLossPriceOrPercent,
        switchWithOrWithoutStopLossTarget,
        handleChangeTakeProfitOption, handleChangeTakeProfitTarget,
        handleChangeStopLossOption, handleChangeStopLossPriceOrPercent,
        take_profit_target_1_percentage_execute,
        take_profit_target_2_percentage_execute,
        handleChangeTakeProfitTargetPercentageExecute,
        base,
        leverage,
        currentPrice,
        liquidationPrice,
        additionalMargin
    } = props

    const classes = useStyles()
    const [withStopLossTarget, setWithStopLossTarget] = React.useState(true)

    React.useEffect(() => {
        setWithStopLossTarget(stopLossOption !== c.STOP_LOSS_OPTION_MANUAL)
    })

    function getTakeProfitInputAdorment() {
        switch (takeProfitOption) {
            case c.TAKE_PROFIT_OPTION_PERCENTAGE: return "%";
            case c.TAKE_PROFIT_OPTION_PRICE: return base;
        }
    }

    function getTakeProfitLabel() {
        switch (takeProfitOption) {
            case c.TAKE_PROFIT_OPTION_PERCENTAGE: return "Take profit at percentage";
            case c.TAKE_PROFIT_OPTION_PRICE: return "Take profit at price";
            default: return "_________________________";

        }
    }

    function getStopLossInputAdorment() {
        switch (stopLossOption) {
            case c.STOP_LOSS_OPTION_PERCENTAGE: return "%";
            case c.STOP_LOSS_OPTION_PRICE: return base;

        }
    }

    function getStopLossLabel() {
        switch (stopLossOption) {
            case c.STOP_LOSS_OPTION_PERCENTAGE: return "Stop loss at percent";
            case c.STOP_LOSS_OPTION_PRICE: return "Stop loss at price";
            default: return "____________________";
        }
    }

    function getDeltaPercentLiqPrice() {
        if (liquidationPrice > 0) {
            let delta = Math.abs((currentPrice - liquidationPrice) / currentPrice * 100 * leverage).toFixed(2)
            return `(-${delta}%)`
        }
    }

    return (<div className={"wizardSectionContainer"}>
        <div id={"takeProfit"} style={{display: "flex", marginTop: "1em",
            justifyContent: "space-between", flexWrap: "wrap", maxWidth: "26em"}}>
            <div>
                <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8", display: "flex", justifyContent: "space-between"}}>
                    <div>{getTakeProfitLabel()}</div>
                    <div>Percentage to execute</div>
                </div>
                {takeProfitTargets.map((elem, index) => {
                    let inputs = []
                    inputs.push(<TextField
                        className={classes.textField}
                        variant={"outlined"}
                        margin={"dense"}
                        id="input-with-icon-textfield_trade_cond_from_suc"
                        value={elem}
                        name={index}
                        onChange={handleChangeTakeProfitTarget}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <TrendingUp/>
                                    {(takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE && futuresType === c.FUTURES_TYPE_LONG) && <span className={"signIndent"}>+</span>}
                                    {(takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE && futuresType === c.FUTURES_TYPE_SHORT) && <span className={"signIndent"}>-</span>}
                                </InputAdornment>
                            ),
                            endAdornment: <InputAdornment position="end">{getTakeProfitInputAdorment()}</InputAdornment>
                        }}
                        inputProps={{ maxLength: 16 }}/>)

                    let inputValue = undefined
                    if (index === 0)
                        inputValue = take_profit_target_1_percentage_execute
                    else if (index === 1)
                        inputValue = take_profit_target_2_percentage_execute
                    let disabled = takeProfitTargets.length === 1
                    inputs.push(<TextField
                        className={classes.textField2}
                        variant={"outlined"}
                        margin={"dense"}
                        id="input-with-icon-textfield_trade_cond_from_suc_2"
                        value={inputValue}
                        name={index + 1}
                        disabled={disabled}
                        onChange={handleChangeTakeProfitTargetPercentageExecute}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>
                        }}
                        inputProps={{ maxLength: 16 }}/>)

                    return <div style={{display: "flex", justifyContent: "space-between"}}>{inputs}</div>
                })}
            </div>
            <div style={{marginLeft:"20px", fontSize:"0.8rem", display: "flex", justifyContent: "flex-start"}}>
                <div style={{marginTop: "1.05rem", width: "8remrem", display: "flex", justifyContent: "space-between"}}>
                    <Fab color={takeProfitOption == c.TAKE_PROFIT_OPTION_PERCENTAGE? "primary": "default"} aria-label="Add"
                         className={takeProfitOption == c.TAKE_PROFIT_OPTION_PERCENTAGE ? classes.fabSelected : classes.fab} variant={"extended"}
                         onClick={() => {handleChangeTakeProfitOption(c.TAKE_PROFIT_OPTION_PERCENTAGE)}}>
                        %
                    </Fab>
                    <Fab color={takeProfitOption == c.TAKE_PROFIT_OPTION_PRICE? "primary": "default"} aria-label="Add"
                         className={takeProfitOption == c.TAKE_PROFIT_OPTION_PRICE ? classes.fabSelected : classes.fab} variant={"extended"}
                         onClick={() => {handleChangeTakeProfitOption(c.TAKE_PROFIT_OPTION_PRICE)}}>
                        #
                    </Fab>
                </div>
            </div>
        </div>
        <div style={{marginLeft: "0em", marginTop: "1.6em"}}>
            With stop loss
            <CustomSwitch checked={withStopLossTarget} color="primary"
                          inputProps={{ 'aria-label': 'checkbox with default color' }}
                          onChange={switchWithOrWithoutStopLossTarget} classes={{checked: classes.switcher.checked}}
            />
        </div>
        <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8"}}>
            <Tooltip title={<span className={"toolTip"}>{`Your trade will be stopped at the liquidation price. In order to increase the interval till the liquidation price is reached, more margin is added automatically.`}</span>} placement="right" enterTouchDelay={s.enterTouchDelay}>
                <span className={"newFTradeFieldsWithExplanations"}>Liquidation price</span>
            </Tooltip>
            <span style={{fontSize: "1rem", fontWeight: "bold", color: "#3BADFF"}}>{liquidationPrice} {base} {getDeltaPercentLiqPrice()}</span>
        </div>
        <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8"}}>
            <Tooltip title={<span className={"toolTip"}>{`Additional isolated margin, added automatically in order to support the liquidation price.`}</span>} placement="right" enterTouchDelay={s.enterTouchDelay}>
                <span className={"newFTradeFieldsWithExplanations"}>Additional margin</span>
            </Tooltip>
            <span style={{fontSize: "1rem", fontWeight: "bold"}}>{additionalMargin} {base}</span>
        </div>
        {withStopLossTarget && <div id={"stopLoss"} style={{display: "flex", marginTop: "1.6em",
            justifyContent: "space-between", flexWrap: "wrap", maxWidth: "25em"}}>
            <div>
                <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8"}}>{getStopLossLabel()}</div>
                <TextField
                    className={classes.textField}
                    variant={"outlined"}
                    margin={"dense"}
                    id="input-with-icon-textfield_trade_cond_from_suc_3"
                    value={stopLossPriceOrPercent}
                    onChange={handleChangeStopLossPriceOrPercent}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <TrendingDown/>
                                {(stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE ||
                                    stopLossOption === c.STOP_LOSS_OPTION_TRAILING) && <span className={"signIndent"}>-</span>}
                            </InputAdornment>
                        ),
                        endAdornment: <InputAdornment position="end">{getStopLossInputAdorment()}</InputAdornment>
                    }}
                    inputProps={{ maxLength: 16 }}
                />
            </div>
            <div style={{marginTop: "1.05rem", width: "7rem"}}>
                <Fab color={ (stopLossOption == c.STOP_LOSS_OPTION_PERCENTAGE || stopLossOption == c.STOP_LOSS_OPTION_TRAILING)
                    ? "primary": "default"} aria-label="Add"
                     className={(stopLossOption == c.STOP_LOSS_OPTION_PERCENTAGE || stopLossOption == c.STOP_LOSS_OPTION_TRAILING) ?
                     classes.fabSelected: classes.fab} variant={"extended"}
                     onClick={() => {handleChangeStopLossOption(c.STOP_LOSS_OPTION_PERCENTAGE)}}>
                    %
                </Fab>
                <Fab color={stopLossOption == c.STOP_LOSS_OPTION_PRICE ? "primary": "default"}
                     aria-label="Add" className={stopLossOption == c.STOP_LOSS_OPTION_PRICE ? classes.fabSelected: classes.fab}
                     variant={"extended"}
                     onClick={() => {handleChangeStopLossOption(c.STOP_LOSS_OPTION_PRICE)}}>
                    #
                </Fab>
            </div>
        </div>}
    </div>)
}
