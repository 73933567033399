import {
    SIGNALS_CLEAR_CURRENT_SIGNAL,
    SIGNALS_CLEAR_ERRORS,
    SIGNALS_CLEAR_SEND_RESULT,
    SIGNALS_DISCARD_NOTIFICATION,
    SIGNALS_ERRORS,
    SIGNALS_GET_ALL_SIGNALS_NOTIFICATIONS,
    SIGNALS_GET_CLOSED_ALL, SIGNALS_GET_CLOSED_ALL_V1,
    SIGNALS_GET_CLOSED_INITIAL, SIGNALS_GET_CLOSED_INITIAL_V1,
    SIGNALS_GET_COUNT_UNREAD_SIGNALS_NOTIFICATIONS,
    SIGNALS_GET_RUNNING_ALL, SIGNALS_GET_RUNNING_ALL_V1,
    SIGNALS_GET_RUNNING_INITIAL, SIGNALS_GET_RUNNING_INITIAL_V1,
    SIGNALS_GET_SIGNALS_NOTIFICATIONS_INITIAL,
    SIGNALS_GET_WAITING_ALL, SIGNALS_GET_WAITING_ALL_V1,
    SIGNALS_GET_WAITING_INITIAL, SIGNALS_GET_WAITING_INITIAL_V1,
    SIGNALS_PREVIEW,
    SIGNALS_READ_UNREAD_NOTIFICATION,
    SIGNALS_SELECT_TAB,
    SIGNALS_SEND,
    SIGNALS_UPDATE_NOTIFICATION,
    SIGNALS_GET_UPDATE,
    SIGNALS_SET_CURRENT_PAGE,
    SIGNALS_SET_ROWS_PER_PAGE
} from "../actions/actionTypes";



let applyingCurrentMaxMinPriceOnCurrentDisplayedSignals = (state, payload) => {
    let records = undefined
    switch (state.selectedTab) {
        case 0: records = state.wsv1_docs; break;
        case 1: records = state.rsv1_docs; break;
        case 2: records = state.csv1_docs; break;
    }
    if (!records)
        return

    let startIndex = state.currentPage * state.rowsPerPage
    let endIndex = (state.currentPage + 1) * state.rowsPerPage
    endIndex = endIndex < records.length ? endIndex: records.length

    if (!records || records && records.length === 0)
        return

    for(let i = startIndex; i < endIndex; i++) {
            if (records[i].id === payload.signal_id) {
                records[i].currentPrice = payload.current_price
                records[i].currentPriceDeltaPercent = ((payload.current_price - records[i].enterTradePriceTo) / records[i].enterTradePriceTo * 100).toFixed(2)
                records[i].currentPriceClass = "currentPriceInline"
                if (records[i].currentPriceDeltaPercent > 0) {
                    records[i].currentPriceClass = "currentPriceUp"
                } else {
                    records[i].currentPriceClass = "currentPriceDown"
                }
                records[i].maxPrice = payload.max_price_during_trade
                records[i].maxDelta = ((payload.max_price_during_trade - records[i].enterTradePriceTo) / records[i].enterTradePriceTo * 100).toFixed(2)
                records[i].minPrice = payload.min_price_during_trade
                records[i].minDelta = ((payload.min_price_during_trade - records[i].enterTradePriceTo) / records[i].enterTradePriceTo * 100).toFixed(2)
            }
    }
    return [...records]
}

export default function (state = {selectedTab: 1}, action) {
    switch (action.type) {

        case SIGNALS_PREVIEW: {
            return {
                ...state,
            ...{
                    signal: action.payload.signal,
                    errors: action.payload.errors,
                    warnings: action.payload.warnings,
                }
            }
        }

        case SIGNALS_SEND: {
            return {
                ...state,
                ...{
                    signalSentResult: action.payload.result,
                    signal: action.payload.signal,
                    errors: action.payload.errors
                }
            }
        }

        case SIGNALS_ERRORS: {
            return {
                ...state,
                ...{
                    errors: action.payload
                }
            }
        }

        case SIGNALS_CLEAR_ERRORS: {
            return {
                ...state,
                ...{
                    errors: undefined
                }
            }
        }

        case SIGNALS_CLEAR_SEND_RESULT: {
            return {
                ...state,
                ...{
                    signalSentResult: undefined
                }
            }
        }

        case SIGNALS_GET_WAITING_ALL: {
            let newState =  {
                ...state,
                ws_docs: [...state.ws_docs, ...action.payload.docs],
                ws_totalDocs: action.payload.totalDocs,
                ws_totalPages: action.payload.totalPages,
                ws_pagesDownloaded: {...state.ws_pagesDownloaded, ...{[action.payload.page]: true}}
            }
            return newState
        }

        case SIGNALS_GET_WAITING_ALL_V1: {
            let newState =  {
                ...state,
                wsv1_docs: [...state.wsv1_docs, ...action.payload.docs],
                wsv1_totalDocs: action.payload.totalDocs,
                wsv1_totalPages: action.payload.totalPages,
                wsv1_pagesDownloaded: {...state.wsv1_pagesDownloaded, ...{[action.payload.page]: true}}
            }
            return newState
        }

        case SIGNALS_GET_WAITING_INITIAL: {
            let newState =  {
                ...state,
                ws_docs: action.payload.docs,
                ws_totalDocs: action.payload.totalDocs,
                ws_totalPages: action.payload.totalPages,
                ws_pagesDownloaded: {[action.payload.page]: true}
            }
            return newState
        }

        case SIGNALS_GET_WAITING_INITIAL_V1: {
            let newState =  {
                ...state,
                wsv1_docs: action.payload.docs,
                wsv1_totalDocs: action.payload.totalDocs,
                wsv1_totalPages: action.payload.totalPages,
                wsv1_pagesDownloaded: {[action.payload.page]: true}
            }
            return newState
        }

        case SIGNALS_GET_RUNNING_ALL: {
            let newState =  {
                ...state,
                rs_docs: [...state.rs_docs, ...action.payload.docs],
                rs_totalDocs: action.payload.totalDocs,
                rs_totalPages: action.payload.totalPages,
                rs_pagesDownloaded: {...state.rs_pagesDownloaded, ...{[action.payload.page]: true}}
            }
            return newState
        }

        case SIGNALS_GET_RUNNING_ALL_V1: {
            let newState =  {
                ...state,
                rsv1_docs: [...state.rsv1_docs, ...action.payload.docs],
                rsv1_totalDocs: action.payload.totalDocs,
                rsv1_totalPages: action.payload.totalPages,
                rsv1_pagesDownloaded: {...state.rsv1_pagesDownloaded, ...{[action.payload.page]: true}}
            }
            return newState
        }

        case SIGNALS_GET_RUNNING_INITIAL: {
            let newState =  {
                ...state,
                rs_docs: action.payload.docs,
                rs_totalDocs: action.payload.totalDocs,
                rs_totalPages: action.payload.totalPages,
                rs_pagesDownloaded: {[action.payload.page]: true}
            }
            return newState
        }

        case SIGNALS_GET_RUNNING_INITIAL_V1: {
            let newState =  {
                ...state,
                rsv1_docs: action.payload.docs,
                rsv1_totalDocs: action.payload.totalDocs,
                rsv1_totalPages: action.payload.totalPages,
                rsv1_pagesDownloaded: {[action.payload.page]: true}
            }
            return newState
        }

        case SIGNALS_GET_CLOSED_INITIAL: {
            let newState =  {
                ...state,
                cs_docs: action.payload.docs,
                cs_totalDocs: action.payload.totalDocs,
                cs_totalPages: action.payload.totalPages,
                cs_pagesDownloaded: {[action.payload.page]: true}
            }
            return newState
        }

        case SIGNALS_GET_CLOSED_INITIAL_V1: {
            let newState =  {
                ...state,
                csv1_docs: action.payload.docs,
                csv1_totalDocs: action.payload.totalDocs,
                csv1_totalPages: action.payload.totalPages,
                csv1_pagesDownloaded: {[action.payload.page]: true}
            }
            return newState
        }

        case SIGNALS_GET_CLOSED_ALL: {
            let newState =  {
                ...state,
                cs_docs: [...state.cs_docs, ...action.payload.docs],
                cs_totalDocs: action.payload.totalDocs,
                cs_totalPages: action.payload.totalPages,
                cs_pagesDownloaded: {...state.cs_pagesDownloaded, ...{[action.payload.page]: true}}
            }
            return newState
        }

        case SIGNALS_GET_CLOSED_ALL_V1: {
            let newState =  {
                ...state,
                csv1_docs: [...state.csv1_docs, ...action.payload.docs],
                csv1_totalDocs: action.payload.totalDocs,
                csv1_totalPages: action.payload.totalPages,
                csv1_pagesDownloaded: {...state.csv1_pagesDownloaded, ...{[action.payload.page]: true}}
            }
            return newState
        }

        case SIGNALS_SELECT_TAB: {
            let newState =  {
                ...state,
                selectedTab: action.payload
            }
            return newState
        }

        case SIGNALS_CLEAR_CURRENT_SIGNAL: {
            let newState =  {
                ...state,
                ...{signal: undefined}
            }
            return newState
        }

        case SIGNALS_GET_COUNT_UNREAD_SIGNALS_NOTIFICATIONS: {
            return  {
                ...state,
                ...{unreadNotifications: action.payload.count}
            }
        }

        case SIGNALS_GET_SIGNALS_NOTIFICATIONS_INITIAL: {
            let newState =  {
                ...state,
                docs: action.payload.docs,
                totalDocs: action.payload.totalDocs,
                totalPages: action.payload.totalPages,
                pagesDownloaded: {[action.payload.page]: true}
            }
            return newState
        }

        case SIGNALS_GET_ALL_SIGNALS_NOTIFICATIONS: {
            let newState =  {
                ...state,
                docs: [...state.docs, ...action.payload.docs],
                totalDocs: action.payload.totalDocs,
                totalPages: action.payload.totalPages,
                pagesDownloaded: {...state.pagesDownloaded, ...{[action.payload.page]: true}}
            }
            return newState
        }

        case SIGNALS_READ_UNREAD_NOTIFICATION: {
            return  {
                ...state,
                ...{docs: state.docs.map((elem, idx) => {
                    if (elem._id === action.payload._id)
                        return {...elem, ...{status: action.payload.status}}
                    else return elem
                    })
                }
            }
        }

        case SIGNALS_UPDATE_NOTIFICATION: {
            return  {
                ...state,
                ...{docs: state.docs.map((elem, idx) => {
                        if (elem._id === action.payload._id)
                            return {...elem, ...{actionResult: action.payload.actionResult, status: action.payload.status}}
                        else return elem
                    })
                }
            }
        }

        case SIGNALS_DISCARD_NOTIFICATION: {
            let newState = {...state, ...{}}
            delete newState.notifications
            newState.notifications = []
            for(let i = 0; i < state.notifications.length; i++) {
                if (state.notifications[i]._id !== action.payload._id)
                    newState.notifications.push(state.notifications[i])
            }
            return newState
        }

        case SIGNALS_GET_UPDATE: {
            let signalsWCpMaxPAndMinP = applyingCurrentMaxMinPriceOnCurrentDisplayedSignals(state, action.payload)
            switch (state.selectedTab) {
                case 0: return {
                    ...state,
                    ...{wsv1_docs: signalsWCpMaxPAndMinP}
                }
                case 1: return {
                    ...state,
                    ...{rsv1_docs: signalsWCpMaxPAndMinP}
                }
                default: return state;
            }
        }

        case SIGNALS_SET_CURRENT_PAGE: {
            return {...state, ...{currentPage: action.payload}}
        }

        case SIGNALS_SET_ROWS_PER_PAGE: {
            return {...state, ...{rowsPerPage: action.payload}}
        }

        default:
            return state
    }
}
