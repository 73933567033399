import {isAuthorizationHeaderPresent} from "./authentication";
import axios from "./axiosFabric";
import {handleError} from "./errorHandler";
import {
    CHANGE_SIGNAL_STATE_AFTER_BUYING_SUBSCRIPTION,
    TRADERS_ADD_SUBSCRIPTION,
    TRADERS_GET_ALL,
    TRADERS_GET_COMMISSIONS_GET_ALL,
    TRADERS_GET_COMMISSIONS_GET_INITIAL,
    TRADERS_GET_FOLLOWER,
    TRADERS_GET_FOLLOWERS_GET_ALL,
    TRADERS_GET_FOLLOWERS_GET_INITIAL,
    TRADERS_GET_INITIAL,
    TRADERS_GET_SUBSCRIPTION_PAYMENTS_GET_ALL,
    TRADERS_GET_SUBSCRIPTION_PAYMENTS_GET_INITIAL,
    TRADERS_GET_THIS_MONTH,
    TRADERS_GET_TRADER,
    TRADERS_RATE_TRADER,
    TRADERS_REMOVE_SUBSCRIPTION,
    TRADERS_SUBSCRIPTION_GET,
    TRADERS_SUBSCRIPTION_UPDATED,
    TRADERS_SUBSCRIPTIONS_GET,
    TRADERS_TRADER_UPDATED,
    TRADERS_USER_BUYS_SUBSCRIPTION,
    TRADERS_USER_UNSUBSCRIBES_BY_REMOVAL,
} from "./actionTypes";

export const getTradersInitial = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    props.page = 0
    axios.post(`/traders/getbypage`, props)
        .then(res => {
            dispatch({
                type: TRADERS_GET_INITIAL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getTradersByPage = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/traders/getbypage`, props)
        .then(res => {
            dispatch({
                type: TRADERS_GET_ALL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getTrader = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/traders/get`, props)
        .then(res => {
            if (cb) cb()
            dispatch({
                type: TRADERS_GET_TRADER,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getTraderByUser = (cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/traders/getbyuser`)
        .then(res => {
            if (cb) cb(res.data)
            dispatch({
                type: TRADERS_GET_TRADER,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const saveTrader = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/traders/save`, props)
        .then(res => {
            if (cb) cb()
            dispatch({
                type: TRADERS_TRADER_UPDATED,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const saveTradersLogo = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/traders/logo/save`, props)
        .then(res => {
            if (cb) cb()
            dispatch({
                type: TRADERS_TRADER_UPDATED,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const saveTraderSubscription = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/traders/savesubscription`, props)
        .then(res => {
            if (cb) cb()
            dispatch({
                type: TRADERS_SUBSCRIPTION_UPDATED,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const addTraderSubscription = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/traders/addsubscription`, props)
        .then(res => {
            if (cb) cb()
            dispatch({
                type: TRADERS_ADD_SUBSCRIPTION,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const removeTraderSubscription = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/traders/removesubscription`, props)
        .then(res => {
            if (cb) cb(res.data)
            if (!res.data.error) {
                dispatch({
                    type: TRADERS_REMOVE_SUBSCRIPTION,
                    payload: res.data
                })
            }
        }).catch(error => handleError(error, dispatch))
}

export const applyForTrader = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/traders/applications/apply`, props)
        .then(res => {
            if (cb) cb(res.data)
            // dispatch({
            //     type: TRADERS_APPLY_FOR_TRADER,
            //     payload: res.data
            // })
        }).catch(error => {
            if (cb) cb({error: true})
    })
}

export const getUserTraderApplication = (cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/traders/applications/getapp`)
        .then(res => {
            if (cb) cb(res.data)
            // dispatch({
            //     type: TRADERS_APPLY_FOR_TRADER,
            //     payload: res.data
            // })
        }).catch(error => {
        if (cb) cb({error: true})
    })
}

export const getTradersSubscription = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/traders/getsubscription`, props)
        .then(res => {
            dispatch({
                type: TRADERS_SUBSCRIPTION_GET,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getTradersSubscriptions = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/traders/getsubscriptions`, props)
        .then(res => {
            dispatch({
                type: TRADERS_SUBSCRIPTIONS_GET,
                payload: res.data
            })
            if (cb) cb()
        }).catch(error => handleError(error, dispatch))
}

export const buyTradersSubscription = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/traders/buysubscription`, props)
        .then(res => {
            if (cb) cb()
            if (!res.data.error) {
                dispatch({
                    type: TRADERS_USER_BUYS_SUBSCRIPTION,
                    payload: res.data
                })
                dispatch({
                    type: CHANGE_SIGNAL_STATE_AFTER_BUYING_SUBSCRIPTION,
                    payload: res.data
                })
            } else {
                dispatch({
                    type: TRADERS_USER_BUYS_SUBSCRIPTION,
                    payload: res.data
                })
            }

        }).catch(error => handleError(error, dispatch))
}

export const getUserTraderSubscriptions = (cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/traders/gettradersubscriptionsforuser`)
        .then(res => {
            if (cb) cb(res.data)
        }).catch(error => handleError(error))
}

export const changeAutoRenewOnSubscriptionByFollower = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/traders/changeautorenew`, props)
        .then(res => {
            if (cb) cb()
        }).catch(error => handleError(error, dispatch))
}

export const unsubscribeByFollower = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/traders/unsubscribe`, props)
        .then(res => {
            if (res.data.remove) {
                dispatch({
                    type: TRADERS_USER_UNSUBSCRIBES_BY_REMOVAL,
                    payload: res.data
                })
            }
            if (cb) cb()
        }).catch(error => handleError(error, dispatch))
}

export const reSubscribeByFollower = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/traders/resubscribe`, props)
        .then(res => {
            if (cb) cb()
        }).catch(error => handleError(error, dispatch))
}

export const removeSubscriptionByFollower = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/traders/unsubscribebyremoval`, props)
        .then(res => {
            if (cb) cb()
            dispatch({
                type: TRADERS_USER_UNSUBSCRIBES_BY_REMOVAL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getTraderFollowersInitial = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    props.page = 0
    axios.post(`/traders/followers/getbypage`, props)
        .then(res => {
            dispatch({
                type: TRADERS_GET_FOLLOWERS_GET_INITIAL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getTraderFollowersByPage = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return

    axios.post(`/traders/followers/getbypage`, props)
        .then(res => {
            dispatch({
                type: TRADERS_GET_FOLLOWERS_GET_ALL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getTraderFollower = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return

    axios.post(`/traders/followers/get`, props)
        .then(res => {
            dispatch({
                type: TRADERS_GET_FOLLOWER,
                payload: res.data
            })
            if (cb) cb(res.data)
        }).catch(error => handleError(error, dispatch))
}

export const removeTraderFollower = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return

    axios.post(`/traders/followers/remove`, props)
        .then(res => {
            // dispatch({
            //     type: TRADERS_REMOVE_FOLLOWER,
            //     payload: res.data
            // })
            if (cb) cb(res.data)
        }).catch(error => handleError(error, dispatch))
}

export const addTraderFollower = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return

    axios.post(`/traders/followers/addedbytrader`, props)
        .then(res => {
            // dispatch({
            //     type: TRADERS_GET_FOLLOWER,
            //     payload: res.data
            // })
            if (cb) cb(res.data)
        }).catch(error => handleError(error, dispatch))
}

export const updateTraderFollower = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return

    axios.post(`/traders/followers/updatedbytrader`, props)
        .then(res => {
            // dispatch({
            //     type: TRADERS_GET_FOLLOWER,
            //     payload: res.data
            // })
            if (cb) cb(res.data)
        }).catch(error => handleError(error, dispatch))
}

export const getTraderSubscriptionPaymentsInitial = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    props.page = 0
    axios.post(`/traders/subscriptionpayments/getbypage`, props)
        .then(res => {
            dispatch({
                type: TRADERS_GET_SUBSCRIPTION_PAYMENTS_GET_INITIAL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getTraderSubscriptionPaymentsByPage = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/traders/subscriptionpayments/getbypage`, props)
        .then(res => {
            dispatch({
                type: TRADERS_GET_SUBSCRIPTION_PAYMENTS_GET_ALL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const rateTrader = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/traders/rate`, props)
        .then(res => {
            if (cb) cb()
            dispatch({
                type: TRADERS_RATE_TRADER,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getTraderCommissionsInitial = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    props.page = 0
    axios.post(`/traders/commissions/getbypage`, props)
        .then(res => {
            dispatch({
                type: TRADERS_GET_COMMISSIONS_GET_INITIAL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getTraderCommissionsByPage = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/traders/commissions/getbypage`, props)
        .then(res => {
            dispatch({
                type: TRADERS_GET_COMMISSIONS_GET_ALL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getThisMonthTraderCommissions = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/traders/commissions/getThisMonth`, props)
        .then(res => {
            dispatch({
                type: TRADERS_GET_THIS_MONTH,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}
