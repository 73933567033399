import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {brushValue} from "./UISupportFns";
import CircularIndeterminate from "./loadingSmall";

export default function NeedExtraIsoMargDialog(props) {

    const {open, title, extraAdditionalMargin, availableMargin, handleOk, handleCancel, okLabel, showWaitingAddingExtraIsoMarg} = props

    return (
            <Dialog
                open={open ? open : false}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" component={'div'}>
                        Additional <b>{brushValue(extraAdditionalMargin, 4)}</b> USDT isolated margin needed.
                        <div style={{fontSize: "0.9rem", opacity: "0.9"}}>Margin available <b>{brushValue(availableMargin,4)}</b> USDT.
                            {extraAdditionalMargin > availableMargin && <span style={{color: "#fb4a4a"}}> (INSUFFICIENT)</span>}
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {extraAdditionalMargin < availableMargin && <div>
                        {showWaitingAddingExtraIsoMarg && <Button color="primary">
                            <CircularIndeterminate/>&nbsp;
                            {!okLabel && <span>OK</span>}
                            {okLabel && <span>{okLabel}</span>}
                        </Button>}
                        {!showWaitingAddingExtraIsoMarg && <Button onClick={handleOk} color="primary">
                                {!okLabel && <span>OK</span>}
                                {okLabel && <span>{okLabel}</span>}
                            </Button>}
                        </div>
                    }
                    <Button onClick={handleCancel} color="primary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
    );
}
