import makeStyles from "@material-ui/core/styles/makeStyles";
import Fab from "@material-ui/core/Fab";
import React from "react";
import c from '../../constants'
import {getFuturesTradeEnterVerb, getTradeEnterVerb, getTradeExitVerb} from "./UISupportFns";
import {Warning} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    fabCancel: {
        margin: theme.spacing(1),
        width: 130,
        background: "#101926",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "white",
        '&:hover': {
            background: "#424242"
        }
    },
    fabTakeProfit: {
        margin: theme.spacing(1),
        background: "#152B40",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "white",
        '&:hover': {
            background: "#424242"
        }
    },
    fabDiscard: {
        margin: theme.spacing(1),
        background: "#FB4A4A",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "black",
        '&:hover': {
            background: "#424242"
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    }
}));

export default function FuturesTradeDetailsMainButtons(props) {
    const classes = useStyles()
    let {futuresTrade, takeProfit, cancelTrade, discardTrade, ASAS} = props
    let verb = getFuturesTradeEnterVerb(futuresTrade)


    if (ASAS && ASAS.suspendActivities === "true" && ASAS.suspendedActivitiesMap[c.SUSPENDED_ACTIVITIES_MANUAL_TAKE_PROFIT])
        return <div className={"maintenanceWarning"} style={{width: "20rem"}}>
            <div><Warning/></div>
            <div style={{marginLeft: "0.6rem"}}>
                Canceling orders and manual take profit are temporarily disabled.
            </div>
        </div>

    if (futuresTrade.child_order && futuresTrade.child_order.order_id) {
        if (futuresTrade.child_order.status === c.ORDER_STATUS_NEW) {
            //This means we have an active futuresTrade upon we can exert actions like: take profit/cancel futuresTrade
            if (futuresTrade.ven_liquidation_state)
                return (<div>
                    <div className={"tradeDetailsMainButtonsContainer"} style={{justifyContent: "center"}}>
                        <div style={{width: "18rem"}}>Trade was liquidated by the exchange.<br/>Waiting for the liquidation order...</div>
                    </div>
                </div>)

            return (
                <div className={"tradeDetailsMainButtonsContainer2"}>
                    <Fab variant="extended" color="primary" aria-label="Preview" className={classes.fabTakeProfit}
                         onClick={() => {
                             takeProfit()
                         }}>
                        <div>Take profit&nbsp;&nbsp;</div>
                    </Fab>
                </div>
            )
        } else if (!futuresTrade.from_SUC && futuresTrade.child_order.status === c.ORDER_STATUS_PARTIALLY_FILLED) {
            return (
                <div>
                    <div className={"tradeDetailsMainButtonsContainer"} style={{justifyContent: "center"}}>
                        <div>Trade is filling...</div>
                    </div>
                </div>)
        } else if (futuresTrade.from_SUC && futuresTrade.child_order.status === c.ORDER_STATUS_PARTIALLY_FILLED) {
            if (futuresTrade.ven_liquidation_state)
                return (<div>
                    <div className={"tradeDetailsMainButtonsContainer"} style={{justifyContent: "center"}}>
                        <div style={{width: "18rem"}}>Trade was liquidated by the exchange.<br/>Waiting for the liquidation order...</div>
                    </div>
                </div>)

            return (
                    <div>
                        <div className={"tradeDetailsMainButtonsContainer"} style={{justifyContent: "center"}}>
                            {futuresTrade.child_order.sub_type === "TAKE_PROFIT_TARGET_1" && <div>Trade is filling...</div>}
                            {futuresTrade.child_order.sub_type === "TAKE_PROFIT_TARGET_1_FILLED" &&
                            <div>Take profit target #1 reached.</div>}
                        </div>
                        <div className={"tradeDetailsMainButtonsContainer2"} style={{justifyContent: "center"}}>
                            <Fab variant="extended" color="primary" aria-label="Preview" className={classes.fabTakeProfit}
                                 onClick={() => {
                                     takeProfit()
                                 }}>
                                <div>Take profit&nbsp;&nbsp;</div>
                            </Fab>
                        </div>
                    </div>)
        } else if (futuresTrade.child_order.status === c.ORDER_STATUS_FILLED || futuresTrade.child_order.status === c.ORDER_STATUS_CANCELED
            || futuresTrade.child_order.status === c.ORDER_STATUS_ERROR) {
            return (<div>
                        {futuresTrade.child_order.status === c.ORDER_STATUS_CANCELED && <div className={"tradeDetailsMainButtonsContainer2"}>
                            <div style={{width: "16rem", margin: "auto"}}>Trade canceled</div>
                        </div>}
                    <div className={"tradeDetailsMainButtonsContainer2"} style={{justifyContent: "center"}}>
                        <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabDiscard}
                             onClick={() => {
                                 discardTrade()
                             }}>
                            <div style={{color: "white"}}>Discard</div>
                        </Fab>
                    </div>
                </div>)
        }
    } else {
        //If the Futures Trade doesn't have a child, means it only has the Open Order
        //so it's ok to let the user cancel it in this state
        if (futuresTrade.status === c.ORDER_STATUS_NEW) {
            //This is a waiting futuresTrade
            return (<div>
                <div className={"tradeDetailsMainButtonsContainer2"}>
                    <div style={{width: "16rem", margin: "auto"}}>Order is active but zero filled...</div>
                </div>
                    <div className={"tradeDetailsMainButtonsContainer"} style={{justifyContent: "center"}}>
                        <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabCancel}
                             onClick={() => {
                                 cancelTrade()
                             }}>
                            <div style={{color: "white"}}>Cancel</div>
                        </Fab>
                    </div>
            </div>)
        } else if (futuresTrade.status === c.ORDER_STATUS_PARTIALLY_FILLED) {
            //This is a waiting futuresTrade
            return (<div>
                    <div className={"tradeDetailsMainButtonsContainer2"}>
                        <div style={{width: "16rem", margin: "auto"}}>Order is filling...</div>
                    </div>
                    <div className={"tradeDetailsMainButtonsContainer"} style={{justifyContent: "center"}}>
                        <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabCancel}
                             onClick={() => {
                                 cancelTrade()
                             }}>
                            <div style={{color: "white"}}>Cancel</div>
                        </Fab>
                    </div>
                </div>)
        } else if (futuresTrade.status === c.ORDER_STATUS_FILLED) {
            //This is a waiting futuresTrade
            return (
                <div className={"tradeDetailsMainButtonsContainer"} style={{justifyContent: "center", height: "2rem"}}>
                    <div>Futures trade started.</div>
                </div>
            )
        } else if (futuresTrade.status === c.ORDER_STATUS_CANCELED) {
            //This is a waiting futuresTrade
            return (
                <div>
                    <div className={"tradeDetailsMainButtonsContainer2"}>
                        <div style={{width: "16rem", margin: "auto"}}>Futures trade canceled before {verb}</div>
                    </div>
                    <div className={"tradeDetailsMainButtonsContainer"} style={{justifyContent: "center"}}>
                        <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabDiscard}
                             onClick={() => {
                                 discardTrade()
                             }}>
                            <div style={{color: "white"}}>Discard</div>
                        </Fab>
                    </div>
                </div>
            )
        } else if (futuresTrade.status === c.ORDER_STATUS_ERROR) {
            //This is a waiting futuresTrade
            return (
                <div>
                    <div className={"tradeDetailsMainButtonsContainer2"}>
                        <div style={{width: "16rem", margin: "auto"}}>Futures trade errored before {verb}</div>
                    </div>
                    <div className={"tradeDetailsMainButtonsContainer"} style={{justifyContent: "center"}}>
                        <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabDiscard}
                             onClick={() => {
                                 discardTrade()
                             }}>
                            <div style={{color: "white"}}>Discard</div>
                        </Fab>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <div className={"tradeDetailsMainButtonsContainer2"}>
                        <div style={{width: "16rem", margin: "auto"}}>Unknown status...</div>
                    </div>
                    <div className={"tradeDetailsMainButtonsContainer"} style={{justifyContent: "center"}}>
                        <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabDiscard}
                             onClick={() => {
                                 discardTrade()
                             }}>
                            <div style={{color: "white"}}>Discard</div>
                        </Fab>
                    </div>
                </div>
            )
        }
    }
}
