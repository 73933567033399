import React from "react"
import {Link as RouterLink} from 'react-router-dom'
import Fab from '@material-ui/core/Fab'
import {Visibility, Clear} from "@material-ui/icons"
import makeStyles from "@material-ui/core/styles/makeStyles"
import c from '../../constants'

const useStyles = makeStyles(theme => ({
    fabCancel: {
        margin: theme.spacing(1),
        width: 160,
        background: "#101926",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "white",
        '&:hover': {
            background: "#424242"
        }
    },
    fabPreviewLong: {
        margin: theme.spacing(1),
        width: 160,
        background: "#3BADFF",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "black",
        '&:hover': {
            background: "#424242", color: "white"
        }
    },
    fabPreviewShort: {
        margin: theme.spacing(1),
        width: 160,
        background: "#FE484F",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "black",
        '&:hover': {
            background: "#424242", color: "white"
        }
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

export default function NewFuturesStep1Buttons({preview, futuresType, ASAS}) {
    const classes = useStyles()

    function isNewTradeEnabled() {
        if (ASAS && ASAS.suspendActivities === "true" &&
            ASAS.suspendedActivitiesMap &&
            ASAS.suspendedActivitiesMap[c.SUSPENDED_ACTIVITIES_NEW_TRADE_ID])
            return false
        return true
    }

    return(
        <div style={{display: "flex", justifyContent: "space-between", maxWidth: "24em", padding: "2em 0.6em 2em 0.6em"}}>
        <Fab variant="extended" color="default" aria-label="Cancel" className={classes.fabCancel}>
            <RouterLink to={'/'} style={{color:"white"}}>
                Cancel&nbsp;&nbsp;
            </RouterLink>
        </Fab>
            {isNewTradeEnabled() && <Fab variant="extended" color="primary" aria-label="Preview"
                                         className={futuresType === c.FUTURES_TYPE_LONG ? classes.fabPreviewLong: classes.fabPreviewShort} onClick={preview}>
        <Visibility className={classes.extendedIcon}/>
        Preview&nbsp;&nbsp;
        </Fab>}
        </div>
    )
}
