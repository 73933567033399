import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";
import rootReducer from "./redux/reducers/rootReducer";
import { initStore as wsPrices } from "./redux/actions/wsPrices";
import { initStore as wsOrderBook } from "./redux/actions/wsOrderBook";

const inititalState = {};

const composeEnhancers = composeWithDevTools({
    actionsBlacklist: ["GET_SUC_UPDATE"],
});

const store = createStore(
  rootReducer,
  inititalState,
  composeEnhancers(applyMiddleware(thunk))
);

wsPrices(store);
wsOrderBook(store);
export default store;
