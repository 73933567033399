import React, {Component} from 'react'
import { connect } from 'react-redux'
import Cookies from 'universal-cookie'
import c from '../../../constants'
import {Helmet} from "react-helmet/es/Helmet";
import {Button, Container, Grid} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import {getInvestmentProgramReferralLinkDetails} from "../../../redux/actions/investment";
const qs = require("query-string")


let checkInvProgram = invProgram => {
    if (!invProgram)
        return false
    if (invProgram && invProgram.title === "unknown")
        return false
    return true
}

class InvestmentProgramReferralLink extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cookie: "unknown"
        }
    }

    componentDidMount() {
        let uid = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).c
        this.props.getInvestmentProgramReferralLinkDetails({uid}, data => {
            let {user, invProgram} = data
            const cookies = new Cookies()
            //The cookie will expire after 7 days
            cookies.set(`${c.INVESTMENT_PROGRAM_REFERRAL_COOKIE}_IP_ID`, invProgram._id, {path: "/", maxAge: 604800})
            cookies.set(`${c.INVESTMENT_PROGRAM_REFERRAL_COOKIE}_USER`, user._id, {path: "/", maxAge: 604800})
            cookies.set(`${c.INVESTMENT_PROGRAM_REFERRAL_COOKIE}_ACCESS_TIME`, new Date().getTime(), {path: "/", maxAge: 604800})
            cookies.set(`${c.INVESTMENT_PROGRAM_REFERRAL_COOKIE}_NEW`, true, {path: "/", maxAge: 604800})
            cookies.set(`${c.INVESTMENT_PROGRAM_REFERRAL_COOKIE}_R_LINK_UID`, uid, {path: "/", maxAge: 604800})
            if (this.props.user) {
                this.props.history.push(`/contributions?id=${invProgram._id}`)
            }
            this.setState({
                user, invProgram
            })
        })
    }

    render() {
        return (<div>
            <Helmet>
                <title>{window.location.hostname}</title>
            </Helmet>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div style={{marginTop: `${checkInvProgram(this.state.invProgram) ? '3rem': '6rem'}`}}>
                    <Grid container spacing={2}>
                        {checkInvProgram(this.state.invProgram) && <Grid item xs={12} sm={12}>
                            <h1>Welcome,</h1>
                            <div><span style={{opacity: "0.8"}}>and thank you for choosing to invest in</span>
                                <span style={{marginLeft: "0.5rem", marginRight: "0.5rem", fontSize: "1.4rem", fontWeight: "bold"}}>{this.state.invProgram.title}</span>
                                <span style={{opacity: "0.8"}}>through the invite from</span>
                                <span style={{marginLeft: "0.5rem", fontSize: "1rem"}}>{this.state.user.firstName} {this.state.user.lastName}</span>.</div>

                        </Grid>}
                        <Grid item xs={12} sm={12}>
                            <div style={{opacity: "0.8"}}>If you are an existent user, please login.</div>
                            <Button onClick={()=> {this.props.history.push("/login")}} fullWidth variant="contained" color="primary">
                                Login</Button>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <div style={{opacity: "0.8"}}>If you are a new user, please register.</div>
                            <Button onClick={()=> {this.props.history.push("/register")}} fullWidth variant="contained" color="primary">Register</Button>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getInvestmentProgramReferralLinkDetails: (props, cb) => dispatch(getInvestmentProgramReferralLinkDetails(props, cb))
    }
}

const mapStateToProps = rootState => ({
    user: rootState.authentication.user,
})

export default
connect(mapStateToProps, mapDispatchToProps) (InvestmentProgramReferralLink)
