import React, {Component} from 'react'
import c from '../../../constants'
import coinNamesNom from '../../../nom/coinNamesNom'
import EnhancedTableStakingBalance from "../../fncomponents/EnhancedTableStakingBalance"

class StakeBalance extends Component {

    populateCoinNames (balance) {
        return balance.map(balanceEntry => {
            balanceEntry.name = coinNamesNom[balanceEntry.coin]
            return balanceEntry
        })
    }

    render() {
        const columns = [
            { id: 'coin', numeric: false, disablePadding: true, label: 'Coin', pic: {class: 'coinLogo',
                    picAddress: (e) => {return `imgs/coins/${e.toUpperCase()}_${c.COIN_LOGOS_PIC_VERSION}.png`}} },
            { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
            { id: 'totalBalance', numeric: true, disablePadding: false, label: 'Total Balance' },
            { id: 'availableBalance', numeric: true, disablePadding: false, label: 'Available Balance' },
            { id: 'stakingBalance', numeric: true, disablePadding: false, label: 'Staking Balance' },
            { id: 'pendingStakingBalance', numeric: true, disablePadding: false, label: 'Pending Staking Balance' },
            { id: 'pendingUnstakingBalance', numeric: true, disablePadding: false, label: 'Pending Unstaking Balance' },
            { id: 'actions', numeric: true, disablePadding: false, label: 'Actions'}
        ]

        return <div>
            {this.props.totals &&
            <div style={{display: "flex", justifyContent: "flex-start", width: "100%", fontSize: "0.9rem"}}>
                <span style={{color: "gray"}}>Estimated value:</span>&nbsp;<b>{this.props.totals.BTC}</b>&nbsp;<span style={{color:"gray"}}>BTC&nbsp;${this.props.totals.USDT}</span></div>}
            <EnhancedTableStakingBalance rows={this.props.balances ? this.populateCoinNames(this.props.balances) : []} columns={columns} actions={this.props.actions}/>
        </div>
    }
}


export default StakeBalance
