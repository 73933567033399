import {
    CHECK_USER_BALANCE_FOR_FUTURES, CLEAR_NOT_ENOUGH_BALANCE_FOR_FUTURES,
    GET_CURRENCIES_MAP_FOR_FUTURES,
    GET_CURRENT_PRICE_FOR_FUTURES,
    GET_LAST_USED_PARAMS_ON_NEW_FUTURES,
    GET_USER_BASE_BALANCE_FOR_FUTURES, GET_USER_LEVERAGE,
    GET_USER_QUOTE_BALANCE_FOR_FUTURES, LAUNCH_NEW_FUTURES,
    PREVIEW_NEW_FUTURES
} from "../actions/actionTypes"

export default function (state = { lastUsed: {}, newFutures: {}}, action) {
    switch (action.type) {

        case GET_CURRENCIES_MAP_FOR_FUTURES: {
            return {
                ...state,
                ...{baseCurrenciesNom: action.payload.baseCurrenciesNom,
                    quoteCurrenciesNom: action.payload.quoteCurrenciesNom}
            }
        }

        case GET_CURRENT_PRICE_FOR_FUTURES: {
            return {
                ...state,
                ...{
                    currentLastBidPrice: action.payload.currentLastBidPrice,
                    precision: action.payload.precision
                }
            }
        }

        case GET_LAST_USED_PARAMS_ON_NEW_FUTURES: {
            return {
                ...state,
                ...{lastUsed: {
                        futuresType: action.payload.futuresType,
                        actionType: action.payload.actionType,
                        base: action.payload.base,
                        quote: action.payload.quote,
                        tradeType: action.payload.tradeType,
                        posSize: action.payload.posSize,
                        actionTypeOption: action.payload.actionTypeOption,
                        takeProfitOption: action.payload.takeProfitOption,
                        takeProfitPriceOrPercent: action.payload.takeProfitPriceOrPercent,
                        stopLossOption: action.payload.stopLossOption,
                        stopLossPriceOrPercent: action.payload.stopLossPriceOrPercent,
                    },
                    currentLastBidPrice: action.payload.currentLastBidPrice
                }
            }
        }

        case PREVIEW_NEW_FUTURES: {
            return {
                ...state,
                ...{newFutures: {
                        futuresType: action.payload.futuresType,
                        actionType: action.payload.actionType,
                        base: action.payload.base,
                        quote: action.payload.quote,
                        posSize: action.payload.posSize,
                        margin: action.payload.cost,
                        openLoss: action.payload.openLoss,
                        notionalValueOrig: action.payload.notionalValueOrig,
                        notionalValue: action.payload.notionalValue,
                        leverage: action.payload.leverage,
                        liquidationPrice: action.payload.liquidationPrice,
                        additionalIsolatedMargin: action.payload.additionalIsolatedMargin,
                        posSizeCurrency: action.payload.posSizeCurrency,
                        fixedPrice: action.payload.fixedPrice,
                        currentPrice: action.payload.currentPrice,
                        actionTypeOption: action.payload.actionTypeOption,
                        takeProfitOption: action.payload.takeProfitOption,
                        takeProfitPriceOrPercent: action.payload.takeProfitPriceOrPercent,
                        stopLossOption: action.payload.stopLossOption,
                        stopLossPriceOrPercent: action.payload.stopLossPriceOrPercent,
                        candlesType: action.payload.candlesType
                    }
                }
            }
        }

        case CHECK_USER_BALANCE_FOR_FUTURES: {
            return {
                ...state,
                ...{notEnoughBalance: action.payload.result.status}
            }
        }

        case GET_USER_BASE_BALANCE_FOR_FUTURES: {
            return {
                ...state,
                ...{userBaseBalance: action.payload.value, userBasePrecision: action.payload.precision}
            }
        }

        case GET_USER_QUOTE_BALANCE_FOR_FUTURES: {
            return {
                ...state,
                ...{userQuoteBalance: action.payload.value,
                    userQuotePrecision: action.payload.precision,
                }
            }
        }

        case CLEAR_NOT_ENOUGH_BALANCE_FOR_FUTURES: {
            return {
                ...state,
                ...{notEnoughBalance: 1}
            }
        }

        case GET_USER_LEVERAGE: {
            return {
                ...state,
                ...{leverage: action.payload}
            }
        }

        case LAUNCH_NEW_FUTURES: {
            let newState = {...state, ...{}}
            newState.newFutures.futuresLaunched = Math.random() * 1000
            return newState
        }

        default:
            return state
    }
}
