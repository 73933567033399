import React, { Component } from "react";
import coinNamesNom from "../../../nom/coinNamesNom";
import { Warning } from "@material-ui/icons";

import {WalletCustomHeader} from "../../fncomponents/CustomHeaders";
import s from "../../../settings";
import { isBLVT } from "../../fncomponents/UISupportFns";
import CurrencyCard from "../../fncomponents/CurrencyCard";



class SpotBalance extends Component {
  walletHasAtLeastOneBLVT = false;

  populateCoinNames(balance) {
    return balance.map((balanceEntry) => {
      this.walletHasAtLeastOneBLVT = isBLVT(balanceEntry.coin);
      balanceEntry.name = coinNamesNom[balanceEntry.coin];
      return balanceEntry;
    });
  }

  componentDidMount() {
    this.props.changeRenderCustomHeader(() => <WalletCustomHeader />);
  }
  
  render() {
    return (
      <div className="currency-grid">
        {this.props.balances?.length > 0 ? (
          <>
            {
              this.populateCoinNames(this.props.balances).map((currency) => {
                if (currency.coin === "USDT") {
                  if (this.props.hasFuturesActivated) {
                    return [<CurrencyCard key={`${Math.random()}_${currency.coin}`} currency={currency}/>, <CurrencyCard
                        key={this.props.futuresBalances?.margin?.coin}
                        currency={this.props.futuresBalances?.margin}
                        isolatedMargin={this.props.futuresBalances?.isolatedMargin}
                        isFuture
                    />]
                  } else {
                    return <CurrencyCard key={currency.coin} currency={currency}/>
                  }
                } else {
                  return <CurrencyCard key={currency.coin} currency={currency}/>
                }
              }
            )}
            {/*{this.props.hasFuturesActivated && <CurrencyCard*/}
            {/*  key={this.props.futuresBalances?.margin?.coin}*/}
            {/*  currency={this.props.futuresBalances?.margin}*/}
            {/*  isolatedMargin={this.props.futuresBalances?.isolatedMargin}*/}
            {/*  isFuture*/}
            {/*/>}*/}
          </>
        ) : (
          <div className="currency__no-currencies">No existing coins</div>
        )}
        {this.walletHasAtLeastOneBLVT && (
          <div>
            <div className={"warningGeneralMessageSmall"}>
              <div>
                <Warning />
              </div>
              <div style={{ marginLeft: "0.6rem" }}>
                <div>
                  <a
                    href={
                      "https://academy.binance.com/economics/a-beginners-guide-to-binance-leveraged-tokens-blvt"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    BLVTs
                  </a>{" "}
                  are not intended for long term holding.
                </div>
                <div>
                  Holding a BLVT carries a{" "}
                  {s.BLVT_DAILY_MANAGEMENT_FEE_PERCENTAGE}% daily management
                  fee.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default SpotBalance
