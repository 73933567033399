import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getTitle} from "../../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"
import KeyboardBackArrow from '@material-ui/icons/KeyboardBackspace'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import c from "../../../constants"
import Fab from "@material-ui/core/Fab";
import {save} from "../../../redux/actions/bots"
import {makeStyles} from "@material-ui/core";
import CircularIndeterminate from "../../fncomponents/loading";

const useStyles = makeStyles(theme => ({
    fab: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

function SaveBotButton(props) {
    const classes = useStyles()
    const {onClick, updated} = props

    return (
        <div>
            <Fab variant="extended" aria-label="Delete" className={classes.fab} onClick={onClick}>
                {updated && <span>Update Bot</span>}
                {!updated && <span>Save Bot</span>}
            </Fab>
        </div>
    )
}

function WaitingBotToBeSaved(props) {
    let {show} = props
    if (show)
        return (<div className={"modal"} key={"kdldkl"}>
            <div className={"modal-content"} key={"dddffff"}>
                <div className="loading">
                    <CircularIndeterminate/>
                </div>
                <div className={"loadingText"}>Saving bot...</div>
            </div>
        </div>)
    else
        return null
}

class PreviewBot extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showWaitSaving: false
        }
        this.saveBot = this.saveBot.bind(this)
    }

    saveBot() {
        this.setState({
            showWaitSaving: true
        })
        this.props.save(this.props.bot, () => {
            this.setState({
                showWaitSaving: false
            })
        })
    }

    render() {
        let bot = this.props.bot
        let botSavedResult = this.props.botSavedResult
        let se = this.props.botErrors && this.props.botErrors.length > 0 ? this.props.botErrors: undefined
        let botActionType ={
            [c.ACTION_TYPE_BUY]: "Starts with a buy ('LONG')",
            [c.ACTION_TYPE_SELL]: "Starts with a sell ('SHORT')"}

        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <div className={"vertSpacer1"}/>
            <div style={{display: "flex", justifyContent: "flex-start", width: "20em", cursor: "pointer"}}>
                <div style={{display: "flex", justifyContent: "flex-start"}} onClick={()=>{this.props.history.push("/newbot")}}>
                    <KeyboardBackArrow/>
                    <div className={"pageTitle"} style={{marginLeft: "1rem"}}>Back</div>
                </div>
                {botSavedResult && <div style={{display: "flex", justifyContent: "flex-start"}} onClick={()=>{this.props.history.push("/bots")}}>
                    <div className={"pageTitle"} style={{marginLeft: "1rem"}}>Bots</div>
                    <KeyboardArrowRight style={{marginTop: "0.2rem"}}/></div>}
            </div>
            <WaitingBotToBeSaved show={this.state.showWaitSaving}/>
            {bot && <div>
                <div className={"newSignalPreviewHighlight"}>
                    {(bot.botDbId && bot.updated) && <span>Update bot:</span>}
                    {(bot.botDbId && !bot.updated) && <span>Nothing was changed to bot:</span>}
                    {!bot.botDbId && <span>Save bot:</span>}
                    <div style={{marginLeft: "2rem"}}>
                        <div>Run on <b>Binance</b>, <b>{botActionType[bot.actionType]}</b>, for pair <b>{bot.quoteAsset}/{bot.baseAsset}</b></div>
                        <div>starts trade&nbsp;
                            {bot.actionType === c.ACTION_TYPE_BUY && bot.actionTypeOption === c.ACTION_TYPE_OPTION_MARKET && <span style={{fontWeight: "bold"}}>
                                buying at market price</span>
                            }
                            {bot.actionType === c.ACTION_TYPE_SELL && bot.actionTypeOption === c.ACTION_TYPE_OPTION_MARKET && <span style={{fontWeight: "bold"}}>
                                selling at market price</span>
                            }
                            {bot.actionTypeOption === c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE && <span style={{fontWeight: "bold"}}>buying just above the bid price</span>}
                            {bot.actionTypeOption === c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE && <span style={{fontWeight: "bold"}}>buying just below the ask price</span>}
                        </div>
                        <div>position size <b>{bot.posSize} {bot.baseAsset}</b></div>
                        {bot.stopLossOption === c.STOP_LOSS_OPTION_MANUAL && <div>no stop loss</div>}
                        {bot.stopLossOption !== c.STOP_LOSS_OPTION_MANUAL && <div> stop loss target:&nbsp;
                            <span style={{fontWeight: "bold"}}>{bot.stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE && <span>-</span>}{bot.stopLossTarget}{bot.stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE && <span>%</span>}</span>
                        </div>}
                    </div>
                </div>
                {se && <div className={"newSignalPreviewHighlight"}>
                    Errors
                    {se.map((err, index) => {
                        return <div className={"errorContainer"}>{err}</div>
                    })}
                </div>}
                {!se && <div>
                    {!bot.botDbId && <SaveBotButton onClick={()=>{this.saveBot()}} updated={bot.updated}/>}
                    {(bot.botDbId && bot.updated) && <SaveBotButton onClick={()=>{this.saveBot()}} updated={bot.updated}/>}
                </div>}
            </div>
            }
            {botSavedResult &&  <div className={"newSignalPreviewHighlight"}>
                {botSavedResult.new && <div>Bot saved.</div>}
                {!botSavedResult.new &&
                <div style={{display: "flex", justifyContent:"flex-start"}}>
                    <div>Bot updated.</div>
                </div>}
            </div>}
        </div>
    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    bot: rootState.bots.bot,
    botErrors: rootState.bots.errors,
    botSavedResult: rootState.bots.botSavedResult,
})

let mapDispatchToProps = dispatch => {
    return {
        save: (props, cb) => dispatch(save(props, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (PreviewBot)
