import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import { connect } from 'react-redux'

import {twoFAAuth} from '../../redux/actions/authentication'
import OTPInput from "react-otp-input";
import {Helmet} from "react-helmet/es/Helmet";
import {getTitle} from "../fncomponents/PageTitleUtils";

class TwoFAAuth extends Component {

    constructor(props) {
        super(props)
        this.state = {
            otp: "",
        }

        this.handleChange = this.handleChange.bind(this)
        this.isAuthenticated = this.isAuthenticated.bind(this)
        this.has2FAEnabled = this.has2FAEnabled.bind(this)
    }

    handleChange(otp) {

        if (otp.length === 6) {
            this.state.otp = otp
            this.props.twoFAAuth({otp: otp})
        } else this.setState({
            otp: otp
        })
    }

    isAuthenticated() {
        if (this.props.user)
            return true
        return false
    }

    has2FAEnabled() {
        let user = this.props.user
        if (user && user.permissions && user.permissions[0] == "OTPONLY")
            return true
        return false
    }

    render() {
        if (this.isAuthenticated()) {
            if (!this.has2FAEnabled()) {
                return (
                    <Redirect to="/"/>
                )
            }
        }

        let errors = undefined
        if (this.props.errors)
            errors =  Object.keys(this.props.errors).length > 0 ? this.props.errors.message: undefined
        return (
            <div>
                <Helmet>
                    <title>{getTitle()}</title>
                </Helmet>
                <div className='loginForm' style={{marginTop: "6em"}}>
                    <div style={{display: "flex"}}>
                        <div className={"normalIconContainer"}>
                            <img src={'imgs/2fa/google2faicon.png'} className={"normalIcon"} alt={"2faicon"}/>
                        </div>
                        <div>
                            Input the 6-digit code in your Google Authenticator App
                        </div>
                    </div>
                    <br/>
                    {errors && <div>
                        <div style={{color: "red"}}>Invalid token...</div>
                        <br/>
                    </div>}
                    <div style={{marginBottom: "0.6em", color: "#8B8275"}}>Google Authentication Code:</div>
                    <OTPInput
                        shouldAutoFocus
                            numInputs={6}
                              value={this.state.otp}
                              onChange={this.handleChange}
                              containerStyle={{width: "18em", justifyContent: "space-between"}}
                              inputStyle={{width: "1.5em", height: "2em", borderBottom: "1px solid black", fontSize: "1.2em", color: "black", borderRadius: "5px"}}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = rootState => ({
    errors: rootState.errors.authError,
    user: rootState.authentication.user
})

const mapDispatchToProps =  dispatch => {
    return {
        twoFAAuth: (props) => {dispatch(twoFAAuth(props))},
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TwoFAAuth)
