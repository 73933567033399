import React, {Component} from 'react'
import {connect} from "react-redux"
import {debounce} from '../../hoc/myUtils'
import {getTitle} from "../../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"
import TraderFollowersTable from "./TraderFollowersTable"
import {
    getTraderFollowersByPage,
    getTraderFollowersInitial,
    getTradersSubscriptions, removeTraderFollower
} from "../../../redux/actions/traders"
import Button from "@material-ui/core/Button";
import AlertDialog from "../../fncomponents/AlertDialog";

class TraderFollowers extends Component {

    constructor(props) {
        super(props)
        this.state = {
            rows: [],
            page: 0,
            rowsPerPage: 10,
            filtersMap: {},
            sort: {},
            isRemoveFollowerDialogOpen: false,
            followerId: undefined
        }

        this.setRowsPerPage = this.setRowsPerPage.bind(this)
        this.setPage = this.setPage.bind(this)
        this.onFilterChange = this.onFilterChange.bind(this)
        this.onFilter = this.onFilter.bind(this)
        this.debouncedFilter = debounce(this.onFilter, 500)
        this.openRemoveFollowerDialog = this.openRemoveFollowerDialog.bind(this)
        this.hideRemoveFollowerDialog = this.hideRemoveFollowerDialog.bind(this)
        this.removeFollower = this.removeFollower.bind(this)
    }

    openRemoveFollowerDialog(followerId) {
        this.setState({
            isRemoveFollowerDialogOpen: true,
            followerId
        })
    }

    hideRemoveFollowerDialog() {
        this.setState({
            isRemoveFollowerDialogOpen: false,
            followerId: undefined
        })
    }

    removeFollower() {
        this.props.removeTraderFollower({_id: this.state.followerId}, () => {
            this.state.isRemoveFollowerDialogOpen = false
            this.state.followerId = undefined
            this.props.getTraderFollowersInitial({filters: this.state.filtersMap,
                sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
        })
    }

    setRowsPerPage (value) {
        this.state.rowsPerPage = value
        this.state.page = 0
        this.props.getTraderFollowersInitial({filters: this.state.filtersMap,
            sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
    }

    setPage (value) {
        let page = value
        if (!this.props.pagesDownloaded[page+1]) {
            this.state.page = page
            this.props.getTraderFollowersByPage({filters: this.state.filtersMap,
                sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
        } else {
            this.setState({
                page: page
            })
        }
    }

    onFilterChange(e) {
        let filterEntry = {[e.target.name]: e.target.value}
        this.setState({
            filtersMap: {...this.state.filtersMap, ...filterEntry}
        })
        this.debouncedFilter()
    }

    onFilter() {
        this.props.getTraderFollowersInitial({filters: this.state.filtersMap,
            sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
    }

    componentDidMount() {
        this.props.getTradersSubscriptions({}, () => {
            this.props.getTraderFollowersInitial({filters: this.state.filtersMap,
                sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
        })
    }

    render() {
        let {followers, totalDocs} = this.props

        return (
            <div className={"AppMainContent"}>
                <div className={"vertSpacer2"}/>
                <Helmet>
                    <title>{getTitle()}</title>
                </Helmet>
                <Button onClick={()=>{this.props.history.push("/trader/followers/new")}}>New</Button>
                <TraderFollowersTable
                    rows={followers}
                    rowsPerPage={this.state.rowsPerPage}
                    setRowsPerPage={this.setRowsPerPage}
                    page={this.state.page}
                    setPage={this.setPage}
                    totalDocs={totalDocs ? totalDocs : 0}
                    filtersMap={this.state.filtersMap}
                    onFilterChange={this.onFilterChange}
                    subscriptions={this.props.subscriptions}
                    removeFollower={this.openRemoveFollowerDialog}
                />
                <AlertDialog
                    open={this.state.isRemoveFollowerDialogOpen}
                    title={"Remove follower"}
                    texts={["Are you sure you want to remove this follower?", "Attention: this operation cannot be undone.",
                        "This follower won't receive any more signals from you.",
                    "However, you can add him back later if you know his email."]}
                    handleOk={this.removeFollower}
                    handleCancel={this.hideRemoveFollowerDialog}
                />
            </div>
        )
    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    followers: rootState.traders.followers,
    totalDocs: rootState.traders.totalDocs,
    totalPages: rootState.traders.totalPages,
    pagesDownloaded: rootState.traders.pagesDownloaded,
    subscriptions: rootState.traders.subscriptions
})

let mapDispatchToProps = dispatch => {
    return {
        getTraderFollowersInitial: (props) => dispatch(getTraderFollowersInitial(props)),
        getTraderFollowersByPage: (props) => dispatch(getTraderFollowersByPage(props)),
        getTradersSubscriptions: (props, cb) => dispatch(getTradersSubscriptions(props, cb)),
        removeTraderFollower: (props, cb) => dispatch(removeTraderFollower(props, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (TraderFollowers)
