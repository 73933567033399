import React from "react"
import Button from '@material-ui/core/Button'
import InputAdornment from "@material-ui/core/InputAdornment"
import {TextField} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Gavel from '@material-ui/icons/Gavel'
import c from '../../constants'
import {scientificToDecimal, brushValue} from "./UISupportFns"

const useStyles = makeStyles(theme => ({

    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 270,
        backgroundColor: '#101A26'
    },
    button: {
        minWidth: 100,
        marginRight: 10,
        marginTop: 10,
        borderRadius: 100,
        height: 50
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    }
}))

export default function BuySellAt({actionType, tradeType, actionTypeOption,
                                      handleChangeActionTypeOption,
                                      quote,
                                      base,
                                      fixedPrice, fixedPriceError,
                                      handleChangeFixedPrice,
                                      currentLastBidPrice, hideTitle,
                                      userAvgBuyPriceForQuote, tickSize
                                  }) {
    const classes = useStyles()
    const [selectedActionTypeOption, setSelectedActionTypeOption] = React.useState(0)
    const [textFieldEnabled, setTextFieldEnabled] = React.useState(true)
    // const [_fixedPrice, setFixedPrice] = React.useState("0")
    const [_currentLastBidPrice, setCurrentLastBidPrice] = React.useState("0")

    React.useEffect(()=> {
        if (quote.length < 2) {
            setSelectedActionTypeOption(-1)
            return
        }

        // if (fixedPrice >= 0) {
        //     setFixedPrice(scientificToDecimal(`${fixedPrice}`))
        // }

        if (currentLastBidPrice >= 0) {
            setCurrentLastBidPrice(brushValue(currentLastBidPrice, tickSize))
        }

        switch (actionTypeOption) {
            case c.ACTION_TYPE_OPTION_FIXED_PRICE: setSelectedActionTypeOption(0); break
            case c.ACTION_TYPE_OPTION_MARKET: setSelectedActionTypeOption(1); break
            case c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE: setSelectedActionTypeOption(2); break
            case c.ACTION_TYPE_OPTION_WAIT_FOR_A_DIP: setSelectedActionTypeOption(3); break
            case c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE: setSelectedActionTypeOption(4); break
            default : setSelectedActionTypeOption(-1); break
        }

        if (actionTypeOption == c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE ||
            actionTypeOption == c.ACTION_TYPE_OPTION_MARKET) {
            setTextFieldEnabled(false)
        } else {
            setTextFieldEnabled(true)
        }
    })

    function getTitle() {
        if (actionType == c.ACTION_TYPE_BUY) {
            if (tradeType == c.TRADE_TYPE_NEW_TRADE)
                return "How will you buy"
            else return "At what price did you buy it"
        } else if (actionType == c.ACTION_TYPE_SELL) {
            if (tradeType == c.TRADE_TYPE_NEW_TRADE)
                return "How will you sell"
            else return "At what price did you sell it"
        }
    }

    function getPosSizeCurrency() {
        return base ? base.toUpperCase() : "..."
    }

    function getPercentForDipPrice() {
        let percent = brushValue((fixedPrice - _currentLastBidPrice) / _currentLastBidPrice * 100, 1)
        let sign = percent > 0 ? '+': ''
        let color = percent < 0 ? '#FB4A4A': 'white'
        return <div style={{color: color, marginLeft: "0.2rem"}}>{`(${sign}${percent}%)`}</div>
    }

    function getVerb() {
        if (actionType === c.ACTION_TYPE_BUY)
            return "buying"
        else if (actionType === c.ACTION_TYPE_SELL)
            return "selling"
    }

    function getJABorJBA() {

        if (actionType === c.ACTION_TYPE_BUY)
            return "just above the 1st bid price"
        else if (actionType === c.ACTION_TYPE_SELL)
            return "just below the 1st ask price"
    }

    return (<div className={"wizardSectionContainer"}>
        {!hideTitle && <div className={"wizardTitle"}>
            <span className={"wizardTitleIcon"}><Gavel/></span> {getTitle()}
        </div>}
        {tradeType === c.TRADE_TYPE_NEW_TRADE && <div>
            <div style={{display: "flex", justifyContent: "flex-start", flexWrap: "wrap", marginBottom: "0.6em", marginLeft: "0.6em"}}>
                <Button className={classes.button} variant={selectedActionTypeOption === 0 ? "contained": "outlined"} color={"primary"}
                        onClick={()=>{handleChangeActionTypeOption(c.ACTION_TYPE_OPTION_FIXED_PRICE)}}>Fixed price</Button>
                <Button className={classes.button} variant={selectedActionTypeOption === 1 ? "contained": "outlined"} color={"primary"}
                        onClick={()=>{handleChangeActionTypeOption(c.ACTION_TYPE_OPTION_MARKET)}}>Market</Button>
                {actionType === c.ACTION_TYPE_BUY && <Button className={classes.button} variant={selectedActionTypeOption === 2 ? "contained": "outlined"} color={"primary"}
                        onClick={()=>{handleChangeActionTypeOption(c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE)}}>Just above bid</Button>}
                {actionType === c.ACTION_TYPE_SELL && <Button className={classes.button} variant={selectedActionTypeOption === 4 ? "contained": "outlined"} color={"primary"}
                        onClick={()=>{handleChangeActionTypeOption(c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE)}}>Just below ask</Button>}<br/>
                <br/>

            </div>
            {(tradeType === c.TRADE_TYPE_NEW_TRADE && actionType === c.ACTION_TYPE_BUY) && <div style={{marginLeft: "0.6em", marginBottom: "0.6em"}}>
                <Button className={classes.button} variant={selectedActionTypeOption === 3 ? "contained": "outlined"} color={"primary"}
                        onClick={()=>{handleChangeActionTypeOption(c.ACTION_TYPE_OPTION_WAIT_FOR_A_DIP)}}>Wait a Dip</Button>
            </div>}

            {selectedActionTypeOption === 0 &&
            <div style={{marginLeft: "0.8em", fontSize: "0.8rem", width: "20rem"}}>
                Enter trade by {getVerb()} at a fixed price.
            </div>}
            {selectedActionTypeOption === 1 &&
            <div style={{marginLeft: "0.8em", fontSize: "0.8rem", width: "20rem"}}>
                Enter trade by {getVerb()} at market price.
            </div>}
            {selectedActionTypeOption === 2 &&
            <div style={{marginLeft: "0.8em", fontSize: "0.8rem", width: "20rem"}}>
                {actionType === c.ACTION_TYPE_BUY && <span>Enter trade by {getVerb()} at just above the 1st bid price.</span>}
                {actionType === c.ACTION_TYPE_SELL && <span>Enter trade by {getVerb()} at just below the 1st ask price.</span>}
            </div>}
            {selectedActionTypeOption === 3 &&
            <div style={{marginLeft: "0.8em", fontSize: "0.8rem", width: "20rem"}}>
            Wait for a price dip before launching trade.
            After the dip is reached a new trade is launched by buying at just above the 1st bid.
            </div>}
        </div>}
        {userAvgBuyPriceForQuote > 0 && <div className={"avgBuyPriceDetails"}>Avg. price calculated from your previous trades.</div>}
        <div style={{marginTop: "1em"}}>
            <TextField
                id="outlined-adornment-weight_buy_sell_at"
                className={classes.textField}
                variant="outlined"
                margin="dense"
                disabled={!textFieldEnabled}
                value={fixedPrice}
                error={fixedPriceError}
                onChange={handleChangeFixedPrice}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {selectedActionTypeOption === 3 && <span>&le;</span>}
                        </InputAdornment>
                    ),
                    endAdornment: <InputAdornment position="end">
                        <div style={{display: "flex"}}>
                        {getPosSizeCurrency()}
                        {selectedActionTypeOption === 3 && getPercentForDipPrice()}
                        </div>
                    </InputAdornment>,
                }}
                inputProps={{ maxLength: 16 }}
            />
            {fixedPriceError && <div className={"warning"} style={{fontSize: "0.8rem", marginLeft: "0.6rem"}}>{fixedPriceError}</div>}
            {currentLastBidPrice && <div className={"currentLastBidPrice"}>Last price <b>&nbsp;{_currentLastBidPrice}&nbsp;{base}</b></div>}
        </div>
    </div>)
}
