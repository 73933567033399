export const depositStatusesNom = {1: "Pending...", 2: "Completed", 3: "Canceled", 66: "Rejected"}
const emailStatusesNom = {1: "Sent", 0: "Not sent", 2: "Re-sent"}
export function populateStatusNamesForDeposits(entries){
    if (!entries)
        return
    return entries.map(entry => {
        entry.statusName = depositStatusesNom[entry.status]
        entry.emailSent = emailStatusesNom[entry.emailSent]
        return entry
    })
}
export const withdrawalStatusesNom = {
    11: "Pending...",
    12: "Confirmed by you",
    40: "Pending...",
    50: "Rejected",
    51: "Rejected",
    60: "Completed",
}

export const withdrawalStatusesNomDetails = {
    50: "insufficient balance",
}

export function populateStatusNamesForWithdrawals(entries){
    if (!entries)
        return
    return entries.map(entry => {
        entry.statusName = withdrawalStatusesNom[entry.status]
        entry.emailSent = emailStatusesNom[entry.emailSent]
        return entry
    })
}
