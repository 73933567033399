import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles"
import Info from '@material-ui/icons/Info'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(1),
        width: 280,
        backgroundColor: '#101A26'
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    }
}))

export default function InfoBox(props) {
    const {message, width} = props

    return (
    <div className={"wizardSectionContainer"}>
        <div className={"wizardTitle"} style={{display: "flex"}}>
            <span className={"wizardTitleIcon"}><Info/></span><div className={"infoText"} style={{marginTop: "0.4rem", width}}>{message}</div>
        </div>
    </div>)
}
