import React, { useState } from "react";
import { Card } from "@material-ui/core";
import { Divider, Button } from "@material-ui/core";
import {
  CHANGE_TO_BALANCE_TAB,
  CHANGE_SELECTED_WITHDRAWAL_COIN,
  CHANGE_TO_TRANSFERS_TAB, CHANGE_TO_STAKING_TAB,
} from "../../redux/actions/actionTypes";
import c from '../../constants'
import { useDispatch } from "react-redux";
import DepositModal from "./DepositModal";
import {brushValue} from "./UISupportFns";
import { isDepositable, isStakeable } from "../pages/wallet/WalletUtilities";

const CurrencyCard = ({ currency = {}, isFuture, isolatedMargin }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const {
    coin,
    totalBalance,
    availableBalance,
    inOrderBalance,
    name,
  } = currency;

  const dispatch = useDispatch();

  return (
    <Card
      className="currency-card"
      style={{
        borderRadius: "24px",
        backgroundColor: "rgba(255, 255, 255, 0.04)",
      }}
      onClick={() =>
        dispatch({
          type: CHANGE_TO_BALANCE_TAB,
          payload: { selectedBalanceCoin: { ...currency, isFuture, isolatedMargin } },
        })
      }
    >
      <div className="currency-card__header">
        <span>{coin}</span>
        <span className="currency-card__dot"> · </span>
        {isFuture ? <span style={{fontWeight: "800", fontSize: "18px"}}>FUTURES</span> : <span>{name}</span>}
      </div>
      <div className="currency-card__content">
          <img
              src={`imgs/coins/${coin?.toUpperCase()}_${c.COIN_LOGOS_PIC_VERSION}.png`}
              className="coinLogo"
              alt="icon"
          />
        <span className="currency-card__available-balance">{brushValue(availableBalance + inOrderBalance + (isolatedMargin ? isolatedMargin: 0), isFuture ? 2: 8)}</span>
      </div>
      <div className="currency-card__balance">
        <div className="currency-card__sums">
          <div>TOTAL</div>
          <div>{brushValue(availableBalance + inOrderBalance + (isolatedMargin ? isolatedMargin: 0))}</div>
        </div>
        <div style={{ margin: "0 36px" }} className="currency-card__sums">
          <div>AVAILABLE</div>
          <div><b>{brushValue(availableBalance)}</b></div>
        </div>
        {!isFuture && <div className="currency-card__sums">
          <div>IN TRADES</div>
          <div>{brushValue(inOrderBalance)}</div>
        </div>}
        {isFuture && <div className="currency-card__sums" style={{width: "90px"}}>
          <div>IN TRADES/A.I.M.</div>
          <div>{brushValue(inOrderBalance, 2)}/{brushValue(isolatedMargin, 2)}</div>
        </div>}
      </div>
      <Divider />
      <div className={`currency-card__footer ${(!isDepositable(coin) && !isStakeable(coin)) ? "card__footer_non_depositable": ""}`} style={isFuture ? {marginTop: "0.2rem"}: {marginTop: "0.4rem"}}>
        {!isFuture && isDepositable(coin) && <Button
              style={{ color: "#3BADFF", textTransform: "none" }}
              onClick={(e) => {
                e.stopPropagation();
                setModalOpen(true);
              }}
            >
              Deposit
            </Button>}
        {(!isFuture) && <Button
              style={{ color: "#3BADFF", textTransform: "none" }}
              onClick={(e) => {
                e.stopPropagation();
                dispatch({
                  type: CHANGE_SELECTED_WITHDRAWAL_COIN,
                  payload: {selectedWithdrawCoin: {...currency, isFuture}, selectedBalanceCoin: {...currency, isFuture}}
                });
              }}
            >
              Withdraw
            </Button>}
        <Button
          style={{ color: "#3BADFF", textTransform: "none" }}
          onClick={(e) => {
            e.stopPropagation();
            dispatch({
              type: CHANGE_TO_TRANSFERS_TAB,
              payload: {selectedTransferCoin: {...currency, isFuture}, selectedBalanceCoin: {...currency, isFuture}}
            });
          }}
        >
          Transfer
        </Button>
        {isStakeable(coin) && <Button
          style={{ color: "#3BADFF", textTransform: "none" }}
          onClick={(e) => {
            e.stopPropagation();
            dispatch({
              type: CHANGE_TO_STAKING_TAB,
              payload: {selectedStakingCoin: {...currency, isFuture}, selectedBalanceCoin: {...currency, isFuture}}
            });
          }}
        >
          Send to Stake
        </Button>}
      </div>
      {modalOpen && (
        <DepositModal
          handleClose={() => setModalOpen(false)}
          currency={currency}
          open={modalOpen}
        />
      )}
    </Card>
  );
};

export default CurrencyCard
