import {isAuthorizationHeaderPresent} from "./authentication";
import axios from "./axiosFabric";
import {
    ADMINISTRATION_GET_ACTIVE_SUSPENDED_ACTIVITIES,
    ADMINISTRATION_GET_ALL_SUSPENDED_ACTIVITIES} from "./actionTypes";

export const getAllSuspendedActivities = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/administration/getallsuspendedactivities`, props)
        .then(res => {
            dispatch({
                type: ADMINISTRATION_GET_ALL_SUSPENDED_ACTIVITIES,
                payload: res.data
            })
        }).catch(error => {
            // console.log("error", error)
    })
}

export const getActiveSuspendedActivities = (cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/administration/getactivesuspendedactivities`)
        .then(res => {
            if (cb) cb(res.data)
            dispatch({
                type: ADMINISTRATION_GET_ACTIVE_SUSPENDED_ACTIVITIES,
                payload: res.data
            })
        }).catch(error => {
        // console.log("error", error)
    })
}
