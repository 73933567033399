import {
    CLEAR_TRADES_STATE,
    GET_HISTORY,
    GET_TRADE_UPDATE,
    OPEN_BTD,
    OPEN_TRADE,
    OPEN_ORDER,
    OPEN_TRADE_FROM_BTD,
    OPEN_TRADE_FROM_SUC,
    UPDATE_TRADE_CANCEL,
    UPDATE_TRADE_CONDITIONS,
    UPDATE_TRADE_CONNECTION_TO_SIGNAL,
    UPDATE_TRADE_DISCARD,
    UPDATE_TRADE_EXECUTE_TAKE_PROFIT,
    UPDATE_ORDER_DISCARD,
    SET_CURRENT_TAB_WAITING_TRADES,
    SET_CURRENT_TAB_ORDERS,
    TRADE_SCROLL_INDEX,
    GET_ALL_ACTIVE_TRADES,
    GET_ALL_WAITING_TRADES,
    GET_ALL_CLOSED_TRADES,
    GET_ALL_ORDERS, TRADES_RE_SORT_ON_VISIBLE_TRADES, TRADE_SCROLL_IS_ON, TRADE_SCROLL_IS_OFF,
} from "../actions/actionTypes"

import {
    brushValue, getBuyAndSellPrice,
    getCurrentBuyAndSellPrice,
    getCurrentPrice,
    getCurrentPriceAndDeltaPercent, getExecutedQtyAndCummulativeQuoteQty,
    getHRStateForTrade,
    getMaxMinAndDeltaPercent,
    getPosSize,
    getProgress, getProgressForWaitingTrade,
    getQuoteAndBase,
    getTradeConditionsContentForActiveTrade,
    getTradeProfitOrLoss,
    getTradeState
} from "../../components/fncomponents/UISupportFns"
import c from "../../constants"
import Cookies from 'universal-cookie'
const cookies = new Cookies()

function getNewWaitingTradesListAndMap(state, newTrade) {
    let newWaitingTrades = []
    let newWaitingTradesMap = {}

    let idx = -1
    if (state.waitingTrades) {
        idx = parseInt(state.waitingTradesMap[newTrade.order_id])
    }

    if (idx >= 0) {
        //The trade is already present in the Waiting Trades List and Map
        //(so nothing to be done on newWaitingTradesMap, just a clone
        newWaitingTradesMap = {...state.waitingTradesMap, ...{}}
        //Check if it's the case to update or remove the trade
        if (newTrade.child_order && newTrade.child_order.order_id) {
            //Remove it as trade has transitioned to a an Active Trade
            newWaitingTrades = [...state.waitingTrades, ...[]]
            newWaitingTrades.splice(idx, 1)
            delete newWaitingTradesMap[newTrade.order_id]
        } else {
            //Update it
            newWaitingTrades = state.waitingTrades.map((trade, index) => {
                if (index !== idx)
                    return trade
                else{
                    return computeFieldsForWaitingTrade({
                        ...trade,
                        ...newTrade
                    }, false)

                }
            })
        }
    } else {
        //It's a new trade
        //Check if it's a Waiting Trade
        if (newTrade.record_type !== "ORDER" && (!newTrade.child_order || (newTrade.child_order && !newTrade.child_order.order_id)) ) {
            //Yes it's a Waiting Trade, we'll add it to the state, at the end of the list
            if (state.waitingTrades) {
                newWaitingTrades = state.waitingTrades.slice(0)
                newWaitingTradesMap = {...state.waitingTradesMap, ...{}}
            }
            newWaitingTrades.push(computeFieldsForWaitingTrade(newTrade))
            newWaitingTradesMap[newTrade.order_id] = newWaitingTrades.length - 1
        } else {
            //Let everything untouched
            newWaitingTradesMap = state.waitingTradesMap
            newWaitingTrades = state.waitingTrades
        }
    }

    return {newWaitingTrades: newWaitingTrades, newWaitingTradesMap: newWaitingTradesMap}
}

function getNewActiveAndClosedTradesListAndMap(state, newTrade) {
    let newActiveTrades = []
    let newActiveTradesMap = {}
    let newClosedTrades = []
    let newClosedTradesMap = {}

    let idxActive = -1
    if (state.activeTrades) {
        idxActive = parseInt(state.activeTradesMap[newTrade.order_id])
    }
    let idxClosed = -1
    if (state.closedTrades) {
        idxClosed = parseInt(state.closedTradesMap[newTrade.order_id])
    }

    if (idxActive >= 0) {
        //The trade is already present in the Active Trades List and Map
        //(so nothing to be done on newActiveTradesMap, just a clone
        newActiveTradesMap = {...state.activeTradesMap, ...{}}
        //Update the trade
        newActiveTrades = state.activeTrades.map((trade, index) => {
            if (index !== idxActive) {
                return {...trade, ...{idx: index}}
            } else {
                let updatedTrade = {...trade, ...newTrade}
                updatedTrade.child_order = {...trade.child_order, ...newTrade.child_order}
                return {...computeFieldsForActiveTrade(updatedTrade, false), ...{idx: index}}
            }
        })
    } else if (idxClosed >= 0) {
        if (newTrade.child_order && newTrade.child_order.status === "ERROR") {
            // console.log("(error) 1 getNewActiveAndClosedTradesListAndMap idxActive %s, idxClosed %s, newTrade ", idxActive, idxClosed, JSON.stringify(newTrade))
        }

        //Let Active Trades Untouched
        newActiveTrades = state.activeTrades
        newActiveTradesMap = state.activeTradesMap
        //The trade is already present in the Closed Trades List and Map
        //(so nothing to be done on newActiveTradesMap, just a clone
        newClosedTradesMap = {...state.closedTradesMap, ...{}}
        //Update the trade
        newClosedTrades = state.closedTrades.map((trade, index) => {
            if (index !== idxClosed) {
                return trade
            } else {
                let updatedTrade = {...trade, ...newTrade}
                let updatedChild = {...trade.child_order, ...newTrade.child_order}
                updatedTrade.child_order = updatedChild
                return computeFieldsForClosedTrade(updatedTrade)
            }

        })
    } else {
        if (newTrade.child_order && newTrade.child_order.status === "ERROR") {
            // console.log("(error) 2 getNewActiveAndClosedTradesListAndMap idxActive %s, idxClosed %s, newTrade ", idxActive, idxClosed, JSON.stringify(newTrade))
        }
        //It's a new trade
        //Check if it's an Active Trade or Closed
        if (newTrade.child_order && newTrade.child_order.order_id && newTrade.record_type !== "ORDER") {
            if (newTrade.child_order.status === "NEW" ||
                newTrade.child_order.status === "PARTIALLY_FILLED") {
                //Yes it'S an Active Trade, we'll add it to the state, at the end of the list
                if (state.activeTrades) {
                    newActiveTrades = state.activeTrades.slice(0)
                    newActiveTradesMap = {...state.activeTradesMap, ...{}}
                }
                newActiveTrades.push(computeFieldsForActiveTrade(newTrade))
                newActiveTradesMap[newTrade.order_id] = newActiveTrades.length - 1
            } else {
                //Let Active Trades Untouched
                newActiveTrades = state.activeTrades
                newActiveTradesMap = state.activeTradesMap

                //Yes it's a Closed Trade, we'll add it to the state, at the end of the list
                if (state.closedTrades) {
                    newClosedTrades = state.closedTrades.slice(0)
                    newClosedTradesMap = {...state.closedTradesMap, ...{}}
                }
                newClosedTrades.push(computeFieldsForClosedTrade(newTrade))
                newClosedTradesMap[newTrade.order_id] = newClosedTrades.length - 1
            }
        } else {
            //Let everything untouched
            newActiveTrades = state.activeTrades
            newActiveTradesMap = state.activeTradesMap
            newClosedTrades = state.closedTrades
            newClosedTradesMap = state.closedTradesMap
        }
    }

    return {newActiveTrades, newActiveTradesMap, newClosedTrades, newClosedTradesMap}
}

function getNewOrdersListAndMap(state, newOrder) {
    let newOrders = []
    let newOrdersMap = {}

    let idx = -1
    if (state.ordersMap) {
        idx = parseInt(state.ordersMap[newOrder.order_id])
    }

    if (idx >= 0) {
        //The trade is already present in the Active Trades List and Map
        //(so nothing to be done on newOrdersMap, just a clone
        newOrdersMap = {...state.ordersMap, ...{}}
        //Update the trade
        newOrders = state.orders.map((order, index) => {
            if (index !== idx) {
                return order
            } else {
                return {...order, ...newOrder}
            }

        })
    } else if (newOrder.record_type === "ORDER") {
        //It's a new order
        if (state.orders) {
            newOrders = state.orders.slice(0)
            newOrdersMap = {...state.ordersMap, ...{}}
        }
        newOrders.push(newOrder)
        newOrdersMap[newOrder.order_id] = newOrders.length - 1

    } else {
        //Let everything untouched
        newOrders = state.orders
        newOrdersMap = state.ordersMap
    }

    return {newOrders, newOrdersMap}
}

function getNewOpenedTrade(state, newTrade) {
    // console.log("getNewOpenedTrade")
    if (state.openedTrade && state.openedTrade.order_id == newTrade.order_id) {
        // console.log("getNewOpenedTrade found openedTrade with order_id " + newTrade.order_id)
        if (newTrade.child_order && newTrade.child_order.order_id != null) {
            // console.log("getNewOpenedTrade found openedTrade with order_id " + newTrade.order_id + ", with child")
            return {
                ...state.openedTrade,
                ...newTrade,
                child_order: {
                    ...state.openedTrade.child_order, ...newTrade.child_order
                }
            }
        } else {
            // console.log("getNewOpenedTrade found openedTrade with order_id " + newTrade.order_id + ", without child")
            return {
                ...state.openedTrade,
                ...newTrade
            }
        }
    } else {
        return state.openedTrade
    }
}

function getNewOpenedBTD(state, newBTD) {
    if (state.openedBTD && state.openedBTD.order_id == newBTD.order_id)
        return {
            ...state.openedTrade,
            ...newBTD
        }
    else
        return state.openedBTD
}

function discardTradeFromWaitingOrActiveLists(state, trade) {
    discardTradeFromWaitingTradesIfFound(state, trade)
    discardTradeFromActiveTradesIfFound(state, trade)
}

function discardTradeFromWaitingTradesIfFound(state, trade) {
    if (!state.waitingTradesMap)
        return
    let idx = state.waitingTradesMap[trade.order_id]
    if (idx >= 0) {
        //it's a waiting trade
        state.waitingTrades.splice(idx, 1)
        delete state.waitingTradesMap[trade.order_id]
    }
}

function discardTradeFromActiveTradesIfFound(state, trade) {
    if (!state.activeTradesMap)
        return
    let idx = state.activeTradesMap[trade.order_id]
    if (idx >= 0) {
        //it's a waiting trade
        state.activeTrades.splice(idx, 1)
        delete state.activeTradesMap[trade.order_id]
    }
}

function discardOrderFromOrdersIfFound(state, trade) {
    if (!state.ordersMap)
        return
    let idx = state.ordersMap[trade.order_id]
    if (idx >= 0) {
        //it's a waiting trade
        state.orders.splice(idx, 1)
        delete state.ordersMap[trade.order_id]
    }
}

function computeFieldsForWaitingTrade(trade, newRecord = true) {
    if (newRecord) {
        const {quote, base} = getQuoteAndBase(trade)
        trade.quote = quote
        trade.base = base
        trade.posSize  = getPosSize(trade)
        const {buyPrice, sellPrice} = getBuyAndSellPrice(trade)
        trade.buyPrice = buyPrice
        trade.sellPrice = sellPrice
        trade.origQty = brushValue(trade.orig_qty)
    }
    trade.currentPrice = getCurrentPrice(trade)
    trade.progress = getProgressForWaitingTrade(trade)
    let q = getCurrentPriceAndDeltaPercent(trade)
    //console.log("q ", JSON.stringify(q))
    //console.log("trade ", JSON.stringify(trade))
    const {trend, currentPriceDeltaPercent, currentPriceClass} = q
    trade.trend = trend
    trade.currentPriceDeltaPercent = currentPriceDeltaPercent
    trade.currentPriceClass = currentPriceClass
    trade.tradeState = getTradeState(trade)
    const {maxPrice, maxDelta, minPrice, minDelta} = getMaxMinAndDeltaPercent(trade)
    trade.maxPrice = maxPrice
    trade.maxDelta = maxDelta
    trade.minPrice = minPrice
    trade.minDelta = minDelta

    return trade
}

function computeFieldsForActiveTrade(trade, newRecord = true) {
    if (newRecord) {
        const {quote, base} = getQuoteAndBase(trade)
        trade.quote = quote
        trade.base = base
        trade.posSize  = getPosSize(trade)
        trade.cummulativeQuoteQty = brushValue(trade.cummulative_quote_qty, c.BASE_PRECISIONS[base])
        const {buyPrice, sellPrice} = getBuyAndSellPrice(trade)
        trade.buyPrice = buyPrice
        trade.sellPrice = sellPrice
    }
    trade.currentPrice = getCurrentPrice(trade)
    trade.executedQty = brushValue(trade.executed_qty)
    trade.progress = getProgress(trade)
    const {trend, currentPriceDeltaPercent, currentPriceClass} = getCurrentPriceAndDeltaPercent(trade)
    trade.trend = trend
    trade.currentPriceDeltaPercent = currentPriceDeltaPercent
    trade.currentPriceClass = currentPriceClass
    trade.tradeState = getTradeState(trade)
    if (trade.tradeState === c.TRADE_STATE_WITH_CHILD_FILLED || trade.tradeState === c.TRADE_STATE_WITH_CHILD_CANCELED) {
        trade.tradeProfitOrLoss = getTradeProfitOrLoss(trade, trade.tradeState)
    }
    const {tpContent, slContent} = getTradeConditionsContentForActiveTrade(trade)
    trade.tpContent = tpContent
    trade.slContent = slContent
    const {maxPrice, maxDelta, minPrice, minDelta} = getMaxMinAndDeltaPercent(trade)
    trade.maxPrice = maxPrice
    trade.maxDelta = maxDelta
    trade.minPrice = minPrice
    trade.minDelta = minDelta
    const {hrStateText, hrStateSymbol, hrStateSymbolColor, blinking} = getHRStateForTrade(trade)
    trade.hrStateText = hrStateText
    trade.hrStateSymbol = hrStateSymbol
    trade.hrStateSymbolColor = hrStateSymbolColor
    trade.blinking = blinking
    return trade
}

function computeFieldsForClosedTrade(trade) {
    const {quote, base} = getQuoteAndBase(trade)
    trade.quote = quote
    trade.base = base
    trade.posSize  = getPosSize(trade)
    trade.cummulativeQuoteQty = brushValue(trade.cummulative_quote_qty, c.BASE_PRECISIONS[base])
    trade.executedQty = brushValue(trade.executed_qty)
    const {currentPrice, buyPrice, sellPrice} = getCurrentBuyAndSellPrice(trade)
    trade.currentPrice = currentPrice
    trade.buyPrice = buyPrice
    trade.sellPrice = sellPrice
    trade.progress = getProgress(trade)
    const {trend, currentPriceDeltaPercent, currentPriceClass} = getCurrentPriceAndDeltaPercent(trade)
    trade.trend = trend
    trade.currentPriceDeltaPercent = currentPriceDeltaPercent
    trade.currentPriceClass = currentPriceClass
    const {tpContent, slContent} = getTradeConditionsContentForActiveTrade(trade)
    trade.tpContent = tpContent
    trade.slContent = slContent
    trade.tradeState = getTradeState(trade)
    trade.tradeProfitOrLoss = getTradeProfitOrLoss(trade, trade.tradeState)
    const {maxPrice, maxDelta, minPrice, minDelta} = getMaxMinAndDeltaPercent(trade)
    trade.maxPrice = maxPrice
    trade.maxDelta = maxDelta
    trade.minPrice = minPrice
    trade.minDelta = minDelta
    const {hrStateText, hrStateSymbol, hrStateSymbolColor, blinking} = getHRStateForTrade(trade)
    trade.hrStateText = hrStateText
    trade.hrStateSymbol = hrStateSymbol
    trade.hrStateSymbolColor = hrStateSymbolColor
    trade.blinking = blinking
    return trade
}

function computeFieldsForOrder(order) {
    let {executedQty, cummulativeQuoteQty} = getExecutedQtyAndCummulativeQuoteQty(order)
    order.executed_qty = executedQty
    order.cummulative_quote_qty = cummulativeQuoteQty
}

function reAttachVisibleTrades(newState) {
    switch (newState.selectedTradesTab) {
        case 0: newState.visibleTrades = newState.waitingTrades; break;
        case 1: newState.visibleTrades = newState.activeTrades; break;
        case 2: newState.visibleTrades = newState.closedTrades; break;
        case 3: newState.visibleTrades = newState.orders; break;
    }
}

function getOrder(a, b, sortOption, type) {
    if (sortOption === "newest") {
        let at, bt
        if (type === c.CLOSED_TRADES_TAB_POS) {
            at = parseInt(a.last_action_time)
            bt = parseInt(b.last_action_time)
        } else {
            at = parseInt(a.time)
            bt = parseInt(b.time)
        }
        return bt - at
    } else {
        let ap = a.currentPriceDeltaPercent
        let bp = b.currentPriceDeltaPercent
        let delta = ap - bp
        if (sortOption === "winners") {
            return -1 * delta
        } else if (sortOption === "losers") {
            return delta
        } else {
            return delta
        }
    }
}

let UPDATE_TRADE_CONDITIONS_LOCK = false

export default function (state = {selectedTradesTab: 1, scrollIndex: 0}, action) {
    switch (action.type) {

        case GET_ALL_WAITING_TRADES: {
            let newState = {...state, ...{ selectedTradesTab: action.payload.selectedTradesTab}}

            newState.waitingTrades = []
            newState.waitingTradesMap = {}

            if (!action.payload)
                return newState
            if (!action.payload.data)
                return newState
            if (!action.payload.data.trades)
                return newState

            //Computing fields for each trade
            Object.keys(action.payload.data.trades).forEach(function(key) {
                let record  = action.payload.data.trades[key]
                record.order_id = key
                if (record.buy_the_dip_id) {
                    newState.waitingTrades.push(record)
                } else {
                    newState.waitingTrades.push(computeFieldsForWaitingTrade(record))
                }
            })

            //Sorting by creation date
            if (newState.waitingTrades.length > 1) {
                newState.waitingTrades = newState.waitingTrades.sort((a,b) => {
                    let at = parseInt(a.time)
                    let bt = parseInt(b.time)
                    return bt - at
                })
            }

            //Creating a map order_id - position
            for(let i in newState.waitingTrades) {
                let trade = newState.waitingTrades[i]
                //Used for finding easy the trade for a future update
                newState.waitingTradesMap[trade.order_id] = i
            }

            newState.visibleTrades = newState.waitingTrades
            newState.totalWaitingNo = action.payload.data.totalWaitingNo
            newState.totalActiveNo = action.payload.data.totalActiveNo
            newState.totalClosedNo = action.payload.data.totalClosedNo
            newState.totalOrdersNo = action.payload.data.totalOrdersNo

            newState.selectedTradesTab = action.payload.selectedTradesTab

            return newState
        }

        case GET_ALL_ACTIVE_TRADES: {
            let newState = {...state, ...{ selectedTradesTab: action.payload.selectedTradesTab}}

            newState.activeTrades = []
            newState.activeTradesMap = {}

            if (!action.payload)
                return newState
            if (!action.payload.data)
                return newState
            if (!action.payload.data.trades)
                return newState

            //Computing fields for each trade
            Object.keys(action.payload.data.trades).forEach(function(key) {
                let record  = action.payload.data.trades[key]
                record.order_id = key
                newState.activeTrades.push(computeFieldsForActiveTrade(record))
            })
            let sortOption = cookies.get(`tab_${c.ACTIVE_TRADES_TAB_POS}_sort`)
            //Sorting by creation date
            if (newState.activeTrades.length > 1) {
                newState.activeTrades = newState.activeTrades.sort((a,b) => {
                    return getOrder(a,b, sortOption, c.ACTIVE_TRADES_TAB_POS)
                })
            }

            //Creating a map order_id - position
            for(let i in newState.activeTrades) {
                let trade = newState.activeTrades[i]
                //Used for finding easy the trade for a future update
                newState.activeTradesMap[trade.order_id] = i
            }

            newState.visibleTrades = newState.activeTrades
            newState.totalWaitingNo = action.payload.data.totalWaitingNo
            newState.totalActiveNo = action.payload.data.totalActiveNo
            newState.totalClosedNo = action.payload.data.totalClosedNo
            newState.totalOrdersNo = action.payload.data.totalOrdersNo

            newState.selectedTradesTab = action.payload.selectedTradesTab

            return newState
        }

        case GET_ALL_CLOSED_TRADES: {
            let newState = {...state, ...{ selectedTradesTab: action.payload.selectedTradesTab}}

            newState.closedTrades = []
            newState.closedTradesMap = {}

            if (!action.payload)
                return newState
            if (!action.payload.data)
                return newState
            if (!action.payload.data.trades)
                return newState

            //Computing fields for each trade
            Object.keys(action.payload.data.trades).forEach(function(key) {
                let record  = action.payload.data.trades[key]
                record.order_id = key
                newState.closedTrades.push(computeFieldsForClosedTrade(record))
            })

            let sortOption = cookies.get(`tab_${c.CLOSED_TRADES_TAB_POS}_sort`)
            //Sorting by creation date
            if (newState.closedTrades.length > 1) {
                newState.closedTrades = newState.closedTrades.sort((a,b) => {
                    return getOrder(a, b, sortOption, c.CLOSED_TRADES_TAB_POS)
                })
            }

            //Creating a map order_id - position
            for(let i in newState.closedTrades) {
                let trade = newState.closedTrades[i]
                //Used for finding easy the trade for a future update
                newState.closedTradesMap[trade.order_id] = i
            }

            newState.visibleTrades = newState.closedTrades
            newState.totalWaitingNo = action.payload.data.totalWaitingNo
            newState.totalActiveNo = action.payload.data.totalActiveNo
            newState.totalClosedNo = action.payload.data.totalClosedNo
            newState.totalOrdersNo = action.payload.data.totalOrdersNo

            newState.selectedTradesTab = action.payload.selectedTradesTab

            return newState
        }

        case GET_ALL_ORDERS: {
            let newState = {...state, ...{ selectedTradesTab: action.payload.selectedTradesTab}}

            newState.orders = []
            newState.ordersMap = {}

            if (!action.payload)
                return newState
            if (!action.payload.data)
                return newState
            if (!action.payload.data.trades)
                return newState

            //Computing fields for each trade
            Object.keys(action.payload.data.trades).forEach(function(key) {
                let record  = action.payload.data.trades[key]
                record.order_id = key
                computeFieldsForOrder(record)
                newState.orders.push(record)
            })

            //Sorting by creation date
            if (newState.orders.length > 1) {
                newState.orders = newState.orders.sort((a,b) => {
                    let at = parseInt(a.time)
                    let bt = parseInt(b.time)
                    return bt - at
                })
            }

            //Creating a map order_id - position
            for(let i in newState.orders) {
                let trade = newState.orders[i]
                //Used for finding easy the trade for a future update
                newState.ordersMap[trade.order_id] = i
            }

            newState.visibleTrades = newState.orders
            newState.totalWaitingNo = action.payload.data.totalWaitingNo
            newState.totalActiveNo = action.payload.data.totalActiveNo
            newState.totalClosedNo = action.payload.data.totalClosedNo
            newState.totalOrdersNo = action.payload.data.totalOrdersNo

            newState.selectedTradesTab = action.payload.selectedTradesTab

            return newState
        }

        case GET_TRADE_UPDATE: {
            if (UPDATE_TRADE_CONDITIONS_LOCK)
                return state

            if (!state.visibleTrades) {
                //Means that we have no visible trades
                return state
            }

            let newTradeOrOrder = action.payload
            let objectType = -1

            if (newTradeOrOrder.record_type === "ORDER") {
                objectType = 3 //Orders
            } else if (!newTradeOrOrder.child_order) {
                objectType = 1 //Waiting trade
            } else if (newTradeOrOrder.child_order && newTradeOrOrder.child_order.order_id) {
                objectType = 2 //Active or closed trade
            }

            if (objectType === 1) {
                let {newWaitingTrades, newWaitingTradesMap} = getNewWaitingTradesListAndMap(state, newTradeOrOrder)
                let newState = {
                    ...state,
                    ...{waitingTrades: newWaitingTrades,
                        waitingTradesMap: newWaitingTradesMap,
                        openedTrade: getNewOpenedTrade(state, newTradeOrOrder)}
                }
                reAttachVisibleTrades(newState)
                return newState
            } else if (objectType === 2) {
                //Testing the case when a Waiting Trade has just become an Active Trade and we have to remove it
                //from the Waiting Trades list.
                let presentStillInWaitingTradesList = -1
                if (state.waitingTradesMap) {
                    presentStillInWaitingTradesList = parseInt(state.waitingTradesMap[newTradeOrOrder.order_id])
                }
                // console.log("presentStillInWaitingTradesList ", presentStillInWaitingTradesList)
                let newWaitingTrades, newWaitingTradesMap
                if (presentStillInWaitingTradesList >= 0) {
                    // console.log("processing waitTradesList even though we are in the active trades list")
                    let q = getNewWaitingTradesListAndMap(state, newTradeOrOrder)
                    newWaitingTrades = q.newWaitingTrades
                    newWaitingTradesMap = q.newWaitingTradesMap
                }
                let {newActiveTrades, newActiveTradesMap} = getNewActiveAndClosedTradesListAndMap(state, newTradeOrOrder)
                if (presentStillInWaitingTradesList) {
                    let newState = {
                        ...state,
                        ...{waitingTrades: newWaitingTrades,
                            waitingTradesMap: newWaitingTradesMap,
                            activeTrades: newActiveTrades,
                            activeTradesMap: newActiveTradesMap,
                            openedTrade: getNewOpenedTrade(state, newTradeOrOrder)
                        }
                    }
                    reAttachVisibleTrades(newState)
                    return newState
                } else {
                    let newState = {
                        ...state,
                        ...{activeTrades: newActiveTrades,
                            activeTradesMap: newActiveTradesMap,
                            openedTrade: getNewOpenedTrade(state, newTradeOrOrder)
                        }
                    }
                    reAttachVisibleTrades(newState)
                    return newState
                }

            } else if (objectType === 3) {
                let {newOrders, newOrdersMap} = getNewOrdersListAndMap(state, newTradeOrOrder)
                let newState = {
                    ...state,
                    ...{orders: newOrders, ordersMap: newOrdersMap,
                        openedTrade: getNewOpenedTrade(state, newTradeOrOrder)
                    }
                }
                reAttachVisibleTrades(newState)
                return newState
            }
            return state
        } break;

        case TRADES_RE_SORT_ON_VISIBLE_TRADES: {
            let newState = {...state, ...{}}
            let sortOption = cookies.get(`tab_${newState.selectedTradesTab}_sort`)
            if (newState.selectedTradesTab === c.ACTIVE_TRADES_TAB_POS) {
                //sorting Active Trades
                newState.activeTrades = newState.activeTrades.sort((a,b) => {
                    return getOrder(a, b, sortOption, c.ACTIVE_TRADES_TAB_POS)
                })
                //Creating a map order_id - position
                for(let i in newState.activeTrades) {
                    let trade = newState.activeTrades[i]
                    //Used for finding easy the trade for a future update
                    newState.activeTradesMap[trade.order_id] = i
                }
                newState.visibleTrades = newState.activeTrades
            } else if (newState.selectedTradesTab === c.CLOSED_TRADES_TAB_POS) {
                //sorting Closed Trades
                newState.closedTrades = newState.closedTrades.sort((a,b) => {
                    return getOrder(a, b, sortOption, c.CLOSED_TRADES_TAB_POS)
                })
                //Creating a map order_id - position
                for(let i in newState.closedTrades) {
                    let trade = newState.closedTrades[i]
                    //Used for finding easy the trade for a future update
                    newState.closedTradesMap[trade.order_id] = i
                }
                newState.visibleTrades = newState.closedTrades
            }

            return newState;
        } break;

        case OPEN_TRADE: {
            return {...state,
                ...{
                    openedTrade: action.payload
                }
            }
        }

        case OPEN_ORDER: {
            return {...state,
                ...{
                    openedOrder: action.payload
                }
            }
        }

        case OPEN_TRADE_FROM_BTD: {
            let openedTrade = undefined
            let order_id = action.payload.order_id
            if (state.waitingTradesMap[order_id])
                openedTrade = state.waitingTrades[state.waitingTradesMap[order_id]]
            else if (state.activeTradesMap[order_id])
                openedTrade = state.activeTrades[state.activeTradesMap[order_id]]

            return {...state,
                ...{
                    openedTrade: openedTrade
                }
            }
        }

        case OPEN_BTD: {
            return {...state,
                ...{
                    openedBTD: action.payload
                }
            }
        }

        case OPEN_TRADE_FROM_SUC: {
            let openedTrade = undefined
            let order_id = action.payload.order_id
            if (state.waitingTradesMap[order_id])
                openedTrade = state.waitingTrades[state.waitingTradesMap[order_id]]
            else if (state.activeTradesMap[order_id])
                openedTrade = state.activeTrades[state.activeTradesMap[order_id]]

            return {...state,
                ...{
                    openedTrade: openedTrade
                }
            }
        }

        case UPDATE_TRADE_CONDITIONS: {
            UPDATE_TRADE_CONDITIONS_LOCK = true
            let newTrade = action.payload

            if (newTrade.unchanged) {
                //trade conditions unchanged; doing nothing...
                return state
            }
            let {newWaitingTrades, newWaitingTradesMap} = getNewWaitingTradesListAndMap(state, newTrade)
            let {newActiveTrades, newActiveTradesMap} = getNewActiveAndClosedTradesListAndMap(state, newTrade)
            let newState = {
                ...state,
                ...{waitingTrades: newWaitingTrades,
                    waitingTradesMap: newWaitingTradesMap,
                    activeTrades: newActiveTrades,
                    activeTradesMap: newActiveTradesMap}
            }
            if (newTrade.buy_the_dip_id)
                newState.openedBTD = getNewOpenedBTD(state, newTrade)
            else
                newState.openedTrade = getNewOpenedTrade(state, newTrade)
            UPDATE_TRADE_CONDITIONS_LOCK = false
            return newState
        }

        case UPDATE_TRADE_CONNECTION_TO_SIGNAL: {
            return {...state,
                ...{
                    openedTrade: action.payload
                }
            }
        }

        case UPDATE_TRADE_EXECUTE_TAKE_PROFIT: {
            return {...state, ...{random: Math.random() * 10000}}
        }

        case UPDATE_TRADE_CANCEL: {

            let entity = ""
            if (state.openedTrade && action.payload.order_id === state.openedTrade.order_id)
                entity = "openedTrade"
            else if (state.openedBTD && action.payload.order_id === state.openedBTD.order_id)
                entity = "openedBTD"
            return {...state,
                ...{[entity]: {
                        ...state[entity],
                        ...{status: "CANCELED"}
                    }
                }
            }
        }

        case UPDATE_TRADE_DISCARD: {
            let newState = {...state, ...{random: Math.random() * 10000}}
            let discardedTrade = action.payload
            if (newState.openedTrade && newState.openedTrade.order_id == discardedTrade.order_id) {
                newState.openedTrade = undefined
            }
            if (newState.openedBTD && newState.openedBTD.order_id == discardedTrade.order_id) {
                newState.openedBTD = undefined
            }
            discardTradeFromWaitingOrActiveLists(newState, discardedTrade)
            return newState
        }

        case UPDATE_ORDER_DISCARD: {
            let newState = {...state, ...{random: Math.random() * 10000}}
            let discardedOrder = action.payload
            newState.openedOrder = undefined
            discardOrderFromOrdersIfFound(newState, discardedOrder)
            return newState
        }

        case GET_HISTORY: {
            return {
                ...state, ...{
                    history: action.payload.history,
                    stats: action.payload.stats,
                    historyFilter: action.payload.historyFilter,
                    historyFilterSelectedYear: action.payload.historyFilterSelectedYear,
                    historyFilterSelectedMonth: action.payload.historyFilterSelectedMonth,
                    selectedTradesTab: 4
                }
            }
        }

        case CLEAR_TRADES_STATE: {
            return {selectedTradesTab: 1}
        }

        case SET_CURRENT_TAB_WAITING_TRADES: {
            return {selectedTradesTab: 0}
        }

        case SET_CURRENT_TAB_ORDERS: {
            return {selectedTradesTab: 3}
        }

        case TRADE_SCROLL_INDEX: {
            return {...state,
                ...{scrollIndex: action.payload}
            }
        }

        default:
            return state
    }
}
