import React from 'react'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import Rating from '@material-ui/lab/Rating'
import Avatar from '@material-ui/core/Avatar'


const StyledRating = withStyles({
    iconFilled: {
        color: '#D79600',
    }
})(Rating);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(13),
        height: theme.spacing(13),
    },
}));


export default function TraderBox(props) {
    const classes = useStyles();
    // function getAvgProfitPerSignal(sigKPIs) {
    //     if (sigKPIs.avgProfit >= 0) {
    //         return <span style={{color: "#64fe7c"}}>+{sigKPIs.avgProfit.toFixed(2)}%</span>
    //     } else
    //         return <span style={{color: "#FE484F"}}>{sigKPIs.avgProfit.toFixed(2)}%</span>
    // }

    function getLastXDaysProfits(sigKPIs) {
        if (sigKPIs.lastXDaysProfits && sigKPIs.lastXDaysProfits.length > 0) {
            let sum = 0
            for(let i = 0; i < sigKPIs.lastXDaysProfits.length; i++) {
                sum += sigKPIs.lastXDaysProfits[i].value
            }
            if (sum > 0) {
                return <span style={{color: "#64fe7c"}}>+{sum.toFixed(2)}%</span>
            } else {
                return <span style={{color: "#FE484F"}}>{sum.toFixed(2)}%</span>
            }

        } else
            return <span style={{color: "#FE484F"}}>0%</span>
    }
    const {trader: _t, open, isSuperAdmin} = props
    if (!_t)
        return null

    function showKPIs(kpi, coin, type) {
        if (kpi && (kpi.winingNo + kpi.losingNo) > 0)
            return (<div style={{marginTop: "0.4rem"}}>
                <div style={{display: "flex"}}>
                    <div style={{fontSize: "0.8rem", marginTop: "0.2rem"}}>
                        {kpi.winingNo + kpi.losingNo} {type} {coin} signals (<span style={{color: "#64fe7c"}} title={"No. of wining signals"}>{kpi.winingNo}↑</span>/ <span style={{color: "#FE484F"}} title={"No. losing signals."}>{kpi.losingNo}↓</span>)
                    </div>
                </div>
                <div style={{display: "flex"}}>
                    <div style={{fontSize: "0.8rem"}} title={"Last 30 days profits"}>
                        profits <span style={{color: "#64fe7c"}}>{getLastXDaysProfits(kpi)}</span>
                    </div>
                </div>
            </div>)
    }

    return (
        <div className={"traderBox2"} onClick={()=>{open && open(_t._id)}}>
            <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                <div id={"traderDetails"} style={{width: "16rem"}}>
                    <div style={{padding: "1rem", width: "100%"}}>
                        <div style={{fontSize: "1.1rem", fontWeight: "bold", cursor: "pointer"}}>{_t.name}</div>
                        {_t.ratings && <div style={{display: "flex"}}>
                            <div style={{marginTop: "0.6rem", marginLeft: "-0.2rem"}}>
                                <StyledRating name="read-only" value={_t.ratings.value} readOnly precision={0.5} max={_t.ratings.max}/>
                            </div>
                            <div style={{fontSize: "0.8rem", marginLeft: "0.6rem", marginTop: "0.78rem"}}>{_t.ratings.votes} Ratings</div>
                        </div>}
                        {_t.followers >= 0 && <div style={{display: "flex"}}>
                            <div style={{fontSize: "0.8rem"}}>
                                {_t.followers}
                            </div>
                            <div style={{fontSize: "0.8rem", marginLeft: "0.4rem"}}>Followers</div>
                        </div>}

                        {showKPIs(_t.btcSignalsKPI, "BTC")}
                        {showKPIs(_t.ethSignalsKPI, "ETH")}
                        {showKPIs(_t.usdtSignalsKPI, "USDT")}
                        {showKPIs(_t.usdtFuturesSignalsKPI, "USDT", "Futures")}
                    </div>
                </div>
                <div id={"traderLogo"}>
                    {_t.logo &&  <div style={{marginTop: "0.6rem", marginRight: "0.6rem"}} onClick={()=>{open && open(_t._id)}}>
                        <Avatar alt="Trader's logo" src={_t.logo.data} className={classes.large} />
                    </div>}
                    {!_t.logo && <div className={"traderBox2NoAvatar"} style={{marginTop: "0.6rem", marginRight: "0.6rem"}} onClick={()=>{open && open(_t._id)}}>
                        <div style={{paddingTop: "2.4rem", paddingLeft: "0.9rem", width:"100%", height: "2rem", alignContent: "center"}}>no image</div>
                    </div>}
                </div>
            </div>
            <div className={"vertSpacer1"}/>
        </div>
    )
}
