export function getTitle(pair, price) {
    let title = undefined;
    if (price && price !== "undefined") {
        title = `${price} | ${pair}`;
    }

    let name = extractDomain(window.location.hostname)
    name = name ? name.charAt(0).toUpperCase() + name.slice(1) : "localhost"

    if (title) {
        title += " | " + name;
    } else {
        title = name;
    }

    return title;
}


function extractDomain(url) {
    const regex = /^[^.]+\.(.+)\.[^.]+$/;
    const match = url.match(regex);

    if (match && match[1]) {
        return match[1];
    }

    return null;
}

