import React from 'react'
import Radio from '@material-ui/core/Radio';
import c from '../../constants'
import {FormControlLabel, RadioGroup, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import InputAdornment from "@material-ui/core/InputAdornment";


const useStyles = makeStyles(theme => ({

    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 270,
        backgroundColor: '#101A26'
    },
    button: {
        minWidth: 100,
        marginRight: 10,
        marginTop: 10,
        borderRadius: 100,
        height: 50
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    }
}))

const ACTION_TYPE_OPTION_MARKET = 1

export default function ExchangeControl(
    {
        actionType, handleChangeActionType, quote, base,
        actionTypeOption, handleChangeActionTypeOption,
        fixedPrice, fixedPriceError,
        handleChangeFixedPrice,
        posSize, handleChangePosSize,
        checkUserBalance,
        notEnoughBalance,
        otherPosSizeErrors,
        amount, handleChangeAmount, otherAmountErrors}) {
    const classes = useStyles()
    const [selectedActionTypeOption, setSelectedActionTypeOption] = React.useState(0)
    const [textFieldEnabled, setTextFieldEnabled] = React.useState(true)

    React.useEffect(()=> {
        if (quote.length < 2) {
            setSelectedActionTypeOption(-1)
            return
        }

        switch (actionTypeOption) {
            case c.ACTION_TYPE_OPTION_FIXED_PRICE: setSelectedActionTypeOption(0); break
            case c.ACTION_TYPE_OPTION_MARKET: setSelectedActionTypeOption(1); break
            case c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE: setSelectedActionTypeOption(2); break
            case c.ACTION_TYPE_OPTION_WAIT_FOR_A_DIP: setSelectedActionTypeOption(3); break
            case c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE: setSelectedActionTypeOption(4); break
            default : setSelectedActionTypeOption(-1); break
        }

        if (actionTypeOption === c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE ||
            actionTypeOption === c.ACTION_TYPE_OPTION_MARKET) {
            setTextFieldEnabled(false)
        }  else {
            setTextFieldEnabled(true)
        }
    })

    function getQuoteLabel() {
        if (quote && quote.length > 0) {
            let _quote = quote.toUpperCase()
            if (_quote.length > 5) {
                return `${_quote.substring(0, 4)}...`
            } else
                return _quote
        } else return "..."
    }

    function getPosSizeCurrency() {
        if (actionType === c.ACTION_TYPE_BUY) {
            return base.toUpperCase()
        } else if (actionType === c.ACTION_TYPE_SELL) {
            return getQuoteLabel()
        }
    }

    return (
        <div className={"wizardSectionContainer"}>
            <div style={{marginLeft: "0.6em"}}>
                <div id="buyOrSellRadioGroup" style={{marginLeft: "0.6rem"}}>
                    <RadioGroup row>
                        <FormControlLabel value={c.ACTION_TYPE_BUY} onChange={handleChangeActionType}
                                          control={<Radio color="primary" />} label={`Buy ${quote}`} checked={actionType === c.ACTION_TYPE_BUY}/>
                        <FormControlLabel value={c.ACTION_TYPE_SELL} onChange={handleChangeActionType}
                                          control={<Radio color="primary" />} label={`Sell ${quote}`} checked={actionType === c.ACTION_TYPE_SELL} />
                    </RadioGroup>
                </div>
                <div id="howToBuyOrSell_Limit_or_Market" style={{display: "flex", justifyContent: "flex-start", flexWrap: "wrap", marginBottom: "0.6em", marginLeft: "0.6em"}}>
                    <Button className={classes.button} variant={selectedActionTypeOption === 0 ? "contained": "outlined"} color={"primary"}
                            onClick={()=>{handleChangeActionTypeOption(c.ACTION_TYPE_OPTION_FIXED_PRICE)}}>Fixed price</Button>
                    <Button className={classes.button} variant={selectedActionTypeOption === 1 ? "contained": "outlined"} color={"primary"}
                            onClick={()=>{handleChangeActionTypeOption(c.ACTION_TYPE_OPTION_MARKET)}}>Market</Button>
                    {actionType === c.ACTION_TYPE_BUY && <Button className={classes.button} variant={selectedActionTypeOption === 2 ? "contained": "outlined"} color={"primary"}
                                                                 onClick={()=>{handleChangeActionTypeOption(c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE)}}>Just above bid</Button>}
                    {actionType === c.ACTION_TYPE_SELL && <Button className={classes.button} variant={selectedActionTypeOption === 4 ? "contained": "outlined"} color={"primary"}
                                                                  onClick={()=>{handleChangeActionTypeOption(c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE)}}>Just below ask</Button>}<br/>
                </div>
                <div id={"price"}>
                    {selectedActionTypeOption !== ACTION_TYPE_OPTION_MARKET && <TextField
                        id="outlined-adornment-weight_exchange_control"
                        className={classes.textField}
                        variant="outlined"
                        margin="dense"
                        disabled={!textFieldEnabled}
                        value={fixedPrice}
                        error={fixedPriceError !== undefined}
                        onChange={handleChangeFixedPrice}
                        label={"Price"}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    {selectedActionTypeOption === 3 && <span>&le;</span>}
                                </InputAdornment>
                            ),
                            endAdornment: <InputAdornment position="end">
                                <div style={{display: "flex"}}>
                                    {getPosSizeCurrency()}
                                </div>
                            </InputAdornment>,
                        }}
                        inputProps={{ maxLength: 16 }}
                    />}
                </div>
                <div id={"amount_container"}>
                    <TextField
                        id="amount"
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        label={"Amount"}
                        value={amount}
                        onChange={handleChangeAmount}
                        error={otherAmountErrors !== undefined}
                        helperText={otherAmountErrors && otherAmountErrors}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{quote}</InputAdornment>,
                        }}
                        inputProps={{ maxLength: 16 }}
                    />
                </div>
                <div id={"total_container"}>
                   <TextField
                        id="total"
                        className={classes.textField}
                        disabled={selectedActionTypeOption === ACTION_TYPE_OPTION_MARKET}
                        margin="dense"
                        variant="outlined"
                        label={"Total"}
                        value={posSize}
                        onChange={handleChangePosSize}
                        onBlur={checkUserBalance}
                        error={(notEnoughBalance === 0) || otherPosSizeErrors !== undefined}
                        helperText={otherPosSizeErrors && otherPosSizeErrors}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{base}</InputAdornment>,
                        }}
                        inputProps={{ maxLength: 16 }}
                    />
                </div>
            </div>
        </div>
    )
}
