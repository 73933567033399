import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import {connect} from "react-redux"
import {Clear, ArrowBack} from "@material-ui/icons"
import {
    brushValue, formatTimeDuration,
    getCurrentPriceAndDeltaPercent, getMaxMinAndDeltaPercent,
    getPosSize,
    getQuoteAndBase,
    getTradeConditionsContentForTradeDetails, getTradeEnterVerbFutureTense,
    timeFormatter,
} from "../../fncomponents/UISupportFns";
import {Paper} from "@material-ui/core";
import c from "../../../constants";
import Fade from '@material-ui/core/Fade';
import BTDDetailsMainButtons from "../../fncomponents/BTDDetailsMainButtons";
import DiscardItem from "../../fncomponents/DiscardItem";
import {
    openTradeFromBTD,
    updateBTDDiscardIt,
} from "../../../redux/actions/trades";
import {getTitle} from "../../fncomponents/PageTitleUtils";
import {Helmet} from "react-helmet/es/Helmet";


class BTDSummary extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isEditBTDTradeConditionsPanelOpen: false,
            isEditBTDConditionsPanelOpen: false,
            isBTDDetailsPanelOpen: true,
            isCancelBTDPanelOpen: false,
            isDiscardBTDPanelOpen: false,
            goBack: false,
            tradeOpened: false
        }

        this.goBack = this.goBack.bind(this)
        this.openDiscardBTDPanel = this.openDiscardBTDPanel.bind(this)
        this.closeDiscardBTDPanel = this.closeDiscardBTDPanel.bind(this)
        this.discardBTD = this.discardBTD.bind(this)
        this.openTrade = this.openTrade.bind(this)
    }

    goBack() {
        this.setState({
            goBack: true
        })
    }

    openDiscardBTDPanel() {
        this.setState({
            isDiscardBTDPanelOpen: true,
            isBTDDetailsPanelOpen: false
        })
    }

    closeDiscardBTDPanel() {
        this.setState({
            isDiscardBTDPanelOpen: false,
            isBTDDetailsPanelOpen: true,
        })
    }

    discardBTD() {
        this.state.isDiscardBTDPanelOpen = false
        this.state.isBTDDetailsPanelOpen = true
        this.props.updateBTDDiscardIt({btdId: this.props.btd.buy_the_dip_id})
    }

    openTrade() {
        this.props.openTradeFromBTD(this.props.btd.trade_order_id)
        this.setState({
            tradeOpened: true
        })
    }

    componentDidMount() {
        if (!this.props.btd)
            return
    }

    render() {
        if (this.state.goBack)
            return <Redirect to="/"/>
        if (this.state.tradeOpened)
            return <Redirect to="/tradedetails"/>

        let {btd} = this.props
        if (!btd)
            return <Redirect to="/"/>

        const {quote, base} = getQuoteAndBase(btd)
        const verbFutureTense = getTradeEnterVerbFutureTense(btd)
        const posSize = getPosSize(btd)
        const {currentPriceDeltaPercent, currentPrice, currentPriceClass} = getCurrentPriceAndDeltaPercent(btd)
        const {maxPrice, maxDelta, minPrice, minDelta} = getMaxMinAndDeltaPercent(btd)
        const {tpContent, slContent} = getTradeConditionsContentForTradeDetails(btd, false)
        const dipPrice = brushValue(btd.dip_price)

        return <div className={"formContainer"} style={{paddingTop: "3.8em"}}>
                <Helmet>
                    <title>{getTitle()}</title>
                </Helmet>
                <div style={{maxWidth: "30rem", minWidth: "22rem"}}>
                    {this.state.isBTDDetailsPanelOpen &&
                    <Fade in={this.state.isBTDDetailsPanelOpen}>
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.goBack()}}><ArrowBack/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>BTD <span style={{opacity: "0.5"}}>{btd.buy_the_dip_id}</span> summary</div>
                                    <div style={{width: "2.6rem"}}/>
                                </div>
                            </div>
                            <div className={"vertSpacer1"}/>
                            <div className={"tradeDetailsPair"} >
                                {quote} <span className={"tradeDetailsPairSlash"}>/</span> {base}
                            </div>
                            <div className={`tradeDetailsCurrentPrice ${currentPriceClass}`} title={"Current price"}>
                                {currentPriceDeltaPercent}% · {currentPrice}
                            </div>
                            <div className={"vertSpacer1"}/>
                            {btd.status === c.BTD_STATUS_ERROR &&
                            <div>
                                <div style={{
                                    color: "#FB4A4A",
                                    textAlign: "center",
                                    fontSize: "1.6rem",
                                    lineHeight: "1.8rem"
                                }}>
                                    Error
                                </div>
                                <div style={{textAlign: "center", color: "#FB4A4A"}}>
                                    {btd.error}
                                </div>
                            </div>
                            }
                            {btd.status === c.BTD_STATUS_DIP_REACHED &&
                            <div>
                                <div style={{
                                    color: "gray",
                                    textAlign: "center",
                                    fontSize: "1.6rem",
                                    lineHeight: "1.8rem"
                                }}>
                                    Dip reached
                                </div>
                                <div style={{textAlign: "center", marginTop: "0.6rem"}} className={"action"}>
                                    {btd.trade_order_id && <div style={{cursor: "pointer"}} onClick={()=>{this.openTrade()}}>Open Trade {btd.trade_order_id}</div>}
                                </div>
                            </div>
                            }
                            {btd.status === c.BTD_STATUS_CANCELED &&
                            <div>
                                <div style={{
                                    color: "#E8E288",
                                    textAlign: "center",
                                    fontSize: "1.6rem",
                                    lineHeight: "1.8rem"
                                }}>
                                    Canceled
                                </div>
                            </div>
                            }
                            <div className={"vertSpacer2"}/>
                            <div className={"tradeDetailsCreationDate"} style={{fontSize: "0.9rem", textAlign: "center"}}>
                                <span title={"Created on"}>{timeFormatter(btd.time, true)}</span>
                                <span title={"Closed on"}>&nbsp;→&nbsp;{timeFormatter(btd.last_update_time, true)}</span>
                                <br/>
                                <span title={"Duration"}>{formatTimeDuration(btd.last_update_time, btd.time)}</span>
                            </div>
                            <div className={"tradeDetailsPosAndQty"}>
                                <b>{posSize} {base}</b> {verbFutureTense} <small>{quote}</small><br/>
                                <b>dip price {dipPrice}</b>
                            </div>
                            <div className={"vertSpacer"}/>
                            <div className={"tradeDetailsCreationDate"}>
                                <div><b>During BTD</b></div>
                                {maxPrice > 0 && <div className={"maxPriceContainer2"}>max price {maxDelta}% · {maxPrice}</div>}
                                {minPrice > 0 && <div className={"minPriceContainer2"}>min price {minDelta}% · {minPrice}</div>}
                            </div>
                            <div className={"vertSpacer2"}/>
                            <div className={"tradeDetailsCreationDate"}>
                                <span><b>Trade conditions</b></span><br/>
                                <span>{tpContent}</span><br/>
                                <span>{slContent}</span>
                            </div>
                            <div style={{padding: "0.6rem"}}>
                                <BTDDetailsMainButtons btd={btd} cancelBTD={this.openCancelBTDPanel}
                                                         discardBTD={this.openDiscardBTDPanel}/>
                            </div>
                        </Paper>
                    </Fade>}
                    {this.state.isDiscardBTDPanelOpen &&
                        <Paper style={{backgroundColor: "#101926"}}>
                            <div className={"tradeDetailsTopBar"}>
                                <div className={"tradeDetailsTopBarVerticalCenter"}>
                                    <div className={"tradeDetailsTopBarBackButton"} onClick={()=> {this.closeDiscardBTDPanel()}}><Clear/></div>
                                    <div className={"tradeDetailsTopBarTitle"}>Discard BTD</div>
                                    <div style={{width: "2.6rem"}}/>
                                </div>
                            </div>
                            <div className={"tradeDetailsTradeConditionsPaper"}>
                                <DiscardItem discardItem={this.discardBTD} nevermind={this.closeDiscardBTDPanel}
                                             question={"Are you sure you want to discard this BTD?"}
                                             discardWarningText={"Discarding this BTD will remove it from your waiting list."}/>
                            </div>
                        </Paper>}
                </div>
            </div>
    }
}


let mapStateToProps = rootState =>({
    btd: rootState.trades.openedBTD,
})

let mapDispatchToProps = dispatch => {
    return {
        openTradeFromBTD: (order_id) => dispatch(openTradeFromBTD(order_id)),
        updateBTDDiscardIt: (props) => dispatch(updateBTDDiscardIt(props))

    }
}


export default connect(mapStateToProps, mapDispatchToProps) (BTDSummary)
