import React from 'react';
import Button from '@material-ui/core/Button';
import {TextField} from "@material-ui/core";
import {Info} from "@material-ui/icons";

export default function ChangeLoginPassword(props) {

    const {handleSave, handleCancel, handleChangePasswordInputs,
        oldPassword, newPassword, newPasswordConfirmation, errors, passwordChanged} = props

    return (
            <div style={{margin: "auto", width: "18rem", marginTop: "5rem"}}>
                {passwordChanged && <div className={"infoAlert"}>
                    <div><Info/>&nbsp;</div>
                    <div>Password changed</div>
                </div>}
                <div>
                    <TextField name={"oldPassword"} style={{width: "16rem"}} type="password"
                               value={oldPassword} onChange={handleChangePasswordInputs}
                               label={"Old password"}
                               error={errors.oldPassword}
                               helperText={errors["oldPassword"]}
                    />
                </div>
                <div style={{marginTop: "1rem"}}>
                    <TextField name={"newPassword"} style={{width: "16rem"}} type="password"
                               value={newPassword} onChange={handleChangePasswordInputs}
                               label={"New password"}
                               error={errors.password}
                               helperText={errors["password"]}
                    />
                </div>
                <div style={{marginTop: "1rem"}}>
                    <TextField name={"newPasswordConfirmation"} style={{width: "16rem"}} type="password"
                               value={newPasswordConfirmation} onChange={handleChangePasswordInputs}
                               label={"New password confirmation"}
                               error={errors.passwordConfirmation}
                               helperText={errors["passwordConfirmation"]}
                    />
                </div>

                <div style={{marginTop: "4rem", display: "flex", justifyContent: "space-between", width: "14rem"}}>
                    <Button onClick={handleCancel} color="primary" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary" variant="contained">
                        Change
                    </Button>
                </div>
            </div>
    );
}
