import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import {Edit, Close} from "@material-ui/icons";
import {TextField, Button} from "@material-ui/core";
import SmallButton from "../../fncomponents/SmallButton";
import Avatar from 'react-avatar-edit'

const useStyles = makeStyles(theme => ({


    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        backgroundColor: '#101A26'
    }
}))


export default function EditableLogoSection(props) {
    const {
        editable,
        switchOnEditable,
        switchOffEditable,
        imgSrc,
        imgPreview,
        imgOnCrop,
        imgOnClose,
        trader,
        handleSave,
        handleCancel} = props
    const classes = useStyles()

    if (editable)
        return (<div>
            <div className={"traderSalesPageEditableSectionContainer"} style={{width: "50rem"}}>
                <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}} title={"Close edit mode"}>
                    <Close className={"smallMenuIcon"} onClick={switchOffEditable}/>
                </div>
                <div style={{marginLeft: "3rem"}}>
                    <div style={{width: "400px"}}>
                        <Avatar
                            width={390}
                            height={295}
                            onCrop={imgOnCrop}
                            onClose={imgOnClose}
                            src={imgSrc}
                        />
                    </div>
                    <div style={{marginTop: "1rem"}}>
                        {imgPreview && <img src={imgPreview} alt="Preview" />}
                    </div>

                </div>
                <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
                    <SmallButton label={"Save"} variant="outlined" onClick={handleSave}/>
                    <SmallButton label={"Cancel"} variant="outlined" onClick={handleCancel}/>
                </div>
            </div>
        </div>)
    else return (<div>
        <div className={"traderSalesPageEditableSectionContainer"} style={{width: "50rem"}}>
            <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}} title={"Open edit mode"}>
                <Edit className={"smallMenuIcon"} onClick={switchOnEditable}/>
            </div>
            <div className={"traderSalesPageAbout"}>
                {trader.logo && <img src={trader.logo.data}/>}
                {!trader.logo && <div>No logo set</div>}
            </div>
        </div>
    </div>)
}
