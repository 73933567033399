import React, {useEffect, useState} from 'react'
import c from '../../constants'
import {Button, TextField} from "@material-ui/core"
import {brushValue} from "./UISupportFns"
import {invest} from "../../redux/actions/investment"
import {useDispatch} from "react-redux"

export default function UserInvests(props) {

    const {investProgram, balances, investmentCoin, cancel, autoClose} = props

    const [investAmount, setInvestAmount] = useState("")
    const [investAmountError, setInvestAmountError] = useState(null)
    const [availableBalance, setAvailableBalance] = useState(0)
    const [showInvestButton, setShowInvestButton] = useState(true)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!balances)
            return
        let balance = undefined
        for(let i = 0; i < balances.length && !balance; i++) {
            if (balances[i].coin === investmentCoin) {
                balance = balances[i]
            }
        }
        setAvailableBalance(balance.availableBalance)
    })

    const areFieldsValidForTransfer = () => {
        let valid = true
        if (investAmount === "") {
            setInvestAmountError("Please specify an amount")
            valid = false
        } else if (investAmount > availableBalance) {
            setInvestAmountError("Insufficient balance")
            valid = false
        } else if (investAmount < 1000) {
            setInvestAmountError(`Minimum amount is 1000 ${investmentCoin}`);
            valid = false
        }
        return valid
    }

    return (
        <div className="transfer__form" style={{marginTop: "1rem"}}>
            <div style={{width: "80%", margin: "auto"}}>
                <div style={{textAlign: "center", fontWeight: "bold", fontSize: "1.6rem"}}>Participate in the</div>
                <div style={{textAlign: "center", fontWeight: "bold", fontSize: "4rem", marginTop: "0.6rem", marginBottom: "0.6rem"}}>{investProgram.title}</div>
                <div style={{textAlign: "center", fontWeight: "bold", fontSize: "1.6rem", marginBottom: "2rem"}}>sale</div>
                <div style={{textAlign: "center", marginBottom: "1rem"}}>Your balance is</div>
            </div>
            <div className="balance__top">
                <img
                    src={`imgs/coins/${investmentCoin?.toUpperCase()}_${c.COIN_LOGOS_PIC_VERSION}.png`}
                    className="balance__coinLogo"
                    alt="icon"
                />
                <div className="balance__title">
                    <span>{investmentCoin}</span>
                    <span className="balance__dot"> · </span>
                    {" "}
                    <span>Available</span>
                </div>
                <div className="balance__amount">{brushValue(availableBalance)}</div>
                <Button
                    style={{
                        color: "#17BEBB",
                        display: "flex",
                        width: "161px",
                        margin: "0 auto",
                        height: "30px",
                        textAlign: "center",
                        textTransform: "none",
                    }}
                    onClick={() => setInvestAmount(brushValue(availableBalance))}
                >
                    Use Max.
                </Button>
            </div>
            <div className="transfer__amount">
                <TextField
                    type="number"
                    variant="outlined"
                    placeholder={`Investing amount (min. 1000 ${investmentCoin})`}
                    margin={"normal"}
                    value={investAmount}
                    helperText={investAmountError}
                    onChange={(e) => setInvestAmount(e.target.value)}
                    error={investAmountError !== null}
                />
                {showInvestButton && <Button
                    style={{
                        backgroundColor: "#17BEBB",
                        display: "flex",
                        width: "161px",
                        margin: "20px auto",
                        borderRadius: "32px",
                        height: "48px",
                        textAlign: "center",
                        color: "black"
                    }}
                    onClick={() => {
                        setShowInvestButton(false)
                        if (!areFieldsValidForTransfer())
                            return
                        invest({
                                investProgramId: investProgram._id,
                                coin: investmentCoin,
                                amount: investAmount,
                            },
                            (data) => {
                                autoClose()
                            })(dispatch)}
                    }
                    >
                    Contribute
                </Button>}
                {!showInvestButton && <Button
                    disabled={true}
                    style={{
                        backgroundColor: "gray",
                        display: "flex",
                        width: "161px",
                        margin: "20px auto",
                        borderRadius: "32px",
                        height: "48px",
                        textAlign: "center",
                        color: "black"
                    }}
                >
                    Contributing...
                </Button>}
                <Button onClick={cancel} style={{width: "6rem", margin: "auto",}}>Cancel</Button>
            </div>
        </div>
    )
}
