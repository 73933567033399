import {isAuthorizationHeaderPresent} from "./authentication";
import axios from "./axiosFabric";
import {handleError} from "./errorHandler";
import {
    USERS_GET_BY_EMAIL, USERS_GET_REFERRED_ALL, USERS_GET_REFERRED_INITIAL,
} from "./actionTypes";

export const getUserByEmail = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/users/getbyemail`, props)
        .then(res => {
            if (cb) cb(res.data)
            dispatch({
                type: USERS_GET_BY_EMAIL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getReferredUsersInitial = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    props.page = 0
    axios.post(`/users/getreferredbypage`, props)
        .then(res => {
            dispatch({
                type: USERS_GET_REFERRED_INITIAL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getReferredUsersByPage = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return

    axios.post(`/users/getreferredbypage`, props)
        .then(res => {
            dispatch({
                type: USERS_GET_REFERRED_ALL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}
