import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import {getJWT} from "../../redux/actions/utils/tokenUtils";

const isJWTActive = () => {
    let jwt = getJWT()
    if(!jwt) {
        // console.error("JWT missing")
        return false
    } else {
        const decoded = jwt_decode(jwt);
        const currentTime = Date.now() / 1000;
        if(decoded.exp < currentTime) {
            // console.error("JWT expired")
            return false
        }
    }
    return true
}

const isJWTOTPOnly = () => {
    let jwt = getJWT()
    if(jwt) {
        const decoded = jwt_decode(jwt)
        const permissions = decoded.permissions
        if (permissions && permissions[0] == "OTPONLY")
            return true
    }
    return false
}

export {isJWTActive}

export default function withAuth (PrivateComponent) {

    return class extends Component {
        constructor (props) {
            super(props)
            this.state = {
                redirectLogin: false,
                redirect2FA: false,
            }

            let jwt = getJWT()
            if (jwt) {
                if (isJWTOTPOnly()) {
                    this.state.redirect2FA = true
                } else {
                    //jwt present - doing nothing
                }
            } else {
                //jwt not present - redirect to login
                this.state.redirectLogin = true
            }
        }

        render() {

            const {redirectLogin, redirect2FA} = this.state
            if (redirectLogin)
                return <Redirect to="/login"/>
            if (redirect2FA)
                return <Redirect to="/2fa"/>
            return (
                <React.Fragment>
                    <PrivateComponent {...this.props}/>
                </React.Fragment>
            )
        }
    }
}
