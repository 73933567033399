import React from 'react'
import {TextField, Button} from "@material-ui/core"
import OTPInput from "react-otp-input"


export default function OTPDeactivation({password, onPasswordChange, otp, onOtpChange, validationErrors, finish}) {
    const errorDisplay = validationErrors ? "block" : "none"

    function submit() {
        finish()
    }

    return (
        <div className={"AppMainContent"}>
            <div className={"stepperStepContainer"} style={{width: "18em", marginTop: "3em"}}>
                <div className={"stepperStepTitle"}>Disable Google Authenticator</div>
                <div>
                    <div style={{color: "red", display: errorDisplay}}>Password or token invalid...</div>
                    <TextField placeholder={"Login password"}
                               label={"Login password"}
                               type={"password"}
                               name="password"
                               value={password}
                               onChange={onPasswordChange}/>
                    <br/><br/>
                    <div style={{marginBottom: "0.6em", color: "#8B8275"}}>Google Authentication Code:</div>
                    <OTPInput numInputs={6}
                              value={otp}
                              onChange={onOtpChange}
                              containerStyle={{width: "18em", justifyContent: "space-between"}}
                              inputStyle={{width: "1.5em", height: "2em", borderBottom: "1px solid black", fontSize: "1.2em", color: "black", borderRadius: "5px"}}/>
                    <br/><br/>
                    <Button variant="contained"  color="primary" fullWidth label="Submit"
                            onClick={() => submit()}>Submit</Button>
                    <br/><br/>
                </div>
            </div>
        </div>
    )
}
