import React from "react"
import {TextField, Avatar, Chip, Paper, FormControl, InputLabel, Select, MenuItem} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import InputAdornment from "@material-ui/core/InputAdornment"
import {TrendingUp, TrendingDown, Timeline} from "@material-ui/icons"
import Fab from '@material-ui/core/Fab'
import CustomSwitch from "./CustomSwitch"
import c from '../../constants'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 270,
        backgroundColor: '#101A26'
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    fab: {
        // margin: theme.spacing(1),
        marginLeft: theme.spacing(0.6),
        backgroundColor: '#101A26',
        border: "1px solid #ffffff",
        color: "#ffffff"
    },
    fabSelected: {
        // margin: theme.spacing(),
        marginLeft: theme.spacing(0.6),
        backgroundColor: '#ffffff',
        border: "1px solid #000000",
        color: "#000000"
    },
    switcher: {
        checked: {
            backgroundColor: "#17BEBB"
        }
    },
    tabFabSelected: {
        backgroundColor: '#ffffff',
    }
}))

export default function TradeConditions(props) {
    let {
        actionType,
        takeProfitOption, takeProfitPriceOrPercent,
        stopLossOption, stopLossPriceOrPercent,
        switchWithOrWithoutStopLossTarget,
        handleChangeTakeProfitOption, handleChangeTakeProfitPriceOrPercent,
        handleChangeStopLossOption, handleChangeStopLossPriceOrPercent,
        base, noTitle, candlesType, handleChangeCandlesType} = props

    const classes = useStyles()
    const [withStopLossTarget, setWithStopLossTarget] = React.useState(true)
    const [trailingStopLossOption, setTrailingStopLossOption] = React.useState(false)
    const [showTitle, setShowTitle] = React.useState(true)

    React.useEffect(() => {
        // setProfitPriceOrPercent(takeProfitPriceOrPercent)
        // setStopPriceOrPercent(stopLossPriceOrPercent)
        if (stopLossOption == c.STOP_LOSS_OPTION_TRAILING)
            setTrailingStopLossOption(true)
        else
            setTrailingStopLossOption(false)

        if (noTitle) {
            setShowTitle(false)
        }

        setWithStopLossTarget(stopLossOption !== c.STOP_LOSS_OPTION_MANUAL)
    })

    function getTakeProfitInputAdorment() {
        switch (takeProfitOption) {
            case c.TAKE_PROFIT_OPTION_MANUAL: return "";
            case c.TAKE_PROFIT_OPTION_PERCENTAGE: return "%";
            case c.TAKE_PROFIT_OPTION_PRICE: return base;

        }
    }

    function getTakeProfitLabel() {
        switch (takeProfitOption) {
            case c.TAKE_PROFIT_OPTION_MANUAL: return <span style={{fontWeight: "bold", color: "#ffffff"}}>Manual take profit</span>;
            case c.TAKE_PROFIT_OPTION_PERCENTAGE: return <span>Take profit at <span style={{fontWeight: "bold", color: "#ffffff"}}>percentage</span></span>;
            case c.TAKE_PROFIT_OPTION_PRICE: return <span>Take profit at <span style={{fontWeight: "bold", color: "#ffffff"}}>price</span></span>;
            default: return "_________________________";

        }
    }

    function getStopLossInputAdorment() {
        switch (stopLossOption) {
            case c.STOP_LOSS_OPTION_TRAILING: return "%";
            case c.STOP_LOSS_OPTION_PERCENTAGE: return "%";
            case c.STOP_LOSS_OPTION_PRICE: return base;

        }
    }

    function getStopLossLabel() {
        switch (stopLossOption) {
            case c.STOP_LOSS_OPTION_TRAILING: return <span><span style={{fontWeight: "bold", color: "#ffffff"}}>Trailing percentage</span> stop loss</span>
            case c.STOP_LOSS_OPTION_PERCENTAGE: return <span>Stop loss at <span style={{fontWeight: "bold", color: "#ffffff"}}>percentage</span></span>
            case c.STOP_LOSS_OPTION_PRICE: return <span>Stop loss at <span style={{fontWeight: "bold", color: "#ffffff"}}>price</span></span>
            default: return "____________________";

        }
    }

    function switchTrailingStopLoss() {
        let newValue = !trailingStopLossOption
        setTrailingStopLossOption(newValue)

        if (newValue)
            handleChangeStopLossOption(c.STOP_LOSS_OPTION_TRAILING)
        else
            handleChangeStopLossOption(c.STOP_LOSS_OPTION_PERCENTAGE_FROM_TRAILING)
    }

    function getDirection() {
        if (actionType === c.ACTION_TYPE_BUY)
            return "rises"
        else if (actionType === c.ACTION_TYPE_SELL)
            return "descends"
    }

    function getStyleByStopLossTab(_stopLossTab) {
        if ( (_stopLossTab === 0 &&
                (stopLossOption === c.STOP_LOSS_OPTION_PRICE ||
                stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE ||
                stopLossOption === c.STOP_LOSS_OPTION_TRAILING)) ||
            (_stopLossTab === 1 && stopLossOption === c.STOP_LOSS_OPTION_CONDITIONAL)) {
            return {backgroundColor: "#ffffff", color: "#000000"}
        }
    }


    return (<div className={"wizardSectionContainer"}>
        {showTitle && <div className={"wizardTitle"}>
            <span className={"wizardTitleIcon"}><Timeline/></span> Set your trade conditions
        </div>}
        <div id={"takeProfit"} style={{display: "flex", marginTop: "1em",
            justifyContent: "space-between", flexWrap: "wrap", maxWidth: "26em"}}>
            <div>
                <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8"}}>{getTakeProfitLabel()}</div>
                <TextField
                    className={classes.textField}
                    variant={"outlined"}
                    margin={"dense"}
                    id="input-with-icon-textfield_trade_cond"
                    value={takeProfitPriceOrPercent}
                    onChange={handleChangeTakeProfitPriceOrPercent}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <TrendingUp/>
                                {(takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE) && <span className={"signIndent"}>+</span>}
                            </InputAdornment>
                        ),
                        endAdornment: <InputAdornment position="end">{getTakeProfitInputAdorment()}</InputAdornment>
                    }}
                    inputProps={{ maxLength: 16 }}
                />
            </div>
            <div style={{marginTop: "1.05rem", width: "8rem"}}>
                <Fab color={takeProfitOption==c.TAKE_PROFIT_OPTION_PERCENTAGE? "primary": "default"} aria-label="Add"
                     className={takeProfitOption==c.TAKE_PROFIT_OPTION_PERCENTAGE ? classes.fabSelected : classes.fab} variant={"extended"}
                     onClick={() => {handleChangeTakeProfitOption(c.TAKE_PROFIT_OPTION_PERCENTAGE)}}>
                    %
                </Fab>
                <Fab color={takeProfitOption==c.TAKE_PROFIT_OPTION_PRICE? "primary": "default"} aria-label="Add"
                     className={takeProfitOption==c.TAKE_PROFIT_OPTION_PRICE ? classes.fabSelected : classes.fab} variant={"extended"}
                     onClick={() => {handleChangeTakeProfitOption(c.TAKE_PROFIT_OPTION_PRICE)}}>
                    #
                </Fab>
            </div>
        </div>
        <div style={{marginLeft: "0.6em", marginTop: "1.6em", marginBottom: "0.6rem"}}>
            With stop loss
            <CustomSwitch checked={withStopLossTarget} color="primary"
                          inputProps={{ 'aria-label': 'checkbox with default color' }}
                          onChange={switchWithOrWithoutStopLossTarget} classes={{checked: classes.switcher.checked}}
            />
        </div>
        {withStopLossTarget && <div>
            <div style={{marginBottom: "0.4rem"}}>
                <Chip
                    label={<div style={{display: "flex", alignItems: "center"}}>Simple</div>}
                    clickable
                    onClick={()=>{handleChangeStopLossOption(c.STOP_LOSS_OPTION_PRICE)}}
                    color="primary"
                    variant="outlined"
                    style={getStyleByStopLossTab(0)}
                />
                &nbsp;
                <Chip
                    label={<div style={{display: "flex", alignItems: "center"}}>Conditional</div>}
                    clickable
                    onClick={()=>{handleChangeStopLossOption(c.STOP_LOSS_OPTION_CONDITIONAL)}}
                    color="primary"
                    variant="outlined"
                    style={getStyleByStopLossTab(1)}
                />
            </div>
            {(stopLossOption === c.STOP_LOSS_OPTION_PRICE ||
                stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE ||
                stopLossOption === c.STOP_LOSS_OPTION_TRAILING) && <Paper>
                <div style={{padding: "0.6rem"}}>
                    <div id={"stopLoss"} style={{display: "flex", marginTop: "1.6em",
                        justifyContent: "space-between", flexWrap: "wrap", maxWidth: "25em"}}>
                        <div>
                            <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8"}}>{getStopLossLabel()}</div>
                            <TextField
                                className={classes.textField}
                                variant={"outlined"}
                                margin={"dense"}
                                id="input-with-icon-textfield_trade_cond_2"
                                value={stopLossPriceOrPercent}
                                onChange={handleChangeStopLossPriceOrPercent}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <TrendingDown/>
                                            {(stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE ||
                                                stopLossOption === c.STOP_LOSS_OPTION_TRAILING) && <span className={"signIndent"}>-</span>}
                                        </InputAdornment>
                                    ),
                                    endAdornment: <InputAdornment position="end">{getStopLossInputAdorment()}</InputAdornment>
                                }}
                                inputProps={{ maxLength: 16 }}
                            />
                        </div>
                        <div style={{marginTop: "1.05rem", width: "7rem"}}>
                            <Fab color={ (stopLossOption==c.STOP_LOSS_OPTION_PERCENTAGE || stopLossOption==c.STOP_LOSS_OPTION_TRAILING)
                                ? "primary": "default"} aria-label="Add"
                                 className={(stopLossOption==c.STOP_LOSS_OPTION_PERCENTAGE || stopLossOption==c.STOP_LOSS_OPTION_TRAILING) ?
                                 classes.fabSelected: classes.fab} variant={"extended"}
                                 onClick={() => {handleChangeStopLossOption(c.STOP_LOSS_OPTION_PERCENTAGE)}}>
                                %
                            </Fab>
                            <Fab color={stopLossOption==c.STOP_LOSS_OPTION_PRICE ? "primary": "default"}
                                 aria-label="Add" className={stopLossOption==c.STOP_LOSS_OPTION_PRICE ? classes.fabSelected: classes.fab}
                                 variant={"extended"}
                                 onClick={() => {handleChangeStopLossOption(c.STOP_LOSS_OPTION_PRICE)}}>
                                #
                            </Fab>
                        </div>
                    </div>
                    <div style={{marginLeft: "0.6em", marginBottom: "1rem"}}>
                        Use trailing stop loss
                        <CustomSwitch checked={trailingStopLossOption} color="primary"
                            inputProps={{ 'aria-label': 'checkbox with default color' }}
                                onChange={switchTrailingStopLoss} classes={{checked: classes.switcher.checked}}
                        />
                        <div style={{maxWidth: "21em", fontSize: "0.86em"}}>Trailing moves stop loss price automatically if the price {getDirection()}.</div>
                    </div>
                </div>
            </Paper>}
            {stopLossOption === c.STOP_LOSS_OPTION_CONDITIONAL && <Paper>
                <div style={{padding: "0.6rem"}}>
                    <div id={"stopLossConditional"} style={{display: "flex",marginTop: "1.6em",
                        justifyContent: "space-between", flexWrap: "wrap", maxWidth: "25em"}}>
                        <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8"}}>Stop loss price at candle close</div>
                        <TextField
                            className={classes.textField}
                            variant={"outlined"}
                            margin={"dense"}
                            id="input-with-icon-textfield_trade_cond_3"
                            value={stopLossPriceOrPercent}
                            onChange={handleChangeStopLossPriceOrPercent}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <TrendingDown/>
                                    </InputAdornment>
                                ),
                                endAdornment: <InputAdornment position="end">{base}</InputAdornment>
                            }}
                            inputProps={{ maxLength: 16 }}
                        />
                    </div>
                    <div id={"stopLossConditional"} style={{marginTop: "0.6em", marginBottom: "1rem"}}>
                        <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8"}}>Candles</div>
                        <FormControl style={{marginLeft: "20px"}}>
                            <Select
                                inputProps={{
                                    name: 'candles',
                                    id: 'candles',
                                }}
                                onChange={handleChangeCandlesType}
                                value={candlesType}
                            >
                                <MenuItem value={1} key={Math.random()}>30m</MenuItem>
                                <MenuItem value={2} key={Math.random()}>1h</MenuItem>
                                <MenuItem value={3} key={Math.random()}>4h</MenuItem>
                                <MenuItem value={4} key={Math.random()}>8h</MenuItem>
                                <MenuItem value={5} key={Math.random()}>12h</MenuItem>
                                <MenuItem value={6} key={Math.random()}>24h</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </Paper>}
        </div>}
    </div>)
}
