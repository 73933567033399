import { SettingsInputAntenna } from "@material-ui/icons";
import React from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TimeAgo from "react-timeago";
import c from "../../../../constants";
import { openSUC } from "../../../../redux/actions/sucs";
import { ReactComponent as DownTrendRed } from "../../../../svgs/down_trend_red.svg";
import { ReactComponent as InlineTrendWhite } from "../../../../svgs/inline_trend_white.svg";
import { ReactComponent as UpTrendGreen } from "../../../../svgs/up_trend_green.svg";
import {
  brushValue,
  getCurrentBuyAndSellPrice,
  getCurrentPriceAndDeltaPercent,
  getMaxMinAndDeltaPercent,
  getMaxMinAndDeltaPercentFutures,
  getQuoteAndBase,
  getTradeConditionsContentForSUC,
  getTradeEnterVerbFutureTense,
  timeAgoFormatter,
  timeFormatter,
  timeFormatter2,
} from "../../../fncomponents/UISupportFns";

function SUCcard({ sucId, type, openSUCFn }) {
  const history = useHistory();
  const suc = useSelector((state) => state.sucs[`${type}SUCs`]?.find((s) => s?.suc_id === sucId)) || {};

  const ACTION_TYPE = suc?.action_type;
  const FUTURES_TYPE = suc?.futures_type;
  const { quote, base } = getQuoteAndBase(suc);
  const { currentPrice } = getCurrentBuyAndSellPrice(suc);
  const { trend, currentPriceDeltaPercent, currentPriceClass } = getCurrentPriceAndDeltaPercent(suc, true);
  const buyPrice = brushValue(suc?.buy_price);
  const sellPrice = brushValue(suc?.sell_price);
  const { tpContent, slContent } = getTradeConditionsContentForSUC(suc);
  const verbFutureTense = getTradeEnterVerbFutureTense(suc);

  let _getMaxMinAndDeltaPercent = getMaxMinAndDeltaPercent;
  if (suc?.futures_type) {
    _getMaxMinAndDeltaPercent = getMaxMinAndDeltaPercentFutures;
  }
  const { maxPrice, maxDelta, minPrice, minDelta } = _getMaxMinAndDeltaPercent(suc);

  const SUC_FUTURES_TYPES = {
    [c.FUTURES_TYPE_LONG]: "LONG",
    [c.FUTURES_TYPE_SHORT]: "SHORT",
  };

  let barColor = "#3BADFF";
  if (suc.status === c.SUC_STATUS_CLOSED || suc?.status === c.SUC_STATUS_MATERIALIZED || suc?.status === c.SUC_STATUS_CANCELED) {
    barColor = "gray";
  } else if (suc.status === c.SUC_STATUS_ERROR) {
    barColor = "#FB4A4A";
  } else if (suc?.connected_to_signal === false || suc?.connected_to_signal === "false") {
    barColor = "gray";
  }

  // useEffect(() => {
  //   console.log(currentPrice);
  // }, [currentPrice]);

  function openSUC(record) {
    openSUCFn(record);
    if (record.status === c.SUC_STATUS_WAITING) history.push(`/sucdetails`);
    else history.push(`/sucsummary`);
  }

  return (
    <div key={sucId} className={`sucContainer ${suc?.futures_type?.toLowerCase() || ""}_FuturesTradeBand`} onClick={() => openSUC(suc)}>
      <div className={"tradeSubContainerLeft"}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "6rem" }}>
            <span style={{ fontSize: "1.2em", fontWeight: "bold" }}>{quote}</span>
            <span style={{ fontSize: "1em" }}> / {base}</span>
          </div>
          {suc?.signal_type == c.SIGNAL_TYPE_FUTURES && (
            <div>
              <span className={`${SUC_FUTURES_TYPES[suc.futures_type].toLowerCase()}FuturesCardLeverage`}>{suc.leverage}X</span>
              <span className={`futuresType${SUC_FUTURES_TYPES[suc.futures_type]}`}>{SUC_FUTURES_TYPES[suc.futures_type]}</span>
            </div>
          )}
          {suc?.status == c.SUC_STATUS_WAITING && (
            <div style={{ top: "0.8rem", marginLeft: "1rem" }}>
              {trend === 1 && <UpTrendGreen />}
              {trend === 0 && <InlineTrendWhite />}
              {trend === -1 && <DownTrendRed />}
            </div>
          )}
        </div>
        {ACTION_TYPE === c.ACTION_TYPE_BUY && (
          <div className={"activeTradePosAndQtyStart"}>
            <b>
              {suc?.pos_size} {base}
            </b>{" "}
            {verbFutureTense} <b>{quote}</b>
          </div>
        )}
        {ACTION_TYPE === c.ACTION_TYPE_SELL && (
          <div className={"activeTradePosAndQtyStart"}>
            <b>
              {suc?.pos_size} {quote}
            </b>{" "}
            {verbFutureTense} <b>{base}</b>
          </div>
        )}
        {(FUTURES_TYPE === c.FUTURES_TYPE_LONG || FUTURES_TYPE === c.FUTURES_TYPE_SHORT) && (
          <div className={"activeTradePosAndQtyStart"}>
            <b>{suc?.pos_size}</b> {base} initial margin
          </div>
        )}
        <div style={{ marginTop: "0.2rem", marginBottom: "0.2rem" }}>
          {tpContent && (
            <div className={"takeProfitStopLossConfig"} title={"How the trade will take profit"}>
              {tpContent}
            </div>
          )}
          <div className={"takeProfitStopLossConfig"} title={"How the trade will stop loss"}>
            {slContent}
          </div>
        </div>
        <div>
          <SettingsInputAntenna style={{ color: barColor, position: "relative", top: "0.4rem", fontSize: "42" }} />
          {suc?.status == c.SUC_STATUS_WAITING && (
            <div className={"progressBarText"} style={{ position: "relative", top: "-0.1rem" }}>
              WAITING...
            </div>
          )}
          {suc?.status == c.SUC_STATUS_MATERIALIZED && <div className={"progressBarText"}>MATERIALIZED</div>}
          {suc?.status == c.SUC_STATUS_SHOULD_HAVE_MATERIALIZED && <div className={"progressBarText"}>SHOULD HAVE MATERIALIZED</div>}
          {suc?.status == c.SUC_STATUS_CANCELED && <div className={"progressBarText"}>CANCELED</div>}
          {suc?.status == c.SUC_STATUS_CLOSED && <div className={"progressBarText"}>CLOSED</div>}
          {(suc?.status == c.SUC_STATUS_ERROR || suc?.error) && (
            <div className={"progressBarText"} style={{ color: "#FB4A4A" }}>
              JAMMED
            </div>
          )}
        </div>
      </div>
      <div className={"tradeSubContainerRight"}>
        {suc?.status == c.SUC_STATUS_WAITING && (
          <div className={"currentPriceStatsContainer"} title={"Current price"}>
            <div className={currentPriceClass}>{currentPriceDeltaPercent}%</div>
            <span className={"horizSpacer"} />
            <div className={currentPriceClass}>{currentPrice}</div>
          </div>
        )}
        <div className={"activeTradePosAndQtyEnd"}>
          {ACTION_TYPE === c.ACTION_TYPE_BUY && (
            <div className={"activeTradePosAndQtyEnd"}>
              {suc?.status >= c.SUC_STATUS_MATERIALIZED && <span style={{ color: "#64FE7C" }}>✓&nbsp;</span>}
              <span className={"buy"}>buy</span> price <b>{buyPrice}</b>
            </div>
          )}
          {ACTION_TYPE === c.ACTION_TYPE_SELL && (
            <div className={"activeTradePosAndQtyEnd"}>
              {suc?.status >= c.SUC_STATUS_MATERIALIZED && <span style={{ color: "#64FE7C" }}>✓&nbsp;</span>}
              <span className={"sell"}>sell</span> price <b>{sellPrice}</b>
            </div>
          )}
          {FUTURES_TYPE === c.FUTURES_TYPE_LONG && (
            <div className={"activeTradePosAndQtyEnd"}>
              {suc?.status >= c.SUC_STATUS_MATERIALIZED && <span style={{ color: "#64FE7C" }}>✓&nbsp;</span>}
              <span className={"buy"}>entry</span> price <b>{suc.entry_price}</b>
            </div>
          )}
          {FUTURES_TYPE === c.FUTURES_TYPE_SHORT && (
            <div className={"activeTradePosAndQtyEnd"}>
              {suc?.status >= c.SUC_STATUS_MATERIALIZED && <span style={{ color: "#64FE7C" }}>✓&nbsp;</span>}
              <span className={"sell"}>entry</span> price <b>{suc?.entry_price}</b>
            </div>
          )}
        </div>
        {maxPrice !== "0" && (
          <div className={"maxPriceContainer"} title={"Max price during signal"}>
            {maxPrice} ({maxDelta}%)
          </div>
        )}
        {minPrice !== "0" && (
          <div className={"minPriceContainer"} title={"Min price during signal"}>
            {minPrice} ({minDelta}%)
          </div>
        )}
        <div className={"activeTradePosAndQtyEnd"}>
          <div title={"Research time frame and graphic chart"}>
            {suc?.time_frame && <span>{suc?.time_frame}, </span>}
            {suc?.tv_chart_url && (
              <a href={suc?.tv_chart_url} target={"blank"}>
                chart
              </a>
            )}
          </div>
        </div>
        <div className={"activeTradePosAndQtyEnd"}>
          <div className={"signalComment"}>
            {suc?.last_comment && (
              <span title={`Last comment by ${suc?.last_comment?.author} on ${timeFormatter2(suc?.last_comment?.creationDate, true)}`}>
                {suc?.last_comment?.comment}
              </span>
            )}
          </div>
        </div>
        {suc?.status === c.SUC_STATUS_WAITING && (
          <div className={"activeForContainer"}>
            <span>
              Active for <TimeAgo date={parseInt(suc.time)} formatter={timeAgoFormatter} />
            </span>
          </div>
        )}
        <div>
          <span style={{ fontSize: "0.7rem" }}>from </span>
          {suc?.trader_name}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        {suc?.status === c.SUC_STATUS_WAITING && (
          <div>
            <div className={"hrState"}>
              <span className="blink live" style={{ color: "#68FF7D" }}>
                ▶
              </span>
            </div>
          </div>
        )}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }} className={"recordId"}>
        <span>
          {suc?.suc_id} ({suc?.signal_id})
        </span>
        <span>{timeFormatter(suc?.time, true)}</span>
      </div>
    </div>
  );
}

let mapStateToProps = (rootState) => ({});

let mapDispatchToProps = (dispatch) => {
  return {
    openSUCFn: (trade) => dispatch(openSUC(trade)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SUCcard);
