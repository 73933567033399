import React from 'react'
import {ReactComponent as UpTrendGreen} from '../../svgs/up_trend_green.svg'
import {ReactComponent as DownTrendRed} from '../../svgs/down_trend_red.svg'
import {ReactComponent as InlineTrendWhite} from '../../svgs/inline_trend_white.svg'
import {
    brushValue,
    getFuturesCardSymbolWidth,
    timeAgoFormatter,
    timeFormatter,
} from "./UISupportFns"
import TimeAgo from 'react-timeago'
import c from '../../constants'
import {AddBox, SettingsInputAntenna} from "@material-ui/icons"

export default function FuturesPositionCard(props) {


    function getMarkPricePNLAndRoeContent(futuresTrade, format = 0) {
        if (format === 1)
            return _formatOne(futuresTrade)
        return _formatSpot(futuresTrade)
    }

    function _formatOne(futuresTrade) {
        return <div className={"currentPriceStatsContainerFutures"} style={{marginTop: "0.6rem"}}>
            <div style={{width: "3rem"}}>
                <div className={"smallIndOnFuturesCard"} style={{textAlign: "center"}}>PNL</div>
                <div className={futuresTrade.currentPriceClass} style={{textAlign: "center"}}>{futuresTrade.pnl}</div>
            </div>
            <div style={{width: "3rem"}}>
                <div className={"smallIndOnFuturesCard"} style={{textAlign: "center"}}>ROE %</div>
                <div className={futuresTrade.currentPriceClass} style={{textAlign: "center"}}>({futuresTrade.currentPriceDeltaPercent}%)</div>
            </div>
            <div style={{width: "4.6rem"}}>
                <div className={"smallIndOnFuturesCard"} style={{textAlign: "right"}}>Mark Price</div>
                <div className={futuresTrade.currentPriceClass} style={{textAlign: "right"}}>{futuresTrade.currentPrice}</div>
            </div>
        </div>
    }

    function _formatSpot(futuresTrade) {

        return <div>
            <div className={"currentPriceStatsContainer"}>
                    <div className={futuresTrade.currentPriceClass} title={"ROE"}>{futuresTrade.currentPriceDeltaPercent}%</div>
                <span className={"horizSpacer"}/>
                <div className={futuresTrade.currentPriceClass} title={"Mark price"}>{futuresTrade.currentPrice}</div>
            </div>
            {futuresTrade.pnl !== undefined && <div className={futuresTrade.currentPriceClass} style={{textAlign: "right"}} title={"PNL"}>{futuresTrade.pnl} {futuresTrade.base}</div>}
        </div>
    }


    const {futuresTrade, openRecord, addRemoveMargin} = props
    let antenaColor = "gray"
    if (futuresTrade.from_SUC && (futuresTrade.connected_to_signal === true || futuresTrade.connected_to_signal === "true"))
        antenaColor = "#68FF7D"
    let futuresTypeOrig = futuresTrade.futures_type ? futuresTrade.futures_type : ""
    let futuresType = futuresTrade.futures_type ? futuresTrade.futures_type.toLowerCase() : ""

    return (
        <div className={`tradeContainer ${futuresType}FuturesTradeBand`} onClick={openRecord}>
            {/*================================== LEFT SUB-CONTAINER ========================================*/}
            <div className={"tradeSubContainerLeft"}>
                <div style={{display: "flex", justifyContent: "space-between", width: getFuturesCardSymbolWidth(futuresTrade)}}>
                    <div>
                        <span style={{fontSize: "1.2em", fontWeight: "bold"}}>{futuresTrade.quote}</span>
                        <span style={{fontSize: "1em"}}> / {futuresTrade.base}</span>
                        <span className={`${futuresType}FuturesCardLeverage`}>{futuresTrade.leverage}X</span>
                        <span className={`futuresType${futuresTypeOrig}`}>{futuresTypeOrig}</span>
                    </div>
                    {(futuresTrade.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_ONGOING || futuresTrade.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_PARTIALLY_FILLED) &&
                        <div style={{top: "0.8rem"}}>
                            {futuresTrade.trend === 1 && <UpTrendGreen/>}
                            {futuresTrade.trend === 0 && <InlineTrendWhite/>}
                            {futuresTrade.trend === -1 && <DownTrendRed/>}
                        </div>
                    }
                </div>
                <div className={"activeTradePosAndQtyStart"}>
                    <small>size</small> <span className={"futuresSize"} title={`With a notional value of ${futuresTrade.notionalValue} ${futuresTrade.base}`}>{futuresTrade.origQty} {futuresTrade.quote}</span>
                    {/*<small title={"Notional value"}>({futuresTrade.notionalValue} {futuresTrade.base})</small>*/}
                </div>
                <div className={"activeTradePosAndQtyStart"} title={"Initial margin"}>
                    <small>initial margin</small> <b>{futuresTrade.initial_margin} {futuresTrade.base}</b>
                </div>
                <div className={"activeTradePosAndQtyStart"}
                     style={{marginTop: addRemoveMargin ? "0.2rem": "0rem", marginBottom: addRemoveMargin ? "0.2rem": "0rem", width: "10rem", display: "flex", justifyContent: "flex-start", cursor: "pointer"}}
                     onClick={e => addRemoveMargin ? addRemoveMargin(e, futuresTrade): ""}
                >
                    <div style={{marginTop: "0.2rem", fontSize: "0.9rem"}} title={"Initial margin + PNL"}>
                        <small>margin</small> <b>{futuresTrade.margin}</b>
                    </div>
                    {addRemoveMargin && <div title={"Additional margin"}>
                        <AddBox style={{width: "2rem"}}/>
                    </div>}
                </div>
                <div className={"activeTradePosAndQtyStart"} style={{fontSize: "0.9rem"}} title={`Margin ratio = maitenance margin/margin; maintenance margin = ${futuresTrade.maintenance_margin_rate} * notional value`}>
                    <small>margin ratio</small> <b>{futuresTrade.marginRatio}%</b>
                </div>
                <div className={"activeTradePosAndQtyStart"} title={"Liquidation price"}>
                    <small>liq.price</small> <span style={{color: "#17BEBB", fontWeight: "bold"}}>{futuresTrade.liquidationPrice}</span>
                </div>
                <div style={{marginTop: "0.2rem", marginBottom: "0.2rem"}}>
                    {futuresTrade.tpContent && <div className={"takeProfitStopLossConfig"} title={"How the trade will take profit"}>
                        {futuresTrade.tpContent}
                    </div>}
                    <div className={"takeProfitStopLossConfig"} title={"How the trade will stop loss"}>
                        {futuresTrade.slContent}
                    </div>
                </div>
                {futuresTrade.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_PARTIALLY_FILLED &&
                    <div>
                        <div className={"w3-progress-sizer"}>
                            <div className="w3-progress-container w3-round-xlarge">
                                <div className="w3-progressbar w3-round-xlarge" style={{width: `${futuresTrade.progress}%`}}></div>
                            </div>
                        </div>
                        <div className={"progressBarText"}>{futuresTrade.progress}% FILLED</div>
                    </div>
                }
                {futuresTrade.from_SUC && <div>
                    <SettingsInputAntenna style={{color: antenaColor, position: "relative", top: "0.4rem", fontSize: "42"}} />
                </div>}
            </div>
            {/*================================== RIGHT SUB-CONTAINER ========================================*/}
            <div style={{display: "flex"}}>
                {/*<div className={"horizSpacerOnSmallScreens"}/>*/}
                {(futuresTrade.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_ONGOING ||
                    futuresTrade.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_PARTIALLY_FILLED) &&
                    <div className={"tradeSubContainerRight"}>
                        {/*---------------------- FIRST ROW: CURRENT PRICE/ MADE X/ LOST X -----------------------*/}
                        {getMarkPricePNLAndRoeContent(futuresTrade)}
                        <div className={"activeTradePosAndQtyEnd"}><span className={`entryPrice${futuresTypeOrig}`}>{futuresTrade.entryPriceLable}</span> price <b>{futuresTrade.price}</b></div>

                        {futuresTrade.maxPrice !== "0" &&<div className={"maxPriceContainer"} title={"Max price during the trade"}>
                            <span>{futuresTrade.maxDelta}% · {futuresTrade.maxPrice}</span>
                        </div>}
                        {futuresTrade.minPrice !== "0" && <div className={"minPriceContainer"} title={"Min price during the trade"}>
                            <span>{futuresTrade.minDelta}% · {futuresTrade.minPrice}</span>
                        </div>}
                        {futuresTrade.child_order.candle_close_price && <div className={"candleClosePriceContainer"} title={"Last candle close price"}>
                            <span>{c.CANDLES_TYPES_HR_MAP[futuresTrade.candles_type]} c.c. {futuresTrade.child_order.candle_close_price}</span>
                        </div>}
                        {futuresTrade.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_CANCELED &&
                        <div className={"currentPriceStatsContainer"}>
                            <span style={{color: " #E8E288"}}>Canceled</span>
                        </div>
                        }
                        {futuresTrade.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_IN_ERROR &&
                        <div className={"currentPriceStatsContainer"}>
                            <span style={{color: " #FB4A4A"}}>Error</span>
                        </div>
                        }
                        {futuresTrade.ven_liquidation_state === c.TRADE_STATE_PENDING_MATCHING_LIQUIDATION_ORDER &&
                        <div className={"currentPriceStatsContainer"}>
                            <span style={{color: "#E8E288"}}>Liquidating...</span>
                        </div>
                        }
                        {(futuresTrade.from_SUC && futuresTrade.trader_name) && <div style={{display: "flex", justifyContent: "flex-end"}}>
                            <span style={{fontSize: "0.7rem", marginTop: "0.3rem"}}>from&nbsp;</span>{futuresTrade.trader_name}</div>}
                    </div>
                }

                {(futuresTrade.tradeState !== c.FUTURES_TRADE_STATE_WITH_CHILD_ONGOING && futuresTrade.tradeState !== c.FUTURES_TRADE_STATE_WITH_CHILD_PARTIALLY_FILLED) &&
                <div className={"tradeSubContainerRight"}>
                    {/*---------------------- FIRST ROW: CURRENT PRICE/ MADE X / LOST X -----------------------*/}
                    {getMarkPricePNLAndRoeContent(futuresTrade)}
                    <div className={"activeTradePosAndQtyEnd"}><span className={`entryPrice${futuresTypeOrig}`}>{futuresTrade.entryPriceLable}</span> price <b>{futuresTrade.price}</b></div>
                    {((futuresTrade.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_FILLED || futuresTrade.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_CANCELED) && futuresTrade.tradeProfitOrLoss.profit) &&
                    <div className={"profitOrLossContainer"}>
                        <div style={{color: "#64FE7C"}}>↑&nbsp;</div>
                        <div style={{color: "#64FE7C"}}>+{futuresTrade.tradeProfitOrLoss.deltaPercent}% <small><b>{futuresTrade.tradeProfitOrLoss.deltaValue}</b> {futuresTrade.base}&nbsp;</small>
                        </div>
                    </div>}
                    {((futuresTrade.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_FILLED || futuresTrade.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_CANCELED) && futuresTrade.tradeProfitOrLoss.loss) &&
                    <div className={"profitOrLossContainer"}>
                        <div style={{color: "#FB4A4A"}}>↓&nbsp;</div>
                        <div style={{color: "#FB4A4A"}}>{futuresTrade.tradeProfitOrLoss.deltaPercent}% <small>{futuresTrade.tradeProfitOrLoss.deltaValue} {futuresTrade.base}&nbsp;</small>
                        </div>
                    </div>
                    }
                    {futuresTrade.ven_liquidation_state === c.TRADE_STATE_EXCHANGE_LIQUIDATION_NOT_FOUND &&
                    <div className={"currentPriceStatsContainer"}>
                        <span style={{color: "#fb4a4a"}}>Liq. Order not found!</span>
                    </div>
                    }
                    {(futuresTrade.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_CANCELED || futuresTrade.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_CANCELED_AND_UNFULFILLED) &&
                    <div className={"currentPriceStatsContainer"}>
                        <span style={{color: "#E8E288"}}>Canceled</span>
                    </div>
                    }
                    {futuresTrade.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_IN_ERROR &&
                    <div className={"currentPriceStatsContainer"}>
                        <span style={{color: " #FB4A4A"}}>Error</span>
                    </div>
                    }
                    {(futuresTrade.from_SUC && futuresTrade.trader_name) && <div style={{display: "flex", justifyContent: "flex-end"}}>
                        <span style={{fontSize: "0.7rem", marginTop: "0.3rem"}}>from&nbsp;</span>{futuresTrade.trader_name}</div>}
                </div>
                }
            </div>
            {/*================================== BOTTOM last ROW (WIDTH 100%) ====================================*/}
            <div style={{display:"flex", justifyContent:"center", width: "100%"}}>
                {futuresTrade.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_FILLED &&
                <div className={"buyPriceContainer"}>
                    {futuresTrade.last_action === c.TRADE_OP_USER_TAKES_PROFIT && <span>You took profit</span>}
                    {futuresTrade.last_action === c.TRADE_OP_AUTO_TAKE_PROFIT_TARGET_1 && <span>Took profit #1</span>}
                    {futuresTrade.last_action === c.TRADE_OP_AUTO_TAKE_PROFIT_TARGET_2 && <span>Took profit #2</span>}
                    {futuresTrade.last_action === c.TRADE_OP_AUTO_TAKE_PROFIT && <span>Took profit</span>}
                    {futuresTrade.last_action === c.TRADE_OP_AUTO_TAKE_LOSS && <span>Stopped loss</span>}
                    {futuresTrade.last_action === c.TRADE_OP_AUTO_LIQUIDATE && <span>Liquidated</span>}
                    {futuresTrade.last_action === c.TRADE_OP_EXCHANGE_LIQUIDATE && <span>Exchange liquidated</span>}
                    {futuresTrade.last_action === c.TRADE_OP_AUTO_CLOSE && <span>Closed</span>}
                    &nbsp;on {timeFormatter(futuresTrade.last_action_time, true)}
                </div>
                }
                {(futuresTrade.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_CANCELED || futuresTrade.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_CANCELED_AND_UNFULFILLED) &&
                <div className={"buyPriceContainer"}>
                    You canceled on {timeFormatter(futuresTrade.last_action_time, true)}
                </div>
                }
                {futuresTrade.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_IN_ERROR &&
                <div className={"buyPriceContainer"}>
                    Errored on {timeFormatter(futuresTrade.last_action_time, true)}
                </div>}
                {futuresTrade.tradeState === c.FUTURES_TRADE_STATE_WITH_CHILD_ONGOING &&
                <div className={"activeForContainer"}>
                    <span>Active for <TimeAgo date={parseInt(futuresTrade.time)} formatter={timeAgoFormatter}/></span>
                </div>
                }
                {futuresTrade.tradeState === c.FUTURES_TRADE_STATE_UNCERTAIN &&
                <div className={"uncertainContainer"}>
                    <span>Uncertain state. Contact support.</span>
                </div>
                }
            </div>

            <div style={{display:"flex", justifyContent:"space-between", width: "100%"}} className={"recordId"}>
                <div style={{position: "relative", top: "0.52rem"}}>
                    {futuresTrade.order_id}
                    {futuresTrade.from_SUC && futuresTrade.from_SUC !== "undefined" && (<span title={`from Signal ${futuresTrade.signal_id}`}>&nbsp;&nbsp;(<b>{futuresTrade.signal_id}</b>)</span>)}
                    <span>,&nbsp;{timeFormatter(futuresTrade.time, true)}</span>
                    {futuresTrade.idx && <span>,&nbsp;#{futuresTrade.idx}</span>}
                </div>
                <div>
                    {futuresTrade.hrStateText && <div>
                        {futuresTrade.blinking && <div className={"hrState"}>{futuresTrade.hrStateText} <span className="blink live" style={{color: futuresTrade.hrStateSymbolColor}}>{futuresTrade.hrStateSymbol}</span></div>}
                        {!futuresTrade.blinking && <div className={"hrState"}>{futuresTrade.hrStateText} <span className="live" style={{color: futuresTrade.hrStateSymbolColor}}>{futuresTrade.hrStateSymbol}</span></div>}
                    </div>}
                </div>
            </div>
        </div>)
}
