import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getTitle} from "../../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"
import KeyboardBackArrow from '@material-ui/icons/KeyboardBackspace'
import NewSignalFormV1Futures from "./NewSignalFormV1Futures"
import c from "../../../constants"
import {debounce} from "../../hoc/myUtils"
import {
    getCurrenciesMap,
    getCurrentPriceOnPair,
    getSymbolPrecisionAndTickSize
} from "../../../redux/actions/newFutures"
import {brushValue, getValueWithPrecision, isValidPair, scientificToDecimal} from "../../fncomponents/UISupportFns"
import {getTradersSubscriptions} from "../../../redux/actions/traders"
import {
    checkEnterTradeIntervalFutures,
    clearCurrentSignal,
    getSignalByIdV1,
    previewV1
} from "../../../redux/actions/signals"
import {getRates} from "../../../redux/actions/futures"

const qs = require("query-string")

const DEBOUNCE_DELAY_IN_MS = 500
const AVERAGE_BTC_POS_SIZE = 0.05
const ENTER_TRADE_PRICE_INTERVAL = 0.01 //1%


class NewOrUpdateSignalV1Futures extends Component {

    constructor(props) {
        super(props)
        this.state = {
            futuresType: c.FUTURES_TYPE_LONG,
            quote: 'BTC',
            base: 'USDT',
            isAdjustLeverageOpen: false,
            oldLeverage: 20,
            leverage: 20,
            leverageSlider: 20,
            enterTradePriceFrom: 0,
            enterTradePriceTo: 0,
            precision: 0,
            tickSize: 0,
            currentLastBidPrice: 0,
            timeFrame: "",
            tvChartURL: "",
            stopLossTarget: 0,
            stopLossOption: c.STOP_LOSS_OPTION_PRICE,//auto: price/percent, trailing
            candlesType: 2,
            takeProfitTargets: [0],
            takeProfitTargetEditedIndex: 0,
            takeProfitOption: c.TAKE_PROFIT_OPTION_PRICE, //TAKE_PROFIT_OPTION_MANUAL, auto: TAKE_PROFIT_OPTION_AT_A_SPECIFIC_PERCENTAGE/TAKE_PROFIT_OPTION_AT_A_SPECIFIC_PRICE
            comment: "",
            receivingSubscriptions:{},
            potentialProfit: 0,
            potentialLoss: 0,
            riskRewardRatio: 0,
            showWarningForEntryPrice: false
        }

        this.cancel = this.cancel.bind(this)
        this.previewSignal = this.previewSignal.bind(this)
        this.populateStateSignalFromSignal = this.populateStateSignalFromSignal.bind(this)

        this.handleChangeLeverage = this.handleChangeLeverage.bind(this)
        this.handleChangeLeverageSlider = this.handleChangeLeverageSlider.bind(this)
        this.decreaseLeverage = this.decreaseLeverage.bind(this)
        this.increaseLeverage = this.increaseLeverage.bind(this)
        this.adjustLeverage = this.adjustLeverage.bind(this)
        this.cancelAdjustLeverage = this.cancelAdjustLeverage.bind(this)
        this.confirmNewLeverage = this.confirmNewLeverage.bind(this)

        this.handleChangeFuturesType = this.handleChangeFuturesType.bind(this)

        this.handleChangeQuote = this.handleChangeQuote.bind(this)
        this._adjustNumbers = this._adjustNumbers.bind(this)
        this.debounceAdjustNumbers = debounce(this._adjustNumbers, DEBOUNCE_DELAY_IN_MS)
        this.actualizeTakeProfitAndStopLoss = this.actualizeTakeProfitAndStopLoss.bind(this)

        this.handleChangeEnterTradePriceTo = this.handleChangeEnterTradePriceTo.bind(this)
        this.handleChangeEnterTradePriceFrom = this.handleChangeEnterTradePriceFrom.bind(this)
        this.fixEnterTradePrices = this.fixEnterTradePrices.bind(this)
        this.debounceFixEnterTradePrices = debounce(this.fixEnterTradePrices, DEBOUNCE_DELAY_IN_MS)
        this.computeIndicators = this.computeIndicators.bind(this)

        this.handleChangeTakeProfitTarget = this.handleChangeTakeProfitTarget.bind(this)
        this.fixTakeProfitTarget = this.fixTakeProfitTarget.bind(this)
        this.debounceFixTakeProfitTarget = debounce(this.fixTakeProfitTarget, DEBOUNCE_DELAY_IN_MS)
        this.handleChangeTakeProfitOption = this.handleChangeTakeProfitOption.bind(this)
        this.addTakeProfitTarget = this.addTakeProfitTarget.bind(this)
        this.removeTakeProfitTarget = this.removeTakeProfitTarget.bind(this)

        this.handleChangeStopLossTarget = this.handleChangeStopLossTarget.bind(this)
        this.fixStopLossTarget = this.fixStopLossTarget.bind(this)
        this.debounceFixStopLossTarget = debounce(this.fixStopLossTarget, DEBOUNCE_DELAY_IN_MS)
        this.handleChangeStopLossOption = this.handleChangeStopLossOption.bind(this)
        this.switchWithOrWithoutStopLossTarget = this.switchWithOrWithoutStopLossTarget.bind(this)

        this.handleChangeComment = this.handleChangeComment.bind(this)
        this.handleChangeReceivingSubscriptions = this.handleChangeReceivingSubscriptions.bind(this)

        this.isAtLeastOneReceivingSubscriptionSelected = this.isAtLeastOneReceivingSubscriptionSelected.bind(this)
        this.checkTakeProfitTargetsAreInSuccession = this.checkTakeProfitTargetsAreInSuccession.bind(this)

        this.reFetchCurrentPrice = this.reFetchCurrentPrice.bind(this)
        this.getPriceIntervalPercentage = this.getPriceIntervalPercentage.bind(this)

        this.stepFrom = this.stepFrom.bind(this)
    }

    stepFrom() {
        let from = parseFloat(this.state.enterTradePriceFrom)
        let to = from + 1
        this.setState({
            enterTradePriceTo: brushValue(to, this.state.tickSize)
        })
    }

    getPriceIntervalPercentage() {
        let l = parseInt(this.state.leverage)
        if (l > 0)
            return ENTER_TRADE_PRICE_INTERVAL / this.state.leverage
        return ENTER_TRADE_PRICE_INTERVAL
    }

    reFetchCurrentPrice() {
        this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, result => {
            let currentLastBidPrice = result.current_price
            this.setState({
                currentLastBidPrice: currentLastBidPrice ? currentLastBidPrice : 0,
                enterTradePriceFrom: currentLastBidPrice ? brushValue(currentLastBidPrice, result.tick_size) : 0,
                precision: result.precision,
                tickSize: result.tick_size,
                enterTradePriceTo: currentLastBidPrice ? brushValue(currentLastBidPrice * (1 + this.getPriceIntervalPercentage()), result.tick_size) : 0
            })
            this.computeIndicators()
        })
    }

    handleChangeComment(e) {
        this.setState({
            comment: e.target.value
        })
    }

    handleChangeReceivingSubscriptions(g) {
        if (this.state.signalId)
            return
        this.setState({
            receivingSubscriptionsErr: undefined,
            receivingSubscriptions: {...this.state.receivingSubscriptions,
                ...{[g.target.value]: (!this.state.receivingSubscriptions[g.target.value])}}
        })
    }

    handleChangeStopLossTarget(e) {
        this.setState({
            stopLossTarget: e.target.value
        })
        this.debounceFixStopLossTarget()
    }

    fixStopLossTarget() {
        let stopLossTarget = `${this.state.stopLossTarget}`
        stopLossTarget = stopLossTarget.replace("-","")
        if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE) {
            this.state.stopLossTarget = getValueWithPrecision(stopLossTarget, 2)
        } else {
            this.state.stopLossTarget = getValueWithPrecision(stopLossTarget, this.state.tickSize)
        }
        this.computeIndicators()
    }

    handleChangeStopLossOption(option) {
        //If there is no change, do nothing
        if (option === this.state.stopLossOption)
            return

        let leverage = parseFloat(this.state.leverage)
        let oldSLOption = this.state.stopLossOption
        let oldSLOptionType = 0
        switch (oldSLOption) {
            case c.STOP_LOSS_OPTION_PRICE: oldSLOptionType = 1; break;
            case c.STOP_LOSS_OPTION_CONDITIONAL: oldSLOptionType = 1; break;
            case c.STOP_LOSS_OPTION_PERCENTAGE: oldSLOptionType = 2; break;
        }

        let optionType = 0
        switch (option) {
            case c.STOP_LOSS_OPTION_PRICE: optionType = 1; break;
            case c.STOP_LOSS_OPTION_CONDITIONAL: optionType = 1; break;
            case c.STOP_LOSS_OPTION_PERCENTAGE: optionType = 2; break;
        }

        let transitionDelta = oldSLOptionType - optionType

        if (transitionDelta === 0) {
            //no need for calculations
        } else if (transitionDelta === -1) {
            //transition from price to percentage
            let oldPrice = parseFloat(this.state.stopLossTarget)
            let currentPrice = this.state.currentLastBidPrice
            let percent = ((currentPrice - oldPrice) / currentPrice / leverage) * 100
            if (percent < 0)
                percent = -1 * percent
            this.state.stopLossTarget = brushValue(percent, 1)
        } else if (transitionDelta === 1) {
            //transition from percentage to price
            let sign = 1
            if (this.state.futuresType === c.FUTURES_TYPE_SHORT)
                sign = -1
            let oldPercent = parseFloat(this.state.stopLossTarget)
            let currentPrice = this.state.currentLastBidPrice
            let price = (1 - sign * oldPercent/ leverage/ 100) * parseFloat(currentPrice)
            this.state.stopLossTarget = brushValue(price, this.state.tickSize)
        }
        this.setState({
            stopLossOption: option
        })
    }

    switchWithOrWithoutStopLossTarget() {
        if (this.state.stopLossOption === c.STOP_LOSS_OPTION_MANUAL) {
            this.setState({
                stopLossOption: c.STOP_LOSS_OPTION_PERCENTAGE,
                stopLossTarget: 10
            })
        } else {
            this.setState({
                stopLossOption: c.STOP_LOSS_OPTION_MANUAL
            })
        }
    }

    addTakeProfitTarget() {
        if (this.state.takeProfitTargets.length === 10)
            return
        let newTakeProfit = 0
        if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE) {
            newTakeProfit = parseFloat(this.state.takeProfitTargets[this.state.takeProfitTargets.length - 1]) + 1
            newTakeProfit = newTakeProfit.toFixed(this.state.tickSize)
        } else if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE){
            newTakeProfit = parseFloat(this.state.takeProfitTargets[this.state.takeProfitTargets.length - 1]) + 10
            newTakeProfit = newTakeProfit.toFixed(0)
        }
        this.state.takeProfitTargets.push(newTakeProfit)
        this.setState({
            someVar: Math.random()
        })
    }

    removeTakeProfitTarget(index) {
        if (index === 0) {
            //Remove first element
            this.state.takeProfitTargets.shift()
        } else if (index === this.state.takeProfitTargets.length -1) {
            //Remove last element
            this.state.takeProfitTargets.pop()
        } else {
            //Remove an element in the middle
            this.state.takeProfitTargets.splice(index, 1)
        }

        this.setState({
            someVar: Math.random()
        })
    }

    handleChangeTakeProfitOption(option) {
        //If there is no change, do nothing
        if (this.state.takeProfitOption === option)
            return

        let leverage = parseFloat(this.state.leverage)
        let sign = 1
        if (this.state.futuresType === c.FUTURES_TYPE_SHORT)
            sign = -1

        //Else if the transition is from Percentage or Price, transform
        //percentage to price and vice-versa
        if (option === c.TAKE_PROFIT_OPTION_PRICE) {
            for(let i = 0; i < this.state.takeProfitTargets.length; i++) {
                let oldPercent = parseFloat(this.state.takeProfitTargets[i])
                let currentPrice = this.state.currentLastBidPrice
                let price = (1 + sign * oldPercent/ leverage/ 100) * parseFloat(currentPrice)
                this.state.takeProfitTargets[i] = brushValue(price, this.state.tickSize)
            }
        } else if (option === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
            for(let i = 0; i < this.state.takeProfitTargets.length; i++) {
                let oldPrice = parseFloat(this.state.takeProfitTargets[i])
                let currentPrice = this.state.currentLastBidPrice
                let percent = ((oldPrice - currentPrice) / currentPrice / leverage) * 100
                if (percent < 0)
                    percent = -1 * percent
                this.state.takeProfitTargets[i] = brushValue(percent, 1)
            }
        }

        this.setState({
            takeProfitOption: option
        })
    }

    handleChangeTakeProfitTarget(e, i) {
        this.state.takeProfitTargets[i] = e.target.value
        this.state.takeProfitTargetEditedIndex = i
        this.setState({
            someVar: Math.random()
        })
        this.debounceFixTakeProfitTarget()
    }

    fixTakeProfitTarget() {
        let index = this.state.takeProfitTargetEditedIndex
        if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
            this.state.takeProfitTargets[index] = getValueWithPrecision(this.state.takeProfitTargets[index], 2)
        } else {
            this.state.takeProfitTargets[index] = getValueWithPrecision(this.state.takeProfitTargets[index], this.state.tickSize)
        }
        this.computeIndicators()
    }

    _adjustNumbers() {
        this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, result => {
            this.props.getRates({symbol: `${this.state.quote}${this.state.base}`, leverage: this.state.leverage}, result1 => {
                let {maxLeverage, maxNotionalValue, initialMarginRate} = result1
                let currentLastBidPrice = result.current_price

                if (this.state.futuresType === c.FUTURES_TYPE_LONG) {
                    if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE) {
                        this.state.takeProfitTargets = [brushValue(currentLastBidPrice * 1.1, result.tick_size)]
                    }
                    if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PRICE) {
                        this.state.stopLossTarget = brushValue(currentLastBidPrice * 0.95, result.tick_size)
                    }
                    this.state.enterTradePriceTo = currentLastBidPrice ?
                        brushValue(currentLastBidPrice * (1 + this.getPriceIntervalPercentage()), result.tick_size) : 0
                } else if (this.state.futuresType === c.FUTURES_TYPE_SHORT) {
                    if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE) {
                        this.state.takeProfitTargets = [brushValue(currentLastBidPrice * .9, result.tick_size)]
                    }
                    if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PRICE) {
                        this.state.stopLossTarget = brushValue(currentLastBidPrice * 1.05, result.tick_size)
                    }
                    this.state.enterTradePriceTo = currentLastBidPrice ?
                        brushValue(currentLastBidPrice * (1 - this.getPriceIntervalPercentage()), result.tick_size) : 0
                }

                this.setState({
                    maxLeverage, maxNotionalValue, initialMarginRate,
                    currentLastBidPrice: currentLastBidPrice ? brushValue(currentLastBidPrice, result.tick_size) : 0,
                    enterTradePriceFrom: currentLastBidPrice ? brushValue(currentLastBidPrice, result.tick_size) : 0,
                    precision: result.precision,
                    tickSize: result.tick_size
                })
            })
        })
    }

    computeIndicators() {
        // let potentialProfit = 0
        // let potentialLoss = 0
        // if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE) {
        //     if (this.state.takeProfitTargets) {
        //         let tpt = 0
        //         if (this.state.takeProfitTargets.length === 2) {
        //             tpt = parseFloat(this.state.takeProfitTargets[1])
        //         } else if (this.state.takeProfitTargets.length === 1) {
        //             tpt = parseFloat(this.state.takeProfitTargets[0])
        //         }
        //
        //         let enterTradePriceTo = parseFloat(this.state.enterTradePriceTo)
        //         potentialProfit = AVERAGE_BTC_POS_SIZE * ( tpt/ enterTradePriceTo - 1)
        //     }
        // } else if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
        //     if (this.state.takeProfitTargets) {
        //         let tpt = 0
        //         if (this.state.takeProfitTargets.length === 2) {
        //             tpt = parseFloat(this.state.takeProfitTargets[1])
        //         } else if (this.state.takeProfitTargets.length === 1) {
        //             tpt = parseFloat(this.state.takeProfitTargets[0])
        //         }
        //         potentialProfit = AVERAGE_BTC_POS_SIZE * tpt/100
        //     }
        // }
        //
        // if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PRICE) {
        //     let slt = parseFloat(this.state.stopLossTarget)
        //     let enterTradePriceFrom = parseFloat(this.state.enterTradePriceFrom)
        //     potentialLoss =  Math.abs(AVERAGE_BTC_POS_SIZE * ( slt/ enterTradePriceFrom - 1))
        // } else if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE) {
        //     let slt = parseFloat(this.state.stopLossTarget)
        //     potentialLoss = AVERAGE_BTC_POS_SIZE * slt
        // }
        //
        // let profitVsLoss = Math.abs(potentialProfit / potentialLoss)
        // profitVsLoss = profitVsLoss.toFixed(1)
        // this.setState({
        //     potentialProfit: potentialProfit.toFixed(8),
        //     potentialLoss: potentialLoss.toFixed(8),
        //     riskRewardRatio: profitVsLoss
        // })
    }

    fixEnterTradePrices() {
        this.state.enterTradePriceFrom = getValueWithPrecision(this.state.enterTradePriceFrom, this.state.tickSize)
        this.state.enterTradePriceTo = getValueWithPrecision(this.state.enterTradePriceTo, this.state.tickSize)
        this.computeIndicators()
        this.props.checkEnterTradeInterval({
                enterTradePriceFrom: this.state.enterTradePriceFrom,
                enterTradePriceTo: this.state.enterTradePriceTo,
                quote: this.state.quote,
                base: this.state.base,
                futuresType: this.state.futuresType
            }, (data) => {
                if (data) {
                    let {currentPrice, delta} = data
                    if (delta >= 1) {
                        this.setState({
                            showWarningForEntryPrice: true,
                            currentPrice, delta
                        })
                    } else {
                        this.setState({
                            showWarningForEntryPrice: false
                        })
                    }}
            }
        )
    }

    handleChangeEnterTradePriceFrom(e) {
        if (e.target.value && e.target.value.length > 0) {
            let from = parseFloat(e.target.value)
            let sign = 1
            if (this.state.futuresType === c.FUTURES_TYPE_SHORT)
                sign = -1
            let to = from * (1 + sign * this.getPriceIntervalPercentage())
            to = scientificToDecimal(to)
            to = getValueWithPrecision(to, this.state.tickSize)

            this.setState({
                enterTradePriceFrom: e.target.value,
                enterTradePriceTo: to,
            })
            this.debounceFixEnterTradePrices()
        } else {
            this.setState({
                enterTradePriceFrom: e.target.value,
            })
        }
    }

    handleChangeEnterTradePriceTo(e) {
        if (e.target.value && e.target.value.length > 0) {
            this.setState({
                enterTradePriceTo: e.target.value
            })
            this.debounceFixEnterTradePrices()
        } else {
            this.setState({
                enterTradePriceTo: e.target.value
            })
        }
    }

    actualizeTakeProfitAndStopLoss(currentLastBidPrice) {
        let signForTP = 1
        let signForSL = -1
        if (this.state.futuresType === c.FUTURES_TYPE_SHORT) {
            signForTP = -1
            signForSL = 1
        }

        if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE) {
            this.state.takeProfitPriceOrPercent = getValueWithPrecision(currentLastBidPrice * (1 + signForTP * 0.1), this.state.tickSize)
        }

        if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PRICE) {
            this.state.stopLossPriceOrPercent = getValueWithPrecision(currentLastBidPrice * (1 + signForSL * 0.05), this.state.tickSize)
        }

    }

    handleChangeQuote(e) {
        this.setState({
            quote: e.target.value.toUpperCase()
        })
        this.debounceAdjustNumbers()
    }

    handleChangeFuturesType() {
        if (this.state.futuresType === c.FUTURES_TYPE_LONG) {
            this.setState({
                futuresType: c.FUTURES_TYPE_SHORT,
            })
        } else if (this.state.futuresType === c.FUTURES_TYPE_SHORT) {
            this.setState({
                futuresType: c.FUTURES_TYPE_LONG,
            })
        }
        this.debounceAdjustNumbers()
    }

    confirmNewLeverage() {
        this.setState({
            isAdjustLeverageOpen: false,
            enterTradePriceTo: this.state.enterTradePriceFrom ? brushValue(this.state.enterTradePriceFrom * (1 + this.getPriceIntervalPercentage()), this.state.tickSize) : 0,
        })
    }

    cancelAdjustLeverage() {
        this.setState({
            leverage: this.state.oldLeverage,
            isAdjustLeverageOpen: false
        })
    }

    adjustLeverage() {
        this.setState({
            oldLeverage: this.state.leverage,
            leverageSlider: this.state.leverage,
            isAdjustLeverageOpen: true,
        })
    }

    increaseLeverage() {
        let newL = this.state.leverage + 1
        let newLs = this.state.leverageSlider + 1
        newL = newL > this.state.maxLeverage ? this.state.maxLeverage: newL
        newLs = newLs > this.state.maxLeverage ? this.state.maxLeverage: newLs
        this.setState({
            leverage: newL,
            leverageSlider: newLs
        })
    }

    decreaseLeverage() {
        let newL = this.state.leverage - 1
        let newLs = this.state.leverageSlider - 1
        newL = newL < 1 ? 1: newL
        newLs = newLs < 1 ? 1: newLs
        this.setState({
            leverage: newL,
            leverageSlider: newLs
        })
    }

    handleChangeLeverageSlider(event, value) {
        this.setState({
            leverageSlider: value,
            leverage: value
        })
    }

    handleChangeLeverage(e) {
        if (e.target.value.length === 0) {
            this.setState({
                leverage: e.target.value,
                leverageSlider: 0
            })

        } else {
            this.setState({
                leverage: parseInt(e.target.value),
                leverageSlider: parseInt(e.target.value)
            })
        }
    }

    cancel() {
        this.props.clearCurrentSignal(()=>{
            this.setState({
                signalId: undefined,
                actionType: c.FUTURES_TYPE_LONG, //action_type_BUY or action_type_SELL
                quote: 'BTC',
                base: 'USDT',
                enterTradePriceFrom: 0,
                enterTradePriceTo: 0,
                currentLastBidPrice: 0,
                timeFrame: "",
                tvChartURL: "",
                stopLossTarget: 0,
                stopLossOption: c.STOP_LOSS_OPTION_PRICE,//auto: price/percent, trailing
                takeProfitTargets: [0],
                takeProfitTargetEditedIndex: 0,
                takeProfitOption: c.TAKE_PROFIT_OPTION_PRICE, //TAKE_PROFIT_OPTION_MANUAL, auto: TAKE_PROFIT_OPTION_AT_A_SPECIFIC_PERCENTAGE/TAKE_PROFIT_OPTION_AT_A_SPECIFIC_PRICE
                comment: "",
                receivingSubscriptions:{}
            })
            this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, result => {
                let tickSize = parseInt(result.tick_size)
                let currentLastBidPrice = brushValue(result.current_price, tickSize)
                this.setState({
                    currentLastBidPrice: currentLastBidPrice ? currentLastBidPrice : 0,
                    enterTradePriceFrom: currentLastBidPrice ? currentLastBidPrice : 0,
                    precision: result.precision,
                    tickSize: result.tick_size,
                    enterTradePriceTo: currentLastBidPrice ? brushValue(currentLastBidPrice * (1 + this.getPriceIntervalPercentage()), tickSize) : 0,
                    stopLossTarget: brushValue(currentLastBidPrice * .95, tickSize),
                    takeProfitTargets:[brushValue(currentLastBidPrice * 1.1, tickSize)]
                })
            })
        })
    }

    isAtLeastOneReceivingSubscriptionSelected(receivingSubscriptions) {
        if (!receivingSubscriptions)
            return false
        let keys = Object.keys(receivingSubscriptions)
        if (keys.length === 0)
            return false
        let result = false
        keys.map((key, index) => {
            if (receivingSubscriptions[key]) {
                result = true
                return undefined
            }
            return undefined
        })
        return result
    }

    checkTakeProfitTargetsAreInSuccession(takeProfitTargets) {
        if (takeProfitTargets) {
            if (takeProfitTargets.length === 1)
                return true

            if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE) {
                for(let i = 0; i < takeProfitTargets.length -1; i++) {
                    if (this.state.futuresType === c.FUTURES_TYPE_LONG && parseFloat(takeProfitTargets[i]) >= parseFloat(takeProfitTargets[i+1])) {
                        return false
                    } else if (this.state.futuresType === c.FUTURES_TYPE_SHORT && parseFloat(takeProfitTargets[i]) <= parseFloat(takeProfitTargets[i+1])) {
                        return false
                    }
                }
            } else if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
                for(let i = 0; i < takeProfitTargets.length -1; i++) {
                    if (parseFloat(takeProfitTargets[i]) >= parseFloat(takeProfitTargets[i+1])) {
                        return false
                    }
                }
            }

            return true
        }
    }

    previewSignal() {
        let errors = false
        this.state.receivingSubscriptionsErr = undefined
        this.state.selectPairErr = undefined
        this.state.enterTradePricesErr = undefined
        this.state.takeProfitErr = undefined
        this.state.stopLossErr = undefined


        if (!this.isAtLeastOneReceivingSubscriptionSelected(this.state.receivingSubscriptions)) {
            errors = true
            this.setState({
                receivingSubscriptionsErr: "Please select at least one subscription"
            })
        }

        if (!this.state.quote || !isValidPair(this.state.quote, this.state.base, this.props.quoteCurrenciesNom)) {
            errors = true
            this.setState({
                selectPairErr: "Please specify a valid pair"
            })
        }

        if (!this.checkTakeProfitTargetsAreInSuccession(this.state.takeProfitTargets)) {
            errors = true
            if (this.state.futuresType === c.FUTURES_TYPE_LONG) {
                this.setState({
                    takeProfitErr: "Take profit targets are not in ascending succession"
                })
            } else if (this.state.futuresType === c.FUTURES_TYPE_SHORT) {
                this.setState({
                    takeProfitErr: "Take profit targets are not in descending succession"
                })
            }

        }

        if (!this.state.enterTradePriceFrom || !this.state.enterTradePriceTo) {
            errors = true
            this.setState({
                enterTradePricesErr: "Please specify entering prices"
            })
        }

        if (this.state.futuresType === c.FUTURES_TYPE_LONG) {
            if (parseFloat(this.state.enterTradePriceFrom) > parseFloat(this.state.enterTradePriceTo)) {
                errors = true
                this.setState({
                    enterTradePricesErr: "Entering prices are not in ascending succession"
                })
            }
            if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE &&
                parseFloat(this.state.takeProfitTargets[0]) < parseFloat(this.state.enterTradePriceTo)) {
                errors = true
                this.setState({
                    takeProfitErr: "First take profit target is not above the last price"
                })
            }
            if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE &&
                parseFloat(this.state.takeProfitTargets[0]) === 0) {
                errors = true
                this.setState({
                    takeProfitErr: "First take profit target is not above the last price"
                })
            }
        } else if (this.state.futuresType === c.FUTURES_TYPE_SHORT) {
            if (parseFloat(this.state.enterTradePriceFrom) < parseFloat(this.state.enterTradePriceTo)) {
                errors = true
                this.setState({
                    enterTradePricesErr: "Entering prices are not in descending succession"
                })
            }
            if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE &&
                parseFloat(this.state.takeProfitTargets[0]) > parseFloat(this.state.enterTradePriceTo)) {
                errors = true
                this.setState({
                    takeProfitErr: "First take profit target is not below the last price"
                })
            }
            if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE &&
                parseFloat(this.state.takeProfitTargets[0]) === 0) {
                errors = true
                this.setState({
                    takeProfitErr: "First take profit target is not below the last price"
                })
            }
        }

        if (!this.state.signalId) {

            if (this.state.futuresType === c.FUTURES_TYPE_LONG) {
                if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PRICE &&
                    parseFloat(this.state.stopLossTarget) >= parseFloat(this.state.enterTradePriceFrom)) {
                    errors = true
                    this.setState({
                        stopLossErr: "Stop loss target is not below the first price"
                    })
                }
                if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE &&
                    parseFloat(this.state.stopLossTarget) === 0) {
                    errors = true
                    this.setState({
                        stopLossErr: "Stop loss target is not below the first price"
                    })
                }
            } else if (this.state.futuresType === c.FUTURES_TYPE_SHORT) {
                if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PRICE &&
                    parseFloat(this.state.stopLossTarget) <= parseFloat(this.state.enterTradePriceFrom)) {
                    errors = true
                    this.setState({
                        stopLossErr: "Stop loss target is not above the first price"
                    })
                }
                if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE &&
                    parseFloat(this.state.stopLossTarget) === 0) {
                    errors = true
                    this.setState({
                        stopLossErr: "Stop loss target is not above the first price"
                    })
                }
            }
        }

        let _currentPrice
        if (this.state.currentPrice)
            _currentPrice = this.state.currentPrice
        else
            _currentPrice = this.state.currentLastBidPrice

        if (!errors) {
            this.props.preview({
                signalId: this.state.signalId,
                signalType: c.SIGNAL_TYPE_FUTURES,
                futuresType: this.state.futuresType,
                leverage: this.state.leverage,
                quoteAsset: this.state.quote,
                baseAsset: this.state.base,
                enterTradePriceFrom: this.state.enterTradePriceFrom,
                enterTradePriceTo: this.state.enterTradePriceTo,
                precision: this.state.precision,
                tickSize: this.state.tickSize,
                timeFrame: this.state.timeFrame,
                tvChartURL: this.state.tvChartURL,
                stopLossTarget: this.state.stopLossTarget,
                stopLossOption: this.state.stopLossOption,
                takeProfitTargets: this.state.takeProfitTargets,
                takeProfitOption: this.state.takeProfitOption,
                comment: this.state.comment,
                receivingSubscriptions: this.state.receivingSubscriptions,
                potentialProfit: this.state.potentialProfit,
                potentialLoss: this.state.potentialLoss,
                riskRewardRatio: this.state.riskRewardRatio,
                currentPrice: _currentPrice,
            }, () => {
                this.props.history.push("/previewsignal")
            })
        }

    }

    populateStateSignalFromSignal(signal, fromProps) {
        let receivingSubscriptions= {}
        if (signal.receivingSubscriptions) {
            if (fromProps) {
                let rGrpsKeys = Object.keys(signal.receivingSubscriptions)
                for(let i = 0; i < rGrpsKeys.length; i++) {
                    if (signal.receivingSubscriptions[rGrpsKeys[i]])
                        receivingSubscriptions[rGrpsKeys[i]] = true
                }
            } else {
                for(let i = 0; i < signal.receivingSubscriptions.length; i++) {
                    receivingSubscriptions[signal.receivingSubscriptions[i]] = true
                }
            }
        }

        let takeProfitTargets = []
        if (signal.takeProfitTargets) {
            for(let i = 0; i < signal.takeProfitTargets.length; i++) {
                if (fromProps) {
                    takeProfitTargets.push(brushValue(signal.takeProfitTargets[i], signal.tickSize))
                } else {
                    takeProfitTargets.push(brushValue(signal.takeProfitTargets[i].price, signal.tickSize))
                }
            }
        }

        let signalState =
            {
                signalId: (fromProps ? signal.signalId: signal._id),
                futuresType: signal.futuresType,
                leverage: signal.leverage,
                quote: signal.quoteAsset,
                base: signal.baseAsset,
                enterTradePriceFrom: brushValue(signal.enterTradePriceFrom, signal.tickSize),
                enterTradePriceTo: brushValue(signal.enterTradePriceTo, signal.tickSize),
                precision: signal.precision,
                tickSize: signal.tickSize,
                timeFrame: signal.timeFrame,
                tvChartURL: signal.tvChartURL,
                stopLossOption: signal.stopLossOption,
                takeProfitTargets: takeProfitTargets,
                takeProfitOption: signal.takeProfitOption,
                currentLastBidPrice: signal.currentPrice,
                comment: signal.comment,
                receivingSubscriptions: {
                    ...this.state.receivingSubscriptions,
                    ...receivingSubscriptions
                }
            }

        if (signal.stopLossTarget) {
            signalState.stopLossTarget = (fromProps ? brushValue(signal.stopLossTarget): brushValue(signal.stopLossTarget.price, signal.tickSize))
        }

        this.setState(signalState)
    }

    componentDidMount() {
        if (!this.props.baseCurrenciesNom) {
            this.props.getCurrenciesMap()
        }

        this.state.signalId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).signalId

        if (this.state.signalId) {
            this.props.getSignalById({signalId: this.state.signalId}, signal => {
                this.populateStateSignalFromSignal(signal)
                this.props.getCurrentPriceOnPair({quote: signal.quoteAsset, base: signal.baseAsset}, result => {
                    this.props.getRates({symbol: `${signal.quoteAsset}${signal.baseAsset}`, leverage: signal.leverage}, result1 => {
                        let {maxLeverage, maxNotionalValue, initialMarginRate} = result1
                        let currentLastBidPrice = result.current_price
                        this.setState({
                            maxLeverage, maxNotionalValue,
                            initialMarginRate,
                            currentLastBidPrice: currentLastBidPrice ? currentLastBidPrice : 0,
                            tickSize: result.tick_size
                        })
                        this.computeIndicators()
                    })
                })
            })
        } else {
            if (this.props.signal) {
                //If the user cam back from Preview Signal
                this.populateStateSignalFromSignal(this.props.signal, true)
            } else {
                this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, result => {
                    this.props.getRates({symbol: `${this.state.quote}${this.state.base}`, leverage: this.state.leverage}, result1 => {
                        let {maxLeverage, maxNotionalValue, initialMarginRate} = result1
                        let currentLastBidPrice = result.current_price
                        this.setState({
                            maxLeverage, maxNotionalValue,
                            initialMarginRate,
                            currentLastBidPrice: currentLastBidPrice ? currentLastBidPrice : 0,
                            enterTradePriceFrom: currentLastBidPrice ? brushValue(currentLastBidPrice, result.tick_size) : 0,
                            enterTradePriceTo: currentLastBidPrice ? brushValue(currentLastBidPrice * (1 + this.getPriceIntervalPercentage()), result.tick_size) : 0,
                            precision: result.precision,
                            tickSize: result.tick_size,
                            takeProfitOption: c.TAKE_PROFIT_OPTION_PERCENTAGE,
                            takeProfitTargets: [200],
                            stopLossOption: c.STOP_LOSS_OPTION_PERCENTAGE,
                            stopLossTarget: 100,
                        })
                        this.computeIndicators()
                    })
                })
            }
        }

        this.props.getTradersSubscriptions()

    }

    render() {
        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <div style={{display: "flex"}}>
                <div style={{display: "flex", justifyContent: "flex-start", width: "7em", cursor: "pointer", marginTop: "0.05rem"}}
                     onClick={()=>{this.props.history.push("/signals")}} >
                    <KeyboardBackArrow/>
                    <div className={"pageTitle"} style={{marginLeft: "1rem"}}>Back</div>
                </div>
            </div>
            <NewSignalFormV1Futures
                open={true}
                signalId={this.state.signalId}
                handleCancel={this.cancel}
                handlePreview={this.previewSignal}

                quote={this.state.quote}
                base={this.state.base}

                isAdjustLeverageOpen={this.state.isAdjustLeverageOpen}
                adjustLeverage={this.adjustLeverage}
                cancelAdjustLeverage={this.cancelAdjustLeverage}
                confirmNewLeverage={this.confirmNewLeverage}
                leverage={this.state.leverage}
                maxLeverage={this.state.maxLeverage}
                handleChangeLeverage={this.handleChangeLeverage}
                leverageSlider={this.state.leverageSlider}
                handleChangeLeverageSlider={this.handleChangeLeverageSlider}
                increaseLeverage={this.increaseLeverage}
                decreaseLeverage={this.decreaseLeverage}

                futuresType={this.state.futuresType}
                handleChangeFuturesType={this.handleChangeFuturesType}

                quoteCurrenciesNom={this.props.quoteCurrenciesNom}
                handleChangeQuote={this.handleChangeQuote}
                baseCurrenciesNom={this.props.baseCurrenciesNom}
                handleChangeBase={this.handleChangeBase}

                reFetchCurrentPrice={this.reFetchCurrentPrice}
                currentPrice={this.state.currentLastBidPrice}
                delta={this.state.delta}
                enterTradePriceFrom={this.state.enterTradePriceFrom}
                handleChangeEnterTradePriceFrom={this.handleChangeEnterTradePriceFrom}
                enterTradePriceTo={this.state.enterTradePriceTo}
                handleChangeEnterTradePriceTo={this.handleChangeEnterTradePriceTo}
                enterTradePricesErr={this.state.enterTradePricesErr}
                showWarningForEntryPrice={this.state.showWarningForEntryPrice}
                stepFrom={this.stepFrom}

                stopLossTarget={this.state.stopLossTarget}
                handleChangeStopLossTarget={this.handleChangeStopLossTarget}
                stopLossOption={this.state.stopLossOption}
                handleChangeStopLossOption={this.handleChangeStopLossOption}
                switchWithOrWithoutStopLossTarget={this.switchWithOrWithoutStopLossTarget}
                stopLossErr={this.state.stopLossErr}

                takeProfitTargets={this.state.takeProfitTargets}
                handleChangeTakeProfitTarget={this.handleChangeTakeProfitTarget}
                takeProfitOption={this.state.takeProfitOption}
                handleChangeTakeProfitOption={this.handleChangeTakeProfitOption}
                addTakeProfitTarget={this.addTakeProfitTarget}
                removeTakeProfitTarget={this.removeTakeProfitTarget}
                takeProfitErr={this.state.takeProfitErr}

                potentialProfit={this.state.potentialProfit}
                potentialLoss={this.state.potentialLoss}
                riskRewardRatio={this.state.riskRewardRatio}
                AVERAGE_BTC_POS_SIZE={AVERAGE_BTC_POS_SIZE}

                comment={this.state.comment}
                handleChangeComment={this.handleChangeComment}
                receivingSubscriptions={this.state.receivingSubscriptions}
                handleChangeReceivingSubscriptions={this.handleChangeReceivingSubscriptions}
                receivingSubscriptionsErr={this.state.receivingSubscriptionsErr}
                subscriptions={this.props.subscriptions}
            />
        </div>

    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    baseCurrenciesNom: rootState.newFutures.baseCurrenciesNom,
    quoteCurrenciesNom: rootState.newFutures.quoteCurrenciesNom,
    signal: rootState.signals.signal,
    subscriptions: rootState.traders.subscriptions
})

let mapDispatchToProps = dispatch => {
    return {
        getCurrenciesMap: (props) => dispatch(getCurrenciesMap(props)),
        getSymbolPrecisionAndTickSize: (props, cb) => dispatch(getSymbolPrecisionAndTickSize(props, cb)),
        getCurrentPriceOnPair: (props, cb) => dispatch(getCurrentPriceOnPair(props, cb)),
        getSignalById: (props, cb) => dispatch(getSignalByIdV1(props, cb)),
        getTradersSubscriptions: (props) => dispatch(getTradersSubscriptions(props)),
        checkEnterTradeInterval: (props, cb) => dispatch(checkEnterTradeIntervalFutures(props, cb)),
        preview: (props, cb) => dispatch(previewV1(props, cb)),
        clearCurrentSignal: (cb) => dispatch(clearCurrentSignal(cb)),
        getRates: (props, cb) => dispatch(getRates(props, cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (NewOrUpdateSignalV1Futures)
