import React, {Component} from 'react'
import { connect } from 'react-redux'
import NavTabs from '../../fncomponents/NavTabs'
import {getTitle} from "../../fncomponents/PageTitleUtils";
import {Helmet} from "react-helmet/es/Helmet";
import {changeTab, getSaleProgress} from "../../../redux/actions/investment";
import ActiveInvestmentsTab from "./ActiveInvestmentsTab";
import MyInvestmentPurchasesTab from "./MyInvestmentPurchasesTab";




class InvestmentSection extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedTab: 0,
            iPIdParent: undefined,
        }

        this.changeTab = this.changeTab.bind(this)
    }

    changeTab(event, newValue) {
        console.log("changeTab", newValue)
        if (this.props.selectedTab === newValue)
            return
        this.props.changeTab(newValue)
    }

    render() {
        let tabs = []
        tabs.push({title: "Programs", content: <ActiveInvestmentsTab
                location={this.props.location}
                history={this.props.history}
            />})
        tabs.push({title: "Contributions", content: <MyInvestmentPurchasesTab
                changeTabToPrograms={() => this.changeTab({}, 0)}
                history={this.props.history}/>})

        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <NavTabs tabs={tabs} selectedTab={this.props.selectedTab}
                     changeTab={this.changeTab}
                     tabContainerClass={"tabsContainer"}/>
        </div>
    }
}

const mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    selectedTab: rootState.investment.selectedTab,
})

const mapDispatchToProps = dispatch => {
    return {
        changeTab: (tab) => dispatch(changeTab(tab))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (InvestmentSection)
