module.exports = {
    "BTC": "Bitcoin",
    "BTCUP": "Long Bitcoin",
    "BTCDOWN": "Short Bitcoin",
    "USDT": "TetherUS",
    "ETH": "Ethereum",
    "ETHUP": "Long Ethereum",
    "ETHDOWN": "Short Ethereum",
    "BNB": "Binance Coin",
    "BNBUP": "Long Binance Coin",
    "BNBDOWN": "Short Binance Coin",
    "ZIL": "Zilliqa",
    "XMR": "Monero",
    "HC": "HyperCash",
    "HIVE": "Hive",
    "BRD": "Bread",
    "BAT": "Basic Attention Token",
    "OST": "OST",
    "VIA": "Viacoin",
    "VIB": "Viberate",
    "NCASH": "Nucleus Vision",
    "POLY": "Polymath",
    "GO": "GoChain",
    "BQX": "ETHOS",
    "EOS": "EOS",
    "EOSUP": "Long EOS",
    "EOSDOWN": "Short EOS",
    "EOP": "EOSpace",
    "EON": "EOS Network",
    "LUN": "Lunyr",
    "AGI": "SingularityNET",
    "MOD": "Modum",
    "USDSB": "StableUSD BEP2",
    "QTUM": "Qtum",
    "MFT": "Mainframe",
    "LINK": "ChainLink",
    "LINKUP": "Long ChainLink",
    "LINKDOWN": "Short ChainLink",
    "SBTC": "Super Bitcoin",
    "XLM": "Stellar Lumens",
    "LLT": "LLToken",
    "CBM": "Crypto Bonus Miles Token",
    "INS": "Insolar",
    "DLT": "Agrello",
    "LEND": "Aave",
    "TFUEL": "Theta Fuel",
    "BCHABC": "Bitcoin Cash ABC",
    "GRS": "Groestlcoin",
    "NXS": "Nexus",
    "OAX": "openANX",
    "EVX": "Everex",
    "WAN": "Wanchain",
    "ENJ": "Enjin Coin",
    "THETA": "Theta Token",
    "ENG": "Enigma",
    // "STRAT": "Stratis",
    "STRAX": "Stratis",
    "USDC": "USD Coin",
    "LTC": "Litecoin",
    "LTCUP": "Long Litecoin",
    "LTCDOWN": "Short Litecoin",
    "WABI": "TAEL",
    "GAS": "NeoGas",
    "TUSD": "TrueUSD",
    "DATA": "Streamr DATAcoin",
    "DCR": "Decred",
    "SYS": "Syscoin",
    "NULS": "Nuls",
    "HCC": "HealthCare Chain",
    "LSK": "Lisk",
    "TRX": "TRON",
    "TRXUP": "Long TRON",
    "TRXDOWN": "Short TRON",
    "WPR": "WePower",
    "FUEL": "Etherparty",
    "XRP": "Ripple",
    "XRPUP": "Long Ripple",
    "XRPDOWN": "Short Ripple",
    "AMB": "Ambrosus",
    "ADX": "AdEx",
    "WAVES": "Waves",
    "EDO": "Eidoo",
    "NANO": "NANO",
    "DASH": "Dash",
    "DUSK": "Dusk",
    "RVN": "Ravencoin",
    "MDA": "Moeda Loyalty Points",
    "USDS": "StableUSD",
    "QLC": "QLC Chain",
    "BNT": "Bancor",
    "PAX": "Paxos Standard",
    "YOYO": "YOYOW",
    "VET": "VeChain",
    "MTL": "Metal",
    "GXS": "GXChain",
    "ZEN": "Horizen",
    "LOOM": "Loom Network",
    "ADD": "ADD",
    "XZC": "Zcoin",
    "MTH": "Monetha",
    "ICX": "ICON",
    "STORJ": "Storj",
    "REQ": "Request Network",
    "ELF": "aelf",
    "ADA": "Cardano",
    "ADAUP": "Long Cardano",
    "ADADOWN": "Short Cardano",
    "APPC": "AppCoins",
    "REP": "Augur",
    "LRC": "Loopring",
    "REN": "Ren",
    "ZEC": "Zcash",
    "NEO": "NEO",
    "MCO": "Crypto.com",
    "CELR": "Celer Network",
    "ETF": "ETF",
    "ETC": "Ethereum Classic",
    "ICN": "ICONOMI",
    "FET": "Fetch.AI",
    "ATD": "Atidium",
    "STORM": "Storm",
    "SALT": "Salt",
    "QSP": "Quantstamp",
    "MEETONE": "MEETONE",
    "VTHO": "VTHO",
    "QKC": "QuarkChain",
    "NEBL": "Neblio",
    "SNGLS": "Breaker",
    "MANA": "Decentraland",
    "CLOAK": "CloakCoin",
    "AST": "AirSwap",
    "ARDR": "Ardor",
    "PIVX": "PIVX",
    "FUN": "FunFair",
    "RDN": "Raiden Network Token",
    "SNT": "Status",
    "PPT": "Populous",
    "ONT": "Ontology",
    "SNM": "SONM",
    "BLZ": "Bluzelle",
    "SC": "Siacoin",
    "ATOM": "Cosmos",
    "ALGO": "Algorand",
    "GNT": "Golem",
    "PHB": "Red Pulse Phoenix Binance",
    "ONG": "Ontology Gas",
    "RLC": "iExecRLC",
    "ONE": "Harmony",
    "CHAT": "ChatCoin",
    "WINGS": "WINGS",
    "GVT": "Genesis Vision",
    "BTT": "BitTorrent",
    "KNC": "KyberNetwork",
    "BTS": "BitShares",
    "POWR": "PowerLedger",
    "KEY": "Selfkey",
    "FTM": "Fantom",
    "ARN": "Aeron",
    "RCN": "Ripio Credit Network",
    "TRIG": "Triggers",
    "ARK": "Ark",
    "BTM": "Bytom",
    "CND": "Cindicator",
    "BCX": "BitcoinX",
    "MITH": "Mithril",
    "BTG": "Bitcoin Gold",
    "CVC": "Civic",
    "IOTA": "MIOTA",
    "BTCB": "Bitcoin BEP2",
    "HOT": "Holo",
    "CMT": "CyberMiles",
    "IQ": "Everipedia",
    "OMG": "OmiseGO",
    "POE": "Po.et",
    "IOST": "Internet of Services",
    "AE": "Aeternity",
    "POA": "POA Network",
    "KMD": "Komodo",
    "BCD": "Bitcoin Diamond",
    "SUB": "Substratum",
    "ZRX": "0x",
    "DGD": "DigixDAO",
    "VIBE": "VIBE",
    "TNT": "Tierion",
    "BCPT": "BlockMason Credit Protocol",
    "NPXS": "Pundi X",
    "AION": "AION",
    "DENT": "DENT",
    "STEEM": "Steem",
    "DOCK": "DOCK",
    "CDT": "Blox",
    "DNT": "district0x",
    "BCHSV": "Bitcoin Cash SV",
    "TNB": "Time New Bank",
    "IOTX": "IoTeX",
    "XVG": "Verge",
    "WTC": "Walton",
    "GTO": "Gifto",
    "NAV": "NAV Coin",
    "MATIC": "MATIC Network",
    "NAS": "Nebulas",
    "XEM": "NEM",
    "SKY": "Skycoin",
    "CTR": "Centra",
    "XTZ": "Tezos",
    "XTZUP": "Long Tezos",
    "XTZDOWN": "Short Tezos",
    "DOT": "DOT",
    "DOTUP": "Long DOT",
    "DOTDOWN": "Short DOT",
    "UNI": "UNI",
    "UNIUP": "Long UNI",
    "UNIDOWN": "Short UNI",
    "KAVA": "Kava",
    "RSR": "Reserve Rights",
    "BCC": "BCC",
    "HSR": "HSR",
    "VEN": "VEN",
    "RPX": "RPX",
    "BCN": "BCN",
    "PHX": "PHX",
    "ERD": "ERD",
    "DOGE": "DOGE",
    "BGBP": "BGBP",
    "ANKR": "ANKR",
    "WIN": "WIN",
    "COS": "COS",
    "TUSDB": "TUSDB",
    "COCOS": "COCOS",
    "TOMO": "TOMO",
    "PERL": "PERL",
    "CHZ": "CHZ",
    "BAND": "BAND",
    "BUSD": "BUSD",
    "BEAM": "BEAM",
    "HBAR": "HBAR",
    "NKN": "NKN",
    "STX": "STX",
    "ARPA": "ARPA",
    "CTXC": "CTXC",
    "BCH": "BCH",
    "TROY": "TROY",
    "VITE": "VITE",
    "FTT": "FTT",
    "EUR": "EUR",
    "OGN": "OGN",
    "DREP": "DREP",
    "BULL": "BULL",
    "BEAR": "BEAR",
    "ETHBULL": "ETHBULL",
    "ETHBEAR": "ETHBEAR",
    "TCT": "TCT",
    "WRX": "WRX",
    "LTO": "LTO",
    "EOSBULL": "EOSBULL",
    "EOSBEAR": "EOSBEAR",
    "XRPBULL": "XRPBULL",
    "XRPBEAR": "XRPBEAR",
    "MBL": "MBL",
    "COTI": "COTI",
    "BNBBULL": "BNBBULL",
    "BNBBEAR": "BNBBEAR",
    "STPT": "STPT",
    "SOL": "SOL",
    "CTSI": "CTSI",
    "CHR": "CHR",
    "MDT": "MDT",
    "STMX": "STMX",
    "PNT": "PNT",
    "GBP": "GBP",
    "DGB": "DGB",
    "COMP": "COMP",
    "BKRW": "BKRW",
    "SXP": "SXP",
    "SNX": "SNX",
    "IRIS": "IRIS",
    "MKR": "MKR",
    "DAI": "DAI",
    "RUNE": "RUNE",
    "AUD": "AUD",
    "FIO": "FIO",
    "AVA": "AVA",
    "BAL": "BAL",
    "YFI": "YFI",
    "JST": "JST",
    "SRM": "SRM",
    "ANT": "ANT",
    "CRV": "CRV",
    "SAND": "SAND",
    "OCEAN": "OCEAN",
    "NMR": "NMR",
    "LUNA": "LUNA",
    "IDEX": "IDEX",
    "PAXG": "PAXG",
    "WNXM": "WNXM",
    "TRB": "TRB",
    "BZRX": "BZRX",
    "WBTC": "WBTC",
    "SUSHI": "SUSHI",
    "YFII": "YFII",
    "KSM": "KSM",
    "EGLD": "EGLD",
    "DIA": "DIA",
    "UMA": "UMA",
    "BEL": "BEL",
    "WING": "WING",
    "SWRV": "SWRV",
    "CREAM": "CREAM",
    "NBS": "NBS",
    "OXT": "OXT",
    "SUN": "SUN",
    "AVAX": "AVAX",
    "HNT": "HNT",
    "BAKE": "BAKE",
    "BURGER": "BURGER",
    "FLM": "FLM",
    "SCRT": "SCRT",
    "CAKE": "CAKE",
    "SPARTA": "SPARTA",
    "ORN": "ORN",
    "UTK": "UTK",
    "XVS": "XVS",
    "ALPHA": "ALPHA",
    "VIDT": "VIDT",
    "AAVE": "AAVE",
    "NEAR": "NEAR",
    "SXPUP": "SXPUP",
    "SXPDOWN": "SXPDOWN",
    "FIL": "FIL",
    "FILUP": "FILUP",
    "FILDOWN": "FILDOWN",
    "YFIUP": "YFIUP",
    "YFIDOWN": "YFIDOWN",
    "INJ": "INJ",
    "AERGO": "AERGO",
    "EASY": "EASY",
    "AUDIO": "AUDIO",
    "AMP": "AMP",
    "1INCH": "1 INCH",
    "SNP": "Network"
}
