import React from 'react'
import {Facebook, Telegram, Http, Email, Twitter} from '@material-ui/icons'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import Rating from '@material-ui/lab/Rating'
import Avatar from '@material-ui/core/Avatar'
import {timeFormatter2} from "./UISupportFns";


const StyledRating = withStyles({
    iconFilled: {
        color: '#D79600',
    }
})(Rating);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    }
}));

export default function StakingProgram(props) {
    const classes = useStyles();
    const {program} = props
    if (!program)
        return null
    return (
        <div style={{marginTop: "1rem", marginBottom: "2rem"}}>
            <div style={{fontSize: "2rem", fontWeight: "bold"}}>Stake {program.coin}</div>
            <div className={"smallExplanations"} style={{"marginLeft": "10rem", "marginTop": "-20px"}}>created on {timeFormatter2(program.creationDate)}</div>
            {program.description && <div>{program.description}</div>}
            <div style={{marginTop: "0.6rem", marginBottom: "0.6rem"}}>Current yearly reward <span style={{fontSize: "1.3rem", fontWeight: "bold"}}>{program.rewardPercent}</span></div>
            {program.terms.map(t => {
                return <div style={{fontSize: "0.76rem", opacity: "0.8"}}>{t}</div>
            })}
        </div>
    )
}
