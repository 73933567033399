import {
    CHANGE_SIGNAL_STATE_AFTER_BUYING_SUBSCRIPTION,
    CONFIRM_SIGNUP,
    DISABLE_2FA,
    ENABLE_2FA, LATE_ACTIVATION,
    LOGIN,
    LOGOUT, REGISTER_WHA_LINK,
    SIGNUP, TRADERS_USER_UNSUBSCRIBES_BY_REMOVAL, UPDATE_USER_BASED_ON_JWT_TOKEN
} from "../actions/actionTypes";

export default function (state = {user: undefined}, action) {
    switch (action.type) {

        case LOGIN: {
            return {
                ...state,
                ...{user: action.payload, logout: false}
            }
        }

        case UPDATE_USER_BASED_ON_JWT_TOKEN: {
            let updatedUser = { ...action.payload.decodedToken,
                ...action.payload.subscriptions}
            return {
                ...state,
                ...{user: updatedUser, logout: false}
            }
        }

        case LOGOUT: {
            return {
                ...state,
                ...{user: null, logout: true}}
        }

        case SIGNUP: {
            let newState = {...state, ...{signupSuccess: true}}
            return newState
        }

        case CONFIRM_SIGNUP: {
            let newState = {...state, ...{signupConfirmSuccess: true}}
            return newState
        }

        case LATE_ACTIVATION: {
            return {...state, ...{lateActivationCode: action.payload.code, lateActivationUserEmail : action.payload.email}}
        }

        case ENABLE_2FA: {
            return {
                ...state,
                ...{
                    user: {...state.user, ...{twoFAEnabled: true}}
                }
            }
        }

        case DISABLE_2FA: {
            return {
                ...state,
                ...{
                    user: {...state.user, ...{twoFAEnabled: false}}
                }
            }
        }

        case CHANGE_SIGNAL_STATE_AFTER_BUYING_SUBSCRIPTION: {
            return {
                ...state,
                ...{
                    user: {
                        ...state.user,
                        ...{
                            signalsState: action.payload.userSignalsState,
                            subscriptionsMap: {
                                ...state.user.subscriptionsMap,
                                ...{[action.payload.subscription._id]: true}
                            }
                        }
                    }
                }
            }
        }

        case TRADERS_USER_UNSUBSCRIBES_BY_REMOVAL: {
            let newState = {...state, ...{}}
            delete newState.user.subscriptionsMap[action.payload._id]
            return newState
        }

        default:
            return state
    }
}
