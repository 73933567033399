import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles, TextField} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 320,
        fontSize: "0.6rem"
    },
}));

export default function ImportSignalFromXMLDialog(props) {
    const classes = useStyles()
    const {open,
        xmlSignal, xmlSignalError, handleChangeXMLSignal, handleCancel, handleImport, handleReset
        } = props

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Import Signal from XML</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <TextField
                        id="standard-textarea"
                        label="XML"
                        placeholder="XML content here..."
                        multiline
                        className={classes.textField}
                        margin="normal"
                        value={xmlSignal}
                        error={xmlSignalError}
                        label={xmlSignalError}
                        onChange={handleChangeXMLSignal}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleImport} color="primary" variant="contained">
                    Import
                </Button>
                <Button onClick={handleReset} color="primary">
                    Reset
                </Button>
                <Button onClick={handleCancel} color="primary" autoFocus>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
