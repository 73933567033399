import React from "react"
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {TextField, Button } from "@material-ui/core"
import InputAdornment from "@material-ui/core/InputAdornment"
import c from '../../constants'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputBase from '@material-ui/core/InputBase'

const useStyles = makeStyles(theme => ({

    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(0),
        width: 280,
        backgroundColor: '#101A26'
    },
    button: {
        minWidth: 290,
        marginRight: 10,
        marginTop: 10,
        borderRadius: 4,
        height: 50
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    }
}))

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        // borderRadius: 4,
        position: 'relative',
//        backgroundColor: theme.palette.background.paper,
        //border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            // borderRadius: 4,
            //borderColor: '#80bdff',
            //boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const currencies = [
    {
        value: 'USD',
        label: 'USD',
    },
    {
        value: 'EUR',
        label: 'EUR',
    },
    {
        value: 'LEI',
        label: 'LEI',
    }
];

const cryptos = [
    {
        value: 'BTC',
        label: 'BTC',
    },
    {
        value: 'ETH',
        label: 'ETH',
    }
];

export default function BuyCryptoStep1(props) {
    let {
        fiatAmount, handleChangeFiatAmount,
        fees,
        currency, handleChangeCurrency,
        paymentMethod, handleChangePaymentMethod,
        crypto, handleChangeCrypto,
        cryptoAmount,
        userWallets,
    } = props
    const classes = useStyles()

    function getPaymentMethodClass(pm) {
        if (paymentMethod === pm) {
            return "buyCryptoPaymentMethod buyCryptoPaymentMethodSelected"
        } else {
            return "buyCryptoPaymentMethod"
        }
    }

    function getOnOfCircle(pm) {
        if (paymentMethod === pm) {
            return <span className={"selectedPaymentMethodCircle"}>◉</span>
        } else {
            return <span className={"unselectedPaymentMethodCircle"}>○ </span>
        }
    }

    return (<div className={"wizardSectionContainer"}>
        <div>
            <TextField
                value={fiatAmount}
                onChange={handleChangeFiatAmount}
                label={"You pay"}
                variant="filled"
                className={classes.textField}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <Select
                            value={currency}
                            onChange={handleChangeCurrency}
                            input={<BootstrapInput />}
                        >
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </InputAdornment>
                }}
            />

        </div>
        <div style={{marginLeft: "1rem"}}>
            <div style={{marginTop: "0.6rem", fontSize: "0.9rem"}}>Using payment method</div>
            <div className={getPaymentMethodClass(c.BUY_CRYPTO_PAYMENT_METHOD_SEPA)}
                onClick={() => {handleChangePaymentMethod(c.BUY_CRYPTO_PAYMENT_METHOD_SEPA)}}>
                {getOnOfCircle(c.BUY_CRYPTO_PAYMENT_METHOD_SEPA)}
                <img src={"imgs/payments/sepaWhite.png"} height={12} alt={"Bank Transfer"}/> Bank Transfer</div>
            <div className={getPaymentMethodClass(c.BUY_CRYPTO_PAYMENT_METHOD_DEBIT_CARD)}
                 style={{display: "flex"}}
                onClick={() => {handleChangePaymentMethod(c.BUY_CRYPTO_PAYMENT_METHOD_DEBIT_CARD)}}>
                <div>{getOnOfCircle(c.BUY_CRYPTO_PAYMENT_METHOD_DEBIT_CARD)}</div>
                <div style={{marginTop: "-0.4rem", marginRight: "0.6rem"}}><img src={"imgs/payments/visa_master_d.png"} height={30} alt={"Visa Master"}/></div>
                <div>Debit Card</div>
            </div>
        </div>
        <div style={{marginLeft: "1rem"}}>
            <div style={{marginTop: "1rem", fontSize: "0.9rem"}}>{fees} {currency} <span className={"buyCryptoSmallHelp"}>Total fees</span></div>
        </div>
        <div style={{marginLeft: "1rem"}}>
            <div style={{marginTop: "1rem", fontSize: "0.9rem"}}>8480.81 {currency} = 1 {crypto} <span className={"buyCryptoSmallHelp"}
            title={"The floating rate shown here is an estimate and the rate you receive will be determined at the time when your payment is settled. The time taken for your payment can vary depending on currency and bank."}>Rate</span></div>
        </div>
        <div style={{marginTop: "1rem"}}>
            <TextField
                value={cryptoAmount}
                onChange={handleChangeFiatAmount}
                label={"You receive (estimate)"}
                variant="filled"
                className={classes.textField}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <Select
                            value={crypto}
                            onChange={handleChangeCrypto}
                            input={<BootstrapInput />}
                        >
                            {cryptos.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </InputAdornment>
                }}
            />
        </div>
        <div style={{marginTop: "1rem", marginLeft: "0.6rem"}}>
            <div className={"buyCryptoSmallHelp"}>Into your <b>Venbot</b> {crypto} wallet</div>
            <div className={"buyCryptoMediumHelpBox"} style={{width: "19rem", marginTop: "0.6rem"}}>{userWallets[crypto]}</div>
        </div>
        <div style={{display: "flex", marginTop: "1rem", marginLeft: "0.6rem"}}>
            <div title={"Our price as compared to market price"} className={"buyCryptoSmallHelpBox"}>
                Slippage -0.6%
            </div>
            <div style={{width: "0.6rem"}}/>
            <div className={"buyCryptoSmallHelpBox"} style={{width: "8rem"}}>
                Processing time 1-2 days
            </div>
        </div>
        <div style={{marginTop: "3rem"}}>
            <Button variant="contained" color="primary" className={classes.button}>
                Buy Now
            </Button>
        </div>
    </div>)
}
