import React, {Component} from 'react'
import { connect } from 'react-redux'
import TransactionsTable from './TransactionsTable'
import {debounce} from "../../../hoc/myUtils";
import {getTransactionsByPage, getTransactionsInitial} from "../../../../redux/actions/wallet";
import {FuturesTransactionsCustomHeader} from "../../../fncomponents/CustomHeaders";

class Transactions extends Component {

    constructor(props) {
        super(props)
        this.state = {
            rows: [],
            page: 0,
            rowsPerPage: 10,
            filtersMap: {},
            sort: {},
        }

        let {changeRenderCustomHeader} = props
        this.changeRenderCustomHeader = changeRenderCustomHeader
        this.setRowsPerPage = this.setRowsPerPage.bind(this)
        this.setPage = this.setPage.bind(this)
        this.onFilterChange = this.onFilterChange.bind(this)
        this.onFilter = this.onFilter.bind(this)
        this.debouncedFilter = debounce(this.onFilter, 500)

    }

    setRowsPerPage (value) {
        this.state.rowsPerPage = value
        this.state.page = 0
        this.props.getTransactionsInitial({filters: this.state.filtersMap,
            sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
    }

    setPage (value) {
        let page = value
        if (!this.props.pagesDownloaded[page+1]) {
            this.state.page = page
            this.props.getTransactionsByPage({filters: this.state.filtersMap,
                sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
        } else {
            this.setState({
                page: page
            })
        }
    }

    onFilterChange(e) {
        let filterEntry = {[e.target.name]: e.target.value}
        this.setState({
            filtersMap: {...this.state.filtersMap, ...filterEntry}
        })
        this.debouncedFilter()
    }

    onFilter() {
        this.props.getTransactionsInitial({filters: this.state.filtersMap,
            sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
    }

    componentDidMount() {
        this.props.getTransactionsInitial({filters: this.state.filtersMap,
            sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage})
        this.changeRenderCustomHeader(() => <FuturesTransactionsCustomHeader />);
    }


    render() {
        let {docs, totalDocs} = this.props

        return <div>
            <TransactionsTable
                rows={docs}
                rowsPerPage={this.state.rowsPerPage}
                setRowsPerPage={this.setRowsPerPage}
                page={this.state.page}
                setPage={this.setPage}
                totalDocs={totalDocs ? totalDocs : 0}
                filtersMap={this.state.filtersMap}
                onFilterChange={this.onFilterChange}
            />
        </div>
    }
}

const mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    docs: rootState.wallet.transactions.docs,
    totalDocs: rootState.wallet.transactions.totalDocs,
    totalPages: rootState.wallet.transactions.totalPages,
    pagesDownloaded: rootState.wallet.transactions.pagesDownloaded,
})

const mapDispatchToProps = dispatch => {
    return {
        getTransactionsInitial: (props) => dispatch(getTransactionsInitial(props)),
        getTransactionsByPage: (props) => dispatch(getTransactionsByPage(props)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (Transactions)
