import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {TextField} from "@material-ui/core";

export default function AntiPhishingCodeDialog(props) {

    const {open, handleSet, handleRemove, handleCancel, antiPhishingCode, handleChangeAntiPhishingCode, remove} = props

    return (
            <Dialog
                open={open}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {remove && <span>Remove Anti-Phishing code</span>}
                    {!remove && <span>Configure an Anti-Phishing code</span>}
                </DialogTitle>
                <DialogContent>
                    {!remove && <DialogContentText id="alert-dialog-description">
                        <div>Code:</div>
                        <div>
                            <TextField name={"rejectReason"} value={antiPhishingCode} onChange={handleChangeAntiPhishingCode}/>
                        </div>
                    </DialogContentText>}
                    {remove && <DialogContentText id="alert-dialog-description">
                        <div>Are you sure you want to remove your Anti-Phishing code?</div>
                    </DialogContentText>}
                </DialogContent>
                <DialogActions>
                    {!remove && <Button onClick={handleSet} color="primary">
                        Set
                    </Button>}
                    {remove && <Button onClick={handleRemove} color="primary">
                        Remove
                    </Button>}
                    <Button onClick={handleCancel} color="primary" autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
    );
}
