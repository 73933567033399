let JWT_KEY = "jwt"
let REFRESH_TOKEN_KEY = "rjwt"
let saveJWT = (token) => {
    sessionStorage.setItem(JWT_KEY, token)
}

let removeJWT = () => {
    sessionStorage.removeItem(JWT_KEY)
}

let saveRJWT = (token) => {
    sessionStorage.setItem(REFRESH_TOKEN_KEY, token)
}

let removeRJWT = () => {
    sessionStorage.removeItem(REFRESH_TOKEN_KEY)
}

let getJWT = () => {
    return sessionStorage.getItem(JWT_KEY)
}

let getRJWT = () => {
    return sessionStorage.getItem(REFRESH_TOKEN_KEY)
}

let saveJWTOnAxios = (token, axios) => {
    if (!axios) {
        console.error("axios is undefined")
        return
    }
    axios.defaults.headers.common['Authorization'] = token
}

let removeJWTFromAxios = (axios) => {
    if (!axios)
        return
    delete axios.defaults.headers.common['Authorization']
}


export {saveJWT, removeJWT, saveRJWT, removeRJWT, saveJWTOnAxios, removeJWTFromAxios, getJWT, getRJWT}
