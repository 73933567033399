import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import {connect} from "react-redux"
import {Helmet} from "react-helmet/es/Helmet"
import {getTitle} from "../../fncomponents/PageTitleUtils"
import Button from "@material-ui/core/Button"
import {ChevronLeft, Info} from "@material-ui/icons"
import {debounce} from "../../hoc/myUtils"
import {isUserSuperAdmin} from "../../../redux/actions/authentication"
import {getTrader, getTradersByPage, getTradersInitial} from "../../../redux/actions/traders"
import TraderBox2 from "../../fncomponents/TraderBox2"
import c from '../../../constants'
import {Paper, Typography} from "@material-ui/core"


const qs = require("query-string")

class Traders extends Component {

    constructor(props) {
        super(props)
        this.state = {
            rows: [],
            page: 0,
            rowsPerPage: 20,
            filtersMap: {},
            sort: {}
        }

        this.setRowsPerPage = this.setRowsPerPage.bind(this)
        this.setPage = this.setPage.bind(this)
        this.onFilterChange = this.onFilterChange.bind(this)
        this.onFilter = this.onFilter.bind(this)
        this.debouncedFilter = debounce(this.onFilter, 500)

        this.openTrader = this.openTrader.bind(this)

    }

    setRowsPerPage (value) {
        this.state.rowsPerPage = value
        this.state.page = 0
        this.props.getTradersInitial({filters: this.state.filtersMap,
            sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage,
            isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
    }

    setPage (value) {
        let page = value
        if (!this.props.pagesDownloaded[page+1]) {
            this.state.page = page
            this.props.getTradersByPage({filters: this.state.filtersMap,
                sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage,
                isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
        } else {
            this.setState({
                page: page
            })
        }
    }

    onFilterChange(e) {
        let filterEntry = {[e.target.name]: e.target.value}
        this.setState({
            filtersMap: {...this.state.filtersMap, ...filterEntry}
        })
        this.debouncedFilter()
    }

    onFilter() {
        this.props.getTradersInitial({filters: this.state.filtersMap,
            sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage,
            isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
    }

    componentDidMount() {
        this.state.from = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).f

        this.props.getTradersInitial({filters: this.state.filtersMap,
            sort: this.state.sort, page: this.state.page, rowsPerPage: this.state.rowsPerPage,
            isUserSuperAdmin: isUserSuperAdmin(this.props.user)})
    }

    openTrader(_id) {
        this.props.history.push(`/trader?tid=${_id}`)
    }

    render() {
        let {user} = this.props
        if (!user)
            return <Redirect to="/login"/>

        let {traders} = this.props
        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            {user.signalsState === c.USER_SIGNALS_STATE_RECEIVING && <Button onClick={()=>{this.props.history.push("/sucs")}} color="primary" variant="outlined"><ChevronLeft/> Signals</Button>}
            {user.signalsState !== c.USER_SIGNALS_STATE_RECEIVING && <div className={"helpTextContainer"}>
                <Paper style={{padding: "1rem"}} className={"helpTextContainer"}>
                    <div style={{display: "flex"}}  className={"helpText"}>
                        <div style={{marginRight: "0.6rem"}}>
                            <Info/>
                        </div>
                        <Typography>
                            In order to start receiving trading signals, please follow one of the traders listed below.
                        </Typography>
                    </div>

                </Paper>

            </div>}
            <div>
                {traders.map((elem, index) => {
                    return <TraderBox2 trader={elem} open={this.openTrader} key={Math.random()}/>
                })}
            </div>
        </div>
    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    traders: rootState.traders.docs,
    totalDocs: rootState.traders.totalDocs,
    totalPages: rootState.traders.totalPages,
    pagesDownloaded: rootState.traders.pagesDownloaded,
    totals: rootState.traders.totals,
})

let mapDispatchToProps = dispatch => {
    return {
        getTradersInitial: (props) => dispatch(getTradersInitial(props)),
        getTradersByPage: (props) => dispatch(getTradersByPage(props)),
        getTrader: (props, cb) => dispatch(getTrader(props, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (Traders)
