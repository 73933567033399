//login & signup
export const LOGIN = "LOGIN"
export const UPDATE_USER_BASED_ON_JWT_TOKEN = "UPDATE_USER_BASED_ON_JWT_TOKEN"
export const LOGOUT = "LOGOUT"
export const SIGNUP = "SIGNUP"
export const CONFIRM_SIGNUP = "CONFIRM_SIGNUP"
export const LATE_ACTIVATION = "LATE_ACTIVATION"
export const CONFIRM_WITHDRAWAL = "CONFIRM_WITHDRAWAL"
export const CONFIRM_TRANSFER = "CONFIRM_TRANSFER"
export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR"
export const CHANGE_SIGNAL_STATE_AFTER_BUYING_SUBSCRIPTION = "CHANGE_SIGNAL_STATE_AFTER_BUYING_SUBSCRIPTION"

//wallet
export const GET_BALANCES = "GET_BALANCES"
export const GET_MARGIN_BALANCES = "GET_MARGIN_BALANCES"
export const GET_FUTURES_BALANCES = "GET_FUTURES_BALANCES"
export const GET_FUTURES_MARGIN_BALANCES = "GET_FUTURES_MARGIN_BALANCES"
export const GET_BALANCES_TOTALS_ONLY = "GET_BALANCES_TOTALS_ONLY"
export const GET_SPECIFIC_BALANCE = "GET_SPECIFIC_BALANCE"
export const GET_SPECIFIC_FUTURES_BALANCE = "GET_SPECIFIC_FUTURES_BALANCE"
export const GET_BALANCES_RECENT_DEPOSITS_AND_WITHDRAWS = "GET_BALANCES_RECENT_DEPOSITS_AND_WITHDRAWS"
export const ERROR_VALIDATING_ADDRESS = "ERROR_VALIDATING_ADDRESS"
export const ERROR_VALIDATING_MEMO = "ERROR_VALIDATING_MEMO"
export const WITHDRAW = "WITHDRAW"
export const TRANSFER = "TRANSFER"
export const STAKE = "STAKE"
export const TRANSFER_INTERNAL = "TRANSFER_INTERNAL"
export const TRANSFER_INTERNAL_ERROR = "TRANSFER_INTERNAL_ERROR"
export const CLEAR_WALLET_ERRORS = "CLEAR_WALLET_ERRORS"
export const GET_RECENT_DEPOSITS = "GET_RECENT_DEPOSITS"
export const GET_RECENT_WITHDRAWALS = "GET_RECENT_WITHDRAWALS"
export const GET_RECENT_INVESTMENT_PURCHASES = "GET_RECENT_INVESTMENT_PURCHASES"
export const GET_RECENT_TRANSFERS = "GET_RECENT_TRANSFERS"
export const GET_RECENT_INTERNAL_TRANSFERS = "GET_RECENT_INTERNAL_TRANSFERS"
export const DEPOSIT_ADD_OR_UPDATE = "DEPOSIT_ADD_OR_UPDATE"
export const CHANGE_TAB = "CHANGE_TAB"
export const CHANGE_WALLET = "CHANGE_WALLET"
export const CHANGE_SELECTED_WITHDRAWAL_COIN = "CHANGE_SELECTED_WITHDRAWAL_COIN"
export const CHANGE_SELECTED_WITHDRAWAL_COIN_BALANCE_ENTRY = "CHANGE_SELECTED_WITHDRAWAL_COIN_BALANCE_ENTRY"
export const CHANGE_TO_DEPOSITS_TAB = "CHANGE_TO_DEPOSITS_TAB"
export const CHANGE_TO_WITHDRAWALS_TAB = "CHANGE_TO_WITHDRAWALS_TAB"
export const CHANGE_TO_TRANSFERS_TAB = "CHANGE_TO_TRANSFERS_TAB"
export const CHANGE_TO_STAKING_TAB = "CHANGE_TO_STAKING_TAB"
export const CHANGE_TRANSFER = "CHANGE_TRANSFER"
export const CHANGE_TO_BALANCE_TAB = "CHANGE_TO_BALANCE_TAB";
export const CHANGE_TO_WITHDRAWALS_ALLOW_TAB = "CHANGE_TO_WITHDRAWALS_ALLOW_TAB";
export const CHANGE_RENDER_CUSTOM_HEADER = "CHANGE_RENDER_CUSTOM_HEADER";


//staking
export const STAKING_CHANGE_TAB = "STAKING_CHANGE_TAB"
export const STAKING_GET_BALANCES = "STAKING_GET_BALANCES"
export const STAKING_GET_STAKING_REWARDS_INITIAL = "STAKING_GET_STAKING_REWARDS_INITIAL"
export const STAKING_GET_STAKING_REWARDS_ALL = "STAKING_GET_STAKING_REWARDS_ALL"
export const STAKING_GET_STAKING_OPERATIONS_INITIAL = "STAKING_GET_STAKING_OPERATIONS_INITIAL"
export const STAKING_GET_STAKING_OPERATIONS_ALL = "STAKING_GET_STAKING_OPERATIONS_ALL"
export const STAKING_STAKE = "STAKING_STAKE"
export const STAKING_UNSTAKE = "STAKING_UNSTAKE"
export const SEND_FROM_STAKING_TO_SPOT_WALLET = "SEND_FROM_STAKING_TO_SPOT_WALLET"
export const STAKING_GET_PROGRAMS = "STAKING_GET_PROGRAMS"

//my profile
export const GENERATE_2FA = "GENERATE_2FA"
export const ENABLE_2FA = "ENABLE_2FA"
export const ENABLE_2FA_ERROR = "ENABLE_2FA_ERROR"
export const DISABLE_2FA = "DISABLE_2FA"
export const DISABLE_2FA_ERROR = "DISABLE_2FA_ERROR"
export const GET_USER_SETTINGS = "GET_USER_SETTINGS"
export const SET_ANTI_PHISHING_CODE = "SET_ANTI_PHISHING_CODE"
export const REMOVE_ANTI_PHISHING_CODE = "REMOVE_ANTI_PHISHING_CODE"
export const SIGNAL_SETTINGS_UPDATED = "SIGNAL_SETTINGS_UPDATED"
export const SIGNAL_SETTINGS_UPDATED_RESET = "SIGNAL_SETTINGS_UPDATED_RESET"
export const SIGNAL_SETTINGS_UPDATE_ERRORS = "SIGNAL_SETTINGS_UPDATE_ERRORS"
export const SIGNALS_SETTINGS_UPDATE_CLEAR_ERRORS = "SIGNALS_SETTINGS_UPDATE_CLEAR_ERRORS"
export const GET_JOIN_SIGNALS_GROUP_REQUEST_STATE = "GET_JOIN_SIGNALS_GROUP_REQUEST_STATE"
export const GET_NO_OF_REFERRED_USERS = "GET_NO_OF_REFERRED_USERS"
export const AFFILIATE_GET_COMMISSIONS_GET_ALL = "AFFILIATE_GET_COMMISSIONS_GET_ALL"
export const AFFILIATE_GET_COMMISSIONS_GET_INITIAL = "AFFILIATE_GET_COMMISSIONS_GET_INITIAL"

//new trade
export const GET_CURRENCIES_MAP = "GET_CURRENCIES_MAP"
export const GET_CURRENT_PRICE = "GET_CURRENT_PRICE"
export const GET_LAST_USED_PARAMS_ON_NEW_TRADE = "GET_LAST_USED_PARAMS_ON_NEW_TRADE"
export const PREVIEW_NEW_TRADE = "PREVIEW_NEW_TRADE"
export const LAUNCH_NEW_TRADE = "LAUNCH_NEW_TRADE"
export const LAUNCH_NEW_TRADE_ERROR = "LAUNCH_NEW_TRADE_ERROR"
export const CHECK_USER_BALANCE = "CHECK_USER_BALANCE"
export const GET_USER_BASE_BALANCE = "GET_USER_BASE_BALANCE"
export const GET_USER_QUOTE_BALANCE = "GET_USER_QUOTE_BALANCE"
export const CLEAR_NOT_ENOUGH_BALANCE = "CLEAR_NOT_ENOUGH_BALANCE"
//new exchange
export const PREVIEW_NEW_EXCHANGE = "PREVIEW_NEW_EXCHANGE"
export const LAUNCH_NEW_EXCHANGE = "LAUNCH_NEW_EXCHANGE"
export const LAUNCH_NEW_EXCHANGE_ERROR = "LAUNCH_NEW_EXCHANGE_ERROR"
//new futures
export const GET_CURRENCIES_MAP_FOR_FUTURES = "GET_CURRENCIES_MAP_FOR_FUTURES"
export const GET_CURRENT_PRICE_FOR_FUTURES = "GET_CURRENT_PRICE_FOR_FUTURES"
export const GET_LAST_USED_PARAMS_ON_NEW_FUTURES = "GET_LAST_USED_PARAMS_ON_NEW_FUTURES"
export const PREVIEW_NEW_FUTURES = "PREVIEW_NEW_FUTURES"
export const CHECK_USER_BALANCE_FOR_FUTURES = "CHECK_USER_BALANCE_FOR_FUTURES"
export const GET_USER_BASE_BALANCE_FOR_FUTURES = "GET_USER_BASE_BALANCE_FOR_FUTURES"
export const GET_USER_QUOTE_BALANCE_FOR_FUTURES = "GET_USER_QUOTE_BALANCE_FOR_FUTURES"
export const CLEAR_NOT_ENOUGH_BALANCE_FOR_FUTURES = "CLEAR_NOT_ENOUGH_BALANCE_FOR_FUTURES"
export const UPDATE_USER_LEVERAGE = "UPDATE_USER_LEVERAGE"
export const GET_USER_LEVERAGE = "GET_USER_LEVERAGE"
export const LAUNCH_NEW_FUTURES = "LAUNCH_NEW_FUTURES"
export const LAUNCH_NEW_FUTURES_ERROR = "LAUNCH_NEW_FUTURES_ERROR"

//test websocket
export const WS_RETRIEVED_MESSAGE = "WS_RETRIEVED_MESSAGE"

//errors
export const RECEIVED_ERRORS = "RECEIVED_ERRORS"
export const RECEIVED_AUTH_ERRORS = "RECEIVED_AUTH_ERRORS"
export const CLEAR_ERRORS = "CLEAR_ERRORS"

//trades
export const GET_ALL_TRADES = "GET_ALL_TRADES"
export const GET_ALL_ACTIVE_TRADES = "GET_ALL_ACTIVE_TRADES"
export const GET_ALL_WAITING_TRADES = "GET_ALL_WAITING_TRADES"
export const GET_ALL_CLOSED_TRADES = "GET_ALL_CLOSED_TRADES"
export const GET_ALL_ORDERS = "GET_ALL_ORDERS"
export const GET_ALL_SUCS = "GET_ALL_SUCS"
export const OPEN_TRADE = "OPEN_TRADE"
export const OPEN_ORDER = "OPEN_ORDER"
export const OPEN_TRADE_FROM_BTD = "OPEN_TRADE_FROM_BTD"
export const UPDATE_TRADE_EXECUTE_TAKE_PROFIT = "UPDATE_TRADE_EXECUTE_TAKE_PROFIT"
export const UPDATE_TRADE_CONDITIONS = "UPDATE_TRADE_CONDITIONS"
export const UPDATE_TRADE_CANCEL = "UPDATE_TRADE_CANCEL"
export const UPDATE_TRADE_DISCARD = "UPDATE_TRADE_DISCARD"
export const UPDATE_ORDER_DISCARD = "UPDATE_ORDER_DISCARD"
export const UPDATE_TRADE_CONNECTION_TO_SIGNAL = "UPDATE_TRADE_CONNECTION_TO_SIGNAL"
export const GET_HISTORY = "GET_HISTORY"
export const OPEN_BTD = "OPEN_BTD"
export const OPEN_SUC = "OPEN_SUC"
export const UPDATE_SUC_CONNECTION_TO_SIGNAL = "UPDATE_SUC_CONNECTION_TO_SIGNAL"
export const UPDATE_SUC_CONDITIONS = "UPDATE_SUC_CONDITIONS"
export const UPDATE_SUC_TRADE_CONDITIONS = "UPDATE_SUC_TRADE_CONDITIONS"
export const UPDATE_SUC_CANCEL = "UPDATE_SUC_CANCEL"
export const UPDATE_SUC_DISCARD = "UPDATE_SUC_DISCARD"
export const OPEN_TRADE_FROM_SUC = "OPEN_TRADE_FROM_SUC"
export const CLEAR_TRADES_STATE = "CLEAR_TRADES_STATE"
export const CLEAR_SUCS_STATE = "CLEAR_SUCS_STATE"
export const TRADE_SCROLL_INDEX = "TRADE_SCROLL_INDEX"
export const TRADES_RE_SORT_ON_VISIBLE_TRADES = "TRADES_RE_SORT_ON_VISIBLE_TRADES"
export const TRADE_SCROLL_IS_ON = "TRADE_SCROLL_IS_ON"
export const TRADE_SCROLL_IS_OFF = "TRADE_SCROLL_IS_OFF"

//futures
export const OPEN_FUTURES_TRADE = "OPEN_FUTURES_TRADE"
export const GET_HISTORY_FUTURES = "GET_HISTORY_FUTURES"
export const TRADES_RE_SORT_ON_VISIBLE_FUTURES_TRADES = "TRADES_RE_SORT_ON_VISIBLE_FUTURES_TRADES"
export const UPDATE_FUTURES_TRADE_DISCARD = "UPDATE_FUTURES_TRADE_DISCARD"
export const UPDATE_FUTURES_TRADE_CANCEL = "UPDATE_FUTURES_TRADE_CANCEL"
export const UPDATE_FUTURES_TRADE_CONDITIONS = "UPDATE_FUTURES_TRADE_CONDITIONS"
export const UPDATE_FUTURES_TRADE_EXECUTE_TAKE_PROFIT = "UPDATE_FUTURES_TRADE_EXECUTE_TAKE_PROFIT"
export const UPDATE_FUTURES_TRADE_CONNECTION_TO_SIGNAL = "UPDATE_FUTURES_TRADE_CONNECTION_TO_SIGNAL"
export const UPDATE_FUTURES_MODIFY_ISOLATED_MARGIN = "UPDATE_FUTURES_MODIFY_ISOLATED_MARGIN"
export const USER_HAS_FUTURES_ACTIVATED = "USER_HAS_FUTURES_ACTIVATED"

export const GET_ALL_FUTURES_ORDERS = "GET_ALL_FUTURES_ORDERS"
export const GET_ALL_OPENED_FUTURES_POSITIONS = "GET_ALL_OPENED_FUTURES_POSITIONS"
export const GET_ALL_CLOSED_FUTURES_POSITIONS = "GET_ALL_CLOSED_FUTURES_POSITIONS"

//trades ws
export const GET_TRADE_UPDATE = "GET_TRADE_UPDATE"
export const GET_FUTURES_TRADE_UPDATE = "GET_FUTURE_TRADE_UPDATE"
export const GET_SUC_UPDATE = "GET_SUC_UPDATE"


//ui
export const CHANGE_SELECTED_SUCS_TAB = "CHANGE_SELECTED_SUCS_TAB"
export const SET_CURRENT_TAB_WAITING_TRADES = "SET_CURRENT_TAB_WAITING_TRADES"
export const SET_CURRENT_TAB_ORDERS = "SET_CURRENT_TAB_ORDERS"

//users
export const USERS_GET_BY_EMAIL = "USERS_GET_BY_EMAIL"
export const USERS_GET_REFERRED_INITIAL = "USERS_GET_REFERRED_INITIAL"
export const USERS_GET_REFERRED_ALL = "USERS_GET_REFERRED_ALL"

//order book
export const GET_ORDER_BOOK = "GET_ORDER_BOOK"

//signals
export const SIGNALS_PREVIEW = "SIGNALS_PREVIEW"
export const SIGNALS_SEND = "SIGNALS_SEND"
export const SIGNALS_FUTURES_ERRORS = "SIGNALS_FUTURES_ERRORS"
export const SIGNALS_ERRORS = "SIGNALS_ERRORS"
export const SIGNALS_CLEAR_ERRORS = "SIGNALS_CLEAR_ERRORS"
export const SIGNALS_FUTURES_CLEAR_ERRORS = "SIGNALS_FUTURES_CLEAR_ERRORS"
export const SIGNALS_CLEAR_SEND_RESULT = "SIGNALS_CLEAR_SEND_RESULT"
export const SIGNALS_GET_WAITING_INITIAL = "SIGNALS_GET_WAITING_INITIAL"
export const SIGNALS_GET_WAITING_INITIAL_V1 = "SIGNALS_GET_WAITING_INITIAL_V1"
export const SIGNALS_GET_WAITING_ALL = "SIGNALS_GET_WAITING_ALL"
export const SIGNALS_GET_WAITING_ALL_V1 = "SIGNALS_GET_WAITING_ALL_V1"
export const SIGNALS_GET_RUNNING_INITIAL = "SIGNALS_GET_RUNNING_INITIAL"
export const SIGNALS_GET_RUNNING_INITIAL_V1 = "SIGNALS_GET_RUNNING_INITIAL_V1"
export const SIGNALS_GET_RUNNING_ALL = "SIGNALS_GET_RUNNING_ALL"
export const SIGNALS_GET_RUNNING_ALL_V1 = "SIGNALS_GET_RUNNING_ALL_V1"
export const SIGNALS_SELECT_TAB = "SIGNALS_SELECT_TAB"
export const SIGNALS_CLEAR_CURRENT_SIGNAL = "SIGNALS_CLEAR_CURRENT_SIGNAL"
export const SIGNALS_GET_CLOSED_INITIAL = "SIGNALS_GET_CLOSED_INITIAL"
export const SIGNALS_GET_CLOSED_INITIAL_V1 = "SIGNALS_GET_CLOSED_INITIAL_V1"
export const SIGNALS_GET_CLOSED_ALL = "SIGNALS_GET_CLOSED_ALL"
export const SIGNALS_GET_CLOSED_ALL_V1 = "SIGNALS_GET_CLOSED_ALL_V1"
export const SIGNALS_GET_COUNT_UNREAD_SIGNALS_NOTIFICATIONS = "SIGNALS_GET_COUNT_UNREAD_SIGNALS_NOTIFICATIONS"
export const SIGNALS_GET_SIGNALS_NOTIFICATIONS_INITIAL = "SIGNALS_GET_SIGNALS_NOTIFICATIONS_INITIAL"
export const SIGNALS_GET_ALL_SIGNALS_NOTIFICATIONS = "SIGNALS_GET_ALL_SIGNALS_NOTIFICATIONS"
export const SIGNALS_READ_UNREAD_NOTIFICATION = "SIGNALS_READ_UNREAD_NOTIFICATION"
export const SIGNALS_UPDATE_NOTIFICATION = "SIGNALS_UPDATE_NOTIFICATION"
export const SIGNALS_DISCARD_NOTIFICATION = "SIGNALS_DISCARD_NOTIFICATION"
export const SIGNALS_GET_UPDATE = "SIGNALS_GET_UPDATE"
export const SIGNALS_SET_CURRENT_PAGE = "SIGNALS_SET_CURRENT_PAGE"
export const SIGNALS_SET_ROWS_PER_PAGE = "SIGNALS_SET_ROWS_PER_PAGE"

//bots
export const BOTS_GET_ACTIVE_INITIAL = "BOTS_GET_ACTIVE_INITIAL"
export const BOTS_GET_ACTIVE_ALL = "BOTS_GET_ACTIVE_ALL"
export const BOTS_GET_STOPPED_INITIAL = "BOTS_GET_STOPPED_INITIAL"
export const BOTS_GET_STOPPED_ALL = "BOTS_GET_STOPPED_ALL"
export const BOTS_SELECT_TAB = "BOTS_SELECT_TAB"
export const BOTS_CLEAR_CURRENT_BOT = "BOTS_CLEAR_CURRENT_BOT"
export const BOTS_PREVIEW = "BOTS_PREVIEW"
export const BOTS_SAVE = "BOTS_SAVE"
export const BOTS_ERRORS = "BOTS_ERRORS"
export const BOTS_CLEAR_ERRORS = "BOTS_CLEAR_ERRORS"
export const BOTS_CLEAR_SAVE_RESULT = "BOTS_CLEAR_SAVE_RESULT"

//users groups
export const USERS_GROUPS_GET_ALL_SIMPLE = "USERS_GROUPS_GET_ALL_SIMPLE"

//traders
export const TRADERS_GET_ALL = "TRADERS_GET_ALL"
export const TRADERS_GET_INITIAL = "TRADERS_GET_INITIAL"
export const TRADERS_GET_TRADER = "TRADERS_GET_TRADER"
export const TRADERS_USERS_GET_INITIAL = "TRADERS_USERS_GET_INITIAL"
export const TRADERS_USERS_GET_ALL = "TRADERS_USERS_GET_ALL"
export const TRADERS_APPLICATIONS_GET_INITIAL = "TRADERS_APPLICATIONS_GET_INITIAL"
export const TRADERS_APPLICATIONS_GET_ALL = "TRADERS_APPLICATIONS_GET_ALL"
export const TRADERS_SELECT_TAB = "TRADERS_SELECT_TAB"
export const TRADERS_APPLICATION_APPROVE = "TRADERS_APPLICATION_APPROVE"
export const TRADERS_APPLICATION_REJECT = "TRADERS_APPLICATION_DENY"
export const TRADERS_SUBSCRIPTION_UPDATED = "TRADERS_SUBSCRIPTION_UPDATED"
export const TRADERS_ADD_SUBSCRIPTION = "TRADERS_ADD_SUBSCRIPTION"
export const TRADERS_REMOVE_SUBSCRIPTION = "TRADERS_REMOVE_SUBSCRIPTION"
export const TRADERS_TRADER_UPDATED = "TRADERS_TRADER_UPDATED"
export const TRADERS_SUBSCRIPTION_GET = "TRADERS_SUBSCRIPTION_GET"
export const TRADERS_SUBSCRIPTIONS_GET = "TRADERS_SUBSCRIPTIONS_GET"
export const TRADERS_USER_BUYS_SUBSCRIPTION = "TRADERS_USER_BUYS_SUBSCRIPTION"
export const TRADERS_USER_UNSUBSCRIBES_BY_REMOVAL = "TRADERS_USER_UNSUBSCRIBES_BY_REMOVAL"
export const TRADERS_GET_FOLLOWERS_GET_ALL = "TRADERS_GET_FOLLOWERS_GET_ALL"
export const TRADERS_GET_FOLLOWER = "TRADERS_GET_FOLLOWER"
export const TRADERS_GET_FOLLOWERS_GET_INITIAL = "TRADERS_GET_FOLLOWERS_GET_INITIAL"
export const TRADERS_GET_SUBSCRIPTION_PAYMENTS_GET_ALL = "TRADERS_GET_SUBSCRIPTION_PAYMENTS_GET_ALL"
export const TRADERS_GET_SUBSCRIPTION_PAYMENTS_GET_INITIAL = "TRADERS_GET_SUBSCRIPTION_PAYMENTS_GET_INITIAL"
export const TRADERS_GET_COMMISSIONS_GET_ALL = "TRADERS_GET_COMMISSIONS_GET_ALL"
export const TRADERS_GET_COMMISSIONS_GET_INITIAL = "TRADERS_GET_COMMISSIONS_GET_INITIAL"
export const TRADERS_GET_THIS_MONTH = "TRADERS_GET_THIS_MONTH"
export const TRADERS_RATE_TRADER = "TRADERS_RATE_TRADER"
export const TRADERS_DISABLED = "TRADERS_DISABLED"
export const TRADERS_ENABLED = "TRADERS_ENABLED"

//administration
export const ADMINISTRATION_NEW_SUSPENDED_ACTIVITIES = "ADMINISTRATION_NEW_SUSPENDED_ACTIVITIES"
export const ADMINISTRATION_GET_ALL_SUSPENDED_ACTIVITIES = "ADMINISTRATION_GET_ALL_SUSPENDED_ACTIVITIES"
export const ADMINISTRATION_GET_ACTIVE_SUSPENDED_ACTIVITIES = "ADMINISTRATION_GET_ACTIVE_SUSPENDED_ACTIVITIES"

//transactions
export const TRANSACTIONS_GET_INITIAL = "TRANSACTIONS_GET_INITIAL"
export const TRANSACTIONS_GET_ALL = "TRANSACTIONS_GET_ALL"

//investments
export const INVESTMENT_CHANGE_TAB = "INVESTMENT_CHANGE_TAB"
export const INVESTMENT_PROGRAMS_GET_ACTIVE = "INVESTMENT_PROGRAMS_GET_ACTIVE"
export const INVESTMENT_PROGRAMS_GET_CLOSED = "INVESTMENT_PROGRAMS_GET_CLOSED"
export const INVESTMENT_GET_MY_INVESTMENT_PURCHASES = "INVESTMENT_GET_MY_INVESTMENT_PURCHASES"
export const INVESTMENT_GET_MY_REFERRAL_INVESTMENT_PURCHASES = "INVESTMENT_GET_MY_REFERRAL_INVESTMENT_PURCHASES"
export const INVEST = "INVEST"

//wha
export const WHA_REGISTER_LINK = "WHA_REGISTER_LINK"
export const WHA_NAME = "WHA_NAME"