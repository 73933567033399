import {
    USERS_GET_BY_EMAIL, USERS_GET_REFERRED_ALL, USERS_GET_REFERRED_INITIAL
} from "../actions/actionTypes";

export default function (state = {docs: [], totalDocs: 0, totalPages: 0, pagesDownloaded: {}, totals: {}}, action) {
    switch (action.type) {

        case USERS_GET_BY_EMAIL: {
            return {
                ...state,
                ...{
                    searchedUser: action.payload
                }
            }
        }

        case USERS_GET_REFERRED_ALL: {
            let newState =  {
                ...state,
                docs: [...state.docs, ...action.payload.docs],
                totalDocs: action.payload.totalDocs,
                totalPages: action.payload.totalPages,
                pagesDownloaded: {...state.pagesDownloaded, ...{[action.payload.page]: true}}
            }
            return newState
        }

        case USERS_GET_REFERRED_INITIAL: {
            let newState =  {
                ...state,
                docs: action.payload.docs,
                totalDocs: action.payload.totalDocs,
                totalPages: action.payload.totalPages,
                pagesDownloaded: {[action.payload.page]: true}
            }
            return newState
        }

        default:
            return state
    }
}
