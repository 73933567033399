import React, {Component} from 'react'
import {connect} from 'react-redux'
import {confirmSignup, lateActivation} from '../../redux/actions/authentication'
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import {getTitle} from "../fncomponents/PageTitleUtils";
import {Helmet} from "react-helmet/es/Helmet";

const qs = require("query-string")


class ConfirmSignup extends Component {

    componentDidMount() {
        let uid = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).uid
        this.props.lateActivation(uid)
    }

    render() {
        return (
            <div className={'loginForm'} style={{marginTop: "6em", width: "20rem", textAlign: "center"}}>
                <Helmet>
                    <title>{getTitle()}</title>
                </Helmet>
                {(this.props.lateActivationCode && this.props.lateActivationCode === "ERROR") && <div>
                    <div className={"errorContainer"}>Forbidden operation.</div>
                </div>}
                {(this.props.lateActivationCode && this.props.lateActivationCode === "SUCCESS") && <div>
                    <h3>Activate account</h3>
                    <div>We are happy to see you back! Your account has been activated.</div>
                    <div>Your username is <b>{this.props.lateActivationUserEmail}</b>.</div>
                    <div style={{marginTop: "1rem"}}>
                        <div>You can now</div>
                        <RouterLink to={"/login"}>
                            <Link variant="body4">
                                <span className={"App-link"}>Login</span>
                            </Link>
                        </RouterLink>
                    </div>
                    <div style={{marginTop: "1rem"}}>
                        <div>Or, in case you forgot your password...</div>
                        <RouterLink to={"/reset-password"}>
                            <Link variant="body4">
                                <span className={"App-link"}>Reset it</span>
                            </Link>
                        </RouterLink>
                    </div>
                </div>}
            </div>)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        lateActivation: (token) => dispatch(lateActivation(token))
    }
}

const mapStateToProps = rootState => ({
    lateActivationCode: rootState.authentication.lateActivationCode,
    lateActivationUserEmail: rootState.authentication.lateActivationUserEmail,
})
export default connect(mapStateToProps, mapDispatchToProps) (ConfirmSignup)
