import React, {Component} from 'react'
import {Route, BrowserRouter as Router} from 'react-router-dom'
import { connect } from 'react-redux'
import {Redirect} from 'react-router-dom'
import c from './constants'
import withGetScreenExt from './withGetScreenExt'
import './App.css'

import withAuth from "./components/hoc/withAuth"
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import Cookies from 'universal-cookie'

//Authentication actions
import {
    checkJWT,
    updateAuthStatePerJWT,
    logOut,
} from "./redux/actions/authentication"

//The rest of the components
import Trades from "./components/pages/trades/Trades"
import Futures from "./components/pages/futures/Futures"
import WsTest from "./components/pages/WsTest"
import Login from "./components/pages/Login"
import ResetPassword from "./components/pages/ResetPassword"
import ResetPasswordForm from "./components/pages/ResetPasswordForm"
import Register from "./components/pages/Register"
import RegisterViaAffiliate from "./components/pages/registerViaAffiliates/RegisterViaAffiliate"
import RegisterViaAffiliate1 from "./components/pages/registerViaAffiliates/RegisterViaAffiliate1"
import RegisterViaAffiliate_roinvite_1i4evmir9kaoca38m from "./components/pages/registerViaAffiliates/RegisterViaAffiliate_roinvite_1i4evmir9kaoca38m"
import RegisterViaAffiliate_joinnow_1i4evmk4kkomw82pg from "./components/pages/registerViaAffiliates/RegisterViaAffiliate_joinnow_1i4evmk4kkomw82pg"
import ConfirmSignup from "./components/pages/ConfirmSignup"
import LateActivation from "./components/pages/LateActivation"
import WalletsDWT from "./components/pages/wallet/WalletsDWT"

import MyProfile from "./components/pages/MyProfile"
import TwoFAAuth from "./components/pages/TwoFAAuth"
import NewTrade from "./components/pages/NewTrade"
import PreviewNewTrade from "./components/pages/PreviewTrade"
import PreviewNewFutures from "./components/pages/PreviewFutures"
import Converter from "./components/pages/NewConversion"
import PreviewConversion from "./components/pages/PreviewConversion"
import NewFutures from "./components/pages/NewFutures"
import TradeDetails from "./components/pages/trades/TradeDetails"
import FuturesTradeDetails from "./components/pages/futures/FuturesTradeDetails"
import TradeSummary from "./components/pages/trades/TradeSummary"
import FuturesTradeSummary from "./components/pages/futures/FuturesTradeSummary"
import CompletedTradeSummary from "./components/pages/trades/CompletedTradeSummary"
import CompletedFuturesTradeSummary from "./components/pages/futures/CompletedFuturesTradeSummary"
import BTDDetails from "./components/pages/trades/BTDDetails"
import BTDSummary from "./components/pages/trades/BTDSummary"
import OrderDetails from "./components/pages/trades/OrderDetails"
import OrderSummary from "./components/pages/trades/OrderSummary"
import SignalsV1 from "./components/pages/signals/SignalsV1"
import SignalsNotifications from "./components/pages/signals/SignalsNotifications"
import NewOrUpdateSignalV1 from "./components/pages/signals/NewOrUpdateSignalV1"
import NewOrUpdateSignalV1Futures from "./components/pages/signals/NewOrUpdateSignalV1Futures"
import PreviewSignalV1 from "./components/pages/signals/PreviewSignalV1"
import Bots from "./components/pages/bots/Bots"
import NewOrUpdateBot from "./components/pages/bots/NewOrUpdateBot"
import PreviewBot from "./components/pages/bots/PreviewBot"
import SUCs from "./components/pages/sucs/SUCs"
import SUCDetails from "./components/pages/sucs/SUCDetails"
import SUCSummary from "./components/pages/sucs/SUCSummary"
import ConfirmWithdrawal from "./components/pages/ConfirmWithdrawal"
import ConfirmTransfer from "./components/pages/ConfirmTransfer"

import Traders from "./components/pages/traders/Traders"
import TraderPresentationPage from "./components/pages/traders/TraderPresentationPage"
import TraderPresentationBox from "./components/pages/traders/TraderPresentationBox"
import TraderMyPage from "./components/pages/traders/TraderMyPage"
import ApplyForTrader from "./components/pages/ApplyForTrader"
import SubscribeConfirmationPage from "./components/pages/subscriptions/SubscribeConfirmationPage"
import UnsubscribeConfirmationPage from "./components/pages/subscriptions/UnsubscribeConfirmationPage"
import TraderFollowers from "./components/pages/traderFollowers/TraderFollowers"
import AddTraderFollower from "./components/pages/traderFollowers/AddTraderFollower"
import EditTraderFollower from "./components/pages/traderFollowers/EditTraderFollower"
import TraderPayments from "./components/pages/traderPayments/TraderPayments"
import TraderCommissions from "./components/pages/traderCommissions/TraderCommissions"
import BuyCrypto from "./components/pages/buycrypto/BuyCrypto"
import BuyCryptoTransak from "./components/pages/buycrypto/BuyCryptoTransak"
import ReferredUsers from "./components/pages/referredUsers/ReferredUsers"
import AffiliateCommissions from "./components/pages/affiliateCommissions/AffiliateCommissions"
import DDD239 from "./components/pages/DDD239"
import Unsubscribe from "./components/pages/Unsubscribe"
import StakingSection from './components/pages/stakes/StakingSection'
import InvestmentSection from './components/pages/investment/InvestmentSection'
import InvestmentProgramReferralLink from './components/pages/investment/InvestmentProgramReferralLink'

//Icons
import CircularIndeterminate from "./components/fncomponents/loading"
import AppAndSideBar from "./components/menu/AppAndSideBar"
const cookies = new Cookies()

const theme = createMuiTheme({
    palette: {
        type: "dark",
        primary: {
            main: '#ffffff'
        },
        secondary: {
            main: '#101A26'
        }
    },
    toggle: {
        thumbOnColor: '#fefefefe',
        trackOnColor: '#ee0000ee'
    }
})

const SPOT_MARKET_ALLOWED_REDIRECTS = {"/futures": true, "/newfutures": true}
const FUTURES_MARKET_ALLOWED_REDIRECTS = {"/": true, "/newtrade": true}


const LOGGING = false
class App extends Component {

   constructor(props) {
       super(props)
       this.state = {
           updateAuthStatePerJWT: false,
       }

   }

   shouldRedirect(user, logout) {
       let location = window.location.href
       location = location.toLowerCase()
       if (location.indexOf("register") > 0)
           return {shouldRedirect: false}
       if (location.indexOf("reset-password") > 0)
           return {shouldRedirect: false}
       if (location.indexOf("reset-password-f") > 0)
           return {shouldRedirect: false}
       if (location.indexOf("confirmsignup") > 0)
            return {shouldRedirect: false}
       if (location.indexOf("lactivation") > 0)
           return {shouldRedirect: false}
       if (location.indexOf("cw") > 0)
           return {shouldRedirect: false}
       if (location.indexOf("ref") > 0)
           return {shouldRedirect: false}
       if (location.indexOf("roinvite") > 0)
           return {shouldRedirect: false}
       if (location.indexOf("joinnow") > 0)
           return {shouldRedirect: false}
       if (location.indexOf("unsub") > 0)
           return {shouldRedirect: false}
       if (location.indexOf("iprl") > 0)
           return {shouldRedirect: false}
       if (!user)
           return {shouldRedirect: true, to: "/login"}
       if (logout)
           return {shouldRedirect: true, to: "/login"}
       if (this.state.markets === c.MARKETS_FUTURES && FUTURES_MARKET_ALLOWED_REDIRECTS[this.props.location])
           return {shouldRedirect: true, to: "/futures"}
       else if (this.state.markets === c.MARKETS_SPOT && SPOT_MARKET_ALLOWED_REDIRECTS[this.props.location])
           return {shouldRedirect: true, to: "/"}
       return {shouldRedirect: false}
   }

   componentDidMount() {
       let markets = cookies.get(`selected_markets`)
       if (!markets) {
           markets = c.MARKETS_SPOT
       }
       this.setState({
           markets
       })
   }

   render() {
        let {user, logout} = this.props
        let {shouldRedirect, to} = this.shouldRedirect(user, logout)

        if (!this.state.updateAuthStatePerJWT) {
            this.props.updateAuthStatePerJWT(()=>{
                this.state.updateAuthStatePerJWT = true
            })
            return (<div className="App">
                <div className="loading">
                    <CircularIndeterminate/>
                </div>
            </div>)
        } else {
          return (
              <div className="App">
                  <MuiThemeProvider theme={theme}>
                      <Router>
                          {/*Public Access*/}
                          <AppAndSideBar/>

                          <Route path={"/login"} exact component={Login}/>
                          <Route path={"/2fa"} exact component={TwoFAAuth}/>
                          <Route path={"/reset-password"} exact component={ResetPassword}/>
                          <Route path={"/reset-password-f"} exact component={ResetPasswordForm}/>
                          <Route path={"/register"} exact component={Register}/>
                          <Route path={"/ref/:ref"} exact component={RegisterViaAffiliate}/>
                          <Route path={"/ref1"} exact component={RegisterViaAffiliate1}/>
                          <Route path={"/roinvite"} exact component={RegisterViaAffiliate_roinvite_1i4evmir9kaoca38m}/>
                          <Route path={"/joinnow"} exact component={RegisterViaAffiliate_joinnow_1i4evmk4kkomw82pg}/>
                          <Route path={"/confirmsignup"} exact component={ConfirmSignup}/>
                          <Route path={"/lactivation"} exact component={LateActivation}/>
                          <Route path={"/cw"} exact component={ConfirmWithdrawal}/>
                          <Route path={"/ct"} exact component={ConfirmTransfer}/>
                          <Route path={"/ddd239red39ie"} exact component={DDD239}/>
                          <Route path={"/unsub"} exact component={Unsubscribe}/>
                          <Route path={"/iprl"} exact component={InvestmentProgramReferralLink}/>

                          {/*Restricted Access*/}
                          <Route path={"/"} exact component={withAuth(Trades)}/>
                          <Route path={"/futures"} exact component={withAuth(Futures)}/>
                          <Route path={"/futuresorderdetails"} exact component={withAuth(FuturesTradeDetails)}/>
                          <Route path={"/futuresposdetails"} exact component={withAuth(FuturesTradeDetails)}/>
                          <Route path={"/futuresordersummary"} exact component={withAuth(FuturesTradeSummary)}/>
                          <Route path={"/futurespossummary"} exact component={withAuth(FuturesTradeSummary)}/>
                          <Route path={"/wstest"} exact component={withAuth(WsTest)}/>
                          <Route path={"/wallets"} exact component={withAuth(WalletsDWT)}/>
                          <Route path={"/staking"} exact component={withAuth(StakingSection)}/>
                          <Route path={"/contributions"} exact component={withAuth(InvestmentSection)}/>
                          <Route path={"/myprofile"} exact component={withAuth(MyProfile)}/>
                          <Route path={"/referredusers"} exact component={withAuth(ReferredUsers)}/>
                          <Route path={"/affiliate/commissions"} exact component={withAuth(AffiliateCommissions)}/>
                          <Route path={"/newtrade"} exact component={withAuth(NewTrade)}/>
                          <Route path={"/previewtrade"} exact component={withAuth(PreviewNewTrade)}/>
                          <Route path={"/converter"} exact component={withAuth(Converter)}/>
                          <Route path={"/previewconversion"} exact component={withAuth(PreviewConversion)}/>
                          <Route path={"/newfutures"} exact component={withAuth(NewFutures)}/>
                          <Route path={"/previewfutures"} exact component={withAuth(PreviewNewFutures)}/>
                          <Route path={"/tradedetails"} exact component={withAuth(TradeDetails)}/>
                          <Route path={"/tradesummary"} exact component={withAuth(TradeSummary)}/>
                          <Route path={"/completedtradedetails"} exact component={withAuth(CompletedTradeSummary)}/>
                          <Route path={"/completedfuturestradedetails"} exact component={withAuth(CompletedFuturesTradeSummary)}/>
                          <Route path={"/btddetails"} exact component={withAuth(BTDDetails)}/>
                          <Route path={"/btdsummary"} exact component={withAuth(BTDSummary)}/>
                          <Route path={"/sucs"} exact component={withAuth(SUCs)}/>
                          <Route path={"/sucdetails"} exact component={withAuth(SUCDetails)}/>
                          <Route path={"/sucsummary"} exact component={withAuth(SUCSummary)}/>
                          <Route path={"/orderdetails"} exact component={withAuth(OrderDetails)}/>
                          <Route path={"/ordersummary"} exact component={withAuth(OrderSummary)}/>
                          {/*Admin Area*/}
                          <Route path={"/signals"} exact component={withAuth(SignalsV1)}/>
                          <Route path={"/signalsnotifs"} exact component={withAuth(SignalsNotifications)}/>
                          <Route path={"/newsignal"} exact component={withAuth(NewOrUpdateSignalV1)}/>
                          <Route path={"/newsignalF"} exact component={withAuth(NewOrUpdateSignalV1Futures)}/>
                          <Route path={"/editsignal"} exact component={withAuth(NewOrUpdateSignalV1)}/>
                          <Route path={"/editsignalF"} exact component={withAuth(NewOrUpdateSignalV1Futures)}/>
                          <Route path={"/previewsignal"} exact component={withAuth(PreviewSignalV1)}/>
                          <Route path={"/bots"} exact component={withAuth(Bots)}/>
                          <Route path={"/newbot"} exact component={withAuth(NewOrUpdateBot)}/>
                          <Route path={"/editbot"} exact component={withAuth(NewOrUpdateBot)}/>
                          <Route path={"/previewbot"} exact component={withAuth(PreviewBot)}/>
                          <Route path={"/traders"} exact component={withAuth(Traders)}/>
                          <Route path={"/trader"} exact component={withAuth(TraderPresentationPage)}/>
                          <Route path={"/traderbox"} exact component={withAuth(TraderPresentationBox)}/>
                          <Route path={"/trader/mypage"} exact component={withAuth(TraderMyPage)}/>
                          <Route path={"/applyfortrader"} exact component={withAuth(ApplyForTrader)}/>
                          <Route path={"/confirmsub"} exact component={withAuth(SubscribeConfirmationPage)}/>
                          <Route path={"/confirmunsub"} exact component={withAuth(UnsubscribeConfirmationPage)}/>
                          <Route path={"/trader/followers"} exact component={withAuth(TraderFollowers)}/>
                          <Route path={"/trader/followers/new"} exact component={withAuth(AddTraderFollower)}/>
                          <Route path={"/trader/followers/edit"} exact component={withAuth(EditTraderFollower)}/>
                          <Route path={"/trader/payments"} exact component={withAuth(TraderPayments)}/>
                          <Route path={"/trader/commissions"} exact component={withAuth(TraderCommissions)}/>
                          <Route path={"/buycrypto"} exact component={withAuth(BuyCrypto)}/>
                          <Route path={"/buycryptot"} exact component={withAuth(BuyCryptoTransak)}/>
                          {shouldRedirect && <Redirect to={to}/>}
                      </Router>
                  </MuiThemeProvider>
              </div>
          )
      }
  }
}


const mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    logout: rootState.authentication.logout,
    totals: rootState.wallet.totals,
})

const mapDispatchToProps = dispatch => ({
    // getTotals: (props, cb) => {dispatch(getTotals(props, cb))},
    updateAuthStatePerJWT: (cb) => dispatch(updateAuthStatePerJWT(cb)),
    checkJWT: () => dispatch(checkJWT()),
    logOut: () => dispatch(logOut())
})

export default connect(mapStateToProps, mapDispatchToProps)(withGetScreenExt(App))
