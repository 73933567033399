import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Paper} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
}));

export default function HistoryFilter(props) {
    const classes = useStyles()
    const {historyFilter, selectedYear, selectedMonth, changeFilter} = props
    const monthsByIndex = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    let years  = []
    let selectedYearIndex = 0
    function getYears() {
        for(let i in historyFilter) {
            if (selectedYear == historyFilter[i].year)
                selectedYearIndex = i
            years.push(historyFilter[i].year)
        }
    }
    getYears()
    return (
        <Paper className={"filterContainer"}>
            <div>
                <div>
                    {years.map((year, index) => {
                        let yClass = selectedYear == year ? "hfSelectedYear": "hfNormalYear"
                        return <span key={`year${year}`} className={yClass} onClick={()=>{changeFilter(selectedMonth, year)}}>{year}  </span>
                    })}
                </div>
                <div>
                    {historyFilter[selectedYearIndex].months.map((month, index) => {
                        let mClass = selectedMonth == month ? "hfSelectedMonth": "hfNormalMonth"
                        return <span key={`month_${selectedYear}_${month}`} className={mClass} onClick={()=>{changeFilter(month, selectedYear)}}>{monthsByIndex[month]}  </span>
                    })}
                </div>
            </div>
        </Paper>)
}
