import React from "react";
import {TextField} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Comment from '@material-ui/icons/Comment'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(1),
        width: 280,
        backgroundColor: '#101A26'
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    }
}))

export default function SetComment(props) {
    const {comment, handleChangeComment} = props
    const classes = useStyles()

    return (
    <div className={"wizardSectionContainer"}>
        <div className={"wizardTitle"}>
            <span className={"wizardTitleIcon"}><Comment/></span> Comment
        </div>
        <div style={{marginLeft: "0.6rem"}}>
            <TextField
                id="outlined-adornment-weight_set_comments"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                placeholder={"Comment"}
                value={comment}
                onChange={handleChangeComment}
                multiline
                rowsMax="4"
            />
        </div>
    </div>)
}
