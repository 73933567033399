import {
    TRADERS_ADD_SUBSCRIPTION,
    TRADERS_APPLICATION_APPROVE,
    TRADERS_APPLICATION_REJECT,
    TRADERS_APPLICATIONS_GET_ALL,
    TRADERS_APPLICATIONS_GET_INITIAL,
    TRADERS_DISABLED,
    TRADERS_ENABLED,
    TRADERS_GET_ALL, TRADERS_GET_COMMISSIONS_GET_ALL, TRADERS_GET_COMMISSIONS_GET_INITIAL,
    TRADERS_GET_FOLLOWER,
    TRADERS_GET_FOLLOWERS_GET_ALL,
    TRADERS_GET_FOLLOWERS_GET_INITIAL,
    TRADERS_GET_INITIAL,
    TRADERS_GET_SUBSCRIPTION_PAYMENTS_GET_ALL,
    TRADERS_GET_SUBSCRIPTION_PAYMENTS_GET_INITIAL, TRADERS_GET_THIS_MONTH,
    TRADERS_GET_TRADER,
    TRADERS_RATE_TRADER,
    TRADERS_REMOVE_SUBSCRIPTION,
    TRADERS_SELECT_TAB,
    TRADERS_SUBSCRIPTION_GET,
    TRADERS_SUBSCRIPTION_UPDATED,
    TRADERS_SUBSCRIPTIONS_GET,
    TRADERS_TRADER_UPDATED,
    TRADERS_USER_BUYS_SUBSCRIPTION,
    TRADERS_USERS_GET_ALL,
    TRADERS_USERS_GET_INITIAL
} from "../actions/actionTypes";
import c from '../../constants'

export default function (state = {docs: [], totalDocs: 0, totalPages: 0, pagesDownloaded: {}, totals: {}, selectedTab: 0}, action) {
    switch (action.type) {

        case TRADERS_GET_ALL: {
            let newState = {
                ...state,
                docs: [...state.docs, ...action.payload.docs],
                totalDocs: action.payload.totalDocs,
                totalPages: action.payload.totalPages,
                pagesDownloaded: {...state.pagesDownloaded, ...{[action.payload.page]: true}}
            }
            return newState
        }

        case TRADERS_GET_INITIAL: {
            let newState = {
                ...state,
                docs: action.payload.docs,
                totalDocs: action.payload.totalDocs,
                totalPages: action.payload.totalPages,
                pagesDownloaded: {[action.payload.page]: true}
            }
            return newState
        }

        case TRADERS_GET_TRADER: {
            let newState = {
                ...state,
                trader: action.payload
            }
            return newState
        }

        case TRADERS_USERS_GET_ALL: {
            let newState = {
                ...state,
                users_docs: [...state.users_docs, ...action.payload.docs],
                users_totalDocs: action.payload.totalDocs,
                users_totalPages: action.payload.totalPages,
                users_pagesDownloaded: {...state.users_pagesDownloaded, ...{[action.payload.page]: true}}
            }
            return newState
        }

        case TRADERS_USERS_GET_INITIAL: {
            let newState = {
                ...state,
                users_docs: action.payload.docs,
                users_totalDocs: action.payload.totalDocs,
                users_totalPages: action.payload.totalPages,
                users_pagesDownloaded: {[action.payload.page]: true}
            }
            return newState
        }


        case TRADERS_APPLICATIONS_GET_ALL: {
            let newState = {
                ...state,
                apps_docs: [...state.apps_docs, ...action.payload.docs],
                apps_totalDocs: action.payload.totalDocs,
                apps_totalPages: action.payload.totalPages,
                apps_pagesDownloaded: {...state.apps_pagesDownloaded, ...{[action.payload.page]: true}}
            }
            return newState
        }

        case TRADERS_APPLICATIONS_GET_INITIAL: {
            let newState = {
                ...state,
                apps_docs: action.payload.docs,
                apps_totalDocs: action.payload.totalDocs,
                apps_totalPages: action.payload.totalPages,
                apps_pagesDownloaded: {[action.payload.page]: true}
            }
            return newState
        }

        case TRADERS_SELECT_TAB: {
            let newState =  {
                ...state,
                selectedTab: action.payload
            }
            return newState
        }

        case TRADERS_APPLICATION_APPROVE:
        case TRADERS_APPLICATION_REJECT: {
            let updatedApp = action.payload
            let newState = {...state, ...{}}
            newState.apps_docs = newState.apps_docs.map((doc, index) => {
                if (doc.app._id === updatedApp._id) {
                    return {app: updatedApp, user: doc.user}
                } else {
                    return doc
                }
            })

            return newState
        }

        case TRADERS_SUBSCRIPTION_UPDATED: {
            let updateSub = action.payload
            if (state.trader && state.trader.subscriptions) {
                return {
                    ...state,
                    ...{
                        trader: {
                            trader: state.trader.trader,
                            subscriptions: state.trader.subscriptions.map((sub, index) => {
                                if (sub) {
                                    if (sub._id == updateSub._id) {
                                        return updateSub
                                    } else {
                                        return sub
                                    }
                                }
                                return undefined
                            })
                        }
                    }
                }
            } else {
                return state
            }
        }

        case TRADERS_ADD_SUBSCRIPTION: {
            let newSub = action.payload
            if (state.trader && state.trader.subscriptions) {
                let newState = {
                    ...state,
                    ...{
                        trader: {
                            trader: state.trader.trader,
                            subscriptions: [...state.trader.subscriptions, ...[newSub]]
                        }
                    }
                }
                return newState
            } else {
                return state
            }
        }

        case TRADERS_REMOVE_SUBSCRIPTION: {
            let removedSub = action.payload
            if (state.trader && state.trader.subscriptions) {
                return {
                    ...state,
                    ...{
                        trader: {
                            trader: state.trader.trader,
                            subscriptions: state.trader.subscriptions.map((sub, index) => {
                                if (sub && sub._id !== removedSub._id) {
                                    return sub
                                }
                            })
                        }
                    }
                }
            } else {
                return state
            }
        }

        case TRADERS_TRADER_UPDATED: {
            let newState =  {
                ...state,
                ...{
                    trader: {
                        trader: {
                            ...state.trader.trader,
                            ...action.payload
                        },
                        subscriptions: state.trader.subscriptions
                    }
                }
            }
            return newState
        }

        case TRADERS_RATE_TRADER: {
            return {
                ...state,
                trader: {
                    ...state.trader,
                    trader: {
                        ...state.trader.trader,
                        ratings: {
                            ...state.trader.trader.ratings,
                            ...action.payload
                        }
                    }
                }
            }
        }

        case TRADERS_SUBSCRIPTION_GET: {
            let newState = {
                ...state,
                ...{openedSubscription: action.payload}
            }
            return newState
        }

        case TRADERS_SUBSCRIPTIONS_GET: {
            let newState = {
                ...state,
                ...{subscriptions: action.payload}
            }
            return newState
        }

        case TRADERS_USER_BUYS_SUBSCRIPTION: {
            let newState
            if (action.payload.error) {
                newState = {
                    ...state,
                    ...{error: action.payload.error}
                }
            } else {
                newState = {
                    ...state,
                    ...{openedSubscription: action.payload.subscription, error: action.payload.error}
                }
            }

            return newState
        }

        case TRADERS_GET_FOLLOWERS_GET_ALL: {
            let newState =  {
                ...state,
                followers: [...state.followers, ...action.payload.docs],
                totalDocs: action.payload.totalDocs,
                totalPages: action.payload.totalPages,
                pagesDownloaded: {...state.pagesDownloaded, ...{[action.payload.page]: true}}
            }
            return newState
        }

        case TRADERS_GET_FOLLOWERS_GET_INITIAL: {
            let newState =  {
                ...state,
                followers: action.payload.docs,
                totalDocs: action.payload.totalDocs,
                totalPages: action.payload.totalPages,
                pagesDownloaded: {[action.payload.page]: true}
            }
            return newState
        }

        case TRADERS_GET_FOLLOWER: {
            return {
                ...state,
                openedTraderFollower: action.payload
            }

        }

        case TRADERS_GET_SUBSCRIPTION_PAYMENTS_GET_ALL: {
            return {
                ...state,
                ...{
                    subscriptionPayments: [...state.subscriptionPayments, ...action.payload.docs],
                    totalDocs: action.payload.totalDocs,
                    totalPages: action.payload.totalPages,
                    pagesDownloaded: {...state.pagesDownloaded, ...{[action.payload.page]: true}}
                },
            }
        }

        case TRADERS_GET_SUBSCRIPTION_PAYMENTS_GET_INITIAL: {
            return {
                ...state,
                subscriptionPayments: action.payload.docs,
                totalDocs: action.payload.totalDocs,
                totalPages: action.payload.totalPages,
                pagesDownloaded: {[action.payload.page]: true}
            }
        }

        case TRADERS_DISABLED: {
            if (action.payload.status === "OK") {
                let updatedTradersDocs = state.users_docs.map((elem, index) => {
                    if (elem.trader && elem.trader._id === action.payload._id) {
                        elem.trader.status = c.TRADER_STATUS_DISABLED
                    }
                    return elem
                })
                return {...state, ...{users_docs: updatedTradersDocs}}
            } else {
                return state
            }
        }

        case TRADERS_ENABLED: {
            if (action.payload.status === "OK") {
                let updatedTradersDocs = state.users_docs.map((elem, index) => {
                    if (elem.trader && elem.trader._id === action.payload._id) {
                        elem.trader.status = c.TRADER_STATUS_ACTIVE
                    }
                    return elem
                })
                return {...state, ...{users_docs: updatedTradersDocs}}
            } else {
                return state
            }
        }

        case TRADERS_GET_COMMISSIONS_GET_ALL: {
            return {
                ...state,
                ...{
                    commissions: [...state.commissions, ...action.payload.docs],
                    totalDocs: action.payload.totalDocs,
                    totalPages: action.payload.totalPages,
                    pagesDownloaded: {...state.pagesDownloaded, ...{[action.payload.page]: true}}
                },
            }
        }

        case TRADERS_GET_COMMISSIONS_GET_INITIAL: {
            return {
                ...state,
                commissions: action.payload.docs,
                totalDocs: action.payload.totalDocs,
                totalPages: action.payload.totalPages,
                pagesDownloaded: {[action.payload.page]: true}
            }
        }

        case TRADERS_GET_THIS_MONTH: {
            return {
                ...state,
                thisMonthTraderCommissions: action.payload,
            }
        }

        default:
            return state
    }
}
