import React from "react";
import {TextField} from "@material-ui/core"
import InputAdornment from "@material-ui/core/InputAdornment"
import makeStyles from "@material-ui/core/styles/makeStyles"
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet'
import Warning from '@material-ui/icons/Warning'
import c from '../../constants'
import CustomSwitch from "./CustomSwitch";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(1),
        width: 270,
        backgroundColor: '#101A26'
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    }
}))

export default function SetPosSize(props) {
    const {posSize, handleChangePosSize,
        quote, base, actionType, tradeType,
        handleChangeTradeType,
        checkUserBalance,
        notEnoughBalance,
        otherPosSizeErrors, hideVariants} = props
    const classes = useStyles()

    function getQuoteLabel() {
        if (quote && quote.length > 0) {
            let _quote = quote.toUpperCase()
            if (_quote.length > 5) {
                return `${_quote.substring(0, 4)}...`
            } else
                return _quote
        } else return "..."
    }

    function getBaseLabel() {
        if (base && base.length > 0) {
            return base.toUpperCase()
        } else return "..."
    }

    function getPosSizeCurrency() {
        if (actionType === c.ACTION_TYPE_BUY) {
            if (tradeType === c.TRADE_TYPE_NEW_TRADE)
                return base.toUpperCase()
            else if (tradeType === c.TRADE_TYPE_OLD)
                return getQuoteLabel()
        } else if (actionType === c.ACTION_TYPE_SELL) {
            if (tradeType === c.TRADE_TYPE_NEW_TRADE)
                return getQuoteLabel()
            else if (tradeType === c.TRADE_TYPE_OLD)
                return base.toUpperCase()
        }
    }

    function getHelperText() {
        if (notEnoughBalance === c.USER_HAS_NOT_ENOUGH_BALANCE)
            return "Not enough balance"

        if (otherPosSizeErrors)
            return otherPosSizeErrors

        if (actionType === c.ACTION_TYPE_BUY) {
            if (tradeType === c.TRADE_TYPE_NEW_TRADE)
                return "Your position size"
            else if (tradeType === c.TRADE_TYPE_OLD)
                return `The field was auto-populated with the ${quote} you already ${getVerb()}`
        } else if (actionType === c.ACTION_TYPE_SELL) {
            return "Your position size"
        }

    }

    function getVerb() {
        if (actionType === c.ACTION_TYPE_BUY)
            return "bought"
        else if (actionType === c.ACTION_TYPE_SELL)
            return "sold"
    }

    function getOpacity() {
        if (tradeType === c.TRADE_TYPE_OLD)
            return "1"
        else return "0.6"

    }

    return (
    <div className={"wizardSectionContainer"}>
        <div className={"wizardTitle"}>
            <span className={"wizardTitleIcon"}><AccountBalanceWallet/></span> Position size
        </div>
        <div style={{marginLeft: "0.6em"}}>
            <div style={{marginLeft:"-2px"}}>
                <TextField
                    id="outlined-adornment-weight_set_pos_size"
                    className={classes.textField}
                    margin="dense"
                    variant="outlined"
                    placeholder={"Amount to trade"}
                    helperText={getHelperText()}
                    value={posSize}
                    onChange={handleChangePosSize}
                    onBlur={checkUserBalance}
                    error={(notEnoughBalance === 0) || otherPosSizeErrors}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{getPosSizeCurrency()}</InputAdornment>,
                    }}
                    inputProps={{ maxLength: 16 }}
                />
            </div>
            {!hideVariants && <div>
                <CustomSwitch
                    className={classes.switch}
                    defaultChecked={tradeType==c.TRADE_TYPE_OLD}
                    onChange={handleChangeTradeType}
                    color="primary"
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                />
                {actionType === c.ACTION_TYPE_BUY && <span style={{opacity: getOpacity()}}>I already bought {getQuoteLabel()}. Use it for this trade.</span>}
                {actionType === c.ACTION_TYPE_SELL && <span style={{opacity: getOpacity()}}>I already sold {getQuoteLabel()} for {getBaseLabel()}.</span>}
                {tradeType==c.TRADE_TYPE_OLD && <div style={{fontSize: "0.8rem", width: "23rem", textAlign: "justify", color: "#B8BBBE"}}>
                    <div><Warning/></div>
                    Unfortunately, you cannot place trades with ALTs that you've acquired by canceling other trades.<br/>
                    Please use the <b>Convertor</b> section for selling/buying.<br/>
                    </div>}
                <br/></div>}
        </div>
    </div>)
}
