import React, { useState, useEffect } from "react";
import c from '../../../constants'
import { Button, TextField } from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";

import { useDispatch, useSelector } from "react-redux";
import {
  getFuturesBalances,
  transfer,
  transferInternal,
} from "../../../redux/actions/wallet";
import { getUserByEmail } from "../../../redux/actions/users";
import { SpotCustomHeader } from "../../fncomponents/CustomHeaders";
import CircularProgress from "@material-ui/core/CircularProgress";
import {brushValue} from "../../fncomponents/UISupportFns";

const Transfer = (props) => {
  const { changeRenderCustomHeader } = props;

  const selectedTransferCoin = useSelector(
    (state) => state.wallet.selectedTransferCoin
  );

  const selectedBalanceCoin = useSelector(
      (state) => state.wallet.selectedBalanceCoin
  );

  const usdtSpot = useSelector((state) =>
    state.wallet.balances.find((balance) => balance.coin === "USDT")
  );

  const [transferButtonEnabled, setTransferButtonEnabled] = useState(true)
  const [showLoading, setShowLoading] = useState(false)
  const [isInternal, setIsInternal] = useState(selectedTransferCoin.isFuture);
  const [isFromSpot, setIsFromSpot] = useState(!selectedTransferCoin.isFuture);
  const [selectedCoin, setSelectedCoin] = useState({});
  const [transferAmount, setTransferAmount] = useState("");
  const [transferAmountError, setTransferAmountError] = useState(null);
  const [emailAddress, setEmailAddress] = useState("");
  const [isEmailAddressFound, setIsEmailAddressFound] = useState(null);
  const [errorOnTransferInternal, setErrorOnTransferInternal] = useState(false);
  const [errorOnTransferExternal, setErrorOnTransferExternal] = useState("");
  const [operationCompletedSuccessfully, setOperationCompletedSuccessfully] = useState(false);

  const { isFuture, coin, availableBalance, name } = selectedCoin;

  const dispatch = useDispatch();

  useEffect(() => {
    changeRenderCustomHeader(() => <SpotCustomHeader currentLevel={2}/>);
    getFuturesBalances()(dispatch);
  }, []);

  useEffect(() => {
    if (transferAmount === "") {
      return;
    }

    if (transferAmount > availableBalance) {
      setTransferAmountError("Insufficient balance");
    } else if (transferAmount < 0) {
      setTransferAmountError("Amount should be positive");
    } else {
      setTransferAmountError(null);
    }
  }, [transferAmount]);

  const errors = useSelector((state) => state.errors);

  const transferInternalError = useSelector(
    (state) => state.wallet.transferInternalError
  );

  const futureCoin = useSelector(
    (state) => state.wallet.futuresBalances.margin
  );

  useEffect(() => {
    setSelectedCoin(
      isInternal ? (isFromSpot ? usdtSpot : futureCoin) : selectedTransferCoin
    );

    setTransferAmount("");
  }, [isInternal, futureCoin, selectedTransferCoin, isFromSpot]);


  const checkEmailAddress = () => {
    getUserByEmail({ email: emailAddress }, (response) => {
        setErrorOnTransferExternal("")
        if (response.email) {
           setIsEmailAddressFound(response);
        } else if (response.self) {
          setErrorOnTransferExternal("Cannot transfer to self")
        } else {
          setIsEmailAddressFound(false);
        }
    })(dispatch);
  }

    const areFieldsValidForTransfer = (isInternal = false) => {
        let valid = true
        if (transferAmount === "") {
            setTransferAmountError("Please specify an amount")
            valid = false
        } else if (transferAmount > availableBalance) {
            setTransferAmountError("Insufficient balance")
            valid = false
        } else if (transferAmount < 0) {
            setTransferAmountError("Amount should be positive");
            valid = false
        } else if (parseFloat(transferAmount) === 0) {
            setTransferAmountError("Amount should be > 0");
            valid = false
        }

        if (!isInternal) {
            if (emailAddress.length === 0) {
                setIsEmailAddressFound(false)
                valid = false
            }

            if (errorOnTransferExternal.length > 0) {
                valid = false
            }
        }

        return valid
    }


    if (operationCompletedSuccessfully) {
        return (<div className="transfer__form">
            Please check your email to confirm your operation.
        </div>)
    } else {
        return (
            <div className="transfer__form">
                <div className="balance__top">
                    <img
                        src={`imgs/coins/${coin?.toUpperCase()}_${c.COIN_LOGOS_PIC_VERSION}.png`}
                        className="balance__coinLogo"
                        alt="icon"
                    />
                    <div className="balance__title">
                        <span>{coin}</span>
                        <span className="balance__dot"> · </span>
                        {isInternal && !isFromSpot? "FUTURES" : <span>{name}</span>}
                        {" "}
                        <span>Available</span>
                    </div>
                    <div className="balance__amount">{brushValue(availableBalance)}</div>
                    <Button
                        style={{
                            color: "#17BEBB",
                            display: "flex",
                            width: "161px",
                            margin: "0 auto",
                            height: "30px",
                            textAlign: "center",
                            textTransform: "none",
                        }}
                        onClick={() => setTransferAmount(brushValue(availableBalance))}
                    >
                        Use Max.
                    </Button>
                </div>
                <div className="transfer__buttons">
                    <Button
                        onClick={() => setIsInternal(true)}
                        style={{
                            borderBottom: isInternal ? "1px solid white" : "none",
                            color: !isInternal ? "grey" : "white",
                            borderRadius: "0",
                        }}
                    >
                        Internal
                    </Button>
                    {!isFuture && (
                        <Button
                            onClick={() => setIsInternal(false)}
                            style={{
                                borderBottom: !isInternal ? "1px solid white" : "none",
                                color: isInternal ? "grey" : "white",
                                borderRadius: "0",
                            }}
                        >
                            External
                        </Button>
                    )}
                </div>
                {isInternal && (
                    <div className="transfer__from-acc">
                        <Button
                            onClick={() => setIsFromSpot(!isFromSpot)}
                            style={{ borderRadius: "0" }}
                        >
                            {isFromSpot ? "Spot → Futures" : "Futures → Spot"}
                            &nbsp;&nbsp;<CachedIcon />
                        </Button>
                    </div>
                )}
                {isInternal && isFromSpot && <div className={"smallExplanations"} style={{width: "18rem", margin: "auto"}}>Transfer from <b>Spot</b> Wallet to <b>Futures USDT</b> Wallet</div>}
                {isInternal && !isFromSpot && <div className={"smallExplanations"} style={{width: "18rem", margin: "auto"}}>Transfer from <b>Futures USDT</b> Wallet to <b>Spot</b> Wallet</div>}
                <div className="transfer__amount">
                    <TextField
                        type="number"
                        variant="outlined"
                        placeholder="Transfer amount"
                        margin={"normal"}
                        value={transferAmount}
                        helperText={transferAmountError}
                        onChange={(e) => setTransferAmount(e.target.value)}
                        error={transferAmountError !== null}
                    />
                    {!isInternal && (
                        <TextField
                            variant="outlined"
                            error={isEmailAddressFound === false}
                            value={emailAddress}
                            label="Email address"
                            margin={"normal"}
                            onChange={(e) => setEmailAddress(e.target.value)}
                            onBlur={checkEmailAddress}
                        />
                    )}
                    {(!isInternal && errorOnTransferExternal.length === 0) &&
                    (isEmailAddressFound ? (
                        <div style={{marginTop: "0.7rem", marginBottom: "1rem", width: "22rem",
                            opacity: "0.8"}}>
                            {transferAmount > 0 && <div style={{display: "flex", justifyContent: "center"}}>Transfer&nbsp;<b>{transferAmount} {coin}</b>&nbsp;to</div>}
                            <div style={{display: "flex", justifyContent: "center"}}><b>{`${isEmailAddressFound.firstName} ${isEmailAddressFound.lastName} (${isEmailAddressFound.email})`}</b></div>
                        </div>
                    ) : (
                        <div style={{marginTop: "0.7rem", marginBottom: "1rem", width: "22rem", opacity: "0.7", display: "flex", justifyContent: "center"}}>No user selected/found</div>
                    ))}
                    {(!isInternal && errorOnTransferExternal.length > 0) && <div style={{marginTop: "0.7rem", marginBottom: "1rem", width: "22rem",
                        opacity: "0.9", color: "#fb4a4a", display: "flex", justifyContent: "center"}}>
                        {errorOnTransferExternal}</div>}
                    <Button
                        style={{
                            backgroundColor: "#17BEBB",
                            display: "flex",
                            width: "161px",
                            margin: "10px auto",
                            borderRadius: "32px",
                            height: "48px",
                            textAlign: "center",
                            color: "black"
                        }}
                        onClick={() => {
                            if (!transferButtonEnabled) return;
                            if (isInternal) {
                                if (!areFieldsValidForTransfer(true))
                                    return
                                setTransferButtonEnabled(false);
                                setShowLoading(true);
                                transferInternal({
                                    amount: transferAmount,
                                    coin: "USDT",
                                    from: "spot",
                                    to: "futures_usdt",
                                    _switch: !isFromSpot,
                                },error => {
                                    setTransferButtonEnabled(true);
                                    setShowLoading(false);
                                    if (error) {
                                        setErrorOnTransferInternal(true)
                                    }
                                })(dispatch)
                            } else {
                                if (!areFieldsValidForTransfer())
                                    return
                                setTransferButtonEnabled(false);
                                setShowLoading(true);
                                transfer({
                                        coin,
                                        toEmailAddress: emailAddress,
                                        amount: transferAmount,
                                    },
                                    data => {
                                        setTransferButtonEnabled(true);
                                        setShowLoading(false);
                                        if (data.error) {
                                            setErrorOnTransferExternal(data.error)
                                        } else if (data.status === "OK") {
                                            setOperationCompletedSuccessfully(true)
                                        }
                                    })(dispatch)
                            }
                        }}>
                        {showLoading && <CircularProgress size={18} style={{color: "black"}}/>}&nbsp;&nbsp;Transfer
                    </Button>
                    {errorOnTransferInternal && <div className={"errorGeneralMessageSmall"} style={{margin: "auto"}}>Error completing transfer...</div>}
                </div>
            </div>
        );
    }


}

export default Transfer
