import React from "react"
import {TextField} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import InputAdornment from "@material-ui/core/InputAdornment"
import {TrendingUp, TrendingDown, Timeline} from "@material-ui/icons"
import Fab from '@material-ui/core/Fab'
import CustomSwitch from "./CustomSwitch";
import c from '../../constants'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 270,
        backgroundColor: '#101A26'
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    fab: {
        // margin: theme.spacing(1),
        marginLeft: theme.spacing(0.6),
        backgroundColor: '#101A26',
        border: "1px solid #ffffff",
        color: "#ffffff"
    },
    fabSelected: {
        // margin: theme.spacing(),
        marginLeft: theme.spacing(0.6),
        backgroundColor: '#ffffff',
        border: "1px solid #000000",
        color: "#000000"
    },
    switcher: {
        checked: {
            backgroundColor: "#17BEBB"
        }
    }}
))

export default function TradeConditions(props) {
    const {stopLossOption, stopLossPriceOrPercent,
        handleChangeStopLossOption, handleChangeStopLossPriceOrPercent,
        base, noTitle} = props

    const classes = useStyles()
    const [trailingStopLossOption, setTrailingStopLossOption] = React.useState(false)
    const [showTitle, setShowTitle] = React.useState(true)

    React.useEffect(() => {
        // setProfitPriceOrPercent(takeProfitPriceOrPercent)
        // setStopPriceOrPercent(stopLossPriceOrPercent)
        if (stopLossOption == c.STOP_LOSS_OPTION_TRAILING)
            setTrailingStopLossOption(true)
        else
            setTrailingStopLossOption(false)

        if (noTitle) {
            setShowTitle(false)
        }

    })

    function getStopLossInputAdorment() {
        switch (stopLossOption) {
            case c.STOP_LOSS_OPTION_PERCENTAGE: return "%";
            case c.STOP_LOSS_OPTION_PRICE: return base;

        }
    }

    function getStopLossLabel() {
        switch (stopLossOption) {
            case c.STOP_LOSS_OPTION_PERCENTAGE: return "Stop loss at percent";
            case c.STOP_LOSS_OPTION_PRICE: return "Stop loss at price";
            case c.STOP_LOSS_OPTION_MANUAL: return "No stop loss";
            default: return "____________________";

        }
    }

    return (<div className={"wizardSectionContainer"}>
        {showTitle && <div className={"wizardTitle"}>
            <span className={"wizardTitleIcon"}><Timeline/></span> Set your trade conditions
        </div>}
        <div id={"stopLoss"} style={{display: "flex", marginTop: "1.6em",
            justifyContent: "space-between", flexWrap: "wrap", maxWidth: "25em"}}>
            <div>
                <div style={{marginLeft:"20px", fontSize:"0.8rem", opacity: "0.8"}}>{getStopLossLabel()}</div>
                <TextField
                    className={classes.textField}
                    variant={"outlined"}
                    margin={"dense"}
                    id="input-with-icon-textfield_trade_cond_for_bot"
                    value={stopLossPriceOrPercent}
                    onChange={handleChangeStopLossPriceOrPercent}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <TrendingDown/>
                                {stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE &&
                                <span>&nbsp;-</span>}
                            </InputAdornment>
                        ),
                        endAdornment: <InputAdornment position="end">{getStopLossInputAdorment()}</InputAdornment>
                    }}
                    inputProps={{ maxLength: 16 }}
                />
            </div>
            <div style={{marginTop: "1.05rem", width: "7rem"}}>
                <Fab color={ (stopLossOption == c.STOP_LOSS_OPTION_PERCENTAGE)
                    ? "primary": "default"} aria-label="Add"
                     className={(stopLossOption==c.STOP_LOSS_OPTION_PERCENTAGE) ?
                     classes.fabSelected: classes.fab} variant={"extended"}
                     onClick={() => {handleChangeStopLossOption(c.STOP_LOSS_OPTION_PERCENTAGE)}}>
                    %
                </Fab>
                <Fab color={stopLossOption==c.STOP_LOSS_OPTION_PRICE ? "primary": "default"}
                     aria-label="Add" className={stopLossOption==c.STOP_LOSS_OPTION_PRICE ? classes.fabSelected: classes.fab}
                     variant={"extended"}
                     onClick={() => {handleChangeStopLossOption(c.STOP_LOSS_OPTION_PRICE)}}>
                    #
                </Fab>
            </div>
        </div>
    </div>)
}
