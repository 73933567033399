import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getTitle} from "../../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"
import KeyboardBackArrow from '@material-ui/icons/KeyboardBackspace'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import c from "../../../constants"
import Fab from "@material-ui/core/Fab"
import {saveAndSendV1} from "../../../redux/actions/signals"
import {makeStyles} from "@material-ui/core"
import CircularIndeterminate from "../../fncomponents/loading"

const useStyles = makeStyles(theme => ({
    fab: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

function SendSignalButton(props) {
    const classes = useStyles()
    const {onClick, updated} = props

    return (
        <div>
            <Fab variant="extended" aria-label="Delete" className={classes.fab} onClick={onClick}>
                {updated && <span>Update Signal</span>}
                {!updated && <span>Send Signal</span>}
            </Fab>
        </div>
    )
}

function WaitingSignalToBeSent(props) {
    let {show} = props
    if (show)
        return (<div className={"modal"} key={"kdldkl"}>
            <div className={"modal-content"} key={"dddffff"}>
                <div className="loading">
                    <CircularIndeterminate/>
                </div>
                <div className={"loadingText"}>Sending signal...</div>
            </div>
        </div>)
    else
        return null
}

class PreviewSignalV1 extends Component {

    constructor(props) {
        super(props)
        this.state = {
            sendSignalPressed: false
        }
        this.sendSignal = this.sendSignal.bind(this)
    }

    sendSignal() {
        if (this.state.sendSignalPressed)
            return

        this.setState({
            showWaitSending: true,
            sendSignalPressed: true
        })
        this.props.send(this.props.signal, () => {
            this.setState({
                showWaitSending: false
            })
        })
    }

    render() {
        let s = this.props.signal

        let ss = this.props.signalSentResult
        let se = this.props.signalErrors && this.props.signalErrors.length > 0 ? this.props.signalErrors: undefined
        let sw = this.props.signalWarnings && this.props.signalWarnings.length > 0 ? this.props.signalWarnings: undefined
        let signalActionOrFuturesType ={
            [c.ACTION_TYPE_BUY]: "Starts with a buy ('LONG')",
            [c.ACTION_TYPE_SELL]: "Starts with a sell ('SHORT')",
            [c.FUTURES_TYPE_LONG]: `Long ${s ? s.leverage: ''}X`,
            [c.FUTURES_TYPE_SHORT]: `Short ${s ? s.leverage: ''}X`,
        }
        let subscriptionsHash = {}
        if (this.props.subscriptions) {
            this.props.subscriptions.map((elem, index) => {
                subscriptionsHash[elem._id] = elem
            })
        }
        let receivingSubscriptionsKeys = undefined
        let backLink = "signals"
        let signalType = ""
        let actionOrFuturesType = ''

        if (s) {
            receivingSubscriptionsKeys = Object.keys(s.receivingSubscriptions)
            if (s.signalType === c.SIGNAL_TYPE_SPOT) {
                backLink = "newsignal"
                signalType = <b>spot</b>
                actionOrFuturesType = s.actionType
            } else if (s.signalType === c.SIGNAL_TYPE_FUTURES) {
                backLink = "newsignalF"
                signalType = <b>futures</b>
                actionOrFuturesType = s.futuresType
            }
        }




        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <div className={"vertSpacer1"}/>
            <div style={{display: "flex", justifyContent: "flex-start", width: "20em", cursor: "pointer"}}>
                <div style={{display: "flex", justifyContent: "flex-start"}} onClick={()=>{this.props.history.push(backLink)}}>
                    <KeyboardBackArrow/>
                    <div className={"pageTitle"} style={{marginLeft: "1rem"}}>Back</div>
                </div>
                {ss && <div style={{display: "flex", justifyContent: "flex-start"}} onClick={()=>{this.props.history.push("/signals")}}>
                    <div className={"pageTitle"} style={{marginLeft: "1rem"}}>Signals</div>
                    <KeyboardArrowRight style={{marginTop: "0.2rem"}}/></div>}
            </div>
            <WaitingSignalToBeSent show={this.state.showWaitSending}/>
            {s && <div>
                <div className={"newSignalPreviewHighlight"}>
                    {(s.signalId && s.updated) && <span>Send and update for the following {signalType} signal:</span>}
                    {(s.signalId && !s.updated) && <span>Nothing was changed to the following {signalType} signal:</span>}
                    {!s.signalId && <span>Send the following {signalType} signal:</span>}
                    <div style={{marginLeft: "2rem"}}>
                        on <b>Binance</b>, <b>{signalActionOrFuturesType[actionOrFuturesType]}</b>, for pair <b>{s.quoteAsset}/{s.baseAsset}</b><br/>
                        entering prices <b>{s.enterTradePriceFrom}</b> - <b>{s.enterTradePriceTo}</b><br/>
                        take profit target(s):&nbsp;
                            {s.takeProfitTargets.map((elem, index) => {
                                return <span style={{fontWeight: "bold"}}>{s.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE && <span>+</span>}{elem}{s.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE && <span>%</span>}, </span>
                            })}
                            <br/>

                        {s.stopLossOption === c.STOP_LOSS_OPTION_CONDITIONAL && <div>
                            stop loss target:&nbsp;
                            <span style={{fontWeight: "bold"}}>{s.stopLossTarget} on close of {c.CANDLES_TYPES_HR_MAP[s.candlesType]} candles</span>
                        </div>}
                        {(s.stopLossOption !== c.STOP_LOSS_OPTION_MANUAL && s.stopLossOption !== c.STOP_LOSS_OPTION_CONDITIONAL) && <div>
                            stop loss target:&nbsp;
                            <span style={{fontWeight: "bold"}}>{s.stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE && <span>-</span>}{s.stopLossTarget}{s.stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE && <span>%</span>}</span>
                        </div>}
                        {s.stopLossOption === c.STOP_LOSS_OPTION_MANUAL && <div>
                            stop loss: <b>MANUAL</b>
                        </div>}
                    </div>
                    <div>
                        {!s.signalId && <span>to the following subscriptions:&nbsp;</span>}
                        {s.signalId && <span>receiving subscriptions:&nbsp;</span>}
                        {receivingSubscriptionsKeys.map((subscriptionId, index) => {
                            if (s.receivingSubscriptions[subscriptionId])
                                return <span style={{fontWeight: "bold"}}>{subscriptionsHash[subscriptionId].name}, </span>
                        })}
                    </div>
                </div>
                {(s.timeFrame || s.tvChartURL || s.comment) && <div className={"newSignalPreviewHighlight075"} style={{marginTop: "1rem"}}>
                    <b>Other details</b>
                    <div style={{marginLeft: "2rem"}}>
                        {s.timeFrame && <span>time frame <b>{s.timeFrame}</b>, </span>}
                        {s.tvChartURL && <span>chart <a href={s.tvChartURL}>{s.tvChartURL}</a>, </span>}
                        {s.comment && <div>comment - '{s.comment}'</div>}
                    </div>
                </div>}
                {se && <div className={"newSignalPreviewHighlight"}>
                    <div className={"errorContainer"}><b>Error(s)</b></div>
                    {se.map((err, index) => {
                        return <div className={"errorContainer"}>{err}</div>
                    })}
                </div>}
                {sw && <div className={"newSignalPreviewHighlight"}>
                    <div className={"warningContainer"}><b>Warning(s)</b></div>
                    {sw.map((warning, index) => {
                        return <div className={"warningContainer"}>{warning}</div>
                    })}
                </div>}
                {!se && <div>
                    {!s.signalId && <SendSignalButton onClick={()=>{this.sendSignal()}} updated={s.updated}/>}
                    {(s.signalId && s.updated) && <SendSignalButton onClick={()=>{this.sendSignal()}} updated={s.updated}/>}
                </div>}
            </div>
            }
            {ss &&  <div className={"newSignalPreviewHighlight"}>
                {ss.new && <div>Signal sent to {ss.sucsUpdated} users.</div>}
                {!ss.new &&
                    <div style={{display: "flex", justifyContent:"flex-start"}}>
                        <div>Signal update sent to</div>
                        {ss.sucsUpdated > 0 && <div>
                            {ss.sucsUpdated === 1 && <span>&nbsp;1 user signal copy</span>}
                            {ss.sucsUpdated > 1 && <span>&nbsp;{ss.sucsUpdated} user signal copies</span>}
                        </div>}
                        {ss.tradesUpdated > 0 && <div>
                            {ss.tradesUpdated === 1 && <span>&nbsp;1 trade (started from a signal)</span>}
                            {ss.tradesUpdated > 1 && <span>&nbsp;{ss.tradesUpdated} trades (started from a signal)</span>}
                        </div>}
                        <div>.</div>
                    </div>}
            </div>}
        </div>
    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    signal: rootState.signals.signal,
    signalSentResult: rootState.signals.signalSentResult,
    signalErrors: rootState.signals.errors,
    signalWarnings: rootState.signals.warnings,
    subscriptions: rootState.traders.subscriptions
})

let mapDispatchToProps = dispatch => {
    return {
        send: (props, cb) => dispatch(saveAndSendV1(props, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (PreviewSignalV1)
