import React, {Component} from 'react'
import { connect } from 'react-redux'
import Cookies from 'universal-cookie'
import {Redirect} from "react-router-dom"
import c from '../../../constants'

class RegisterViaAffiliate extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cookie: "unknown"
        }
    }

    componentDidMount() {
        if (this.props.match && this.props.match.params && this.props.match.params.ref) {
            const cookies = new Cookies()
            //The cookie will expire after 7 days
            cookies.set(c.REFERRAL_COOKIE, this.props.match.params.ref, {path: "/", maxAge: 604800})
        }
    }

    render() {
        return <Redirect to="/register"/>
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

const mapStateToProps = rootState => ({
})

export default
connect(mapStateToProps, mapDispatchToProps) (RegisterViaAffiliate)
