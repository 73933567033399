import React from 'react'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import Fab from "@material-ui/core/Fab";

const useStyles = makeStyles(theme => ({
    fabActivate: {
        margin: theme.spacing(1),
        width: 130,
        background: "#101926",
        border: "1px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        borderRadius: 32,
        color: "white",
        '&:hover': {
            background: "#424242"
        }
    }
}));


export default function ActivateFuturesOption(props) {
    const classes = useStyles();
    let { activateFutures} = props
    return (
        <div>
            <div className={"introTitle"}><b>Access Futures Trades</b></div>
            <div className={"introText"} style={{width: "30rem", textAlign: "justify", marginTop: "1rem"}}>
                <div>Start using our latest feature - Futures Trading on Binance.</div>
                <div style={{marginTop: "1rem", marginBottom: "1rem"}}>
                    <Fab variant="extended" color="default" aria-label="Activate" className={classes.fabActivate} onClick={activateFutures}>
                        <div style={{color: "white"}}>Activate</div>
                    </Fab>
                </div>
            </div>
        </div>
    )
}
