import React, {Component} from 'react'
import { connect } from 'react-redux'
import c from '../../../constants'
import StakeBalance from "./StakeBalance"
import { changeTab, getBalances, sendToSpot, stake, unstake } from "../../../redux/actions/staking";
import StakeUnstakeDialog from "../../fncomponents/StakeUnstakeDialog"
import SendToSpotDialog from "../../fncomponents/SendToSpotDialog"
import {debounce} from "../../hoc/myUtils"

const DEBOUNCE_DELAY_IN_MS = 500

class StakingWallet extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isStakeDialogOpen: false,
            isUnstakeDialogOpen: false,
            isSendToSpotDialogOpen: false,
            stakingAmt: 0,
            stakingBalance: undefined,
            stakingCoin: "",
            sendToSpotCoin: "",
            sendToSpotAmount: 0,
        }
        this.stake = this.stake.bind(this)
        this.handleChangeStakingAmt = this.handleChangeStakingAmt.bind(this)
        this.fixToStakingAmt = this.fixToStakingAmt.bind(this)
        this.debounceFixStakingAmt = debounce(this.fixToStakingAmt, DEBOUNCE_DELAY_IN_MS)
        this.useMaxAvailableAmt = this.useMaxAvailableAmt.bind(this)
        this.useMaxStakingAmt = this.useMaxStakingAmt.bind(this)
        this.showStakeDialog = this.showStakeDialog.bind(this)
        this.closeStakeDialog = this.closeStakeDialog.bind(this)
        this.unstake = this.unstake.bind(this)
        this.showUnstakeDialog = this.showUnstakeDialog.bind(this)
        this.closeUnstakeDialog = this.closeUnstakeDialog.bind(this)

        this.showSendToSpotDialog = this.showSendToSpotDialog.bind(this)
        this.handleChangeSendToSpotAmt = this.handleChangeSendToSpotAmt.bind(this)
        this.useMaxStakingAmtToSendToSpot = this.useMaxStakingAmtToSendToSpot.bind(this)
        this.fixToSpotAmt = this.fixToSpotAmt.bind(this)
        this.debounceFixToSpotAmt = debounce(this.fixToSpotAmt, DEBOUNCE_DELAY_IN_MS)
        this.sendToSpot = this.sendToSpot.bind(this)
        this.closeSendToSpotDialog = this.closeSendToSpotDialog.bind(this)
    }

    showSendToSpotDialog(balance) {
        this.setState({
            isSendToSpotDialogOpen: true,
            sendToSpotCoin: balance.coin,
            stakingBalance: balance,
            sendToSpotAmount: 0,
        })
    }


    closeSendToSpotDialog() {
        this.setState({
            isSendToSpotDialogOpen: false
        })
    }

    showUnstakeDialog(balance) {
        this.setState({
            isUnstakeDialogOpen: true,
            stakingCoin: balance.coin,
            stakingBalance: balance,
            stakingAmt: 0,
        })
    }

    closeUnstakeDialog() {
        this.setState({
            isUnstakeDialogOpen: false,
        })
    }

    showStakeDialog(balance) {
        this.setState({
            isStakeDialogOpen: true,
            stakingCoin: balance.coin,
            stakingBalance: balance,
            stakingAmt: balance.availableBalance,
        })
    }

    closeStakeDialog() {
        this.setState({
            isStakeDialogOpen: false,
        })
    }

    handleChangeStakingAmt(e) {
        this.setState({
            stakingAmt: e.target.value
        })
        this.debounceFixStakingAmt()
    }

    handleChangeSendToSpotAmt(e) {
        this.setState({
            sendToSpotAmount: e.target.value
        })
        this.debounceFixStakingAmt()
    }

    fixToStakingAmt() {
        let _v = 0
        try {
            _v = parseFloat(this.state.stakingAmt)
            if (isNaN(_v)) {
                _v = 0
            } else if (_v > this.state.stakingBalance.availableBalance) {
                _v = this.state.stakingBalance.availableBalance
            }
        } catch (e) {}
        this.setState({
            stakingAmt: _v
        })
    }

    fixToSpotAmt() {
        let _v = 0
        try {
            _v = parseFloat(this.state.sendToSpotCoin)
            if (isNaN(_v)) {
                _v = 0
            } else if (_v > this.state.stakingBalance.availableBalance) {
                _v = this.state.stakingBalance.availableBalance
            }
        } catch (e) {}
        this.setState({
            sendToSpotCoin: _v
        })
    }

    useMaxAvailableAmt() {
        this.setState({
            stakingAmt: this.state.stakingBalance.availableBalance
        })
    }

    useMaxStakingAmt() {
        this.setState({
            stakingAmt: this.state.stakingBalance.stakingBalance
        })
    }

    useMaxStakingAmtToSendToSpot() {
        this.setState({
            sendToSpotAmount: this.state.stakingBalance.availableBalance
        })
    }

    stake() {
        let _samt = parseFloat(this.state.stakingAmt)
        let _max = parseFloat(this.state.stakingBalance.availableBalance)
        if (0 < _samt && _samt <= _max) {
            this.props.stake({coin: this.state.stakingCoin, amount: this.state.stakingAmt, randomForSubmit: Math.random()}, () => {
                this.props.changeTab(c.STAKES_OPERATIONS_TAB)
            })
        }
    }

    unstake() {
        let _samt = parseFloat(this.state.stakingAmt)
        let _max = parseFloat(this.state.stakingBalance.stakingBalance)
        if (0< _samt && _samt <= _max) {
            this.props.unstake({coin: this.state.stakingCoin, amount: this.state.stakingAmt, randomForSubmit: Math.random()}, () => {
                this.props.changeTab(c.STAKES_OPERATIONS_TAB)
            })
        }
    }

    sendToSpot() {
        let _samt = parseFloat(this.state.sendToSpotAmount)
        let _max = parseFloat(this.state.stakingBalance.availableBalance)
        if (0< _samt && _samt <= _max) {
            this.setState({
                isSendToSpotDialogOpen: false
            })
            this.props.sendToSpot({coin: this.state.sendToSpotCoin, amount: this.state.sendToSpotAmount, randomForSubmit: Math.random()}, () => {
                this.props.getStakingBalances({})
            })
        }
    }

    componentDidMount() {
        if (this.props.selectedTab === c.STAKES_STAKES_TAB)
            this.props.getStakingBalances({}, data => {
                if (!data.balances) {
                    this.props.changeTab(c.STAKES_STAKES_PROGRAMS_TAB)
                }
            })
    }

    render() {
        return <div>
            <StakeBalance balances={this.props.stakingBalances}
                      actions={[{name: "Stake", behaviour: this.showStakeDialog, condition: b => b.availableBalance > 0},
                          {name: "Unstake", behaviour: this.showUnstakeDialog, condition: b => b.stakingBalance > 0},
                          {name: "Send to Spot", behaviour: this.showSendToSpotDialog, condition: b => b.availableBalance > 0}
                      ]}/>
            <StakeUnstakeDialog open={this.state.isStakeDialogOpen} title="Stake" saveLabel="Stake"
                                coin={this.state.stakingCoin}
                                stakingAmt={this.state.stakingAmt} stakingBalance={this.state.stakingBalance}
                                handleChangeStakingAmt={this.handleChangeStakingAmt}
                                max={this.state.stakingBalance ? this.state.stakingBalance.availableBalance: 0}
                                useMax={this.useMaxAvailableAmt}
                                handleSave={this.stake}
                                handleCancel={this.closeStakeDialog}
            />
            <StakeUnstakeDialog open={this.state.isUnstakeDialogOpen} title="Unstake" saveLabel="Unstake"
                                coin={this.state.stakingCoin}
                                stakingAmt={this.state.stakingAmt} stakingBalance={this.state.stakingBalance}
                                handleChangeStakingAmt={this.handleChangeStakingAmt}
                                max={this.state.stakingBalance ? this.state.stakingBalance.stakingBalance: 0}
                                useMax={this.useMaxStakingAmt}
                                handleSave={this.unstake}
                                handleCancel={this.closeUnstakeDialog}
            />
            <SendToSpotDialog open={this.state.isSendToSpotDialogOpen} saveLabel="Send"
                                coin={this.state.sendToSpotCoin}
                                sendToSpotAmount={this.state.sendToSpotAmount} stakingBalance={this.state.stakingBalance}
                                handleChangeSendToSpotAmt={this.handleChangeSendToSpotAmt}
                                max={this.state.stakingBalance ? this.state.stakingBalance.availableBalance: 0}
                                useMax={this.useMaxStakingAmtToSendToSpot}
                                handleSave={this.sendToSpot}
                                handleCancel={this.closeSendToSpotDialog}
            />
          </div>
    }

}

const mapStateToProps = rootState => ({
    stakingBalances: rootState.staking.balances,
    selectedTab: rootState.staking.selectedTab,
})

const mapDispatchToProps = dispatch => {
    return {
        changeTab: (props) => dispatch(changeTab(props)),
        getStakingBalances: (props, cb) => dispatch(getBalances(props, cb)),
        stake: (props, cb) => dispatch(stake(props, cb)),
        unstake: (props, cb) => dispatch(unstake(props, cb)),
        sendToSpot: (props, cb) => dispatch(sendToSpot(props, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (StakingWallet)
