import axios from './axiosFabric'
import {handleError} from "./errorHandler"
import {isAuthorizationHeaderPresent} from "./authentication"
import {
    SIGNALS_CLEAR_ERRORS,
    SIGNALS_CLEAR_SEND_RESULT,
    SIGNALS_ERRORS,
    SIGNALS_GET_RUNNING_INITIAL,
    SIGNALS_PREVIEW,
    SIGNALS_SELECT_TAB,
    SIGNALS_SEND,
    SIGNALS_CLEAR_CURRENT_SIGNAL,
    SIGNALS_GET_ALL_SIGNALS_NOTIFICATIONS,
    SIGNALS_READ_UNREAD_NOTIFICATION,
    SIGNALS_UPDATE_NOTIFICATION,
    SIGNALS_DISCARD_NOTIFICATION,
    SIGNALS_GET_SIGNALS_NOTIFICATIONS_INITIAL,
    SIGNALS_GET_WAITING_INITIAL_V1,
    SIGNALS_GET_WAITING_ALL_V1,
    SIGNALS_GET_RUNNING_INITIAL_V1,
    SIGNALS_GET_RUNNING_ALL_V1,
    SIGNALS_GET_CLOSED_INITIAL_V1,
    SIGNALS_GET_CLOSED_ALL_V1, SIGNALS_SET_CURRENT_PAGE, SIGNALS_SET_ROWS_PER_PAGE,

} from "./actionTypes";
import {disable2FA} from "./profile";

export const preview = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/signalsv1/preview`, props)
        .then(res => {
            if (cb) cb(res.data)
            dispatch({type: SIGNALS_CLEAR_ERRORS})
            dispatch({type: SIGNALS_CLEAR_SEND_RESULT})
            dispatch({
                type: SIGNALS_PREVIEW,
                payload: res.data
            })
        }).catch(error => {
            if (cb) cb()
            handleError(error, dispatch, SIGNALS_ERRORS)
        })
}

export const previewV1 = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/signalsv1/preview`, props)
        .then(res => {
            if (cb) cb(res.data)
            dispatch({type: SIGNALS_CLEAR_ERRORS})
            dispatch({type: SIGNALS_CLEAR_SEND_RESULT})
            dispatch({
                type: SIGNALS_PREVIEW,
                payload: res.data
            })
        }).catch(error => {
        if (cb) cb()
        handleError(error, dispatch, SIGNALS_ERRORS)
    })
}

export const saveAndSendV1 = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/signalsv1/saveandsend`, props)
        .then(res => {
            if (cb) cb(res.data)
            dispatch({type: SIGNALS_CLEAR_ERRORS})
            console.log("saveAndSednV1", JSON.stringify(res.data))
            dispatch({
                type: SIGNALS_SEND,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch, SIGNALS_ERRORS))
}

export const getWaitingSignalsInitialV1 = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    props.page = 0
    axios.post(`/signalsv1/getwaitingbypage`, props)
        .then(res => {
            dispatch({
                type: SIGNALS_GET_WAITING_INITIAL_V1,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getWaitingSignalsByPageV1 = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/signalsv1/getwaitingbypage`, props)
        .then(res => {
            dispatch({
                type: SIGNALS_GET_WAITING_ALL_V1,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getRunningSignalsInitialV1 = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    props.page = 0
    axios.post(`/signalsv1/getrunningbypage`, props)
        .then(res => {
            dispatch({
                type: SIGNALS_GET_RUNNING_INITIAL_V1,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getRunningSignalsByPageV1 = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/signalsv1/getrunningbypage`, props)
        .then(res => {
            dispatch({
                type: SIGNALS_GET_RUNNING_ALL_V1,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getClosedSignalsInitialV1 = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    props.page = 0
    axios.post(`/signalsv1/getclosedbypage`, props)
        .then(res => {
            dispatch({
                type: SIGNALS_GET_CLOSED_INITIAL_V1,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getClosedSignalsByPageV1 = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/signalsv1/getclosedbypage`, props)
        .then(res => {
            dispatch({
                type: SIGNALS_GET_CLOSED_ALL_V1,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const selectTab = (tab) => dispatch => {
    dispatch({
        type: SIGNALS_SELECT_TAB,
        payload: tab
    })
}

export const getSignalByIdV1 = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/signalsv1/getbyid`, props)
        .then(res => {
            if (cb) cb(res.data)
        }).catch(error => handleError(error, dispatch))
}

export const clearCurrentSignal = (cb) => dispatch => {
    dispatch({
        type: SIGNALS_CLEAR_CURRENT_SIGNAL
    })
    if (cb) cb()
}

export const closeSignal = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/signalsv1/close`, props)
        .then(res => {
            delete props.signalId
            axios.post(`/signalsv1/getrunningbypage`, props)
                .then(res => {
                    dispatch({
                        type: SIGNALS_GET_RUNNING_INITIAL,
                        payload: res.data
                    })
                }).catch(error => handleError(error, dispatch))
        }).catch(error => handleError(error, dispatch))
}

export const closeWaitingSignalV1 = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/signalsv1/close`, props)
        .then(res => {
            delete props.signalId
            axios.post(`/signalsv1/getwaitingbypage`, props)
                .then(res => {
                    if (cb) cb()
                    dispatch({
                        type: SIGNALS_GET_WAITING_INITIAL_V1,
                        payload: res.data
                    })
                }).catch(error => handleError(error, dispatch))
        }).catch(error => handleError(error, dispatch))
}

export const closeRunningSignalV1 = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/signalsv1/close`, props)
        .then(res => {
            delete props.signalId
            axios.post(`/signalsv1/getrunningbypage`, props)
                .then(res => {
                    if (cb) cb()
                    dispatch({
                        type: SIGNALS_GET_RUNNING_INITIAL_V1,
                        payload: res.data
                    })
                }).catch(error => handleError(error, dispatch))
        }).catch(error => handleError(error, dispatch))
}

export const getSignalsNotificationsInitial = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/notif/signals/getbypage`, props)
        .then(res => {
            dispatch({
                type: SIGNALS_GET_SIGNALS_NOTIFICATIONS_INITIAL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getSignalsNotificationsByPage = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/notif/signals/getbypage`, props)
        .then(res => {
            dispatch({
                type: SIGNALS_GET_ALL_SIGNALS_NOTIFICATIONS,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const readUnreadSignalsNotification = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/notif/signals/readunread`, props)
        .then(res => {
            dispatch({
                type: SIGNALS_READ_UNREAD_NOTIFICATION,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const acceptRequestToJoin = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/notif/signals/accept`, props)
        .then(res => {
            if (cb) cb()
            dispatch({
                type: SIGNALS_UPDATE_NOTIFICATION,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const rejectRequestToJoin = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/notif/signals/reject`, props)
        .then(res => {
            if (cb) cb()
            dispatch({
                type: SIGNALS_UPDATE_NOTIFICATION,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const discardRequestToJoin = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/notif/signals/discard`, props)
        .then(res => {
            if (cb) cb()
            dispatch({
                type: SIGNALS_DISCARD_NOTIFICATION,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const checkEnterTradeInterval = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/signalsv1/checkentertradeinterval`, props)
        .then(res => {
            if (cb) cb(res.data)
            // dispatch({type: SIGNALS_CLEAR_ERRORS})
            // dispatch({
            //     type: SIGNALS_PREVIEW,
            //     payload: res.data
            // })
        }).catch(error => {
        if (cb) cb()
        handleError(error, dispatch, SIGNALS_ERRORS)
    })
}

export const checkEnterTradeIntervalFutures = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/signalsv1/checkentertradeintervalfutures`, props)
        .then(res => {
            if (cb) cb(res.data)
            // dispatch({type: SIGNALS_CLEAR_ERRORS})
            // dispatch({
            //     type: SIGNALS_PREVIEW,
            //     payload: res.data
            // })
        }).catch(error => {
        if (cb) cb()
        handleError(error, dispatch, SIGNALS_ERRORS)
    })
}

export const setCurrentPage = currentPage => dispatch => {
    dispatch({
        type: SIGNALS_SET_CURRENT_PAGE,
        payload: currentPage
    })
}

export const setRowsPerPage = rowsPerPage => dispatch => {
    dispatch({
        type: SIGNALS_SET_ROWS_PER_PAGE,
        payload: rowsPerPage
    })
}