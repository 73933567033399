import React from 'react'
import c from "../../constants";
import Fab from "@material-ui/core/Fab";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {RadioGroup} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import {calculateJustAboveBidPrice, brushValue} from "./UISupportFns";

const useStyles = makeStyles(theme => ({
        fabCancel: {
            margin: theme.spacing(1),
            width: 140,
            background: "#101926",
            border: "1px solid rgba(255, 255, 255, 0.12)",
            boxSizing: "border-box",
            borderRadius: 32,
            color: "white",
            '&:hover': {
                background: "#424242"
            }
        },
        fabTakeProfit: {
            margin: theme.spacing(1),
            background: "white",
            border: "1px solid rgba(255, 255, 255, 0.12)",
            boxSizing: "border-box",
            borderRadius: 32,
            color: "#101926",
            '&:hover': {
                background: "#424242",
                color: "white"
            }
        },
        radioGroup: {
            margin: theme.spacing(1, 0),
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
            backgroundColor: '#080F19'
        },
}
))

export default function TakeProfitConditions(props) {
    const {trade, currentPrice, executeTakeProfitOption, executeTakeProfitPrice,
        executeTakeProfitPriceWarning, executeTakeProfitPriceError,
        handleChangeExecuteTakeProfitOption,
        handleChangeExecuteTakeProfitPrice, executeTakeProfit, nevermind} = props
    const classes = useStyles()
    const [_executeTakeProfitOption, setExecuteTakeProfitOption] = React.useState(0)
    // const [_executeTakeProfitPrice, setExecuteTakeProfitPrice] = React.useState(0)
    // const [_executeTakeProfitPriceJAB, setExecuteTakeProfitPriceJAB] = React.useState(0)
    //
    React.useEffect(()=>{
        setExecuteTakeProfitOption(executeTakeProfitOption)
        // let jABPrice = calculateJustAboveBidPrice(currentPrice)
        // setExecuteTakeProfitPriceJAB(jABPrice)
        // if (executeTakeProfitOption == "market"){
        //     setExecuteTakeProfitPrice(brushValue(currentPrice, 8))
        // }else if (executeTakeProfitOption == "just_above_bid_price") {
        //     setExecuteTakeProfitPrice(brushValue(jABPrice, 8))
        // } else if (executeTakeProfitOption == "fixed_price") {
        //     setExecuteTakeProfitPrice(brushValue(executeTakeProfitPrice, 8))
        // }
    })

    function getVerb() {
        if (!trade)
            return "sell"
        if (!trade.action_type)
            return "sell"
        if (trade.action_type === c.ACTION_TYPE_BUY) {
            return "sell"
        } else if (trade.action_type === c.ACTION_TYPE_SELL) {
            return "buy"
        }
    }

    function handleChange(event) {
        handleChangeExecuteTakeProfitOption(event.target.value)
    }

    function handleChangePrice(event) {
        handleChangeExecuteTakeProfitPrice(event.target.value)
    }

    function isTextFieldDisabled() {
        if (_executeTakeProfitOption === "fixed_price")
            return false
        return true
    }
    return <div style={{display: "flex", marginTop: "1em",
        justifyContent: "flex-start", flexWrap: "wrap", maxWidth: "22em"}}>
        <div>Are you sure you want to {getVerb()}?</div>
        <div style={{display: "flex", marginTop: "1em",
            justifyContent: "flex-start", flexWrap: "wrap", maxWidth: "22em"}}>
            <RadioGroup
                aria-label="takeProfitOptionSelector"
                name="takeProfitOption"
                className={classes.radioGroup}
                value={_executeTakeProfitOption}
                onChange={handleChange}
            >
                <FormControlLabel value="market" control={<Radio color="primary"/>} label="@ market" />
                {trade.action_type === c.ACTION_TYPE_BUY &&
                    <FormControlLabel value="just_above_bid_price" control={<Radio color="primary"/>} label={`@ just above bid (${executeTakeProfitPrice})`} />}
                {trade.action_type === c.ACTION_TYPE_SELL &&
                    <FormControlLabel value="just_bellow_ask_price" control={<Radio color="primary"/>} label={`@ just bellow ask (${executeTakeProfitPrice})`} />}
                <FormControlLabel value="fixed_price" control={<Radio color="primary"/>} label={`@ specific`} />
                <TextField className={classes.textField}  variant="outlined"
                           value={executeTakeProfitPrice}
                           error={!!executeTakeProfitPriceError}
                           onChange={handleChangePrice} disabled={isTextFieldDisabled()}
                            style={{marginLeft: "2rem"}} inputProps={{ maxLength: 16 }} onClick={() => handleChangeExecuteTakeProfitOption("fixed_price")}/>
                {executeTakeProfitPriceError && <div className={"errorGeneralMessageSmall"} style={{margin: "1.7rem"}}>{executeTakeProfitPriceError}</div>}
                {executeTakeProfitPriceWarning && <div className={"warningGeneralMessageSmall"} style={{margin: "1.7rem"}}>{executeTakeProfitPriceWarning}</div>}
            </RadioGroup>
            <div className={"tradeDetailsMainButtonsContainer"}>
                <Fab color="primary"
                     aria-label="Yes, take profit" className={classes.fabTakeProfit}
                     variant={"extended"}
                    onClick={()=>{executeTakeProfit()}}>
                    Yes, take profit!
                </Fab>
                <Fab color="default"
                     aria-label="Yes, take profit" className={classes.fabCancel}
                     variant={"extended"}
                    onClick={()=>{nevermind()}}>Nevermind</Fab>
            </div>
        </div>
    </div>
}
