import { combineReducers } from 'redux';
import errorsReducer from './errors'
import authenticationReducer from './authentication'
import walletReducer from './wallet'
import profileReducer from './profile'
import newTradeReducer from './newTrade'
import tradesReducer from './trades'
import signalsReducer from './signals'
import usersGroupsReducer from './usersGroups'
import sucsReducer from './sucs'
import botsReducer from './bots'
import tradersReducer from './traders'
import administrationReducer from './administration'
import usersReducer from './users'
import orderBookReducer from './orderbook'
import newFuturesReducer from './newFutures'
import stakingReducer from './staking'
import futuresReducer from './futures'
import investmentReducer from './investment'
import whaReducer from "./wha"

let appReducer = combineReducers({
    errors: errorsReducer,
    authentication: authenticationReducer,
    wallet: walletReducer,
    profile: profileReducer,
    newTrade: newTradeReducer,
    trades: tradesReducer,
    signals: signalsReducer,
    usersGroups: usersGroupsReducer,
    sucs: sucsReducer,
    bots: botsReducer,
    traders: tradersReducer,
    administration: administrationReducer,
    users: usersReducer,
    orderBook: orderBookReducer,
    newFutures: newFuturesReducer,
    staking: stakingReducer,
    futures: futuresReducer,
    investment: investmentReducer,
    wha: whaReducer
});


export default (state, action) => {
    return appReducer(state, action)
}
