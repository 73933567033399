import {
    CHECK_USER_BALANCE, CLEAR_NOT_ENOUGH_BALANCE,
    GET_CURRENCIES_MAP,
    GET_CURRENT_PRICE,
    GET_LAST_USED_PARAMS_ON_NEW_TRADE, GET_ORDER_BOOK,
    GET_USER_BASE_BALANCE,
    GET_USER_QUOTE_BALANCE, LAUNCH_NEW_EXCHANGE,
    LAUNCH_NEW_TRADE, PREVIEW_NEW_EXCHANGE,
    PREVIEW_NEW_TRADE
} from "../actions/actionTypes";

export default function (state = { lastUsed: {}, newTrade: {}, newExchange: {}}, action) {
    switch (action.type) {

        case GET_CURRENCIES_MAP: {
            return {
                ...state,
                ...{baseCurrenciesNom: action.payload.baseCurrenciesNom,
                    quoteCurrenciesNom: action.payload.quoteCurrenciesNom}
            }
        }

        case GET_CURRENT_PRICE: {
            return {
                ...state,
                ...{
                    currentLastBidPrice: action.payload.currentLastBidPrice,
                    precision: action.payload.precision
                }
            }
        }

        case GET_LAST_USED_PARAMS_ON_NEW_TRADE: {
            return {
                ...state,
                ...{lastUsed: {
                        actionType: action.payload.actionType,
                        base: action.payload.base,
                        quote: action.payload.quote,
                        tradeType: action.payload.tradeType,
                        posSize: action.payload.posSize,
                        actionTypeOption: action.payload.actionTypeOption,
                        takeProfitOption: action.payload.takeProfitOption,
                        takeProfitPriceOrPercent: action.payload.takeProfitPriceOrPercent,
                        stopLossOption: action.payload.stopLossOption,
                        stopLossPriceOrPercent: action.payload.stopLossPriceOrPercent,
                    },
                    currentLastBidPrice: action.payload.currentLastBidPrice
                }
            }
        }

        case PREVIEW_NEW_TRADE: {
            return {
                ...state,
                ...{newTrade: {
                        actionType: action.payload.actionType,
                        base: action.payload.base,
                        quote: action.payload.quote,
                        tradeType: action.payload.tradeType,
                        posSize: action.payload.posSize,
                        posSizeCurrency: action.payload.posSizeCurrency,
                        fixedPrice: action.payload.fixedPrice,
                        currentPrice: action.payload.currentPrice,
                        actionTypeOption: action.payload.actionTypeOption,
                        takeProfitOption: action.payload.takeProfitOption,
                        takeProfitPriceOrPercent: action.payload.takeProfitPriceOrPercent,
                        stopLossOption: action.payload.stopLossOption,
                        stopLossPriceOrPercent: action.payload.stopLossPriceOrPercent,
                        candlesType: action.payload.candlesType
                    }
                }
            }
        }

        case PREVIEW_NEW_EXCHANGE: {
            return {
                ...state,
                ...{newExchange: {
                        actionType: action.payload.actionType,
                        base: action.payload.base,
                        quote: action.payload.quote,
                        tradeType: action.payload.tradeType,
                        posSize: action.payload.posSize,
                        posSizeCurrency: action.payload.posSizeCurrency,
                        fixedPrice: action.payload.fixedPrice,
                        currentPrice: action.payload.currentPrice,
                        actionTypeOption: action.payload.actionTypeOption,
                        amount: action.payload.amount
                    }
                }
            }
        }

        case LAUNCH_NEW_TRADE: {
            let newState = {...state, ...{}}
            newState.newTrade.tradeLaunched = Math.random() * 1000
            return newState
        }

        case LAUNCH_NEW_EXCHANGE: {
            let newState = {...state, ...{}}
            newState.newExchange.exchangeLaunched = Math.random() * 1000
            return newState
        }

        case CHECK_USER_BALANCE: {
            return {
                ...state,
                ...{notEnoughBalance: action.payload.result.status}
            }
        }

        case CLEAR_NOT_ENOUGH_BALANCE: {
            return {
                ...state,
                ...{notEnoughBalance: 1}
            }
        }

        case GET_USER_BASE_BALANCE: {
            return {
                ...state,
                ...{userBaseBalance: action.payload.value, userBasePrecision: action.payload.precision}
            }
        }

        case GET_USER_QUOTE_BALANCE: {
            return {
                ...state,
                ...{userQuoteBalance: action.payload.value,
                    userQuotePrecision: action.payload.precision,
                }
            }
        }

        case GET_ORDER_BOOK: {
            return {
                ...state,
                ...{orderBook: action.payload}
            }
        }

        default:
            return state
    }
}
