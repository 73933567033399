import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import {connect} from "react-redux"
import KeyboardBackArrow from '@material-ui/icons/KeyboardBackspace'
import Warning from '@material-ui/icons/Warning'
import c from '../../constants'
import { makeStyles } from '@material-ui/core/styles'
import Fab from '@material-ui/core/Fab'
import {launchNewTrade} from "../../redux/actions/newTrade"
import {brushValue} from "../fncomponents/UISupportFns"
import {getTitle} from "../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"

const useStyles = makeStyles(theme => ({
    fab: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

function getFixedPriceWarning(actionType, actionTypeOption, fixedPrice, currentPrice) {
    if (actionTypeOption === c.ACTION_TYPE_OPTION_FIXED_PRICE) {
        let fixedPriceF = parseFloat(fixedPrice)
        let currentPriceF = parseFloat(currentPrice)
        let delta = (fixedPriceF - currentPriceF) / currentPriceF
        if (actionType === c.ACTION_TYPE_SELL)
            delta *= -1
        if (delta >= 0.1) {
            if (actionType === c.ACTION_TYPE_BUY) {
                return <div className={"warning"}>
                    <Warning/> buy price is current price +{(delta * 100).toFixed(2)}%
                </div>
            } else if (actionType === c.ACTION_TYPE_SELL) {
                return <div className={"warning"}>
                    <Warning/> sell price is current price -{(delta * 100).toFixed(2)}%
                </div>
            }
        }
    }
}

function LaunchTradeButton(props) {
    const classes = useStyles()
    const {onClick, enabled} = props

    return (
        <div>
            <Fab variant="extended" aria-label="Delete" disabled={!enabled} className={classes.fab} onClick={onClick}>
                Launch Trade
            </Fab>
        </div>
    )
}


class PreviewTrade extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tradeLaunched: this.props.tradeLaunched,
            launchNewTradeBtnEnabled: true,
            launchNewTradePressed: false
        }

        this.getFirstParagraph = this.getFirstParagraph.bind(this)
        this.getSecondParagraph = this.getSecondParagraph.bind(this)
        this.getThirdParagraph = this.getThirdParagraph.bind(this)

        this.launchNewTrade = this.launchNewTrade.bind(this)
        this.didNewTradeLaunched = this.didNewTradeLaunched.bind(this)
    }

    getFirstParagraph() {
        let {actionType, tradeType, actionTypeOption,
            base, quote, posSize, userQuoteBalance, fixedPrice, currentPrice} = this.props
        fixedPrice = brushValue(fixedPrice)
        let firstPart = ''
        let secondPart = []
        let quoteArticle = ''
        let vowelRegex = '^[aieouAIEOU].*'
        if (quote && quote.match(vowelRegex)) {
            quoteArticle = 'an'
        } else {
            quoteArticle = 'a'
        }

        if (actionType === c.ACTION_TYPE_BUY) {
            if (tradeType === c.TRADE_TYPE_NEW_TRADE) {
                firstPart = <div>Open {quoteArticle} <span className={"newTPHBold"}>{quote}/{base}</span> trade for <span className={"newTPHBold"}>{posSize} {base}</span></div>
                if (actionTypeOption === c.ACTION_TYPE_OPTION_MARKET) {
                    secondPart.push(<div key={`second_part_${Math.random()}`}>by <u>buying</u> {quote} at <span className={"newTPHBold"}>market price</span>.</div>)
                } else if (actionTypeOption === c.ACTION_TYPE_OPTION_FIXED_PRICE) {
                    secondPart.push(<div key={`second_part_${Math.random()}`}>by <u>buying</u> {quote} at a <span className={"newTPHBold"}>fixed price</span> of <span className={"newTPHBold"}>{fixedPrice}</span>.</div>)
                    secondPart.push(getFixedPriceWarning(actionType, actionTypeOption, fixedPrice, currentPrice))
                } else if (actionTypeOption === c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE) {
                    secondPart.push(<div key={`second_part_${Math.random()}`}>by <u>buying</u> {quote} <span className={"newTPHBold"}>just above the first bid ({fixedPrice})</span>.</div>)
                } else if (actionTypeOption === c.ACTION_TYPE_OPTION_WAIT_FOR_A_DIP) {
                    secondPart.push(<div key={`second_part_${Math.random()}`}>by <u>waiting for a price dip</u> &le; <span className={"newTPHBold"}>{fixedPrice}</span> before launching trade.</div>)
                }
            } else if (tradeType === c.TRADE_TYPE_OLD) {
                firstPart = <div>Open {quoteArticle} <span className={"newTPHBold"}>{quote}/{base}</span> trade with <u>my</u> <span className={"newTPHBold"}>{posSize} {quote}</span></div>
                if (actionTypeOption === c.ACTION_TYPE_OPTION_MARKET) {
                    secondPart.push(<div key={`second_part_${Math.random()}`}><u>bought</u> at current <span className={"newTPHBold"}>market price</span>.</div>)
                } else if (actionTypeOption === c.ACTION_TYPE_OPTION_FIXED_PRICE) {
                    secondPart.push(<div key={`second_part_${Math.random()}`}><u>bought</u> at the <span className={"newTPHBold"}>fixed price</span> of <span className={"newTPHBold"}>{fixedPrice}</span>.</div>)
                    secondPart.push(<div key={`second_part_${Math.random()}`}>getFixedPriceWarning(actionType, actionTypeOption, fixedPrice, currentPrice)</div>)
                }
            }
        } else if (actionType === c.ACTION_TYPE_SELL) {
            if (tradeType === c.TRADE_TYPE_NEW_TRADE) {
                firstPart = <div>Open {quoteArticle} <span className={"newTPHBold"}>{quote}/{base}</span> trade for <span className={"newTPHBold"}>{posSize} {quote}</span></div>
                if (actionTypeOption === c.ACTION_TYPE_OPTION_MARKET) {
                    secondPart.push(<div key={`second_part_${Math.random()}`}>by <u>selling</u> {quote} at <span className={"newTPHBold"}>market price</span>.</div>)
                } else if (actionTypeOption === c.ACTION_TYPE_OPTION_FIXED_PRICE) {
                    secondPart.push(<div key={`second_part_${Math.random()}`}>by <u>selling</u> {quote} at a <span className={"newTPHBold"}>fixed price</span> of <span className={"newTPHBold"}>{fixedPrice}</span>.</div>)
                    secondPart.push(<div key={`second_part_${Math.random()}`}>getFixedPriceWarning(actionType, actionTypeOption, fixedPrice, currentPrice)</div>)
                } else if (actionTypeOption === c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE) {
                    secondPart.push(<div key={`second_part_${Math.random()}`}>by <u>selling</u> {quote} <span className={"newTPHBold"}>below the first ask ({fixedPrice})</span>.</div>)
                }
            } else if (tradeType === c.TRADE_TYPE_OLD) {
                firstPart = <div>Open {quoteArticle} <span className={"newTPHBold"}>{quote}/{base}</span> trade for <span className={"newTPHBold"}>{posSize} {base}</span></div>
                if (actionTypeOption === c.ACTION_TYPE_OPTION_MARKET) {
                    secondPart.push(<div key={`second_part_${Math.random()}`}>considering <u>my</u> {quote} <u>sold</u> at current <span className={"newTPHBold"}>market price</span>.</div>)
                } else if (actionTypeOption === c.ACTION_TYPE_OPTION_FIXED_PRICE) {
                    secondPart.push(<div key={`second_part_${Math.random()}`}>considering <u>my</u> {quote} <u>sold</u> at the <span className={"newTPHBold"}>fixed price</span> of <span className={"newTPHBold"}>{fixedPrice}</span>.</div>)
                    secondPart.push(<div key={`second_part_${Math.random()}`}>getFixedPriceWarning(actionType, actionTypeOption, fixedPrice, currentPrice)</div>)
                }
            }
        }

        return <div>{firstPart} {secondPart}</div>
    }

    getSecondParagraph() {
        let {takeProfitOption, takeProfitPriceOrPercent, base, actionType} = this.props
        takeProfitPriceOrPercent = brushValue(takeProfitPriceOrPercent)
        let how = ""
        if (actionType === c.ACTION_TYPE_BUY)
            how = "selling"
        else if (actionType === c.ACTION_TYPE_SELL)
            how = "buying"
        if (takeProfitOption === c.TAKE_PROFIT_OPTION_MANUAL) {
            return <div className={"newTPHBold"}>Manual take profit</div>
        } else if (takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE) {
            return <div><span className={"newTPHBold"}>Take profit</span> <u>{how}</u> at <span className={"newTPHBold"}>{takeProfitPriceOrPercent} {base}</span></div>
        } else if (takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
            return <div><span className={"newTPHBold"}>Take profit</span> <u>{how}</u> with <span className={"newTPHBold"}>+{takeProfitPriceOrPercent}%</span> profit</div>
        }
    }

    getThirdParagraph() {
        let {stopLossOption, stopLossPriceOrPercent, base, actionType, candlesType} = this.props
        stopLossPriceOrPercent = brushValue(stopLossPriceOrPercent)
        let how = ""
        if (actionType === c.ACTION_TYPE_BUY)
            how = "selling"
        else if (actionType === c.ACTION_TYPE_SELL)
            how = "buying"
        if (stopLossOption === c.STOP_LOSS_OPTION_TRAILING) {
            return <div><span className={"newTPHBold"}>Trailing stop loss</span> at <span className={"newTPHBold"}>-{stopLossPriceOrPercent}%</span>
            </div>
        } else if (stopLossOption === c.STOP_LOSS_OPTION_PRICE) {
            return <div><span className={"newTPHBold"}>Stop loss</span> <u>{how}</u> at <span className={"newTPHBold"}>{stopLossPriceOrPercent} {base}</span></div>
        } else if (stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE) {
            return <div><span className={"newTPHBold"}>Stop loss</span> <u>{how}</u> with <span className={"newTPHBold"}>-{stopLossPriceOrPercent}%</span> loss</div>
        } else if (stopLossOption === c.STOP_LOSS_OPTION_MANUAL) {
            return <div><span className={"newTPHBold"}>Stop loss</span> <u>MANUAL</u></div>
        } else if (stopLossOption === c.STOP_LOSS_OPTION_CONDITIONAL) {
            return <div><span className={"newTPHBold"}>Stop loss</span> by <u>{how}</u> if price on closing of the <span className={"newTPHBold"}>{c.CANDLES_TYPES_HR_MAP[candlesType]}</span> candles goes below <span className={"newTPHBold"}>{stopLossPriceOrPercent} {base}</span></div>
        }
    }

    launchNewTrade() {
        if (this.state.launchNewTradePressed)
            return
        this.setState({
            launchNewTradeBtnEnabled: false,
            launchNewTradePressed: true
        })
        this.props.launchNewTrade(() => {
            this.setState({
                launchNewTradeBtnEnabled: true
            })
        })
    }

    didNewTradeLaunched() {
        return this.state.tradeLaunched !== this.props.tradeLaunched
    }

    render() {
        if (this.didNewTradeLaunched() || !this.props.posSize)
            return <Redirect to="/"/>

        let error = this.props.error
        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <div className={"vertSpacer1"}/>
            <div style={{display: "flex", justifyContent: "flex-start", width: "20em", cursor: "pointer"}} onClick={()=>{this.props.history.push("/newtrade")}} >
                <KeyboardBackArrow/>
                <div className={"pageTitle"} style={{marginLeft: "1rem"}}>Back</div>
            </div>
            <div className={"vertSpacer2"}/>
            <div className={"newTradePreviewHighlight"} key={`key_${Math.random()}`}>
                {this.getFirstParagraph()}
            </div>
            <br/>
            <div className={"newTradePreviewHighlight"} key={`key_${Math.random()}`}>
                {this.getSecondParagraph()}
            </div>
            <div className={"newTradePreviewHighlight"} key={`key_${Math.random()}`}>
                {this.getThirdParagraph()}
            </div>
            <div className={"vertSpacer2"}/>
            {error &&
            <div>
                <div key={`key_${Math.random()}`}>
                    <div className={"errorIntroduction"}>We apologise, but we could not start this trade for you.</div>
                    <div className={"errorContainer"}>{error}</div>
                </div>
                <div className={"vertSpacer3"}/>
            </div>}
            <div>
                <LaunchTradeButton onClick={()=>{this.launchNewTrade()}} enabled={this.state.launchNewTradeBtnEnabled}/>
            </div>
            <div className={"vertSpacer1_5"}/>
        </div>
    }
}


let mapStateToProps = rootState => ({
    base: rootState.newTrade.newTrade.base,
    quote: rootState.newTrade.newTrade.quote,
    tradeType: rootState.newTrade.newTrade.tradeType,
    actionType: rootState.newTrade.newTrade.actionType,
    posSize: rootState.newTrade.newTrade.posSize,
    userQuoteBalance: rootState.newTrade.newTrade.userQuoteBalance,
    fixedPrice: rootState.newTrade.newTrade.fixedPrice,
    currentPrice: rootState.newTrade.newTrade.currentPrice,
    actionTypeOption: rootState.newTrade.newTrade.actionTypeOption,
    takeProfitOption: rootState.newTrade.newTrade.takeProfitOption,
    takeProfitPriceOrPercent: rootState.newTrade.newTrade.takeProfitPriceOrPercent,
    stopLossOption: rootState.newTrade.newTrade.stopLossOption,
    stopLossPriceOrPercent: rootState.newTrade.newTrade.stopLossPriceOrPercent,
    candlesType: rootState.newTrade.newTrade.candlesType,
    tradeLaunched: rootState.newTrade.newTrade.tradeLaunched,
    error: rootState.errors.launchNewTradeError
})

let mapDispatchToProps = dispatch => {
    return {
        launchNewTrade: (cb) => dispatch(launchNewTrade(cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (PreviewTrade)
