import React, {Component} from 'react'
import coinNamesNom from '../../../nom/coinNamesNom'
import EnhancedTableFuturesBalance from "../../fncomponents/EnhancedTableFuturesBalance"
import {Paper} from "@material-ui/core";
import {brushValue} from "../../fncomponents/UISupportFns";

class FuturesBalance extends Component {

    getFontSize() {
        console.log("getFontSize, isMobile", this.props.isMobile)
        if (this.props.isMobile)
            return '0.7rem'
        return '0.9rem'
    }

    render() {
        const columns = [
            { id: 'coin', numeric: false, disablePadding: true, label: 'Symbol'},
            { id: 'type', numeric: false, disablePadding: true, label: 'Type'},
            { id: 'balance', numeric: true, disablePadding: false, label: 'Balance' },
            { id: 'empty', numeric: true, disablePadding: false, label: '' },
        ]
        if (!this.props.balances)
            return null
        return <div>
                <Paper>
                    <div style={{padding: "0.7rem"}}>
                        <div style={{fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif;`, fontSize: "1.25rem", fontWeight: 500, lineHeight: "1.6", letterSpacing: "0.0075em"}}>Margin</div>
                        <table>
                            <tr className={"futuresMgmtContainerSmallTitle"} style={{fontSize: this.getFontSize()}}>
                                <td>Coin</td>
                                <td>Total</td>
                                <td>Available</td>
                                <td>In Order</td>
                                <td>{this.props.isMobile ? <div>Additional<br/>Isolated Margin</div>: <div>Add. Isolated Margin</div>}</td>
                            </tr>
                            <tr style={{fontSize: "0.9rem"}}>
                                <td style={{width: "4rem"}}>USDT</td>
                                <td style={{width: "5rem"}}>{brushValue(this.props.balances.margin.totalBalance, 6)}</td>
                                <td style={{width: "5rem"}}>{brushValue(this.props.balances.margin.availableBalance, 6)}</td>
                                <td style={{width: "5rem"}}>{brushValue(this.props.balances.margin.inOrderBalance, 6)}</td>
                                <td style={{width: "10rem"}}>{brushValue(this.props.balances.isolatedMargin, 6)}</td>
                            </tr>
                        </table>
                    </div>
                </Paper>


                <EnhancedTableFuturesBalance rows={this.props.balances.positions} columns={columns} tableTitle={"Positions"}/>
        </div>
    }
}


export default FuturesBalance
