import {
    CHANGE_TAB,
    CHANGE_WALLET,
    CONFIRM_TRANSFER,
    CONFIRM_WITHDRAWAL,
    DEPOSIT_ADD_OR_UPDATE,
    CHANGE_TO_DEPOSITS_TAB,
    GET_BALANCES,
    GET_BALANCES_RECENT_DEPOSITS_AND_WITHDRAWS,
    GET_BALANCES_TOTALS_ONLY,
    GET_RECENT_TRANSFERS,
    GET_SPECIFIC_BALANCE,
    TRANSFER,
    WITHDRAW,
    CHANGE_TO_WITHDRAWALS_TAB,
    CHANGE_TO_TRANSFERS_TAB,
    CHANGE_TRANSFER,
    CHANGE_SELECTED_WITHDRAWAL_COIN,
    CHANGE_SELECTED_WITHDRAWAL_COIN_BALANCE_ENTRY,
    GET_MARGIN_BALANCES,
    GET_RECENT_DEPOSITS,
    GET_RECENT_WITHDRAWALS,
    GET_FUTURES_BALANCES,
    GET_RECENT_INTERNAL_TRANSFERS,
    TRANSFER_INTERNAL,
    GET_SPECIFIC_FUTURES_BALANCE,
    TRANSACTIONS_GET_ALL,
    TRANSACTIONS_GET_INITIAL,
    TRANSFER_INTERNAL_ERROR,
    CLEAR_WALLET_ERRORS,
    CHANGE_TO_BALANCE_TAB,
    CHANGE_RENDER_CUSTOM_HEADER,
    CHANGE_TO_WITHDRAWALS_ALLOW_TAB,
    GET_RECENT_INVESTMENT_PURCHASES,
    GET_BALANCES_AND_BACK_TO_MAIN_WALLET_SECTION,
    CHANGE_TO_STAKING_TAB,
} from "../actions/actionTypes";
import c from '../../constants'
import StatsComponent from "../../components/fncomponents/StatsComponent"

export default function (state = {selectedTab: c.WALLETS_TAB, selectedWallet: "spot", selectedTransfer: "internal", transactions: {},
    recentDeposits: [], recentWithdrawals: [], recentInternalTransfers: [], recentTransfers: [], recentInvestmentPurchases: []}, action) {
    switch (action.type) {

        case GET_BALANCES: {
            return {
                ...state,
                balances: action.payload.balances,
            }
        }

        case GET_MARGIN_BALANCES: {
            return {
                ...state,
                marginBalances: action.payload.balances,
            }
        }

        case GET_FUTURES_BALANCES: {
            return {
                ...state,
                futuresBalances: action.payload,
            }
        }

        case GET_BALANCES_TOTALS_ONLY: {
            return {
                ...state,
                totals: action.payload.totals,
            }
        }

        case GET_SPECIFIC_BALANCE: {
            return {
                ...state,
                specificBalance: action.payload
            }
        }

        case GET_SPECIFIC_FUTURES_BALANCE: {
            return {
                ...state,
                specificFuturesBalance: action.payload
            }
        }

        case GET_BALANCES_RECENT_DEPOSITS_AND_WITHDRAWS: {
            let recentDepositsMap = {}
            //the list comes already sorted desc by creationDate
            let recentDepositsList = action.payload.recentDeposits
            for(let i in recentDepositsList) {
                recentDepositsMap[recentDepositsList[i]._id] = i
            }
            return {
                ...state,
                ...{balances: action.payload.balances,
                    recentDeposits: recentDepositsList,
                    recentDepositsMap: recentDepositsMap,
                    recentWithdrawals: action.payload.recentWithdrawals}
            }
        }

        case WITHDRAW: {
            return {
                ...state,
                ...{
                    recentWithdrawals: [action.payload.updatedWithdrawal, ...(state.recentWithdrawals ? state.recentWithdrawals: [])],
                }
            }
        }

        case CONFIRM_WITHDRAWAL: {
            return {...state, ...{withdrawalConfirmSuccess: true}}
        }

        case CONFIRM_TRANSFER: {
            return {...state, ...{transferConfirmSuccess: true}}
        }

        case TRANSFER: {
            let newState = undefined
            try {
                newState = {
                    ...state,
                    ...{
                        recentTransfers: [action.payload.updatedTransfer, ...state.recentTransfers],
                        balances: state.balances.map(b => {
                            if (b.coin === action.payload.balanceEntry.coin) {
                                return action.payload.balanceEntry
                            }
                            return b
                        }),
                        selectedBalanceCoin: action.payload.balanceEntry,
                        selectedTransferCoin: action.payload.balanceEntry,
                        selectedTab: 1,
                    }
                }
            } catch (err) {
                // console.error("reducer[TRANSFER] error", err)
            }

            return newState
        }

        case GET_RECENT_DEPOSITS: {
            return {
                ...state,
                recentDeposits: action.payload
            }
        }

        case GET_RECENT_WITHDRAWALS: {
            return {
                ...state,
                recentWithdrawals: action.payload
            }
        }

        case GET_RECENT_INVESTMENT_PURCHASES: {
            return {
                ...state,
                recentInvestmentPurchases: action.payload
            }
        }

        case GET_RECENT_TRANSFERS: {
            return {
                ...state,
                recentTransfers: action.payload
            }
        }
        case GET_RECENT_INTERNAL_TRANSFERS: {
            return {
                ...state,
                recentInternalTransfers: action.payload
            }
        }

        case DEPOSIT_ADD_OR_UPDATE: {
            let newOrUpdatedDeposit = action.payload
            let idx = state.recentDepositsMap[newOrUpdatedDeposit._id]
            if (idx >= 0) {
                //deposit already present only need to update it
                let newRecentDepositsList = state.recentDeposits.map((d, index) => {

                    if (index !== idx)
                        return d
                    else {
                        return {
                            ...d,
                            ...{status: newOrUpdatedDeposit.status}
                        }


                    }
                })
                let q = {
                    ...state,
                    recentDeposits: newRecentDepositsList
                }
                return q
            } else {
                //new deposit, add it to the beginning of the list as
                //the list is sorted desc by creationDate by default
                let q = {
                    ...state,
                    recentDeposits: [newOrUpdatedDeposit, ...state.recentDeposits],
                    recentDepositsMap: {...{[newOrUpdatedDeposit._id]: 0}, ...state.recentDepositsMap}
                }
                return q
            }
        }
        case CHANGE_RENDER_CUSTOM_HEADER: {
            return {
                ...state,
                renderCustomHeader: action.payload.renderCustomHeader,
            }
        }

        case CHANGE_TAB: {
            return {
                ...state,
                selectedTab: action.payload,
            }
        }

        case CHANGE_WALLET: {
            return {
                ...state,
                selectedWallet: action.payload
            }
        }

        case CHANGE_TO_DEPOSITS_TAB: {
            return {
                ...state,
                ...{
                    selectedDepositCoin: action.payload.selectedDepositCoin,
                    selectedDepositCoinAddress: action.payload.selectedDepositCoinAddress,
                    coinBalanceEntry: action.payload.coinBalanceEntry
                }
            }
        }

        case CHANGE_TO_WITHDRAWALS_TAB: {
            return {
                ...state,
                ...{
                    selectedWithdrawCoin: action.payload.selectedWithdrawCoin,
                    selectedWithdrawCoinAddress: action.payload.selectedWithdrawCoinAddress,
                    coinBalanceEntry: action.payload.coinBalanceEntry,
                    withdrawFromWalletList: action.payload.withdrawFromWalletList,
                }
            }
        }

        case CHANGE_TO_WITHDRAWALS_ALLOW_TAB: {
            return {
                ...state,
                selectedTab: 3,
                transactionDetails: action.payload,
            }
        }

        case CHANGE_TO_TRANSFERS_TAB: {
            return {
                ...state,
                ...{
                    selectedBalanceCoin: action.payload.selectedBalanceCoin,
                    selectedTransferCoin: action.payload.selectedTransferCoin,
                    transferCoinBalanceEntry: action.payload.transferCoinBalanceEntry,
                    selectedTab: 4,
                }
            }
        }

        case CHANGE_TO_STAKING_TAB: {
            return {
                ...state,
                ...{
                    selectedBalanceCoin: action.payload.selectedBalanceCoin,
                    selectedStakingCoin: action.payload.selectedStakingCoin,
                    stakingCoinBalanceEntry: action.payload.stakingCoinBalanceEntry,
                    selectedTab: 7,
                }
            }
        }
        case CHANGE_TO_BALANCE_TAB: {
            return {
                ...state,
                selectedBalanceCoin: action.payload.selectedBalanceCoin,
                selectedTab: 1,
            }
        }

        case CHANGE_TRANSFER: {
            return {
                ...state,
                selectedTransfer: action.payload
            }
        }

        case CHANGE_SELECTED_WITHDRAWAL_COIN: {
            return {
                ...state,
                selectedBalanceCoin: action.payload.selectedBalanceCoin,
                selectedWithdrawCoin: action.payload.selectedWithdrawCoin,
                selectedTab: 2,
            }
        }

        case CHANGE_SELECTED_WITHDRAWAL_COIN_BALANCE_ENTRY: {
            return {
                ...state,
                coinBalanceEntry: action.payload
            }
        }

        case TRANSFER_INTERNAL: {
            let newState = undefined
            try {
                newState = {
                    ...state,
                    ...{
                        recentInternalTransfers: [action.payload.newInternalTransfer, ...state.recentInternalTransfers],
                        balances: state.balances.map(b => {
                            if (b.coin === action.payload.balanceEntry.coin) {
                                return action.payload.balanceEntry
                            }
                            return b
                        }),
                        selectedBalanceCoin: action.payload.balanceEntry,
                        selectedTransferCoin: action.payload.balanceEntry,
                        selectedTab: 1,
                    }
                }
            } catch (err) {
                // console.error("reducer[TRANSFER_INTERNAL] error", err)
            }
            return newState
        }

        case TRANSFER_INTERNAL_ERROR: {
            return {
                ...state,
                transferInternalError: true
            }
        }

        case CLEAR_WALLET_ERRORS: {
            return {
                ...state,
                transferInternalError: undefined
            }
        }

        case TRANSACTIONS_GET_ALL: {
            return  {
                ...state,
                transactions: {
                    ...state.transactions,
                    ...{
                        docs: [...state.transactions.docs, ...action.payload.docs],
                        totalDocsBB: action.payload.totalDocs,
                        totalPagesBB: action.payload.totalPages,
                        pagesDownloadedBB: {...state.transactions.pagesDownloaded, ...{[action.payload.page]: true}}
                    }
                }
            }
        }

        case TRANSACTIONS_GET_INITIAL: {
            return  {
                ...state,
                ...{
                    transactions: {
                        docs: action.payload.docs,
                        totalDocs: action.payload.totalDocs,
                        totalPages: action.payload.totalPages,
                        pagesDownloaded: {[action.payload.page]: true}
                    }
                }
            }
        }

        default:
            return state
    }
}
