import React from "react";
import {
    depositStatusesNom,
    withdrawalStatusesNom,
    withdrawalStatusesNomDetails
} from "../../../nom/depositsAndWithdrawsNom";
import c from '../../../constants'
export function isBTC(el) {return el.coin === "BTC"}
export function isETHorUSDT(el) {return el.coin === "ETH" || el.coin === "USDT"}
export function isLong(transaction) {return transaction.type === "LONG"}
export function isDeposit(transaction) { return transaction.type === "deposit"}
export function isWithdrawal(transaction) { return transaction.type === "withdrawal"}
export function isTransferReceived(myId, transaction) {return myId === transaction.destUser}
export function isTransferSent(myId, transaction) {return myId === transaction.srcUser}
export function isTransfer(transaction) {return transaction.type === "transfer"}
export function isInternalTransfer(transaction) {return transaction.type === "internalTransfer"}
export function isInternalTransferSpotToFutures(transaction) {return transaction.fromWallet === "spot" && transaction.toWallet === "futures_usdt"}
export function isInvestment(transaction) { return transaction.type === "investment"}
export function isDecrement(myId, el) {return isWithdrawal(el) || isTransferSent(myId, el) || isInternalTransferSpotToFutures(el) || isInvestment(el)}


export function enhanceWithType(type) { return list => list?.map((el) => ({ ...el, type }))}

export function updateStatus(list) {
    return list.map((el) => {
        if (isDeposit(el)) {
            return {...el, status: depositStatusesNom[el.status]};
        } else if (isWithdrawal(el) || isTransfer(el)) {
            return {...el, ...{statusOrig: el.status, status: withdrawalStatusesNom[el.status]}};
        } else if (isInternalTransfer(el)) {
            return {...el, status: "Completed"};
        } else if (isInvestment(el)) {
            return {...el, status: "Completed"};
        }
    })
}

const INTERNAL_TRANSFERS_WALLET_TYPES_NAMES = {"spot": "Spot", "futures_usdt": "Futures"}

export function renderType(myId, el) {
    if (isWithdrawal(el)) {
        return (
            <div>
                <b>withdraw</b> to{" "}
                {isBTC(el) ? (
                    <a href={`https://btc.com/${el.toAddress}`} target="_blank" rel="noopener noreferrer">
                        {" "}
                        {el.toAddress}
                    </a>
                ) : isETHorUSDT(el) ? (
                    <a
                        href={`https://etherscan.io/address/${el.toAddress}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {el.toAddress}
                    </a>
                ) : (
                    el.toAddress
                )}{" "}
                {el.txid && <div>
                    txid {isBTC(el) ? (
                <a href={`https://btc.com/${el.txid}`} target="_blank" rel="noopener noreferrer">
                    {" "}
                    {el.txid}
                </a>
            ) : isETHorUSDT(el) ? (
                <a
                    href={`https://etherscan.io/tx/${el.txid}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {el.txid}
                </a>
            ) : (
                el.txid
                )
                }
                </div>}
                {el.toMemo && <div>{`memo ${el.toMemo}`}</div>}
                {el.statusOrig === 50 && <div><span title={"Rejection reason"}>r.r.</span> <b>{withdrawalStatusesNomDetails[el.statusOrig]}</b></div>}
                {el.statusOrig === 51 && <div><span title={"Rejection reason"}>r.r.</span> <b>{el.rejectReason}</b></div>}
            </div>
        )
    }

    if (isDeposit(el)) {
        return (
            <div>
                <b>deposit</b> to{" "}
                {isBTC(el) ? (
                    <a href={`https://btc.com/${el.address}`} target="_blank" rel="noopener noreferrer">
                        {" "}
                        {el.address}
                    </a>
                ) : isETHorUSDT(el) ? (
                    <a
                        href={`https://etherscan.io/address/${el.address}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {el.address}
                    </a>
                ) : (
                    el.address
                )}{" "}
                <br />
                {el.txid && <span>
                    txid {isBTC(el) ? (
                    <a href={`https://btc.com/${el.txid}`} target="_blank" rel="noopener noreferrer">
                        {" "}
                        {el.txid}
                    </a>
                ) : isETHorUSDT(el) ? (
                    <a
                        href={`https://etherscan.io/tx/${el.txid}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {el.txid}
                    </a>
                ) : (
                    el.txid
                )}{" "}
                </span>}
            </div>
        );
    }

    if (isTransfer(el)) {
        if (isTransferReceived(myId, el)) {
            return <div>
                <div><b>transfer</b> {`from ${el.sender} (${el.senderEmail})`}</div>
                {el.statusOrig === c.TRANSFER_STATUS_REJECTED_BY_ADMIN_OTHER &&
                <div style={{fontSize: "0.7rem"}}>(<span title={"Rejection reason"}>r.r.</span>: {el.rejectReason})</div>}
            </div>;
        } else {
            return <div>
                <div><b>transfer</b> {`to ${el.receiver} (${el.receiverEmail})`}</div>
                {el.statusOrig === c.TRANSFER_STATUS_PENDING_EMAIL_SENT && <div style={{fontSize: "0.7rem", color: "#f7a102"}}>Please check confirmation email.</div>}
                {el.statusOrig === c.TRANSFER_STATUS_REJECTED_BY_ADMIN_OTHER &&
                <div style={{fontSize: "0.7rem"}}>(<span title={"Rejection reason"}>r.r.</span>: {el.rejectReason})</div>}
            </div>;
        }
    }

    if (isInternalTransfer(el)) {
        return <div>transfer <b>{INTERNAL_TRANSFERS_WALLET_TYPES_NAMES[el.fromWallet]}</b> → <b>{INTERNAL_TRANSFERS_WALLET_TYPES_NAMES[el.toWallet]}</b></div>
    }

    if (isInvestment(el)) {
        return <div>purchase to <b>{el.investmentProgram.title}</b></div>
    }

}

export function isDepositable(coin) {
    return coin === "BTC" || coin === "ETH" || coin === "USDT" || coin === "TRX"
}

export function isStakeable(coin) {
    return coin === "AMP" || coin === "B2M"
}