import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import React from "react";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}))

export default function SmallButton({label, onClick, variant}) {
    const classes = useStyles()

    function handleOnClick() {
        onClick()
    }

    return(
        <Button variant={variant} color="primary" size="small" className={classes.button} onClick={handleOnClick}>
            {label}
        </Button>
    )
}
