import React from "react";
import { Button } from "@material-ui/core";

import {
  ArrowBack,
  List as ListIcon,
  Close
} from "@material-ui/icons";
import { useDispatch } from "react-redux";

import { CHANGE_TAB } from "../../redux/actions/actionTypes";


export const CustomHeader = ({ title, preButton, postButton }) => {
  return (
    <div className="custom-header">
      <div className="custom-header__first">
              {preButton && preButton()}
      <div>{title}</div>
      </div>
      {postButton && postButton()}
    </div>
  );
};

export const WalletCustomHeader = () => {
  return (
    <CustomHeader
      title="Wallet"
      preButton={() => (<span>
        {" "}
      </span>
      )}
    />
  );
};

export const SpotCustomHeader = props => {
  const {currentLevel} = props
  const dispatch = useDispatch();

  let tabLevel = currentLevel - 1
  tabLevel = tabLevel < 0 ? 0: tabLevel

  return (
    <CustomHeader
      title="Wallet"
      preButton={() => (
        <ArrowBack
          onClick={() => dispatch({ type: CHANGE_TAB, payload: tabLevel})}
          className="backIcon"
        />
      )}
    />
  );
};

export const WithdrawCustomHeader = () => {
    const dispatch = useDispatch();
  
    return (
      <CustomHeader
        title="New withdraw"
        preButton={() => (
          <Close
            onClick={() => dispatch({ type: CHANGE_TAB, payload: 1 })}
            className="backIcon"
          />
        )}
      />
    );
  };

  export const WithdrawAllowCustomHeader = () => {
    const dispatch = useDispatch();
  
    return (
      <CustomHeader
        title="Withdraw preview"
        preButton={() => (
          <ArrowBack
            onClick={() => dispatch({ type: CHANGE_TAB, payload: 2 })}
            className="backIcon"
          />
        )}
      />
    );
  };

export const AllTransactionsCustomHeader = () => {
    const dispatch = useDispatch();

    return (
        <CustomHeader
            title="All Transactions"
            preButton={() => (
                <ArrowBack
                    onClick={() => dispatch({ type: CHANGE_TAB, payload: 0 })}
                    className="backIcon"
                />
            )}
            // postButton={() => (
            //   <Button style={{ color: "#3BADFF" }} onClick={() => dispatch({type: CHANGE_TAB, payload: 5})}>
            //     <ListIcon />
            //     All Transactions
            //   </Button>
            // )}
        />
    );
};

export const FuturesTransactionsCustomHeader = () => {
    const dispatch = useDispatch();

    return (
        <CustomHeader
            title="Futures Transactions"
            preButton={() => (
                <ArrowBack
                    onClick={() => dispatch({ type: CHANGE_TAB, payload: 0 })}
                    className="backIcon"
                />
            )}
        />
    );
};