import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {

    const {open, title="Information", texts, handleClose, closeLabel="OK"} = props

    return (
            <Dialog
                open={open ? open : false}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" component={'div'}>
                       {(texts && texts[0]) && <div key={Math.random()}>{texts[0]}</div>}
                        {(texts && texts[1]) && <div key={Math.random()}><small>{texts[1]}</small></div>}
                        {(texts && texts[2]) && <div key={Math.random()}><small>{texts[2]}</small></div>}
                        {(texts && texts[3]) && <div key={Math.random()}><small>{texts[3]}</small></div>}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        {closeLabel}
                    </Button>
                </DialogActions>
            </Dialog>
    );
}
