import React from "react";

export default {
    //BUY OPTION
    ACTION_TYPE_OPTION_MARKET: "ACTION_TYPE_OPTION_MARKET_PRICE",
    ACTION_TYPE_OPTION_FIXED_PRICE : "ACTION_TYPE_OPTION_FIXED_PRICE",
    ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE : "ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE",
    ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE : "ACTION_TYPE_OPTION_JUST_BELLOW_ASK_PRICE",
    ACTION_TYPE_OPTION_WAIT_FOR_A_DIP : "ACTION_TYPE_OPTION_WAIT_FOR_A_DIP",
    DEFAULT_ACTION_TYPE_OPTION : "ACTION_TYPE_OPTION_MARKET_PRICE",

    //STOP LOSS OPTION
    STOP_LOSS_OPTION_PERCENTAGE : "STOP_LOSS_OPTION_AT_A_SPECIFIC_PERCENTAGE",
    STOP_LOSS_OPTION_PERCENTAGE_FROM_TRAILING : "STOP_LOSS_OPTION_PERCENTAGE_FROM_TRAILING",
    STOP_LOSS_OPTION_PRICE : "STOP_LOSS_OPTION_AT_A_SPECIFIC_PRICE",
    STOP_LOSS_OPTION_TRAILING : "STOP_LOSS_OPTION_TRAILING_STOP_LOSS",
    STOP_LOSS_OPTION_CONDITIONAL : "STOP_LOSS_OPTION_CONDITIONAL",
    STOP_LOSS_OPTION_MANUAL : "STOP_LOSS_OPTION_MANUAL",
    DEFAULT_STOP_LOSS_OPTION: "STOP_LOSS_OPTION_TRAILING_STOP_LOSS",

    //TAKE PROFIT OPTION
    TAKE_PROFIT_OPTION_PERCENTAGE : "TAKE_PROFIT_OPTION_AT_A_SPECIFIC_PERCENTAGE",
    TAKE_PROFIT_OPTION_PRICE : "TAKE_PROFIT_OPTION_AT_A_SPECIFIC_PRICE",
    TAKE_PROFIT_OPTION_MANUAL : "TAKE_PROFIT_OPTION_MANUAL",
    DEFAULT_TAKE_PROFIT_OPTION : "TAKE_PROFIT_OPTION_MANUAL",

    //TRADE_TYPES
    TRADE_TYPE_NEW_TRADE: "trade_type_new",
    TRADE_TYPE_OLD : "trade_type_old",

    //LONG_OR_SHORT
    ACTION_TYPE_BUY: "action_type_BUY",
    ACTION_TYPE_SELL: "action_type_SELL",

    //FUTURES TYPE
    FUTURES_TYPE_LONG: "FUTURES_TYPE_LONG",
    FUTURES_TYPE_SHORT: "FUTURES_TYPE_SHORT",
    FUTURES_TYPE_LONG_s: "LONG",
    FUTURES_TYPE_SHORT_s: "SHORT",

    FUTURES_ACTION_TYPE_OPTION_DEFAULT : "FUTURES_ACTION_TYPE_OPTION_MARKET_PRICE",
    FUTURES_ACTION_TYPE_OPTION_MARKET: "FUTURES_ACTION_TYPE_OPTION_MARKET_PRICE",
    FUTURES_ACTION_TYPE_OPTION_FIXED_PRICE : "FUTURES_ACTION_TYPE_OPTION_FIXED_PRICE",
    FUTURES_ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE : "FUTURES_ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE",
    FUTURES_ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE : "FUTURES_ACTION_TYPE_OPTION_JUST_BELLOW_ASK_PRICE",

    EXCHANGE_BINANCE: "exchange_binance",
    EXCHANGE_COINEX: "exchange_coinex",

    WAITING_TRADES_TAB: 1001,
    WAITING_TRADES_TAB_POS: 0,
    ACTIVE_TRADES_TAB: 1002,
    ACTIVE_TRADES_TAB_POS: 1,
    CLOSED_TRADES_TAB: 1003,
    CLOSED_TRADES_TAB_POS: 2,
    ORDERS_TAB: 1004,

    FUTURES_OPEN_ORDERS_TAB: 4001,
    FUTURES_OPEN_ORDERS_TAB_POS: 0,
    FUTURES_OPENED_POSITIONS_TAB: 4002,
    FUTURES_OPENED_POSITIONS_TAB_POS: 1,
    FUTURES_CLOSED_POSITIONS_TAB: 4004,
    FUTURES_CLOSED_POSITIONS_TAB_POS: 2,
    FUTURES_HISTORY_TAB_POS: 3,


    RUNNING_SUCS_TAB: 2001,
    CLOSED_SUCS_TAB: 2002,

    ACTIVE_BOTS_TAB: 3001,
    STOPPED_BOTS_TAB: 3002,

    WALLETS_TAB: 0,
    BALANCE_TAB: 1,
    WITHDRAW_TAB: 2,
    WITHDRAW_ALLOW_TAB: 3,
    TRANSFERS_TAB: 4,


    ORDER_STATUS_NEW: "NEW",
    ORDER_STATUS_FILLED: "FILLED",
    ORDER_STATUS_PARTIALLY_FILLED: "PARTIALLY_FILLED",
    ORDER_STATUS_CANCELED: "CANCELED",
    ORDER_STATUS_ERROR: "ERROR",


    FUTURES_ORDER_STATUS_NEW: "NEW",
    FUTURES_ORDER_STATUS_FILLED: "FILLED",
    FUTURES_ORDER_STATUS_PARTIALLY_FILLED: "PARTIALLY_FILLED",
    FUTURES_ORDER_STATUS_CANCELED: "CANCELED",
    FUTURES_ORDER_STATUS_ERROR: "ERROR",

    ORDER_TYPE_FAKE_PARENT : "FAKE_PARENT_ORDER",
    BACKEND_FUTURES_TRADE_STATE_UNCERTAIN: "trade_uncertain",

    FUTURES_TRADE_STATE_UNCERTAIN: 8,
    TRADE_STATE_PARENT_CANCELED: 9,
    FUTURES_TRADE_STATE_PARENT_CANCELED: 90,
    TRADE_STATE_PARENT_ONGOING: 10,
    FUTURES_TRADE_STATE_PARENT_ONGOING: 100,
    TRADE_STATE_PARENT_PARTIALLY_FILLED: 11,
    FUTURES_TRADE_STATE_PARENT_PARTIALLY_FILLED: 110,
    TRADE_STATE_PARENT_FILLED: 12,
    FUTURES_TRADE_STATE_PARENT_FILLED: 120,
    TRADE_STATE_PARENT_IN_ERROR: 13,
    FUTURES_TRADE_STATE_PARENT_IN_ERROR: 130,
    TRADE_STATE_WITH_CHILD_CANCELED: 18,
    FUTURES_TRADE_STATE_WITH_CHILD_CANCELED: 180,
    TRADE_STATE_WITH_CHILD_CANCELED_AND_UNFULFILLED: 19,
    FUTURES_TRADE_STATE_WITH_CHILD_CANCELED_AND_UNFULFILLED: 190,
    TRADE_STATE_WITH_CHILD_ONGOING: 20,
    FUTURES_TRADE_STATE_WITH_CHILD_ONGOING: 200,
    TRADE_STATE_WITH_CHILD_PARTIALLY_FILLED: 21,
    FUTURES_TRADE_STATE_WITH_CHILD_PARTIALLY_FILLED: 210,
    TRADE_STATE_WITH_CHILD_FILLED: 22,
    FUTURES_TRADE_STATE_WITH_CHILD_FILLED: 220,
    TRADE_STATE_WITH_CHILD_IN_ERROR: 23,
    FUTURES_TRADE_STATE_WITH_CHILD_IN_ERROR: 230,
    TRADE_MAIN_STATE_ONGOING: 100,
    TRADE_MAIN_STATE_FINISHED: 101,

    TRADE_OP_USER_CANCELS_TRADE: "TRADE_OP_USER_CANCELS_TRADE",
    TRADE_OP_USER_TAKES_PROFIT: "TRADE_OP_USER_TAKES_PROFIT",
    TRADE_OP_AUTO_TAKE_PROFIT: "TRADE_OP_AUTO_TAKE_PROFIT",
    TRADE_OP_AUTO_TAKE_PROFIT_TARGET_1: "TRADE_OP_AUTO_TAKE_PROFIT_TARGET_1",
    TRADE_OP_AUTO_TAKE_PROFIT_TARGET_2: "TRADE_OP_AUTO_TAKE_PROFIT_TARGET_2",
    TRADE_OP_AUTO_TAKE_LOSS: "TRADE_OP_AUTO_TAKE_LOSS",
    TRADE_OP_AUTO_LIQUIDATE: "TRADE_OP_AUTO_LIQUIDATE",
    TRADE_OP_EXCHANGE_LIQUIDATE: "TRADE_OP_EXCHANGE_LIQUIDATE",
    TRADE_STATE_PENDING_MATCHING_LIQUIDATION_ORDER: "pending_matching_liquidation_order",
    TRADE_STATE_EXCHANGE_LIQUIDATION_NOT_FOUND: "exchange_liquidation_not_found",
    TRADE_OP_AUTO_CLOSE: "TRADE_OP_AUTO_CLOSE",


    BTD_STATUS_WAITING: "WAITING",
    BTD_STATUS_DIP_REACHED: "DIP_REACHED",
    BTD_STATUS_CANCELED: "CANCELED",
    BTD_STATUS_ERROR: "ERROR",

    USER_HAS_NOT_ENOUGH_BALANCE: 0,
    USER_HAS_BALANCE: 1,

    LAST_BID_PRICE_MULTIPLIER: 1.0002,
    FIRST_ASK_PRICE_MULTIPLIER: 0.9998,

    LOCAL_JWT_ERROR_REFRESH_TOKEN_NOT_FOUND: "LOCAL_JWT_ERROR_REFRESH_TOKEN_NOT_FOUND",

    WITHDRAWAL_STATUS_PENDING_EMAIL_SENT: 11,
    WITHDRAWAL_STATUS_PENDING_USER_CONFIRMED_VIA_EMAIL: 12,
    WITHDRAWAL_STATUS_PENDING_AT_ADMIN_BLOCK_BALANCE: 40,
    WITHDRAWAL_STATUS_REJECTED_BY_ADMIN_INSUFFICIENT_BALANCE: 50,
    WITHDRAWAL_STATUS_REJECTED_BY_ADMIN_OTHER: 51,
    WITHDRAWAL_STATUS_COMPLETED: 60,

    TRANSFER_STATUS_PENDING_EMAIL_SENT: 11,
    TRANSFER_STATUS_REJECTED_BY_ADMIN_OTHER: 51,


    USER_STATUS_NEW: 1,
    USER_STATUS_ACTIVE: 2,
    USER_STATUS_DEACTIVATED: 66,

    USER_SIGNALS_STATE_NOT_RECEIVING: 1,
    USER_SIGNALS_STATE_JOIN_REQUEST_SENT: 2,
    USER_SIGNALS_STATE_RECEIVING: 3,
    USER_SIGNALS_STATE_JOIN_REQUEST_REJECTED: 4,

    SUC_STATUS_WAITING: "1",
    SUC_STATUS_MATERIALIZED: "2",
    // SUC_STATUS_DISCONNECTED: "3",
    SUC_STATUS_CLOSED: "4",
    SUC_STATUS_CANCELED: "5",
    SUC_STATUS_ERROR: "6",
    SUC_STATUS_SHOULD_HAVE_MATERIALIZED: "7",

    SUC_POS_SIZE_TYPE_FIXED: 1,
    SUC_POS_SIZE_TYPE_PERCENTAGE: 2,
    SUC_POS_SIZE_TYPE_RISK_REWARD_FUNCTION: 3,

    SUC_MIN_POS_SIZE_BTC: 0.002,
    SUC_MAX_POS_SIZE_BTC: 0.1,
    SUC_MIN_POS_SIZE_USDT: 20,
    SUC_MAX_POS_SIZE_USDT: 1000,
    SUC_MIN_POS_SIZE_FUTURES_USDT: 10,
    SUC_MAX_POS_SIZE_FUTURES_USDT: 10000,

    //SIGNAL_STATES
    SIGNALS_STATUS_WAITING: 1,
    SIGNALS_STATUS_RUNNING: 2,
    SIGNALS_STATUS_CLOSED: 4,

    //BOT STATUSES
    BOT_STATUS_ACTIVE: 1,
    BOT_STATUS_STOPPED: 2,

    BASE_PRECISIONS: {
        "BTC": 8,
        "USDT": 6,
        "ETH": 8
    },

    FUTURES_BASE_PRECISIONS: {
        "USDT": 2,
    },

    USER_TRADER_APPLICATION_STATUS_ACTIVE: 1,
    USER_TRADER_APPLICATION_STATUS_PENDING_APPROVAL: 2,
    USER_TRADER_APPLICATION_STATUS_REJECTED: 3,
    USER_TRADER_APPLICATION_STATUS_PERMANENTLY_REJECTED: 66,

    PENDING_TRANSFER_STATUS_WAITING: 1,
    PENDING_TRANSFER_STATUS_EXECUTED: 2,
    PENDING_TRANSFER_STATUS_ERRORED: 3,

    MOBILE_OPTIONS: {
        mobileLimit: 600,
        tabletLimit: 800
    },

    TRADER_SUBSCRIPTION_ACTIVE: 1,
    TRADER_SUBSCRIPTION_DISABLED: 66,

    TRADER_SUBSCRIPTION_PERIODICITY_MONTHLY: 1,
    TRADER_SUBSCRIPTION_PERIODICITY_QUARTERLY: 3,
    TRADER_SUBSCRIPTION_PERIODICITY_YEARLY: 12,

    SUSPENDED_ACTIVITIES_NOM: [
        {_id: 1, name: "New Trade"},
        {_id: 10, name: "Edit Enter Trade Conditions"},
        {_id: 12, name: "Manual Take Profit"},
        {_id: 13, name: "Cancel Trade"},
        {_id: 2, name: "New Conversion"},
        {_id: 3, name: "Withdraw"}
    ],

    SUSPENDED_ACTIVITIES_HNOM: {
        1:"New Trade",
        10: "Edit Enter Trade Conditions",
        12: "Manual Take Profit",
        13:"Cancel Trade",
        2:"New Conversion",
        3:"Withdraw"
    },

    SUSPENDED_ACTIVITIES_NEW_TRADE_ID: 1,
    SUSPENDED_ACTIVITIES_EDIT_ENTER_TRADE_CONDITIONS: 10,
    SUSPENDED_ACTIVITIES_MANUAL_TAKE_PROFIT: 12,
    SUSPENDED_ACTIVITIES_CANCEL_TRADE: 13,
    SUSPENDED_ACTIVITIES_NEW_CONVERSION_ID: 2,

    PERIODICITY_IN_DAYS_BY_TYPE: {
        1: 30,
        3: 91,
        12: 365
    },

    MS_IN_A_DAY: 86400000,

    LIMIT_NO_SUCS_TO_RECEIVE_BEFORE_GIVING_RATES: 2,

    TRADER_STATUS_ACTIVE: 1,
    TRADER_STATUS_DISABLED: 66,

    BUY_CRYPTO_PAYMENT_METHOD_SEPA: 3301,
    BUY_CRYPTO_PAYMENT_METHOD_DEBIT_CARD: 3302,

    MONTHS: {
        0: "Jan",
        1: "Feb",
        2: "Mar",
        3: "Apr",
        4: "May",
        5: "Jun",
        6: "Jul",
        7: "Aug",
        8: "Sep",
        9: "Oct",
        10: "Nov",
        11: "Dec"
    },

    REFERRAL_COOKIE: "ref",
    INVESTMENT_PROGRAM_REFERRAL_COOKIE: "ipref",
    DOMAIN_COOKIE: "domain",

    CANDLES_TYPE_30m: 1,
    CANDLES_TYPE_1H: 2,
    CANDLES_TYPE_4H: 3,
    CANDLES_TYPE_8H: 4,
    CANDLES_TYPE_12H: 5,
    CANDLES_TYPE_24H: 6,

    CANDLES_TYPES_HR_MAP: {
        1: "30m",
        2: "1h",
        3: "4h",
        4: "8h",
        5: "12h",
        6: "24h",
    },

    //WALLET CODES
    WALLET_CODES_SPOT: "spot",
    WALLET_CODES_FUTURES_USDT: "futures_usdt",
    WALLET_CODES_FUTURES_COIN: "futures_coin",
    WALLET_CODES_MARGIN_CROSS: "margin_cross",
    WALLET_CODES_MARGIN_ISOLATED: "margin_isolated",

    STAKES_STAKES_TAB: 0,
    STAKES_REWARDS_TAB: 1,
    STAKES_OPERATIONS_TAB: 2,
    STAKES_STAKES_PROGRAMS_TAB: 3,

    STAKE_STAKING_REWARD_STATUS_PENDING: 1,
    STAKE_STAKING_REWARD_STATUS_DELIVERED: 2,

    MARKETS_SPOT: "SPOT",
    MARKETS_FUTURES: "FUTURES",

    SIGNAL_TYPE_SPOT: 1,
    SIGNAL_TYPE_FUTURES: 2,

    SIGNAL_SETTINGS_TYPE_FUTURES: "FUTURES",

    STATUS_TYPE: {1: "Pending...", 2: "Completed", 3: "Canceled", 66: "Rejected"},

    TRANSACTION_MINUS_COLOR: "#E8E288",
    TRANSACTION_PLUS_COLOR: "#64FE7C",


    INVESTMENT_PROGRAM_STATUS_ACTIVE: 1,
    INVESTMENT_PROGRAM_STATUS_CLOSED: 2,

    INVESTMENT_PROGRAM_SALE_NOT_STARTED: 1,
    INVESTMENT_PROGRAM_SALE_STARTED: 2,
    INVESTMENT_PROGRAM_SALE_ENDED: 3,

    INVESTMENT_ACTIVE_INVESTMENT_TAB: 0,

    USER_HAS_FUTURES_ACTIVATED: 1,
    USER_HAS_FUTURES_PENDING: 2,

    marks125: [
        {
            value: 1,
            label: <span style={{marginLeft: "1rem"}}>1X</span>,
        },
        {
            value: 25,
            label: '25X',
        },
        {
            value: 50,
            label: '50X',
        },
        {
            value: 75,
            label: '75X',
        },
        {
            value: 100,
            label: '100X',
        },
        {
            value: 125,
            label: `125X`,
        },
    ],

    marks75: [
        {
            value: 1,
            label: <span style={{marginLeft: "1rem"}}>1x</span>,
        },
        {
            value: 10,
            label: '10X',
        },
        {
            value: 25,
            label: '25X',
        },
        {
            value: 50,
            label: '50X',
        },
        {
            value: 75,
            label: `75X`,
        },
    ],

    marks50: [
        {
            value: 1,
            label: <span style={{marginLeft: "1rem"}}>1X</span>,
        },
        {
            value: 10,
            label: '10X',
        },
        {
            value: 20,
            label: '20X',
        },
        {
            value: 30,
            label: '30X',
        },
        {
            value: 40,
            label: '40X',
        },
        {
            value: 50,
            label: `50X`,
        },
    ],

    marks25: [
        {
            value: 1,
            label: <span style={{marginLeft: "1rem"}}>1X</span>,
        },
        {
            value: 5,
            label: '5X',
        },
        {
            value: 10,
            label: '10X',
        },
        {
            value: 15,
            label: '15X',
        },
        {
            value: 20,
            label: '20X',
        },
        {
            value: 25,
            label: `25X`,
        },
    ],

    marks20: [
        {
            value: 1,
            label: <span style={{marginLeft: "1rem"}}>1X</span>,
        },
        {
            value: 5,
            label: '5X',
        },
        {
            value: 10,
            label: '10X',
        },
        {
            value: 15,
            label: '15X',
        },
        {
            value: 20,
            label: '20X',
        }
    ],

    FUTURES_TYPES:{
        "FUTURES_TYPE_LONG": "LONG",
        "FUTURES_TYPE_SHORT": "SHORT"
    },

    COIN_LOGOS_PIC_VERSION: "v3"
}
