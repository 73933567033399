import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import TableHead from "@material-ui/core/TableHead"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import {TextField} from "@material-ui/core"
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import {timeFormatter2} from "../../fncomponents/UISupportFns"

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5),
    },
}))

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, columns } = props
    const createSortHandler = property => event => {
        onRequestSort(event, property)
    }

    return (
        <TableHead>
            <TableRow>
                {columns.map(column => (
                    <TableCell
                        key={column.id}
                        align={column.numeric ? 'right' : 'left'}
                        padding={'default'}
                        sortDirection={orderBy === column.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === column.id}
                            direction={order}
                            onClick={createSortHandler(column.id)}
                        >
                            {column.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
}

function TablePaginationActions(props) {
    const classes = useStyles1()
    const theme = useTheme()
    const { count, page, rowsPerPage, onChangePage } = props

    function handleFirstPageButtonClick(event) {
        onChangePage(event, 0)
    }

    function handleBackButtonClick(event) {
        onChangePage(event, page - 1)
    }

    function handleNextButtonClick(event) {
        onChangePage(event, page + 1)
    }

    function handleLastPageButtonClick(event) {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    )
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
}

const useStyles2 = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    table: {
        minWidth: 500,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}))

export default function TraderFollowersTable(props) {
    const classes = useStyles2()
    const {rows, rowsPerPage, setRowsPerPage, page, setPage, totalDocs,
        filtersMap, onFilterChange, subscriptions, removeFollower} = props

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalDocs - page * rowsPerPage)

    function handleChangePage(event, newPage) {
        setPage(newPage)
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }
    return (
        <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
                <Table className={classes.table}>
                    <TableBody>
                        <TableRow hover tabIndex={-1} key={"filterRow"}>
                            <TableCell component="th" scope="row">
                                <TextField value={filtersMap["user"]} onChange={onFilterChange} name={"user"} label={"User"} margin={"dense"}/>
                            </TableCell>
                            <TableCell align="left">
                                <TextField value={filtersMap["email"]} onChange={onFilterChange} name={"email"} label={"Email"} margin={"dense"}/>
                            </TableCell>
                            <TableCell align="left">
                                {subscriptions && <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor="fee-type">Subscription</InputLabel>
                                    <Select
                                        value={filtersMap["subscriptions"]}
                                        onChange={onFilterChange}
                                        inputProps={{
                                            name: 'subscriptions',
                                            id: 'subscriptions',
                                        }}
                                    >
                                        <MenuItem value={""}>All</MenuItem>
                                        {subscriptions.map((elem, index) => {
                                            return <MenuItem value={elem._id}>{elem.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>}
                            </TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                        {rows && rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
                            <TableRow hover tabIndex={0} key={row._id}>
                                <TableCell>
                                    <div style={{fontSize: "1.2rem", marginBottom: "0.4rem"}}><b>{row.userFirstName} {row.userLastName}</b></div>
                                </TableCell>
                                <TableCell align="left">
                                    <div>{row.userEmail}</div>
                                </TableCell>
                                <TableCell align="left">
                                    {row.activeSubscriptions && <div>
                                        {row.activeSubscriptions.map((elem, index) => {
                                            if (elem.active)
                                                return <div style={{fontWeight: "bold", fontSize: "1rem"}}>
                                                    {elem.subscriptionName}
                                                    {!elem.free && <div className={"subscriptionExpiresOn"}>expires on {timeFormatter2(elem.expirationDate)}</div>}
                                            </div>
                                            else
                                                return <div style={{fontWeight: "bold", fontSize: "1rem", textDecoration: "line-through"}}
                                                            title={`Subscription not active for this follower. Last payment date ${timeFormatter2(elem.lastPaymentDate)}.`}>&nbsp;{elem.subscriptionName}&nbsp;</div>
                                        })}
                                    </div>}
                                    {(row.activeSubscriptions && row.activeSubscriptions.length === 0) && <small>No subscriptions</small>}
                                </TableCell>
                                <TableCell>
                                    <div style={{display: "flex"}}>
                                        <a href={`/trader/followers/edit?followerId=${row._id}`} target={"blank"}>Edit</a>
                                        <div className={"hrefButton"} style={{verticalAlign: "middle", height: "1rem", position: "relative", textAlign: "left"}}>
                                            <span onClick={()=>{removeFollower(row._id)}} style={{marginLeft: "0.8rem"}}>Remove</span>
                                        </div>
                                    </div>


                                </TableCell>
                            </TableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 48 * emptyRows }}>
                                <TableCell colSpan={5}>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={6}
                                count={totalDocs}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: { 'aria-label': 'rows per page' },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </div>
        </Paper>
    )
}
