import React from 'react'
import c from '../../../constants'
import NewSignalFormMainButtons from "../../fncomponents/NewSignalFormMainButtons"
import {Polymer} from "@material-ui/icons"
import SelectActionTypeRadios from "../../fncomponents/SelectActionTypeRadios"
import SelectPair from "../../fncomponents/SelectPair"
import SetEnterTradePrices from "../../fncomponents/SetEnterTradePrices";
import SignalTradeConditions from "../../fncomponents/SignalTradeConditions";
import SetTradingResearchDetails from "../../fncomponents/SetTradingResearchDetails";
import SetComment from "../../fncomponents/SetComment";
import {Paper} from "@material-ui/core";
import SetReceivingSubscriptions from "../../fncomponents/SetReceivingSubscriptions";


export default function NewSignalFormV1(props) {
    let {
            open, user, handleCancel, handlePreview,
            actionType, actionTypeErr, handleChangeActionType,
            quote, handleChangeQuote, quoteCurrenciesNom,
            base, handleChangeBase, baseCurrenciesNom,
            invalidPairError,
            selectPairErr,
            enterTradePriceFrom, handleChangeEnterTradePriceFrom,
            enterTradePriceTo, handleChangeEnterTradePriceTo,
            reFetchCurrentPrice,
            enterTradePricesErr,
            timeFrame, handleChangeTimeFrame,
            tvChartURL, handleChangeTVChartURL,
            stopLossTarget, handleChangeStopLossTarget,
            stopLossOption, handleChangeStopLossOption,
            switchWithOrWithoutStopLossTarget,
            takeProfitTargets, handleChangeTakeProfitTarget,
            takeProfitOption, handleChangeTakeProfitOption,
            addTakeProfitTarget, removeTakeProfitTarget,
            takeProfitErr, stopLossErr,
            comment, handleChangeComment,
            receivingSubscriptions, handleChangeReceivingSubscriptions, receivingSubscriptionsErr,
            subscriptions, signalId, potentialProfit, potentialLoss,
            riskRewardRatio,
            averagePosSize,
            showWarningForEntryPrice, currentPrice, delta,
            candlesType, handleChangeCandlesType
        } = props


    if (!open)
        return null

    return (
        <div key={"theForm"}>
            <div className={"vertSpacer2"}/>
            <div className={"wizardTitle"}>
                    <span className={"wizardTitleIcon"}><Polymer/></span>
                    {!signalId && <span>New <b>Spot</b> Signal for <b>Binance</b></span>}
                    {signalId && <span>Update <b>Spot</b> Signal for <b>Binance</b></span>}
            </div>
                <div className={"vertSpacer1_5"}/>
                <div className={"horizSeparator"}/>
                <div className={"vertSpacer1"}/>

                <SetReceivingSubscriptions
                    receivingSubscriptions={receivingSubscriptions}
                    receivingSubscriptionsErr={receivingSubscriptionsErr}
                    handleChangeReceivingSubscriptions={handleChangeReceivingSubscriptions}
                    subscriptions={subscriptions}
                    signalId={signalId}
                />
            <div className={"vertSpacer1_5"}/>
            <div className={"horizSeparator"}/>
            <div className={"vertSpacer1"}/>
            <SelectActionTypeRadios
                    readOnly={signalId}
                    user={user}
                    signalId={signalId}
                    componentLabel={"Type"}
                    actionType={actionType}
                    actionTypeErr={actionTypeErr}
                    handleChangeActionType={handleChangeActionType}
                    optionsSet={2}/>
            <div className={"vertSpacer1_5"}/>
            <div className={"horizSeparator"}/>
            <div className={"vertSpacer1_5"}/>
            <SelectPair handleChangeQuote={handleChangeQuote}
                        handleChangeBase={handleChangeBase}
                        quote={quote}
                        base={base}
                        quoteCurrenciesNom={quoteCurrenciesNom}
                        baseCurrenciesNom={baseCurrenciesNom}
                        selectPairErr={selectPairErr}
                        signalId={signalId}
                        hideAvailableBalances={true}
                        invalidPairError={invalidPairError}
            />
            <div className={"vertSpacer1_5"}/>
            <div className={"horizSeparator"}/>
            <div className={"vertSpacer1_5"}/>

                {showWarningForEntryPrice && <div>
                        <Paper style={{padding: "0.6rem", width: "22rem", marginBottom: "0.4rem", color: "#FB4A4A"}}>
                                <div>Entry price interval is <b>{delta}%</b>
                                        {actionType === c.ACTION_TYPE_BUY && <span> above </span>}
                                        {actionType === c.ACTION_TYPE_SELL && <span> below </span>}
                                        the current price of <b>{currentPrice}</b>!</div>
                        </Paper>
                </div>}
            <SetEnterTradePrices
                reFetchCurrentPrice={reFetchCurrentPrice}
                actionType={actionType}
                enterTradePriceFrom={enterTradePriceFrom}
                handleChangeEnterTradePriceFrom={handleChangeEnterTradePriceFrom}
                enterTradePriceTo={enterTradePriceTo}
                handleChangeEnterTradePriceTo={handleChangeEnterTradePriceTo}
                base={base}
                enterTradePricesErr={enterTradePricesErr}
            />

            <div className={"vertSpacer1_5"}/>
            <div className={"horizSeparator"}/>
            <div className={"vertSpacer1_5"}/>
            <SignalTradeConditions
                stopLossTarget={stopLossTarget} handleChangeStopLossTarget={handleChangeStopLossTarget}
                stopLossOption={stopLossOption} handleChangeStopLossOption={handleChangeStopLossOption}
                switchWithOrWithoutStopLossTarget={switchWithOrWithoutStopLossTarget}
                takeProfitTargets={takeProfitTargets} handleChangeTakeProfitTarget={handleChangeTakeProfitTarget}
                takeProfitOption={takeProfitOption} handleChangeTakeProfitOption={handleChangeTakeProfitOption}
                addTakeProfitTarget={addTakeProfitTarget} removeTakeProfitTarget={removeTakeProfitTarget}
                takeProfitErr={takeProfitErr} stopLossErr={stopLossErr}
                base={base}
                potentialProfit={potentialProfit}
                potentialLoss={potentialLoss}
                riskRewardRatio={riskRewardRatio}
                averagePosSize={averagePosSize}
                candlesType={candlesType}
                handleChangeCandlesType={handleChangeCandlesType}
            />
            <div className={"vertSpacer1_5"}/>
            <div className={"horizSeparator"}/>
            <div className={"vertSpacer1_5"}/>
            <SetTradingResearchDetails
                timeFrame={timeFrame}
                handleChangeTimeFrame={handleChangeTimeFrame}
                tvChartURL={tvChartURL}
                handleChangeTVChartURL={handleChangeTVChartURL}
            />
            <div className={"vertSpacer1_5"}/>
            <div className={"horizSeparator"}/>
            <div className={"vertSpacer1_5"}/>
            <SetComment
                comment={comment}
                handleChangeComment={handleChangeComment}/>
                <div className={"vertSpacer1_5"}/>
                <div className={"horizSeparator"}/>
                <div className={"vertSpacer1_5"}/>
            <div className={"vertSpacer1_5"}/>
            <div className={"horizSeparator"}/>
            <div className={"vertSpacer1"}/>
            <NewSignalFormMainButtons handleCancel={handleCancel} handlePreview={handlePreview}/>
        </div>
    )
}
