import React, { Component } from "react";
import { connect } from "react-redux";
import withGetScreenExt from "../../../withGetScreenExt";
import c from "../../../constants";
import {
  changeWallet,
  changeToDepositsTab,
  getBalances,
  changeToWithdrawalsTab,
  changeTab,
  getMarginBalances,
  getFuturesBalances,
} from "../../../redux/actions/wallet";
import SpotBalance from "./SpotBalance";
import MarginCrossBalance from "./MarginCrossBalance";
import FuturesBalance from "./FuturesBalance";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class Wallets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spotWalletLoaded: false,
      marginCrossWalletLoaded: false,
    };
    this.changeWallet = this.changeWallet.bind(this);
    this.getClassesForMainButtons = this.getClassesForMainButtons.bind(this);
    this.deposit = this.deposit.bind(this);
    this.withdraw = this.withdraw.bind(this);

    this.borrowMarginCross = this.borrowMarginCross.bind(this);
    this.repayMarginCross = this.repayMarginCross.bind(this);
  }

  repayMarginCross() {}

  borrowMarginCross() {}

  deposit(params) {
    this.props.changeToDepositsTab({
      selectedDepositCoin: params.coin,
      selectedDepositCoinAddress: params.depositAddress,
      coinBalanceEntry: params,
    });
    this.props.changeTab(c.DEPOSITS_TAB);
  }

  withdraw(params) {
    this.props.changeToWithdrawalsTab({
      selectedWithdrawCoin: params.coin,
      selectedWithdrawCoinAddress: "",
      coinBalanceEntry: params,
      withdrawFromWalletList: true,
    });
    this.props.changeTab(c.WITHDRAW_TAB);
  }

  changeWallet(wallet) {
    if (wallet === this.props.selectedWallet) return;
    if (wallet === "spot" && !this.state.spotWalletLoaded) {
      this.props.getBalances({}, () => (this.state.spotWalletLoaded = true));
    } else if (wallet === "futures" && !this.state.futuresWalletLoaded) {
      this.props.getFuturesBalances(
        {},
        () => (this.state.futuresWalletLoaded = true)
      );
    } else if (
      wallet === "margin_cross" &&
      !this.state.marginCrossWalletLoaded
    ) {
      this.props.getMarginBalances(
        {},
        () => (this.state.marginCrossWalletLoaded = true)
      );
    }
    this.props.changeWallet({ wallet });
  }

  getClassesForMainButtons(button) {
    if (this.props.selectedWallet === button) {
      if (this.props.selectedWallet === "spot") return "walletButtonSelected";
      return "walletButtonSelected walletButtonSpacer";
    } else {
      if (button === "spot") return "walletButton";
      else if (button === "margin_cross")
        return "walletButtonMargin walletButtonSpacer";
      else if (button === "futures") return "walletButton walletButtonSpacer";
    }
  }

  componentDidMount() {
    this.props.getBalances();
    this.props.getFuturesBalances();
  }
  // if (this.props.selectedWallet === "spot") {
  //     this.state.spotWalletLoaded = true
  //     this.props.getBalances()
  // } else if (this.props.selectedWallet === "margin_cross") {
  //     this.state.futuresWalletLoaded = true
  //     this.props.getFuturesBalances()
  // } else if (this.props.selectedWallet === "margin_cross") {
  //     this.state.marginCrossWalletLoaded = true
  //     this.props.getMarginBalances()
  // }

  render() {
    return (
      <div>
        {this.props.selectedWallet === "spot" && (
          <SpotBalance
            balances={this.props.balances}
            totals={this.props.totals}
            futuresBalances={this.props.futuresBalances}
            hasFuturesActivated={this.props.hasFuturesActivated}
            changeRenderCustomHeader={this.props.changeRenderCustomHeader}
          />
        )}
        {this.props.selectedWallet === "margin_cross" && (
          <MarginCrossBalance
            balances={this.props.marginBalances}
            actions={[
              { name: "Borrow", behaviour: this.borrowMarginCross },
              { name: "Repay", behaviour: this.repayMarginCross },
            ]}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (rootState) => ({
  user: rootState.authentication.user,
  selectedWallet: rootState.wallet.selectedWallet,
  balances: rootState.wallet.balances,
  totals: rootState.wallet.totals,
  marginBalances: rootState.wallet.marginBalances,
  futuresBalances: rootState.wallet.futuresBalances,
});

const mapDispatchToProps = (dispatch) => {
  return {
    changeWallet: (props) => dispatch(changeWallet(props)),
    changeTab: (tab) => dispatch(changeTab(tab)),
    getBalances: (props, cb) => dispatch(getBalances(props, cb)),
    changeToDepositsTab: (props, cb) => dispatch(changeToDepositsTab(props)),
    changeToWithdrawalsTab: (props, cb) => dispatch(changeToWithdrawalsTab(props)),
    getMarginBalances: (props, cb) => dispatch(getMarginBalances(props, cb)),
    getFuturesBalances: (props, cb) => dispatch(getFuturesBalances(props, cb)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withGetScreenExt(Wallets));
