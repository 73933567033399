import axios from "./axiosFabric"
import {
  SEND_FROM_STAKING_TO_SPOT_WALLET,
  STAKING_CHANGE_TAB,
  STAKING_GET_BALANCES, STAKING_GET_PROGRAMS,
  STAKING_GET_STAKING_OPERATIONS_ALL,
  STAKING_GET_STAKING_OPERATIONS_INITIAL,
  STAKING_GET_STAKING_REWARDS_ALL,
  STAKING_GET_STAKING_REWARDS_INITIAL, STAKING_STAKE, STAKING_UNSTAKE,
} from "./actionTypes";
import {isAuthorizationHeaderPresent} from "./authentication";
import {handleError} from "./errorHandler";


export const changeTab = tab => dispatch => {
    dispatch({
        type: STAKING_CHANGE_TAB,
        payload: tab
    })
}

export const getBalances = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/staking/getBalances`)
        .then(res => {
            if (cb) cb(res.data)
            dispatch({
                type: STAKING_GET_BALANCES,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getStakingRewardsInitial = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    props.page = 0
    axios.post(`/staking/getRewards`, props)
        .then(res => {
            dispatch({
                type: STAKING_GET_STAKING_REWARDS_INITIAL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getStakingRewardsByPage = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/staking/getRewards`, props)
        .then(res => {
            dispatch({
                type: STAKING_GET_STAKING_REWARDS_ALL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}


export const getStakingOperationsInitial = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    props.page = 0
    axios.post(`/staking/getOperations`, props)
        .then(res => {
            dispatch({
                type: STAKING_GET_STAKING_OPERATIONS_INITIAL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const getStakingOperationsByPage = (props) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/staking/getOperations`, props)
        .then(res => {
            dispatch({
                type: STAKING_GET_STAKING_OPERATIONS_ALL,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const stake = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/staking/stake`, props)
        .then(res => {
            if (cb) cb()
            dispatch({
                type: STAKING_STAKE,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const unstake = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/staking/unstake`, props)
        .then(res => {
            if (cb) cb()
            dispatch({
                type: STAKING_UNSTAKE,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}

export const sendToSpot = (props, cb) => dispatch => {
  if (!isAuthorizationHeaderPresent(axios))
    return
  axios.post(`/staking/sendtospot`, props)
    .then(res => {
      if (cb) cb(res.data)
      dispatch({
        type: SEND_FROM_STAKING_TO_SPOT_WALLET,
        payload: res.data
      })
    }).catch(error => handleError(error, dispatch))
}

export const getPrograms = (props, cb) => dispatch => {
    if (!isAuthorizationHeaderPresent(axios))
        return
    axios.post(`/staking/getPrograms`, props)
        .then(res => {
            if (cb) cb()
            dispatch({
                type: STAKING_GET_PROGRAMS,
                payload: res.data
            })
        }).catch(error => handleError(error, dispatch))
}
