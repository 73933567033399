import React from "react";
import {TextField} from "@material-ui/core"
import InputAdornment from "@material-ui/core/InputAdornment"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Gavel from '@material-ui/icons/Gavel'
import c from '../../constants'
import {Sync} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(1),
        width: 160,
        backgroundColor: '#101A26'
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    }
}))

export default function SetEnterTradePrices(props) {
    const {enterTradePriceFrom, enterTradePriceTo, handleChangeEnterTradePriceFrom,
        handleChangeEnterTradePriceTo, base, enterTradePricesErr, actionType, futuresType, currentPrice, reFetchCurrentPrice, stepFrom} = props
    const classes = useStyles()

    return (
    <div className={"wizardSectionContainer"}>
        <div className={"wizardTitle"}>
            <span className={"wizardTitleIcon"}><Gavel/></span> Entry
        </div>
        {enterTradePricesErr && <div className={"errorContainer"} style={{marginLeft: "0.6rem"}}>{enterTradePricesErr}</div>}
        <div style={{marginLeft: "0.6rem", display: "flex"}}>
            <TextField
                id="outlined-adornment-weight_set_enter_trade_prices"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                placeholder={"From price"}
                value={enterTradePriceFrom}
                onChange={handleChangeEnterTradePriceFrom}
                label={"From"}
                InputProps={{
                    endAdornment: <InputAdornment position="end">{base}</InputAdornment>,
                }}
                inputProps={{ maxLength: 11 }}
            />
            <div style={{verticalAlign: "center", marginRight: "0.6rem", marginTop: "0.8rem"}}>
                {actionType && <span>
                    {actionType === c.ACTION_TYPE_BUY && <span>&le;</span>}
                    {actionType === c.ACTION_TYPE_SELL && <span>&ge;</span>}
                </span>}
                {futuresType && <span style={{cursor: `${stepFrom ? "pointer": ""}`}}>
                    {futuresType === c.FUTURES_TYPE_LONG && <span onClick={stepFrom}>&le;</span>}
                    {futuresType === c.FUTURES_TYPE_SHORT && <span>&ge;</span>}
                </span>}

            </div>
            <TextField
                id="outlined-adornment-weight_set_enter_trade_prices_2"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                placeholder={"To price"}
                value={enterTradePriceTo}
                onChange={handleChangeEnterTradePriceTo}
                label={"To"}
                InputProps={{
                    endAdornment: <InputAdornment position="end">{base}</InputAdornment>,
                }}
                inputProps={{ maxLength: 11 }}
            />
            <div style={{cursor: "pointer", marginTop: "0.8rem"}} title={"Sync interval with current mark price"} onClick={reFetchCurrentPrice}><Sync/></div>
        </div>
        <div>

        </div>
        <div style={{marginLeft: "2rem", opacity: "0.8", marginTop: "0.6rem"}}>Current price {currentPrice}</div>
        <div className={"smallExplanations"} style={{width: "18rem", marginLeft: "2rem"}}>Each user receiving the signal will enter the trade with a price allocated from this range.</div>

    </div>)
}
