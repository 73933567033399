import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import {Edit, Close, RemoveCircle, AddCircle, ArrowUpward, ArrowDownward, DeleteForever} from "@material-ui/icons";
import {FormControlLabel, TextField, withStyles} from "@material-ui/core";
import SmallButton from "../../fncomponents/SmallButton";
import TraderSubscription from "../../fncomponents/TraderSubscription";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import c from "../../../constants";
import CustomSwitch from "../../fncomponents/CustomSwitch";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles(theme => ({
    menu: {
        width: 200,
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        backgroundColor: '#101A26'
    },
    textFieldShort: {
        width: 100,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        backgroundColor: '#101A26'
    },
    textFieldLong: {
        width: 300,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        backgroundColor: '#101A26'
    }
}))

const periodicitiesNom = [{key: 1, value: "Month"}, {key: 3, value: "Quarter"}, {key: 12, value: "Year"}]

const CustomColorCheckbox = withStyles({
    root: {
        color: "#ffffff",
        '&$checked': {
            color: "#ffffff",
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />)

export default function EditableSubscriptionSection(props) {
    const {subscription,
        editedSubscriptionId,
        editedSubscription,
        editedTraderSubscriptionElement,
        switchOnEditable,
        switchOffEditable,
        handleSave,
        handleCancel,
        handleChangeStatus,
        handleChangeName,
        handleChangePeriodicity,
        handleChangeDescription,
        handleChangeDiscountedPrice,
        handleChangeDiscounted,
        handleChangeListPrice,
        handleChangeFree,
        handleChangeIsMain,
        handleChangeOrderInList,
        handleChangeFeature,
        handleRemoveFeature,
        handleAddFeature,
        handleMoveUpFeature,
        handleMoveDownFeature,
        handleRemove

    } = props

    const classes = useStyles()

    if (!subscription)
        return null

    if (editedSubscriptionId === subscription._id)
        return (<div>
            <div className={"traderSalesPageEditableSectionContainer"}>
                <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}} title="Close edit mode">
                    <Close className={"smallMenuIcon"} onClick={switchOffEditable}/>
                </div>
                <div>
                    <div>
                        <CustomSwitch
                            className={classes.switch}
                            defaultChecked={editedSubscription.status === c.TRADER_SUBSCRIPTION_ACTIVE}
                            onChange={handleChangeStatus}
                            color="primary"
                            inputProps={{ 'aria-label': 'checkbox with default color' }}
                        /> Active
                        &nbsp;<span className={"helpIconContainer"} title={"Only active subscriptions will be shown to users."}>?</span>
                    </div>
                    <div style={{marginLeft: "0.6rem"}}>
                        {editedSubscription.isMain &&
                        <FormControlLabel key={Math.random()}
                                          control={<CustomColorCheckbox checked={true}
                                                                        onChange={handleChangeIsMain}/>}
                                          label={"Main"}
                        />}
                        {!editedSubscription.isMain &&
                        <FormControlLabel key={Math.random()}
                                          control={<CustomColorCheckbox checked={false}
                                                                        onChange={handleChangeIsMain}/>}
                                          label={"Main"}
                        />}
                        <span className={"helpIconContainer"} title={"A visual effect - marking this subscription as 'Main' will gain more of user's attention. Only one subscription can be main."}>?</span>
                    </div>
                    <div>
                        <TextField
                            id="subscription-edit-name"
                            className={classes.textField}
                            margin="dense"
                            variant="outlined"
                            placeholder={"Name"}
                            autoFocus={editedTraderSubscriptionElement === "name"}
                            value={editedSubscription.name}
                            onChange={handleChangeName}
                            label={"Name"}
                        />
                    </div>
                    <div style={{marginLeft: "0.6rem"}}>
                        {editedSubscription.free &&
                        <FormControlLabel key={Math.random()}
                                          control={<CustomColorCheckbox checked={true}
                                                                        onChange={handleChangeFree}/>}
                                          label={"Free"}
                        />}
                        {!editedSubscription.free &&
                        <FormControlLabel key={Math.random()}
                                          control={<CustomColorCheckbox checked={false}
                                                                        onChange={handleChangeFree}/>}
                                          label={"Free"}
                        />}
                    </div>
                    {!editedSubscription.free && <div style={{width: "100%"}}>
                        <div>
                            <TextField
                                id="subscription-edit-list-price"
                                className={classes.textField}
                                margin="dense"
                                variant="outlined"
                                autoFocus={editedTraderSubscriptionElement === "listPrice"}
                                value={editedSubscription.listPrice}
                                onChange={handleChangeListPrice}
                                label={"List price"}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USDT</InputAdornment>,
                                }}
                            />
                        </div>
                        <div style={{marginLeft: "0.6rem"}}>
                            {editedSubscription.discounted &&
                            <FormControlLabel key={Math.random()}
                                              control={<CustomColorCheckbox checked={true}
                                                                            onChange={handleChangeDiscounted}/>}
                                              label={"Discounted"}
                            />}
                            {!editedSubscription.discounted &&
                            <FormControlLabel key={Math.random()}
                                              control={<CustomColorCheckbox checked={false}
                                                                            onChange={handleChangeDiscounted}/>}
                                              label={"Discounted"}
                            />}
                        </div>
                        {editedSubscription.discounted && <div>
                            <TextField
                                id="subscription-edit-discounted-price"
                                className={classes.textField}
                                margin="dense"
                                variant="outlined"
                                autoFocus={editedTraderSubscriptionElement === "discountedPrice"}
                                disabled={editedSubscription.discounted === false}
                                value={editedSubscription.discountedPrice}
                                onChange={handleChangeDiscountedPrice}
                                inputProps={{ maxLength: 4 }}
                                label={"Discounted price"}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">USDT</InputAdornment>,
                                }}
                            />
                        </div>}
                    </div>}
                    <div>
                        <TextField
                            id="subscription-edit-order-in-list"
                            className={classes.textField}
                            margin="dense"
                            variant="outlined"
                            autoFocus={editedTraderSubscriptionElement === "orderInList"}
                            placeholder={"Order in list"}
                            value={editedSubscription.orderInList}
                            onChange={handleChangeOrderInList}
                            label={"Order in list"}
                        /> <span className={"helpIconContainer"} title={"A visual effect - on which position this subscription will be shown in the list."}>?</span>
                    </div>
                    <div>
                        <TextField
                            select
                            className={classes.textField}
                            SelectProps={{
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            autoFocus={editedTraderSubscriptionElement === "periodicity"}
                            value={editedSubscription.periodicity}
                            onChange={handleChangePeriodicity}
                            margin="dense"
                            variant="outlined"
                            label={"Periodicity"}>
                            {periodicitiesNom.map(el => {
                                return (<MenuItem key={el.key} value={el.key}>
                                    {el.value}
                                </MenuItem>)
                            })}
                        </TextField> <span className={"helpIconContainer"} title={"Time interval for which this subscription will be active. When the period ends the user will be invoiced automatically if he didn't specified otherwise."}>?</span>
                    </div>
                    <div>
                        <TextField
                            id="subscription-edit-description"
                            className={classes.textField}
                            margin="dense"
                            variant="outlined"
                            autoFocus={editedTraderSubscriptionElement === "description"}
                            placeholder={"Description"}
                            value={editedSubscription.description}
                            onChange={handleChangeDescription}
                            label={"Description"}
                        />
                    </div>
                    {editedSubscription.features && <div>
                        {editedSubscription.features.map((feature, index) => {
                            return <div style={{display: "flex"}} key={Math.random()}>
                                <TextField
                                id={`subscription-edit-feature_${index}`}
                                className={classes.textFieldLong}
                                margin="dense"
                                variant="outlined"
                                autoFocus={editedTraderSubscriptionElement === `feature_${index}`}
                                placeholder={`Feature ${index}`}
                                name={`feature_${index}`}
                                value={feature}
                                onChange={handleChangeFeature}
                                label={"Feature"}
                                />
                                {editedSubscription.features.length > 1 && <div onClick={()=>{handleRemoveFeature(index)}}><RemoveCircle className={"buttonsEditableSections"}/></div>}
                                <div onClick={()=>{handleAddFeature(index)}}><AddCircle className={"buttonsEditableSections"}/></div>
                                <div onClick={()=>{handleMoveUpFeature(index)}}><ArrowUpward className={"buttonsEditableSections"}/></div>
                                <div onClick={()=>{handleMoveDownFeature(index)}}><ArrowDownward className={"buttonsEditableSections"}/></div>
                            </div>
                        })}
                    </div>}
                </div>
                <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
                    <SmallButton label={"Save"} variant="outlined" onClick={handleSave}/>
                    <SmallButton label={"Cancel"} variant="outlined" onClick={handleCancel}/>
                </div>
            </div>
        </div>)
    else return (<div>
        <div className={"traderSalesPageEditableSectionContainer"}>
            <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}} title="Open edit mode" >
                <Edit className={"smallMenuIcon"} onClick={()=>{switchOnEditable(subscription)}}/>
                <div title={subscription._id}><DeleteForever className={"smallMenuIcon"} onClick={()=>{handleRemove(subscription)}}/></div>
            </div>
            <TraderSubscription subscription={subscription}/>
        </div>
    </div>)
}
