import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles";
import HistoryFilter from "./HistoryFilter";

const useStyles = makeStyles(theme => ({
}));

export default function StatsComponent(props) {
    const classes = useStyles()
    const  {stats, selected, id, base, selectedMonth, selectedYear} = props

    let ss = {}
    function selectStatsToShow() {
        let qq = stats[selected]
        if (selected === "monthly") {
            for(let i in qq) {
                if (qq[i].year == selectedYear && qq[i].month == selectedMonth ) {
                    ss = qq[i]
                    break
                }
            }
        } else {
            ss = qq
        }
    }

    selectStatsToShow()

    let netProfitClass = "statsNetProfitPlus"
    if (ss.net_profit < 0)
        netProfitClass = "statsNetProfitMinus"

    return (
        <div className="statsContainer1" id={id}>
            <span className="statsSectionTitle" title={`Total trades in ${base}`}>{ss.total ? ss.total: 0}</span>
            <div className="statsWinsVsLoss"><span className="statsWins" title="Wining trades">{ss.wins ? ss.wins : 0}</span><span className={"statsSeparator"}>/</span><span
                className="statsLosses" title="Losing trades">{ss.losses ? ss.losses : 0}</span></div>
            <div className={netProfitClass} title="Net Profit">{ss.net_profit ? ss.net_profit: 0}<span
                className="statsProfitCurrency"> {base}</span></div>
            <div><span className="statsWins" title="Average wining percentage">{ss.avg_wins_percentage ? ss.avg_wins_percentage : 0}</span><span
                className="statsPercentageWin">%</span><span className={"statsSeparator"}>/</span><span className="statsLosses"
                                                           title="Average losing percentage">{ss.avg_losses_percentage ? ss.avg_losses_percentage: 0}</span><span
                className="statsPercentageLoss">%</span></div>
        </div>)
}
