import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getTitle} from "../../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"
import NewBotForm from "./NewBotForm"
import c from "../../../constants"
import {getCurrenciesMap, getCurrentPriceOnPair, getUserBaseBalance} from "../../../redux/actions/newTrade"
import {brushValue, getValueWithPrecision, isValidPair} from "../../fncomponents/UISupportFns"
import {debounce} from "../../hoc/myUtils"
import {clearCurrentBot, getBotById, getNewBotId, preview} from "../../../redux/actions/bots"
import KeyboardBackArrow from '@material-ui/icons/KeyboardBackspace'
import {isUserSuperAdmin} from "../../../redux/actions/authentication"


const qs = require("query-string")

const DEFAULT_BTC_POS_SIZE = 0.1
const DEFAULT_USDT_POS_SIZE = 500
const DEFAULT_ETH_POS_SIZE = 3


const DEBOUNCE_DELAY_IN_MS = 500

class NewOrUpdateBot extends Component {

    constructor(props) {
        super(props)
        this.state = {
            actionType: c.ACTION_TYPE_BUY, //action_type_BUY or action_type_SELL
            tradeType: c.TRADE_TYPE_NEW_TRADE, //new or old
            quote: 'ETH',
            base: 'BTC',
            posSize: 0.1,
            actionTypeOption: c.ACTION_TYPE_OPTION_MARKET, //market, justAboveBid
            precision: 0,
            stopLossTarget: 5,
            stopLossOption: c.STOP_LOSS_OPTION_PERCENTAGE,//auto: price/percent, trailing
            openHelp: false,
            edit: false
        }

        this.handleChangeActionType = this.handleChangeActionType.bind(this)

        this.handleChangeQuote = this.handleChangeQuote.bind(this)
        this.handleChangeBase = this.handleChangeBase.bind(this)

        this.handleChangePosSize = this.handleChangePosSize.bind(this)
        this.fixPosSize = this.fixPosSize.bind(this)
        this.debounceFixPosSize = debounce(this.fixPosSize, DEBOUNCE_DELAY_IN_MS)

        this.selectMaxBaseBalance = this.selectMaxBaseBalance.bind(this)
        this.handleChangeActionTypeOption = this.handleChangeActionTypeOption.bind(this)
        this.handleChangeStopLossTarget = this.handleChangeStopLossTarget.bind(this)
        this.fixStopLossTarget = this.fixStopLossTarget.bind(this)
        this.debounceFixStopLossTarget = debounce(this.fixStopLossTarget, DEBOUNCE_DELAY_IN_MS)
        this.handleChangeStopLossOption = this.handleChangeStopLossOption.bind(this)

        this.populateStateBotFromBot = this.populateStateBotFromBot.bind(this)

        this.previewBot = this.previewBot.bind(this)
        this.cancel = this.cancel.bind(this)

        this.handleOpenHelp = this.handleOpenHelp.bind(this)
        this.handleCloseHelp = this.handleCloseHelp.bind(this)
    }

    handleChangeActionType() {
        if (this.state.actionType === c.ACTION_TYPE_BUY) {
            this.setState({
                actionType: c.ACTION_TYPE_SELL
            })
        } else {
            this.setState({
                actionType: c.ACTION_TYPE_BUY
            })
        }
    }

    handleChangeQuote(e) {
        if (this.state.signalId)
            return
        let newQuote = e.target.value.toUpperCase()
        this.setState({
            quote: newQuote
        })

        this.props.getCurrentPriceOnPair({quote: newQuote, base: this.state.base}, (currentLastBidPrice, precision) => {
            this.setState({
                currentLastBidPrice: currentLastBidPrice ? currentLastBidPrice : 0,
                precision: precision
            })
        })
    }

    handleChangeBase(e) {
        if (this.state.signalId)
            return
        let newBase = e.target.value
        let defaultPosSize = 0
        if (newBase === "BTC")
            defaultPosSize = DEFAULT_BTC_POS_SIZE
        else if (newBase === "USDT")
            defaultPosSize = DEFAULT_USDT_POS_SIZE
        else if (newBase === "ETH")
            defaultPosSize = DEFAULT_ETH_POS_SIZE
        this.setState({
            base: newBase,
            posSize: defaultPosSize
        })
        this.props.getUserBaseBalance({currency: newBase})
    }

    handleChangePosSize(e) {
        this.setState({
            posSize: e.target.value
        })
        this.debounceFixPosSize()
    }

    fixPosSize() {
        if (this.state.posSize.length > 0)
            this.setState({
                posSize: getValueWithPrecision(this.state.posSize, 8)
            })
    }

    selectMaxBaseBalance(maxBaseBalance) {
        this.setState({
            posSize: maxBaseBalance
        })
    }


    handleChangeActionTypeOption(actTypeOption) {
        if (this.state.quote && this.state.quote.length < 2)
            return

        this.setState({
            actionTypeOption: actTypeOption
        })

        if (this.state.quote.length > 1 && actTypeOption != c.ACTION_TYPE_OPTION_MARKET) {
            this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, result => {
                let tickSize = parseInt(result.tick_size)
                let currentLastBidPrice = parseFloat(result.current_price)
                let price = 0
                if (actTypeOption === c.ACTION_TYPE_OPTION_JUST_ABOVE_BID_PRICE) {
                    price = currentLastBidPrice * c.LAST_BID_PRICE_MULTIPLIER
                } else if (actTypeOption === c.ACTION_TYPE_OPTION_JUST_BELOW_ASK_PRICE) {
                    price = currentLastBidPrice * c.FIRST_ASK_PRICE_MULTIPLIER
                }
                this.state.currentLastBidPrice = brushValue(price, tickSize)
            })
        }
    }

    handleChangeStopLossTarget(e) {
        this.setState({
            stopLossTarget: e.target.value
        })
        this.debounceFixStopLossTarget()
    }

    fixStopLossTarget() {
        let stopLossTarget = `${this.state.stopLossTarget}`
        stopLossTarget = stopLossTarget.replace("-","")
        if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE) {
            this.state.stopLossTarget = getValueWithPrecision(stopLossTarget, 1)
        } else {
            this.state.stopLossTarget = getValueWithPrecision(stopLossTarget, this.state.precision)
        }
        this.setState({
            random: Math.random()
        })
    }

    handleChangeStopLossOption(option) {
        //If there is no change, do nothing
        if (this.state.stopLossOption == option) {
            this.setState({
                stopLossOption: c.STOP_LOSS_OPTION_MANUAL,
                stopLossTarget: 0
            })
            return
        }

        //If the transition is from Percentage/Trailing or Price, transform
        //percentage to price and vice-versa
        if (option === c.STOP_LOSS_OPTION_PRICE) {
            let oldPercent = parseFloat(this.state.stopLossTarget)
            let currentPrice = this.props.currentLastBidPrice
            let price = (1 - oldPercent/100) * parseFloat(currentPrice)
            this.state.stopLossTarget = brushValue(price, this.state.precision)
        } else if (option === c.STOP_LOSS_OPTION_PERCENTAGE) {
            let oldPrice = parseFloat(this.state.stopLossTarget)
            let currentPrice = this.props.currentLastBidPrice
            let percent = ((currentPrice - oldPrice) / currentPrice) * 100
            this.state.stopLossTarget = brushValue(percent, 1)
        }
        this.setState({
            stopLossOption: option
        })
    }

    previewBot() {
        let errors = false
        this.state.selectPairErr = undefined
        this.state.stopLossErr = undefined

        if (!this.state.quote || !isValidPair(this.state.quote, this.state.base, this.props.quoteCurrenciesNom)) {
            errors = true
            this.setState({
                selectPairErr: "Please specify a valid pair"
            })
        }

        if (this.state.posSize === 0) {
            errors = true
            this.setState({
                posSizeErr: "Please specify a position size greater than 0.002"
            })
        }


        if (!errors) {
            this.props.preview({
                botDbId: this.state.botDbId,
                botId: this.state.botId,
                botSecret: this.state.botSecret,
                quoteAsset: this.state.quote,
                baseAsset: this.state.base,
                precision: this.state.precision,
                posSize: this.state.posSize,
                actionType: this.state.actionType,
                actionTypeOption: this.state.actionTypeOption,
                stopLossTarget: this.state.stopLossTarget,
                stopLossOption: this.state.stopLossOption,
            }, () => {
                this.props.history.push("/previewbot")
            })
        }
    }

    cancel() {
        this.props.clearCurrentBot(()=>{
            this.setState({
                botDbId: undefined,
                botId: undefined,
                quote: 'ETH',
                base: 'BTC',
                stopLossTarget: 5,
                stopLossOption: c.STOP_LOSS_OPTION_PERCENTAGE,//auto: price/percent, trailing
            })
            this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, (currentLastBidPrice, precision) => {
                this.setState({
                    currentLastBidPrice: currentLastBidPrice ? currentLastBidPrice : 0,
                    enterTradePriceFrom: currentLastBidPrice ? currentLastBidPrice : 0,
                    precision: precision,
                    stopLossTarget: brushValue(currentLastBidPrice * (1 - this.state.stopLossTarget / 100), precision),
                })
            })
        })
    }

    populateStateBotFromBot(bot, fromProps) {
        this.setState({
            botDbId: (fromProps ? bot.botDbId: bot._id),
            botId: (fromProps ? bot.botId: bot.id),
            botSecret: (fromProps ? bot.botSecret: bot.secret),
            actionType: bot.actionType,
            quote: bot.quoteAsset,
            base: bot.baseAsset,
            posSize: bot.posSize,
            precision: bot.precision,
            actionTypeOption: bot.actionTypeOption,
            stopLossTarget: bot.stopLossTarget,
            stopLossOption: bot.stopLossOption
        })
        this.props.getUserBaseBalance({currency: bot.baseAsset})
    }

    handleOpenHelp(event) {
        this.setState({
            openHelp: true,
            openHelpAnchorEl: event.currentTarget
        })
    }

    handleCloseHelp() {
        this.setState({
            openHelp: false
        })
    }

    componentDidMount() {
        if (!this.props.baseCurrenciesNom) {
            this.props.getCurrenciesMap()
        }

        this.state.botDbId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).botId
        if (this.state.botDbId) {
            this.props.getBotById({botDbId: this.state.botDbId, isUserSuperAdmin: isUserSuperAdmin(this.props.user)}, bot => {
                this.populateStateBotFromBot(bot)
            })
        } else {
            if (this.props.bot) {
                //If the user came back from Preview Bot
                this.populateStateBotFromBot(this.props.bot, true)
            } else {
                this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, result => {
                    let tickSize = parseInt(result.tick_size)
                    let currentLastBidPrice = brushValue(result.current_price, tickSize)
                    this.props.getNewBotId(({botId, botSecret}) => {
                        this.setState({
                            botId: botId,
                            botSecret: botSecret,
                            currentLastBidPrice: currentLastBidPrice ? currentLastBidPrice : 0,
                            tickSize: tickSize,
                            precision: parseInt(result.precision),
                            stopLossTarget: 5,
                            stopLossOption: c.STOP_LOSS_OPTION_PERCENTAGE
                        })
                        this.props.getUserBaseBalance({currency: this.state.base})
                    })
                })
            }
        }
    }

    render() {
        let {user} = this.props
        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <div style={{display: "flex"}}>
                <div style={{display: "flex", justifyContent: "flex-start", width: "7em", cursor: "pointer", marginTop: "0.05rem"}}
                     onClick={()=>{this.props.history.push("/bots")}} >
                    <KeyboardBackArrow/>
                    <div className={"pageTitle"} style={{marginLeft: "1rem"}}>Back</div>
                </div>
            </div>
            <NewBotForm
                open={true}
                botDbId={this.state.botDbId}
                botId={this.state.botId}
                user={user}
                botSecret={this.state.botSecret}
                handleCancel={this.cancel}
                handlePreview={this.previewBot}
                actionType={this.state.actionType}
                handleChangeActionType={this.handleChangeActionType}
                tradeType={this.state.tradeType}
                quote={this.state.quote}
                handleChangeQuote={this.handleChangeQuote}
                quoteCurrenciesNom={this.props.quoteCurrenciesNom}
                base={this.state.base}
                handleChangeBase={this.handleChangeBase}
                baseCurrenciesNom={this.props.baseCurrenciesNom}
                selectPairErr={this.state.selectPairErr}
                posSize={this.state.posSize}
                handleChangePosSize={this.handleChangePosSize}
                posSizeErr={this.state.posSizeErr}
                userBaseBalance={this.props.userBaseBalance}
                selectMaxBaseBalance={this.selectMaxBaseBalance}
                actionTypeOption={this.state.actionTypeOption}
                handleChangeActionTypeOption={this.handleChangeActionTypeOption}
                currentLastBidPrice={this.state.currentLastBidPrice}
                stopLossTarget={this.state.stopLossTarget}
                stopLossOption={this.state.stopLossOption}
                handleChangeStopLossTarget={this.handleChangeStopLossTarget}
                handleChangeStopLossOption={this.handleChangeStopLossOption}
                takeProfitErr={this.state.takeProfitErr}
                stopLossErr={this.state.stopLossErr}
                openHelp={this.state.openHelp}
                openHelpAnchorEl={this.state.openHelpAnchorEl}
                handleOpenHelp={this.handleOpenHelp}
                handleCloseHelp={this.handleCloseHelp}
            />
        </div>

    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    userBaseBalance: rootState.newTrade.userBaseBalance,
    baseCurrenciesNom: rootState.newTrade.baseCurrenciesNom,
    quoteCurrenciesNom: rootState.newTrade.quoteCurrenciesNom,
    currentLastBidPrice: rootState.newTrade.currentLastBidPrice,
    precision: rootState.newTrade.precision,
    bot: rootState.bots.bot
})

let mapDispatchToProps = dispatch => {
    return {
        getUserBaseBalance: (props) => dispatch(getUserBaseBalance(props)),
        getNewBotId: (cb) => dispatch(getNewBotId(cb)),
        getCurrenciesMap: (props) => dispatch(getCurrenciesMap(props)),
        getCurrentPriceOnPair: (props, cb) => dispatch(getCurrentPriceOnPair(props, cb)),
        preview: (props, cb) => dispatch(preview(props, cb)),
        getBotById: (props, cb) => dispatch(getBotById(props, cb)),
        clearCurrentBot: (cb) => dispatch(clearCurrentBot(cb))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (NewOrUpdateBot)
