import React from 'react'
import {ChevronLeft} from "@material-ui/icons";
import {Button} from "@material-ui/core";

export default function InvestmentTermsAndCond (props) {

    const {data, back} = props
    function renderTermsAndConditions() {
        if (!data.termsAndCond)
            return null
        if (data.termsAndCond.length === 0)
            return null
        let rows = []
        for(let i = 0; i < data.termsAndCond.length; i++) {
            rows.push(<tr><td style={{verticalAlign: "top"}}>♦</td><td>{data.termsAndCond[i]}</td></tr>)
        }
        return <div style={{fontSize: "0.78rem", marginTop: "0.5rem", marginLeft: "0.2rem", maxWidth: "30rem", opacity: "0.9"}}>
                    <table>
                        {rows}
                    </table>
                </div>
    }

    return <div style={{marginTop: "1rem"}}>
                <Button onClick={()=>back()}><ChevronLeft/> {data.title}</Button>
                <div style={{marginLeft: "1rem"}}><h3>{data.title} Terms & Conditions</h3></div>
                {renderTermsAndConditions()}
            </div>
}