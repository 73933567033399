import React from 'react'

export default function UserPermissions(props) {
    const {permissions, size} = props

    function isRole(permissions, role) {
        if (!permissions)
            return false
        if (permissions && permissions.length === 0)
            return false
        for(let i = 0; i < permissions.length; i++) {
            if (permissions[i] === role)
                return true
        }

        return false
    }

    function getRoles() {
        let rolesContent = []
        if (!permissions)
            return rolesContent
        if (permissions && permissions.length === 0)
            return rolesContent

        if (isRole(permissions, "superAdmin"))
            rolesContent.push(<span style={{color: "#FE484F"}} key={Math.random()}>S.Admin&nbsp;</span>)
        if (isRole(permissions, "admin"))
            rolesContent.push(<span style={{color: "#64fe7c"}} key={Math.random()}>Admin&nbsp;</span>)
        if (isRole(permissions, "trader"))
            rolesContent.push(<span style={{color: "#3BADFF"}} key={Math.random()}>Trader&nbsp;</span>)
        return rolesContent
    }

    function getClassName() {
        if (!size)
            return "userPermissionsContainerNormal"
        if (size.toLowerCase() === "small")
            return "userPermissionsContainerSmall"
        if (size.toLowerCase() === "normal")
            return "userPermissionsContainerNormal"
        return "userPermissionsContainerNormal"
    }
    return (
       <div className={getClassName()}>
           {getRoles()}
        </div>
    )
}
