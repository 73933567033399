let state = {
    userIsScrolling: false,
    index: 0
}

let isIPhone = navigator.appVersion.indexOf("iPhone")!=-1

export function setScrollIsOn() {
    state.userIsScrolling = true
}

export function setScrollIsOff() {
    state.userIsScrolling = false
}

export function setIndex(index) {
    state.index = index
}

export function getIndex() {
    return state.index
}
export default state
