import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {brushValue, formatNumberWithCommas} from "./UISupportFns";
import {ArrowDownward, ArrowUpward} from "@material-ui/icons";
import {parse} from "query-string";

const useStyles = makeStyles(theme => ({
    root: {
        width: 380,
        overflowX: 'auto',
        backgroundColor: "#1B212F",
        justifyContent: "center"
    },

    firstColumnAsk: {
        color: "#FE484F",
        fontSize: 12,
        border: "0px",
        height: 13
    },

    firstColumnBid: {
        color: "#68FF7D",
        fontSize: 12,
        border: "0px",
        height: 13
    },

    secondColumn: {
        fontSize: 12,
        border: "0px",
    },

    thirdColumn: {
        fontSize: 12,
        width: 80,
        border: "0px",
    },

    _currentPrice: {
        fontSize: 16,
        border: "0px",
    },

    __currentPrice: {
        backgroundColor: "#1B212F",
        width: "100%",
        height: "2rem",
    },

    table: {
        margin: "auto",
    },
}));


export default function OrderBook(props) {
    const classes = useStyles();
    const {base, quote, orderBook, size, futures} = props

    function listAsks(orderBook) {
        let asksRows = []
        let asks = orderBook.asks
        if (!asks)
            return
        // let _size = asks ? (size > asks.length ? asks.length: size): size
        let _size = size
        let sum = [0]
        let sz = []
        for(let i =0; i < _size; i++) {
            let a = asks[i]
            let j = i+1
            if (a) {
                sum[j] = sum[j-1] + parseFloat(a[0]) * parseFloat(a[1])
                sz[i] = parseFloat(a[0]) * parseFloat(a[1])
            }
        }

        for(let i = _size -1; i  >= 0 && asks; i--) {
            let a = asks[i]
            if (!a) {
                asksRows.push(<TableRow key={`ask_${Math.random()}`}>
                    <TableCell component="th" scope="row" className={classes.firstColumnAsk}>&nbsp;</TableCell>
                    <TableCell align="right" className={classes.secondColumn}>&nbsp;</TableCell>
                    <TableCell align="right" className={classes.thirdColumn}>&nbsp;</TableCell>
                </TableRow>)
            } else {
                let price = a[0]
                let quantity = a[1]
                let _price = price, _quantity, _lastColumn
                // if (futures) {
                //     _quantity = formatNumberWithCommas(brushValue(sz[i], orderBook.precision))
                //     _lastColumn = formatNumberWithCommas(brushValue(sum[i+1], orderBook.precision))
                // } else {
                //     _price = brushValue(price, orderBook.tickSize)
                //     _quantity = brushValue(quantity, orderBook.precision)
                //     _lastColumn = formatNumberWithCommas(brushValue(sz[i], orderBook.tickSize))
                // }
                // _price = formatNumberWithCommas(brushValue(price, orderBook.tickSize))
                // _price = price
                // _quantity = brushValue(quantity, orderBook.precision)
                if (!futures) {
                    price = brushValue(price, orderBook.tickSize)
                    quantity = brushValue(quantity, orderBook.precision)
                }
                _lastColumn = formatNumberWithCommas(brushValue(sz[i], orderBook.tickSize))

                asksRows.push(<TableRow key={`ask_${Math.random()}`}>
                    <TableCell component="th" scope="row" className={classes.firstColumnAsk}>{price}</TableCell>
                    <TableCell align="right" className={classes.secondColumn}>{quantity}</TableCell>
                    <TableCell align="right" className={classes.thirdColumn}>{_lastColumn}</TableCell>
                </TableRow>)
            }

        }
        return asksRows
    }


    function listBids(orderBook) {
        let bidsRows = []
        let bids = orderBook.bids
        if (!bids)
            return
        let _size = bids ? (size > bids.length ? bids.length: size): size
        let sum = 0, sz

        for(let i = 0; i < _size && bids; i++) {
            let b = bids[i]
            let price = b[0]
            let quantity = b[1]
            sum = sum + parseFloat(b[0]) * parseFloat(b[1])
            sz = parseFloat(b[0]) * parseFloat(b[1])

            let _lastColumn

            if (!futures) {
                price = brushValue(price, orderBook.tickSize)
                quantity = brushValue(quantity, orderBook.precision)
            }

            _lastColumn = formatNumberWithCommas(brushValue(sz, orderBook.tickSize))

            bidsRows.push(<TableRow key={`bids_${Math.random()}`}>
                <TableCell component="th" scope="row" className={classes.firstColumnBid}>{price}</TableCell>
                <TableCell align="right" className={classes.secondColumn}>{quantity}</TableCell>
                <TableCell align="right" className={classes.thirdColumn}>{_lastColumn}</TableCell>
            </TableRow>)
        }

        return bidsRows
    }


    function getLastBid(orderBook) {
        if (orderBook && orderBook.bids && orderBook.bids[0]) {
            return formatNumberWithCommas(brushValue(orderBook.bids[0][0], orderBook.tickSize))
        }
    }

    if (!orderBook)
        return null

    let currentPriceColor = "#ffffff"
    switch (orderBook.trend) {
        case 1: currentPriceColor = "#68FF7D"; break;
        case -1: currentPriceColor = "#FE484F"; break;
        default: currentPriceColor = "#FFFFFF"; break;
    }

    return (
        <Paper className={classes.root} style={{margin: "auto"}}>
            <Table className={classes.table} size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell align="right" style={{width: "6rem"}}>Price({base})</TableCell>
                        <TableCell align="right" style={{width: "6rem"}}>Amt({quote})</TableCell>
                        <TableCell align="right" style={{width: "6rem"}}>Total ({base})</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {listAsks(orderBook)}
                    <TableRow>
                        <TableCell colSpan={3} className={classes._currentPrice} style={{textAlign: "center", color: currentPriceColor}}>
                            <div className={classes.__currentPrice}>
                                <span style={{position: "relative"}}>
                                    {getLastBid(orderBook)}
                                </span>
                                <span style={{position: "relative", top: "0.4rem"}}>
                                    {orderBook.trend === 0 && <ArrowUpward style={{color: "#1B212F"}}/>}
                                    {orderBook.trend === 1 && <ArrowUpward/>}
                                    {orderBook.trend === -1 && <ArrowDownward/>}
                                </span>
                            </div>
                        </TableCell>
                    </TableRow>
                    {listBids(orderBook)}
                </TableBody>
            </Table>
        </Paper>
    );
}
