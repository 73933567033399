import React from 'react'
import NewSignalFormMainButtons from "../../fncomponents/NewSignalFormMainButtons"
import {Polymer} from "@material-ui/icons"
import SetAccountLeverageFutures from "../../fncomponents/SetAccountLeverageFutures";
import SelectFuturesTypeRadios from "../../fncomponents/SelectFuturesTypeRadios";
import SelectPairFutures from "../../fncomponents/SelectPairFutures";
import SetEnterTradePrices from "../../fncomponents/SetEnterTradePrices";
import {Paper} from "@material-ui/core";
import c from "../../../constants";
import SignalTradeConditions from "../../fncomponents/SignalTradeConditions";
import SetComment from "../../fncomponents/SetComment";
import SetReceivingSubscriptions from "../../fncomponents/SetReceivingSubscriptions";


export default function NewSignalFormV1Futures(props) {
    let {
            open, signalId, handleCancel, handlePreview,
            quote, base,
            isAdjustLeverageOpen, adjustLeverage,
            maxLeverage,
            leverage, handleChangeLeverage,
            leverageSlider, handleChangeLeverageSlider, decreaseLeverage, increaseLeverage,
            cancelAdjustLeverage, confirmNewLeverage,

            futuresType, handleChangeFuturesType,

            quoteCurrenciesNom, handleChangeQuote, baseCurrenciesNom, handleChangeBase,

            delta, currentPrice, reFetchCurrentPrice,
            enterTradePriceFrom, handleChangeEnterTradePriceFrom, enterTradePriceTo, handleChangeEnterTradePriceTo, enterTradePricesErr, showWarningForEntryPrice,
            stepFrom,

            stopLossTarget, handleChangeStopLossTarget, stopLossOption, handleChangeStopLossOption, switchWithOrWithoutStopLossTarget, stopLossErr,

            takeProfitTargets, handleChangeTakeProfitTarget, takeProfitOption, handleChangeTakeProfitOption, addTakeProfitTarget, removeTakeProfitTarget, takeProfitErr,
            potentialProfit, potentialLoss, riskRewardRatio, AVERAGE_BTC_POS_SIZE,

            comment, handleChangeComment,
            receivingSubscriptions, handleChangeReceivingSubscriptions, receivingSubscriptionsErr, subscriptions
        } = props


    if (!open)
        return null

    return (
        <div key={"theForm"}>
            <div className={"vertSpacer2"}/>
            <div className={"wizardTitle"}>
                    <span className={"wizardTitleIcon"}><Polymer/></span>
                    {!signalId && <span>New <b>Futures</b> Signal for <b>Binance</b></span>}
                    {signalId && <span>Update <b>Futures</b> Signal for <b>Binance</b></span>}
            </div>
            <div className={"vertSpacer1_5"}/>
            <div className={"horizSeparator"}/>
            <div className={"vertSpacer1"}/>
            <SetReceivingSubscriptions
                    receivingSubscriptions={receivingSubscriptions}
                    receivingSubscriptionsErr={receivingSubscriptionsErr}
                    handleChangeReceivingSubscriptions={handleChangeReceivingSubscriptions}
                    subscriptions={subscriptions}
                    signalId={signalId}
            />
                <div className={"vertSpacer1_5"}/>
                <div className={"horizSeparator"}/>
                <div className={"vertSpacer1"}/>
            <SetAccountLeverageFutures
                isEditable={isAdjustLeverageOpen}
                leverage={leverage}
                maxLeverage={maxLeverage}
                handleChangeLeverage={handleChangeLeverage}
                leverageSlider={leverageSlider}
                handleChangeLeverageSlider={handleChangeLeverageSlider}
                decreaseLeverage={decreaseLeverage}
                increaseLeverage={increaseLeverage}
                quote={quote}
                base={base}
                adjustLeverage={adjustLeverage}
                cancelAdjustLeverage={cancelAdjustLeverage}
                confirmNewLeverage={confirmNewLeverage}
            />
            <SelectFuturesTypeRadios futuresType={futuresType}
                                     handleChangeFuturesType={handleChangeFuturesType} signals={true}/>
            <div className={"vertSpacer1_5"}/>
            <div className={"horizSeparator"}/>
            <div className={"vertSpacer1_5"}/>
            <SelectPairFutures handleChangeQuote={handleChangeQuote}
                               handleChangeBase={handleChangeBase}
                               quote={quote}
                               base={base}
                               quoteCurrenciesNom={quoteCurrenciesNom}
                               baseCurrenciesNom={baseCurrenciesNom}
            />
            <div className={"vertSpacer1_5"}/>
            <div className={"horizSeparator"}/>
            <div className={"vertSpacer1_5"}/>
            {showWarningForEntryPrice && <div>
                <Paper style={{padding: "0.6rem", width: "22rem", marginBottom: "0.4rem", color: "#FB4A4A"}}>
                        <div>Entry price interval is <b>{delta}%</b>
                                {futuresType === c.FUTURES_TYPE_LONG && <span> above </span>}
                                {futuresType === c.FUTURES_TYPE_SHORT && <span> below </span>}
                                the current price of <b>{currentPrice}</b>!</div>
                </Paper>
            </div>}
            <SetEnterTradePrices
                reFetchCurrentPrice={reFetchCurrentPrice}
                futuresType={futuresType}
                currentPrice={currentPrice}
                enterTradePriceFrom={enterTradePriceFrom}
                handleChangeEnterTradePriceFrom={handleChangeEnterTradePriceFrom}
                enterTradePriceTo={enterTradePriceTo}
                handleChangeEnterTradePriceTo={handleChangeEnterTradePriceTo}
                base={base}
                enterTradePricesErr={enterTradePricesErr}
                stepFrom={stepFrom}
            />
            <div className={"vertSpacer1_5"}/>
            <div className={"horizSeparator"}/>
            <div className={"vertSpacer1_5"}/>
            <SignalTradeConditions
                    stopLossTarget={stopLossTarget} handleChangeStopLossTarget={handleChangeStopLossTarget}
                    stopLossOption={stopLossOption} handleChangeStopLossOption={handleChangeStopLossOption}
                    switchWithOrWithoutStopLossTarget={switchWithOrWithoutStopLossTarget}
                    takeProfitTargets={takeProfitTargets} handleChangeTakeProfitTarget={handleChangeTakeProfitTarget}
                    takeProfitOption={takeProfitOption} handleChangeTakeProfitOption={handleChangeTakeProfitOption}
                    addTakeProfitTarget={addTakeProfitTarget} removeTakeProfitTarget={removeTakeProfitTarget}
                    takeProfitErr={takeProfitErr} stopLossErr={stopLossErr}
                    base={base}
                    potentialProfit={potentialProfit}
                    potentialLoss={potentialLoss}
                    riskRewardRatio={riskRewardRatio}
                    AVERAGE_BTC_POS_SIZE={AVERAGE_BTC_POS_SIZE}
            />
            <div className={"vertSpacer1_5"}/>
            <div className={"horizSeparator"}/>
            <div className={"vertSpacer1_5"}/>
            <SetComment
                comment={comment}
                handleChangeComment={handleChangeComment}/>
            <div className={"vertSpacer1_5"}/>
            <div className={"horizSeparator"}/>
            <div className={"vertSpacer1_5"}/>
            <NewSignalFormMainButtons handleCancel={handleCancel} handlePreview={handlePreview}/>
        </div>
    )
}
