import React, { useState, useEffect, useMemo } from "react";
import c from '../../constants'
import { useDispatch } from "react-redux";
import { Divider, Button, Chip, List, ListItem } from "@material-ui/core";
import CreditCardIcon from "@material-ui/icons/CreditCard";

import { format } from "date-fns";
import { SpotCustomHeader } from "../../components/fncomponents/CustomHeaders";

import {
  getRecentDeposits,
  getRecentWithdrawals,
  getRecentTransfers,
  getFuturesBalances,
  getRecentInternalTransfers, getRecentInvestmentPurchases,
} from "../../redux/actions/wallet";

import DepositModal from "./DepositModal";

import constants from "../../constants";
import {
  CHANGE_SELECTED_WITHDRAWAL_COIN, CHANGE_TO_STAKING_TAB,
  CHANGE_TO_TRANSFERS_TAB,
} from "../../redux/actions/actionTypes";
import {brushValue} from "./UISupportFns";
import {
  isDecrement,
  isLong,
  renderType,
  enhanceWithType,
  updateStatus,
  isDepositable, isStakeable,
} from "../pages/wallet/WalletUtilities";

const { TRANSACTION_MINUS_COLOR, TRANSACTION_PLUS_COLOR } = constants;


const mapRecordToRow = (myId) => (el) =>
  (
    <ListItem key={el.creationDate}>
      <div className="all-transactions__list__record">
        <div
          className="all-transactions__list__record__amount"
          style={{
            color: isDecrement(myId, el)
              ? TRANSACTION_MINUS_COLOR
              : TRANSACTION_PLUS_COLOR,
          }}
        >
          {isDecrement(myId, el) ? "-" : "+"} {el.amount}
        </div>
        <div className="all-transactions__list__record__recordedDate">
          {format(new Date(el.creationDate), "MMM d, yyyy")}
        </div>
        <div className="all-transactions__list__record__status">{el.status}</div>
        <div className="all-transactions__list__record__informations">
          {renderType(myId, el)}
        </div>
      </div>
    </ListItem>
  );

const mapFuturesRecordToRow = (el) => (
  <ListItem key={el._id}>
    <div className="balance__list__futures__record">
      <div
        className={`balance__list__futures__type__${
          isLong(el) ? "long" : "short"
        }`}
      >
        {el.type}
      </div>
      <div className="balance__list__futures__symbol">{el.symbol} </div>
      <div className="balance__list__futures__balance">
        {el.type === "LONG" && <span>{el.balance}</span>}
        {(el.type === "SHORT" && el.balance >= 0) && <span>-{el.balance}</span>}
        {(el.type === "SHORT" && el.balance < 0) && <span style={{color: "#fb4a4a"}}>{el.balance}</span>}
      </div>
    </div>
  </ListItem>
);


const BalanceTab = ({
  currency,
  recentDeposits,
  recentTransfers,
  recentInternalTransfers,
  recentWithdrawals,
  recentInvestmentPurchases,
  futuresBalances,
  changeRenderCustomHeader,
  user,
}) => {

  const [selectedTab, setSelectedTab] = useState(currency.initialTab || "all");
  const [modalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();

  const {
    coin,
    totalBalance,
    availableBalance,
    inOrderBalance,
    isolatedMargin,
    name,
    isFuture,
  } = currency;

  useEffect(() => {
    getRecentDeposits()(dispatch);
    getRecentTransfers()(dispatch);
    getRecentInternalTransfers()(dispatch);
    getRecentWithdrawals()(dispatch);
    getRecentInvestmentPurchases()(dispatch);
    getFuturesBalances()(dispatch);
    changeRenderCustomHeader(() => <SpotCustomHeader currentLevel={1}/>);
  }, []);

  const allRecords = useMemo(
    () =>
      enhanceWithType("transfer")(recentTransfers)
        ?.concat(enhanceWithType("internalTransfer")(recentInternalTransfers))
        ?.concat(enhanceWithType("deposit")(recentDeposits))
        ?.concat(enhanceWithType("withdrawal")(recentWithdrawals))
        ?.concat(enhanceWithType("investment")(recentInvestmentPurchases)),
    [recentWithdrawals, recentDeposits, recentTransfers, recentInvestmentPurchases]
  );

  return (
    <div className="balance">
      <div className="balance__top">
        <div className="balance__amount">
          <img
            src={`imgs/coins/${coin ? coin.toUpperCase(): "?"}_${c.COIN_LOGOS_PIC_VERSION}.png`}
            className="balance__coinLogo"
            alt="icon"
          />
          <div className="balance__title">
            <span>{coin}</span> {isFuture ? "FUTURES" : <span>{name}</span>}
            <span className="balance__dot"> · </span>
            <span>AVAILABLE</span>
          </div>
          <div>{brushValue(availableBalance + inOrderBalance + (isolatedMargin ? isolatedMargin: 0), isFuture ? 2: 8)}</div>
        </div>
        <div className="balance__details">
          <div className="balance__sums">
            <div>TOTAL</div>
            <div>{brushValue(availableBalance + inOrderBalance + (isolatedMargin ? isolatedMargin: 0), isFuture ? 2: 8)}</div>
          </div>
          <div className="balance__sums">
            <div>AVAILABLE</div>
            <div><b>{brushValue(availableBalance, isFuture? 6: 8)}</b></div>
          </div>
          <div className="balance__sums">
            <div>IN TRADES</div>
            <div>{brushValue(inOrderBalance, isFuture? 6: 8)}</div>
          </div>
          {isFuture && (
            <div className="balance__sums">
              <div> Add. Iso. Margin</div>
              <div>{brushValue(isolatedMargin, isFuture? 6: 8)}</div>
            </div>
          )}
        </div>
        <Divider />
        <div className={`balance__buttons ${(!isDepositable(coin) && !isStakeable(coin)) ? "balance__buttons_non_depositable": ""}`}>
          {!isFuture && isDepositable(coin) &&
              <Button
                style={{ color: "#3BADFF", textTransform: "none" }}
                onClick={(e) => {
                  e.stopPropagation();
                  setModalOpen(true);
                }}
              >
                Deposit
              </Button>}
          {(!isFuture) &&
            <Button
                style={{color: "#3BADFF", textTransform: "none"}}
                onClick={() => {
                  dispatch({
                    type: CHANGE_SELECTED_WITHDRAWAL_COIN,
                    payload: {selectedWithdrawCoin: {...currency, isFuture}, selectedBalanceCoin: {...currency, isFuture}}
                  });
                }}
            >
              <CreditCardIcon/>&nbsp;
              Withdraw
            </Button>
          }
          <Button
            style={{
              color: "#3BADFF",
              textTransform: "none",
            }}
            onClick={() =>
              dispatch({
                type: CHANGE_TO_TRANSFERS_TAB,
                payload: {
                  selectedTransferCoin: { ...currency, isFuture},
                  selectedBalanceCoin: { ...currency, isFuture}}
              })
            }
          >
            Transfer
          </Button>
          {isStakeable(coin) && <Button
            style={{
              color: "#3BADFF",
              textTransform: "none",
            }}
            onClick={() =>
              dispatch({
                type: CHANGE_TO_STAKING_TAB,
                payload: {
                  selectedStakingCoin: { ...currency, isFuture},
                  selectedBalanceCoin: { ...currency, isFuture}}
              })
            }
          >
            Send to Stake
          </Button>}
        </div>
      </div>
      {!isFuture && (
        <div className="balance__tabs-container">
          <div className="balance__tabs">
            <Chip
              style={
                selectedTab === "all"
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.65)",
                      color: "#101926",
                    }
                  : {}
              }
              label="All"
              variant="outlined"
              className="balance__tab"
              clickable
              onClick={() => setSelectedTab("all")}
            />
            <Chip
              style={
                selectedTab === "deposits"
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.65)",
                      color: "#101926",
                    }
                  : {}
              }
              label="Deposits"
              variant="outlined"
              className="balance__tab"
              clickable
              onClick={() => setSelectedTab("deposits")}
            />
            <Chip
              style={
                selectedTab === "withdrawals"
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.65)",
                      color: "#101926",
                    }
                  : {}
              }
              label="Withdrawals"
              variant="outlined"
              className="balance__tab"
              clickable
              onClick={() => setSelectedTab("withdrawals")}
            />
            <Chip
              style={
                selectedTab === "transfers"
                  ? {
                      backgroundColor: "rgba(255, 255, 255, 0.65)",
                      color: "#101926",
                    }
                  : {}
              }
              label="Transfers"
              variant="outlined"
              className="balance__tab"
              clickable
              onClick={() => setSelectedTab("transfers")}
            />
            {coin === "USDT" && <Chip
                style={
                  selectedTab === "investmentPurchases"
                      ? {
                        backgroundColor: "rgba(255, 255, 255, 0.65)",
                        color: "#101926",
                      }
                      : {}
                }
                label="Contributions"
                variant="outlined"
                className="balance__tab"
                clickable
                onClick={() => setSelectedTab("contributions")}
            />}
          </div>
        </div>
      )}
      {!isFuture ? (
        recentWithdrawals &&
        recentDeposits &&
        recentTransfers && recentInternalTransfers && recentInvestmentPurchases &&  (
          <List
            className="balance__list"
            style={{ textAlign: "center" }}
          >
            {selectedTab === "all" &&
              (allRecords.length > 0
                ? updateStatus(allRecords).sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()).map(mapRecordToRow(user._id))
                  : <div className={"noTransactions"}>No transactions</div>)}
            {selectedTab === "deposits" &&
              (recentDeposits.length > 0
                ? updateStatus(enhanceWithType("deposit")(recentDeposits)).sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()).map(
                    mapRecordToRow(user._id)
                  )
                : <div className={"noTransactions"}>No deposits</div>)}
            {selectedTab === "withdrawals" &&
              (recentWithdrawals.length > 0
                ? updateStatus(
                    enhanceWithType("withdrawal")(recentWithdrawals)
                  ).sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()).map(mapRecordToRow(user._id))
                : <div className={"noTransactions"}>No withdrawals</div>)}
            {selectedTab === "transfers" &&
              ((recentTransfers.length > 0 || recentInternalTransfers.length > 0)
                ? updateStatus(
                    enhanceWithType("transfer")(recentTransfers)?.concat(enhanceWithType("internalTransfer")(recentInternalTransfers))
                  ).sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()).map(mapRecordToRow(user._id))
                : <div className={"noTransactions"}>No transfers</div>)}
            {selectedTab === "contributions" &&
            ((recentInvestmentPurchases.length > 0)
                ? updateStatus(enhanceWithType("investment")(recentInvestmentPurchases)).sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()).map(mapRecordToRow(user._id))
                : <div className={"noTransactions"}>No contributions</div>)}
          </List>
        )
      ) : (futuresBalances.positions && futuresBalances.positions.length > 0) && <div>
          <div style={{marginLeft: "1rem", opacity: "0.8"}}>Positions</div>
          <List
            className="balance__list"
            style={{ textAlign: "center", width: "350px" }}
          >
            {futuresBalances.positions.map(mapFuturesRecordToRow)}
          </List>
          </div>}
      {modalOpen && (
        <DepositModal
          handleClose={() => setModalOpen(false)}
          currency={currency}
          open={modalOpen}
        />
      )}
    </div>
  );
};

export default BalanceTab
