import React, {Component} from 'react'
import {connect} from "react-redux"
import {Helmet} from "react-helmet/es/Helmet"
import {getTitle} from "../../fncomponents/PageTitleUtils"
import CircularIndeterminate from "../../fncomponents/loading"
import {getTradersSubscription} from "../../../redux/actions/traders"
import {ChevronLeft} from "@material-ui/icons"
import Button from "@material-ui/core/Button"

const qs = require("query-string")

class SubscribeConfirmationPage extends Component {


    constructor(props) {
        super(props)
        this.state = {
        }
        this.goBack = this.goBack.bind(this)
    }

    goBack() {
        let _t  = this.props.trader ? this.props.trader.trader: undefined
        if (_t)
            this.props.history.push(`/trader?tid=${_t._id}`)
        else
            this.props.history.push(`/traders`)
    }

    componentDidMount() {
        this.state.subscriptionId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).subid
        this.props.getTradersSubscription({_id: this.state.subscriptionId})
    }


    render() {
        let {subscriptionBought, trader, error} = this.props
        let _t = trader ? trader.trader : undefined
        if (!subscriptionBought || subscriptionBought && (this.state.subscriptionId !== subscriptionBought._id)) {
            return <div className={"AppMainContent"}>
                <Helmet>
                    <title>{getTitle()}</title>
                </Helmet>
                <div className={"loadingContainer"}>
                    <div className="loading">
                        <CircularIndeterminate/>
                    </div>
                    <div className={"loadingText"}>Open subscription...</div>
                </div>
            </div>
        }
        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <Button onClick={this.goBack} color="primary" variant="outlined"><ChevronLeft/> <span>Back</span>
            </Button>
            <div className={"vertSpacer2"}/>
            {!error && _t && <div>
                <div>Thank you for subscribing to <b>{subscriptionBought.name}</b>!</div>
                <div>You are now eligible to start receiving signals from <b>{_t.name}</b>.</div>
            </div>}
            {error && <div>
                <div>We are sorry to inform you that you could not subscribe at the moment for <b>{subscriptionBought.name}</b> from <b>{_t.name}</b>!</div>
                <div className={"errorContainer"}>{error}</div>
            </div>}
        </div>
    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    error: rootState.traders.error,
    trader: rootState.traders.trader,
    subscriptionBought: rootState.traders.openedSubscription
})

let mapDispatchToProps = dispatch => {
    return {
        getTradersSubscription: (props) => dispatch(getTradersSubscription(props))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (SubscribeConfirmationPage)
