import React, {Component} from 'react'
import {connect} from 'react-redux'
import {getTitle} from "../../fncomponents/PageTitleUtils"
import {Helmet} from "react-helmet/es/Helmet"
import c from "../../../constants"
import {getCurrenciesMap, getCurrentPriceOnPair} from "../../../redux/actions/newTrade"
import {brushValue, getValueWithPrecision, isValidPair, scientificToDecimal} from "../../fncomponents/UISupportFns"
import {debounce} from "../../hoc/myUtils"
import {
    clearCurrentSignal,
    checkEnterTradeInterval,
    getSignalByIdV1, previewV1
} from "../../../redux/actions/signals"
import KeyboardBackArrow from '@material-ui/icons/KeyboardBackspace'
import {isUserSuperAdmin} from "../../../redux/actions/authentication";
import Button from "@material-ui/core/Button";
import ImportSignalFromXMLDialog from "../../fncomponents/ImportSignalFromXMLDialog";
import NewSignalFormV1 from "./NewSignalFormV1";
import {getTradersSubscriptions} from "../../../redux/actions/traders";
const convert = require('xml-js')


const qs = require("query-string")

const DEBOUNCE_DELAY = 500
const AVERAGE_POS_SIZE = {
    "BTC": 0.05,
    "ETH": 1,
    "USDT": 300
}
const ENTER_TRADE_PRICE_INTERVAL = 0.01 //1%

const INDICATORS_PRECISION_BY_BASE = {
    "BTC": 6,
    "ETH": 4,
    "USDT": 2,
}


class NewOrUpdateSignalV1 extends Component {

    constructor(props) {
        super(props)
        this.state = {
            actionType: c.ACTION_TYPE_BUY, //action_type_BUY or action_type_SELL
            quote: 'BTC',
            base: 'USDT',
            enterTradePriceFrom: 0,
            enterTradePriceTo: 0,
            precision: 0,
            tickSize: 0,
            currentLastBidPrice: 0,
            timeFrame: "",
            tvChartURL: "",
            stopLossTarget: 0,
            stopLossOption: c.STOP_LOSS_OPTION_PRICE,//auto: price/percent, trailing
            candlesType: 2,
            takeProfitTargets: [0],
            takeProfitTargetEditedIndex: 0,
            takeProfitOption: c.TAKE_PROFIT_OPTION_PRICE, //TAKE_PROFIT_OPTION_MANUAL, auto: TAKE_PROFIT_OPTION_AT_A_SPECIFIC_PERCENTAGE/TAKE_PROFIT_OPTION_AT_A_SPECIFIC_PRICE
            comment: "",
            receivingSubscriptions:{},
            potentialProfit: 0,
            potentialLoss: 0,
            riskRewardRatio: 0,
            isOpenImportSignalPanel: false,
            showWarningForEntryPrice: false
        }


        this.previewSignal = this.previewSignal.bind(this)

        this._adjustNumbers = this._adjustNumbers.bind(this)

        this.handleChangeActionType = this.handleChangeActionType.bind(this)
        this.handleChangeQuote = this.handleChangeQuote.bind(this)
        this.handleChangeBase = this.handleChangeBase.bind(this)
        this.debounceAdjustNumbers = debounce(this._adjustNumbers, DEBOUNCE_DELAY)

        this.handleChangeEnterTradePriceFrom = this.handleChangeEnterTradePriceFrom.bind(this)
        this.handleChangeEnterTradePriceTo = this.handleChangeEnterTradePriceTo.bind(this)
        this.fixEnterTradePrices = this.fixEnterTradePrices.bind(this)
        this.debounceFixEnterTradePrices = debounce(this.fixEnterTradePrices, DEBOUNCE_DELAY)

        this.handleChangeTimeFrame = this.handleChangeTimeFrame.bind(this)
        this.handleChangeTVChartURL = this.handleChangeTVChartURL.bind(this)

        this.handleChangeStopLossTarget = this.handleChangeStopLossTarget.bind(this)
        this.fixStopLossTarget = this.fixStopLossTarget.bind(this)
        this.debounceFixStopLossTarget = debounce(this.fixStopLossTarget, DEBOUNCE_DELAY)
        this.handleChangeStopLossOption = this.handleChangeStopLossOption.bind(this)
        this.handleChangeTakeProfitTarget = this.handleChangeTakeProfitTarget.bind(this)
        this.fixTakeProfitTarget = this.fixTakeProfitTarget.bind(this)
        this.debounceFixTakeProfitTarget = debounce(this.fixTakeProfitTarget, DEBOUNCE_DELAY)
        this.handleChangeTakeProfitOption = this.handleChangeTakeProfitOption.bind(this)
        this.addTakeProfitTarget = this.addTakeProfitTarget.bind(this)
        this.removeTakeProfitTarget = this.removeTakeProfitTarget.bind(this)

        this.handleChangeComment = this.handleChangeComment.bind(this)

        this.handleChangeReceivingSubscriptions = this.handleChangeReceivingSubscriptions.bind(this)

        this.populateStateSignalFromSignal = this.populateStateSignalFromSignal.bind(this)

        this.cancel = this.cancel.bind(this)

        this.computeIndicators = this.computeIndicators.bind(this)

        this.showImportSignalPanel = this.showImportSignalPanel.bind(this)
        this.hideImportSignalPanel = this.hideImportSignalPanel.bind(this)
        this.handleChangeImportSignalXML = this.handleChangeImportSignalXML.bind(this)

        this.getXmlSignalTemplate = this.getXmlSignalTemplate.bind(this)
        this.resetSignalXMLToTemplate = this.resetSignalXMLToTemplate.bind(this)
        this.importSignalFromXML = this.importSignalFromXML.bind(this)

        this.switchWithOrWithoutStopLossTarget = this.switchWithOrWithoutStopLossTarget.bind(this)

        this.handleChangeCandlesType = this.handleChangeCandlesType.bind(this)

        this.reFetchCurrentPrice = this.reFetchCurrentPrice.bind(this)
    }

    reFetchCurrentPrice() {
        this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, result => {
            if (result.error) {
                this.setState({
                    invalidPairError: true
                })
                return
            } else {
                this.setState({
                    invalidPairError: false
                })
            }


            let currentLastBidPrice = result.current_price
            this.setState({
                currentLastBidPrice: currentLastBidPrice ? currentLastBidPrice : 0,
                enterTradePriceFrom: currentLastBidPrice ? brushValue(currentLastBidPrice, result.tick_size) : 0,
                precision: result.precision,
                tickSize: result.tick_size,
                enterTradePriceTo: currentLastBidPrice ? brushValue(currentLastBidPrice * (1 + ENTER_TRADE_PRICE_INTERVAL), result.tick_size) : 0
            })
            this.computeIndicators()
        })
    }

    handleChangeCandlesType(e) {
        this.setState({
            candlesType: e.target.value
        })
    }

    switchWithOrWithoutStopLossTarget() {
        if (this.state.stopLossOption === c.STOP_LOSS_OPTION_MANUAL) {
            this.setState({
                stopLossOption: c.STOP_LOSS_OPTION_PERCENTAGE,
                stopLossTarget: 10
            })
        } else {
            this.setState({
                stopLossOption: c.STOP_LOSS_OPTION_MANUAL
            })
        }
    }

    getXmlSignalTemplate = function() {

        let stopLossOption, takeProfitOption
        if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PRICE)
            stopLossOption = 'price'
        else if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE)
            stopLossOption = 'percentage'

        if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE)
            takeProfitOption = 'price'
        else if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE)
            takeProfitOption = 'percentage'

        let takeProfitTargets = ''
        for(let i = 0; i < this.state.takeProfitTargets.length; i++) {
            takeProfitTargets += `<target${i+1}>` + this.state.takeProfitTargets[i] + `</target${i+1}>\n`
        }

        return '<trade>\n' +
        '<actiontype>' + this.state.actionType + '</actiontype>\n' +
        '<asset>' + this.state.quote + ':' + this.state.base + '</asset>\n' +
        '<exchange>Binance</exchange>\n' +
        '<entryfrom>' + this.state.enterTradePriceFrom + '</entryfrom>\n' +
        '<entryto>' + this.state.enterTradePriceTo + '</entryto>\n' +
        '<stoplosstype>' + stopLossOption + '</stoplosstype>\n' +
        '<stoploss>' + this.state.stopLossTarget + '</stoploss>\n' +
        '<targettype>' + takeProfitOption + '</targettype>\n' +
            takeProfitTargets +
        '<timeframe>4h</timeframe>\n' +
        '<chartlink>http://...</chartlink>\n' +
        '</trade>'
    }

    computeIndicators() {
        let potentialProfit = 0
        let potentialLoss = 0
        let averagePosSize = AVERAGE_POS_SIZE[this.state.base]

        if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE) {
            if (this.state.takeProfitTargets) {
                let tpt = 0
                if (this.state.takeProfitTargets.length === 2) {
                    tpt = parseFloat(this.state.takeProfitTargets[1])
                } else if (this.state.takeProfitTargets.length === 1) {
                    tpt = parseFloat(this.state.takeProfitTargets[0])
                }

                let enterTradePriceTo = parseFloat(this.state.enterTradePriceTo)
                potentialProfit = averagePosSize * ( tpt/ enterTradePriceTo - 1)
            }
        } else if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
            if (this.state.takeProfitTargets) {
                let tpt = 0
                if (this.state.takeProfitTargets.length === 2) {
                    tpt = parseFloat(this.state.takeProfitTargets[1])
                } else if (this.state.takeProfitTargets.length === 1) {
                    tpt = parseFloat(this.state.takeProfitTargets[0])
                }
                potentialProfit = averagePosSize * (1 + tpt/100)
            }
        }

        if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PRICE) {
            let slt = parseFloat(this.state.stopLossTarget)
            let enterTradePriceFrom = parseFloat(this.state.enterTradePriceFrom)
            potentialLoss =  Math.abs(averagePosSize *  (1 - slt/ enterTradePriceFrom))
        } else if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE) {
            let slt = parseFloat(this.state.stopLossTarget)
            potentialLoss = averagePosSize * slt/100
        }

        let profitVsLoss = Math.abs(potentialProfit / potentialLoss)
        profitVsLoss = isNaN(profitVsLoss) ? 0: profitVsLoss
        profitVsLoss = profitVsLoss.toFixed(1)
        this.setState({
            potentialProfit: potentialProfit.toFixed(INDICATORS_PRECISION_BY_BASE[this.state.base]),
            potentialLoss: potentialLoss.toFixed(INDICATORS_PRECISION_BY_BASE[this.state.base]),
            riskRewardRatio: profitVsLoss
        })
    }


    _adjustNumbers() {
        this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, result => {

            if (result.error) {
                this.setState({
                    invalidPairError: true
                })
                return
            } else {
                this.setState({
                    invalidPairError: false
                })
            }

            let currentLastBidPrice = result.current_price

            if (this.state.actionType === c.ACTION_TYPE_BUY) {
                if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE) {
                    this.state.takeProfitTargets = [brushValue(currentLastBidPrice * 1.1, result.tick_size)]
                }
                if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PRICE) {
                    this.state.stopLossTarget = brushValue(currentLastBidPrice * 0.95, result.tick_size)
                }
                this.state.enterTradePriceTo = currentLastBidPrice ?
                    brushValue(currentLastBidPrice * (1 + ENTER_TRADE_PRICE_INTERVAL), result.tick_size) : 0
            } else if (this.state.actionType === c.ACTION_TYPE_SELL) {
                if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE) {
                    this.state.takeProfitTargets = [brushValue(currentLastBidPrice * .9, result.tick_size)]
                }
                if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PRICE) {
                    this.state.stopLossTarget = brushValue(currentLastBidPrice * 1.05, result.tick_size)
                }
                this.state.enterTradePriceTo = currentLastBidPrice ?
                    brushValue(currentLastBidPrice * (1 - ENTER_TRADE_PRICE_INTERVAL), result.tick_size) : 0
            }
            this.state.currentLastBidPrice = currentLastBidPrice ? brushValue(currentLastBidPrice, result.tick_size) : 0
            this.state.enterTradePriceFrom = currentLastBidPrice ? brushValue(currentLastBidPrice, result.tick_size) : 0
            this.computeIndicators()
            this.setState({
                precision: result.precision,
                tickSize: result.tick_size
            })
        })
    }

    handleChangeActionType() {
        if (!this.state.signalId) {
            if (this.state.actionType === c.ACTION_TYPE_BUY) {
                this.state.actionType = c.ACTION_TYPE_SELL
            } else {
                this.state.actionType = c.ACTION_TYPE_BUY
            }
            this.debounceAdjustNumbers()
        }
    }


    handleChangeQuote(e) {
        if (this.state.signalId)
            return
        this.setState({
            quote: e.target.value.toUpperCase()
        })
        this.debounceAdjustNumbers()
    }

    handleChangeBase(e) {
        if (this.state.signalId)
            return
        this.state.base = e.target.value
        this.debounceAdjustNumbers()
    }

    handleChangeEnterTradePriceFrom(e) {
        if (e.target.value && e.target.value.length > 0) {
            let from = parseFloat(e.target.value)
            let sign = 1
            if (this.state.actionType === c.ACTION_TYPE_SELL)
                sign = -1
            let to = from * (1 + sign * ENTER_TRADE_PRICE_INTERVAL)
            to = scientificToDecimal(to)
            to = getValueWithPrecision(to, this.state.tickSize)

            this.setState({
                enterTradePriceFrom: e.target.value,
                enterTradePriceTo: to,
            })
            this.debounceFixEnterTradePrices()
        } else {
            this.setState({
                enterTradePriceFrom: e.target.value,
            })
        }
    }

    handleChangeEnterTradePriceTo(e) {
        if (e.target.value && e.target.value.length > 0) {
            this.setState({
                enterTradePriceTo: e.target.value
            })
            this.debounceFixEnterTradePrices()
        } else {
            this.setState({
                enterTradePriceTo: e.target.value
            })
        }
    }

    fixEnterTradePrices() {
        this.state.enterTradePriceFrom = getValueWithPrecision(this.state.enterTradePriceFrom, this.state.tickSize)
        this.state.enterTradePriceTo = getValueWithPrecision(this.state.enterTradePriceTo, this.state.tickSize)
        this.computeIndicators()
        this.props.checkEnterTradeInterval({
            enterTradePriceFrom: this.state.enterTradePriceFrom,
            enterTradePriceTo: this.state.enterTradePriceTo,
            quote: this.state.quote,
            base: this.state.base,
            actionType: this.state.actionType
        }, (data) => {
            if (data) {
                let {currentPrice, delta} = data
                if (delta >= 1) {
                    this.setState({
                        showWarningForEntryPrice: true,
                        currentPrice, delta
                    })
                } else {
                    this.setState({
                        showWarningForEntryPrice: false
                    })
                }}
            }
        )
    }

    handleChangeTimeFrame(e) {
        this.setState({
            timeFrame: e.target.value
        })
    }

    handleChangeTVChartURL(e) {
        this.setState({
            tvChartURL: e.target.value
        })
    }

    handleChangeStopLossTarget(e) {
        this.setState({
            stopLossTarget: e.target.value
        })
        this.debounceFixStopLossTarget()
    }

    fixStopLossTarget() {
        let stopLossTarget = `${this.state.stopLossTarget}`
        stopLossTarget = stopLossTarget.replace("-","")
        if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE) {
            this.state.stopLossTarget = getValueWithPrecision(stopLossTarget, 2)
        } else {
            this.state.stopLossTarget = getValueWithPrecision(stopLossTarget, this.state.tickSize)
        }
        this.computeIndicators()
    }

    handleChangeStopLossOption(option) {
        //If there is no change, do nothing
        if (option === this.state.stopLossOption)
            return

        let oldSLOption = this.state.stopLossOption
        let oldSLOptionType = 0
        switch (oldSLOption) {
            case c.STOP_LOSS_OPTION_PRICE: oldSLOptionType = 1; break;
            case c.STOP_LOSS_OPTION_CONDITIONAL: oldSLOptionType = 1; break;
            case c.STOP_LOSS_OPTION_PERCENTAGE: oldSLOptionType = 2; break;
        }

        let optionType = 0
        switch (option) {
            case c.STOP_LOSS_OPTION_PRICE: optionType = 1; break;
            case c.STOP_LOSS_OPTION_CONDITIONAL: optionType = 1; break;
            case c.STOP_LOSS_OPTION_PERCENTAGE: optionType = 2; break;
        }

        let transitionDelta = oldSLOptionType - optionType

        if (transitionDelta === 0) {
            //no need for calculations
        } else if (transitionDelta === -1) {
            //transition from price to percentage
            let oldPrice = parseFloat(this.state.stopLossTarget)
            let currentPrice = this.props.currentLastBidPrice
            let percent = ((currentPrice - oldPrice) / currentPrice) * 100
            if (percent < 0)
                percent = -1 * percent
            this.state.stopLossTarget = brushValue(percent, 1)
        } else if (transitionDelta === 1) {
            //transition from percentage to price
            let sign = 1
            if (this.state.actionType === c.ACTION_TYPE_SELL)
                sign = -1
            let oldPercent = parseFloat(this.state.stopLossTarget)
            let currentPrice = this.props.currentLastBidPrice
            let price = (1 - sign * oldPercent/100) * parseFloat(currentPrice)
            this.state.stopLossTarget = brushValue(price, this.state.tickSize)
        }
        this.setState({
            stopLossOption: option
        })
    }

    handleChangeTakeProfitTarget(e, i) {
        this.state.takeProfitTargets[i] = e.target.value
        this.state.takeProfitTargetEditedIndex = i
        this.setState({
            someVar: Math.random()
        })
        this.debounceFixTakeProfitTarget()
    }

    fixTakeProfitTarget() {
        let index = this.state.takeProfitTargetEditedIndex
        if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
            this.state.takeProfitTargets[index] = getValueWithPrecision(this.state.takeProfitTargets[index], 2)
        } else {
            this.state.takeProfitTargets[index] = getValueWithPrecision(this.state.takeProfitTargets[index], this.state.tickSize)
        }
        this.computeIndicators()
    }

    handleChangeTakeProfitOption(option) {
        //If there is no change, do nothing
        if (this.state.takeProfitOption === option)
            return

        let sign = 1
        if (this.state.actionType === c.ACTION_TYPE_SELL)
            sign = -1

        //Else if the transition is from Percentage or Price, transform
        //percentage to price and vice-versa
        if (option === c.TAKE_PROFIT_OPTION_PRICE) {
            for(let i = 0; i < this.state.takeProfitTargets.length; i++) {
                let oldPercent = parseFloat(this.state.takeProfitTargets[i])
                let currentPrice = this.props.currentLastBidPrice
                let price = (1 + sign * oldPercent/100) * parseFloat(currentPrice)
                this.state.takeProfitTargets[i] = brushValue(price, this.state.tickSize)
            }
        } else if (option === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
            for(let i = 0; i < this.state.takeProfitTargets.length; i++) {
                let oldPrice = parseFloat(this.state.takeProfitTargets[i])
                let currentPrice = this.props.currentLastBidPrice
                let percent = ((oldPrice - currentPrice) / currentPrice) * 100
                if (percent < 0)
                    percent = -1 * percent
                this.state.takeProfitTargets[i] = brushValue(percent, 1)
            }
        }
        this.computeIndicators()
        this.setState({
            takeProfitOption: option
        })
    }

    addTakeProfitTarget() {
        if (this.state.takeProfitTargets.length === 10)
            return
        this.state.takeProfitTargets.push(this.state.takeProfitTargets[this.state.takeProfitTargets.length - 1])
        this.setState({
            someVar: Math.random()
        })
    }

    removeTakeProfitTarget(index) {
        if (index === 0) {
            //Remove first element
            this.state.takeProfitTargets.shift()
        } else if (index === this.state.takeProfitTargets.length -1) {
            //Remove last element
            this.state.takeProfitTargets.pop()
        } else {
            //Remove an element in the middle
            this.state.takeProfitTargets.splice(index, 1)
        }

        this.setState({
            someVar: Math.random()
        })
    }

    handleChangeComment(e) {
        this.setState({
            comment: e.target.value
        })
    }

    handleChangeReceivingSubscriptions(g) {
        if (this.state.signalId)
            return
        this.setState({
            receivingSubscriptionsErr: undefined,
            receivingSubscriptions: {...this.state.receivingSubscriptions,
                ...{[g.target.value]: (!this.state.receivingSubscriptions[g.target.value])}}
        })
    }

    isAtLeastOneReceivingSubscriptionSelected(receivingSubscriptions) {
        if (!receivingSubscriptions)
            return false
        let keys = Object.keys(receivingSubscriptions)
        if (keys.length === 0)
            return false
        let result = false
        keys.map((key, index) => {
            if (receivingSubscriptions[key]) {
                result = true
                return undefined
            }
            return undefined
        })
        return result
    }

    checkTakeProfitTargetsAreInSuccession(takeProfitTargets) {
        if (takeProfitTargets) {
            if (takeProfitTargets.length === 1)
                return true

            if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE) {
                for(let i = 0; i < takeProfitTargets.length -1; i++) {
                    if (this.state.actionType === c.ACTION_TYPE_BUY && parseFloat(takeProfitTargets[i]) >= parseFloat(takeProfitTargets[i+1])) {
                        return false
                    } else if (this.state.actionType === c.ACTION_TYPE_SELL && parseFloat(takeProfitTargets[i]) <= parseFloat(takeProfitTargets[i+1])) {
                        return false
                    }
                }
            } else if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE) {
                for(let i = 0; i < takeProfitTargets.length -1; i++) {
                    if (parseFloat(takeProfitTargets[i]) >= parseFloat(takeProfitTargets[i+1])) {
                        return false
                    }
                }
            }

            return true
        }
    }

    previewSignal() {
        let errors = false
        this.state.receivingSubscriptionsErr = undefined
        this.state.selectPairErr = undefined
        this.state.enterTradePricesErr = undefined
        this.state.takeProfitErr = undefined
        this.state.stopLossErr = undefined

        if (!this.isAtLeastOneReceivingSubscriptionSelected(this.state.receivingSubscriptions)) {
            errors = true
            this.setState({
              receivingSubscriptionsErr: "Please select at least one subscription"
            })
        }

        if (!this.state.quote || !isValidPair(this.state.quote, this.state.base, this.props.quoteCurrenciesNom)) {
            errors = true
            this.setState({
                selectPairErr: "Please specify a valid pair"
            })
        }

        if (this.state.invalidPairError) {
            return
        }

        if (!this.checkTakeProfitTargetsAreInSuccession(this.state.takeProfitTargets)) {
            errors = true
            if (this.state.actionType === c.ACTION_TYPE_BUY) {
                this.setState({
                    takeProfitErr: "Take profit targets are not in ascending succession"
                })
            } else if (this.state.actionType === c.ACTION_TYPE_SELL) {
                this.setState({
                    takeProfitErr: "Take profit targets are not in descending succession"
                })
            }

        }

        if (!this.state.enterTradePriceFrom || !this.state.enterTradePriceTo) {
            errors = true
            this.setState({
                enterTradePricesErr: "Please specify entering prices"
            })
        }

        if (this.state.actionType === c.ACTION_TYPE_BUY) {
            if (parseFloat(this.state.enterTradePriceFrom) > parseFloat(this.state.enterTradePriceTo)) {
                errors = true
                this.setState({
                    enterTradePricesErr: "Entering prices are not in ascending succession"
                })
            }
            if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE &&
                parseFloat(this.state.takeProfitTargets[0]) < parseFloat(this.state.enterTradePriceTo)) {

                errors = true
                this.setState({
                    takeProfitErr: "First take profit target is not above the last price"
                })
            }
            if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE &&
                parseFloat(this.state.takeProfitTargets[0]) === 0) {
                errors = true
                this.setState({
                    takeProfitErr: "First take profit target is not above the last price"
                })
            }
        } else if (this.state.actionType === c.ACTION_TYPE_SELL) {
            if (parseFloat(this.state.enterTradePriceFrom) < parseFloat(this.state.enterTradePriceTo)) {
                errors = true
                this.setState({
                    enterTradePricesErr: "Entering prices are not in descending succession"
                })
            }
            if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PRICE &&
                parseFloat(this.state.takeProfitTargets[0]) > parseFloat(this.state.enterTradePriceTo)) {
                errors = true
                this.setState({
                    takeProfitErr: "First take profit target is not below the last price"
                })
            }
            if (this.state.takeProfitOption === c.TAKE_PROFIT_OPTION_PERCENTAGE &&
                parseFloat(this.state.takeProfitTargets[0]) === 0) {
                errors = true
                this.setState({
                    takeProfitErr: "First take profit target is not below the last price"
                })
            }

        }

        if (!this.state.signalId) {

            if (this.state.actionType === c.ACTION_TYPE_BUY) {
                if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PRICE &&
                    parseFloat(this.state.stopLossTarget) >= parseFloat(this.state.enterTradePriceFrom)) {
                    errors = true
                    this.setState({
                        stopLossErr: "Stop loss target is not below the first price"
                    })
                }
                if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE &&
                    parseFloat(this.state.stopLossTarget) === 0) {
                    errors = true
                    this.setState({
                        stopLossErr: "Stop loss target is not below the first price"
                    })
                }
            } else if (this.state.actionType === c.ACTION_TYPE_SELL) {
                if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PRICE &&
                    parseFloat(this.state.stopLossTarget) <= parseFloat(this.state.enterTradePriceFrom)) {
                    errors = true
                    this.setState({
                        stopLossErr: "Stop loss target is not above the first price"
                    })
                }
                if (this.state.stopLossOption === c.STOP_LOSS_OPTION_PERCENTAGE &&
                    parseFloat(this.state.stopLossTarget) === 0) {
                    errors = true
                    this.setState({
                        stopLossErr: "Stop loss target is not above the first price"
                    })
                }
            }
        }

        let _currentPrice
        if (this.state.currentPrice)
            _currentPrice = this.state.currentPrice
        else
            _currentPrice = this.state.currentLastBidPrice

        if (!errors) {
            this.props.preview({
                signalId: this.state.signalId,
                signalType: c.SIGNAL_TYPE_SPOT,
                actionType: this.state.actionType,
                quoteAsset: this.state.quote,
                baseAsset: this.state.base,
                enterTradePriceFrom: this.state.enterTradePriceFrom,
                enterTradePriceTo: this.state.enterTradePriceTo,
                precision: this.state.precision,
                tickSize: this.state.tickSize,
                timeFrame: this.state.timeFrame,
                tvChartURL: this.state.tvChartURL,
                stopLossTarget: this.state.stopLossTarget,
                stopLossOption: this.state.stopLossOption,
                takeProfitTargets: this.state.takeProfitTargets,
                takeProfitOption: this.state.takeProfitOption,
                comment: this.state.comment,
                receivingSubscriptions: this.state.receivingSubscriptions,
                potentialProfit: this.state.potentialProfit,
                potentialLoss: this.state.potentialLoss,
                riskRewardRatio: this.state.riskRewardRatio,
                currentPrice: _currentPrice,
                candlesType: this.state.candlesType
            }, () => {
                this.props.history.push("/previewsignal")
            })
        }
    }

    cancel() {
        this.props.clearCurrentSignal(()=>{
            this.setState({
                signalId: undefined,
                actionType: c.ACTION_TYPE_BUY, //action_type_BUY or action_type_SELL
                quote: 'ETH',
                base: 'BTC',
                enterTradePriceFrom: 0,
                enterTradePriceTo: 0,
                currentLastBidPrice: 0,
                timeFrame: "",
                tvChartURL: "",
                stopLossTarget: 0,
                stopLossOption: c.STOP_LOSS_OPTION_PRICE,//auto: price/percent, trailing
                takeProfitTargets: [0],
                takeProfitTargetEditedIndex: 0,
                takeProfitOption: c.TAKE_PROFIT_OPTION_PRICE, //TAKE_PROFIT_OPTION_MANUAL, auto: TAKE_PROFIT_OPTION_AT_A_SPECIFIC_PERCENTAGE/TAKE_PROFIT_OPTION_AT_A_SPECIFIC_PRICE
                comment: "",
                receivingSubscriptions:{}
            })
            this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, result => {

                if (result.error) {
                    this.setState({
                        invalidPairError: true
                    })
                    return
                } else {
                    this.setState({
                        invalidPairError: false
                    })
                }

                let tickSize = parseInt(result.tick_size)
                let currentLastBidPrice = brushValue(result.current_price, tickSize)
                this.setState({
                    currentLastBidPrice: currentLastBidPrice ? currentLastBidPrice : 0,
                    enterTradePriceFrom: currentLastBidPrice ? currentLastBidPrice : 0,
                    precision: result.precision,
                    tickSize: result.tick_size,
                    enterTradePriceTo: currentLastBidPrice ? brushValue(currentLastBidPrice * (1 + ENTER_TRADE_PRICE_INTERVAL), tickSize) : 0,
                    stopLossTarget: brushValue(currentLastBidPrice * .95, tickSize),
                    takeProfitTargets:[brushValue(currentLastBidPrice * 1.1, tickSize)]
                })
            })
        })
    }

    populateStateSignalFromSignal(signal, fromProps) {
        let receivingSubscriptions= {}
        if (signal.receivingSubscriptions) {
            if (fromProps) {
                let rGrpsKeys = Object.keys(signal.receivingSubscriptions)
                for(let i = 0; i < rGrpsKeys.length; i++) {
                    if (signal.receivingSubscriptions[rGrpsKeys[i]])
                        receivingSubscriptions[rGrpsKeys[i]] = true
                }
            } else {
                for(let i = 0; i < signal.receivingSubscriptions.length; i++) {
                    receivingSubscriptions[signal.receivingSubscriptions[i]] = true
                }
            }

        }

        let takeProfitTargets = []
        if (signal.takeProfitTargets) {
            for(let i = 0; i < signal.takeProfitTargets.length; i++) {
                if (fromProps) {
                    takeProfitTargets.push(brushValue(signal.takeProfitTargets[i], signal.tickSize))
                } else {
                    takeProfitTargets.push(brushValue(signal.takeProfitTargets[i].price, signal.tickSize))
                }
            }
        }

        let signalState =
        {
            signalId: (fromProps ? signal.signalId: signal._id),
            actionType: signal.actionType,
            quote: signal.quoteAsset,
            base: signal.baseAsset,
            enterTradePriceFrom: brushValue(signal.enterTradePriceFrom, signal.tickSize),
            enterTradePriceTo: brushValue(signal.enterTradePriceTo, signal.tickSize),
            precision: signal.precision,
            tickSize: signal.tickSize,
            timeFrame: signal.timeFrame,
            tvChartURL: signal.tvChartURL,
            //stopLossTarget: (fromProps ? brushValue(signal.stopLossTarget): brushValue(signal.stopLossTarget.price, signal.tickSize)),
            stopLossOption: signal.stopLossOption,
            takeProfitTargets: takeProfitTargets,
            takeProfitOption: signal.takeProfitOption,
            comment: signal.comment,
            receivingSubscriptions: {
                ...this.state.receivingSubscriptions,
                ...receivingSubscriptions
            }
        }

        if (signal.stopLossTarget) {
            signalState.stopLossTarget = (fromProps ? brushValue(signal.stopLossTarget): brushValue(signal.stopLossTarget.price, signal.tickSize))
        }

        this.setState(signalState)
    }

    showImportSignalPanel() {
        this.state.xmlSignal = this.getXmlSignalTemplate()
        this.setState({
            isOpenImportSignalPanel: true
        })
    }

    hideImportSignalPanel() {
        this.setState({
            isOpenImportSignalPanel: false
        })
    }

    handleChangeImportSignalXML(e) {
        this.setState({
            xmlSignal: e.target.value
        })
    }

    resetSignalXMLToTemplate() {
        this.setState({
            xmlSignal: this.getXmlSignalTemplate()
        })
    }

    getElementFromXmlObj(xmlObj, elementName, type) {
        let result = undefined
        if (xmlObj) {
          if (xmlObj.trade) {
              if (xmlObj.trade[elementName]) {
                  if (xmlObj.trade[elementName]._text) {
                      result = xmlObj.trade[elementName]._text
                  }
              }
          }
        }

        if (result) {
            if (type === "float")
                return parseFloat(result)
            else if (type === "text")
                return result
            else
                return result
        } else {
            if (type === "float")
                return 0
            else if (type === "text")
                return ''
            else return ''
        }
    }

    getQuoteFromXmlObj(xmlObj) {
        let asset = this.getElementFromXmlObj(xmlObj, "asset", "text")
        if (asset) {
            return asset.substring(0, asset.indexOf(":"))
        }
    }

    getBaseFromXmlObj(xmlObj) {
        let asset = this.getElementFromXmlObj(xmlObj, "asset", "text")
        if (asset) {
            return asset.substring(asset.indexOf(":") + 1, asset.length)
        }
    }

    importSignalFromXML() {
        let xmlObj = undefined
        let errorParsing = undefined
        try {
            //Returns a string not an object
            xmlObj = convert.xml2json(this.state.xmlSignal, {compact: true, spaces: 0});
            xmlObj = JSON.parse(xmlObj)
        } catch (err) {
            errorParsing = err
        }
        if (!xmlObj) {
            this.setState({
                xmlSignalError: errorParsing.message
            })
        } else {

            let stopLossOption = c.STOP_LOSS_OPTION_PRICE
            if (this.getElementFromXmlObj(xmlObj, "stopLossType".toLowerCase(), "text") === "percentage")
                stopLossOption = c.STOP_LOSS_OPTION_PERCENTAGE

            let takeProfitOption = c.TAKE_PROFIT_OPTION_PRICE
            if (this.getElementFromXmlObj(xmlObj, "targetType".toLowerCase(), "text") === "percentage")
                takeProfitOption = c.TAKE_PROFIT_OPTION_PERCENTAGE

            let signal = {
                actionType: this.getElementFromXmlObj(xmlObj, "ActionType".toLowerCase(), "text"),
                quote: this.getQuoteFromXmlObj(xmlObj),
                base: this.getBaseFromXmlObj(xmlObj),
                enterTradePriceFrom: this.getElementFromXmlObj(xmlObj, "EntryFrom".toLowerCase(), "float"),
                enterTradePriceTo: this.getElementFromXmlObj(xmlObj, "EntryTo".toLowerCase(), "float"),
                stopLossTarget: this.getElementFromXmlObj(xmlObj, "stopLoss".toLowerCase(), "float"),
                stopLossOption: stopLossOption,
                takeProfitTargets: [
                    this.getElementFromXmlObj(xmlObj, "target1", "float"),
                    this.getElementFromXmlObj(xmlObj, "target2", "float"),
                ],
                takeProfitOption: takeProfitOption,
                timeFrame: this.getElementFromXmlObj(xmlObj, "timeFrame".toLowerCase(), "text"),
                tvChartURL: this.getElementFromXmlObj(xmlObj, "chartLink".toLowerCase(), "text")
            }
            this.props.getCurrentPriceOnPair({quote: signal.quote, base: signal.base}, result => {

                if (result.error) {
                    this.setState({
                        invalidPairError: true
                    })
                    return
                } else {
                    this.setState({
                        invalidPairError: false
                    })
                }

                let currentLastBidPrice = result.current_price
                this.setState({
                    currentLastBidPrice: currentLastBidPrice ? currentLastBidPrice : 0,
                    tickSize: result.tick_size,
                    precision: result.precision,
                    isOpenImportSignalPanel: false,
                    ...signal
                })
                this.computeIndicators()
            })
        }
    }

    componentDidMount() {
        if (!this.props.baseCurrenciesNom) {
            this.props.getCurrenciesMap()
        }

        this.state.signalId = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).signalId
        if (this.state.signalId) {
            this.props.getSignalById({signalId: this.state.signalId, isUserSuperAdmin: isUserSuperAdmin(this.props.user)}, signal => {
                this.populateStateSignalFromSignal(signal)
                this.props.getCurrentPriceOnPair({quote: signal.quoteAsset, base: signal.baseAsset}, result => {

                    if (result.error) {
                        this.setState({
                            invalidPairError: true
                        })
                        return
                    } else {
                        this.setState({
                            invalidPairError: false
                        })
                    }

                    let currentLastBidPrice = result.current_price
                    this.setState({
                        currentLastBidPrice: currentLastBidPrice ? currentLastBidPrice : 0,
                        tickSize: result.tick_size
                    })
                    this.computeIndicators()
                })
            })
        } else {
            if (this.props.signal) {
                //If the user cam back from Preview Signal
                this.populateStateSignalFromSignal(this.props.signal, true)
            } else {
                this.props.getCurrentPriceOnPair({quote: this.state.quote, base: this.state.base}, result => {

                    if (result.error) {
                        this.setState({
                            invalidPairError: true
                        })
                        return
                    } else {
                        this.setState({
                            invalidPairError: false
                        })
                    }

                    let currentLastBidPrice = result.current_price
                    this.setState({
                        currentLastBidPrice: currentLastBidPrice ? currentLastBidPrice : 0,
                        enterTradePriceFrom: currentLastBidPrice ? brushValue(currentLastBidPrice, result.tick_size) : 0,
                        precision: result.precision,
                        tickSize: result.tick_size,
                        enterTradePriceTo: currentLastBidPrice ? brushValue(currentLastBidPrice * (1 + ENTER_TRADE_PRICE_INTERVAL), result.tick_size) : 0,
                        stopLossTarget: brushValue(currentLastBidPrice * .95, result.tick_size),
                        takeProfitTargets:[brushValue(currentLastBidPrice * 1.1, result.tick_size)]
                    })
                    this.computeIndicators()
                })
            }
        }

        this.props.getTradersSubscriptions()
    }

    render() {
        return <div className={"AppMainContent"}>
            <Helmet>
                <title>{getTitle()}</title>
            </Helmet>
            <div style={{display: "flex"}}>
                <div style={{display: "flex", justifyContent: "flex-start", width: "7em", cursor: "pointer", marginTop: "0.05rem"}}
                     onClick={()=>{this.props.history.push("/signals")}} >
                    <KeyboardBackArrow/>
                    <div className={"pageTitle"} style={{marginLeft: "1rem"}}>Back</div>
                </div>
                <Button onClick={()=>{this.showImportSignalPanel()}}>Import</Button>
            </div>
            <NewSignalFormV1
                open={true}
                signalId={this.state.signalId}
                user={this.props.user}
                handleCancel={this.cancel}
                handlePreview={this.previewSignal}
                actionType={this.state.actionType}
                actionTypeErr={this.state.actionTypeErr}
                handleChangeActionType={this.handleChangeActionType}
                quote={this.state.quote}
                handleChangeQuote={this.handleChangeQuote}
                quoteCurrenciesNom={this.props.quoteCurrenciesNom}
                base={this.state.base}
                invalidPairError={this.state.invalidPairError}
                handleChangeBase={this.handleChangeBase}
                baseCurrenciesNom={this.props.baseCurrenciesNom}
                selectPairErr={this.state.selectPairErr}
                reFetchCurrentPrice={this.reFetchCurrentPrice}
                enterTradePriceFrom={this.state.enterTradePriceFrom}
                handleChangeEnterTradePriceFrom={this.handleChangeEnterTradePriceFrom}
                enterTradePriceTo={this.state.enterTradePriceTo}
                handleChangeEnterTradePriceTo={this.handleChangeEnterTradePriceTo}
                enterTradePricesErr={this.state.enterTradePricesErr}
                timeFrame={this.state.timeFrame}
                handleChangeTimeFrame={this.handleChangeTimeFrame}
                tvChartURL={this.state.tvChartURL}
                handleChangeTVChartURL={this.handleChangeTVChartURL}
                stopLossTarget={this.state.stopLossTarget}
                stopLossOption={this.state.stopLossOption}
                takeProfitTargets={this.state.takeProfitTargets}
                takeProfitOption={this.state.takeProfitOption}
                handleChangeStopLossTarget={this.handleChangeStopLossTarget}
                handleChangeStopLossOption={this.handleChangeStopLossOption}
                switchWithOrWithoutStopLossTarget={this.switchWithOrWithoutStopLossTarget}
                handleChangeTakeProfitTarget={this.handleChangeTakeProfitTarget}
                handleChangeTakeProfitOption={this.handleChangeTakeProfitOption}
                addTakeProfitTarget={this.addTakeProfitTarget}
                removeTakeProfitTarget={this.removeTakeProfitTarget}
                takeProfitErr={this.state.takeProfitErr}
                stopLossErr={this.state.stopLossErr}
                comment={this.state.comment}
                handleChangeComment={this.handleChangeComment}
                receivingSubscriptions={this.state.receivingSubscriptions}
                receivingSubscriptionsErr={this.state.receivingSubscriptionsErr}
                handleChangeReceivingSubscriptions={this.handleChangeReceivingSubscriptions}
                subscriptions={this.props.subscriptions}
                potentialProfit={this.state.potentialProfit}
                potentialLoss={this.state.potentialLoss}
                riskRewardRatio={this.state.riskRewardRatio}
                averagePosSize={AVERAGE_POS_SIZE[this.state.base]}
                showWarningForEntryPrice={this.state.showWarningForEntryPrice}
                currentPrice={this.state.currentPrice}
                delta={this.state.delta}
                candlesType={this.state.candlesType}
                handleChangeCandlesType={this.handleChangeCandlesType}
            />
            <ImportSignalFromXMLDialog
                open={this.state.isOpenImportSignalPanel}
                xmlSignal={this.state.xmlSignal}
                xmlSignalError={this.state.xmlSignalError}
                handleChangeXMLSignal={this.handleChangeImportSignalXML}
                handleImport={this.importSignalFromXML}
                handleCancel={this.hideImportSignalPanel}
                handleReset={this.resetSignalXMLToTemplate}
            />
        </div>

    }
}

let mapStateToProps = rootState => ({
    user: rootState.authentication.user,
    baseCurrenciesNom: rootState.newTrade.baseCurrenciesNom,
    quoteCurrenciesNom: rootState.newTrade.quoteCurrenciesNom,
    currentLastBidPrice: rootState.newTrade.currentLastBidPrice,
    precision: rootState.newTrade.precision,
    signal: rootState.signals.signal,
    subscriptions: rootState.traders.subscriptions
})

let mapDispatchToProps = dispatch => {
    return {
        getCurrenciesMap: (props) => dispatch(getCurrenciesMap(props)),
        getCurrentPriceOnPair: (props, cb) => dispatch(getCurrentPriceOnPair(props, cb)),
        preview: (props, cb) => dispatch(previewV1(props, cb)),
        getTradersSubscriptions: (props) => dispatch(getTradersSubscriptions(props)),
        getSignalById: (props, cb) => dispatch(getSignalByIdV1(props, cb)),
        clearCurrentSignal: (cb) => dispatch(clearCurrentSignal(cb)),
        checkEnterTradeInterval: (props, cb) => dispatch(checkEnterTradeInterval(props, cb)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (NewOrUpdateSignalV1)
