import React from "react";
import {TextField} from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import {Person} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(1),
        width: 280,
        backgroundColor: '#101A26'
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    }
}))

export default function SearchUserByEmail(props) {
    const {email, handleChangeEmail, emailErr, result, handleSelect} = props
    const classes = useStyles()

    return (
    <div className={"wizardSectionContainer"}>
        <div className={"wizardTitle"}>
            <span className={"wizardTitleIcon"}><Person/></span> User <sup className={"helpIconContainer"} title={"Search an user by email. Then select to add him/her as your new follower."}>?</sup>
        </div>
        <div style={{marginLeft: "0.6rem"}}>
            <TextField
                id="outlined-adornment-weight_search_user_by_email"
                className={classes.textField}
                margin="dense"
                variant="outlined"
                placeholder={"email"}
                value={email}
                onChange={handleChangeEmail}
            />
            {emailErr && <div className={"errorContainer"}>{emailErr}</div>}
            <br/>
            {(result && result._id) &&
                <div title={result._id} className={"someResults"} style={{cursor: "pointer"}} onClick={handleSelect}>
                    {result.firstName} {result.lastName}, {result.email}</div>}
            {(result && !result._id)&& <div className={"noResults"}>No results</div>}
            {!result && <div className={"noResults"}>No results</div>}

        </div>
    </div>)
}
